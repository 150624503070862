/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './theme'
import { withStyles } from '@material-ui/core/styles'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import Moment from 'react-moment'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import BrushIcon from '@material-ui/icons/Brush'
import CachedIcon from '@material-ui/icons/Cached'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import { IMAGE_APPROVAL_STATUS, ROUTES } from '../../Shared/Constants'
import {
  gridCheckedLogic,
  isExternalUser,
  escapeHtml,
} from '../../Shared/SharedUtils'
import ImageToolbar from '../Shared/Components/ImageToolbar'
import ApproveImageAction from '../Shared/Components/ApproveImageAction'
import SendToRetouchImageAction from '../Shared/Components/SendToRetouchImageAction'
import RejectImageAction from '../Shared/Components/RejectImageAction'
import ImageToolbarActionDrawerContainer from '../Shared/Components/ImageToolbarActionDrawer/ImageToolbarActionDrawerContainer'
import { Helmet } from 'react-helmet'
import {
  Button,
  Checkbox,
  Paper,
  Grid,
  Typography,
  Toolbar,
  CircularProgress,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from '@material-ui/core'
import { flattenDeep, some, isNil, isEqual, uniq } from 'lodash'
import {
  requestImageReviewHistoryData,
  clearReviewHistoryData,
  handleSelectImage,
  getImageReviewHistoryStrapiData,
} from '../../../store/images/reviewHistory/actionCreator'
import { toggleActionDrawer } from '../../../store/images/review/actionCreator'
import DefaultAlert from '../../Shared/StrapiComponents/DefaultAlert'
import Alert from '../../Shared/StrapiComponents/Alert'
import StrapiExpansionPanel from '../../Shared/StrapiComponents/StrapiExpansionPanel'
import { withRouter } from '../../Shared/WithRouterHoc'

function ImageReviewHistory(props) {
  const {
    classes = {},
    reviewHistoryData = [],
    selectedReviewHistoryImages = [],
    isFetching = false,
    showDefaultAlert = false,
    alert = null,
    information = null,
    faq = null,
    auth = {},
  } = props

  useEffect(() => {
    props.getImageReviewHistoryStrapiData(isExternalUser(auth))
  }, [])

  const [expansionPanelOpen, expandFaq] = useState(false)

  const handleFaqPanel = () => {
    expandFaq(!expansionPanelOpen)
  }

  const backToSearch = (_) => {
    props.clearReviewHistoryData()
    // re-run selected filter
    // props.history.push(ROUTES.IMAGES.SEARCH)
    props.router.navigate({ pathname: ROUTES.IMAGES.SEARCH })
  }

  const buildApprovalStatus = (
    approvalStatus,
    userStatusMessage,
    rejectionReason
  ) => {
    if (approvalStatus === IMAGE_APPROVAL_STATUS.APPROVED) {
      return (
        <div className={`${classes.approvalStatus} ${classes.statusApproved}`}>
          <CheckIcon /> {userStatusMessage}
        </div>
      )
    } else if (approvalStatus === IMAGE_APPROVAL_STATUS.PENDING) {
      return (
        <div className={`${classes.approvalStatus} ${classes.statusPending}`}>
          <CachedIcon /> {userStatusMessage}
        </div>
      )
    } else if (approvalStatus === IMAGE_APPROVAL_STATUS.IN_PROCESS) {
      return (
        <div className={`${classes.approvalStatus} ${classes.statusInProcess}`}>
          <SpeakerNotesIcon /> {userStatusMessage}
        </div>
      )
    } else if (approvalStatus === IMAGE_APPROVAL_STATUS.REJECTED) {
      return (
        <div className={`${classes.approvalStatus} ${classes.statusRejected}`}>
          <WarningIcon /> {IMAGE_APPROVAL_STATUS.REJECTED}: {rejectionReason}
        </div>
      )
    } else if (approvalStatus === IMAGE_APPROVAL_STATUS.ERROR) {
      return (
        <div className={`${classes.approvalStatus} ${classes.statusRejected}`}>
          <CheckIcon /> {IMAGE_APPROVAL_STATUS.ERROR}: {userStatusMessage}
        </div>
      )
    } else if (
      approvalStatus === IMAGE_APPROVAL_STATUS.RETOUCH &&
      userStatusMessage === 'Image being retouched'
    ) {
      return (
        <div className={`${classes.approvalStatus} ${classes.statusPending}`}>
          <BrushIcon /> {userStatusMessage}
        </div>
      )
    } else if (
      approvalStatus === IMAGE_APPROVAL_STATUS.RETOUCH &&
      userStatusMessage === 'Approved - Retouch Complete'
    ) {
      return (
        <div className={`${classes.approvalStatus} ${classes.statusApproved}`}>
          <CheckIcon /> {userStatusMessage}
        </div>
      )
    }
  }

  const isSelected = (clickedImage, tcin) => {
    const reviewItem = {
      tcin: tcin,
      image: clickedImage,
    }
    return !isNil(
      selectedReviewHistoryImages &&
        selectedReviewHistoryImages.find((image) => isEqual(image, reviewItem))
    )
  }

  const toggleActionDrawer = (isActionDrawerOpen, drawerAction) => {
    props.toggleActionDrawer(isActionDrawerOpen, drawerAction)
  }

  const handleClick = (_, image, tcin) => {
    const checkedImage = {
      tcin: tcin,
      image: image,
    }
    props.handleSelectImage(
      gridCheckedLogic(selectedReviewHistoryImages, checkedImage)
    )
  }

  const numSelected = some(selectedReviewHistoryImages)
    ? selectedReviewHistoryImages.length
    : 0
  const selectedTcins = uniq(
    selectedReviewHistoryImages.map((item) => item.tcin)
  )

  const totalItems = some(reviewHistoryData) ? reviewHistoryData.length : 0
  const totalImages = some(reviewHistoryData)
    ? flattenDeep(reviewHistoryData.map((review) => review.image_list)).length
    : 0

  return (
    <React.Fragment>
      <HeaderTitle title="Image Review History" />
      <Helmet title="Image Review History" />
      {showDefaultAlert ? <DefaultAlert /> : null}
      {alert ? <Alert alert={alert} /> : null}
      {(information || faq) && (
        <StrapiExpansionPanel
          faq={faq}
          information={information}
          expansionPanelOpen={expansionPanelOpen}
          handleFaqPanel={handleFaqPanel}
        />
      )}
      <Paper className={classes.width100}>
        <React.Fragment>
          <Toolbar>
            <Grid
              justify="space-between"
              container
              spacing={3}
              alignItems="center"
            >
              <Grid item>
                <div className={classes.title}>
                  <Typography
                    variant="subtitle1"
                    className={classes.totalElementsTitle}
                    id="tableTitle"
                  >
                    {totalItems} {totalItems === 1 ? 'item' : 'items'} /{' '}
                    {totalImages} {totalImages === 1 ? 'image' : 'images'}
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => backToSearch()}
                  className={classes.marginRight20}
                >
                  Back To Search Results
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          {isFetching && (
            <Grid className={classes.progressBar}>
              <CircularProgress />
            </Grid>
          )}
          {some(reviewHistoryData) && !isFetching && (
            <React.Fragment>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      {auth.permission.imgCurationEdit ? (
                        <TableCell padding="default" align="left" />
                      ) : null}
                      <TableCell padding="default" align="left">
                        TCIN
                      </TableCell>
                      <TableCell padding="default" align="left">
                        Product Title
                      </TableCell>
                      <TableCell padding="default" align="left">
                        Image Position
                      </TableCell>
                      <TableCell padding="default" align="left">
                        Image
                      </TableCell>
                      <TableCell padding="default" align="left">
                        Upload Time
                      </TableCell>
                      <TableCell padding="default" align="left">
                        Upload User
                      </TableCell>
                      <TableCell padding="default" align="left">
                        Review Status
                      </TableCell>
                      <TableCell padding="default" align="left">
                        Asset Status
                      </TableCell>
                      {/* <TableCell padding="default" align="left">
                        Launch Date
                      </TableCell> */}
                      {/* <TableCell padding="default" align="left">
                        Item Vendor
                      </TableCell> */}
                      {/* <TableCell padding="default" align="left">
                        Rel. Type Code
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reviewHistoryData.map((item) =>
                      item.image_list
                        .sort((a, b) => b.upload_date - a.upload_date)
                        .map((image) => (
                          <TableRow hover key={item.tcin + image.image_id}>
                            {auth.permission.imgCurationEdit ? (
                              <TableCell padding="default">
                                <Checkbox
                                  data-test-id="image-checkbox"
                                  checked={isSelected(image, item.tcin)}
                                  color="primary"
                                  onClick={(event) =>
                                    handleClick(event, image, item.tcin)
                                  }
                                  disabled={image?.reuse_image_expired}
                                />
                              </TableCell>
                            ) : null}
                            <TableCell padding="default">{item.tcin}</TableCell>
                            <TableCell
                              padding="default"
                              dangerouslySetInnerHTML={escapeHtml(
                                !isNil(item.item_info.title)
                                  ? item.item_info.title
                                  : 'No Title Found'
                              )}
                            />
                            <TableCell padding="default">
                              {image.requested_position === 0
                                ? 'Primary'
                                : `Alt ${image.requested_position}`}
                            </TableCell>
                            <TableCell padding="default">
                              <img
                                src={image.publish_url + '&hei=150&wei=150'}
                                alt={`${item.tcin}-${image.image_id}`}
                              />
                            </TableCell>
                            <TableCell padding="default">
                              {!isNil(image.upload_date) && (
                                <Moment format={'YYYY-MM-DD h:mm a'}>
                                  {image.upload_date}
                                </Moment>
                              )}
                            </TableCell>
                            <TableCell padding="default">
                              {image.vendor_name}
                              <br />
                              {image.vendor_email}
                            </TableCell>
                            <TableCell padding="default">
                              {buildApprovalStatus(
                                image.ids_review,
                                image.user_status_message,
                                image.rejection_reason
                              )}
                            </TableCell>
                            {/* <TableCell padding="default">
                                { (!isNil(item.launch_date) &&
                                <Moment format={'YYYY-MM-DD'}>
                                  {item.launch_date}
                                </Moment>) || 'N/A' }
                              </TableCell> */}
                            {/* item vendor placeholder */}
                            {/* <TableCell padding="default">
                                {first(item.relationship_type_codes)}
                              </TableCell> */}
                            <TableCell padding="default">
                              {image?.reuse_image_expired
                                ? 'Expired'
                                : 'Active'}
                            </TableCell>
                          </TableRow>
                        ))
                    )}
                    <TableRow className={classes.height49}>
                      <TableCell colSpan={11} />
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </React.Fragment>
          )}
          {!some(reviewHistoryData) && !isFetching && (
            <div className={classes.noResult}>No Review History Found</div>
          )}
          <ImageToolbar
            numSelected={numSelected}
            selectedTcins={selectedTcins}
            enabledActions={[
              <ApproveImageAction toggleActionDrawer={toggleActionDrawer} />,
              <SendToRetouchImageAction
                toggleActionDrawer={toggleActionDrawer}
              />,
              <RejectImageAction toggleActionDrawer={toggleActionDrawer} />,
            ]}
          />
          <ImageToolbarActionDrawerContainer
            numSelected={numSelected}
            selectedTcins={selectedTcins}
            isReprocess
          />
        </React.Fragment>
      </Paper>
      <div className={classes.toolbarSpacer} />
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestImageReviewHistoryData,
      clearReviewHistoryData,
      toggleActionDrawer,
      handleSelectImage,
      getImageReviewHistoryStrapiData,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, imageReviewHistory } = state
  const {
    reviewHistoryData,
    isFetching,
    selectedReviewHistoryImages,
    showDefaultAlert,
    alert,
    information,
    faq,
  } = imageReviewHistory
  return {
    reviewHistoryData,
    selectedReviewHistoryImages,
    isFetching,
    auth,
    showDefaultAlert,
    alert,
    information,
    faq,
  }
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(ImageReviewHistory)))
