const styles = (theme) => ({
  header: {
    height: '56px',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: '48px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '64px',
    },
  },
  appBar: {
    backgroundColor: '#CC0000',
  },
  marginLeftMedium: {
    marginLeft: theme.spacing(2),
  },
  headerTab: {
    display: 'contents',
  },
  button: {
    color: theme.palette.primary.contrastText,
  },
})

export default styles
