import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setTagStepperActiveStep } from '../../../../../../store/images/tagTraining/actionCreator'
import CreateNewTagStepper from './CreateNewTagStepper'

class CreateNewTagStepperContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    const { createNewTag } = this.props
    return (
      <React.Fragment>
        <CreateNewTagStepper
          createNewTagOverall={createNewTag.overall}
          handleNext={() =>
            this.props.setTagStepperActiveStep(
              createNewTag.overall.activeStep + 1
            )
          }
          handleBack={() =>
            this.props.setTagStepperActiveStep(
              createNewTag.overall.activeStep - 1
            )
          }
        />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTagStepperActiveStep,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { createNewTag } = imageTagTraining
  return {
    auth,
    createNewTag,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewTagStepperContainer)
