import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import TcinCard from './TcinCard'
import {
  editModeEnabled,
  editModeSetTitle,
  addVideoGroupStatus,
  clearRequestedVideoData,
  approveVideoGroup,
  setShowRejectButtons,
  resetRejectionMessages,
  setShowVideoActiveCheckbox,
  setVideoActive,
  handleSelectVideoTcin,
  showStartOverConfirmation,
  requestVideoDataById,
} from '../../../../../store/videos/upload/actionCreator'
import { withRouter } from '../../../../Shared/WithRouterHoc'
import firefly from '../../../../../analytics/firefly'
import { isEmpty } from 'lodash'
import { uploadStart } from '../../../../../store/upload/actionCreator'
import { successVendorTcinMapping } from '../../../../../store/auth/actionCreator'
import {
  VIDEO_ASSETS,
  VIDEO_APPROVAL_STATUS,
  VIDEO_EDIT_MODE,
  TOAST_BODY,
} from '../../../../Shared/Constants'
import { toast } from 'react-toastify'

export function TcinCardContainer(props) {
  const customInteractionValue = () => {
    if (props.posterFrame.length === 0 && props.transcript.length === 0) {
      return 'Video, VTT'
    }
    if (props.posterFrame.length > 0 && props.transcript.length === 0) {
      return 'Video, VTT, Poster Frame'
    }
    if (props.posterFrame.length === 0 && props.transcript.length > 0) {
      return 'Video, VTT, Media Alternative'
    }
    if (props.posterFrame.length > 0 && props.transcript.length > 0) {
      return 'Video, VTT, Poster Frame, Media Alternative'
    }
  }

  const sendToTarget = () => {
    let userId = props.auth.email ? props.auth.email : props.auth.lanId
    let externalGroupJobId =
      props.externalGroupJobId === null ? null : props.externalGroupJobId
    let saveRequestBody = {
      type: VIDEO_ASSETS.VIDEO.TYPE,
      provided_tcin_sequences: [],
      title: props.editModeTitle,
      source: 'vendor',
      user_info: {
        email_id: userId,
        vendor_name: userId.split('@')[1].split('.')[0],
      },
      external_job_requests: [],
    }
    calculateAllTcins(props.selectedTcins).forEach((tcin) => {
      saveRequestBody.provided_tcin_sequences.push({
        tcin: tcin,
        sequence: '1',
      })
    })
    if (
      props.videoGroupStatus === VIDEO_APPROVAL_STATUS.REJECTED &&
      isEmpty(props.deleteAsset)
    ) {
      props.resetRejectionMessages()
      props.setShowVideoActiveCheckbox(false)
      props.setShowRejectButtons(false)
      props.requestVideoDataById(externalGroupJobId)
      //props.history.push(`/v2/video/videoEdit/${externalGroupJobId}`)
      props.router.navigate({
        pathname: `/v2/video/videoEdit/${externalGroupJobId}`,
      })
      props.editModeEnabled(VIDEO_EDIT_MODE.HISTORY)
      toast.error('Error with upload, try again', TOAST_BODY)
    } else {
      props.uploadStart(
        userId,
        props.validFiles,
        VIDEO_ASSETS.VIDEO.id,
        saveRequestBody,
        externalGroupJobId,
        props.deleteAsset
      )
      firefly.trackClick('Video Upload Start', {
        location: window.location.pathname,
        key: 'Files Uploaded: ',
        value: customInteractionValue(),
      })
    }
  }

  const calculateAllTcins = (selectedTcins) => {
    const allTcins = []
    selectedTcins.forEach((selectedTcin) => {
      allTcins.push(selectedTcin.tcin)
      if (selectedTcin.child_tcins) {
        selectedTcin.child_tcins.forEach((childTcin) => {
          allTcins.push(childTcin)
        })
      }
    })
    return allTcins
  }

  const newVideoUpload = () => {
    // check each video asset type, if it exists, remove it
    if (!isEmpty(props.video)) {
      props.removeFile(VIDEO_ASSETS.VIDEO.type)
    }
    if (!isEmpty(props.closedCaption)) {
      props.removeFile(VIDEO_ASSETS.CLOSED_CAPTION.type)
    }
    if (!isEmpty(props.posterFrame)) {
      props.removeFile(VIDEO_ASSETS.POSTER_FRAME.type)
    }
    if (!isEmpty(props.transcript)) {
      props.removeFile(VIDEO_ASSETS.MEDIA_ALTERNATIVE.type)
    }

    props.clearRequestedVideoData()
    props.editModeEnabled(VIDEO_EDIT_MODE.UPLOAD)
    props.handleSelectVideoTcin({ selectedTcins: [] })
    props.editModeSetTitle('')
    props.setShowVideoActiveCheckbox(false)
    props.setShowRejectButtons(false)
    props.setVideoActive(false)
    props.resetRejectionMessages()
    props.addVideoGroupStatus(null)
    //props.history.push('/v2/video/videoEdit/newUpload')
    props.router.navigate({ pathname: '/v2/video/videoEdit/newUpload' })
    props.successVendorTcinMapping({
      validTcins: null,
      invalidTcins: null,
      unauthorizedTcins: null,
    })
    props.showStartOverConfirmation(false)
  }

  const approveVideoGroup = () => {
    const video =
      props.requestedVideoData.map_child_assets === undefined ||
      props.requestedVideoData.map_child_assets.video === undefined ||
      props.requestedVideoData.map_child_assets.video.length === 0
        ? {}
        : props.requestedVideoData.map_child_assets.video[0]
    const closedCaption =
      props.requestedVideoData.map_child_assets === undefined ||
      props.requestedVideoData.map_child_assets.closedcaption === undefined ||
      props.requestedVideoData.map_child_assets.closedcaption.length === 0
        ? {}
        : props.requestedVideoData.map_child_assets.closedcaption[0]
    const posterFrame =
      props.requestedVideoData.map_child_assets === undefined ||
      props.requestedVideoData.map_child_assets.posterframe === undefined ||
      props.requestedVideoData.map_child_assets.posterframe.length === 0
        ? {}
        : props.requestedVideoData.map_child_assets.posterframe[0]
    const transcript =
      props.requestedVideoData.map_child_assets === undefined ||
      props.requestedVideoData.map_child_assets.transcript === undefined ||
      props.requestedVideoData.map_child_assets.transcript.length === 0
        ? {}
        : props.requestedVideoData.map_child_assets.transcript[0]
    const jsonRejectionMessages = JSON.stringify({
      video_reject:
        props.rejectionMessages.video_reject === null
          ? ''
          : props.rejectionMessages.video_reject,
      closed_caption_reject:
        props.rejectionMessages.closed_caption_reject === null
          ? ''
          : props.rejectionMessages.closed_caption_reject,
      poster_frame_reject:
        props.rejectionMessages.poster_frame_reject === null
          ? ''
          : props.rejectionMessages.poster_frame_reject,
      transcript_reject:
        props.rejectionMessages.transcript_reject === null
          ? ''
          : props.rejectionMessages.transcript_reject,
    })

    const videoGroup = {
      external_group_job_id: props.requestedVideoData.external_group_job_id,
      title: props.editModeTitle,
      active: props.videoIsActive,
      applied_tcins_set: calculateAllTcins(props.selectedTcins),
      map_child_assets: {
        video: isEmpty(video)
          ? []
          : [
              {
                job_id: video.job_id,
                approval_status: rejectVideoGroup
                  ? props.rejectionMessages.video_reject === null
                    ? video.approval_status
                    : VIDEO_APPROVAL_STATUS.REJECTED
                  : VIDEO_APPROVAL_STATUS.APPROVED,
                reject_reason: props.rejectionMessages.video_reject,
              },
            ],
        closedcaption: isEmpty(closedCaption)
          ? []
          : [
              {
                job_id: closedCaption.job_id,
                approval_status: rejectVideoGroup
                  ? props.rejectionMessages.closed_caption_reject === null
                    ? closedCaption.approval_status
                    : VIDEO_APPROVAL_STATUS.REJECTED
                  : VIDEO_APPROVAL_STATUS.APPROVED,
                reject_reason: props.rejectionMessages.closed_caption_reject,
              },
            ],
        posterframe: isEmpty(posterFrame)
          ? []
          : [
              {
                job_id: posterFrame.job_id,
                approval_status: rejectVideoGroup
                  ? props.rejectionMessages.poster_frame_reject === null
                    ? posterFrame.approval_status
                    : VIDEO_APPROVAL_STATUS.REJECTED
                  : VIDEO_APPROVAL_STATUS.APPROVED,
                reject_reason: props.rejectionMessages.poster_frame_reject,
              },
            ],
        transcript: isEmpty(transcript)
          ? []
          : [
              {
                job_id: transcript.job_id,
                approval_status: rejectVideoGroup
                  ? props.rejectionMessages.transcript_reject === null
                    ? transcript.approval_status
                    : VIDEO_APPROVAL_STATUS.REJECTED
                  : VIDEO_APPROVAL_STATUS.APPROVED,
                reject_reason: props.rejectionMessages.transcript_reject,
              },
            ],
      },
      reject_reason: rejectVideoGroup ? jsonRejectionMessages : null,
      last_updated_by: props.auth.email ? props.auth.email : props.auth.lanId,
    }
    const Email = props.auth.email
    props.approveVideoGroup(videoGroup, Email)
    props.resetRejectionMessages()
    //props.history.push('/v2/video/review')
    props.router.navigate({ pathname: '/v2/video/review' })
  }

  let rejectVideoGroup =
    props.rejectionMessages.video_reject !== null ||
    props.rejectionMessages.closed_caption_reject !== null ||
    props.rejectionMessages.poster_frame_reject !== null ||
    props.rejectionMessages.transcript_reject !== null

  return (
    <TcinCard
      invalidTcinList={props.invalidTcinList}
      notOwnedTcinList={props.notOwnedTcinList}
      editModeTitle={props.editModeTitle}
      posterFrame={props.posterFrame}
      transcript={props.transcript}
      closedCaption={props.closedCaption}
      video={props.video}
      videoGroupStatus={props.videoGroupStatus}
      videoFileAdded={props.videoFileAdded}
      ccFileAdded={props.ccFileAdded}
      sendToTarget={sendToTarget}
      newVideoUpload={newVideoUpload}
      selectedTcins={props.selectedTcins}
      approveVideoGroup={approveVideoGroup}
      editModeSetTitle={props.editModeSetTitle}
      rejectVideoGroup={rejectVideoGroup}
      requestedVideoData={props.requestedVideoData}
      setVideoActive={props.setVideoActive}
      videoIsActive={props.videoIsActive}
      showVideoActiveCheckbox={props.showVideoActiveCheckbox}
      showRejectButtons={props.showRejectButtons}
      editMode={props.editMode}
      openStartOverModal={props.openStartOverModal}
      showStartOverConfirmation={props.showStartOverConfirmation}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleSelectVideoTcin,
      editModeEnabled,
      editModeSetTitle,
      uploadStart,
      clearRequestedVideoData,
      approveVideoGroup,
      resetRejectionMessages,
      setVideoActive,
      setShowVideoActiveCheckbox,
      setShowRejectButtons,
      addVideoGroupStatus,
      successVendorTcinMapping,
      showStartOverConfirmation,
      requestVideoDataById,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, videoUpload } = state
  const {
    invalidTcinList,
    notOwnedTcinList,
    editModeTitle,
    videoGroupStatus,
    videoFileAdded,
    ccFileAdded,
    externalGroupJobId,
    deleteAsset,
    requestedVideoData,
    selectedTcins,
    showRejectButtons,
    rejectionMessages,
    showVideoActiveCheckbox,
    videoIsActive,
    isFetching,
    editMode,
    openStartOverModal,
  } = videoUpload
  return {
    auth,
    invalidTcinList,
    notOwnedTcinList,
    editModeTitle,
    videoGroupStatus,
    videoFileAdded,
    ccFileAdded,
    externalGroupJobId,
    deleteAsset,
    requestedVideoData,
    selectedTcins,
    rejectionMessages,
    videoIsActive,
    showVideoActiveCheckbox,
    showRejectButtons,
    isFetching,
    editMode,
    openStartOverModal,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TcinCardContainer))
