/* eslint-disable jsx-a11y/alt-text */
import { withStyles } from '@material-ui/core/styles'
import { TableCell, TableRow, Checkbox } from '@material-ui/core'

import ZoomInIcon from '@material-ui/icons/ZoomIn'
import styles from '../theme'
import { gridCheckedLogic } from '../../Shared/SharedUtils'

import { urlProtocol } from '../../Shared/Constants'
import { isNil } from 'lodash'

function AltTextSearchResult(props) {
  const {
    itemData = {},
    isSelected = () => {},
    setLightboxUrl = () => {},
    classes,
    selectItem = () => {},
    selectedItems = [],
  } = props

  const handleClick = (_, checkedItem) => {
    selectItem({
      selectedItems: gridCheckedLogic(selectedItems, checkedItem),
    })
  }

  const image = {
    publish_url: itemData?.alt_text?.publish_url,
    base_url: itemData?.alt_text?.base_url,
  }

  const urlBuilderAltText = (image) => {
    return !isNil(image) ? image.base_url + image.publish_url : ''
  }

  const assetUrl = urlProtocol + urlBuilderAltText(image)

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={itemData.tcin}
      selected={isSelected}
      className={
        !itemData?.alt_text?.alt_text_active ? classes.disableRowStyle : ''
      }
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          color="primary"
          onClick={(event) => handleClick(event, itemData)}
          disabled={!itemData?.alt_text?.alt_text_active}
        />
      </TableCell>
      <TableCell padding="default">{itemData.tcin}</TableCell>
      <TableCell padding="default" data-test-id="relationship-cell">
        {itemData?.alt_text?.active ? 'Active' : 'Disabled'}
      </TableCell>
      <TableCell padding="default" data-test-id="swatch-cell">
        <a href={assetUrl} target="_blank" rel="noreferrer">
          Img Url
        </a>
      </TableCell>
      <TableCell padding="default" data-test-id="primary-cell">
        <div className={`${classes.alignCenterFrame}`}>
          <img
            role="presentation"
            src={assetUrl}
            width={'60px'}
            height={'60px'}
          />
          <ZoomInIcon
            color="primary"
            onClick={() => {
              setLightboxUrl(assetUrl)
            }}
          />
        </div>
      </TableCell>
      <TableCell padding="default" data-test-id="primary-cell">
        {itemData?.alt_text?.suffix}
      </TableCell>
      <TableCell padding="default" data-test-id="primary-cell">
        {itemData?.alt_text?.alt_text_des}
      </TableCell>

      <TableCell padding="default" data-test-id="primary-cell">
        {itemData?.alt_text?.alt_text_active ? 'Active' : 'Disabled'}
      </TableCell>
    </TableRow>
  )
}

// @ts-ignore
export default withStyles(styles)(AltTextSearchResult)
