/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Toolbar,
  Tooltip,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Checkbox,
  TableSortLabel,
  TablePagination,
  Grid,
  IconButton,
  Input,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import CloseIcon from '@material-ui/icons/Close'
import LaunchIcon from '@material-ui/icons/Launch'
import ImageIcon from '@material-ui/icons/Image'
// @ts-ignore
import NoThumbnail from '../../../../images/NoThumbnail.svg?url'
import { urlProtocol } from '../../../Shared/Constants'
import {
  gridCheckedLogic,
  urlBuilder,
  escapeHtml,
} from '../../../Shared/SharedUtils'
import { isNil } from 'lodash'

function RelatedItemsModal(props) {
  const {
    classes = {},
    open = false,
    toggleRelatedItemsModal = () => {},
    relatedItems = [],
    relatedTcin = '',
    manageTcins = () => {},
  } = props

  const [orderBy, changeOrderBy] = useState('itemType')
  const [order, changeOrder] = useState('asc')
  const [page, changePage] = useState(0)
  const [rowsPerPage, changeRowsPerPage] = useState(5)
  const [selectedItems, setSelectedItems] = useState([])
  const [enteredFilter, enterFilter] = useState('')
  const [filteredRelatedItems, filterRelatedItems] = useState([])

  useEffect(() => {
    filterRelatedItems(relatedItems)
  }, [])

  useEffect(() => {
    filterRelatedItems(relatedItems)
  }, [enteredFilter === ''])

  let numberRelated = filteredRelatedItems.length

  const numSelected = selectedItems.length

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedItems(
        filteredRelatedItems.map((relatedItem) => relatedItem.tcin)
      )
      return
    }
    setSelectedItems([])
  }

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getSorting = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, numberRelated - page * rowsPerPage)

  const handleRequestSort = (property) => {
    if (orderBy === property && order === 'desc') {
      changeOrder('asc')
    } else if (orderBy !== property) {
      changeOrderBy(property)
      changeOrder('asc')
    } else {
      changeOrder('desc')
    }
  }

  const findIsSelected = (id) => selectedItems.indexOf(id) !== -1

  const handleClick = (_, id) => {
    setSelectedItems(gridCheckedLogic(selectedItems, id))
  }

  const handleChangePage = (_, page) => {
    changePage(page)
  }

  const handleChangeRowsPerPage = (event) => {
    changePage(0)
    changeRowsPerPage(event.target.value)
  }

  const handleSearch = (searchInput) => {
    enterFilter(searchInput)
    const filteredData = filteredRelatedItems.filter((value) => {
      const searchStr = searchInput.toLowerCase()
      const tcinMatches = value.tcin.toLowerCase().includes(searchStr)
      const itemTypeMatches = value.itemType.toLowerCase().includes(searchStr)
      const dpciMatches = value.dpci.toLowerCase().includes(searchStr)
      const colorMatches = value.color.toLowerCase().includes(searchStr)
      const sizeMatches = value.size.toLowerCase().includes(searchStr)
      const titleMatches = value.title.toLowerCase().includes(searchStr)

      return (
        tcinMatches ||
        itemTypeMatches ||
        dpciMatches ||
        colorMatches ||
        sizeMatches ||
        titleMatches
      )
    })
    filterRelatedItems(filteredData)
  }

  return (
    <Dialog
      open={open}
      onClose={() => toggleRelatedItemsModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
      classes={{ paper: classes.minHeight45Per }}
    >
      <Toolbar className={classes.modalToolbar}>
        <div className={classes.title}>
          <Typography color="inherit">{numSelected} selected</Typography>
        </div>
        <div className={classes.actions}>
          <Button
            className={classes.drawerButton}
            // @ts-ignore
            onClick={() => {
              navigator.clipboard.writeText(selectedItems.join('\n'))
            }}
            disabled={numSelected === 0}
          >
            <GetAppIcon />
            <span className={classes.marginLeft5}>
              Copy TCIN(s) to clipboard
            </span>
          </Button>
          <Button
            className={classes.drawerButton}
            onClick={() => manageTcins(selectedItems)}
            disabled={numSelected === 0}
          >
            <LaunchIcon />
            <span className={classes.marginLeft5}>Manage TCIN(s)</span>
          </Button>
        </div>
      </Toolbar>
      <DialogContent className={classes.noOverflow}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            {numberRelated} Relationships for {relatedTcin}
          </Grid>
          <Grid item>
            <Input
              className={classes.input}
              placeholder="Filter Items"
              onChange={(e) => handleSearch(e.target.value)}
              value={enteredFilter}
              endAdornment={
                enteredFilter === '' ? null : (
                  <IconButton
                    onClick={() => {
                      enterFilter('')
                      filterRelatedItems(relatedItems)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item className={classes.horizontalFlex}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={numberRelated}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <IconButton onClick={() => toggleRelatedItemsModal(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < numberRelated}
                  checked={numSelected === numberRelated}
                  onChange={handleSelectAllClick}
                  color="primary"
                />
              </TableCell>
              <TableCell
                key={'tcin'}
                align="left"
                padding="default"
                // @ts-ignore
                sortDirection={orderBy === 'tcin' ? order : false}
              >
                <Tooltip title="Click to sort">
                  <TableSortLabel
                    active={orderBy === 'tcin'}
                    // @ts-ignore
                    direction={order}
                    onClick={() => handleRequestSort('tcin')}
                  >
                    TCIN
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                key={'itemType'}
                align="left"
                padding="default"
                // @ts-ignore
                sortDirection={orderBy === 'itemType' ? order : false}
              >
                <Tooltip title="Click to sort">
                  <TableSortLabel
                    active={orderBy === 'itemType'}
                    // @ts-ignore
                    direction={order}
                    onClick={() => handleRequestSort('itemType')}
                  >
                    Type
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell key={'primary'} align="left" padding="default">
                Primary
              </TableCell>
              <TableCell
                key={'dpci'}
                align="left"
                padding="default"
                // @ts-ignore
                sortDirection={orderBy === 'dpci' ? order : false}
              >
                <Tooltip title="Click to sort">
                  <TableSortLabel
                    active={orderBy === 'dpci'}
                    // @ts-ignore
                    direction={order}
                    onClick={() => handleRequestSort('dpci')}
                  >
                    DPCI
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                key={'color'}
                align="left"
                padding="default"
                // @ts-ignore
                sortDirection={orderBy === 'color' ? order : false}
              >
                <Tooltip title="Click to sort">
                  <TableSortLabel
                    active={orderBy === 'color'}
                    // @ts-ignore
                    direction={order}
                    onClick={() => handleRequestSort('color')}
                  >
                    Color
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                key={'size'}
                align="left"
                padding="default"
                // @ts-ignore
                sortDirection={orderBy === 'size' ? order : false}
              >
                <Tooltip title="Click to sort">
                  <TableSortLabel
                    active={orderBy === 'size'}
                    // @ts-ignore
                    direction={order}
                    onClick={() => handleRequestSort('size')}
                  >
                    Size
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                key={'title'}
                align="left"
                padding="default"
                // @ts-ignore
                sortDirection={orderBy === 'title' ? order : false}
              >
                <Tooltip title="Click to sort">
                  <TableSortLabel
                    active={orderBy === 'title'}
                    // @ts-ignore
                    direction={order}
                    onClick={() => handleRequestSort('title')}
                  >
                    Product Title
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredRelatedItems, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((relatedItem) => {
                const isSelected = findIsSelected(relatedItem.tcin)
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, relatedItem.tcin)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={relatedItem.tcin}
                    selected={isSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isSelected} color="primary" />
                    </TableCell>
                    <TableCell data-test-id="tcin-cell">
                      {relatedItem.tcin}
                    </TableCell>
                    <TableCell data-test-id="item-type-cell">
                      {relatedItem.itemType}
                    </TableCell>
                    <TableCell data-test-id="primary-image-cell">
                      {!isNil(relatedItem.baseUrl) &&
                      !isNil(relatedItem.publishUrl) ? (
                        <img
                          className={classes.maxHeight40}
                          alt={relatedItem.tcin}
                          src={
                            urlProtocol +
                            urlBuilder(relatedItem) +
                            '?hei=40&wei=40'
                          }
                          // @ts-ignore
                          onError={(e) => {
                            e.target.src = NoThumbnail
                          }}
                        />
                      ) : (
                        <Tooltip title="No Primary Image">
                          <ImageIcon />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell data-test-id="dpci-cell">
                      {relatedItem.dpci}
                    </TableCell>
                    <TableCell data-test-id="color-cell">
                      {relatedItem.color}
                    </TableCell>
                    <TableCell data-test-id="size-cell">
                      {relatedItem.size}
                    </TableCell>
                    <TableCell
                      data-test-id="title-cell"
                      dangerouslySetInnerHTML={escapeHtml(relatedItem.title)}
                    />
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}
export default withStyles(styles)(RelatedItemsModal)
