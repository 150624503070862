import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Button, Grid } from '@material-ui/core'
import { Help, CloudUpload } from '@material-ui/icons'
import DropZone from '../../../../Shared/DropZone/DropZone'
import { VIDEO_ASSETS } from '../../../../Shared/Constants'

function UploadVideo(props) {
  const {
    onDrop = () => {},
    onDragEnter = () => {},
    onDragLeave = () => {},
    dropZoneEnter = false,
    classes = {},
    fileType = '',
    requiredType = '' || null,
    editVttDialogBoxEmpty = () => {},
    openHelpContent = () => {},
    disabled = false,
  } = props

  const dropZoneRef = React.createRef()

  return (
    <div>
      <Grid container className={classes.videoTitle}>
        <Grid item container xs={10}>
          <Grid item xs={12} className={classes.videoFileTypeTitle}>
            <React.Fragment>
              {fileType}
              {requiredType === 'Required'}
            </React.Fragment>
          </Grid>
          <Grid
            item
            xs={12}
            className={
              requiredType === 'Required'
                ? classes.requiredTypeRequired
                : classes.requiredTypeOptional
            }
          >
            {requiredType}
          </Grid>
        </Grid>
        <Grid item xs={1} container alignItems="center">
          <Help
            onClick={() => openHelpContent(fileType)}
            className={classes.helpLink}
          />
        </Grid>
      </Grid>
      <DropZone
        onDrop={(files) => onDrop(fileType, files)}
        dropZoneEnter={dropZoneEnter}
        onDragEnter={() => onDragEnter(fileType)}
        onDragLeave={onDragLeave}
        disableClick
        dropZoneRef={dropZoneRef}
        requiredType={requiredType}
        dropZoneActiveStyle={classes.dropZoneActive}
        dropZoneNotActiveStyle={classes.dropZoneNotActive}
        disabled={disabled}
        DropZoneContent={
          <React.Fragment>
            <Grid justify="center" container alignItems="center" item xs={12}>
              <CloudUpload className={classes.videoUploadIcon} />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.DropZoneSmallText}>
                No {fileType} Added
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.DropZoneSmallText}>
                <hr className={classes.lineSeperator} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.DropZoneSmallText}>
                Drag & Drop File Here{' '}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.DropZoneSmallText}> or</div>
            </Grid>
            <Grid justify="center" container alignItems="center" item xs={12}>
              <Button
                role="presentation"
                onClick={() => dropZoneRef.current.open()}
                className={classes.marginTop10}
                variant="contained"
                color="primary"
                disabled={disabled}
              >
                <span className={classes.browseButtonText}>browse</span>
              </Button>
              {fileType === VIDEO_ASSETS.CLOSED_CAPTION.type && (
                <span className={classes.paddingSide15}> or </span>
              )}
              {fileType === VIDEO_ASSETS.CLOSED_CAPTION.type && (
                <Button
                  onClick={editVttDialogBoxEmpty}
                  className={classes.marginTop10}
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                >
                  <span className={classes.browseButtonText}>Create New</span>
                </Button>
              )}
            </Grid>
          </React.Fragment>
        }
      />
    </div>
  )
}

export default withStyles(styles)(UploadVideo)
