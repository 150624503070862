import React, { useEffect, useState } from 'react'
import styles from '../theme'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

function RuleOrTcinPopUp(props) {
  const {
    classes = {},
    popUpFlag,
    createByRuleOrTcin,
    cancelCreateByRuleOrTcin,
    createByTcinOrRule,
  } = props

  const [seletedRadioButton, SetSeletedRadioButton] = useState('')

  useEffect(() => {
    SetSeletedRadioButton('')
  }, [popUpFlag])
  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted
        maxWidth="md"
        open={popUpFlag}
        aria-labelledby="alert-dialog-image-label"
        aria-describedby="alert-dialog-imageLable-description"
      >
        <DialogTitle
          className={classes.saveFilterTitle}
          id="alert-dialog-image-label"
        >
          <div data-test-id="size-chart-tcin-popup">
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.saveTitleText}
            >
              How would you like to add items to your size chart?
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={seletedRadioButton}
              onChange={(e) => {
                SetSeletedRadioButton(e.target.value)
                createByTcinOrRule(e.target.value)
              }}
            >
              <FormControlLabel value="Rule" control={<Radio />} label="Rule" />
              <FormControlLabel
                value="Tcin List"
                control={<Radio />}
                label="Tcin List"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => cancelCreateByRuleOrTcin()}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => createByRuleOrTcin()}
            disabled={seletedRadioButton === ''}
          >
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default withStyles(styles)(RuleOrTcinPopUp)
