const styles = (theme) => ({
  linkStyle: {
    margin: '0 5px',
    textDecoration: 'none',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  marginTopBottom10: {
    margin: '10px 0',
  },
  copyDataroot: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    minWidth: '1020px',
  },
  noResult: {
    textAlign: 'center',
    margin: '20px',
  },
  tagInput: {
    marginLeft: '20px',
    paddingTop: '50px',
  },
  cellStyle: {
    maxWidth: '300px',
    overflowWrap: 'break-word',
  },
  saveCell: {
    maxWidth: '80px',
    paddingLeft: '30px',
    paddingRight: '20px',
  },
  infoTitle: {
    fontWeight: 500,
    fontSize: '15px',
  },
})

export default styles
