import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Paper, Button } from '@material-ui/core'
import styles from '../theme'
import { toggleHowToMeasure } from '../../../store/sizeChart/sizeChartEdit/sizeChartDataActions'
import { getHowToMeasure } from '../../../store/sizeChart/sizeChartEdit/sizeChartHTTPActions'

class HowToMeasure extends React.Component {
  componentDidMount() {
    this.props.getHowToMeasure(this.props.category)
  }
  render() {
    const {
      classes,
      useMeasurementGuide,
      howToMeasure,
      category,
      brand,
      size,
    } = this.props

    return (
      <div>
        <link
          type="text/css"
          href="https://digitalcontent.target.com/itemcontent/sizecharts/css/modified-size-charts_minified.css"
          rel="stylesheet"
        />
        <link
          type="text/css"
          href="https://digitalcontent.target.com/itemcontent/sizecharts/css/tgt_sizechart_htm.css"
          rel="stylesheet"
        />
        <Paper className={classes.howToMeasureContainer}>
          {!howToMeasure ? (
            <div className={classes.noHowToMeasureFound}>
              <div>
                There is no How To Measure information for your current Size
                Chart Identifiers.
              </div>
              <br />
              <div>
                {category} ---> {brand} ---> {size}
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className={classes.howToMeasureToggleWrapper}>
                <Button
                  onClick={() => this.props.toggleHowToMeasure()}
                  className={classes.toggleHowToMeasureButton}
                  variant="contained"
                  color="primary"
                >
                  {useMeasurementGuide ? 'Disable' : 'Enable'} How To Measure
                </Button>
              </div>
              <div
                id="how to measure"
                dangerouslySetInnerHTML={
                  !useMeasurementGuide
                    ? { __html: '' }
                    : { __html: howToMeasure }
                }
                className={classes.howToMeasure}
              />
            </React.Fragment>
          )}
        </Paper>
      </div>
    )
  }
}

HowToMeasure.defaultProps = {
  category: '',
  brand: '',
  size: '',
}

export const mapStateToProps = (state) => {
  const { sizeChartEdit } = state
  const { sizeChart, useMeasurementGuide, howToMeasure } = sizeChartEdit
  return {
    category: sizeChart.category,
    brand: sizeChart.brand,
    size: sizeChart.size,
    useMeasurementGuide,
    howToMeasure,
  }
}

export function mapDispatchToProps(dispatch) {
  // these are all the functions which will change what the page looks like
  // in a stateless component, all of these actions SHOULD be handled in props
  return {
    toggleHowToMeasure: () => dispatch(toggleHowToMeasure()),
    getHowToMeasure: (category) => dispatch(getHowToMeasure(category)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HowToMeasure))
