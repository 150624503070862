import { TableHead, TableRow, TableCell, Checkbox } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'

const columnData = [
  { id: 'tcin', numeric: false, disablePadding: true, label: 'TCIN' },
  {
    id: 'Image Url',
    numeric: false,
    disablePadding: true,
    label: 'Image Url',
  },
  {
    id: 'Image Preview',
    numeric: false,
    disablePadding: true,
    label: 'Image Preview',
  },
  {
    id: 'Alt Text',
    numeric: false,
    disablePadding: false,
    label: 'Alt Text',
  },
  { id: 'delete', numeric: false, disablePadding: false, label: 'Delete' },
]

function AltTextTableHead(props) {
  const { handleSelectAllClick, numSelected, rowCount } = props
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={handleSelectAllClick}
            color="primary"
          />
        </TableCell>
        {columnData.map((column) => {
          return (
            <TableCell
              key={column.id}
              padding={column.disablePadding ? 'none' : 'default'}
            >
              {column.label}
            </TableCell>
          )
        }, this)}
      </TableRow>
    </TableHead>
  )
}

export default withStyles(styles)(AltTextTableHead)
