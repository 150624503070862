import TcinCardTable from './TcinCardTable'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  handleSelectVideoTcin,
  addTcinToVideoGroup,
} from '../../../../../store/videos/upload/actionCreator'
import { withRouter } from '../../../../Shared/WithRouterHoc'
import { gridCheckedLogic } from '../../../../Shared/SharedUtils'
import { vendorTcinMapping } from '../../../../../store/auth/actionCreator'

function TcinCardTableContainer(props) {
  const handleSelectAllClick = (_, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = props.requestedVideoData.lst_group_item_data
    }
    props.handleSelectVideoTcin({
      selectedTcins: newSelected,
    })
  }

  const handleClick = (_, checkedTcin) => {
    const { selectedTcins } = props

    props.handleSelectVideoTcin({
      selectedTcins: gridCheckedLogic(selectedTcins, checkedTcin),
    })
  }

  const isSelected = (checkedTcin) =>
    props.selectedTcins.indexOf(checkedTcin) !== -1

  const addTcinToVideoGroup = (newList, existingList) => {
    let newTcins = newList.map((tcin) => tcin.value)
    let existingTcins = existingList.map((tcin) => tcin.tcin)
    // if (props.permission.vendorTcinMap) {
    //   props.vendorTcinMapping(newTcins, props.email)
    //   if (!props.isFetchingAuth && props.validTcins && props.validTcins.length > 0) {
    //     props.addTcinToVideoGroup(props.validTcins, existingTcins)
    //   }
    // } else {
    props.addTcinToVideoGroup(newTcins, existingTcins, props.auth)
    // }
  }

  const {
    requestedVideoData,
    selectedTcins,
    editMode,
    // isFetchingAuth,
  } = props

  return (
    <TcinCardTable
      requestedVideoData={requestedVideoData}
      selectedTcins={selectedTcins}
      handleSelectAllClick={handleSelectAllClick}
      isSelected={isSelected}
      handleClick={handleClick}
      addTcinToVideoGroup={addTcinToVideoGroup}
      editMode={editMode}
      // isFetchingAuth={isFetchingAuth}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleSelectVideoTcin,
      addTcinToVideoGroup,
      vendorTcinMapping,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, videoUpload } = state
  // const {
  //   email,
  //   isFetchingAuth,
  //   permission,
  //   validTcins,
  // } = auth
  const { selectedTcins, requestedVideoData, editMode } = videoUpload
  return {
    auth,
    // email,
    // isFetchingAuth,
    // permission,
    // validTcins,
    requestedVideoData,
    selectedTcins,
    editMode,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TcinCardTableContainer))
