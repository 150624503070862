import { RANDOM_IMAGE_GET_SUCCESS, IMAGE_TAGS_RESET_FETCH } from './actionType'

const initState = {
  currentImageSignature: null,
  currentImageToLabel: null,
  isFetching: false,
}

export default function imageTagReducer(state = initState, action = {}) {
  switch (action.type) {
    case RANDOM_IMAGE_GET_SUCCESS: {
      const { payload } = action
      const newState = {
        ...state,
        currentImageToLabel: payload.imageUrl,
        currentImageSignature: payload.signature,
      }
      return newState
    }
    case IMAGE_TAGS_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    default:
      return state
  }
}
