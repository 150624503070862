const styles = (theme) => ({
  header: {
    height: 80,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 88,
    },
  },
  marginLeftMedium: {
    marginLeft: theme.spacing(2),
  },
  toolbarTab: {
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
  headerTab: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-4),
  },
  appBar: {
    backgroundColor: '#CC0000',
  },
  button: {
    color: theme.palette.primary.contrastText,
  },
  whiteColor: {
    color: 'white',
  },
  noColor: {
    backgroundColor: 'transparent',
  },
  drawerButton: {
    margin: '0px 0px 0px 5px',
    color: 'white',
  },
  alignCenterFrame: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  progressBar: {
    marginLeft: '50%',
  },
  disableRowStyle: {
    background: '#dddddd',
  },
  clickToEditContainer: {
    margin: '10px',
    marginBottom: '30px',
    minHeight: '62px',
    cursor: 'pointer',
    width: '500px',
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
  altTextImage: {
    maxWidth: '65px',
    maxHeight: '65px',
  },
  altTextHeading: {
    fontWeight: 'bold',
  },
  editAlign: {
    position: 'absolute',
    left: '482px',
    bottom: '0px',
    cursor: 'pointer',
  },
  editAltTextWrapper: {
    position: 'relative',
  },
  saveFilterTitle: {
    backgroundColor: '#3f51b5',
  },
  saveTitleText: {
    color: 'white',
  },
})

export default styles
