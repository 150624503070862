/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import UploadPageContainer from './Components/UploadPage/UploadPageContainer'
import Upload from '../../Shared/Upload/Upload'
import { getVideosUploadStrapiData } from '../../../store/videos/upload/actionCreator'
import { isExternalUser } from '../../Shared/SharedUtils'
import { Grid } from '@material-ui/core'
import DefaultAlert from '../../Shared/StrapiComponents/DefaultAlert'
import Alert from '../../Shared/StrapiComponents/Alert'

function Videos(props) {
  const { auth = {}, alert = null, showDefaultAlert = false } = props

  useEffect(() => {
    props.getVideosUploadStrapiData(isExternalUser(auth))
  }, [])

  return (
    <React.Fragment>
      <HeaderTitle title="Video Edit" />
      <Helmet title="Video Edit" />
      {showDefaultAlert ? <DefaultAlert /> : null}
      {alert ? <Alert alert={alert} /> : null}
      <Grid container>{!showDefaultAlert && <UploadPageContainer />}</Grid>
      {showDefaultAlert && <UploadPageContainer />}
      <Upload />
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVideosUploadStrapiData,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { videoUpload, auth } = state
  const { isFetching, alert, showDefaultAlert } = videoUpload
  return {
    isFetching,
    auth,
    alert,
    showDefaultAlert,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Videos)
