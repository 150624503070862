import { Typography, Toolbar, Grid, TablePagination } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'

let HistoryPageContainerTitle = (props) => {
  const {
    classes = {},
    totalElements = 0,
    rowsPerPage = 0,
    page = 0,
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
  } = props

  return (
    <Toolbar>
      <Grid justify="space-between" container spacing={3} alignItems="center">
        <Grid item>
          <div className={classes.title}>
            <Typography
              variant="subtitle1"
              className={classes.totalElementsTitle}
              id="tableTitle"
            >
              <div className={classes.totalHistoryData}>
                {rowsPerPage < totalElements ? rowsPerPage : totalElements} out
                of {totalElements}
              </div>
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <Grid>
            <TablePagination
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              rowsPerPageOptions={[10, 25, 50, 100]}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

// @ts-ignore
export default withStyles(styles)(HistoryPageContainerTitle)
