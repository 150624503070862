import styles from '../theme'
import { withStyles } from '@material-ui/core/styles'
import { first, isNil } from 'lodash'
import { Badge } from '@material-ui/core'
import { IMAGE_VIEW_TYPE, noSwatchUrl } from '../../../Shared/Constants'

function SearchDisplay(props) {
  const { imageList = [], displayType = 'default', classes = {} } = props

  const firstImage = first(imageList)

  const liveSource = (image) => {
    return `${image.base_url}${image.publish_url}?hei=80&wei=80`
  }

  const liveSourceSwatch = (image) => {
    return !isNil(image) && image.publish_url.includes('?')
      ? `${image.base_url}${image.publish_url}&hei=80&wei=80`
      : `${image.base_url}${image.publish_url}?hei=80&wei=80`
  }

  const externalSource = (image) => {
    return `${image.publish_url}&hei=80&wei=80`
  }

  // swatch and primary display when no data
  if (displayType === IMAGE_VIEW_TYPE.SWATCH) {
    return (
      <img
        alt={'Swatch'}
        className={classes.swatchImage}
        src={
          firstImage === undefined ? noSwatchUrl : liveSourceSwatch(firstImage)
        }
        onError={(e) => {
          e.target.src = noSwatchUrl
        }}
      />
    )
  } else if (displayType === IMAGE_VIEW_TYPE.PRIMARY) {
    return firstImage === undefined ? (
      <div className={classes.primaryMissing} data-test-id={'no-primary-image'}>
        Primary Image Missing
      </div>
    ) : (
      <img alt={'Primary'} src={liveSource(firstImage)} />
    )
  }
  // alternate, disabled, rejected, retouch, and readyForReview all display null if list empty
  if (firstImage !== undefined) {
    if (
      displayType === IMAGE_VIEW_TYPE.ALTERNATE ||
      displayType === IMAGE_VIEW_TYPE.DISABLED
    ) {
      return (
        <Badge badgeContent={imageList.length} color="primary">
          <img alt={'Search Result'} src={liveSource(firstImage)} />
        </Badge>
      )
    }
    if (
      displayType === IMAGE_VIEW_TYPE.REJECTED ||
      displayType === IMAGE_VIEW_TYPE.RETOUCH
    ) {
      return (
        <Badge badgeContent={imageList.length} color="primary">
          <img alt={'Search Result'} src={externalSource(firstImage)} />
        </Badge>
      )
    }
    if (displayType === IMAGE_VIEW_TYPE.READY_FOR_REVIEW) {
      return (
        <Badge
          badgeContent={imageList.length}
          color={
            imageList.filter((image) => image.requested_position === 0).length >
            0
              ? 'error'
              : 'primary'
          }
        >
          <img alt={'Search Result'} src={externalSource(firstImage)} />
        </Badge>
      )
    }
    if (displayType === IMAGE_VIEW_TYPE.REUSE) {
      return (
        <Badge badgeContent={imageList.length} className={'customeBadgeColour'}>
          <img alt={'Search Result'} src={externalSource(firstImage)} />
        </Badge>
      )
    }
  } else {
    return null
  }
}

// @ts-ignore
export default withStyles(styles)(SearchDisplay)
