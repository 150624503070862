/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import UploadPageContainer from './Components/UploadPage/UploadPageContainer'
import Upload from '../../Shared/Upload/Upload'
import DefaultAlert from '../../Shared/StrapiComponents/DefaultAlert'
import { Card, CardContent, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import { isExternalUser } from '../../Shared/SharedUtils'
import { getImageUploadStrapiData } from '../../../store/images/upload/actionCreator'
import MDReactComponent from 'markdown-react-js'
import Alert from '../../Shared/StrapiComponents/Alert'

function BulkUploadImages(props) {
  const { classes, alert, information, faq, auth, showDefaultAlert } = props

  useEffect(() => {
    props.getImageUploadStrapiData(isExternalUser(auth))
  }, [])

  return (
    <React.Fragment>
      <HeaderTitle title="Image Upload" />
      <Helmet title="Image Upload" />
      {showDefaultAlert ? <DefaultAlert /> : null}
      {alert ? <Alert alert={alert} /> : null}
      <Grid container>
        {!showDefaultAlert && (
          <Grid item xs={6}>
            <UploadPageContainer />
          </Grid>
        )}
        {information || faq ? (
          <Grid item xs={6}>
            <Card className={classes.margin10}>
              <CardContent>
                {faq && faq.title ? (
                  <MDReactComponent
                    className={`${classes.marginBottom10} ${classes.header}`}
                    text={faq.title}
                  />
                ) : null}
                {faq && faq.body ? (
                  <MDReactComponent className={classes.data} text={faq.body} />
                ) : null}
                {information && information.title ? (
                  <MDReactComponent
                    className={`${classes.marginBottom10} ${classes.header}`}
                    text={information.title}
                  />
                ) : null}
                {information && information.body ? (
                  <MDReactComponent
                    className={classes.data}
                    text={information.body}
                  />
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
      {showDefaultAlert && <UploadPageContainer />}
      <Upload />
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getImageUploadStrapiData,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageUpload, auth } = state
  const { isFetching, alert, information, faq, showDefaultAlert } = imageUpload
  return {
    isFetching,
    auth,
    alert,
    information,
    faq,
    showDefaultAlert,
  }
}
// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BulkUploadImages))
