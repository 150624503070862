import {
  ADD_FILES,
  IMAGES_DROP_ZONE_ACTIVE,
  VALID_NUMBER_OF_IMAGE_FILES,
  IMAGES_ON_DROP_LOADING,
  STORE_IMAGES_UPLOAD_STRAPI_DATA,
  IMAGE_UPLOAD_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_UPLOAD,
} from './actionType'

const initState = {
  files: [],
  numberValidFiles: 0,
  isFetching: false,
  isFetchingOnDrop: false,
  dropZoneEnter: false,
  showDefaultAlert: false,
  alert: null,
  information: null,
  faq: null,
}

export default function imageUploadReducer(state = initState, action = {}) {
  switch (action.type) {
    case ADD_FILES: {
      const { payload } = action
      const newState = {
        ...state,
        files: payload.files,
      }
      return newState
    }
    case IMAGES_DROP_ZONE_ACTIVE: {
      const { payload } = action
      const newState = {
        ...state,
        dropZoneEnter: payload.dropZoneEnter,
      }
      return newState
    }
    case IMAGE_UPLOAD_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case VALID_NUMBER_OF_IMAGE_FILES: {
      const { payload } = action
      const newState = {
        ...state,
        numberValidFiles: payload.numberValidFiles,
      }
      return newState
    }
    case IMAGES_ON_DROP_LOADING: {
      const { payload } = action
      const newState = {
        ...state,
        isFetchingOnDrop: payload.isFetchingOnDrop,
      }
      return newState
    }
    case STORE_IMAGES_UPLOAD_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_IMAGE_UPLOAD: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    default:
      return state
  }
}
