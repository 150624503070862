import GetAppIcon from '@material-ui/icons/GetApp'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import styles from '../theme'

const CopyTcinAction = (props) => {
  const { selectedTcins = [], classes = {} } = props

  return (
    <Button
      className={classes.drawerButton}
      // @ts-ignore
      onClick={() => {
        navigator.clipboard.writeText(selectedTcins.join('\n'))
      }}
    >
      <GetAppIcon />
      <span className={classes.marginLeft5}>Copy TCIN(s) to clipboard</span>
    </Button>
  )
}

// @ts-ignore
export default withStyles(styles)(CopyTcinAction)
