/* eslint-disable default-case */
/* eslint-disable array-callback-return */
import {
  RULES_RELATION_CHANGE,
  RULES_CONDITION_CHANGE,
  RULES_CONDITION_MANUALLY_ADD,
  RULES_ADDITION_CONDITION_SELECTION,
  RULES_ADDITION_CONDITION_INPUT_CHANGE,
  RULES_ADDITION_CONDITION_EXTRA_CHANGE,
  RULES_ADDITION_CONDITION_RESET,
  RULES_ADDITION_CONDITION_OPERATOR_CHANGE,
  RULES_CONDITION_REMOVE,
  RULES_CONDITION_ATTRIBUTE_REMOVE,
  RULES_SET_ITEMS,
  RULES_GET_ITEMS_FAILED,
  RULES_NAME_CHANGE,
  RULE_SET,
  RULE_GET_ERROR,
  RULES_CONDITION_EDIT,
  RULES_PRIORITY_CHANGE,
  RULE_STATE_RESET,
  RULES_ATTRIBUTES_ADD,
  RULES_BUILDER_WARNING,
  SET_ACTIVE_STEP,
  RULES_COMPLETE_STEP,
  RULE_BUILDER_RESET_FETCH,
  VALIDATE_TCIN_BY_RULE_ID_LOADING,
  VALIDATE_TCIN_BY_RULE_ID_RESPONSE,
  VALIDATE_TCIN_BY_RULE_ID_CLEAR,
  ADD_TCIN_CHIPS,
  DISABLE_LOADING_CONDITION,
  ADD_TCIN_CHIPS_TO_API,
} from './actionTypes'
import { difference, forEach } from 'lodash'
import {
  RELATIONSHIP_TYPE,
  PRODUCT_TITLE,
  TAXONOMY_SUBGROUP,
  VENDOR_NAME,
  MTA_VALUES,
  MANUFACTURER_BRAND,
  TAXONOMY,
  MTA,
  TAXONOMY_GROUP,
  MTA_TYPE,
} from './SearchCriteriaStandardNames'

export const variationRelationshipTypes = ['VAP', 'VPC', 'VC']
export const allSizeChartRelationshipTypes = ['VAP', 'VPC', 'VC', 'SA', 'CC']

export const initialState = {
  // select items redux stuff
  priority: 1,
  relationIndex: 0,
  conditions: [
    {
      key: RELATIONSHIP_TYPE,
      display: 'Relationship Type',
      values: variationRelationshipTypes,
      displayValues: variationRelationshipTypes,
    },
  ],
  ruleCriteria: [
    {
      key: RELATIONSHIP_TYPE,
      values: variationRelationshipTypes,
      operator: 'IN',
    },
  ],
  additionalConditionKey: -1,
  additionalConditionExtra: [],
  additionalConditionInput: '',
  ruleAttributes: [],
  name: '',
  overwriteIndex: -1,

  // container redux stuff
  warning: {
    open: false,
    ignore: false,
    message: '',
    ok: () => {},
    cancel: () => {},
  },
  stages: [
    'Select Items',
    'Confirm Size Chart',
    'Add How to Measure',
    'Confirm Rule',
  ],
  activeStep: 0,
  completedSteps: [false, false, false, false],

  // table redux stuff
  needsRefresh: false,
  totalResults: 0,
  foundItems: [],
  selectedOperator: 0,
  operators: ['IN', 'OUT'],
  loadingConditions: true,
  ruleId: undefined,
  isGetRuleError: false,
  getRuleErrorMessage: '',
  validateTcinByRuleIdLoading: false,
  validateTcinByRuleIdData: {},
  tcinChipsArray: [],
  addTcinToApi: [],
}

export default function sizeChartRuleBuilderReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case RULES_RELATION_CHANGE: {
      const types = [variationRelationshipTypes, allSizeChartRelationshipTypes]
      let newState = {
        ...state,
        relationIndex: action.index,
        additionalConditionExtra:
          Number(action.index) === 0 ? types[0] : types[1],
        needsRefresh: true,
      }
      let savedCondition = saveCondition(newState)
      newState = {
        ...newState,
        conditions: savedCondition.newConditions,
        ruleCriteria: savedCondition.newRuleCriteria,
        additionalConditionExtra: [],
      }
      return newState
    }
    case RULES_CONDITION_CHANGE: {
      let savedCondition = saveCondition(state)
      const newState = {
        ...state,
        conditions: savedCondition.newConditions,
        ruleCriteria: savedCondition.newRuleCriteria,
        needsRefresh: true,
        overwriteIndex: -1,
      }
      return newState
    }
    case RULES_CONDITION_MANUALLY_ADD: {
      let conditions = state.conditions.slice(0)
      conditions.push(action.condition)
      const newState = {
        ...state,
        conditions,
        needsRefresh: true,
      }
      return newState
    }
    case RULES_ADDITION_CONDITION_SELECTION: {
      const newState = {
        ...state,
        additionalConditionKey: action.index,
        additionalConditionInput: '',
        additionalConditionExtra: [],
        overwriteIndex: -1,
      }
      return newState
    }
    case RULES_ADDITION_CONDITION_INPUT_CHANGE: {
      const newState = {
        ...state,
        additionalConditionInput: action.value,
      }
      return newState
    }
    case RULES_ADDITION_CONDITION_EXTRA_CHANGE: {
      const newState = {
        ...state,
        additionalConditionExtra: [...action.value],
      }
      return newState
    }
    case RULES_ADDITION_CONDITION_RESET: {
      const newState = {
        ...state,
        additionalConditionKey: -1,
        additionalConditionInput: '',
        additionalConditionExtra: [],
        overwriteIndex: -1,
        selectedOperator: 0,
      }
      return newState
    }
    case RULES_ADDITION_CONDITION_OPERATOR_CHANGE: {
      const newState = {
        ...state,
        selectedOperator: action.selectedOperator,
      }
      return newState
    }
    case RULES_CONDITION_REMOVE: {
      let conditions = state.conditions.slice(0)
      conditions.splice(action.condition.conditionIndex, 1)
      let ruleCriteria = state.ruleCriteria.slice(0)
      ruleCriteria.splice(action.condition.conditionIndex, 1)
      const newState = {
        ...state,
        conditions,
        ruleCriteria,
        needsRefresh: true,
      }
      return newState
    }
    case RULES_CONDITION_ATTRIBUTE_REMOVE: {
      let conditions = state.conditions.slice(0)
      let ruleCriteria = state.ruleCriteria.slice(0)
      let attributes =
        conditions[action.condition.conditionIndex].values.slice(0)
      let types =
        conditions[action.condition.conditionIndex].displayValues.slice(0)
      attributes.splice(action.condition.attributeIndex, 1)
      types.splice(action.condition.attributeIndex, 1)
      if (attributes.length === 0) {
        conditions.splice(action.condition.conditionIndex, 1)
        ruleCriteria.splice(action.condition.conditionIndex, 1)
      } else {
        conditions[action.condition.conditionIndex] = {
          ...conditions[action.condition.conditionIndex],
          values: attributes,
          displayValues: types,
        }
        ruleCriteria[action.condition.conditionIndex] = {
          ...ruleCriteria[action.condition.conditionIndex],
          values: attributes,
        }
      }
      const newState = {
        ...state,
        conditions,
        ruleCriteria,
        needsRefresh: true,
      }
      return newState
    }
    case RULES_SET_ITEMS: {
      const newState = {
        ...state,
        foundItems: action.foundItems.items,
        totalResults: action.foundItems.totalResults,
        needsRefresh: false,
        overwriteIndex: -1,
        loadingConditions: false,
      }
      return newState
    }
    case RULES_GET_ITEMS_FAILED: {
      const newState = {
        ...state,
        needsRefresh: false,
      }
      return newState
    }
    case RULES_NAME_CHANGE: {
      const newState = {
        ...state,
        name: action.name,
      }
      return newState
    }
    case RULE_SET: {
      let relationshipType =
        action.rule.criteria.find((item) => item.key === 'relationship_type') ||
        action.rule.criteria.find(
          (item) => item.key === 'tcin_list_reference_id'
        )
      const newState = {
        ...state,
        ruleCriteria: action.rule.criteria,
        conditions: getDisplayValues(
          action.rule.criteria,
          state.ruleAttributes
        ),
        priority: action.rule.priority,
        ruleId: action.rule.id,
        needsRefresh: true,
        relationIndex:
          difference(relationshipType.values, allSizeChartRelationshipTypes)
            .length === 0
            ? 1
            : 0,
      }
      return newState
    }
    case RULE_GET_ERROR: {
      const newState = {
        ...state,
        isGetRuleError: true,
        getRuleErrorMessage: action.message,
      }
      return newState
    }
    case RULES_CONDITION_EDIT: {
      let conditionIndex = action.condition
      let condition = state.conditions[conditionIndex]
      const newState = {
        ...state,
        ...getConditionInfo(condition, state.ruleAttributes),
        overwriteIndex: conditionIndex,
      }
      return newState
    }
    case RULES_PRIORITY_CHANGE: {
      const newState = {
        ...state,
        priority: action.priority,
      }
      return newState
    }
    case RULE_STATE_RESET: {
      const newState = {
        ...initialState,
      }
      return newState
    }
    case RULES_ATTRIBUTES_ADD: {
      const newState = {
        ...state,
        ruleAttributes: [
          ...state.ruleAttributes,
          {
            ...action.attributeInformation,
          },
        ],
      }
      return newState
    }
    case RULES_BUILDER_WARNING: {
      const newState = {
        ...state,
        warning: action.warning,
      }
      return newState
    }
    case SET_ACTIVE_STEP: {
      const newState = {
        ...state,
        activeStep: action.index,
      }
      return newState
    }
    case RULES_COMPLETE_STEP: {
      let completedSteps = state.completedSteps.slice(0)
      completedSteps[action.index] = true
      const newState = {
        ...state,
        completedSteps,
      }
      return newState
    }
    case RULE_BUILDER_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case VALIDATE_TCIN_BY_RULE_ID_LOADING: {
      const { payload } = action
      const newState = {
        ...state,
        validateTcinByRuleIdLoading: payload.isLoading,
      }
      return newState
    }
    case VALIDATE_TCIN_BY_RULE_ID_RESPONSE: {
      const { payload } = action
      const newState = {
        ...state,
        validateTcinByRuleIdData: payload.data,
      }
      return newState
    }
    case VALIDATE_TCIN_BY_RULE_ID_CLEAR: {
      const newState = {
        ...state,
        validateTcinByRuleIdData: {},
      }
      return newState
    }
    case ADD_TCIN_CHIPS: {
      const newState = {
        ...state,
        tcinChipsArray: action.tcinArray,
      }
      return newState
    }
    case ADD_TCIN_CHIPS_TO_API: {
      const newState = {
        ...state,
        addTcinToApi: action.tcinArray,
      }
      return newState
    }
    case DISABLE_LOADING_CONDITION: {
      const newState = {
        ...state,
        loadingConditions: action.value,
      }
      return newState
    }
    default:
      return state
  }
}

function getConditionInfo(condition, ruleAttributes) {
  // find which of the conditions we are trying to edit
  let key = -1
  let input = ''
  let extra = []
  switch (condition.key) {
    case PRODUCT_TITLE: {
      key = 0
      input = ''
      extra = condition.values.slice(0)
      break
    }
    case TAXONOMY_SUBGROUP: {
      key = 1
      ruleAttributes[1].data[1].map((subgroups, groupIndex) => {
        subgroups.map((subgroupName, subgroupIndex) => {
          condition.values.map((value) => {
            if (subgroupName.display === value) {
              extra.push(subgroupIndex)
              input = groupIndex
            }
          })
        })
      })
      break
    }
    case VENDOR_NAME: {
      key = 2
      input = ''
      ruleAttributes[2].data.map((vendor, index) => {
        condition.values.map((value, conditionIndex) => {
          if (value === vendor.value || value === vendor.key) {
            // extra.push(index)
            // the above would display the index in the vendor add condition box instead of the vendor id/name
            // then when adding it would show no results because it would use the index as the id

            // the below code makes it so the vendor id only shows up when editting existing vendor criteria
            // if you try to assign `${vendor.id} - ${vendor.name}` it breaks because extra needs to be an array
            // even though it only shows the vendor id it displays correctly after adding the condition back into the criteria
            // TODO: figure out how to display vendor name also
            extra = condition.values.slice(0)
          }
        })
      })
      break
    }
    case MTA_VALUES: {
      key = 3
      ruleAttributes[3].data[1].map((typeValues, typeIndex) => {
        typeValues.map((value, valueIndex) => {
          condition.values.map((conditionValue) => {
            if (value.value === conditionValue) {
              extra.push(valueIndex)
              input = typeIndex
            }
          })
        })
      })
      break
    }
    case MANUFACTURER_BRAND: {
      key = 4
      input = ''
      condition.values.map((value) => {
        // skipping the brand key -> value check, and just adding the
        // text value, as we allow users to freeform text here
        extra.push(value)
      })
      break
    }
  }

  return {
    additionalConditionKey: key,
    additionalConditionInput: input,
    additionalConditionExtra: extra,
  }
}

function saveCondition(state) {
  const {
    conditions,
    ruleAttributes,
    overwriteIndex,
    additionalConditionInput,
    additionalConditionExtra,
    additionalConditionKey,
    operators,
    selectedOperator,
    ruleCriteria,
  } = state
  let newConditions = conditions.slice(0)
  let newRuleCriteria = ruleCriteria.slice(0)
  const ruleAttribute =
    additionalConditionKey > -1
      ? ruleAttributes[additionalConditionKey]
      : {
          attribute: RELATIONSHIP_TYPE,
        }
  switch (ruleAttribute.attribute) {
    case PRODUCT_TITLE: {
      let values = additionalConditionExtra.slice(0)
      let displayValues = additionalConditionExtra.slice(0)
      let newCondition = {
        key: ruleAttribute.attribute,
        display: ruleAttribute.display,
        values,
        displayValues,
        operator: operators[selectedOperator],
      }
      let newRuleCriterion = {
        key: ruleAttribute.attribute,
        values,
        operator: operators[selectedOperator],
      }
      if (overwriteIndex >= 0) {
        newConditions[overwriteIndex] = newCondition
        newRuleCriteria[overwriteIndex] = newRuleCriterion
      } else {
        newConditions.push(newCondition)
        newRuleCriteria.push(newRuleCriterion)
      }
      break
    }
    case TAXONOMY: {
      // TAXONOMY gets 2 conditions to describe it
      let subGroupCondition = {
        key: TAXONOMY_SUBGROUP,
        display:
          ruleAttribute.data[0][additionalConditionInput].display +
          ' Subgroups',
        values: additionalConditionExtra.map((index) => {
          return ruleAttribute.data[1][additionalConditionInput][index].value
        }),
        displayValues: additionalConditionExtra.map((index) => {
          return ruleAttribute.data[1][additionalConditionInput][index].display
        }),
        operator: operators[selectedOperator],
      }
      let newRuleCriterion = {
        key: TAXONOMY_SUBGROUP,
        values: additionalConditionExtra.map((index) => {
          return ruleAttribute.data[1][additionalConditionInput][index].value
        }),
        operator: operators[selectedOperator],
      }
      if (overwriteIndex >= 0) {
        newConditions[overwriteIndex] = subGroupCondition
        newRuleCriteria[overwriteIndex] = newRuleCriterion
      } else {
        newConditions.push(subGroupCondition)
        newRuleCriteria.push(newRuleCriterion)
      }
      break
    }
    case VENDOR_NAME: {
      let newCondition = {
        key: VENDOR_NAME,
        values: additionalConditionExtra.map((index) => {
          for (let i = 0; i < ruleAttribute.data.length; i++) {
            if (
              `${ruleAttribute.data[i].key} - ${ruleAttribute.data[i].value}` ===
              index
            ) {
              return ruleAttribute.data[i].key
            }
          }
          return index
        }),
        display: ruleAttribute.display,
        displayValues: additionalConditionExtra.map((index) => {
          for (let i = 0; i < ruleAttribute.data.length; i++) {
            if (ruleAttribute.data[i].key === index) {
              return `${ruleAttribute.data[i].key} - ${ruleAttribute.data[i].value}`
            }
          }
          return index
        }),
        operator: operators[selectedOperator],
      }
      let newRuleCriterion = {
        key: VENDOR_NAME,
        values: additionalConditionExtra.map((index) => {
          for (let i = 0; i < ruleAttribute.data.length; i++) {
            if (
              `${ruleAttribute.data[i].key} - ${ruleAttribute.data[i].value}` ===
              index
            ) {
              return ruleAttribute.data[i].key
            }
          }
          return index
        }),
        operator: operators[selectedOperator],
      }
      if (overwriteIndex >= 0) {
        newConditions[overwriteIndex] = newCondition
        newRuleCriteria[overwriteIndex] = newRuleCriterion
      } else {
        newConditions.push(newCondition)
        newRuleCriteria.push(newRuleCriterion)
      }
      break
    }
    case RELATIONSHIP_TYPE: {
      let newCondition = {
        key: RELATIONSHIP_TYPE,
        display: 'Relationship Type',
        values: additionalConditionExtra.slice(0),
        displayValues: additionalConditionExtra.slice(0),
        operator: operators[selectedOperator],
      }
      let newRuleCriterion = {
        key: RELATIONSHIP_TYPE,
        values: additionalConditionExtra.slice(0),
        operator: operators[selectedOperator],
      }
      let found = false
      newConditions = newConditions.map((condition) => {
        found = found || condition.key === RELATIONSHIP_TYPE
        return condition.key === RELATIONSHIP_TYPE ? newCondition : condition
      })
      newRuleCriteria = newRuleCriteria.map((criterion) => {
        found = found || criterion.key === RELATIONSHIP_TYPE
        return criterion.key === RELATIONSHIP_TYPE
          ? newRuleCriterion
          : criterion
      })
      // sanity check
      if (!found) {
        newConditions.push(newCondition)
        newRuleCriteria.push(newRuleCriterion)
      }
      break
    }
    case MTA: {
      let valueCondition = {
        key: MTA_VALUES,
        display:
          [ruleAttribute.data[0][additionalConditionInput].display] + ' Values',
        values: additionalConditionExtra.map((index) => {
          return ruleAttribute.data[1][additionalConditionInput][index].value
        }),
        displayValues: additionalConditionExtra.map((index) => {
          return ruleAttribute.data[1][additionalConditionInput][index].display
        }),
        operator: operators[selectedOperator],
      }
      let newRuleCriterion = {
        key: MTA_VALUES,
        values: additionalConditionExtra.map((index) => {
          return ruleAttribute.data[1][additionalConditionInput][index].value
        }),
        operator: operators[selectedOperator],
      }
      if (overwriteIndex >= 0) {
        newConditions[overwriteIndex] = valueCondition
        newRuleCriteria[overwriteIndex] = newRuleCriterion
      } else {
        newConditions.push(valueCondition)
        newRuleCriteria.push(newRuleCriterion)
      }
      break
    }
    case MANUFACTURER_BRAND: {
      let values = additionalConditionExtra.slice(0)
      let displayValues = additionalConditionExtra.slice(0)
      let newCondition = {
        key: ruleAttribute.attribute,
        display: ruleAttribute.display,
        values,
        displayValues,
        operator: operators[selectedOperator],
      }
      let newRuleCriterion = {
        key: ruleAttribute.attribute,
        values,
        operator: operators[selectedOperator],
      }
      if (overwriteIndex >= 0) {
        newConditions[overwriteIndex] = newCondition
        newRuleCriteria[overwriteIndex] = newRuleCriterion
      } else {
        newConditions.push(newCondition)
        newRuleCriteria.push(newRuleCriterion)
      }
      break
    }
  }
  return { newConditions, newRuleCriteria }
}

// add display values to the criteria returned
// from the size chart app
function getDisplayValues(conditions, ruleAttributes) {
  // there are lots of different variations of the same attribute name,
  // and while we would like them to all be the same, rules have been through
  // many iterations over the years which leave them with a variety of different
  // names for the same field to be searching on
  if (!conditions) {
    return initialState.conditions
  }
  let out = []
  forEach(conditions, (condition) => {
    switch (condition.key.toLowerCase()) {
      case 'product title':
      case 'product_title':
      case PRODUCT_TITLE: {
        out.push({
          ...condition,
          key: PRODUCT_TITLE,
          display: 'Product Title',
          displayValues: condition.values.slice(0),
        })
        break
      }
      // Out Dated Condition
      case 'group_name':
      case 'iac_group_value':
      case TAXONOMY_GROUP: {
        break
      }
      case 'sub_group':
      case 'iac_subgroup_value':
      case TAXONOMY_SUBGROUP: {
        let subgroups = []
        let groupIndex = -1
        // check each of the mta arrays
        forEach(ruleAttributes[1].data[1], (array, index) => {
          // check each value in the array
          forEach(array, (subgroup) => {
            // for each value in this condition
            forEach(condition.values, (value) => {
              // if the value is the mta, add its display
              if (subgroup.value === value) {
                subgroups.push(subgroup.value)
                groupIndex = index
              }
            })
          })
        })
        out.push({
          ...condition,
          key: TAXONOMY_SUBGROUP,
          display: ruleAttributes[1].data[0][groupIndex]
            ? ruleAttributes[1].data[0][groupIndex].display + ' Subgroups'
            : 'Subgroups',
          displayValues: condition.values
            ? condition.values.slice(0)
            : 'No Results',
        })
        break
      }
      case 'vendor name':
      case 'vendor_name':
      case 'vendor id':
      case 'vendor_id':
      case 'vendors id':
      case 'vendors_id':
      case VENDOR_NAME: {
        out.push({
          ...condition,
          key: VENDOR_NAME,
          display: 'Vendor Name',
          displayValues: condition.values.map((nameOrId, index) => {
            let vendorNameMatch = ''
            forEach(ruleAttributes[2].data, (vendor) => {
              // handle vendor id or name, but always display both (vendor.key is id vendor.value is name)
              if (vendor.key === nameOrId || vendor.value === nameOrId) {
                vendorNameMatch = `${vendor.key} - ${vendor.value}`
                condition.values[index] = vendor.key
              }
            })
            return vendorNameMatch === '' ? 'Vendor Unknown' : vendorNameMatch
          }),
        })
        break
      }
      case 'relationship type':
      case RELATIONSHIP_TYPE: {
        out.push({
          ...condition,
          key: RELATIONSHIP_TYPE,
          display: 'Relationship Type',
          displayValues: condition.values.slice(0),
        })
        break
      }
      // Out Data condition
      case 'iac_attribute':
      case 'iac':
      case MTA_TYPE.toLowerCase(): {
        break
      }
      case 'iac_attribute_values':
      case 'iac_value':
      case MTA_VALUES.toLowerCase(): {
        let mtaValues = []
        let mtaIndex = -1
        // check each of the mta arrays
        forEach(ruleAttributes[3].data[1], (array, index) => {
          // check each value in the array
          forEach(array, (mta) => {
            // for each value in this condition
            forEach(condition.values, (value) => {
              // if the value is the mta, add its display
              if (mta.value === value) {
                mtaValues.push(mta.display)
                mtaIndex = index
              }
            })
          })
        })
        out.push({
          ...condition,
          key: MTA_VALUES,
          display: ruleAttributes[3].data[0][mtaIndex].display + ' Values',
          displayValues: mtaValues,
        })
        break
      }
      case 'manufacturer_brand':
      case MANUFACTURER_BRAND: {
        out.push({
          ...condition,
          key: MANUFACTURER_BRAND,
          display: 'Manufacturer Brand',
          displayValues: condition.values.map((value) => {
            let out = ''
            forEach(ruleAttributes[4].data, (brand) => {
              if (brand.key === value) {
                out = brand.key
              }
            })
            return out === '' ? value : out
          }),
        })
        break
      }
      default: {
        out.push({
          ...condition,
          display: condition.key,
          displayValues: condition.values.slice(0),
        })
      }
    }
  })
  return out
}
