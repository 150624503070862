/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import styles from '../../theme'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'

function AltTextNonApproved(props) {
  const { classes = {} } = props

  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted
        maxWidth="md"
        open={props.nonApporvedAltTextImages}
        aria-labelledby="alert-dialog-image-label"
        aria-describedby="alert-dialog-imageLable-description"
      >
        <DialogTitle
          className={classes.saveFilterTitle}
          id="alert-dialog-image-label"
        >
          <div>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.saveTitleText}
            >
              Unable to submit Alt Text for {props?.invalid_tcins?.length} TCINs
              due to unapproved images
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.marginTop20}
            id="alert-dialog-imageLable-description"
          >
            TCIN's
            <ul>
              {props?.invalid_tcins.map((tcin) => {
                return <li>{tcin}</li>
              })}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigator.clipboard.writeText(props?.invalid_tcins.join('\n'))
            }}
          >
            <GetAppIcon />
            <span className={classes.marginLeft5}>
              Copy TCIN(s) to clipboard
            </span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              props.nonApprovedAltText(!props.nonApporvedAltTextImages)
            }
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default withStyles(styles)(AltTextNonApproved)
