export const GET_STYLES_SUCCESS = 'GET_STYLES_SUCCESS'
export const GET_STYLES_FAILED = 'GET_STYLES_FAILED'
export const GET_STYLES_PENDING = 'GET_STYLES_PENDING'
export const SAVE_SIZECHART_SUCCESS = 'SAVE_SIZECHART_SUCCESS'
export const SAVE_SIZECHART_PENDING = 'SAVE_SIZECHART_PENDING'
export const SAVE_SIZECHART_ERROR = 'SAVE_SIZECHART_ERROR'
export const GET_SIZECHART_SUCCESS = 'GET_SIZECHART_SUCCESS'
export const GET_SIZECHART_PENDING = 'GET_SIZECHART_PENDING'
export const GET_SIZECHART_ERROR = 'GET_SIZECHART_ERROR'
export const SIZECHART_MERGE_SUCCESS = 'SIZECHART_MERGE_SUCCESS'
export const SIZECHART_DELETE_SUCCESS = 'SIZECHART_DELETE_SUCCESS'
export const SIZECHART_INSERT_SUCCESS = 'SIZECHART_INSERT_SUCCESS'
export const SIZECHART_ADD_SUCCESS = 'SIZECHART_ADD_SUCCESS'
export const SIZECHART_DATA_SUCCESS = 'SIZECHART_DATA_SUCCESS'
export const SET_SIZECHART_CREATE = 'SET_SIZECHART_CREATE'
export const SIZECHART_CREATE_SUCCESS = 'SIZECHART_CREATE_SUCCESS'
export const SIZECHART_SET_SELECTMODE = 'SIZECHART_SET_SELECTMODE'
export const SIZECHART_SELECTION_CHANGE = 'SIZECHART_SELECTION_CHANGE'
export const SIZECHART_SELECTION_CLEAR = 'SIZECHART_SELECTION_CLEAR'
export const SIZECHART_SET_BRANDS = 'SIZECHART_SET_BRANDS'
export const SIZECHART_SET_CATEGORIES = 'SIZECHART_SET_CATEGORIES'
export const SIZECHART_DATA_CLEAR = 'SIZECHART_DATA_CLEAR'
export const SIZECHART_UNDO = 'SIZECHART_UNDO'
export const SIZECHART_REDO = 'SIZECHART_REDO'
export const SIZECHART_SET_IDENTIFIERS = 'SIZECHART_SET_IDENTIFIERS'
export const SIZECHART_SET_SIZES = 'SIZECHART_SET_SIZES'
export const SIZECHART_RULE_SET = 'SIZECHART_RULE_SET'
export const SIZECHART_RESET = 'SIZECHART_RESET'
export const SIZECHART_MEASUREMENT_GET = 'SIZECHART_MEASUREMENT_GET'
export const SIZECHART_MEASUREMENT_TOGGLE = 'SIZECHART_MEASUREMENT_TOGGLE'
export const SIZECHART_SET_TABNAMES = 'SIZECHART_SET_TABNAMES'
export const SIZECHART_GET_TABNAMES_PENDING = 'SIZECHART_GET_TABNAMES_PENDING'
export const SIZECHART_GET_TABNAMES_ERROR = 'SIZECHART_GET_TABNAMES_ERROR'
export const SIZECHART_RESET_FETCH = 'SIZECHART_RESET_FETCH'
export const SIZECHART_RETRY_FASTLY = 'SIZECHART_RETRY_FASTLY'
export const SIZECHART_REDIRECT_FASTLY = 'SIZECHART_REDIRECT_FASTLY'
export const CHECK_SIZE_CHART_EXIST = 'CHECK_SIZE_CHART_EXIST'
export const CHECK_SIZE_CHART_EXIST_POPUP = 'CHECK_SIZE_CHART_EXIST_POPUP'
export const SAVE_SIZECHART_EXIST_LOADER = 'SAVE_SIZECHART_EXIST_LOADER'
