import React from 'react'
import {
  Button,
  Input,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import TagDataTableHeadContainer from '../../../Shared/Components/TagDataTableHeadContainer'
import EditTagContainer from '../EditTag/EditTagContainer'
import SaveIcon from '@material-ui/icons/Save'
import UndoIcon from '@material-ui/icons/Undo'
import CreateIcon from '@material-ui/icons/Create'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../../Shared/Constants'

let TagOverviewDataTable = (props) => {
  const {
    classes,
    tagGroups,
    tagGroupsFilterText,
    filterTagGroups,
    resetBtnAction,
    saveTagBtnAction,
    changeTagEditState,
  } = props
  const columnHeaderData = [
    {
      id: 'tagImage',
      numeric: false,
      disablePadding: false,
      label: 'Example Image',
    },
    {
      id: 'typeInfo',
      numeric: false,
      disablePadding: false,
      label: 'Type Info',
    },
    { id: 'tagInfo', numeric: false, disablePadding: false, label: 'Tag Info' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ]
  return (
    <React.Fragment>
      <Input
        className={classes.tagInput}
        placeholder="type to filter tags"
        onChange={(e) => filterTagGroups(e.target.value)}
        name="Tags Search"
        value={tagGroupsFilterText}
        endAdornment={
          <IconButton
            onClick={() => {
              filterTagGroups('')
            }}
          >
            <Clear />
          </IconButton>
        }
      />
      <Link to={ROUTES.IMAGES.LABELS_CREATE} className={classes.linkStyle}>
        <Button variant="outlined" color="primary">
          Create New Tag
        </Button>
      </Link>
      <Link to={ROUTES.IMAGES.LABELS_TRAINING} className={classes.linkStyle}>
        <Button variant="outlined" color="primary">
          View Training Data
        </Button>
      </Link>
      <Link to={ROUTES.IMAGES.LABELS_CLASSIFIER} className={classes.linkStyle}>
        <Button variant="outlined" color="primary">
          Classify Images
        </Button>
      </Link>
      <div>
        {tagGroups.length ? (
          <Paper className={classes.copyDataroot}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <TagDataTableHeadContainer
                  columnHeaderData={columnHeaderData}
                />
                <TableBody>
                  {tagGroups.map((group) => {
                    return group.tags.map((tag) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={`${group.group}-${tag.name}`}
                        >
                          <TableCell
                            padding="checkbox"
                            key={`${group.group}-${tag.name}-image`}
                          >
                            <img
                              src={tag.tag_metadata.example_asset_urls[0]}
                              alt="tag"
                              height={150}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.cellStyle}
                            padding="none"
                            key={`${group.group}-${tag.name}-typeInfo`}
                          >
                            <div className={classes.infoTitle}>
                              {group.group.toUpperCase()}
                            </div>
                            <div
                              className={classes.marginTopBottom10}
                              role="presentation"
                            >
                              <p>
                                {group.group_metadata.description
                                  ? group.group_metadata.description
                                  : 'no type description'}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.cellStyle}
                            padding="none"
                            key={`${group.group}-${tag.name}-tagInfo`}
                          >
                            <div className={classes.infoTitle}>
                              {tag.name.toUpperCase()}
                            </div>
                            <EditTagContainer
                              type={group.group}
                              tag={tag.name}
                              isTagEditing={tag.isTagEditing}
                              tagDescription={
                                tag.tag_metadata.description
                                  ? tag.tag_metadata.description
                                  : 'no tag description'
                              }
                            />
                          </TableCell>
                          <TableCell
                            className={classes.saveCell}
                            key={`${group.group}-${tag.name}-revert`}
                          >
                            {tag.isTagEditing || tag.isTagEdited ? (
                              <div>
                                <div>
                                  <Tooltip title="Save Changes">
                                    <SaveIcon
                                      className={classes.pointerCursor}
                                      onClick={() =>
                                        saveTagBtnAction(
                                          group.group,
                                          tag.name,
                                          tag.tag_metadata.description
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </div>
                                <div>
                                  <Tooltip title="Revert Changes">
                                    <UndoIcon
                                      className={classes.pointerCursor}
                                      onClick={() =>
                                        resetBtnAction(group.group, tag.name)
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            ) : (
                              <Tooltip title="Edit tag">
                                <CreateIcon
                                  className={classes.pointerCursor}
                                  onClick={() =>
                                    changeTagEditState(
                                      group.group,
                                      tag.name,
                                      true
                                    )
                                  }
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  })}
                </TableBody>
              </Table>
            </div>
          </Paper>
        ) : (
          <div className={classes.noResult}>No tags found</div>
        )}
      </div>
    </React.Fragment>
  )
}

TagOverviewDataTable = reduxForm({ form: 'tagEditForm' })(TagOverviewDataTable)
TagOverviewDataTable = connect((state) => ({
  initialValues: {
    typeDescription: '',
    tagDescription: '',
    isTagEditing: false,
  },
}))(TagOverviewDataTable)
export default withStyles(styles)(TagOverviewDataTable)
