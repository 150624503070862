// import * as fireflyInternalAppConfig from './config/fireflyInternalAppConfig'
import * as log from 'loglevel'
import envConfigs from '../config/apiConfig'
import Firefly from 'firefly-sdk-ts'

const fireflyInternalAppConfig = {
  schemaId: '1961',
  autoWrap: true,
  eventManagerKey: 'internalapp',
  persistMethod: 'localStorage',
  retries: 1,
}

export function initializeFirefly() {
  const analyticsEndpoint =
    envConfigs.targetApiGateway.targetApiGatewayUrl +
    envConfigs.targetApiGateway.analytics
  fireflyInternalAppConfig.url = analyticsEndpoint
  fireflyInternalAppConfig.apiKey =
    envConfigs.targetApiGateway.targetApiGatewayKey

  Firefly.setLoggingLevel(log.levels.TRACE)
  log.debug(`Initializing Firefly to post to: ${analyticsEndpoint}.`)

  Firefly.registerEventManagerWithConfig(fireflyInternalAppConfig)
}
