import {
  SIZECHART_MEASUREMENT_GET,
  SIZECHART_SET_IDENTIFIERS,
  SAVE_SIZECHART_PENDING,
  SAVE_SIZECHART_ERROR,
  SAVE_SIZECHART_SUCCESS,
  GET_SIZECHART_PENDING,
  GET_SIZECHART_ERROR,
  GET_SIZECHART_SUCCESS,
  SET_SIZECHART_CREATE,
  SIZECHART_GET_TABNAMES_PENDING,
  SIZECHART_GET_TABNAMES_ERROR,
  SIZECHART_SET_TABNAMES,
  SIZECHART_SET_CATEGORIES,
  SIZECHART_SET_BRANDS,
  SIZECHART_SET_SIZES,
  SIZECHART_RESET_FETCH,
  SIZECHART_RETRY_FASTLY,
  SIZECHART_REDIRECT_FASTLY,
  CHECK_SIZE_CHART_EXIST,
  CHECK_SIZE_CHART_EXIST_POPUP,
  SAVE_SIZECHART_EXIST_LOADER,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { each, forEach } from 'lodash'
import { toast } from 'react-toastify'
import {
  TOAST_BODY,
  TOAST_BODY_RETRY,
} from '../../../components/Shared/Constants'
import { saveRule } from '../ruleBuilder/actionCreator'
import {
  getRules,
  resetTcinRuleSelection,
} from '../../../store/sizeChart/ruleSelection/actionCreator'

// all of the harbinger events
export const events = {
  sizeChartGetSuccess: 'gbrbsr',
  sizeChartGetFailed: 'ktbkam',
  sizeChartGetStarted: 'czl5md',
  sizeChartSaveStarted: '2mrlio',
  sizeChartSaveError: 'x9flxb',
  sizeChartSaveSuccess: 'a0y8p5',
}

export function resetFetch(data) {
  return {
    type: SIZECHART_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function setSearchCategories(identifiers) {
  let categories = []
  for (let i = 0; i < identifiers.length; i++) {
    if (categories.indexOf(identifiers[i].category) === -1) {
      categories.push(identifiers[i].category)
    }
  }
  categories.sort((a, b) => {
    if (a <= b) {
      return -1
    } else {
      return 1
    }
  })
  return {
    type: SIZECHART_SET_CATEGORIES,
    categories,
  }
}

function setSearchBrands(identifiers) {
  let brands = []
  each(identifiers, function (identifier) {
    if (brands.indexOf(identifier.brand) === -1) {
      brands.push(identifier.brand)
    }
  })
  brands.sort((a, b) => {
    if (a <= b) {
      return -1
    } else {
      return 1
    }
  })
  return {
    type: SIZECHART_SET_BRANDS,
    brands,
  }
}

function setSearchSizes(identifiers) {
  let sizes = []
  forEach(identifiers, (identifier) => {
    if (sizes.indexOf(identifier.size) === -1) {
      sizes.push(identifier.size)
    }
  })
  return {
    type: SIZECHART_SET_SIZES,
    sizes,
  }
}

export function sizeChartRetryFastly(fastlyUrl, payload, tabName) {
  let fastlyUrlParams = fastlyUrl.cloud_url
  return (dispatch) => {
    dispatch(setSizeChartFastlyLoader(true))
    return axios
      .get(
        envConfigs.api.sizeChartV2Api +
          `tcinsizechart/v1/validate?url=${fastlyUrlParams}`
      )
      .then((response) => {
        dispatch(
          saveRule({
            ...payload,
            size_chart_info: {
              ...payload.size_chart_info,
              size_chart_tab_name: tabName,
            },
          })
        )
      })
      .then((response) => {
        dispatch(setSizeChartFastlyLoader(false))
        dispatch(setSaveSizechartSuccess(true))
        dispatch(setSaveSizechartPending(false))
        dispatch(getRules())
        dispatch(resetTcinRuleSelection())
        dispatch(setSizeChartFastlyRedirect(true))
      })
      .catch(() => {
        dispatch(setSizeChartFastlyLoader(false))
        dispatch(
          resetFetch(false),
          toast.error(
            'Failed to Create Size Chart Please Publish again',
            TOAST_BODY_RETRY
          )
        )
      })
  }
}

export function saveSizeChart(
  payload,
  mainPagePayload = {},
  tabName = '',
  selectedTcinRule
) {
  const charts = payload.charts.slice(0)
  var outCharts = []
  // input checking
  for (var c = 0; c < charts.length; c++) {
    var { sizeTitle, brandTitle } = charts[c]
    var header = charts[c].header.slice(0)
    var data = charts[c].data.slice(0)
    // check columns for a column which has been merged into another
    // and delete the unnessisary data if it exists
    var dups = 0
    var i = 0
    var j = 0
    for (i = 0; i < header.length; i++) {
      dups = 0
      if (header[i] === '') {
        return (dispatch) => {
          dispatch(
            setSaveSizechartError({
              value: true,
              message: 'All cells must contain something!',
            })
          )
        }
      }
      if (header[i] === 'column_merge') {
        dups += 1
        for (j = 0; j < data.length; j++) {
          if (data[j][i] === 'column_merge') {
            dups += 1
          } else {
            break
          }
        }

        if (dups === 1 + data.length) {
          // remove col
          header.splice(i, 1)
          for (j = 0; j < data.length; j++) {
            var rowArr = data[j].slice(0)
            rowArr.splice(i, 1)
            data[j] = rowArr
          }
          i--
        }
      }
    }

    // check for rows which have been merged into other rows
    // and remove them from the chart
    for (i = 0; i < data.length; i++) {
      dups = 0
      for (j = 0; j < data[i].length; j++) {
        if (data[i][j] === '') {
          // this is input checking to make sure that all
          // cells in the size chart are full, TODO: make propmt with confirmation
          // that the user wants to submit a chart with blank cells
          return (dispatch) => {
            dispatch(
              setSaveSizechartError({
                value: true,
                message: 'All cells must contain something!',
              })
            )
          }
        }
        if (data[i][j] === 'row_merge') {
          dups += 1
        }
      }
      if (dups === data[i].length) {
        data.splice(i, 1)
      }
    }

    outCharts.push({
      header: header.slice(0),
      data: data.slice(0),
      size_title: sizeTitle,
      brand_title: brandTitle,
    })
  }
  let currentDate = new Date()
  // convert the input payload to a web format
  payload = {
    ...payload,
    size_chart_tab_name: payload.sizeChartTabName,
    id: payload.sizeChartId,
    charts: outCharts,
    category_title: payload.categoryTitle,
    shop_all_url: payload.shopAllUrl,
    measuring_guides: payload.measuringGuides,
    include_measurements: payload.includeMeasurements,
    last_edited_by: payload.user.email,
    last_edited_on:
      currentDate.getMonth() +
      1 +
      '/' +
      currentDate.getDate() +
      '/' +
      currentDate.getFullYear() +
      ':' +
      currentDate.getHours() +
      ':' +
      currentDate.getMinutes() +
      ':' +
      currentDate.getSeconds(),
  }
  return (dispatch) => {
    dispatch(setSaveSizechartPending(true))
    // harbinger.trackEvent(events.sizeChartSaveStarted)
    return axios
      .post(
        envConfigs.api.sizeChartApi +
          'sizecharts/save?key=' +
          envConfigs.api.gatewayKey,
        payload
      )
      .then((response) => {
        // harbinger.trackEvent(events.sizeChartSaveSuccess)

        if (selectedTcinRule) {
          dispatch(
            sizeChartRetryFastly(response.data, mainPagePayload, tabName)
          )
        } else {
          dispatch(setSaveSizechartSuccess(true))
          dispatch(setSaveSizechartPending(false))
        }
      })
      .catch((error, data) => {
        let errorPayload = {
          value: true,
          message: error.message,
        }
        // harbinger.trackEvent(events.sizeChartSaveError)
        dispatch(setSaveSizechartPending(false))
        dispatch(setSaveSizechartError(errorPayload))
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function getSizeChartTabNames() {
  return (dispatch) => {
    dispatch(setGetSizeChartTabNamesPending(true))
    axios
      .get(
        envConfigs.api.sizeChartApi +
          'sizecharts/tab_names?key=' +
          envConfigs.api.gatewayKey
      )
      .then((response) => {
        dispatch(setSizeChartTabNames(response.data))
        dispatch(setGetSizeChartTabNamesPending(false))
      })
      .catch((error, data) => {
        dispatch(setGetSizeChartTabNamesPending(false))
        dispatch(setGetSizeChartTabNamesError(true))
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function getSizeChart(payload, ifSetRuleByTcin = false) {
  let { category, brand, size } = payload
  if (category === '' && brand === '' && size === '') {
    return (dispatch) => {}
  }
  // brand = brand.toLowerCase()
  // this other brand trimming stuff should probably be removed too but need to verify
  brand = brand.replace(/[$&!@#^*]/g, '')
  brand = brand.replace(/[ ]+/g, '-')

  return (dispatch, getState) => {
    // const { selectedTcinRule } = getState().sizeChartRuleSelection
    dispatch(setSaveSizechartPending(true))
    // harbinger.trackEvent(events.sizeChartGetStarted)
    axios
      .get(
        envConfigs.api.sizeChartApi +
          'sizecharts/get/' +
          category +
          '/' +
          brand +
          '/' +
          size +
          '?key=' +
          envConfigs.api.gatewayKey
      )
      .then((response) => {
        dispatch(setGetSizechartPending(false))
        if (response.data === '') {
          // harbinger.trackEvent(events.sizeChartGetFailed)
          dispatch(setGetSizechartError(true))
        } else {
          // harbinger.trackEvent(events.sizeChartGetSuccess)
          dispatch(setSizechart(response.data))
        }
      })
      .catch((error, data) => {
        // harbinger.trackEvent(events.sizeChartGetFailed)
        dispatch(setGetSizechartPending(false))
        dispatch(setGetSizechartError(true))
      })
  }
}

export function checkForSizeChartExist(payload) {
  let { category, brand, size } = payload
  if (category !== '' && brand !== '' && size !== '') {
    return (dispatch, getState) => {
      dispatch(setSizeChartExistLoader(true))
      axios
        .get(
          envConfigs.api.sizeChartApi +
            'sizecharts/get/' +
            category +
            '/' +
            brand +
            '/' +
            size +
            '?key=' +
            envConfigs.api.gatewayKey
        )
        .then((response) => {
          dispatch(setSizeChartExistLoader(false))
          if (response.data === '') {
            dispatch(checkForSizeChart(false))
            dispatch(checkForSizeChartPopUp(false))
          } else {
            dispatch(checkForSizeChart(true))
            dispatch(checkForSizeChartPopUp(true))
          }
        })
        .catch((error, data) => {
          // harbinger.trackEvent(events.sizeChartGetFailed)
          dispatch(setSizeChartExistLoader(false))
          dispatch(checkForSizeChart(false))
        })
    }
  } else {
    return (dispatch) => {}
  }
}

function setSizeChartExistLoader(loader) {
  return {
    type: SAVE_SIZECHART_EXIST_LOADER,
    loader,
  }
}

export function getAllSizeChartIdentifiers() {
  return (dispatch) => {
    axios
      .get(
        envConfigs.api.sizeChartApi +
          'sizecharts/get/all?key=' +
          envConfigs.api.gatewayKey
      )
      .then((response) => {
        dispatch(setSizeChartIdentifiers(response.data))
        dispatch(setSearchCategories(response.data))
        dispatch(setSearchBrands(response.data))
        dispatch(setSearchSizes(response.data))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function getHowToMeasure(category) {
  return (dispatch) => {
    return axios
      .get(
        envConfigs.api.sizeChartApi +
          'measurement/' +
          category +
          '?key=' +
          envConfigs.api.gatewayKey
      )
      .then((response) => {
        return dispatch({
          type: SIZECHART_MEASUREMENT_GET,
          howToMeasure: response.data,
        })
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function setSizeChartIdentifiers(payload) {
  return {
    type: SIZECHART_SET_IDENTIFIERS,
    identifiers: payload,
  }
}

function setSaveSizechartPending(isSaveSizechartPending) {
  return {
    type: SAVE_SIZECHART_PENDING,
    isSaveSizechartPending,
  }
}

export function setSaveSizechartError(payload) {
  return {
    type: SAVE_SIZECHART_ERROR,
    isSaveSizechartError: payload.value,
    message: payload.message,
  }
}

function setSaveSizechartSuccess(isSaveSizechartSuccess) {
  return {
    type: SAVE_SIZECHART_SUCCESS,
    isSaveSizechartSuccess,
  }
}

function setGetSizechartPending(isGetSizechartPending) {
  return {
    type: GET_SIZECHART_PENDING,
    isGetSizechartPending,
  }
}

export function setGetSizechartError(isGetSizechartError) {
  return {
    type: GET_SIZECHART_ERROR,
    isGetSizechartError,
  }
}

export function checkForSizeChart(sizeChartExist) {
  return {
    type: CHECK_SIZE_CHART_EXIST,
    sizeChartExist,
  }
}

export function checkForSizeChartPopUp(sizeChartExistPopUp) {
  return {
    type: CHECK_SIZE_CHART_EXIST_POPUP,
    sizeChartExistPopUp,
  }
}

function setSizechart(sizeChart) {
  return {
    type: GET_SIZECHART_SUCCESS,
    sizeChart: sizeChart,
  }
}

export function setSizeChartCreate(value) {
  return {
    type: SET_SIZECHART_CREATE,
    value,
  }
}

function setGetSizeChartTabNamesPending(isGetTabNamesPending) {
  return {
    type: SIZECHART_GET_TABNAMES_PENDING,
    isGetTabNamesPending,
  }
}

function setGetSizeChartTabNamesError(isGetTabNamesError) {
  return {
    type: SIZECHART_GET_TABNAMES_ERROR,
    isGetTabNamesError,
  }
}

function setSizeChartTabNames(sizeChartTabNames) {
  return {
    type: SIZECHART_SET_TABNAMES,
    sizeChartTabNames: sizeChartTabNames,
  }
}

function setSizeChartFastlyLoader(loader) {
  return {
    type: SIZECHART_RETRY_FASTLY,
    fastlyLoading: loader,
  }
}

export function setSizeChartFastlyRedirect(flag) {
  return {
    type: SIZECHART_REDIRECT_FASTLY,
    redirect: flag,
  }
}
