import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AssetSearch from './AssetSearch'
import {
  updateItemSearch,
  altTextSearchItem,
  requestAltTextDataByTcins,
} from '../../../../store/altText/actionCreator'
import { splitTcins } from '../../../Shared/SharedUtils'

function AssetSearchContainer(props) {
  const handleSearchForAltTextBulkUploadSection = () => {
    let enteredTcins = props.searchedItems
    let searchTcins = props.inputTcins.concat(enteredTcins)
    let userId = props.auth.email ? props.auth.email : props.auth.lanId
    props.requestAltTextDataByTcins(searchTcins, userId.toLowerCase())
    props.updateItemSearch(searchTcins)
  }
  const handleAddTcin = (selectedTcins) => {
    let chips = []
    splitTcins(selectedTcins).forEach((i) => {
      chips.push(i)
    })
    if (props.searchedItems !== undefined && props.searchedItems.length > 0) {
      props.searchedItems.forEach((item) => {
        chips.push(item)
      })
    }
    props.altTextSearchItem(chips)
  }

  const handleDeleteTcin = (deletedTcin) => {
    let chips = []
    props.searchedItems.forEach((item) => {
      if (item !== deletedTcin) {
        chips.push(item)
      }
    })
    props.altTextSearchItem(chips)
  }

  return (
    <AssetSearch
      handleSearchForLongCopyAndFeatureBullets={
        handleSearchForAltTextBulkUploadSection
      }
      searchedItems={props.searchedItems}
      handleAddTcin={handleAddTcin}
      handleDeleteTcin={handleDeleteTcin}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateItemSearch,
      requestAltTextDataByTcins,
      altTextSearchItem,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, altTextReducer } = state
  const { inputTcins, searchedItems } = altTextReducer
  return {
    auth,
    inputTcins,
    searchedItems,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetSearchContainer)
