import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core'
import envConfigs from '../../../../../config/apiConfig'
import { isEmpty } from 'lodash'

function escapeHtml(input) {
  return { __html: input }
}

function DialogBoxDelete(props) {
  const { data, handleDelete, cancelDeleteConfirmation, classes } = props
  var baseUrl = !isEmpty(data.itemData)
    ? data.itemData.enrichment
      ? data.itemData.enrichment.images
        ? data.itemData.enrichment.images.base_url
          ? data.itemData.enrichment.images.base_url
          : envConfigs.api.sceneSevenBaseUrl
        : envConfigs.api.sceneSevenBaseUrl
      : envConfigs.api.sceneSevenBaseUrl
    : envConfigs.api.sceneSevenBaseUrl
  var primaryImage = !isEmpty(data.itemData)
    ? data.itemData.enrichment
      ? data.itemData.enrichment.images
        ? data.itemData.enrichment.images.primary_image
          ? data.itemData.enrichment.images.primary_image
          : 'pipeline_image_missing'
        : 'pipeline_image_missing'
      : 'pipeline_image_missing'
    : 'pipeline_image_missing'
  var title = !isEmpty(data.itemData)
    ? data.itemData.product_description
      ? data.itemData.product_description.title
        ? data.itemData.product_description.title
        : 'No Title Available'
      : 'No Title Available'
    : 'No Title Available'

  return (
    <Dialog
      open={data.confirmDelete}
      onClose={() => cancelDeleteConfirmation(data.tcin)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Skip Upload of Long Copy and Features'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container direction="row">
            <Grid container item xs={6}>
              <Grid item xs={4}>
                <img
                  src={baseUrl + primaryImage}
                  alt={data.tcin}
                  className={classes.itemImage}
                />
              </Grid>
              <Grid container item xs={8}>
                <Grid item xs={12}>
                  {data.tcin}
                </Grid>
                <Grid item xs={12}>
                  <div dangerouslySetInnerHTML={escapeHtml(title)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <div>
                {' '}
                Do you want to remove the long copy and features from the
                curerent bulk upload?
              </div>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => cancelDeleteConfirmation(data.tcin)}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => handleDelete(event, data)}
          variant="contained"
          color="primary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withStyles(styles)(DialogBoxDelete)
