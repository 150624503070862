import {
  ADD_FILES_VIDEO,
  VIDEOS_DROP_ZONE_ACTIVE,
  VALID_NUMBER_OF_VIDEO_FILES,
  VIDEOS_ON_DROP_LOADING,
  VIDEOS_RESET_FETCH,
  UPDATE_INVALID_TCIN_LIST,
  UPDATE_NOTOWNED_TCIN_LIST,
  VIDEO_FILES_ADDED,
  CC_FILES_ADDED,
  POSTER_FRAME_FILES_ADDED,
  TRANSCRIPT_FILES_ADDED,
  REMOVE_ALL_FILES,
  EDIT_MODE,
  EDIT_MODE_TITLE,
  SET_EXTERNAL_GROUP,
  ADD_DELETE_ASSET,
  VIDEO_GROUP_STATUS,
  UPDATE_SUCCESSFUL_EVENT,
  EDIT_VTT_FILE,
  SHOW_DEFAULT_ALERT_VIDEOS,
  STORE_VIDEOS_UPLOAD_STRAPI_DATA,
  OPEN_VIDEO_HELP_CONTENT,
  CLEAR_VIDEO_HELP_CONTENT,
  RECEIVE_VIDEO_DATA_BY_ID,
  CLEAR_VIDEO_DATA_BY_ID,
  REQUEST_VIDEO_DATA_BY_ID,
  RECEIVE_TCIN_DATA,
  REQUEST_ADD_TCIN_TO_VIDEO_GROUP,
  SELECT_VIDEO_TCIN_REVIEW_EVENT,
  SET_SHOW_REVIEW_OPTIONS,
  RESET_REJECTION_MESSAGE,
  SET_SHOW_VIDEO_ACTIVE_CHECKBOX,
  SET_VIDEO_ACTIVE,
  SET_REJECTION_MESSAGE,
  SHOW_START_OVER_CONFIRMATION,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { isEmpty, uniqBy, difference, intersection } from 'lodash'
import firefly from '../../../analytics/firefly'
import { successVendorTcinMapping } from '../../auth/actionCreator'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'

export function addedFilesVideo(files) {
  return {
    type: ADD_FILES_VIDEO,
    payload: {
      files: files,
    },
  }
}

export function validNumberFilesVideos(numberValidFiles) {
  return {
    type: VALID_NUMBER_OF_VIDEO_FILES,
    payload: {
      numberValidFiles: numberValidFiles,
    },
  }
}

export function dropZoneActive(data) {
  return {
    type: VIDEOS_DROP_ZONE_ACTIVE,
    payload: {
      dropZoneEnter: data,
    },
  }
}

export function onDropLoading(isFetchingOnDrop) {
  return {
    type: VIDEOS_ON_DROP_LOADING,
    payload: {
      isFetchingOnDrop: isFetchingOnDrop,
    },
  }
}

export function successfullUploadsVideos(message) {
  return (dispatch) => {
    dispatch(resetFetch(false), toast.success(message, TOAST_BODY))
    dispatch(handleSelectVideoTcin({ selectedTcins: [] }))
    dispatch(updateUploadSuccessfull(true))
    firefly.trackClick('VIDEO_SUCCESSFUL_UPLOAD', {
      location: window.location.pathname,
    })
  }
}

export function updateUploadSuccessfull(value) {
  return {
    type: UPDATE_SUCCESSFUL_EVENT,
    payload: {
      uploadComplete: value,
    },
  }
}

export function resetFetch(data) {
  return {
    type: VIDEOS_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function updateInvalidTcinList(tcinList) {
  return {
    type: UPDATE_INVALID_TCIN_LIST,
    payload: {
      invalidTcinList: tcinList,
    },
  }
}

export function updateNotOwnedTcinList(tcinList) {
  return {
    type: UPDATE_NOTOWNED_TCIN_LIST,
    payload: {
      notOwnedTcinList: tcinList,
    },
  }
}

export function addVideoFiles(data) {
  return {
    type: VIDEO_FILES_ADDED,
    payload: {
      videoFileAdded: data,
    },
  }
}

export function addCCFiles(data) {
  return {
    type: CC_FILES_ADDED,
    payload: {
      ccFileAdded: data,
    },
  }
}

export function addPosterFrameFiles(data) {
  return {
    type: POSTER_FRAME_FILES_ADDED,
    payload: {
      posterFrameFileAdded: data,
    },
  }
}

export function addTranscriptFiles(data) {
  return {
    type: TRANSCRIPT_FILES_ADDED,
    payload: {
      transcriptFileAdded: data,
    },
  }
}

export function removeFiles() {
  return {
    type: REMOVE_ALL_FILES,
    payload: {
      videoFileAdded: false,
      ccFileAdded: false,
      posterFrameFileAdded: false,
      transcriptFileAdded: false,
    },
  }
}

export function editModeEnabled(value) {
  return {
    type: EDIT_MODE,
    payload: {
      editMode: value,
    },
  }
}

export function editModeSetTitle(value) {
  return {
    type: EDIT_MODE_TITLE,
    payload: {
      editModeTitle: value,
    },
  }
}

export function setExternalGroupId(value) {
  return {
    type: SET_EXTERNAL_GROUP,
    payload: {
      externalGroupJobId: value,
    },
  }
}

export function addDeleteAsset(value) {
  return {
    type: ADD_DELETE_ASSET,
    payload: {
      deleteAsset: value,
    },
  }
}

export function addVideoGroupStatus(value) {
  return {
    type: VIDEO_GROUP_STATUS,
    payload: {
      videoGroupStatus: value,
    },
  }
}

export function editVttDialogBox(open, fileContent = '', fileName = '') {
  return {
    type: EDIT_VTT_FILE,
    payload: {
      vttEditBoxOpen: open,
      vttEditData: fileContent,
      vttEditBoxTitle: fileName,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_VIDEOS,
    payload: data,
  }
}

export function storeVideosUploadStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) =>
      item.interface_document_type === 'information' && item.interface_id === 0
  )
  const videoData = data.filter(
    (item) =>
      item.interface_document_type === 'information' && item.interface_id === 1
  )
  const closedCaptionData = data.filter(
    (item) =>
      item.interface_document_type === 'information' && item.interface_id === 2
  )
  const posterFrameData = data.filter(
    (item) =>
      item.interface_document_type === 'information' && item.interface_id === 3
  )
  const transcriptData = data.filter(
    (item) =>
      item.interface_document_type === 'information' && item.interface_id === 4
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const videoInfo =
    videoData.length > 0
      ? {
          title: videoData[0].user_facing_title,
          body: videoData[0].user_facing_body,
        }
      : null
  const closedCaptionInfo =
    closedCaptionData.length > 0
      ? {
          title: closedCaptionData[0].user_facing_title,
          body: closedCaptionData[0].user_facing_body,
        }
      : null
  const posterFrameInfo =
    posterFrameData.length > 0
      ? {
          title: posterFrameData[0].user_facing_title,
          body: posterFrameData[0].user_facing_body,
        }
      : null
  const transcriptInfo =
    transcriptData.length > 0
      ? {
          title: transcriptData[0].user_facing_title,
          body: transcriptData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_VIDEOS_UPLOAD_STRAPI_DATA,
    payload: {
      alert,
      information,
      videoInfo,
      closedCaptionInfo,
      posterFrameInfo,
      transcriptInfo,
      faq,
    },
  }
}

export function getVideosUploadStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Video%20Upload&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data)
          ? dispatch(showDefaultAlert(true))
          : dispatch(storeVideosUploadStrapiData(response.data))
        dispatch(showDefaultAlert(false))
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function openVideoHelpContent(data) {
  return {
    type: OPEN_VIDEO_HELP_CONTENT,
    payload: data,
  }
}

export function clearSelectedVideoHelpContent() {
  return {
    type: CLEAR_VIDEO_HELP_CONTENT,
  }
}

function startRequestDataByIdEvent() {
  return {
    type: REQUEST_VIDEO_DATA_BY_ID,
    payload: {
      isFetching: true,
      requestedVideoData: null,
    },
  }
}

function successRequestDataByIdEvent(json) {
  return {
    type: RECEIVE_VIDEO_DATA_BY_ID,
    payload: {
      isFetching: false,
      requestedVideoData: json,
    },
  }
}

export function requestVideoDataById(id) {
  return (dispatch) => {
    dispatch(startRequestDataByIdEvent())
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/external/group_search/full/${id}?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((res) => {
        var item = {
          map_child_assets: res.data.map_child_assets,
          lst_group_item_data: res.data.lst_group_item_data
            ? res.data.lst_group_item_data
            : [],
          external_group_job_id: res.data.external_group_job_id,
          title: res.data.title,
          applied_tcins_set: res?.data?.applied_tcins_set?.sort(
            (a, b) => a - b
          ),
          poster_frame_url: res.data.poster_frame_url,
          default_poster_frame_url: res.data.default_poster_frame_url,
          approval_status: res.data.approval_status,
          external_user_status_message: res.data.external_user_status_message,
          internal_user_status_message: res.data.internal_user_status_message,
          user_status_color: res.data.user_status_color,
          active: res.data.active,
          created_by: res.data.created_by,
          vendor_id: res.data.vendor_id,
          create_time: res.data.create_time,
          last_updated_by: res.data.last_updated_by,
          last_updated_time: res.data.last_updated_time,
          deleted: res.data.deleted,
          auto_approve: res.data.auto_approve,
        }
        dispatch(successRequestDataByIdEvent(item))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function clearRequestedVideoData() {
  return {
    type: CLEAR_VIDEO_DATA_BY_ID,
    payload: {
      isFetching: false,
      requestedVideoData: null,
      selectedTcins: [],
    },
  }
}

export function approveVideoGroup(videoGroup, email) {
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.api.externalApi}group_review/review?emailId=${email}&key=${envConfigs.api.gatewayKey}`,
        videoGroup
      )
      .then(dispatch(clearRequestedVideoData()))
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function startAddTcinToVideoGroupEvent() {
  return {
    type: REQUEST_ADD_TCIN_TO_VIDEO_GROUP,
    payload: {
      isFetching: true,
    },
  }
}

function successRequestTcinDataEvent(json) {
  return {
    type: RECEIVE_TCIN_DATA,
    payload: {
      isFetching: false,
      requestedTcinData: json,
    },
  }
}

export function addTcinToVideoGroup(newTcins, existingTcins, auth) {
  return (dispatch) => {
    dispatch(startAddTcinToVideoGroupEvent())
    if (auth.permission.vendorTcinMap) {
      axios
        .post(
          `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/user_profile/vendor_tcin/${auth.email}?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
          newTcins
        )
        .then((res) => {
          var tcinLists = {
            validTcins: res.data.valid_tcins,
            invalidTcins: res.data.invalid_tcins,
            unauthorizedTcins: res.data.unauthorized_tcins,
          }
          dispatch(successVendorTcinMapping(tcinLists))
          dispatch(
            resetFetch(false),
            toast.info(
              `You submitted ${newTcins.length} TCIN(s), you are authorized to work with ${tcinLists.validTcins.length} TCIN(s)`,
              TOAST_BODY
            )
          )
          return axios
            .post(
              `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/external/group_search/tcin_data?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
              tcinLists.validTcins
            )
            .then((res) => {
              const uniqTcinsData = uniqBy(res.data, 'tcin')
              // @ts-ignore
              const uniqTcinsList = uniqTcinsData.map((tcin) => tcin.tcin)
              const duplicateTcins = intersection(uniqTcinsList, existingTcins)
              const addedTcins = difference(uniqTcinsList, existingTcins)
              // @ts-ignore
              const addedTcinData = uniqTcinsData.filter((uniqtcin) =>
                addedTcins.includes(uniqtcin.tcin)
              )
              const tcinAddMessage =
                'From the list of ' +
                tcinLists.validTcins.length +
                ' authorized TCIN(s), ' +
                uniqTcinsData.length +
                ' parent TCIN(s) were derived. ' +
                duplicateTcins.length +
                ' were already in the list. Therefore, ' +
                addedTcins.length +
                ' total rows were added'
              dispatch(successRequestTcinDataEvent(addedTcinData))
              dispatch(
                resetFetch(false),
                toast.info(tcinAddMessage, TOAST_BODY)
              )
            })
            .catch((error, data) => {
              dispatch(
                resetFetch(false),
                toast.error(error.message, TOAST_BODY)
              )
            })
        })
        .catch((error, data) => {
          dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        })
    } else {
      return axios
        .post(
          `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/external/group_search/tcin_data?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
          newTcins
        )
        .then((res) => {
          const uniqTcinsData = uniqBy(res.data, 'tcin')
          // @ts-ignore
          const uniqTcinsList = uniqTcinsData.map((tcin) => tcin.tcin)
          const duplicateTcins = intersection(uniqTcinsList, existingTcins)
          const addedTcins = difference(uniqTcinsList, existingTcins)
          // @ts-ignore
          const addedTcinData = uniqTcinsData.filter((uniqtcin) =>
            addedTcins.includes(uniqtcin.tcin)
          )
          const tcinAddMessage =
            'From the list of ' +
            newTcins.length +
            ' TCIN(s) entered, ' +
            uniqTcinsData.length +
            ' parent TCIN(s) were derived. ' +
            duplicateTcins.length +
            ' were already in the list. Therefore, ' +
            addedTcins.length +
            ' total rows were added'
          dispatch(successRequestTcinDataEvent(addedTcinData))
          dispatch(resetFetch(false), toast.info(tcinAddMessage, TOAST_BODY))
        })
        .catch((error, data) => {
          dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        })
    }
  }
}

export function handleSelectVideoTcin(data) {
  return {
    type: SELECT_VIDEO_TCIN_REVIEW_EVENT,
    payload: {
      selectedTcins: data.selectedTcins,
    },
  }
}

export function setShowRejectButtons(data) {
  return {
    type: SET_SHOW_REVIEW_OPTIONS,
    payload: {
      showRejectButtons: data,
    },
  }
}

export function resetRejectionMessages() {
  return {
    type: RESET_REJECTION_MESSAGE,
  }
}

export function setShowVideoActiveCheckbox(data) {
  return {
    type: SET_SHOW_VIDEO_ACTIVE_CHECKBOX,
    payload: {
      showVideoActiveCheckbox: data,
    },
  }
}

export function setVideoActive(data) {
  return {
    type: SET_VIDEO_ACTIVE,
    payload: {
      videoIsActive: data,
    },
  }
}

export function setRejectionMessage(data) {
  return {
    type: SET_REJECTION_MESSAGE,
    payload: {
      rejectionMessages: data,
    },
  }
}

export function showStartOverConfirmation(data) {
  return {
    type: SHOW_START_OVER_CONFIRMATION,
    payload: {
      openStartOverModal: data,
    },
  }
}
