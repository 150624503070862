import React from 'react'
import TagTrainingPage from './TagTrainingPage'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  selectTrainingImage,
  clearSelectedTrainingImages,
  removeImageFromModelTraining,
  setDeleteConfirmationOpen,
  removeSelectedImageFromTrainingDeleteConfirmation,
} from '../../../../../../store/images/tagTraining/actionCreator'

class TagTrainingPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  handleDialogOpenStatus = (open) => {
    this.props.setDeleteConfirmationOpen(open)
  }

  toggleSelect = (image) => {
    this.props.selectTrainingImage(image)
  }

  deleteImagesFromModel = () => {
    this.props.removeImageFromModelTraining(
      this.props.imageInfo,
      this.props.auth.email
    )
  }

  render() {
    const { imageUrlsFetching, imageInfo } = this.props
    return (
      <TagTrainingPage
        imageUrlsFetching={imageUrlsFetching}
        imageInfo={imageInfo}
        selectToggleMainGrid={(image) => this.toggleSelect(image)}
        selectToggleDeleteConfirmation={(image) =>
          this.props.removeSelectedImageFromTrainingDeleteConfirmation(image)
        }
        handleDialogOpenStatus={(open) => this.handleDialogOpenStatus(open)}
        deleteImages={() => this.deleteImagesFromModel()}
        isDialogOpen={this.props.deleteConfirmationisOpen}
        deletingInProgress={this.props.deletingInProgress}
        clearSelectedTrainingImages={() =>
          this.props.clearSelectedTrainingImages()
        }
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectTrainingImage,
      clearSelectedTrainingImages,
      removeImageFromModelTraining,
      setDeleteConfirmationOpen,
      removeSelectedImageFromTrainingDeleteConfirmation,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { training } = imageTagTraining
  const {
    imageUrlsFetching,
    imageInfo,
    deleteConfirmationisOpen,
    deletingInProgress,
  } = training
  return {
    auth,
    imageUrlsFetching,
    imageInfo,
    deleteConfirmationisOpen,
    deletingInProgress,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagTrainingPageContainer)
