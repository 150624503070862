import React from 'react'
import { TableHead, TableRow, TableCell } from '@material-ui/core'

class TagDataTableHeadContainer extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { columnHeaderData } = this.props

    return (
      <TableHead>
        <TableRow>
          {columnHeaderData.map((column) => {
            return (
              <TableCell
                key={column.id}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                {column.label}
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

export default TagDataTableHeadContainer
