import React from 'react'
import { Card, Grid, CardContent } from '@material-ui/core'
import styles from '../../../theme'
import { withStyles } from '@material-ui/core/styles'
import ItemDetailContainer from '../ItemDetail/ItemDetailContainer'
import ItemBadgesContainer from '../ItemBadges/ItemBadgesContainer'
import ClaimViewer from './ClaimViewer'
import SubjectiveClaimsPopUp from './SubjectiveClaimsPopUp'
import Magnifier from 'react-magnifier'
import CheckCircle from '@material-ui/icons/CheckCircle'
import NoThumbnail from '../../../../../../images/NoThumbnail.svg?url'
import envConfigs from '../../../../../../config/apiConfig'

function ImageViewer(props) {
  const {
    currentImagesSelected = [],
    classes = {},
    updateCurrentSelectedImage = () => {},
    selectedItemData = {},
    currentImage = {},
    currentSwatch = {},
    updateCurrentImages = () => {},
    swatchData = [],
    claimsData = [],
    claimsError = null,
    subjectiveClaimsPopUp = () => {},
    subjectiveClaimsPopUpData,
  } = props

  const imageWidHeight = '?scl=1&fmt=pjpeg'

  const swatches =
    swatchData &&
    swatchData.map((item) => {
      return {
        label: item.color,
        value: item,
        swatchUrl: item.baseUrl + item.swatchUrl,
      }
    })

  return (
    <React.Fragment>
      {selectedItemData ? (
        <Grid item xs={12}>
          <Card className={classes.addScrollBar}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={2}>
                      <Grid
                        container
                        direction="column"
                        justify="flex-end"
                        alignItems="flex-start"
                      >
                        <div className={classes.marginBottom20}>
                          <Grid
                            container
                            item
                            xs={12}
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                          >
                            {currentSwatch && currentSwatch.label && (
                              <Grid item xs={12}>
                                <div className={classes.marginLeft15}>
                                  <b>Color:</b> {currentSwatch.label}
                                </div>
                              </Grid>
                            )}
                            {swatches.map((item, index) => (
                              <div
                                className={classes.swatchDiv}
                                key={item.label + index}
                              >
                                <img
                                  src={item.swatchUrl}
                                  alt={item.swatchUrl}
                                  onClick={() => updateCurrentImages(item)}
                                  role="presentation"
                                  className={
                                    currentSwatch.swatchUrl === item.swatchUrl
                                      ? classes.swatchImageSelected
                                      : classes.swatchImage
                                  }
                                  onError={(e) => {
                                    e.target.src =
                                      envConfigs.api.sceneSevenBaseUrl +
                                      'pipeline_swatch_missing?hei=40&wei=40&1576512574295'
                                  }}
                                />
                                {currentSwatch.swatchUrl === item.swatchUrl && (
                                  <CheckCircle
                                    className={`${classes.selectedCheck} ${classes.fontSize18}`}
                                  />
                                )}
                              </div>
                            ))}
                          </Grid>
                        </div>
                        <Grid
                          container
                          item
                          xs={12}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          {currentImagesSelected &&
                            currentImagesSelected.map((item) => (
                              <div
                                className={classes.swatchDiv}
                                key={item.label}
                              >
                                <img
                                  src={item.imageUrl}
                                  alt={item.imageUrl}
                                  onClick={() =>
                                    updateCurrentSelectedImage(item)
                                  }
                                  role="presentation"
                                  className={
                                    currentImage.imageUrl === item.imageUrl
                                      ? classes.swatchImageSelected
                                      : classes.swatchImage
                                  }
                                  onError={(e) => {
                                    e.target.src = NoThumbnail
                                  }}
                                />
                                {currentImage.imageUrl === item.imageUrl && (
                                  <CheckCircle
                                    className={`${classes.selectedCheck} ${classes.fontSize18}`}
                                  />
                                )}
                              </div>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Card>
                          {currentImage.imageUrl && (
                            <Magnifier
                              src={currentImage.imageUrl + imageWidHeight}
                              className={props.classes.selectedImage}
                              mgWidth={500}
                              zoomFactor={1}
                              mgHeight={500}
                              mgShape={'square'}
                            />
                          )}
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      {selectedItemData &&
                        selectedItemData.wellness_merchandise_attributes && (
                          <ItemBadgesContainer />
                        )}
                      <ItemDetailContainer />
                    </Grid>
                    <Grid item xs={3}>
                      <ClaimViewer
                        claimsData={claimsData}
                        claimsError={claimsError}
                        subjectiveClaimsPopUp={subjectiveClaimsPopUp}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <SubjectiveClaimsPopUp
            claimsData={claimsData}
            claimsError={claimsError}
            subjectiveClaimsPopUp={subjectiveClaimsPopUp}
            subjectiveClaimsPopUpData={subjectiveClaimsPopUpData}
          />
        </Grid>
      ) : (
        <Grid container justify="center">
          <span className={`${classes.noHistoryData} ${classes.minHeightCard}`}>
            No Image data available
          </span>
        </Grid>
      )}
    </React.Fragment>
  )
}
export default withStyles(styles)(ImageViewer)
