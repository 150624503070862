import React from 'react'
import TagOverviewDataTableContainer from '../TagOverviewDataTable/TagOverviewDataTableContainer'
import { Paper } from '@material-ui/core'

function TagsPage(props) {
  return (
    <React.Fragment>
      <Paper>
        <TagOverviewDataTableContainer />
      </Paper>
    </React.Fragment>
  )
}

export default TagsPage
