import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
} from '@material-ui/core'
import AddTcinContainer from '../AddTcin/AddTcinContainer'
import ExampleImageFinderContainer from '../ExampleImageFinder/ExampleImageFinderContainer'

function SelectExampleImageStepper(props) {
  const { activeStep, backToEnterTcin, classes } = props
  return (
    <React.Fragment>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key="label">
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.stepperIcon,
                completed: classes.completed,
                active: classes.active,
              },
            }}
          >
            Specify a TCIN to find images to select from
          </StepLabel>
          <StepContent>
            <AddTcinContainer />
          </StepContent>
        </Step>
        {/* Step 2 */}
        <Step className={classes.checkColor} key="label2">
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.stepperIcon,
                completed: classes.completed,
                active: classes.active,
              },
            }}
          >
            Select a specific image{' '}
            {activeStep === 1 && (
              <Button
                variant="outlined"
                color="primary"
                onClick={backToEnterTcin}
              >
                Go Back
              </Button>
            )}
          </StepLabel>
          <StepContent>
            <ExampleImageFinderContainer />
          </StepContent>
        </Step>
      </Stepper>
    </React.Fragment>
  )
}

export default withStyles(styles)(SelectExampleImageStepper)
