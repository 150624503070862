import {
  SEARCH_SET_FETCH,
  RECEIVE_VENDOR_LIST,
  RECEIVE_BRANDS_LIST,
} from './actionType'

const DefaultState = {
  isFetching: false,
  vendorList: [],
  brandsList: [],
}

export default function lovReducer(state = DefaultState, action = {}) {
  switch (action.type) {
    case SEARCH_SET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case RECEIVE_VENDOR_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        vendorList: payload.vendorList,
      }
      return newState
    }
    case RECEIVE_BRANDS_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        brandsList: payload.brandsList,
      }
      return newState
    }
    default:
      return state
  }
}
