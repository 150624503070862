import {
  REQUEST_IMAGE_REVIEW_HISTORY_DATA,
  RECIEVE_IMAGE_REVIEW_HISTORY_DATA,
  ERROR_SEARCHING_IMAGE_REVIEW_HISTORY,
  IMAGE_REVIEW_HISTORY_RESET_FETCH,
  CLEAR_REVIEW_HISTORY_DATA,
  SELECT_IMAGE_REVIEW_HISTORY_EVENT,
  STORE_IMAGE_REVIEW_HISTORY_STRAPI_DATA,
  SHOW_DEFAULT_ALERT_IMAGE_REVIEW_HISTORY,
} from './actionType'

export const initState = {
  isFetching: false,
  pageName: 'ImageReviewHistory',
  errorSearching: false,
  reviewHistoryData: [],
  selectedReviewHistoryImages: [],
  showDefaultAlert: false,
  alert: null,
  information: null,
  faq: null,
  error: null,
}

export default function imageReviewHistoryReducer(
  state = initState,
  action = {}
) {
  switch (action.type) {
    case REQUEST_IMAGE_REVIEW_HISTORY_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case RECIEVE_IMAGE_REVIEW_HISTORY_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        reviewHistoryData: payload.reviewHistoryData,
        selectedReviewHistoryImages: initState.selectedReviewHistoryImages,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case IMAGE_REVIEW_HISTORY_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case CLEAR_REVIEW_HISTORY_DATA: {
      const newState = {
        ...state,
        reviewHistoryData: initState.reviewHistoryData,
        selectedReviewHistoryImages: initState.selectedReviewHistoryImages,
      }
      return newState
    }
    case ERROR_SEARCHING_IMAGE_REVIEW_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    case SELECT_IMAGE_REVIEW_HISTORY_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedReviewHistoryImages: payload.selectedReviewHistoryImages,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_IMAGE_REVIEW_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case STORE_IMAGE_REVIEW_HISTORY_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
        error: payload.error,
      }
      return newState
    }
    default:
      return state
  }
}
