import React from 'react'
import TagSelection from './TagSelection'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getAllTagsByGroup,
  setClassifierActiveStep,
  tagDataSelection,
  getSimilarItems,
} from '../../../../../../store/images/tagTraining/actionCreator'

class TagSelectionContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {
    this.props.getAllTagsByGroup()
  }

  tagSelectedContinue = () => {
    this.props.setClassifierActiveStep(3)
    let emailId = this.props.auth.email
      ? this.props.auth.email
      : this.props.auth.lanId
    this.props.getSimilarItems(
      this.props.selectedImage,
      this.props.tagSelectedData,
      emailId
    )
  }

  tagDataSelection = (data) => {
    this.props.tagDataSelection(data)
  }
  render() {
    const {
      selectedImage,
      tagGroups,
      tagSelectedData,
      tagDataFetching,
      stepIsDisabled,
      activeStep,
    } = this.props
    return (
      <TagSelection
        selectedImage={selectedImage}
        tagSelectedContinue={this.tagSelectedContinue}
        tagData={tagGroups}
        tagDataSelection={this.tagDataSelection}
        tagSelectedData={tagSelectedData}
        tagDataFetching={tagDataFetching}
        stepIsDisabled={stepIsDisabled}
        activeStep={activeStep}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setClassifierActiveStep,
      tagDataSelection,
      getSimilarItems,
      getAllTagsByGroup,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { tagGroups, classifierSelection } = imageTagTraining
  const {
    selectedImage,
    tcinList,
    tagSelectedData,
    tagDataFetching,
    activeStep,
  } = classifierSelection
  return {
    auth,
    selectedImage,
    tagSelectedData,
    tcinList,
    tagDataFetching,
    tagGroups,
    activeStep,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagSelectionContainer)
