//

import ListItemText from '@material-ui/core/ListItemText'

export function Text(props) {
  const { children, ...additionalProps } = props
  return <ListItemText primary={children} {...additionalProps} />
}

Text.displayName = 'Text'

export default Text
