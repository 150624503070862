import React from 'react'
import ExampleImageFinder from './ExampleImageFinder'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  selectedTagImageContinue,
  selectImage,
} from '../../../../../../store/images/tagTraining/actionCreator'

class ExampleImageFinderContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  selectImage = (item) => {
    this.props.selectImage(item)
  }

  imageSelectedContinue = () => {
    this.props.selectedTagImageContinue(this.props.selectedImage.publish_url)
  }

  render() {
    const { tcinList, imageList, imageListFetching, selectedImage } = this.props
    return (
      <ExampleImageFinder
        tcinList={tcinList}
        imageList={imageList}
        imageListFetching={imageListFetching}
        selectImage={this.selectImage}
        selectedImage={selectedImage}
        imageSelectedContinue={this.imageSelectedContinue}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectImage,
      selectedTagImageContinue,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { exampleImageSelection } = imageTagTraining
  const { tcinList, imageList, selectedImage, imageListFetching } =
    exampleImageSelection
  return {
    tcinList,
    auth,
    imageList,
    imageListFetching,
    selectedImage,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExampleImageFinderContainer)
