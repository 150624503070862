import React from 'react'
import {
  Typography,
  Toolbar,
  Grid,
  IconButton,
  Link,
  Button,
  CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import GetAppIcon from '@material-ui/icons/GetApp'

let BadgesTableContainerTitle = (props) => {
  const {
    classes = {},
    totalElements = 0,
    downloadAllToExcel = () => {},
    openCreateBadgeModal = () => {},
    isBadgesFetching = false,
    editableBadge = {},
  } = props

  return (
    <Toolbar>
      <Grid justify="space-between" container spacing={3} alignItems="center">
        <Grid item>
          <div className={classes.title}>
            <Typography
              variant="subtitle1"
              className={classes.totalElementsTitle}
              id="tableTitle"
            >
              {totalElements} Badges
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <Button
            onClick={() => openCreateBadgeModal(editableBadge)}
            variant="contained"
            color="primary"
          >
            <span className={classes.marginLeft5}>Create New Badge</span>
          </Button>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            spacing={1}
          >
            {isBadgesFetching ? (
              <CircularProgress />
            ) : (
              <React.Fragment>
                <Grid item>
                  <IconButton onClick={() => downloadAllToExcel()}>
                    <GetAppIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="caption" id="tableTitle">
                    <Link
                      href="#"
                      className={classes.marginRight20}
                      onClick={() => props.downloadAllToExcel()}
                    >
                      Download All to Excel
                    </Link>
                  </Typography>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

BadgesTableContainerTitle.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BadgesTableContainerTitle)
