import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import CopyDetailLayout from './CopyDetailLayout'
import { changeTabEdit } from '../../../../../../store/longCopy/editLongCopyActionCreator'

class CopyDetailContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  handleTabChange = (event, currentTabIndex) => {
    this.props.changeTabEdit(currentTabIndex)
  }

  render() {
    return (
      <CopyDetailLayout
        historyIndex={this.props.historyIndex}
        handleTabChange={this.handleTabChange}
        currentTabIndex={this.props.currentTabIndex}
        hideDot={
          this.props.plannerNotes ? this.props.plannerNotes.length < 1 : true
        }
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeTabEdit,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { longCopy } = state
  const { historyIndex, currentTabIndex, plannerNotes } = longCopy
  return {
    historyIndex,
    currentTabIndex,
    plannerNotes,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CopyDetailContainer)
