import React from 'react'
import ModelsListContainer from '../ModelsList/ModelsListContainer'
import ImageGridContainer from '../../../Shared/Components/ImageGrid/ImageGridContainer'
import { Paper, Grid, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'

function TagTrainingPage(props) {
  const {
    classes,
    imageUrlsFetching,
    imageInfo,
    selectToggleMainGrid,
    selectToggleDeleteConfirmation,
    handleDialogOpenStatus,
    deleteImages,
    isDialogOpen,
    deletingInProgress,
    clearSelectedTrainingImages,
  } = props
  return (
    <React.Fragment>
      <Paper>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={3}>
            <div className={classes.tagPageContainerTitle}>
              <div className={classes.fontWeight}>Tags</div>
            </div>
            <div>
              <ModelsListContainer />
            </div>
          </Grid>
          {imageUrlsFetching ? (
            <CircularProgress
              disableShrink
              className={classes.progressCircle}
            />
          ) : (
            <Grid item xs={9}>
              {imageInfo.group && (
                <div className={classes.tagPageContainerTitle}>
                  <div className={classes.fontWeight}> Type: </div>{' '}
                  <div className={classes.typePadding}>{imageInfo.group}</div>
                  <div className={classes.fontWeight}> Tag: </div>{' '}
                  <div className={classes.paddingLeft10}>{imageInfo.label}</div>
                </div>
              )}
              <div>
                <ImageGridContainer
                  images={imageInfo.images}
                  selectToggleMainGrid={(image) => selectToggleMainGrid(image)}
                  selectToggleDeleteConfirmation={(image) =>
                    selectToggleDeleteConfirmation(image)
                  }
                  clearSelectedTrainingImages={() =>
                    clearSelectedTrainingImages()
                  }
                  handleDialogOpenStatus={(open) =>
                    handleDialogOpenStatus(open)
                  }
                  isDialogOpen={isDialogOpen}
                  deletingInProgress={deletingInProgress}
                  deleteImages={() => deleteImages()}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>
    </React.Fragment>
  )
}

export default withStyles(styles)(TagTrainingPage)
