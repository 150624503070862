/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import HistoryPage from './HistoryPage'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getVideoHistoryData,
  handleChangePage,
  changeDefaultPageSize,
  changeSearchItem,
} from '../../../../store/videos/history/actionCreator'
import {
  setShowVideoActiveCheckbox,
  resetRejectionMessages,
  setShowRejectButtons,
  requestVideoDataById,
  editModeEnabled,
} from '../../../../store/videos/upload/actionCreator'
import { withRouter } from '../../../Shared/WithRouterHoc'
import { VIDEO_EDIT_MODE } from '../../../Shared/Constants'
import ItemSearchVideoHostory from './ItemSearchHistoryFilter'
import { splitTcins } from '../../../Shared/SharedUtils'

import { Grid, CircularProgress } from '@material-ui/core'

function HistoryPageContainer(props) {
  let userId = props.auth.email ? props.auth.email : props.auth.lanId
  const initialLoad = () => {
    props.getVideoHistoryData(0, props.defaultPageSize, userId, false)
  }

  useEffect(() => {
    if (props.searchedItems?.length > 0) {
      props.getVideoHistoryData(
        props.currentPage,
        props.defaultPageSize,
        userId,
        true,
        props.searchedItems
      )
    } else {
      initialLoad()
    }
  }, [])

  const handleChangePage = (event, page) => {
    if (props.searchedItems?.length > 0) {
      props.getVideoHistoryData(
        page,
        props.defaultPageSize,
        userId,
        true,
        props.searchedItems
      )
    } else {
      props.handleChangePage(
        {
          currentPage: page,
          defaultPageSize: props.defaultPageSize,
        },
        userId,
        false
      )
    }
  }

  const handleChangeDefaultPageSize = (event) => {
    if (props.searchedItems?.length > 0) {
      props.getVideoHistoryData(
        0,
        event.target.value,
        userId,
        true,
        props.searchedItems
      )
    } else {
      props.changeDefaultPageSize(event.target.value, userId, false)
    }
  }

  const requestVideoDataById = (id) => {
    props.resetRejectionMessages()
    props.setShowVideoActiveCheckbox(false)
    props.setShowRejectButtons(false)
    props.requestVideoDataById(id)
    //props.history.push(`/v2/video/videoEdit/${id}`)
    props.router.navigate({ pathname: `/v2/video/videoEdit/${id}` })
    props.editModeEnabled(VIDEO_EDIT_MODE.HISTORY)
  }

  const emptyRows =
    props.defaultPageSize -
    Math.min(
      props.defaultPageSize,
      (props.totalElements - props.currentPage) * props.defaultPageSize
    )

  const handleAddTcin = (selectedTcins) => {
    let chips = []
    splitTcins(selectedTcins).forEach((i) => {
      chips.push(i)
    })
    if (props.searchedItems !== undefined && props.searchedItems.length > 0) {
      props.searchedItems.forEach((item) => {
        chips.push(item)
      })
    }
    props.changeSearchItem(chips)
  }

  const handleDeleteTcin = (deletedTcin) => {
    let chips = []
    props.searchedItems.forEach((item) => {
      if (item !== deletedTcin) {
        chips.push(item)
      }
    })
    if (props?.searchedItems?.length === 1) {
      initialLoad()
    }
    props.changeSearchItem(chips)
  }

  const addTcinForVideoFilter = () => {
    props.getVideoHistoryData(
      0,
      props.defaultPageSize,
      userId,
      true,
      props.searchedItems
    )
  }

  return (
    <>
      <ItemSearchVideoHostory
        handleAddTcin={handleAddTcin}
        handleDeleteTcin={handleDeleteTcin}
        searchedItems={props.searchedItems}
        addTcinForVideoFilter={addTcinForVideoFilter}
        changeSearchItem={props.changeSearchItem}
        initialLoad={initialLoad}
      />
      {props?.isFetching && (
        <Grid class="alignCenterCircularBar">
          <CircularProgress />
        </Grid>
      )}
      <HistoryPage
        isFetching={props?.isFetching}
        videoHistoryData={props.videoHistoryData}
        rowsPerPage={props.defaultPageSize}
        page={props.currentPage}
        emptyRows={emptyRows}
        totalElements={props.totalElements}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeDefaultPageSize}
        auth={props.auth}
        requestVideoDataById={requestVideoDataById}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVideoHistoryData,
      handleChangePage,
      changeDefaultPageSize,
      requestVideoDataById,
      setShowRejectButtons,
      setShowVideoActiveCheckbox,
      resetRejectionMessages,
      editModeEnabled,
      changeSearchItem,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { videoHistory, auth } = state
  const {
    videoHistoryData,
    currentPage,
    defaultPageSize,
    totalElements,
    searchedItems,
    isFetching,
  } = videoHistory
  return {
    videoHistoryData,
    currentPage,
    defaultPageSize,
    totalElements,
    auth,
    searchedItems,
    isFetching,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HistoryPageContainer))
