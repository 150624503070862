import { MuiThemeProvider } from '@material-ui/core/styles'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'
import muiTheme from '../config/themeConfig'
import 'react-toastify/dist/ReactToastify.css'
import { AuthProvider } from '@praxis/component-auth'
import config from '../config/apiConfig'
import { Main } from './signInMainSuccess'

const oauthConfig = {
  authorizationUrl: window.location.hostname.includes('partnersonline.com')
    ? `${config.auth.externalHost}${config.auth.externalAuthorizationPath}`
    : `${config.auth.host}${config.auth.authorizationPath}`,
  clientId: config.auth.clientId,
  logoutUrl: window.location.hostname.includes('partnersonline.com')
    ? `${config.auth.externalLogoutHost}${config.auth.logoutPath}`
    : `${config.auth.logoutHost}${config.auth.logoutPath}`,
}

const App = () => (
  <AuthProvider
    clientId={config.auth.clientId}
    loginRedirect={config.auth.redirectUri}
    authorizationUrl={oauthConfig.authorizationUrl}
    logoutUrl={oauthConfig.logoutUrl}
  >
    <MuiThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
      />
    </MuiThemeProvider>
  </AuthProvider>
)

export default App
