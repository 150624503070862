import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth/reducer'
import badges from './badges/reducer'
import bulkUpload from './bulkUpload/reducer'
import bulkUploadReport from './bulkUploadReport/reducer'
import imageHistory from './images/history/reducer'
import imageManage from './images/manage/reducer'
import imageReview from './images/review/reducer'
import imageReviewHistory from './images/reviewHistory/reducer'
import imageSearch from './images/search/reducer'
import imageTags from './images/tags/reducer'
import imageTagTraining from './images/tagTraining/reducer'
import imageUpload from './images/upload/reducer'
import layout from '../store/layout/reducer'
import longCopy from './longCopy/reducer'
import prodSpecs from './prodSpecs/reducer'
import signInPrompt from './signInPrompt/reducer'
import stageCorona from './images/stageCorona/reducer'
import sizeChartEdit from './sizeChart/sizeChartEdit/reducer'
import sizeChartRuleBuilder from './sizeChart/ruleBuilder/reducer'
import sizeChartRuleSelection from './sizeChart/ruleSelection/reducer'
import upload from './upload/reducer'
import videoHistory from './videos/history/reducer'
import videoReview from './videos/review/reducer'
import videoUpload from './videos/upload/reducer'
import help from './help/reducer'
import lov from './listOfValues/reducer'
import altTextReducer from './altText/reducer'

const rootReducer = combineReducers({
  auth,
  badges,
  bulkUpload,
  bulkUploadReport,
  form: formReducer,
  imageHistory,
  imageManage,
  imageReview,
  imageReviewHistory,
  imageSearch,
  imageTags,
  imageTagTraining,
  imageUpload,
  layout,
  longCopy,
  prodSpecs,
  signInPrompt,
  sizeChartEdit,
  sizeChartRuleBuilder,
  sizeChartRuleSelection,
  stageCorona,
  upload,
  videoHistory,
  videoReview,
  videoUpload,
  lov,
  help,
  altTextReducer,
})

export default rootReducer
