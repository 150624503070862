import React from 'react'
import { Grid, Fab, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import DeleteIcon from '@material-ui/icons/Delete'
import UndoIcon from '@material-ui/icons/Undo'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DeleteConfirmationDialogContainer from '../DeleteConfirmationDialog/DeleteConfirmationDialogContainer'

function ImageGrid(props) {
  const {
    images,
    selectToggleMainGrid,
    clearSelectedTrainingImages,
    handleDialogOpenStatus,
    selectToggleDeleteConfirmation,
    deleteImages,
    isDialogOpen,
    deletingInProgress,
    classes,
  } = props
  const imageDimensions = '?hei=250&wid=250'
  const selectedImages =
    images.length &&
    images.filter((image) => {
      return image.isSelected
    })
  const reversedImages = [...images].reverse()
  return (
    <React.Fragment>
      <DeleteConfirmationDialogContainer
        images={images}
        handleDialogOpenStatus={(open) => handleDialogOpenStatus(open)}
        deleteImages={() => deleteImages()}
        isDialogOpen={isDialogOpen}
        deletingInProgress={deletingInProgress}
        selectToggleDeleteConfirmation={(image) =>
          selectToggleDeleteConfirmation(image)
        }
      />
      {
        <Grid container>
          {reversedImages.map((image) => (
            <Grid item key={image.url}>
              <div>
                <img
                  className={
                    image.isSelected
                      ? classes.imageBorder
                      : classes.imageWithoutBorder
                  }
                  src={`${image.url}${imageDimensions}`}
                  alt="tag"
                  role="presentation"
                  onClick={() => selectToggleMainGrid(image)}
                />
                {image.isSelected && (
                  <CheckCircle className={classes.selectedCheck} />
                )}
              </div>
            </Grid>
          ))}
          {selectedImages.length > 0 && (
            <React.Fragment>
              <Tooltip title="Clear All" aria-label="Add">
                <Fab
                  className={classes.clearSelectedImagesFab}
                  onClick={() => clearSelectedTrainingImages()}
                  color="primary"
                >
                  <UndoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Remove Selected Images" aria-label="Add">
                <Fab
                  className={classes.deleteSelectedImagesFab}
                  onClick={() => handleDialogOpenStatus(true)}
                  color="primary"
                >
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </React.Fragment>
          )}
        </Grid>
      }
    </React.Fragment>
  )
}

export default withStyles(styles)(ImageGrid)
