import React from 'react'
import HeaderTitle from '../../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import TagTrainingPageContainer from './Components/TagsTrainingPage/TagTrainingPageContainer'

class TagTraining extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <React.Fragment>
        <HeaderTitle title="Tags" />
        <Helmet title="Tags" />
        <TagTrainingPageContainer />
      </React.Fragment>
    )
  }
}

export default TagTraining
