import PropTypes from 'prop-types'
import {
  Drawer,
  Grid,
  Typography,
  Toolbar,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../../../theme'
import CopyDataContainerToolbar from '../CopyDataContainerToolbar'

let CopyDataActionDrawer = (props) => {
  const {
    selectedCopy,
    classes,
    isActionDrawerOpen,
    toggleActionDrawer,
    toggleActionDrawerForAssigningWriters,
    drawerAction,
    selectRouteTeam,
    currentRouteTeam,
    currentWriterAssigned,
    handleChangePlannerNotes,
    handlePlannerNotesEvent,
    isLoading,
    isFetchingWriters,
    handleRoutingTeamEvent,
    pristine,
    auth,
    closeEscapeKey,
    selectAssignedWriter,
    assignedWritersList,
    assignWriterToCopy,
    deleteData,
    handleValueDeleteOption,
    selectTypeCopyBulletDelete,
    cancelCopyBulletDelete,
    downloadSelectedTcinsCopy,
    deleteBulletAndCopy,
  } = props

  const selectedItemsHasNote = selectedCopy.filter(
    (item) => item.planner_note !== undefined && item.planner_note !== ''
  ).length

  return (
    <Drawer
      anchor="bottom"
      open={isActionDrawerOpen}
      onKeyDown={(event) => closeEscapeKey(event, false)}
    >
      {isActionDrawerOpen && (
        <CopyDataContainerToolbar
          selectedCopy={selectedCopy}
          toggleActionDrawer={toggleActionDrawer}
          toggleActionDrawerForAssigningWriters={
            toggleActionDrawerForAssigningWriters
          }
          downloadSelectedTcinsCopy={downloadSelectedTcinsCopy}
          deleteBulletAndCopy={deleteBulletAndCopy}
          auth={auth}
          isActionDrawerOpen={isActionDrawerOpen}
        />
      )}
      <Toolbar>
        {drawerAction === 'ROUTE_ACTION' && (
          <Grid container className={classes.margin}>
            <Grid item xs={12}>
              <Typography variant="title" gutterBottom>
                <b>Route items for Copy Writing</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-label="copyWritingTeam"
                  name="copyWritingTeam"
                  value={currentRouteTeam}
                  onChange={(event) => selectRouteTeam(event)}
                >
                  <FormControlLabel
                    value="Periscope"
                    control={<Radio color="primary" />}
                    label="Periscope"
                  />
                  <FormControlLabel
                    value="TargetIndia"
                    control={<Radio color="primary" />}
                    label="Target India"
                  />
                  <FormControlLabel
                    value="None"
                    control={<Radio color="primary" />}
                    label="None (vendor will provide)"
                  />
                </RadioGroup>
                <Grid>
                  {isLoading && <CircularProgress size={25} color="primary" />}
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => toggleActionDrawer(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.marginLeft5}
                    onClick={() => handleRoutingTeamEvent()}
                  >
                    Route
                  </Button>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {drawerAction === 'ASSIGN_ACTION' && (
          <Grid container className={classes.margin}>
            <Grid item xs={12}>
              <Typography variant="title" gutterBottom>
                <b>Assign Copy to Writer</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                {isFetchingWriters ? (
                  <CircularProgress size={25} color="primary" />
                ) : (
                  <Select
                    className={`${classes.assignToUserSectionDrawer} ${classes.marginBottom10}`}
                    value={currentWriterAssigned}
                    onChange={(event) =>
                      selectAssignedWriter(event.target.value)
                    }
                  >
                    {assignedWritersList.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <Grid container direction="row" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => toggleActionDrawerForAssigningWriters(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.marginLeft5}
                    disabled={
                      isFetchingWriters || currentWriterAssigned === undefined
                    }
                    onClick={() => assignWriterToCopy()}
                  >
                    Assign
                  </Button>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {drawerAction === 'NOTE_ACTION' && (
          <Grid container className={classes.margin}>
            <Grid item xs={12}>
              <Typography variant="title" gutterBottom>
                <b>Write Note</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <TextField
                  id="multiline-flexible"
                  label="Notes"
                  helperText="Please provide notes"
                  multiline
                  rows="7"
                  fullWidth
                  onBlur={(event) => handleChangePlannerNotes(event)}
                  className={props.classes.notesSectionDrawer}
                />
                <Grid>
                  {selectedItemsHasNote && (
                    <div className={classes.noteWarning}>
                      One or more selected items has a note. Saving a note here
                      will overwrite any existing notes.
                    </div>
                  )}
                  {isLoading && (
                    <CircularProgress size={25} color="secondary" />
                  )}
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => toggleActionDrawer(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={pristine}
                    variant="contained"
                    color="primary"
                    className={classes.marginLeft5}
                    onClick={() => handlePlannerNotesEvent()}
                  >
                    Save
                  </Button>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {drawerAction === 'DELETE_ACTION' && (
          <Grid container className={classes.margin}>
            <Grid item xs={12}>
              <Typography variant="title" gutterBottom>
                <span className={classes.deleteCopyFeatureTitle}>
                  Delete copy / bullets?
                </span>
              </Typography>
              <span className={classes.deleteCopyFeatureText}>
                Do you want to remove copy and/or feature bullets for the
                selected items?
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-label="CopyFeatureBullet"
                  name="CopyFeatureBullet"
                  value={deleteData.selectDeleteType}
                  onChange={handleValueDeleteOption}
                >
                  <FormControlLabel
                    value="COPY_ONLY"
                    control={<Radio color="primary" />}
                    label="COPY"
                  />
                  <FormControlLabel
                    value="FEATURE_BULLETS_ONLY"
                    control={<Radio color="primary" />}
                    label="BULLETS"
                  />
                  <FormControlLabel
                    value="COPY_AND_FEATURE_BULLETS"
                    control={<Radio color="primary" />}
                    label="BOTH"
                  />
                </RadioGroup>
                <Grid container direction="row" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      toggleActionDrawer(false)
                      cancelCopyBulletDelete()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.marginLeft5}
                    onClick={() => selectTypeCopyBulletDelete()}
                  >
                    Delete
                  </Button>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </Drawer>
  )
}

CopyDataActionDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedCopy: PropTypes.array.isRequired,
}

export default withStyles(styles)(CopyDataActionDrawer)
