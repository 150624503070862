import React from 'react'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { some } from 'lodash'
import styles from '../../../theme'

import { Grid } from '@material-ui/core'

function escapeHtml(input) {
  return { __html: input }
}

function SubjectiveClaimsPopUp(props) {
  const {
    claimsData = [],
    claimsError = null,
    classes = {},
    subjectiveClaimsPopUpData,
    subjectiveClaimsPopUp,
  } = props

  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted
        maxWidth="md"
        open={subjectiveClaimsPopUpData}
        aria-labelledby="alert-dialog-image-label"
        aria-describedby="alert-dialog-imageLable-description"
      >
        <DialogTitle
          className={classes.saveFilterTitle}
          id="alert-dialog-image-label"
        >
          <div>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.saveTitleText}
            >
              Additional Copy Features
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.marginTop20}
            id="alert-dialog-imageLable-description"
          >
            <Grid item>
              {claimsError && (
                <div data-test-id={'claims-error'}>Error: {claimsError}</div>
              )}
              {!claimsError && some(claimsData) && (
                <div>
                  <div
                    className={`${classes.productDetailContainer} ${classes.removePadding}`}
                  >
                    {claimsData &&
                      claimsData.map((claim, parentIndex) => {
                        return (
                          <div>
                            <div>
                              <ul className={classes.subjectiveStamentAlign}>
                                {claim &&
                                  claim.subjectiveStatements &&
                                  claim.subjectiveStatements.map(
                                    (item, index) => {
                                      return (
                                        <li
                                          key={index}
                                          dangerouslySetInnerHTML={escapeHtml(
                                            item
                                          )}
                                        />
                                      )
                                    }
                                  )}
                              </ul>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            data-testid="Close"
            onClick={() => subjectiveClaimsPopUp(!subjectiveClaimsPopUpData)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default withStyles(styles)(SubjectiveClaimsPopUp)
