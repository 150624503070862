/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import HistoryPageContainer from './Components/HistoryPageContainer'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styles from './theme'
import { withStyles } from '@material-ui/core/styles'
import {
  buildFilterExpand,
  getImageHistoryStrapiData,
  buildFilterAction,
  changeFilterEvent,
  selectFilterValueEvent,
  newFilterAction,
  loadExistingFilter,
  getHistoryData,
  clearFilters,
  removeFilterHandler,
} from '../../../store/images/history/actionCreator'
import {
  saveFilterDataEvent,
  fetchSavedFiltersEvent,
  toggleSaveFilterDialogue,
  toggleConfirmation,
  removeSavedFilter,
} from '../../../store/auth/actionCreator'
import {
  isExternalUser,
  splitTcins,
  hasWhiteSpace,
  chipFilter,
  dateRangeFilter,
} from '../../Shared/SharedUtils'
import DefaultAlert from '../../Shared/StrapiComponents/DefaultAlert'
import MyFilter from '../../Shared/Filter/MyFilter/MyFilter'
import BuildFilter from '../../Shared/Filter/BuildFilter'
import NewFilter from '../../Shared/Filter/NewFilter'
import { FilterData, Timespans } from './FilterData'
import Alert from '../../Shared/StrapiComponents/Alert'
import StrapiExpansionPanel from '../../Shared/StrapiComponents/StrapiExpansionPanel'
import { uniqBy } from 'lodash'

function ImageUploadHistory(props) {
  const {
    isFetching = false,
    classes = {},
    buildFilterContainerShown = false,
    newFilterContainerShown = false,
    buildFilterExpansionPanel = false,
    historyPageContainerShown = false,
    showDefaultAlert = false,
    auth = {},
    alert = null,
    information = null,
    faq = null,
    selectedFilters = [],
    selectedFilter = -1,
    filterValues = {},
    userProfile = {},
    isConfirmationOpen = false,
    defaultFilterValues = {},
    saveFilter = {},
    isSaveFilterOpen = false,
    pristine,
    isFetchingSavedFilters = false,
    pageName = 'ImageUploadHistory',
    isFetchingAuth = false,
    errorSearching = false,
  } = props

  useEffect(() => {
    props.fetchSavedFiltersEvent()
    props.getImageHistoryStrapiData(isExternalUser(auth))
  }, [])

  useEffect(() => {
    if (
      buildFilterContainerShown ||
      newFilterContainerShown ||
      historyPageContainerShown
    ) {
      expandFaq(false)
    }
  }, [
    buildFilterContainerShown ||
      newFilterContainerShown ||
      historyPageContainerShown,
  ])

  const [expansionPanelOpen, expandFaq] = useState(true)

  const handlePanel = () => {
    props.buildFilterExpand(!props.buildFilterExpansionPanel)
  }

  const handleFaqPanel = () => {
    expandFaq(!expansionPanelOpen)
  }

  const buildFilter = (selectedFilter, selectedFilterValue) => {
    if (!props.isFetching) {
      props.buildFilterAction(
        {
          newFilterContainerShown: true,
          copyDataContainerShown: true,
          selectedFilters: props.selectedFilters,
          selectedFilter: selectedFilter,
          selectedFilterValue: selectedFilterValue,
        },
        props.currentPage,
        pageSize,
        sortDirection,
        sortField,
        props.filterValues
      )
    }
  }

  /**
   * This method calls when user clicks on ADD button on Build filter
   * section. This method reset value for selected value once it add to filter
   * list
   */
  const addNewFilter = () => {
    var selectedFilter = FilterData.filter(
      (item) => item.value === props.selectedFilter
    )[0]
    var selectedFilterValue
    if (selectedFilter.value === 'tcin') {
      chipFilter(
        filterValues.tcins,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'emails') {
      chipFilter(
        filterValues.emails,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'dateRange') {
      dateRangeFilter(
        filterValues,
        selectedFilterValue,
        selectedFilter,
        buildFilter,
        false
      )
    }

    // Return value to parent components
  }

  const onFilterSelect = (event) => {
    if (event.target.value === 'emails') {
      props.changeFilterEvent({
        selectedFilter: event.target.value,
      })
      handleAddEmail(props.auth.email)
    } else {
      props.changeFilterEvent({
        selectedFilter: event.target.value,
      })
    }
  }

  const selectFilterValue = (selectedFilterValues) => {
    props.selectFilterValueEvent({
      filterValues: selectedFilterValues,
    })
  }

  const onFilterValueSelect = (event) => {
    if (event.target.name === 'dateRange') {
      /**
       * Raise event for filter value select
       * filterValues: selectedFilterValues,
       */
      selectFilterValue({
        dateRange: event.target.value,
        tcins: [],
        emails: [],
        fromDate: -1,
        toDate: -1,
      })
    }
  }

  const handleAddTcin = (selectedTcins) => {
    let chips = []
    splitTcins(selectedTcins).forEach((i) => {
      chips.push({
        value: i,
        display: i,
      })
    })
    if (
      props.filterValues !== undefined &&
      props.filterValues.tcins.length > 0
    ) {
      props.filterValues.tcins.forEach((item) => {
        chips.push(item)
      })
    }
    selectFilterValue({
      dateRange: -1,
      tcins: uniqBy(chips, 'value'),
      emails: [],
      fromDate: -1,
      toDate: -1,
    })
  }

  const handleDeleteTcin = (deletedTcin) => {
    let chips = []
    props.filterValues.tcins.forEach((item) => {
      if (item.value !== deletedTcin) {
        chips.push(item)
      }
    })
    selectFilterValue({
      dateRange: -1,
      tcins: chips,
      emails: [],
      fromDate: -1,
      toDate: -1,
    })
  }

  const handleDeleteEmail = (deletedEmail) => {
    let chips = []
    props.filterValues.emails.forEach((item) => {
      if (item.value !== deletedEmail) {
        chips.push(item)
      }
    })
    selectFilterValue({
      dateRange: -1,
      tcins: [],
      emails: chips,
      fromDate: -1,
      toDate: -1,
    })
  }

  const splitEmails = (email) => {
    let emails = email.split('\n')
    let emailsList = []
    if (emails.length > 0) {
      emails.forEach((element) => {
        if (element.trim() !== '') {
          if (element.indexOf(',') > -1) {
            element.split(',').forEach((item) => {
              if (item.trim() !== '') {
                if (emailsList.indexOf(item.trim()) === -1) {
                  emailsList.push(item.trim())
                }
              }
            })
          } else if (hasWhiteSpace(element)) {
            element.split(' ').forEach((item) => {
              if (item.trim() !== '') {
                if (emailsList.indexOf(item.trim()) === -1) {
                  emailsList.push(item.trim())
                }
              }
            })
          } else {
            if (emailsList.indexOf(element.trim()) === -1) {
              emailsList.push(element.trim())
            }
          }
        }
      })
    }
    return emailsList.map((item) => {
      return {
        display: item.toLowerCase(),
        value: item.toLowerCase(),
      }
    })
  }

  const handleAddEmail = (emails) => {
    let chips = []
    splitEmails(emails).forEach((i) => {
      chips.push(i)
    })
    if (
      props.filterValues !== undefined &&
      props.filterValues.emails.length > 0
    ) {
      props.filterValues.emails.forEach((item) => {
        chips.push(item)
      })
    }
    selectFilterValue({
      dateRange: -1,
      tcins: [],
      emails: uniqBy(chips, 'value'),
      fromDate: -1,
      toDate: -1,
    })
  }

  const changeDateRangeFilter = (dateRange) => {
    let fromDateEpoch = dateRange && dateRange[0] ? dateRange[0].getTime() : -1
    let toDateEpoch = dateRange && dateRange[1] ? dateRange[1].getTime() : -1
    selectFilterValue({
      dateRange: props.filterValues.dateRange,
      tcins: [],
      emails: [],
      fromDate: fromDateEpoch,
      toDate: toDateEpoch,
    })
  }

  const removeSavedFilterHandler = (item) => {
    props.toggleConfirmation(true, { id: item })
  }

  const handleCancelConfirmation = () => {
    props.toggleConfirmation(false, null)
  }

  const handleConfirmRemoveFilter = () => {
    props.removeSavedFilter(props.confirmationPayload.id)
  }

  const loadFilterHandler = (item) => {
    props.buildFilterExpand(false)
    props.newFilterAction(true)
    props.loadExistingFilter(JSON.parse(item.filter_criteria))
    props.getHistoryData(0, pageSize, JSON.parse(item.filter_criteria))
  }

  const onClick = () => {
    props.newFilterAction(true)
    props.buildFilterExpand(true)
    props.clearFilters()
  }

  const removeFilterHandler = (mainFilter, filterVal) => {
    props.removeFilterHandler(
      {
        selectedFilters: props.selectedFilters,
        mainFilterValue: mainFilter,
        filterValue: filterVal,
      },
      props.currentPage,
      props.defaultPageSize
    )
  }

  const saveNewFilter = () => {
    if (props.selectedFilters && props.selectedFilters.length > 0) {
      props.toggleSaveFilterDialogue(true)
    }
  }

  const clearFilters = () => {
    props.clearFilters()
  }

  const handleClose = () => {
    props.toggleSaveFilterDialogue(false)
  }

  const handleSave = () => {
    props.saveFilterDataEvent({
      filterName: props.saveFilter.values.saveFilter,
      filterCriteria: JSON.stringify(props.selectedFilters),
      pageSize: pageSize,
      sortOrder: sortDirection,
      sortField: sortField,
      pageName: pageName,
    })
  }

  const savedFilterData =
    userProfile && userProfile.search_filters
      ? userProfile.search_filters.filter(
          (filter) => filter.page_name === pageName
        )
      : []

  const paginationValues =
    userProfile && userProfile.pagination
      ? userProfile.pagination.filter((item) => item.page_name === pageName)
      : undefined

  const pageSize =
    paginationValues && paginationValues[0] && paginationValues[0].page_size
      ? paginationValues[0].page_size
      : props.defaultPageSize

  const sortDirection =
    paginationValues && paginationValues[0] && paginationValues[0].sort_order
      ? paginationValues[0].sort_order
      : props.sortDirection

  const sortField =
    paginationValues && paginationValues[0] && paginationValues[0].sort_field
      ? paginationValues[0].sort_field
      : props.sortField

  return (
    <React.Fragment>
      <HeaderTitle title="Image History" />
      <Helmet title="Image History" />
      {showDefaultAlert ? <DefaultAlert /> : null}
      {alert ? <Alert alert={alert} /> : null}
      <Grid container>
        <Grid item xs={12} sm={12} className={classes.marginBottom5}>
          <MyFilter
            isConfirmationOpen={isConfirmationOpen}
            handleCancelConfirmation={handleCancelConfirmation}
            handleConfirmRemoveFilter={handleConfirmRemoveFilter}
            savedFilterData={savedFilterData}
            onClick={onClick}
            loadFilterHandler={loadFilterHandler}
            removeSavedFilterHandler={removeSavedFilterHandler}
            isFetchingSavedFilters={isFetchingSavedFilters}
            disabled={isFetchingAuth}
          />
        </Grid>
        <React.Fragment>
          {(buildFilterContainerShown || newFilterContainerShown) && (
            <Accordion
              className={classes.buildFilterExpansion}
              expanded={buildFilterExpansionPanel}
              onChange={() => handlePanel()}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {buildFilterExpansionPanel ? 'Hide ' : 'Show '} Filter
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {buildFilterContainerShown && (
                  <Grid item xs={12} sm={6}>
                    <BuildFilter
                      selectedFilter={selectedFilter}
                      onFilterSelect={onFilterSelect}
                      filterValues={filterValues}
                      onFilterValueSelect={onFilterValueSelect}
                      handleAddTcin={handleAddTcin}
                      handleDeleteTcin={handleDeleteTcin}
                      isFetching={isFetching}
                      addNewFilter={addNewFilter}
                      defaultFilterValues={defaultFilterValues}
                      FilterData={FilterData}
                      Timespans={Timespans}
                      handleAddEmail={handleAddEmail}
                      handleDeleteEmail={handleDeleteEmail}
                      changeDateRangeFilter={changeDateRangeFilter}
                      emailInputDisabled
                    />
                  </Grid>
                )}
                {newFilterContainerShown && (
                  <Grid item xs={12} sm={6}>
                    <NewFilter
                      clearFilters={clearFilters}
                      selectedFilters={selectedFilters}
                      saveNewFilter={saveNewFilter}
                      removeFilterHandler={removeFilterHandler}
                      isSaveFilterOpen={isSaveFilterOpen}
                      saveFilter={saveFilter}
                      handleClose={handleClose}
                      handleSave={handleSave}
                      pristine={pristine}
                      disabled={isFetchingAuth}
                    />
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {(information || faq) && (
            <StrapiExpansionPanel
              faq={faq}
              information={information}
              expansionPanelOpen={expansionPanelOpen}
              handleFaqPanel={handleFaqPanel}
            />
          )}
        </React.Fragment>
        {isFetching && (
          <Grid className={classes.progressBar}>
            <CircularProgress className={classes.progressBar} />
          </Grid>
        )}
        {historyPageContainerShown && !isFetching && (
          <Grid item xs={12} sm={12}>
            <HistoryPageContainer rowsPerPage={pageSize} />
          </Grid>
        )}
        {errorSearching && (
          <Grid item xs={12} sm={12}>
            <div className={classes.noResult}>
              Filter criteria too broad, please add more filters to narrow
              results.
            </div>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildFilterExpand,
      getImageHistoryStrapiData,
      buildFilterAction,
      changeFilterEvent,
      selectFilterValueEvent,
      newFilterAction,
      fetchSavedFiltersEvent,
      loadExistingFilter,
      getHistoryData,
      removeSavedFilter,
      toggleConfirmation,
      clearFilters,
      removeFilterHandler,
      toggleSaveFilterDialogue,
      saveFilterDataEvent,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageHistory, auth, form } = state
  const {
    isFetching,
    buildFilterContainerShown,
    newFilterContainerShown,
    historyPageContainerShown,
    buildFilterExpansionPanel,
    alert,
    information,
    faq,
    showDefaultAlert,
    selectedFilters,
    selectedFilter,
    filterValues,
    currentPage,
    defaultPageSize,
    defaultFilterValues,
    sortDirection,
    sortField,
    pageName,
    errorSearching,
  } = imageHistory
  const {
    savedFilterData,
    isFetchingSavedFilters,
    userProfile,
    isSaveFilterOpen,
    isConfirmationOpen,
    confirmationPayload,
    isFetchingAuth,
  } = auth
  const { saveFilter } = form
  return {
    isFetching,
    buildFilterContainerShown,
    newFilterContainerShown,
    historyPageContainerShown,
    buildFilterExpansionPanel,
    auth,
    alert,
    information,
    faq,
    showDefaultAlert,
    selectedFilters,
    selectedFilter,
    filterValues,
    currentPage,
    defaultPageSize,
    savedFilterData,
    isConfirmationOpen,
    confirmationPayload,
    defaultFilterValues,
    isSaveFilterOpen,
    saveFilter,
    isFetchingSavedFilters,
    userProfile,
    sortDirection,
    sortField,
    pageName,
    isFetchingAuth,
    errorSearching,
  }
}
// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ImageUploadHistory))
