import {
  CLOSE_SIDE_NAV,
  OPEN_SIDE_NAV,
  SHOW_AUTH_POPUP,
  SET_HEADER_TITLE,
  STORE_HOME_PAGE_STRAPI_DATA,
  SHOW_DEFAULT_ALERT_LAYOUT,
} from './actionType'

export const initialState = {
  sideNavIsOpen: false,
  openPopup: 0,
  popupType: '',
  headerTitle: '',
  alert: null,
  headline: null,
  announcement: null,
  faq: null,
  showDefaultAlert: false,
}

export default function layoutReducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_SIDE_NAV: {
      const newState = {
        ...state,
        sideNavIsOpen: true,
      }
      return newState
    }
    case CLOSE_SIDE_NAV: {
      const newState = {
        ...state,
        sideNavIsOpen: false,
      }
      return newState
    }
    case SHOW_AUTH_POPUP: {
      const { payload } = action
      const newState = {
        ...state,
        openPopup: state.openPopup + 1,
        popupType: payload,
      }
      return newState
    }
    case SET_HEADER_TITLE: {
      const { payload } = action
      const newState = {
        ...state,
        headerTitle: payload,
      }
      return newState
    }
    case STORE_HOME_PAGE_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        headline: payload.headline,
        announcement: payload.announcement,
        faq: payload.faq,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_LAYOUT: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    default:
      return state
  }
}
