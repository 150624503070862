/* eslint-disable array-callback-return */
import React from 'react'
import ModelsList from './ModelsList'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getImageUrlsForModelLabel,
  getModelsListFromApi,
  initiateModelTraining,
} from '../../../../../../store/images/tagTraining/actionCreator'

class ModelsListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {
    this.props.getModelsListFromApi()
  }

  toggleTagVisibility(tagGroup) {
    let tagsToUpdate = this.props.tags
    tagsToUpdate.map((tag) => {
      if (tag.group === tagGroup) {
        tag.visible = !tag.visible
      }
    })

    this.props.updateTagGroups(tagsToUpdate)
  }

  render() {
    const { auth, models } = this.props
    return (
      <React.Fragment>
        <ModelsList
          userEmail={auth.email}
          models={models}
          getImageUrlsForModelLabel={(model, label) =>
            this.props.getImageUrlsForModelLabel(model, label)
          }
          initiateModelTraining={(id, userEmail) =>
            this.props.initiateModelTraining(id, userEmail)
          }
        />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getImageUrlsForModelLabel,
      getModelsListFromApi,
      initiateModelTraining,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { training } = imageTagTraining
  const { models } = training
  return {
    auth,
    models,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelsListContainer)
