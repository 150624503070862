import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Button, Grid, Typography, CircularProgress } from '@material-ui/core'
import NoThumbnail from '../../../../../../images/NoThumbnail.svg?url'
import { Link } from 'react-router-dom'
import ImageGridContainer from '../../../Shared/Components/ImageGrid/ImageGridContainer'
import { ROUTES } from '../../../../../Shared/Constants'

function SimilarItem(props) {
  const {
    similarImageData,
    selectedImage,
    similarImageFetching,
    currentSimilarImageCount,
    classes,
    skipCurrentImage,
    approveCurrentImage,
    maxSimilarImageCount,
    currentApprovedImages,
    toggleCurrentApprovedImageSelect,
    handleDialogOpenStatus,
    deleteImages,
    isDialogOpen,
    selectToggleDeleteConfirmation,
    clearSelectedTrainingImages,
    deletingInProgress,
    startOver,
  } = props
  const imageToShow =
    currentSimilarImageCount >= maxSimilarImageCount - 1
      ? maxSimilarImageCount - 1
      : currentSimilarImageCount
  return (
    <React.Fragment>
      {similarImageFetching ? (
        <div>
          <CircularProgress className={classes.progressBar} />
          <Typography>Finding Similar Images For Review</Typography>
        </div>
      ) : (
        <React.Fragment>
          {similarImageData.length > 0 && (
            <Grid container>
              {selectedImage && maxSimilarImageCount > 0 && (
                <Grid item xs={4}>
                  <Typography>
                    Would you use the same tag to describe the below image?
                  </Typography>
                  <div className={classes.buttonStyles}>
                    <Grid container direction="row" justify="space-between">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={approveCurrentImage}
                          disabled={
                            currentSimilarImageCount >= maxSimilarImageCount
                          }
                        >
                          Yes
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={skipCurrentImage}
                          disabled={
                            currentSimilarImageCount >= maxSimilarImageCount
                          }
                          variant="outlined"
                          color="primary"
                        >
                          No
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={startOver}
                          variant="outlined"
                          color="primary"
                        >
                          Start Over
                        </Button>
                      </Grid>
                      <Grid item>
                        <Link
                          to={ROUTES.IMAGES.LABELS}
                          className={classes.linkStyle}
                        >
                          <Button
                            onClick={startOver}
                            variant="contained"
                            color="primary"
                          >
                            Exit
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                  <img
                    key={similarImageData[imageToShow].image_uri}
                    src={similarImageData[imageToShow].image_url}
                    alt={similarImageData[imageToShow].image_uri}
                    className={classes.marginTop10}
                    onError={(e) => {
                      e.target.src = NoThumbnail
                    }}
                  />
                </Grid>
              )}
              {currentApprovedImages.length > 0 && (
                <Grid item xs={8} container direction="row">
                  <div>
                    <Grid item xs={12} className={classes.approvedImageMargin}>
                      <Typography className={classes.marginLeft20}>
                        Current selected image(s). Click on any image to edit
                        selection.
                      </Typography>
                    </Grid>
                    <ImageGridContainer
                      images={currentApprovedImages}
                      selectToggleMainGrid={(image) =>
                        toggleCurrentApprovedImageSelect(image)
                      }
                      selectToggleDeleteConfirmation={(image) =>
                        selectToggleDeleteConfirmation(image)
                      }
                      clearSelectedTrainingImages={() =>
                        clearSelectedTrainingImages()
                      }
                      handleDialogOpenStatus={(open) =>
                        handleDialogOpenStatus(open)
                      }
                      isDialogOpen={isDialogOpen}
                      deletingInProgress={deletingInProgress}
                      deleteImages={() => deleteImages()}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(SimilarItem)
