import React from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Tooltip,
} from '@material-ui/core'
import { CheckCircleOutline, HighlightOffOutlined } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'

function ModelsList(props) {
  const {
    classes,
    userEmail,
    models,
    getImageUrlsForModelLabel,
    initiateModelTraining,
  } = props
  return (
    <React.Fragment>
      <List className={classes.modelsList}>
        {models.map((model) => (
          <React.Fragment key={`${model.type}`}>
            <ListItem>
              <ListItemText
                primary={`${model.type}`}
                classes={{ primary: classes.primaryListItemHeaderText }}
              />
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.fontSize10}
                  onClick={() => {
                    initiateModelTraining(model.id, userEmail)
                  }}
                  disabled={
                    model.isTrainingInProgress || !model.isEligibleForTraining
                  }
                >
                  {(() => {
                    if (model.isTrainingInProgress) {
                      return 'In Progress'
                    } else if (!model.isEligibleForTraining) {
                      return 'Not Eligible'
                    } else {
                      return 'Train Model'
                    }
                  })()}
                </Button>
              </span>
            </ListItem>
            {model.labels.map((l) => (
              <ListItem
                divider
                key={`${model.type}${l.label}`}
                button
                onClick={() => getImageUrlsForModelLabel(model.type, l.label)}
              >
                <ListItemText inset primary={`${l.label}`} />
                {l.isEligibleForTraining ? (
                  <Tooltip title="Eligible for Model Training">
                    <CheckCircleOutline nativeColor="green" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Not Enough Images for Model Training">
                    <HighlightOffOutlined nativeColor="red" />
                  </Tooltip>
                )}
              </ListItem>
            ))}
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  )
}

export default withStyles(styles)(ModelsList)
