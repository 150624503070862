import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import { Collapse } from '@material-ui/core'

function TableCellCollapsible(props) {
  const {
    list = [] || undefined,
    index = 0,
    collapsedRow = {},
    handleExpansion = () => {},
    classes = {},
  } = props

  return (
    <React.Fragment>
      {list && list.length > 0 ? (
        list.length > 3 ? (
          <span>
            <div>{list[0]}</div>
            <div>{list[1]}</div>
            <div>{list[2]}</div>
            <Collapse in={collapsedRow === index} timeout="auto" unmountOnExit>
              {list.slice(3).map((item, i) => {
                return <div key={i}>{item}</div>
              })}
            </Collapse>
            <div
              onClick={() => handleExpansion(index)}
              role="presentation"
              className={classes.expansionText}
            >
              Show {collapsedRow === index ? 'Less' : 'More'}
            </div>
          </span>
        ) : (
          list.map((item, i) => {
            return <div key={i}>{item}</div>
          })
        )
      ) : (
        '-'
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(TableCellCollapsible)
