/* eslint-disable array-callback-return */
import {
  NEW_FILTER_EVENT_BULK_UPLOAD_REPORT,
  BUILD_FILTER_EVENT_BULK_UPLOAD_REPORT,
  REQUEST_BULK_UPLOAD_REPORT_DATA,
  RECEIVE_BULK_UPLOAD_REPORT_DATA,
  CHANGE_FILTER_EVENT_BULK_UPLOAD_REPORT,
  SELECT_FILTER_VALUE_EVENT_BULK_UPLOAD_REPORT,
  CHANGE_DEFAULT_PAGE_SIZE_BULK_UPLOAD_REPORT,
  CHANGE_CURRENT_PAGE_BULK_UPLOAD_REPORT,
  SELECT_BULK_UPLOAD_REPORT_EVENT,
  REMOVE_FILTER_EVENT_BULK_UPLOAD_REPORT,
  BULK_UPLOAD_REPORT_RESET_FETCH,
  CLEAR_BULK_UPLOAD_REPORT_DATA_EVENT,
  DOWNLOAD_START_BULK_UPLOAD_REPORT,
  DOWNLOAD_FINISH_BULK_UPLOAD_REPORT,
  BUILD_FILTER_EXPAND_BULK_UPLOAD_REPORT,
  SHOW_DEFAULT_ALERT_BULK_UPLOAD_REPORT,
  STORE_BULK_UPLOAD_REPORT_STRAPI_DATA,
  ERROR_SEARCHING_BULK_UPLOAD_REPORT,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import changeCase from 'change-case'
import { savePaginationDataEvent } from '../auth/actionCreator'
import { DownloadDate } from '../../components/Shared/SharedUtils'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../components/Shared/Constants'

const fileDownload = require('js-file-download')

export function newFilterAction(isShown) {
  return {
    type: NEW_FILTER_EVENT_BULK_UPLOAD_REPORT,
    payload: isShown,
  }
}

export function resetFetch(data) {
  return {
    type: BULK_UPLOAD_REPORT_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function changeFilterEvent(data) {
  return {
    type: CHANGE_FILTER_EVENT_BULK_UPLOAD_REPORT,
    payload: {
      selectedFilter: data.selectedFilter,
    },
  }
}

export function handleSelectBulkUploadReport(data) {
  return {
    type: SELECT_BULK_UPLOAD_REPORT_EVENT,
    payload: {
      selectedCopy: data.selectedCopy,
    },
  }
}

export function selectFilterValueEvent(data) {
  return {
    type: SELECT_FILTER_VALUE_EVENT_BULK_UPLOAD_REPORT,
    payload: {
      filterValues: data.filterValues,
    },
  }
}

export function removeFilterHandler(data, currentPage, defaultPageSize) {
  return (dispatch) => {
    var filter = dispatch(removeFilterEvent(data))
    dispatch(
      requestBulkUploadReportData(
        filter.payload.selectedFilters,
        currentPage,
        defaultPageSize
      )
    )
  }
}

export function removeFilterEvent(data) {
  var newFilterContainerShown = true
  var copyDataContainerShown = true
  var deepCopy = cloneDeep(data.selectedFilters)
  var currentFilter = deepCopy.filter(
    (v) => v.filterValue === data.mainFilterValue
  )[0]
  currentFilter.selectedValues = currentFilter.selectedValues.filter(
    (item) => !isEqual(item.value, data.filterValue)
  )
  deepCopy = deepCopy.filter((v) => v.selectedValues.length >= 1)
  if (currentFilter.selectedValues.length === 0 && deepCopy.length <= 0) {
    deepCopy = deepCopy.filter((v) => v.filterValue !== data.mainFilterValue)
    newFilterContainerShown = false
    copyDataContainerShown = false
  }
  return {
    type: REMOVE_FILTER_EVENT_BULK_UPLOAD_REPORT,
    payload: {
      newFilterContainerShown: newFilterContainerShown,
      copyDataContainerShown: copyDataContainerShown,
      selectedFilters: deepCopy,
    },
  }
}

function changePage(currentPage) {
  return {
    type: CHANGE_CURRENT_PAGE_BULK_UPLOAD_REPORT,
    payload: {
      currentPage: currentPage,
    },
  }
}

export function handleChangePage(data) {
  return (dispatch) => {
    dispatch(changePage(data.currentPage))
    dispatch(
      requestBulkUploadReportData(
        data.selectedFilters,
        data.currentPage,
        data.defaultPageSize
      )
    )
  }
}

function changePageSize(defaultPageSize) {
  return {
    type: CHANGE_DEFAULT_PAGE_SIZE_BULK_UPLOAD_REPORT,
    payload: {
      defaultPageSize: defaultPageSize,
    },
  }
}

export function changeDefaultPageSize(
  data,
  sortDirection,
  sortField,
  pageName
) {
  return (dispatch) => {
    dispatch(changePageSize(data.defaultPageSize))
    dispatch(
      savePaginationDataEvent({
        pageSize: data.defaultPageSize,
        sortOrder: sortDirection,
        sortField: sortField,
        pageName: pageName,
      })
    )
    // Always setting the currentPage to 0 to prevent bugs
    dispatch(changePage(0))
    dispatch(
      requestBulkUploadReportData(data.selectedFilters, 0, data.defaultPageSize)
    )
  }
}

/**
 * Data will have following attributes
 * NewFilterContainer
 * BulkUploadReportDataContainer
 * @param {*} data
 */
export function buildFilterAction(data, pageNumber, pageSize) {
  var alreadySelectedFilters = data.selectedFilters.filter(
    (item) => item.filterValue === data.selectedFilter.value
  )
  if (alreadySelectedFilters.length > 0) {
    // If filter already selected and different value selected.
    let isDuplicateValueFound = false
    if (Array.isArray(data.selectedFilterValue)) {
      data.selectedFilterValue.forEach((item) => {
        isDuplicateValueFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val) => val.value === item.value
          ).length === 0
      })
    } else {
      isDuplicateValueFound =
        alreadySelectedFilters[0].selectedValues.filter(
          (val) => val.value === data.selectedFilterValue.value
        ).length === 0
    }
    if (isDuplicateValueFound) {
      return (dispatch) => {
        dispatch(changePage(0)) // Reset Current Page to first page
        var filter = dispatch(handleAlreadySelectedFilter(data))
        dispatch(
          requestBulkUploadReportData(
            filter.payload.selectedFilters,
            pageNumber,
            pageSize
          )
        )
      }
    } else {
      return (dispatch) => {
        dispatch(
          resetFetch(false),
          toast.error(
            'You have attempted to add duplicate filters.',
            TOAST_BODY
          )
        )
      }
    }
  } else {
    return (dispatch) => {
      dispatch(changePage(0)) // Reset Current Page to first page
      var filter = dispatch(handleNewFilter(data))
      dispatch(
        requestBulkUploadReportData(filter.payload.selectedFilters, 0, pageSize)
      )
    }
  }
}

function handleAlreadySelectedFilter(data) {
  var deepCopy = cloneDeep(data.selectedFilters)
  let selectedValues = []
  let dateRangeAlreadySelected =
    deepCopy.filter((item) => item.filterValue === 'dateRange').length > 0
  let dateRangeAdded = data.selectedFilter.value === 'dateRange'
  if (dateRangeAlreadySelected && dateRangeAdded) {
    deepCopy
      .filter((item) => item.filterValue === 'dateRange')[0]
      .selectedValues.shift()
  }
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  let oldSelectedValue = deepCopy.filter(
    (v) => v.filterValue === data.selectedFilter.value
  )[0].selectedValues
  selectedValues.forEach((item) => {
    oldSelectedValue.push(item)
  })
  deepCopy
    .filter((v) => v.filterValue === data.selectedFilter.value)[0]
    .selectedValues.concat(selectedValues)
  return {
    type: BUILD_FILTER_EVENT_BULK_UPLOAD_REPORT,
    payload: {
      newFilterContainerShown: true,
      copyDataContainerShown: true,
      selectedFilters: deepCopy,
    },
  }
}

function handleNewFilter(data) {
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  var newSelectedFilters = [
    {
      filterValue: data.selectedFilter.value,
      filterDisplay: data.selectedFilter.display,
      selectedValues: selectedValues,
    },
  ]
  return {
    type: BUILD_FILTER_EVENT_BULK_UPLOAD_REPORT,
    payload: {
      newFilterContainerShown: true,
      copyDataContainerShown: true,
      selectedFilters: data.selectedFilters.concat(newSelectedFilters),
    },
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING_BULK_UPLOAD_REPORT,
    payload: payload,
  }
}

export function requestBulkUploadReportData(data, pageNumber, pageSize) {
  var queryParam = {}

  data.map((item) => {
    if (item.filterValue === 'dateRange') {
      queryParam.from_date = item.selectedValues[0].value.fromDate
      queryParam.to_date = item.selectedValues[0].value.toDate
    }
    if (item.filterValue === 'emails') {
      queryParam.email_ids = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'tcin') {
      queryParam.tcin = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'compnayName') {
      queryParam.company = item.selectedValues.map((filter) => filter.value)
    }
  })

  if (data === undefined || data.length === 0) {
    return clearBulkUploadReportDataEvent()
  }

  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(dispatchRequestBulkUploadReportDataEvent(data))
    return axios
      .post(
        `${
          envConfigs.targetApiGateway.targetApiGatewayUrl +
          envConfigs.targetApiGateway.generateBulkUploadReport
        }?page_number=${pageNumber}&page_size=${pageSize}&key=${
          envConfigs.targetApiGateway.targetApiGatewayKey
        }`,
        queryParam
      )
      .then((res) => {
        dispatch(
          successRequestDataEvent(
            res.data.content.map((d) => {
              var item = {
                id: d.id,
                tcin: d.tcin,
                job_id: d.job_id,
                long_copy: d.long_copy,
                feature_bullets: d.feature_bullets,
                created_by: d.created_by,
                created_time: d.created_time,
                company: d.company,
              }
              return item
            }),
            res.data.totalPages,
            res.data.totalElements
          )
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

function clearBulkUploadReportDataEvent() {
  return {
    type: CLEAR_BULK_UPLOAD_REPORT_DATA_EVENT,
  }
}

function successRequestDataEvent(json, totalPages, totalElements) {
  return {
    type: RECEIVE_BULK_UPLOAD_REPORT_DATA,
    payload: {
      isFetching: false,
      copyData: json,
      totalPages: totalPages,
      totalElements: totalElements,
    },
  }
}

function dispatchRequestBulkUploadReportDataEvent(data) {
  var newCopyData = cloneDeep(data)
  newCopyData.splice(0, newCopyData.length)
  return {
    type: REQUEST_BULK_UPLOAD_REPORT_DATA,
    payload: {
      isFetching: true,
      copyData: newCopyData,
      selectedCopy: [],
    },
  }
}

export function loadExistingFilter(selectedFilters) {
  return {
    type: BUILD_FILTER_EVENT_BULK_UPLOAD_REPORT,
    payload: {
      newFilterContainerShown: true,
      copyDataContainerShown: true,
      selectedFilters: selectedFilters,
    },
  }
}

export function clearFilters() {
  return {
    type: REMOVE_FILTER_EVENT_BULK_UPLOAD_REPORT,
    payload: {
      newFilterContainerShown: false,
      copyDataContainerShown: false,
      selectedFilters: [],
    },
  }
}

export function downloadSelectedTcinsBulkUploadReportEvent(queryParamter) {
  // harbinger.trackEvent('s7g49u', [{ key: 'Item Count', value: queryParamter.split(',').length }, { key: 'Button Pressed', value: 'Download Selected' }])
  return (dispatch) => {
    dispatch(downloadStartBulkUploadReport(queryParamter))
    axios
      .post(
        `${
          envConfigs.targetApiGateway.targetApiGatewayUrl +
          envConfigs.targetApiGateway.downloadBulkUploadReport
        }?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        queryParamter,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        // @ts-ignore
        fileDownload(res.data, `bulk_upload_report_${DownloadDate}_v.2.0.xlsx`)
        dispatch(downloadFinishBulkUploadReport(queryParamter))
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(downloadFinishBulkUploadReport(queryParamter))
      })
  }
}

export function downloadAllToExcel(selectedFilters, totalElements) {
  var requestBody = {}
  selectedFilters.map((item) => {
    var filterValue = []
    item.selectedValues.map((sv) => {
      filterValue.push(sv.value)
    })
    requestBody[changeCase.snakeCase(item.filterValue)] = filterValue
  })
  // harbinger.trackEvent('s7g49u', [{ key: 'Item Count', value: totalElements }, { key: 'Button Pressed', value: 'Download All' }])
  return (dispatch) => {
    dispatch(downloadStartBulkUploadReport(requestBody))
    axios
      .post(
        `${
          envConfigs.targetApiGateway.targetApiGatewayUrl +
          envConfigs.targetApiGateway.downloadBulkUploadReport
        }_by_criteria?page_number=${0}&page_size=${totalElements}&key=${
          envConfigs.targetApiGateway.targetApiGatewayKey
        }`,
        requestBody,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        // @ts-ignore
        fileDownload(res.data, `bulk_upload_report_${DownloadDate}_v.2.0.xlsx`)
        dispatch(downloadFinishBulkUploadReport(requestBody))
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(downloadFinishBulkUploadReport(requestBody))
      })
  }
}

export function downloadStartBulkUploadReport(downloadedTcins) {
  return {
    type: DOWNLOAD_START_BULK_UPLOAD_REPORT,
    payload: {
      downloadedTcins: downloadedTcins,
    },
  }
}

export function downloadFinishBulkUploadReport(downloadedTcins) {
  return {
    type: DOWNLOAD_FINISH_BULK_UPLOAD_REPORT,
    payload: {
      downloadedTcins: downloadedTcins,
    },
  }
}

export function buildFilterExpand(value) {
  return {
    type: BUILD_FILTER_EXPAND_BULK_UPLOAD_REPORT,
    payload: {
      buildFilterExpansionPanel: value,
    },
  }
}

export function storeBulkUploadReportStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_BULK_UPLOAD_REPORT_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_BULK_UPLOAD_REPORT,
    payload: data,
  }
}

export function getBulkUploadReportStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Copy%20Bulk%20Upload%20Report&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        if (isEmpty(response.data)) {
          dispatch(showDefaultAlert(true))
        } else {
          dispatch(storeBulkUploadReportStrapiData(response.data))
          dispatch(showDefaultAlert(false))
        }
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function handleSelectCopy(data) {
  return {
    type: SELECT_BULK_UPLOAD_REPORT_EVENT,
    payload: {
      selectedCopy: data.selectedCopy,
    },
  }
}
