import styles from '../theme'
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Chip,
  CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Confirmation from './Confirmation'

const filterChip = (
  val,
  classes,
  loadFilterHandler,
  removeSavedFilterHandler
) => {
  return (
    <Chip
      key={val.filter_id}
      label={val.filter_name}
      className={classes.margin5}
      onClick={() => loadFilterHandler(val)}
      onDelete={() => removeSavedFilterHandler(val.filter_id)}
    />
  )
}

function MyFilter(props) {
  const {
    isConfirmationOpen = false,
    handleCancelConfirmation = () => {},
    handleConfirmRemoveFilter = () => {},
    savedFilterData = [],
    onClick = () => {},
    loadFilterHandler = () => {},
    removeSavedFilterHandler = () => {},
    classes = {},
    isFetchingSavedFilters = false,
    disabled = false,
  } = props

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body2">My Filters</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.displayBlock}>
        {isFetchingSavedFilters ? (
          <CircularProgress />
        ) : (
          savedFilterData.map((val) =>
            filterChip(
              val,
              classes,
              loadFilterHandler,
              removeSavedFilterHandler
            )
          )
        )}
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => onClick()}
        >
          NEW FILTER
        </Button>
      </AccordionDetails>
      <Confirmation
        isConfirmationOpen={isConfirmationOpen}
        handleCancelConfirmation={handleCancelConfirmation}
        handleConfirmRemoveFilter={handleConfirmRemoveFilter}
        disabled={disabled}
      />
    </Accordion>
  )
}
export default withStyles(styles)(MyFilter)
