export const PRODUCT_TITLE = 'Product Title'
export const VENDOR_NAME = 'Vendor Name'
export const TAXONOMY = 'Taxonomy'
export const TAXONOMY_GROUP = 'group_name'
export const TAXONOMY_SUBGROUP = 'sub_groups'
export const MTA = 'Merch Type Attributes'
export const MTA_TYPE = 'IAC_ATTRIBUTE'
export const MTA_VALUES = 'IAC_ATTRIBUTE_VALUE'
export const MANUFACTURER_BRAND = 'Manufacturer Brand'
export const RELATIONSHIP_TYPE = 'relationship_type'
