/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BrowseAltTextContainer from './BrowseAltText/BrowseAltTextContainer'
import AssetSearchContainer from './AssetSearch/AssetSearchContainer'

import { Grid, Card, Button } from '@material-ui/core'
import styles from './theme'

import { isExternalUser } from '../../Shared/SharedUtils'
import AltTextTableUploadContainer from './AltTextTableUpload/AltTextTableUploadContainer'
import {
  publishData,
  getAltTextBulkUploadStrapiData,
  clearUploadData,
  altTextDownLoadTemplate,
  downloadAllToExcelByTcins,
  nonApprovedAltText,
} from '../../../store/altText/actionCreator'
import CircularProgress from '@material-ui/core/CircularProgress'
import StrapiExpansionPanel from '../../Shared/StrapiComponents/StrapiExpansionPanel'
import { some } from 'lodash'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import NonApprovedImagePopUp from './AltTextTableUpload/NonApprovedImagePopUp'

const publishDataToCorona = (selectedData, auth, apiActionPublish) => {
  var invalidFiles = []
  var validFiles = []
  selectedData.forEach((item) => {
    if (item.valid) {
      var data = {}
      data.tcin = item.tcin
      data.alt_text = item.alt_text
      data.image_url = item.image_url
      data.valid = item.valid
      data.profane_category = item.profane_category
      validFiles.push(data)
    } else {
      invalidFiles.push(item)
    }
  })
  var requestBody = {
    data: validFiles,
    uploaded_by: auth.email ? auth.email : auth.lanId,
  }

  apiActionPublish(requestBody, invalidFiles)
}

function BulkUpload(props) {
  const { classes = {}, auth = {} } = props

  useEffect(() => {
    props.getAltTextBulkUploadStrapiData(isExternalUser(auth))
  }, [])

  useEffect(() => {
    if (props.uploadData.length > 0) {
      expandFaq(false)
    }
  }, [props.uploadData])
  const [expansionPanelOpen, expandFaq] = useState(true)
  const handlePanel = () => {
    expandFaq(!expansionPanelOpen)
  }

  const handleDownload = () => {
    let tcins = []
    props.selectedData.forEach((item) => {
      tcins.push(item.tcin)
    })
    if (tcins.length > 0) {
      let uniqueTcins = Array.from(new Set(tcins))
      props.downloadAllToExcelByTcins(uniqueTcins)
    }
  }

  return (
    <React.Fragment>
      <HeaderTitle title="Bulk Upload & Item Search" />
      <Helmet title="Bulk Upload & Item Search" />
      <Card className={classes.marginTopBottom5}>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          className={classes.bulkUploadTopCard}
        >
          <Grid item>
            <BrowseAltTextContainer />
          </Grid>
          <Grid item>
            <div className={classes.divider}>or</div>
          </Grid>
          <Grid item>
            <AssetSearchContainer />
          </Grid>
          <Grid>
            <Button
              className={classes.primaryButton}
              variant="outlined"
              color="primary"
              onClick={() => {
                props.clearUploadData()
              }}
            >
              Discard
            </Button>

            <Button
              disabled={
                props?.selectedData?.length <= 0 ||
                some(props.uploadData, (data) => data.isAltTextEditable)
              }
              className={`${classes.secondaryButton} ${classes.downloadDataButton}`}
              onClick={() => handleDownload()}
              variant="outlined"
              color="primary"
            >
              D/L from database
            </Button>

            <Button
              className={classes.secondaryButton}
              onClick={() => props.altTextDownLoadTemplate()}
              variant="outlined"
              color="primary"
            >
              Get Template
            </Button>
            <Button
              disabled={
                props?.selectedData?.length <= 0 ||
                some(props.uploadData, (data) => data.isAltTextEditable)
              }
              className={classes.primaryButton}
              variant="contained"
              color="primary"
              onClick={() => {
                publishDataToCorona(
                  props.selectedData,
                  props.auth,
                  props.publishData
                )
              }}
            >
              Publish Content
            </Button>

            <Grid>
              {props?.invalid_tcins.length > 0 ? (
                <div className={classes.infoAlert}>
                  <ErrorOutlineIcon className={classes.infoAlertIcon} />
                  Unable to submit Alt text for {
                    props?.invalid_tcins?.length
                  }{' '}
                  TCINs due to unapproved images.{' '}
                  <span
                    className="textAnimationForClick"
                    onClick={() => props.nonApprovedAltText(true)}
                  >
                    Click here
                  </span>{' '}
                  for details
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {(props.information || props.faq) && (
          <StrapiExpansionPanel
            faq={props.faq}
            information={props.information}
            expansionPanelOpen={expansionPanelOpen}
            handleFaqPanel={handlePanel}
          />
        )}
        {props.isFetching && (
          <div>
            <CircularProgress className={classes.progressBar} />
          </div>
        )}
        <NonApprovedImagePopUp
          nonApporvedAltTextImages={props.nonApporvedAltTextImages}
          nonApprovedAltText={props.nonApprovedAltText}
          invalid_tcins={props?.invalid_tcins}
        />
        <Grid>
          {props?.uploadData?.length > 0 && <AltTextTableUploadContainer />}
        </Grid>
      </Card>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAltTextBulkUploadStrapiData,
      publishData,
      clearUploadData,
      altTextDownLoadTemplate,
      downloadAllToExcelByTcins,
      nonApprovedAltText,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, altTextReducer } = state
  const {
    uploadData,
    isFetching,
    alert,
    information,
    faq,
    showDefaultAlert,
    selectedData,
    invalid_tcins,
    nonApporvedAltTextImages,
  } = altTextReducer
  return {
    uploadData,
    isFetching,
    auth,
    alert,
    information,
    faq,
    showDefaultAlert,
    selectedData,
    invalid_tcins,
    nonApporvedAltTextImages,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BulkUpload))
