/* eslint-disable default-case */
import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Grid,
  Card,
  Button,
  Tooltip,
  CardContent,
  Tabs,
  Tab,
  AppBar,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import UploadVideoContainer from '../UploadVideo/UploadVideoContainer'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import TcinCardContainer from '../TcinCard/TcinCardContainer'
import DialogBoxEditVttContainer from '../DialogBoxEditVtt/DialogBoxEditVttContainer'
import { withRouter } from '../../../../Shared/WithRouterHoc'
import ErrorDialog from '../../../../Shared/ErrorDialog/ErrorDialog'
import MDReactComponent from 'markdown-react-js'
import UndoIcon from '@material-ui/icons/Undo'
import {
  VIDEO_ASSETS,
  VIDEO_APPROVAL_STATUS,
  VIDEO_EDIT_MODE,
} from '../../../../Shared/Constants'

function TabPanel(props) {
  const {
    children = null,
    value = 0,
    index = 0,
    titleStyle = {},
    bodyStyle = {},
  } = props
  return (
    <React.Fragment>
      {children ? (
        <div hidden={value !== index}>
          {children.title ? (
            <MDReactComponent className={titleStyle} text={children.title} />
          ) : null}
          {children.body ? (
            <MDReactComponent className={bodyStyle} text={children.body} />
          ) : null}
        </div>
      ) : (
        <div className={titleStyle}>Content Unavailable</div>
      )}
    </React.Fragment>
  )
}

const ccPreviewStyle = (editMode, closedCaption, classes) => {
  if (editMode === VIDEO_EDIT_MODE.HISTORY) {
    if (closedCaption[0].approval_status === VIDEO_APPROVAL_STATUS.IN_PROCESS) {
      return classes.historyCcPreview
    } else if (
      closedCaption[0].approval_status === VIDEO_APPROVAL_STATUS.REJECTED
    ) {
      return classes.reviewCcPreview
    } else if (
      closedCaption[0].approval_status === VIDEO_APPROVAL_STATUS.APPROVED
    ) {
      return classes.historyCcPreview
    } else return classes.uploadCcPreview
  } else if (editMode === VIDEO_EDIT_MODE.REVIEW) {
    if (closedCaption[0].approval_status === VIDEO_APPROVAL_STATUS.IN_PROCESS) {
      return classes.reviewCcPreview
    } else return classes.historyCcPreview
  } else return classes.uploadCcPreview
}

function UploadPage(props) {
  const {
    classes = {},
    video = [],
    closedCaption = [],
    posterFrame = [],
    transcript = [],
    dropZoneEnter = {},
    onDrop = () => {},
    removeFile = () => {},
    videoFileAdded = false,
    ccFileAdded = false,
    posterFrameFileAdded = false,
    transcriptFileAdded = false,
    editMode = VIDEO_EDIT_MODE.UPLOAD,
    deleteAssetJob = () => {},
    videoGroupStatus = null,
    vttEditBoxOpen = false,
    editVttDialogBox = () => {},
    invalidFileError = false,
    removeInvalidFile = () => {},
    invalidFiles = [],
    information = null,
    videoInfo = null,
    closedCaptionInfo = null,
    posterFrameInfo = null,
    transcriptInfo = null,
    selectedHelpContent = null,
    validFiles = [],
    isFetching = false,
    showRejectButtons = false,
    setRejectionMessage = () => {},
    rejectionMessages = {},
  } = props

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    switch (selectedHelpContent) {
      case VIDEO_ASSETS.VIDEO.type: {
        setValue(1)
        props.clearSelectedVideoHelpContent()
        break
      }
      case VIDEO_ASSETS.CLOSED_CAPTION.type: {
        setValue(2)
        props.clearSelectedVideoHelpContent()
        break
      }
      case VIDEO_ASSETS.POSTER_FRAME.type: {
        setValue(3)
        props.clearSelectedVideoHelpContent()
        break
      }
      case VIDEO_ASSETS.MEDIA_ALTERNATIVE.type: {
        setValue(4)
        props.clearSelectedVideoHelpContent()
        break
      }
    }
  })

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [videoRejected, setVideoRejection] = React.useState(false)
  const [videoRejectedMessage, setVideoRejectionMessage] = React.useState('')
  const [closedCaptionRejected, setClosedCaptionRejection] =
    React.useState(false)
  const [closedCaptionRejectedMessage, setClosedCaptionRejectionMessage] =
    React.useState('')
  const [posterFrameRejected, setPosterFrameRejection] = React.useState(false)
  const [posterFrameRejectedMessage, setPosterFrameRejectionMessage] =
    React.useState('')
  const [transcriptRejected, setTranscriptRejection] = React.useState(false)
  const [transcriptRejectedMessage, setTranscriptRejectionMessage] =
    React.useState('')

  return (
    <React.Fragment>
      {isFetching ? (
        <CircularProgress className={classes.progressBar} />
      ) : (
        <Grid container>
          <TcinCardContainer
            video={video}
            closedCaption={closedCaption}
            posterFrame={posterFrame}
            transcript={transcript}
            validFiles={validFiles}
            removeFile={removeFile}
          />
          <Grid container item xs={7}>
            <Grid item xs={12} xl={6} lg={6} md={6} sm={12}>
              <Card className={classes.uploadCard}>
                {video.length > 0 && videoFileAdded ? (
                  <div>
                    <Grid
                      container
                      direction="row"
                      className={classes.videoTitle}
                    >
                      <Grid
                        container
                        item
                        xs={6}
                        justify="flex-start"
                        alignItems="center"
                      >
                        <div className={classes.videoFileTypeTitle}>Video</div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <Grid
                          container
                          item
                          xs={10}
                          justify="flex-end"
                          className={classes.videoHeaderFileName}
                        >
                          <span
                            style={
                              editMode !== VIDEO_EDIT_MODE.HISTORY &&
                              video[0].approval_status ===
                                VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                              videoGroupStatus ===
                                VIDEO_APPROVAL_STATUS.REJECTED
                                ? { paddingRight: '10px' }
                                : { paddingRight: '40px' }
                            }
                          >
                            {video[0].name ? video[0].name : video[0].file_name}
                          </span>
                        </Grid>
                        {video[0].blobUrl && (
                          <Grid
                            container
                            item
                            xs={2}
                            justify="flex-start"
                            className={classes.deleteIcon}
                          >
                            <Tooltip title="Remove File">
                              <DeleteIcon
                                onClick={() =>
                                  removeFile(VIDEO_ASSETS.VIDEO.type)
                                }
                              />
                            </Tooltip>
                          </Grid>
                        )}
                        {video[0].approval_status ===
                          VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                          videoGroupStatus ===
                            VIDEO_APPROVAL_STATUS.REJECTED && (
                            <Grid
                              container
                              item
                              xs={2}
                              justify="flex-start"
                              className={classes.deleteIcon}
                            >
                              <Tooltip title="Remove File">
                                <DeleteIcon
                                  onClick={() =>
                                    deleteAssetJob(
                                      VIDEO_ASSETS.VIDEO.type,
                                      video[0].job_id
                                    )
                                  }
                                />
                              </Tooltip>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                    <Grid>
                      {video[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.REJECTED && (
                        <div className={classes.rejectedMessage}>
                          Rejected: {video[0].reject_reason}
                        </div>
                      )}
                      {video[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                        videoGroupStatus !== VIDEO_APPROVAL_STATUS.REJECTED && (
                          <div
                            className={
                              rejectionMessages.video_reject === null
                                ? classes.waitingForReviewMessage
                                : classes.rejectedMessage
                            }
                          >
                            Video:{' '}
                            {rejectionMessages.video_reject === null
                              ? 'Waiting for Review'
                              : VIDEO_APPROVAL_STATUS.REJECTED}
                          </div>
                        )}
                      {video[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.APPROVED && (
                        <div className={classes.approvedMessage}>
                          Video: Approved
                        </div>
                      )}
                    </Grid>
                    <Grid container justify="center">
                      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                      <video
                        src={
                          video[0].blobUrl
                            ? video[0].blobUrl
                            : video[0].asset_url
                        }
                        controls
                        crossOrigin="anonymous"
                        className={classes.vuVideoPreview}
                      >
                        {closedCaption.length > 0 &&
                          closedCaption[0].blobUrl && (
                            <track
                              label="English"
                              kind="subtitles"
                              srcLang="en"
                              src={closedCaption[0].blobUrl}
                              default
                            />
                          )}
                        {closedCaption.length > 0 &&
                          closedCaption[0].asset_url && (
                            <track
                              label="English"
                              kind="subtitles"
                              srcLang="en"
                              src={closedCaption[0].asset_url}
                              default
                            />
                          )}
                      </video>
                      {showRejectButtons &&
                        video.length > 0 &&
                        !videoRejected &&
                        rejectionMessages.video_reject === null && (
                          <Button
                            onClick={() => setVideoRejection(true)}
                            variant="contained"
                            color="primary"
                          >
                            Reject
                          </Button>
                        )}
                      {videoRejected &&
                        rejectionMessages.video_reject === null && (
                          <Grid item>
                            <TextField
                              multiline
                              rows="1"
                              className={classes.forControl}
                              label="Rejection Comment"
                              onChange={(e) =>
                                setVideoRejectionMessage(e.target.value)
                              }
                            />
                            <Button
                              onClick={() => setVideoRejection(false)}
                              variant="outlined"
                              color="primary"
                              className={classes.videoRejectButtons}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() =>
                                setRejectionMessage({
                                  type: VIDEO_ASSETS.VIDEO.id,
                                  message: videoRejectedMessage,
                                })
                              }
                              variant="contained"
                              color="primary"
                              className={classes.videoRejectButtons}
                            >
                              Reject
                            </Button>
                          </Grid>
                        )}
                      {rejectionMessages.video_reject !== null && (
                        <Grid container direction="row">
                          <Grid
                            container
                            item
                            xs={6}
                            justify="flex-start"
                            alignItems="baseline"
                          >
                            <div className={classes.marginLeft10Top5}>
                              <span className={classes.red}>Rejected:</span>{' '}
                              {rejectionMessages.video_reject}
                            </div>
                          </Grid>
                          <Grid container item xs={6} justify="flex-end">
                            <Button
                              className={classes.marginRight10}
                              onClick={() => {
                                setRejectionMessage({
                                  type: VIDEO_ASSETS.VIDEO.id,
                                  message: null,
                                })
                                setVideoRejection(false)
                              }}
                              variant="outlined"
                              color="primary"
                            >
                              <UndoIcon /> Undo
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {editMode === VIDEO_EDIT_MODE.HISTORY &&
                      video[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.REJECTED && (
                        <Grid container justify="center">
                          <Button
                            className={classes.addReplacement}
                            onClick={() =>
                              deleteAssetJob(
                                VIDEO_ASSETS.VIDEO.type,
                                video[0].job_id
                              )
                            }
                            variant="contained"
                            color="primary"
                          >
                            Add a replacement Video
                          </Button>
                        </Grid>
                      )}
                  </div>
                ) : (
                  <UploadVideoContainer
                    fileType={VIDEO_ASSETS.VIDEO.type}
                    requiredType={'Required'}
                    dropZoneEnter={dropZoneEnter.video}
                    onDrop={onDrop}
                  />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} xl={6} lg={6} md={6} sm={12}>
              <Card className={classes.uploadCard}>
                {closedCaption.length > 0 && ccFileAdded ? (
                  <div>
                    <Grid
                      container
                      direction="row"
                      className={classes.videoTitle}
                    >
                      <Grid
                        container
                        item
                        xs={6}
                        justify="flex-start"
                        alignItems="center"
                      >
                        <div className={classes.videoFileTypeTitle}>
                          Closed Caption
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <Grid
                          container
                          item
                          xs={10}
                          justify="flex-end"
                          className={classes.videoHeaderFileName}
                        >
                          <span
                            style={
                              closedCaption[0].approval_status ===
                                VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                              (videoGroupStatus ===
                                VIDEO_APPROVAL_STATUS.REJECTED ||
                                videoGroupStatus ===
                                  VIDEO_APPROVAL_STATUS.IN_PROCESS)
                                ? { paddingRight: '10px' }
                                : { paddingRight: '10px', paddingTop: '11px' }
                            }
                          >
                            {closedCaption[0].name
                              ? closedCaption[0].name
                              : closedCaption[0].file_name}
                          </span>
                          {closedCaption[0].blobUrl && (
                            <div className={classes.deleteIcon}>
                              <Tooltip title="Edit File">
                                <EditIcon
                                  onClick={() => editVttDialogBox()}
                                  className={classes.paddingRight5}
                                />
                              </Tooltip>
                              <Tooltip title="Remove File">
                                <DeleteIcon
                                  onClick={() =>
                                    removeFile(VIDEO_ASSETS.CLOSED_CAPTION.type)
                                  }
                                />
                              </Tooltip>
                            </div>
                          )}
                          {closedCaption[0].approval_status ===
                            VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                            videoGroupStatus ===
                              VIDEO_APPROVAL_STATUS.REJECTED && (
                              <div className={classes.deleteIcon}>
                                <Tooltip title="Edit File">
                                  <EditIcon
                                    onClick={() => editVttDialogBox()}
                                    className={classes.paddingRight5}
                                  />
                                </Tooltip>
                                <Tooltip title="Remove File">
                                  <DeleteIcon
                                    onClick={() =>
                                      deleteAssetJob(
                                        VIDEO_ASSETS.CLOSED_CAPTION.type,
                                        closedCaption[0].job_id
                                      )
                                    }
                                  />
                                </Tooltip>
                              </div>
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid>
                      {closedCaption[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.REJECTED && (
                        <div className={classes.rejectedMessage}>
                          Rejected: {closedCaption[0].reject_reason}
                        </div>
                      )}
                      {closedCaption[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                        videoGroupStatus !== VIDEO_APPROVAL_STATUS.REJECTED && (
                          <div
                            className={
                              rejectionMessages.closed_caption_reject === null
                                ? classes.waitingForReviewMessage
                                : classes.rejectedMessage
                            }
                          >
                            Closed Caption:{' '}
                            {rejectionMessages.closed_caption_reject === null
                              ? 'Waiting for Review'
                              : VIDEO_APPROVAL_STATUS.REJECTED}
                          </div>
                        )}
                      {closedCaption[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.APPROVED && (
                        <div className={classes.approvedMessage}>
                          Closed Caption: Approved
                        </div>
                      )}
                    </Grid>
                    <Grid container justify="center">
                      <div
                        className={ccPreviewStyle(
                          editMode,
                          closedCaption,
                          classes
                        )}
                      >
                        <pre>{closedCaption[0].fileContents}</pre>
                      </div>
                      {showRejectButtons &&
                        closedCaption.length > 0 &&
                        !closedCaptionRejected &&
                        rejectionMessages.closed_caption_reject === null && (
                          <Button
                            onClick={() => setClosedCaptionRejection(true)}
                            variant="contained"
                            color="primary"
                          >
                            Reject
                          </Button>
                        )}
                      {closedCaptionRejected &&
                        rejectionMessages.closed_caption_reject === null && (
                          <Grid item>
                            <TextField
                              multiline
                              rows="1"
                              className={classes.forControl}
                              label="Rejection Comment"
                              onChange={(e) =>
                                setClosedCaptionRejectionMessage(e.target.value)
                              }
                            />
                            <Button
                              onClick={() => setClosedCaptionRejection(false)}
                              variant="outlined"
                              color="primary"
                              className={classes.videoRejectButtons}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() =>
                                setRejectionMessage({
                                  type: VIDEO_ASSETS.CLOSED_CAPTION.id,
                                  message: closedCaptionRejectedMessage,
                                })
                              }
                              variant="contained"
                              color="primary"
                              className={classes.videoRejectButtons}
                            >
                              Reject
                            </Button>
                          </Grid>
                        )}
                      {rejectionMessages.closed_caption_reject !== null && (
                        <Grid container direction="row">
                          <Grid
                            container
                            item
                            xs={6}
                            justify="flex-start"
                            alignItems="baseline"
                          >
                            <div className={classes.marginLeft10Top5}>
                              <span className={classes.red}>Rejected:</span>{' '}
                              {rejectionMessages.closed_caption_reject}
                            </div>
                          </Grid>
                          <Grid container item xs={6} justify="flex-end">
                            <Button
                              className={classes.marginRight10}
                              onClick={() => {
                                setRejectionMessage({
                                  type: VIDEO_ASSETS.CLOSED_CAPTION.id,
                                  message: null,
                                })
                                setClosedCaptionRejection(false)
                              }}
                              variant="outlined"
                              color="primary"
                            >
                              <UndoIcon /> Undo
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {editMode === VIDEO_EDIT_MODE.HISTORY &&
                      (closedCaption[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.REJECTED ||
                        closedCaption[0].approval_status ===
                          VIDEO_APPROVAL_STATUS.APPROVED) &&
                      videoGroupStatus !== VIDEO_APPROVAL_STATUS.IN_PROCESS && (
                        <Grid container justify="center">
                          <Button
                            className={classes.addReplacement}
                            onClick={() =>
                              deleteAssetJob(
                                VIDEO_ASSETS.CLOSED_CAPTION.type,
                                closedCaption[0].job_id
                              )
                            }
                            variant="contained"
                            color="primary"
                          >
                            Add a replacement Closed Caption
                          </Button>
                        </Grid>
                      )}
                  </div>
                ) : (
                  <UploadVideoContainer
                    fileType={VIDEO_ASSETS.CLOSED_CAPTION.type}
                    requiredType={'Required'}
                    dropZoneEnter={dropZoneEnter.closedCaption}
                    onDrop={onDrop}
                  />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} xl={6} lg={6} md={6} sm={12}>
              <Card className={classes.uploadCard}>
                {posterFrame.length > 0 && posterFrameFileAdded ? (
                  <div>
                    <Grid
                      container
                      direction="row"
                      className={classes.videoTitle}
                    >
                      <Grid
                        container
                        item
                        xs={6}
                        justify="flex-start"
                        alignItems="center"
                      >
                        <div className={classes.videoFileTypeTitle}>
                          Poster Frame
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <Grid
                          container
                          item
                          xs={10}
                          justify="flex-end"
                          className={classes.videoHeaderFileName}
                        >
                          <span
                            style={
                              posterFrame[0].approval_status ===
                                VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                              videoGroupStatus ===
                                VIDEO_APPROVAL_STATUS.REJECTED
                                ? { paddingRight: '10px' }
                                : { paddingRight: '40px' }
                            }
                          >
                            {posterFrame[0].name
                              ? posterFrame[0].name
                              : posterFrame[0].file_name}
                          </span>
                        </Grid>
                        {posterFrame[0].blobUrl && (
                          <Grid
                            container
                            item
                            xs={2}
                            justify="flex-start"
                            className={classes.deleteIcon}
                          >
                            <Tooltip title="Remove File">
                              <DeleteIcon
                                onClick={() =>
                                  removeFile(VIDEO_ASSETS.POSTER_FRAME.type)
                                }
                              />
                            </Tooltip>
                          </Grid>
                        )}
                        {posterFrame[0].approval_status ===
                          VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                          videoGroupStatus ===
                            VIDEO_APPROVAL_STATUS.REJECTED && (
                            <Grid
                              container
                              item
                              xs={2}
                              justify="flex-start"
                              className={classes.deleteIcon}
                            >
                              <Tooltip title="Remove File">
                                <DeleteIcon
                                  onClick={() =>
                                    deleteAssetJob(
                                      VIDEO_ASSETS.POSTER_FRAME.type,
                                      posterFrame[0].job_id
                                    )
                                  }
                                />
                              </Tooltip>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                    <Grid>
                      {posterFrame[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.REJECTED && (
                        <div className={classes.rejectedMessage}>
                          Rejected: {posterFrame[0].reject_reason}
                        </div>
                      )}
                      {posterFrame[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                        videoGroupStatus !== VIDEO_APPROVAL_STATUS.REJECTED && (
                          <div
                            className={
                              rejectionMessages.poster_frame_reject === null
                                ? classes.waitingForReviewMessage
                                : classes.rejectedMessage
                            }
                          >
                            Poster Frame:{' '}
                            {rejectionMessages.poster_frame_reject === null
                              ? 'Waiting for Review'
                              : VIDEO_APPROVAL_STATUS.REJECTED}
                          </div>
                        )}
                      {posterFrame[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.APPROVED && (
                        <div className={classes.approvedMessage}>
                          Poster Frame: Approved
                        </div>
                      )}
                    </Grid>
                    <Grid container justify="center">
                      <img
                        src={
                          posterFrame[0].blobUrl
                            ? posterFrame[0].blobUrl
                            : posterFrame[0].asset_url
                        }
                        alt="poster frame"
                        className={classes.vuPosterFramePreview}
                      />
                      {showRejectButtons &&
                        posterFrame.length > 0 &&
                        !posterFrameRejected &&
                        rejectionMessages.poster_frame_reject === null && (
                          <Button
                            onClick={() => setPosterFrameRejection(true)}
                            variant="contained"
                            color="primary"
                          >
                            Reject
                          </Button>
                        )}
                      {posterFrameRejected &&
                        rejectionMessages.poster_frame_reject === null && (
                          <Grid item>
                            <TextField
                              multiline
                              rows="1"
                              className={classes.forControl}
                              label="Rejection Comment"
                              onChange={(e) =>
                                setPosterFrameRejectionMessage(e.target.value)
                              }
                            />
                            <Button
                              onClick={() => setPosterFrameRejection(false)}
                              variant="outlined"
                              color="primary"
                              className={classes.videoRejectButtons}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() =>
                                setRejectionMessage({
                                  type: VIDEO_ASSETS.POSTER_FRAME.id,
                                  message: posterFrameRejectedMessage,
                                })
                              }
                              variant="contained"
                              color="primary"
                              className={classes.videoRejectButtons}
                            >
                              Reject
                            </Button>
                          </Grid>
                        )}
                      {rejectionMessages.poster_frame_reject !== null && (
                        <Grid container direction="row">
                          <Grid
                            container
                            item
                            xs={6}
                            justify="flex-start"
                            alignItems="baseline"
                          >
                            <div className={classes.marginLeft10Top5}>
                              <span className={classes.red}>Rejected:</span>{' '}
                              {rejectionMessages.poster_frame_reject}
                            </div>
                          </Grid>
                          <Grid container item xs={6} justify="flex-end">
                            <Button
                              className={classes.marginRight10}
                              onClick={() => {
                                setRejectionMessage({
                                  type: VIDEO_ASSETS.POSTER_FRAME.id,
                                  message: null,
                                })
                                setPosterFrameRejection(false)
                              }}
                              variant="outlined"
                              color="primary"
                            >
                              <UndoIcon /> Undo
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    {editMode === VIDEO_EDIT_MODE.HISTORY &&
                      (posterFrame[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.REJECTED ||
                        posterFrame[0].approval_status ===
                          VIDEO_APPROVAL_STATUS.APPROVED) &&
                      videoGroupStatus !== VIDEO_APPROVAL_STATUS.IN_PROCESS && (
                        <Grid container justify="center">
                          <Button
                            className={classes.addReplacement}
                            onClick={() =>
                              deleteAssetJob(
                                VIDEO_ASSETS.POSTER_FRAME.type,
                                posterFrame[0].job_id
                              )
                            }
                            variant="contained"
                            color="primary"
                          >
                            Add a replacement Posterframe
                          </Button>
                        </Grid>
                      )}
                  </div>
                ) : (
                  <UploadVideoContainer
                    fileType={VIDEO_ASSETS.POSTER_FRAME.type}
                    requiredType={'Optional'}
                    dropZoneEnter={dropZoneEnter.posterFrame}
                    onDrop={onDrop}
                    disabled={
                      videoGroupStatus !== null &&
                      videoGroupStatus === VIDEO_APPROVAL_STATUS.IN_PROCESS
                    }
                  />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} xl={6} lg={6} md={6} sm={12}>
              <Card className={classes.uploadCard}>
                {transcript.length > 0 && transcriptFileAdded ? (
                  <div>
                    <Grid
                      container
                      direction="row"
                      className={classes.videoTitle}
                    >
                      <Grid
                        container
                        item
                        xs={6}
                        justify="flex-start"
                        alignItems="center"
                      >
                        <div className={classes.videoFileTypeTitle}>
                          Media Alternative
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <Grid
                          container
                          item
                          xs={10}
                          justify="flex-end"
                          className={classes.videoHeaderFileName}
                        >
                          <span
                            style={
                              transcript[0].approval_status ===
                                VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                              videoGroupStatus ===
                                VIDEO_APPROVAL_STATUS.REJECTED
                                ? { paddingRight: '10px' }
                                : { paddingRight: '40px' }
                            }
                          >
                            {transcript[0].name
                              ? transcript[0].name
                              : transcript[0].file_name}
                          </span>
                        </Grid>
                        {transcript[0].blobUrl && (
                          <Grid
                            container
                            item
                            xs={2}
                            justify="flex-start"
                            className={classes.deleteIcon}
                          >
                            <Tooltip title="Remove File">
                              <DeleteIcon
                                onClick={() =>
                                  removeFile(
                                    VIDEO_ASSETS.MEDIA_ALTERNATIVE.type
                                  )
                                }
                              />
                            </Tooltip>
                          </Grid>
                        )}
                        {transcript[0].approval_status ===
                          VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                          videoGroupStatus ===
                            VIDEO_APPROVAL_STATUS.REJECTED && (
                            <Grid
                              container
                              item
                              xs={2}
                              justify="flex-start"
                              className={classes.deleteIcon}
                            >
                              <Tooltip title="Remove File">
                                <DeleteIcon
                                  onClick={() =>
                                    deleteAssetJob(
                                      VIDEO_ASSETS.MEDIA_ALTERNATIVE.type,
                                      transcript[0].job_id
                                    )
                                  }
                                />
                              </Tooltip>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                    <Grid>
                      {transcript[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.REJECTED && (
                        <div className={classes.rejectedMessage}>
                          Rejected: {transcript[0].reject_reason}
                        </div>
                      )}
                      {transcript[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.IN_PROCESS &&
                        videoGroupStatus !== VIDEO_APPROVAL_STATUS.REJECTED && (
                          <div
                            className={
                              rejectionMessages.transcript_reject === null
                                ? classes.waitingForReviewMessage
                                : classes.rejectedMessage
                            }
                          >
                            Media Alternative:{' '}
                            {rejectionMessages.transcript_reject === null
                              ? 'Waiting for Review'
                              : VIDEO_APPROVAL_STATUS.REJECTED}
                          </div>
                        )}
                      {transcript[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.APPROVED && (
                        <div className={classes.approvedMessage}>
                          Media Alternative: Approved
                        </div>
                      )}
                    </Grid>
                    <Grid container justify="center">
                      <object
                        data={
                          transcript[0].blobUrl
                            ? transcript[0].blobUrl
                            : transcript[0].asset_url
                        }
                        className={classes.vuTranscriptPreview}
                      >
                        <embed
                          ng-src={
                            transcript[0].blobUrl
                              ? transcript[0].blobUrl
                              : transcript[0].asset_url
                          }
                        />
                      </object>
                      {showRejectButtons &&
                        transcript.length > 0 &&
                        !transcriptRejected &&
                        rejectionMessages.transcript_reject === null && (
                          <Button
                            onClick={() => setTranscriptRejection(true)}
                            variant="contained"
                            color="primary"
                          >
                            Reject
                          </Button>
                        )}
                      {transcriptRejected &&
                        rejectionMessages.transcript_reject === null && (
                          <Grid item>
                            <TextField
                              multiline
                              rows="1"
                              className={classes.forControl}
                              label="Rejection Comment"
                              onChange={(e) =>
                                setTranscriptRejectionMessage(e.target.value)
                              }
                            />
                            <Button
                              onClick={() => setTranscriptRejection(false)}
                              variant="outlined"
                              color="primary"
                              className={classes.videoRejectButtons}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() =>
                                setRejectionMessage({
                                  type: VIDEO_ASSETS.MEDIA_ALTERNATIVE.id,
                                  message: transcriptRejectedMessage,
                                })
                              }
                              variant="contained"
                              color="primary"
                              className={classes.videoRejectButtons}
                            >
                              Reject
                            </Button>
                          </Grid>
                        )}
                      {rejectionMessages.transcript_reject !== null && (
                        <Grid container direction="row">
                          <Grid
                            container
                            item
                            xs={6}
                            justify="flex-start"
                            alignItems="baseline"
                          >
                            <div className={classes.marginLeft10Top5}>
                              <span className={classes.red}>Rejected:</span>{' '}
                              {rejectionMessages.transcript_reject}
                            </div>
                          </Grid>
                          <Grid container item xs={6} justify="flex-end">
                            <Button
                              className={classes.marginRight10}
                              onClick={() => {
                                setRejectionMessage({
                                  type: VIDEO_ASSETS.MEDIA_ALTERNATIVE.id,
                                  message: null,
                                })
                                setTranscriptRejection(false)
                              }}
                              variant="outlined"
                              color="primary"
                            >
                              <UndoIcon /> Undo
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {editMode === VIDEO_EDIT_MODE.HISTORY &&
                      (transcript[0].approval_status ===
                        VIDEO_APPROVAL_STATUS.REJECTED ||
                        transcript[0].approval_status ===
                          VIDEO_APPROVAL_STATUS.APPROVED) &&
                      videoGroupStatus !== VIDEO_APPROVAL_STATUS.IN_PROCESS && (
                        <Grid container justify="center">
                          <Button
                            className={classes.addReplacement}
                            onClick={() =>
                              deleteAssetJob(
                                VIDEO_ASSETS.MEDIA_ALTERNATIVE.type,
                                transcript[0].job_id
                              )
                            }
                            variant="contained"
                            color="primary"
                          >
                            Add a replacement Media Alternative
                          </Button>
                        </Grid>
                      )}
                  </div>
                ) : (
                  <UploadVideoContainer
                    fileType={VIDEO_ASSETS.MEDIA_ALTERNATIVE.type}
                    requiredType={'Optional'}
                    dropZoneEnter={dropZoneEnter.transcript}
                    onDrop={onDrop}
                    disabled={
                      videoGroupStatus !== null &&
                      videoGroupStatus === VIDEO_APPROVAL_STATUS.IN_PROCESS
                    }
                  />
                )}
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Card className={classes.margin10}>
              <AppBar position="static" className={classes.appBar}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  classes={{ flexContainer: classes.tabsFlex }}
                >
                  <Tab
                    label="Help/FAQ"
                    classes={{ root: classes.tabButtons }}
                  />
                  <Tab label="Video" classes={{ root: classes.tabButtons }} />
                  <Tab
                    label="Closed Caption"
                    classes={{ root: classes.tabButtons }}
                  />
                  <Tab
                    label="Poster Frame"
                    classes={{ root: classes.tabButtons }}
                  />
                  <Tab
                    label="Media Alternative"
                    classes={{ root: classes.tabButtons }}
                  />
                </Tabs>
              </AppBar>
              <CardContent className={classes.infoCard}>
                <TabPanel
                  value={value}
                  index={0}
                  titleStyle={`${classes.marginBottom10} ${classes.header}`}
                  bodyStyle={classes.data}
                  children={information}
                />
                <TabPanel
                  value={value}
                  index={1}
                  titleStyle={`${classes.marginBottom10} ${classes.header}`}
                  bodyStyle={classes.data}
                  children={videoInfo}
                />
                <TabPanel
                  value={value}
                  index={2}
                  titleStyle={`${classes.marginBottom10} ${classes.header}`}
                  bodyStyle={classes.data}
                  children={closedCaptionInfo}
                />
                <TabPanel
                  value={value}
                  index={3}
                  titleStyle={`${classes.marginBottom10} ${classes.header}`}
                  bodyStyle={classes.data}
                  children={posterFrameInfo}
                />
                <TabPanel
                  value={value}
                  index={4}
                  titleStyle={`${classes.marginBottom10} ${classes.header}`}
                  bodyStyle={classes.data}
                  children={transcriptInfo}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {vttEditBoxOpen && <DialogBoxEditVttContainer onDrop={onDrop} />}
      {invalidFileError && (
        <ErrorDialog
          invalidFileError={invalidFileError}
          removeInvalidFile={() => removeInvalidFile()}
          invalidFiles={invalidFiles}
        />
      )}
    </React.Fragment>
  )
}

// @ts-ignore
export default withRouter(withStyles(styles)(UploadPage))
