import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { DefaultState } from './Components/StageCoronaData'
import StageCoronaContainer from './Components/StageCorona/StageCoronaContainer'
import HeaderTitle from '../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'

class StageCoronaPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = DefaultState
  }

  render() {
    return (
      <React.Fragment>
        <HeaderTitle title="Send Image Data to Corona Stage" />
        <Helmet title="Send Image Data to Corona Stage" />
        <StageCoronaContainer />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const mapStateToProps = (state) => {
  const { stageCorona } = state
  const { imageDataCorona } = stageCorona
  return {
    imageDataCorona,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StageCoronaPage)
