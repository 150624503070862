import { useLayoutEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useDispatch } from 'react-redux'
import Layout from './Layout/Layout'

export const Main = () => {
  const auth = useAuth()
  const dispatch = useDispatch()
  const { isAuthenticated } = auth
  const { session, login } = auth

  useLayoutEffect(() => {}, [dispatch, session])

  if (isAuthenticated()) {
    return <Layout />
  } else {
    login({ redirect: window.location.href })
  }
}
