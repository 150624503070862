import MyFilter from '../../Shared/Filter/MyFilter/MyFilter'
import BuildFilter from '../../Shared/Filter/BuildFilter'
import NewFilter from '../../Shared/Filter/NewFilter'
import React, { useEffect, useState } from 'react'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TablePagination,
  CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import styles from '../theme'
import { connect } from 'react-redux'
import {
  newFilterAction,
  buildFilterExpand,
  clearFilters,
  changeFilterEvent,
  selectFilterValueEvent,
  buildFilterAction,
  disableAltTextSearchResults,
  handleChangePage,
  changeDefaultPageSize,
  handleSelectItem,
  requestAltImageSearchData,
  loadExistingFilter,
  removeFilterHandler,
  getAltImageSearchStrapiData,
} from '../../../store/altText/actionCreator'
import { FilterData } from './FilterData'
import {
  splitTcins,
  chipFilter,
  urlBuilderAltText,
  isExternalUser,
} from '../../Shared/SharedUtils'
import { urlProtocol } from '../../Shared/Constants'
import { uniqBy, isEmpty } from 'lodash'

import SearchResultContainer from './AltTextSeachResultContainer'
import {
  saveFilterDataEvent,
  fetchSavedFiltersEvent,
  toggleSaveFilterDialogue,
  toggleConfirmation,
  removeSavedFilter,
} from '../../../store/auth/actionCreator'
import StrapiExpansionPanel from '../../Shared/StrapiComponents/StrapiExpansionPanel'

const AltTextSearch = (props) => {
  const {
    classes = {},
    buildFilterContainerShown = false,
    newFilterContainerShown = false,
    buildFilterExpansionPanel = false,
    isConfirmationOpen = false,
    selectedFilter = -1,
    filterValues = {},
    defaultFilterValues = {},
    isFetchingSavedFilters = false,
    isFetchingAuth = false,
    selectedFilters = [],
    dataContainerShown = false,
    isFetching = false,
    errorSearching = false,
    rhData = [],
    selectedItems = [],
    auth,
    isSaveFilterOpen = false,
    saveFilter = {},
    pageName = 'AltTextPage',
    pristine,
    userProfile = {},
    information = null,
    faq = null,
  } = props

  const initialFilterValue = {
    tcins: [],
  }

  const savedFilterData =
    userProfile && userProfile.search_filters
      ? userProfile.search_filters.filter(
          (filter) => filter.page_name === pageName
        )
      : []

  const paginationValues =
    userProfile && userProfile.pagination
      ? userProfile.pagination.filter((item) => item.page_name === pageName)
      : undefined

  const pageSize =
    paginationValues && paginationValues[0] && paginationValues[0].page_size
      ? paginationValues[0].page_size
      : props.defaultPageSize

  const sortDirection =
    paginationValues && paginationValues[0] && paginationValues[0].sort_order
      ? paginationValues[0].sort_order.toUpperCase()
      : props.sortDirection

  const sortField =
    paginationValues && paginationValues[0] && paginationValues[0].sort_field
      ? paginationValues[0].sort_field
      : props.sortField

  useEffect(() => {
    props.fetchSavedFiltersEvent()
    props.getAltImageSearchStrapiData(isExternalUser(auth))
    if (!isEmpty(selectedFilters)) {
      let filterCriteria = props.selectedFilters
      props.buildFilterExpand(false)
      props.newFilterAction(true)
      props.loadExistingFilter(filterCriteria)
      props.requestAltImageSearchData(
        filterCriteria,
        0,
        pageSize,
        sortDirection,
        sortField
      )
    }
  }, [])

  useEffect(() => {
    if (
      buildFilterContainerShown ||
      newFilterContainerShown ||
      dataContainerShown
    ) {
      expandFaq(false)
    }
  }, [
    buildFilterContainerShown || newFilterContainerShown || dataContainerShown,
  ])

  const handleCancelConfirmation = () => {
    props.toggleConfirmation(false, null)
  }

  const handleConfirmRemoveFilter = () => {
    props.removeSavedFilter(props.confirmationPayload.id)
  }

  const onClick = () => {
    props.newFilterAction(true)
    props.buildFilterExpand(true)
    props.clearFilters()
  }

  const loadFilterHandler = (item) => {
    let filterData = JSON.parse(item.filter_criteria)
    props.buildFilterExpand(false)
    props.newFilterAction(true)
    props.loadExistingFilter(filterData)
    props.requestAltImageSearchData(
      filterData,
      0,
      pageSize,
      sortDirection,
      sortField
    )
  }

  const removeSavedFilterHandler = (item) => {
    props.toggleConfirmation(true, { id: item })
  }

  const handlePanel = () => {
    props.buildFilterExpand(!props.buildFilterExpansionPanel)
  }

  const onFilterSelect = (event) => {
    props.changeFilterEvent({
      selectedFilter: event.target.value,
    })
  }

  const selectFilterValue = (selectedFilterValues) => {
    props.selectFilterValueEvent({
      filterValues: selectedFilterValues,
    })
  }

  const handleAddTcin = (selectedTcins) => {
    let chips = []
    splitTcins(selectedTcins).forEach((i) => {
      chips.push({
        value: i,
        display: i,
      })
    })
    if (
      props.filterValues !== undefined &&
      props.filterValues.tcins.length > 0
    ) {
      props.filterValues.tcins.forEach((item) => {
        chips.push(item)
      })
    }
    selectFilterValue({ ...initialFilterValue, tcins: uniqBy(chips, 'value') })
  }

  const handleDeleteTcin = (deletedTcin) => {
    let chips = []
    props.filterValues.tcins.forEach((item) => {
      if (item.value !== deletedTcin) {
        chips.push(item)
      }
    })
    selectFilterValue({ ...initialFilterValue, tcins: chips })
  }

  const buildFilter = (selectedFilter, selectedFilterValue) => {
    if (!props.isFetching) {
      props.buildFilterAction(
        {
          newFilterContainerShown: true,
          dataContainerShown: true,
          selectedFilters: props.selectedFilters,
          selectedFilter: selectedFilter,
          selectedFilterValue: selectedFilterValue,
        },
        props.currentPage,
        pageSize,
        sortDirection,
        sortField
      )
    }
  }

  const addNewFilter = () => {
    var selectedFilter = FilterData.filter(
      (item) => item.value === props.selectedFilter
    )[0]
    var selectedFilterValue
    if (selectedFilter.value === 'tcin') {
      chipFilter(
        filterValues.tcins,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }

    // Return value to parent components
  }

  const clearFilters = () => {
    props.clearFilters()
  }

  const disableAltTextApiInteract = () => {
    let disableAssets = []
    selectedItems.forEach((item) => {
      const image = {
        publish_url: item.alt_text.publish_url,
        base_url: item.alt_text.base_url,
      }
      const assetUrl = urlProtocol + urlBuilderAltText(image)
      var data = {}
      data.tcin = item.tcin
      data.alt_text = item.alt_text.alt_text_des
      data.image_url = assetUrl
      data.alt_text_active = !item.alt_text.alt_text_active
      disableAssets.push(data)
    })
    var requestBody = {
      data: disableAssets,
      uploaded_by: auth.email ? auth.email : auth.lanId,
    }

    props.disableAltTextSearchResults(
      requestBody,
      props.selectedFilters,
      props.currentPage,
      pageSize,
      sortDirection,
      sortField
    )
  }

  const handleChangePage = (_, page) => {
    props.handleChangePage(
      {
        currentPage: page,
        defaultPageSize: pageSize,
        selectedFilters: props.selectedFilters,
      },
      props.sortDirection,
      props.sortField
    )
  }
  const handleChangeDefaultPageSize = (event) => {
    props.changeDefaultPageSize(
      {
        defaultPageSize: event.target.value,
        selectedFilters: props.selectedFilters,
      },
      props.sortDirection,
      props.sortField,
      props.pageName
    )
  }

  const saveNewFilter = () => {
    if (props.selectedFilters && props.selectedFilters.length > 0) {
      props.toggleSaveFilterDialogue(true)
    }
  }
  const handleClose = () => {
    props.toggleSaveFilterDialogue(false)
  }

  const handleSave = () => {
    props.saveFilterDataEvent({
      filterName: props.saveFilter.values.saveFilter,
      filterCriteria: JSON.stringify(props.selectedFilters),
      pageSize: pageSize,
      sortOrder: props.sortDirection,
      sortField: props.sortField,
      pageName: pageName,
    })
  }

  const removeFilterHandler = (mainFilter, filterVal) => {
    props.removeFilterHandler(
      {
        selectedFilters: props.selectedFilters,
        mainFilterValue: mainFilter,
        filterValue: filterVal,
      },
      props.currentPage,
      props.defaultPageSize,
      sortDirection,
      sortField
    )
  }

  const [expansionPanelOpen, expandFaq] = useState(true)
  const handleFaqPanel = () => {
    expandFaq(!expansionPanelOpen)
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <HeaderTitle title="Alt Text Seach" />
        <Helmet title="Alt Text Seach" />
        <Grid container>
          <Grid item xs={12} sm={12}>
            <MyFilter
              isConfirmationOpen={isConfirmationOpen}
              handleCancelConfirmation={handleCancelConfirmation}
              handleConfirmRemoveFilter={handleConfirmRemoveFilter}
              savedFilterData={savedFilterData}
              onClick={onClick}
              loadFilterHandler={loadFilterHandler}
              removeSavedFilterHandler={removeSavedFilterHandler}
              isFetchingSavedFilters={isFetchingSavedFilters}
              disabled={isFetchingAuth}
            />
          </Grid>
        </Grid>
      </React.Fragment>
      <React.Fragment>
        {(buildFilterContainerShown || newFilterContainerShown) && (
          <Accordion
            className={classes.buildFilterExpansion}
            expanded={buildFilterExpansionPanel}
            onChange={handlePanel}
          >
            <AccordionSummary>
              <Typography>
                {buildFilterExpansionPanel ? 'Hide ' : 'Show '} Filter
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {buildFilterContainerShown && (
                <Grid item xs={12} sm={6}>
                  <BuildFilter
                    selectedFilter={selectedFilter}
                    onFilterSelect={onFilterSelect}
                    filterValues={filterValues}
                    handleAddTcin={handleAddTcin}
                    handleDeleteTcin={handleDeleteTcin}
                    addNewFilter={addNewFilter}
                    defaultFilterValues={defaultFilterValues}
                    FilterData={FilterData}
                  />
                </Grid>
              )}
              {newFilterContainerShown && (
                <Grid item xs={12} sm={6}>
                  <NewFilter
                    clearFilters={clearFilters}
                    selectedFilters={selectedFilters}
                    saveNewFilter={saveNewFilter}
                    removeFilterHandler={removeFilterHandler}
                    isSaveFilterOpen={isSaveFilterOpen}
                    saveFilter={saveFilter}
                    handleClose={handleClose}
                    handleSave={handleSave}
                    pristine={pristine}
                    disabled={isFetchingAuth}
                  />
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {(information || faq) && (
          <StrapiExpansionPanel
            faq={faq}
            information={information}
            expansionPanelOpen={expansionPanelOpen}
            handleFaqPanel={handleFaqPanel}
          />
        )}
        {isFetching && (
          <Grid className={classes.progressBar}>
            <CircularProgress />
          </Grid>
        )}
        {dataContainerShown && !isFetching && !errorSearching && (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <TablePagination
                component="div"
                count={props.totalElements}
                rowsPerPage={pageSize}
                page={props.currentPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeDefaultPageSize}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SearchResultContainer
                disableAltTextSearchResults={disableAltTextApiInteract}
                searchData={rhData}
                selectedItems={selectedItems}
                handleSelectItem={props.handleSelectItem}
              />
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      newFilterAction,
      buildFilterExpand,
      clearFilters,
      changeFilterEvent,
      selectFilterValueEvent,
      buildFilterAction,
      disableAltTextSearchResults,
      handleChangePage,
      changeDefaultPageSize,
      handleSelectItem,
      saveFilterDataEvent,
      fetchSavedFiltersEvent,
      toggleSaveFilterDialogue,
      toggleConfirmation,
      removeSavedFilter,
      requestAltImageSearchData,
      loadExistingFilter,
      removeFilterHandler,
      getAltImageSearchStrapiData,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageHistory, imageManage, auth, form, lov, altTextReducer } = state
  const {
    buildFilterContainerShown,
    newFilterContainerShown,
    dataContainerShown,
    isFetching,
    buildFilterExpansionPanel,
    alert,
    information,
    faq,
    selectedFilter,
    filterValues,
    defaultFilterValues,
    defaultPageSize,
    sortDirection,
    sortField,
    currentPage,
    selectedFilters,
    errorSearching,
    pageName,
    deptClassList,
    totalElements,
    selectedItems,
    rhData,
  } = altTextReducer
  const { showRelatedItemsModal, relatedItemsData, relatedTcin } = imageManage
  const {
    isFetchingSavedFilters,
    userProfile,
    isSaveFilterOpen,
    isConfirmationOpen,
    confirmationPayload,
    isFetchingAuth,
  } = auth
  const vendorList = lov.vendorList
  const isFetchingLov = lov.isFetching
  const imageHistoryDefaultPageSize = imageHistory.defaultPageSize
  const imageHistoryPageName = imageHistory.pageName
  const imageManageDefaultPageSize = imageManage.defaultPageSize
  const imageManagePageName = imageManage.pageName
  const { saveFilter } = form
  return {
    buildFilterContainerShown,
    newFilterContainerShown,
    dataContainerShown,
    isFetching,
    buildFilterExpansionPanel,
    auth,
    alert,
    information,
    faq,
    rhData,
    userProfile,
    isConfirmationOpen,
    selectedFilter,
    filterValues,
    defaultFilterValues,
    confirmationPayload,
    defaultPageSize,
    sortDirection,
    sortField,
    currentPage,
    selectedFilters,
    saveFilter,
    isSaveFilterOpen,
    isFetchingSavedFilters,
    errorSearching,
    pageName,
    vendorList,
    isFetchingAuth,
    deptClassList,
    totalElements,
    selectedItems,
    imageHistoryDefaultPageSize,
    imageHistoryPageName,
    imageManageDefaultPageSize,
    imageManagePageName,
    isFetchingLov,
    showRelatedItemsModal,
    relatedItemsData,
    relatedTcin,
  }
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AltTextSearch))
