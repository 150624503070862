export const REQUEST_HISTORY_DATA = 'REQUEST_HISTORY_DATA'
export const RECIEVE_HISTORY_DATA = 'RECIEVE_HISTORY_DATA'
export const CHANGE_CURRENT_PAGE_IMAGE_HISTORY =
  'CHANGE_CURRENT_PAGE_IMAGE_HISTORY'
export const CHANGE_DEFAULT_PAGE_SIZE_IMAGE_HISTORY =
  'CHANGE_DEFAULT_PAGE_SIZE_IMAGE_HISTORY'
export const BUILD_FILTER_EXPAND_IMAGES = 'BUILD_FILTER_EXPAND_IMAGES'
export const STORE_IMAGE_HISTORY_STRAPI_DATA = 'STORE_IMAGE_HISTORY_STRAPI_DATA'
export const NEW_FILTER_EVENT_IMAGE_HISTORY = 'NEW_FILTER_EVENT_IMAGE_HISTORY'
export const REMOVE_FILTER_EVENT_IMAGE_HISTORY =
  'REMOVE_FILTER_EVENT_IMAGE_HISTORY'
export const BUILD_FILTER_EVENT_IMAGE_HISTORY =
  'BUILD_FILTER_EVENT_IMAGE_HISTORY'
export const CHANGE_FILTER_EVENT_IMAGE_HISTORY =
  'CHANGE_FILTER_EVENT_IMAGE_HISTORY'
export const SELECT_FILTER_VALUE_EVENT_IMAGE_HISTORY =
  'SELECT_FILTER_VALUE_EVENT_IMAGE_HISTORY'
export const IMAGE_HISTORY_RESET_FETCH = 'IMAGE_HISTORY_RESET_FETCH'
export const SHOW_DEFAULT_ALERT_IMAGE_HISTORY =
  'SHOW_DEFAULT_ALERT_IMAGE_HISTORY'
export const ERROR_SEARCHING_IMAGE_HISTORY = 'ERROR_SEARCHING_IMAGE_HISTORY'
