import styles from './theme'
import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'

/* eslint-disable */
const renderTextField = ({
  input,
  label,
  value,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    fullWidth
    autoFocus
    margin="normal"
    {...input}
    {...custom}
  />
)

/* eslint-enable */
let SaveFilter = (props) => {
  const {
    isSaveFilterOpen = false,
    saveFilter = {},
    handleClose = () => {},
    handleSave = () => {},
    pristine = false,
    classes = {},
    disabled = false,
  } = props

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      open={isSaveFilterOpen}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle
        id="confirmation-dialog-title"
        className={classes.saveFilterTitle}
      >
        <div>
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.saveTitleText}
          >
            Save New Search
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.marginTop20}>
        <Grid container direction="column">
          <Field
            className={classes.saveFilterWidth}
            id="saveFilter"
            name="saveFilter"
            component={renderTextField}
          />
          {!isEmpty(saveFilter) && saveFilter.values.saveFilter.length < 200 ? (
            <span className={classes.helperTextLabel}>
              Please provide filter name
            </span>
          ) : (
            <span className={classes.maxExceeded}>
              You’ve exceeded the max of 200 characters.
            </span>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.marginLeft5}
          onClick={() => {
            handleClose()
            props.reset()
          }}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSave()
            props.reset()
          }}
          disabled={
            pristine || saveFilter.values.saveFilter.length > 200 || disabled
          }
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// @ts-ignore
SaveFilter = reduxForm({ form: 'saveFilter' })(SaveFilter)
SaveFilter = connect((state) => ({
  initialValues: {
    saveFilter: '',
  }, // pull initial values from account reducer
}))(SaveFilter)

// @ts-ignore
export default withStyles(styles)(SaveFilter)
