import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  cancelCreateBadge,
  changeBadgeEditState,
  changeBadge,
  changeBadgeCriteria,
  saveBadge,
  deleteBadge,
} from '../../../../../store/badges/actionCreator'
import { getBrandsList } from '../../../../../store/listOfValues/actionCreator'
import CreateBadge from './CreateBadge'

class CreateBadgeContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  changeToEditState = (fieldName) => {
    if (this.props.auth.permission.badgesEdit) {
      this.props.changeBadgeEditState(fieldName)
    }
  }

  changeToViewState = () => {
    this.props.changeBadgeEditState('')
  }

  changeBadgeCriteria = (name, value, badge) => {
    this.props.changeBadgeCriteria(name, value, badge)
  }

  changeBadge = (fieldName, value) => {
    this.props.changeBadge(fieldName, value)
  }

  saveBadge = (badge, active) => {
    this.props.saveBadge(badge, active, this.props.auth)
  }

  deleteBadge = (badge) => {
    this.props.deleteBadge(badge)
  }

  onSearchChange = (searchText) => {
    this.props.getBrandsList(searchText)
  }

  render() {
    const {
      createBadgeOpen,
      cancelCreateBadge,
      editableBadge,
      badgeFieldToEdit,
      brands,
      auth,
      createBadgeInProcess,
    } = this.props

    return (
      <CreateBadge
        createBadgeOpen={createBadgeOpen}
        cancelCreateBadge={cancelCreateBadge}
        badge={editableBadge}
        changeToEditState={this.changeToEditState}
        badgeFieldToEdit={badgeFieldToEdit}
        changeBadge={this.changeBadge}
        changeToViewState={this.changeToViewState}
        changeBadgeCriteria={this.changeBadgeCriteria}
        saveBadge={this.saveBadge}
        onSearchChange={(text) => this.onSearchChange(text)}
        auth={auth}
        createBadgeInProcess={createBadgeInProcess}
        deleteBadge={this.deleteBadge}
        brands={brands.map((brand) => {
          return {
            key: brand.brandName,
            value: brand.brandName,
          }
        })}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelCreateBadge,
      changeBadgeEditState,
      changeBadge,
      changeBadgeCriteria,
      saveBadge,
      getBrandsList,
      deleteBadge,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { badges, auth, lov } = state
  const {
    createBadgeOpen,
    editableBadge,
    badgeFieldToEdit,
    createBadgeInProcess,
  } = badges
  const { brandsList } = lov
  return {
    auth,
    createBadgeOpen,
    editableBadge,
    badgeFieldToEdit,
    brands: brandsList,
    createBadgeInProcess,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBadgeContainer)
