export const SIGN_IN = 'SIGN_IN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_OUT = 'SIGN_OUT'
export const PROMPT_SIGN_IN = 'PROMPT_SIGN_IN'
export const DECLINE_SIGN_IN = 'DECLINE_SIGN_IN'
export const RECEIVE_USER_PROFILE_DATA = 'RECEIVE_USER_PROFILE_DATA'
export const REQUEST_SAVE_FILTER_DATA = 'REQUEST_SAVE_FILTER_DATA'
export const SUCCESS_SAVE_FILTER_DATA = 'SUCCESS_SAVE_FILTER_DATA'
export const REQUEST_SAVED_FILTER_DATA = 'REQUEST_SAVED_FILTER_DATA'
export const RECEIVE_SAVED_FILTER_DATA = 'RECEIVE_SAVED_FILTER_DATA'
export const TOGGLE_SAVE_FILTER_DIALOGUE = 'TOGGLE_SAVE_FILTER_DIALOGUE'
export const TOGGLE_CONFIRMATION = 'TOGGLE_CONFIRMATION'
export const REQUEST_SAVED_FILTER_DATA_FAILURE =
  'REQUEST_SAVED_FILTER_DATA_FAILURE'
export const START_VENDOR_TCIN_MAPPING = 'START_VENDOR_TCIN_MAPPING'
export const SUCCESS_VENDOR_TCIN_MAPPING = 'SUCCESS_VENDOR_TCIN_MAPPING'
export const AUTH_RESET_FETCH = 'AUTH_RESET_FETCH'
