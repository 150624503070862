import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
// @ts-ignore
import NoThumbnail from '../../../../images/NoThumbnail.svg?url'
import { Draggable } from 'react-beautiful-dnd'
import { Tooltip } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import { urlProtocol } from '../../../Shared/Constants'
import { urlBuilder } from '../../../Shared/SharedUtils'
import LabelIcon from '@material-ui/icons/Label'

function DragAndDropImage(props) {
  const {
    image = {},
    classes = {},
    index = null,
    isDragDisabled = false,
    auth = {},
    setZoom = () => {},
    item,
    rowIndex,
    colIndex,
    getAssetLabel,
  } = props

  const [isZoomVisible, setIsZoomVisible] = useState(false)

  const assetUrl = urlProtocol + urlBuilder(image)

  return (
    <div className={classes.verticalFlex}>
      <Draggable
        draggableId={image.publishUrl + ' ' + index}
        index={index}
        isDragDisabled={isDragDisabled || !auth.permission.imgCurationEdit}
      >
        {(provided, snapshot) => (
          <span
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={classes.posRel}
            onMouseEnter={() => setIsZoomVisible(true)}
            onMouseLeave={() => setIsZoomVisible(false)}
          >
            <Tooltip
              title={isDragDisabled ? 'Primary images are required' : ''}
            >
              <img
                src={urlProtocol + urlBuilder(image) + '?hei=100&wei=100'}
                alt={image.viewType + ' ' + image.sequence}
                // @ts-ignore
                onError={(e) => {
                  e.target.src = NoThumbnail
                }}
                className={`
              ${
                snapshot.isDragging ? classes.imageBorder : classes.imageSpacing
              }
              ${image.active ? '' : classes.disabled}
              `}
              />
            </Tooltip>
            {provided.placeHolder}
            <Tooltip
              title="Zoom In"
              className={isZoomVisible ? classes.zoomin : classes.displayNone}
            >
              <ZoomInIcon
                color="primary"
                onClick={() => setZoom(`${urlBuilder(image)}?hei=800&wei=800`)}
              />
            </Tooltip>
            <div className={classes.utilityRow}>
              {image.changed && (
                <Tooltip title={'Unpublished Changes'}>
                  <BookmarkIcon className={classes.bookmarkIcon} />
                </Tooltip>
              )}
              {auth.permission.imgCurationEdit ? (
                <React.Fragment>
                  <Tooltip title={'Copy Image'}>
                    <FileCopyIcon
                      className={classes.actionIcons}
                      color="primary"
                      onClick={() => props.setCopiedImage(image)}
                    />
                  </Tooltip>
                  <LabelIcon
                    className={classes.actionIcons}
                    color="primary"
                    onClick={() => {
                      getAssetLabel(
                        item.tcin,
                        image.sequence,
                        true,
                        image,
                        item,
                        rowIndex,
                        colIndex,
                        assetUrl
                      )
                    }}
                  />
                </React.Fragment>
              ) : null}
            </div>
          </span>
        )}
      </Draggable>
    </div>
  )
}

// @ts-ignore
export default withStyles(styles)(DragAndDropImage)
