import { VIDEO_APPROVAL_STATUS } from '../../Shared/Constants'

const FilterData = [
  {
    display: 'Approval Status',
    value: 'approvalStatus',
  },
  {
    display: 'Date Range',
    value: 'dateRange',
  },
  {
    display: 'TCIN(s)',
    value: 'tcin',
  },
  {
    display: 'Vendor',
    value: 'vendor',
  },
]

const Timespans = [
  {
    display: 'Last 24 Hours',
    value: 'last24Hours',
  },
  {
    display: 'Last 3 Days',
    value: 'last3Days',
  },
  {
    display: 'last 7 Days',
    value: 'last7Days',
  },
  {
    display: 'last 14 Days',
    value: 'last14Days',
  },
  {
    display: 'last 30 Days',
    value: 'last30Days',
  },
  {
    display: 'Date Picker',
    value: 'datePicker',
  },
]

const VideoApprovalStatus = [
  {
    display: 'Ready for Review',
    value: VIDEO_APPROVAL_STATUS.IN_PROCESS,
  },
  {
    display: VIDEO_APPROVAL_STATUS.REJECTED,
    value: VIDEO_APPROVAL_STATUS.REJECTED,
  },
]

export { FilterData, Timespans, VideoApprovalStatus }
