import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
// @ts-ignore
import NoThumbnail from '../../../../images/NoThumbnail.svg?url'
import envConfigs from '../../../../config/apiConfig'
import Moment from 'react-moment'
import HistoryPageContainerHead from './HistoryPageContainerHead'
import HistoryPageContainerTitle from './HistoryPageContainerTitle'
import { Table, TableBody, TableCell, TableRow, Paper } from '@material-ui/core'
import { useState, useEffect } from 'react'

const calendarStrings = {
  lastDay: '[Yesterday at] LT',
  sameDay: '[Today at] LT',
  nextDay: '[Tomorrow at] LT',
  lastWeek: '[Last] dddd [at] LT',
  nextWeek: 'dddd [at] LT',
  sameElse: 'MM-DD-YYYY [at] LT',
}

const addImgConstant = '&wid=133&make_square=true'
const generatingPreview =
  envConfigs.api.sceneSevenBaseUrl + 'generating_preview'

function HistoryPage(props) {
  const {
    classes = {},
    totalElements = 0,
    rowsPerPage = 10,
    page = 0,
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    emptyRows = 0,
  } = props

  const [retainedHistoryData, SethistoryData] = useState([])

  useEffect(() => {
    if (props.historyData) {
      const sorted = [...props.historyData]
      SethistoryData(sorted)
    }
  }, [props.historyData])

  return (
    <Paper className={classes.width100Percent}>
      <HistoryPageContainerTitle
        totalElements={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <HistoryPageContainerHead />
          <TableBody>
            {retainedHistoryData &&
              retainedHistoryData
                .sort((a, b) => {
                  return a.created_timestamp < b.created_timestamp ? 1 : -1
                })
                .map((data) => {
                  return (
                    <TableRow key={data.id}>
                      <TableCell className={classes.width100}>
                        {!data.publish_url || data.publish_url === null ? (
                          <img
                            src={generatingPreview}
                            alt={data.tcin}
                            // @ts-ignore
                            onError={(e) => {
                              e.target.src = NoThumbnail
                            }}
                            className={classes.imageThumbnail}
                          />
                        ) : (
                          <img
                            src={data.publish_url + addImgConstant}
                            alt={data.tcin}
                            // @ts-ignore
                            onError={(e) => {
                              e.target.src = NoThumbnail
                            }}
                            className={classes.imageThumbnail}
                          />
                        )}
                      </TableCell>
                      <TableCell>{data.tcin}</TableCell>
                      <TableCell>{data.file_name}</TableCell>
                      <TableCell>
                        {data.events &&
                        data.events.publish_to_vault &&
                        data.events.publish_to_vault.end_time ? (
                          <Moment calendar={calendarStrings}>
                            {data.events.publish_to_vault.end_time}
                          </Moment>
                        ) : (
                          <Moment calendar={calendarStrings}>
                            {data.created_timestamp}
                          </Moment>
                        )}
                      </TableCell>
                      <TableCell>
                        {data.vendor_email ? (
                          <span>{data.vendor_email}</span>
                        ) : (
                          ' - '
                        )}
                      </TableCell>
                      <TableCell style={{ color: data.user_status_color }}>
                        {data.user_status_message}
                      </TableCell>
                    </TableRow>
                  )
                })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={10} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

// @ts-ignore
export default withStyles(styles)(HistoryPage)
