//
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import ExitIcon from '@material-ui/icons/ExitToApp'

export function UserBar(props) {
  const { classes, displayName, handleSignOut, handleSignIn, isAuthorized } =
    props
  return (
    <Toolbar classes={{ root: classes.root, gutters: classes.gutters }}>
      {isAuthorized && (
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Grid className={classes.displayName} item>
            {displayName}
          </Grid>
          <Grid item>
            <IconButton
              title="Sign Out"
              color="inherit"
              onClick={handleSignOut}
              aria-label="Sign Out"
            >
              <ExitIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {!isAuthorized && (
        <Button
          className={classes.signInButton}
          color="inherit"
          onClick={handleSignIn}
          aria-label="Sign In"
        >
          Sign In
        </Button>
      )}
    </Toolbar>
  )
}

UserBar.defaultProps = {
  classes: {},
}

const styles = {
  root: {
    backgroundColor: '#666',
    color: '#fff',
  },
  gutters: {
    paddingLeft: '14px',
    paddingRight: '4px',
  },
  displayName: {
    flexShrink: '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  signInButton: {
    margin: '0 auto',
  },
}

export default withStyles(styles)(UserBar)
