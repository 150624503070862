import React from 'react'
import { Typography, Toolbar, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../../theme'
import TextsmsIcon from '@material-ui/icons/Textsms'
import GroupIcon from '@material-ui/icons/Group'
import PersonIcon from '@material-ui/icons/Person'
import GetAppIcon from '@material-ui/icons/GetApp'
import Delete from '@material-ui/icons/Delete'
import { each, includes } from 'lodash'

function isCopyWriter(userInfo) {
  var returnGroup = true
  var adminGroup = false
  each(userInfo.memberOf, function (group) {
    var upperGroup = group.toUpperCase()
    if (includes(upperGroup, 'APP-CEP-COPYWRITING') && !adminGroup) {
      returnGroup = false
    }
    if (includes(upperGroup, 'ADMN-CEP-PROD')) {
      returnGroup = true
      adminGroup = true
    }
  })
  return returnGroup
}

function isDeleteAccess(userInfo) {
  var returnGroup = false
  each(userInfo.memberOf, function (group) {
    var upperGroup = group.toUpperCase()
    if (includes(upperGroup, 'ADMN-CEP-PROD')) {
      returnGroup = true
    }
    if (includes(upperGroup, 'APP-CEP-CONTENT')) {
      returnGroup = true
    }
  })
  return returnGroup
}

let CopyDataContainerToolbar = (props) => {
  const {
    selectedCopy,
    classes,
    auth,
    isActionDrawerOpen,
    deleteBulletAndCopy,
  } = props
  const numSelected = selectedCopy ? selectedCopy.length : 0
  const selectedCopyIncludesNotNewWriteRequest =
    selectedCopy.filter(
      (item) => item.current_event.event_type !== 'NewWriteRequest'
    ).length > 0
  const selectedCopyIncludesNotDone =
    selectedCopy.filter((item) => item.current_event.event_type !== 'Done')
      .length > 0
  return (
    <React.Fragment>
      {numSelected > 0 && (
        <Toolbar
          className={!isActionDrawerOpen ? classes.toolBar : classes.highlight}
        >
          <div className={classes.title}>
            {numSelected > 0 && (
              <Typography color="inherit">{numSelected} selected</Typography>
            )}
          </div>
          {numSelected > 0 ? null : <div className={classes.spacer} />}
          <div className={classes.actions}>
            {numSelected > 0 && isCopyWriter(auth) && (
              <Button
                className={props.classes.drawerButton}
                onClick={() => props.toggleActionDrawer(true, 'ROUTE_ACTION')}
              >
                <GroupIcon />
                <span className={classes.marginLeft5}>Route to Team</span>
              </Button>
            )}
            {numSelected > 0 && isCopyWriter(auth) && (
              <Button
                className={props.classes.drawerButton}
                onClick={() =>
                  props.toggleActionDrawerForAssigningWriters(
                    true,
                    'ASSIGN_ACTION'
                  )
                }
                disabled={selectedCopyIncludesNotNewWriteRequest}
              >
                <PersonIcon />
                <span className={classes.marginLeft5}>Assign to Writer</span>
              </Button>
            )}
            {numSelected > 0 && isCopyWriter(auth) && (
              <Button
                className={props.classes.drawerButton}
                onClick={() => props.toggleActionDrawer(true, 'NOTE_ACTION')}
              >
                <TextsmsIcon />
                <span className={classes.marginLeft5}>Write Note</span>
              </Button>
            )}
            {numSelected > 0 && isCopyWriter(auth) && (
              <Button
                className={props.classes.drawerButton}
                onClick={() => props.downloadSelectedTcinsCopy()}
              >
                <GetAppIcon />
                <span className={classes.marginLeft5}>
                  DOWNLOAD SELECTED TO EXCEL
                </span>
              </Button>
            )}
            {numSelected > 0 && isDeleteAccess(auth) && (
              <Button
                className={props.classes.drawerButton}
                onClick={() => {
                  props.toggleActionDrawer(true, 'DELETE_ACTION')
                  deleteBulletAndCopy()
                }}
                disabled={selectedCopyIncludesNotDone}
              >
                <Delete />
                <span className={classes.marginLeft5}>DELETE COPY/BULLETS</span>
              </Button>
            )}
          </div>
        </Toolbar>
      )}
    </React.Fragment>
  )
}

CopyDataContainerToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CopyDataContainerToolbar)
