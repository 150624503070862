export const REQUEST_BADGES = 'REQUEST_BADGES'
export const RECEIVE_BADGES = 'RECEIVE_BADGES'
export const UPDATE_BADGE_EDIT_STATE = 'UPDATE_BADGE_EDIT_STATE'
export const CHANGE_BADGE = 'CHANGE_BADGE'
export const BADGES_VALUE_PERCENT = 'BADGES_VALUE_PERCENT'
export const BADGES_CLEAR_UPLOAD = 'BADGES_CLEAR_UPLOAD'
export const BADGES_UPLOAD_IN_PROGRESS_START = 'BADGES_UPLOAD_IN_PROGRESS_START'
export const BADGES_DROP_ZONE_ACTIVE = 'BADGE_DROP_ZONE_ACTIVE'
export const BADGES_ADD_FILES_UPLOAD = 'BADGES_ADD_FILES_UPLOAD'
export const CHANGE_BADGE_CRITERIA = 'CHANGE_BADGE_CRITERIA'
export const SELECT_BADGE_EVENT = 'SELECT_BADGE_EVENT'
export const DOWNLOAD_START_BADGES = 'DOWNLOAD_START_BADGES'
export const DOWNLOAD_FINISH_BADGES = 'DOWNLOAD_FINISH_BADGES'
export const OPEN_CREATE_BADGE_MODAL = 'OPEN_CREATE_BADGE_MODAL'
export const CLOSE_CREATE_BADGE_MODAL = 'CLOSE_CREATE_BADGE_MODAL'
export const BADGES_RESET_FETCH = 'BADGES_RESET_FETCH'
export const CREATE_BADGE_IN_PROCESS = 'CREATE_BADGE_IN_PROCESS'
export const CREATE_BADGE_FAILED = 'CREATE_BADGE_FAILED'
