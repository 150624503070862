import React from 'react'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import { connect } from 'react-redux'

class DeleteConfirmationDialogContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    const {
      images,
      handleDialogOpenStatus,
      deleteImages,
      isDialogOpen,
      deletingInProgress,
      selectToggleDeleteConfirmation,
    } = this.props
    return (
      <React.Fragment>
        <DeleteConfirmationDialog
          images={images}
          handleDialogOpenStatus={(open) => handleDialogOpenStatus(open)}
          deleteImages={() => deleteImages()}
          dialogOpen={isDialogOpen}
          deletingInProgress={deletingInProgress}
          selectToggleDeleteConfirmation={(image) =>
            selectToggleDeleteConfirmation(image)
          }
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { training } = imageTagTraining
  const { imageInfo, deleteConfirmationisOpen } = training
  return {
    auth,
    imageInfo,
    deleteConfirmationisOpen,
  }
}

export default connect(mapStateToProps, null)(DeleteConfirmationDialogContainer)
