/* eslint-disable array-callback-return */
import {
  CHANGE_DEFAULT_PAGE_SIZE_VIDEO_REVIEW,
  STORE_VIDEO_REVIEW_STRAPI_DATA,
  BUILD_FILTER_EXPAND_VIDEO_REVIEW,
  NEW_FILTER_EVENT_VIDEO_REVIEW,
  BUILD_FILTER_EVENT_VIDEO_REVIEW,
  REMOVE_FILTER_EVENT_VIDEO_REVIEW,
  RECEIVE_VIDEO_REVIEW_DATA,
  REQUEST_VIDEO_REVIEW_DATA,
  ERROR_SEARCHING_VIDEO_REVIEW,
  CLEAR_VIDEO_REVIEW_DATA_EVENT,
  CHANGE_FILTER_EVENT_VIDEO_REVIEW,
  SELECT_FILTER_VALUE_EVENT_VIDEO_REVIEW,
  CHANGE_CURRENT_PAGE_VIDEO_REVIEW,
  SELECT_VIDEO_REVIEW_EVENT,
  SET_SORT_FIELD_VIDEO_REVIEW,
  SET_SORT_DIRECTION_VIDEO_REVIEW,
  VIDEO_REVIEW_SEARCH_RESET_FETCH,
  SHOW_DEFAULT_ALERT_VIDEO_REVIEW_SEARCH,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { isEmpty, isEqual, cloneDeep, toNumber } from 'lodash'
import { savePaginationDataEvent } from '../../auth/actionCreator'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'

function changePage(currentPage) {
  return {
    type: CHANGE_CURRENT_PAGE_VIDEO_REVIEW,
    payload: {
      currentPage: currentPage,
    },
  }
}

export function handleChangePage(data, sortDirection, sortField) {
  return (dispatch) => {
    dispatch(changePage(data.currentPage))
    dispatch(
      requestVideoReviewData(
        data.selectedFilters,
        data.currentPage,
        data.defaultPageSize,
        sortDirection,
        sortField
      )
    )
  }
}

function changePageSize(defaultPageSize) {
  return {
    type: CHANGE_DEFAULT_PAGE_SIZE_VIDEO_REVIEW,
    payload: {
      defaultPageSize: defaultPageSize,
    },
  }
}

export function changeDefaultPageSize(
  data,
  sortDirection,
  sortField,
  pageName
) {
  return (dispatch) => {
    dispatch(changePageSize(data.defaultPageSize))
    // Always setting the currentPage to 0 to prevent bugs
    dispatch(changePage(0))
    dispatch(
      requestVideoReviewData(
        data.selectedFilters,
        0,
        data.defaultPageSize,
        sortDirection,
        sortField
      )
    ).then(
      dispatch(
        savePaginationDataEvent({
          pageSize: data.defaultPageSize,
          sortOrder: sortDirection,
          sortField: sortField,
          pageName: pageName,
        })
      )
    )
  }
}

export function storeVideoReviewStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const errorData = data.filter(
    (item) => item.interface_document_type === 'error'
  )
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  const error =
    errorData.length > 0
      ? {
          title: errorData[0].user_facing_title,
          body: errorData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_VIDEO_REVIEW_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
      error,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_VIDEO_REVIEW_SEARCH,
    payload: data,
  }
}

export function getVideoReviewStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Video%20Review&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data)
          ? dispatch(showDefaultAlert(true))
          : dispatch(storeVideoReviewStrapiData(response.data))
        dispatch(showDefaultAlert(false))
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function buildFilterExpand(value) {
  return {
    type: BUILD_FILTER_EXPAND_VIDEO_REVIEW,
    payload: {
      buildFilterExpansionPanel: value,
    },
  }
}

export function newFilterAction(isShown) {
  return {
    type: NEW_FILTER_EVENT_VIDEO_REVIEW,
    payload: isShown,
  }
}

export function loadExistingFilter(selectedFilters) {
  return {
    type: BUILD_FILTER_EVENT_VIDEO_REVIEW,
    payload: {
      newFilterContainerShown: true,
      videoDataContainerShown: true,
      selectedFilters: selectedFilters,
    },
  }
}

export function clearFilters() {
  return {
    type: REMOVE_FILTER_EVENT_VIDEO_REVIEW,
    payload: {
      newFilterContainerShown: false,
      videoDataContainerShown: false,
      selectedFilters: [],
    },
  }
}

export function buildFilterAction(
  data,
  pageNumber,
  pageSize,
  sortDirection,
  sortField
) {
  var alreadySelectedFilters = data.selectedFilters.filter(
    (item) => item.filterValue === data.selectedFilter.value
  )
  if (alreadySelectedFilters.length > 0) {
    // If filter already selected and different value selected.
    let isDuplicateValueFound = false
    if (Array.isArray(data.selectedFilterValue)) {
      data.selectedFilterValue.forEach((item) => {
        isDuplicateValueFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val) => val.value === item.value
          ).length === 0
      })
    } else {
      isDuplicateValueFound =
        alreadySelectedFilters[0].selectedValues.filter(
          (val) => val.value === data.selectedFilterValue.value
        ).length === 0
    }
    if (isDuplicateValueFound) {
      return (dispatch) => {
        dispatch(changePage(0)) // Reset Current Page to first page
        var filter = dispatch(handleAlreadySelectedFilter(data))
        dispatch(
          requestVideoReviewData(
            filter.payload.selectedFilters,
            pageNumber,
            pageSize,
            sortDirection,
            sortField
          )
        )
      }
    } else {
      return (dispatch) => {
        dispatch(
          resetFetch(false),
          toast.error(
            'You have attempted to add duplicate filters.',
            TOAST_BODY
          )
        )
      }
    }
  } else {
    return (dispatch) => {
      dispatch(changePage(0)) // Reset Current Page to first page
      var filter = dispatch(handleNewFilter(data))
      dispatch(
        requestVideoReviewData(
          filter.payload.selectedFilters,
          0,
          pageSize,
          sortDirection,
          sortField
        )
      )
    }
  }
}

function handleAlreadySelectedFilter(data) {
  var deepCopy = cloneDeep(data.selectedFilters)
  let selectedValues = []
  let dateRangeAlreadySelected =
    deepCopy.filter((item) => item.filterValue === 'dateRange').length > 0
  let dateRangeAdded = data.selectedFilter.value === 'dateRange'
  if (dateRangeAlreadySelected && dateRangeAdded) {
    deepCopy
      .filter((item) => item.filterValue === 'dateRange')[0]
      .selectedValues.shift()
  }
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  let oldSelectedValue = deepCopy.filter(
    (v) => v.filterValue === data.selectedFilter.value
  )[0].selectedValues
  selectedValues.forEach((item) => {
    oldSelectedValue.push(item)
  })
  deepCopy
    .filter((v) => v.filterValue === data.selectedFilter.value)[0]
    .selectedValues.concat(selectedValues)
  return {
    type: BUILD_FILTER_EVENT_VIDEO_REVIEW,
    payload: {
      newFilterContainerShown: true,
      videoDataContainerShown: true,
      selectedFilters: deepCopy,
    },
  }
}

function handleNewFilter(data) {
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  var newSelectedFilters = [
    {
      filterValue: data.selectedFilter.value,
      filterDisplay: data.selectedFilter.display,
      selectedValues: selectedValues,
    },
  ]
  return {
    type: BUILD_FILTER_EVENT_VIDEO_REVIEW,
    payload: {
      newFilterContainerShown: true,
      videoDataContainerShown: true,
      selectedFilters: data.selectedFilters.concat(newSelectedFilters),
    },
  }
}

export function requestVideoReviewData(
  data,
  pageNumber,
  pageSize,
  sortDirection,
  sortField
) {
  var queryParam = {
    asset_source: ['helios_1p', 'vendor'],
  }

  data.map((item) => {
    if (item.filterValue === 'dateRange') {
      if (item.selectedValues[0].value.fromDate !== undefined) {
        queryParam.from_date = item.selectedValues[0].value.fromDate
        queryParam.to_date = item.selectedValues[0].value.toDate
      }
      queryParam.duration = item.selectedValues[0].value.duration
    }
    if (item.filterValue === 'tcin') {
      queryParam.tcins = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'vendor') {
      queryParam.vendor_ids = item.selectedValues.map((filter) =>
        toNumber(filter.value)
      )
    }
    if (item.filterValue === 'approvalStatus') {
      queryParam.event_types = item.selectedValues.map((filter) => filter.value)
    }
  })

  if (data === undefined || data.length === 0) {
    return clearVideoReviewDataEvent()
  }

  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(dispatchRequestVideoReviewDataEvent(data))
    return axios
      .post(
        `${envConfigs.api.externalApi}group_search/search?page_number=${pageNumber}&page_size=${pageSize}&sort_direction=${sortDirection}&sort_field=${sortField}&key=${envConfigs.api.gatewayKey}`,
        queryParam
      )
      .then((res) => {
        dispatch(
          successRequestDataEvent(
            res.data.content.map((d) => {
              var item = {
                approvalStatus: d.approval_status,
                statusMessage: d.internal_user_status_message,
                statusColor: d.user_status_color,
                posterFrameUrl: d.poster_frame_url ? d.poster_frame_url : null,
                videoUrl:
                  d.map_child_assets === undefined ||
                  d.map_child_assets.video === undefined ||
                  d.map_child_assets.video.length === 0
                    ? ''
                    : d.map_child_assets.video[0].asset_url
                    ? d.map_child_assets.video[0].asset_url
                    : null,
                ccUrl:
                  d.map_child_assets === undefined ||
                  d.map_child_assets.closedcaption === undefined ||
                  d.map_child_assets.closedcaption.length === 0
                    ? ''
                    : d.map_child_assets.closedcaption[0].asset_url
                    ? d.map_child_assets.closedcaption[0].asset_url
                    : null,
                title: d.title,
                division:
                  d.lst_group_item_data &&
                  d.lst_group_item_data.map((item) => {
                    return item.division_name
                  }),
                applied_tcins_set: d?.applied_tcins_set?.sort((a, b) => a - b),
                lastModifiedTime: d.last_updated_time,
                lastModifiedBy: d.last_updated_by,
                uploadedTime: d.create_time,
                uploadedBy: d.created_by,
                external_group_job_id: d.external_group_job_id,
                active: d.active,
                errorMessage: d.error_message,
                listOfDuplicateTcin: d.list_of_duplicate_tcin,
                list_of_disabled_tcins: d?.list_of_disabled_tcins,
              }
              return item
            }),
            res.data.totalPages,
            res.data.totalElements
          )
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

function clearVideoReviewDataEvent() {
  return {
    type: CLEAR_VIDEO_REVIEW_DATA_EVENT,
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING_VIDEO_REVIEW,
    payload: payload,
  }
}

function dispatchRequestVideoReviewDataEvent(data) {
  var newVideoData = cloneDeep(data)
  newVideoData.splice(0, newVideoData.length)
  return {
    type: REQUEST_VIDEO_REVIEW_DATA,
    payload: {
      isFetching: true,
      videoData: newVideoData,
      selectedVideos: [],
    },
  }
}

function successRequestDataEvent(json, totalPages, totalElements) {
  return {
    type: RECEIVE_VIDEO_REVIEW_DATA,
    payload: {
      isFetching: false,
      videoData: json,
      totalPages: totalPages,
      totalElements: totalElements,
    },
  }
}

export function changeFilterEvent(data) {
  return {
    type: CHANGE_FILTER_EVENT_VIDEO_REVIEW,
    payload: {
      selectedFilter: data.selectedFilter,
    },
  }
}

export function selectFilterValueEvent(data) {
  return {
    type: SELECT_FILTER_VALUE_EVENT_VIDEO_REVIEW,
    payload: {
      filterValues: data.filterValues,
    },
  }
}

export function removeFilterHandler(
  data,
  currentPage,
  defaultPageSize,
  sortDirection,
  sortField
) {
  return (dispatch) => {
    var filter = dispatch(removeFilterEvent(data))
    dispatch(
      requestVideoReviewData(
        filter.payload.selectedFilters,
        currentPage,
        defaultPageSize,
        sortDirection,
        sortField
      )
    )
  }
}

export function removeFilterEvent(data) {
  var newFilterContainerShown = true
  var videoDataContainerShown = true
  var deepCopy = cloneDeep(data.selectedFilters)
  var currentFilter = deepCopy.filter(
    (v) => v.filterValue === data.mainFilterValue
  )[0]
  currentFilter.selectedValues = currentFilter.selectedValues.filter(
    (item) => !isEqual(item.value, data.filterValue)
  )
  deepCopy = deepCopy.filter((v) => v.selectedValues.length >= 1)
  if (currentFilter.selectedValues.length === 0 && deepCopy.length <= 0) {
    deepCopy = deepCopy.filter((v) => v.filterValue !== data.mainFilterValue)
    newFilterContainerShown = false
    videoDataContainerShown = false
  }
  return {
    type: REMOVE_FILTER_EVENT_VIDEO_REVIEW,
    payload: {
      newFilterContainerShown: newFilterContainerShown,
      videoDataContainerShown: videoDataContainerShown,
      selectedFilters: deepCopy,
    },
  }
}

export function handleSelectVideo(data) {
  return {
    type: SELECT_VIDEO_REVIEW_EVENT,
    payload: {
      selectedVideo: data.selectedVideo,
    },
  }
}

export function setSortField(value) {
  return {
    type: SET_SORT_FIELD_VIDEO_REVIEW,
    payload: value,
  }
}

export function setSortDirection(value) {
  return {
    type: SET_SORT_DIRECTION_VIDEO_REVIEW,
    payload: value,
  }
}

export function resetFetch(data) {
  return {
    type: VIDEO_REVIEW_SEARCH_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}
