import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DialogBoxDelete from './DialogBoxDelete'
import {
  deleteDataEvent,
  cancelDeleteEvent,
} from '../../../../../store/bulkUpload/tableActionCreator'
import { remove, isEqual } from 'lodash'

class DialogBoxDeleteContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  handleDelete = (event, data) => {
    let updateData = []
    let selectedData = []
    let defaultUploadData = []
    updateData = this.props.uploadData.map((item) => item)
    remove(updateData, function (item) {
      return isEqual(item, data)
    })
    selectedData = this.props.selectedData.map((item) => item)
    remove(selectedData, function (item) {
      return isEqual(item.tcin, data.tcin)
    })
    defaultUploadData = this.props.defaultUploadData.map((item) => item)
    remove(defaultUploadData, function (item) {
      return isEqual(item.tcin, data.tcin)
    })
    this.props.deleteDataEvent({
      uploadData: updateData,
      selectedData: selectedData,
      defaultUploadData: defaultUploadData,
    })
  }

  cancelDeleteConfirmation = (tcin) => {
    this.props.cancelDeleteEvent(tcin)
  }

  render() {
    return (
      <DialogBoxDelete
        data={this.props.data}
        handleDelete={this.handleDelete}
        cancelDeleteConfirmation={this.cancelDeleteConfirmation}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteDataEvent,
      cancelDeleteEvent,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { bulkUpload } = state
  const { uploadData, selectedData, defaultUploadData } = bulkUpload
  return {
    uploadData,
    selectedData,
    defaultUploadData,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogBoxDeleteContainer)
