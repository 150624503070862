import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Card, CircularProgress } from '@material-ui/core'
import UploadImageContainer from '../UploadImage/UploadImageContainer'

function UplodPage(props) {
  return (
    <React.Fragment>
      <Card className={props.classes.margin10}>
        <UploadImageContainer />
      </Card>
      {props.isFetchingOnDrop && (
        <CircularProgress className={props.classes.progressBar} />
      )}
    </React.Fragment>
  )
}
export default withStyles(styles)(UplodPage)
