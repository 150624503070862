import {
  ENTERED_TCIN,
  ENTERED_MTA_ID,
  PROD_SPECS_RESET_FETCH,
  STORE_TCIN_PROD_SPECS,
  STORE_TCIN_IMAGE_DATA,
  STORE_MTA_DATA,
  STORE_TCIN_MTA_DATA,
  CLEAR_MTA_DATA,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../components/Shared/Constants'

export function enterTcin(tcin) {
  return {
    type: ENTERED_TCIN,
    payload: tcin,
  }
}

export function enterMtaId(mtaId) {
  return {
    type: ENTERED_MTA_ID,
    payload: mtaId,
  }
}

export function resetFetch(data) {
  return {
    type: PROD_SPECS_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function storeTcinProdSpecs(payload) {
  return {
    type: STORE_TCIN_PROD_SPECS,
    payload: payload,
  }
}

export function searchTcinProdSpecs(tcin) {
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.api.pipelineContentApp}prodSpecs/${tcin}?key=${envConfigs.api.gatewayKey}`
      )
      .then((response) => {
        if (isEmpty(response.data)) {
          dispatch(resetFetch(false), toast.error('TCIN not found', TOAST_BODY))
        } else {
          dispatch(storeTcinProdSpecs(response.data))
          dispatch(searchTcinImageUrls(tcin))
        }
      })
      .catch((error) => {
        dispatch(
          resetFetch(false),
          toast.error(error.response.data.message, TOAST_BODY)
        )
      })
  }
}

export function storeTcinImageUrls(payload) {
  return {
    type: STORE_TCIN_IMAGE_DATA,
    payload: payload,
  }
}

export function searchTcinImageUrls(tcin) {
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.api.pipelineAssetApp}assets/v1/curation/${tcin}?key=${envConfigs.api.gatewayKey}`
      )
      .then((response) => {
        if (isEmpty(response.data)) {
          dispatch(resetFetch(false), toast.error('TCIN not found', TOAST_BODY))
        } else {
          dispatch(storeTcinImageUrls(response.data))
          dispatch(searchTcinMtaData(tcin))
        }
      })
      .catch((error) => {
        dispatch(
          resetFetch(false),
          toast.error(error.response.data.message, TOAST_BODY)
        )
      })
  }
}

export function storeTcinMtaData(payload) {
  return {
    type: STORE_TCIN_MTA_DATA,
    payload: payload,
  }
}

export function searchTcinMtaData(tcin) {
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.api.itemApi}digital_items/v1/lite/${tcin}?key=${envConfigs.api.gatewayKey}`
      )
      .then((response) => {
        dispatch(storeTcinMtaData(response.data))
      })
      .catch((error) => {
        dispatch(
          resetFetch(false),
          toast.error(error.response.data.message, TOAST_BODY)
        )
      })
  }
}

export function storeMtaData(payload) {
  return {
    type: STORE_MTA_DATA,
    payload: payload,
  }
}

export function searchMtaData(mta) {
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.api.pipelineContentApp}prodSpecs/subtemplate/mta/${mta}?key=${envConfigs.api.gatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data[0])
          ? dispatch(
              resetFetch(false),
              toast.error('Issue searching MTA, please try again', TOAST_BODY)
            )
          : dispatch(storeMtaData(response.data[0]))
      })
      .catch((error) => {
        dispatch(
          resetFetch(false),
          toast.error(error.response.data.message, TOAST_BODY)
        )
      })
  }
}

export function clearMtaData() {
  return {
    type: CLEAR_MTA_DATA,
  }
}
