import React from 'react'
import { Typography, Toolbar } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import CopyTcinAction from '../../Shared/Components/CopyTcinAction'

let ImageToolbar = (props) => {
  const {
    classes = {},
    numSelected = 0,
    selectedTcins = [],
    enabledActions = [],
    isActionDrawerOpen = false,
  } = props

  return (
    <React.Fragment>
      {numSelected > 0 && (
        <Toolbar
          className={
            !isActionDrawerOpen ? classes.toolBar : classes.toolBarDrawerOpen
          }
        >
          <div className={classes.title}>
            {numSelected > 0 && (
              <Typography color="inherit">{numSelected} selected</Typography>
            )}
          </div>
          {numSelected > 0 ? null : <div className={classes.spacer} />}
          <div className={classes.actions}>
            {numSelected > 0 && (
              <CopyTcinAction selectedTcins={selectedTcins} />
            )}
            {enabledActions.map((action, index) => (
              <span key={index}>{action}</span>
            ))}
          </div>
        </Toolbar>
      )}
    </React.Fragment>
  )
}

ImageToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

// @ts-ignore
export default withStyles(styles)(ImageToolbar)
