import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setTagStepperActiveStep,
  setTagsByType,
  saveNewTag,
  setTagFormType,
  setTagFormTypeDescription,
  setTagFormTypeDescriptionDisabledState,
  setTagFormTagLabel,
  setTagFormTagDescription,
  resetCreateTag,
  goToClassifierPage,
  populateClassifierDetails,
  getSimilarItems,
  setClassifierActiveStep,
  startOverSelectExampleImage,
} from '../../../../../../store/images/tagTraining/actionCreator'
import CreateNewTagForm from './CreateNewTagForm'
import { autofill } from 'redux-form'

class CreateNewTagFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {
    this.props.setTagsByType()
  }

  pickAnImage() {
    this.props.setTagStepperActiveStep(1)
  }

  handleSelectChange = (props, event) => {
    const { dispatch, tagDetailsForm, tagsByTypeMap } = props
    let type = tagDetailsForm.values.typeSelect
    if (type) {
      let typesList = Array.from(tagsByTypeMap.keys())
      if (typesList.includes(type)) {
        let typeData = tagsByTypeMap.get(type)
        let typeDescription = typeData.groupMetadata.description
        if (typeDescription) {
          dispatch(
            autofill('tagDetailsForm', 'typeDescription', typeDescription)
          )
          this.props.setTagFormTypeDescriptionDisabledState(true)
          this.updateStore('typeDescription', typeDescription)
        }
      }
    }
    this.updateStore(event.target.name, event.target.value)
  }

  saveNewTag() {
    const tagSaveRequest = {
      type: 'TAG',
      group: this.props.tagDetailsForm.values.typeSelect,
      name: this.props.tagDetailsForm.values.tagLabel,
      description: this.props.tagDetailsForm.values.tagDescription,
      example_asset_urls: [this.props.createNewTag.overall.tagExampleImageUrl],
      user_email: this.props.auth.email,
    }
    this.props.saveNewTag(tagSaveRequest)
  }

  updateStore = (name, value) => {
    switch (name) {
      case 'typeSelect':
        this.props.setTagFormType(value)
        break

      case 'typeDescription':
        this.props.setTagFormTypeDescription(value)
        break

      case 'tagDescription':
        this.props.setTagFormTagDescription(value)
        break

      case 'tagLabel':
        this.props.setTagFormTagLabel(value)
        break

      default:
        break
    }
  }

  finish() {
    this.props.resetCreateTag()
  }

  findMoreImages() {
    this.props.populateClassifierDetails()
    let emailId = this.props.auth.email
      ? this.props.auth.email
      : this.props.auth.lanId
    this.props.getSimilarItems(
      this.props.selectedImage,
      this.props.tagSelectedData,
      emailId
    )
    this.props.setClassifierActiveStep(3)
    this.props.resetCreateTag()
  }

  render() {
    const { tagDetailsForm, tagsByTypeMap } = this.props
    const columnHeaderData = [
      { id: 'tagImage', numeric: false, disablePadding: false, label: '' },
      {
        id: 'typeInfo',
        numeric: false,
        disablePadding: false,
        label: 'Type Info',
      },
      {
        id: 'tagInfo',
        numeric: false,
        disablePadding: false,
        label: 'Tag Info',
      },
      { id: 'actions', numeric: false, disablePadding: false, label: '' },
    ]

    return (
      <React.Fragment>
        {tagsByTypeMap && (
          <CreateNewTagForm
            columnHeaderData={columnHeaderData}
            pickAnImageBtnAction={() => this.pickAnImage()}
            tagDetailsForm={tagDetailsForm}
            createNewTag={this.props.createNewTag}
            tagsByTypeMap={this.props.tagsByTypeMap}
            saveNewTagBtnAction={() => this.saveNewTag()}
            handleSelectChange={(props, event) =>
              this.handleSelectChange(props, event)
            }
            updateStore={(event) =>
              this.updateStore(event.target.name, event.target.value)
            }
            findMoreImages={() => this.props.goToClassifierPage()}
            finish={() => this.finish()}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTagStepperActiveStep,
      setTagsByType,
      saveNewTag,
      setTagFormType,
      setTagFormTypeDescription,
      setTagFormTypeDescriptionDisabledState,
      setTagFormTagLabel,
      setTagFormTagDescription,
      resetCreateTag,
      populateClassifierDetails,
      goToClassifierPage,
      getSimilarItems,
      setClassifierActiveStep,
      startOverSelectExampleImage,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth, form } = state
  const { createNewTag, tagsByTypeMap } = imageTagTraining
  const { tagDetailsForm } = form
  return {
    auth,
    createNewTag,
    tagsByTypeMap,
    tagDetailsForm,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewTagFormContainer)
