import { withStyles } from '@material-ui/core/styles'
import styles from '../../../theme'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core'

function DialogBoxQuickPublish(props) {
  const {
    classes,
    quickEditPublishEventHandler,
    quickEditConfirm,
    cancelQuickPublish,
  } = props
  return (
    <Dialog
      open={quickEditConfirm}
      onClose={() => cancelQuickPublish()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'xs'}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid
              container
              direction="column"
              item
              xs={12}
              className={classes.marginBottom10}
            >
              <span className={classes.deleteCopyFeatureTitle}>
                Publish copy / bullets now?
              </span>
              <span className={classes.deleteCopyFeatureText}>
                Do you want to immediately publish the edits to copy and/or
                feature bullets for this item?
              </span>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => cancelQuickPublish()}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => quickEditPublishEventHandler()}
          variant="contained"
          color="primary"
        >
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withStyles(styles)(DialogBoxQuickPublish)
