import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  updateCurrentSelectedImage,
  updateCurrentImages,
  getClaimsData,
  subjectiveClaimsPopUp,
} from '../../../../../../store/longCopy/actionCreator'
import ImageViewer from './ImageViewer'

class ImageViewerContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {
    if (this.props.selectedItemData.claimsTcin) {
      this.props.getClaimsData(this.props.selectedItemData.claimsTcin)
    }
  }

  render() {
    return (
      <ImageViewer
        selectedItemData={this.props.selectedItemData}
        currentImage={this.props.currentImage}
        updateCurrentSelectedImage={this.props.updateCurrentSelectedImage}
        currentImagesSelected={this.props.currentImagesSelected}
        currentSwatch={this.props.currentSwatch}
        updateCurrentImages={this.props.updateCurrentImages}
        swatchData={this.props.swatchData}
        claimsData={this.props.claimsData}
        claimsError={this.props.claimsError}
        subjectiveClaimsPopUp={this.props.subjectiveClaimsPopUp}
        subjectiveClaimsPopUpData={this.props.subjectiveClaimsPopUpData}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCurrentSelectedImage,
      updateCurrentImages,
      getClaimsData,
      subjectiveClaimsPopUp,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { longCopy } = state
  const {
    selectedItemData,
    currentImage,
    currentImagesSelected,
    currentSwatch,
    swatchData,
    claimsData,
    claimsError,
    subjectiveClaimsPopUpData,
  } = longCopy
  return {
    selectedItemData,
    currentImage,
    currentImagesSelected,
    currentSwatch,
    swatchData,
    claimsData,
    claimsError,
    subjectiveClaimsPopUpData,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageViewerContainer)
