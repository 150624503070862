import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import { Card, CardContent } from '@material-ui/core'
import MDReactComponent from 'markdown-react-js'

function Alert(props) {
  const { alert, classes } = props

  return (
    <Card className={`${classes.marginTopBottom10} ${classes.alert}`}>
      <CardContent>
        {alert.title ? (
          <MDReactComponent
            className={`${classes.marginBottom10} ${classes.header}`}
            text={alert.title}
          />
        ) : null}
        {alert.body ? (
          <MDReactComponent className={classes.body} text={alert.body} />
        ) : null}
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(Alert)
