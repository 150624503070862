import React from 'react'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Button,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { connect } from 'react-redux'
import TagDataTableHeadContainer from '../../../Shared/Components/TagDataTableHeadContainer'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../../Shared/Constants'

const validate = (values, { tagsByTypeMap }) => {
  const errors = {}
  const requiredFields = [
    'typeSelect',
    'typeDescription',
    'tagLabel',
    'tagDescription',
  ]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      if (field === 'typeSelect') {
        errors[field] = true
      } else {
        errors[field] = 'Required'
      }
    }
  })

  let type = values['typeSelect']
  let tag = values['tagLabel']

  if (type && tag) {
    let tagsData = tagsByTypeMap.get(type)
    let tagsList = tagsData.tags
    if (tagsList) {
      if (tagsList.includes(tag)) {
        errors['tagLabel'] = `tag ${tag} already exists for type ${type}`
      }
    }
  }
  return errors
}

const renderTextField = ({
  label,
  input,
  value,
  multiline,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <div>
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      multiline={multiline}
      value={value}
      {...input}
      {...custom}
    />
  </div>
)

const renderSelectField = (props) => {
  const {
    input,
    label,
    inputProps,
    meta: { touched, error },
    children,
    ...custom
  } = props
  return (
    <FormControl error={touched && error}>
      <InputLabel htmlFor="select-native-simple">{label}</InputLabel>
      <Select {...input} {...custom} inputProps={inputProps}>
        {children}
      </Select>
    </FormControl>
  )
}

let CreateNewTagForm = (props) => {
  const {
    classes,
    columnHeaderData,
    createNewTag,
    pickAnImageBtnAction,
    reset,
    submitting,
    invalid,
    tagDetailsForm,
    tagsByTypeMap,
    saveNewTagBtnAction,
    handleSelectChange,
    updateStore,
    findMoreImages,
    finish,
  } = props

  const {
    activeStep,
    typeDescription,
    typeDescriptionDisabled,
    tagExampleImageUrl,
  } = createNewTag.overall

  return (
    <form>
      <Table>
        <TagDataTableHeadContainer columnHeaderData={columnHeaderData} />
        <TableBody>
          <TableRow>
            <TableCell key={'createTagImage'}>
              {tagExampleImageUrl && (
                <img
                  src={tagExampleImageUrl}
                  alt={'no thumbnail'}
                  width={150}
                />
              )}
            </TableCell>
            <TableCell key={'createTagTypeInfo'}>
              <Grid
                container
                direction={'row'}
                justify={'flex-start'}
                spacing={1}
              >
                <Grid item xs={12}>
                  <Field
                    className={classes.formControl}
                    onBlur={(event) => handleSelectChange(props, event)}
                    name="typeSelect"
                    component={renderSelectField}
                    label="select type"
                    props={{
                      name: 'typeSelect',
                      id: 'type-native-simple',
                    }}
                    disabled={activeStep > 0}
                  >
                    {tagsByTypeMap.size > 0 &&
                      Array.from(tagsByTypeMap.keys()).map((type) => {
                        return (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        )
                      })}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    className={classes.formControl}
                    component={renderTextField}
                    name="typeDescription"
                    value={typeDescription}
                    label="type description"
                    multiline
                    disabled={typeDescriptionDisabled}
                    onBlur={(event) => updateStore(event)}
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell key={'createTagTagInfo'}>
              <Grid
                container
                direction={'row'}
                justify={'flex-start'}
                spacing={1}
              >
                <Grid item xs={12}>
                  <Field
                    className={classes.formControl}
                    name="tagLabel"
                    component={renderTextField}
                    label="enter new tag name"
                    disabled={activeStep > 0}
                    onBlur={(event) => updateStore(event)}
                  />
                </Grid>
                <Grid item xs={12} className={classes.paddingBottom10}>
                  <Field
                    className={classes.formControl}
                    name="tagDescription"
                    component={renderTextField}
                    label="enter a description of the tag"
                    multiline
                    disabled={activeStep > 0}
                    onBlur={(event) => updateStore(event)}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid item xs={12} className={classes.buttons}>
        {activeStep !== 3 && (
          <React.Fragment>
            <Button
              disabled={
                submitting ||
                invalid ||
                activeStep > 0 ||
                (tagDetailsForm &&
                  (!tagDetailsForm.values.typeSelect ||
                    !tagDetailsForm.values.typeDescription ||
                    !tagDetailsForm.values.tagLabel.length ||
                    !tagDetailsForm.values.tagDescription))
              }
              variant="contained"
              color="primary"
              onClick={() => pickAnImageBtnAction()}
            >
              Pick an Image
            </Button>
            <Button
              disabled={
                submitting ||
                activeStep > 0 ||
                (tagDetailsForm &&
                  tagDetailsForm.values.typeSelect.length === 0 &&
                  tagDetailsForm.values.typeDescription.length === 0 &&
                  tagDetailsForm.values.tagLabel.length === 0 &&
                  tagDetailsForm.values.tagDescription.length === 0)
              }
              variant="outlined"
              color="primary"
              onClick={reset}
            >
              Reset
            </Button>
            <Button
              disabled={submitting || activeStep !== 2}
              variant="contained"
              color="primary"
              onClick={() => saveNewTagBtnAction()}
            >
              Save Tag
            </Button>
          </React.Fragment>
        )}
        {activeStep === 3 && (
          <div>
            <Typography>
              Would you like to find additional images for this tag?
            </Typography>
            <Link
              to={ROUTES.IMAGES.LABELS_CLASSIFIER}
              className={classes.linkStyle}
            >
              <Button
                disabled={submitting || activeStep !== 3}
                variant="outlined"
                onClick={() => findMoreImages()}
                color="primary"
              >
                Yes
              </Button>
            </Link>
            <Link to={ROUTES.IMAGES.LABELS} className={classes.linkStyle}>
              <Button
                disabled={submitting || activeStep !== 3}
                variant="outlined"
                color="primary"
                onClick={() => finish()}
              >
                No, Finish
              </Button>
            </Link>
          </div>
        )}
      </Grid>
    </form>
  )
}

CreateNewTagForm = reduxForm({ form: 'tagDetailsForm', validate: validate })(
  CreateNewTagForm
)
CreateNewTagForm = connect((state) => ({
  initialValues: {
    typeSelect: state.imageTagTraining.createNewTag.overall.typeSelect
      ? state.imageTagTraining.createNewTag.overall.typeSelect
      : '',
    typeDescription: state.imageTagTraining.createNewTag.overall.typeDescription
      ? state.imageTagTraining.createNewTag.overall.typeDescription
      : '',
    typeDescriptionDisabled: false,
    tagLabel: state.imageTagTraining.createNewTag.overall.tagLabel
      ? state.imageTagTraining.createNewTag.overall.tagLabel
      : '',
    tagDescription: state.imageTagTraining.createNewTag.overall.tagDescription
      ? state.imageTagTraining.createNewTag.overall.tagDescription
      : '',
    exampleImageUrl: '',
  },
}))(CreateNewTagForm)

export default withStyles(styles)(CreateNewTagForm)
