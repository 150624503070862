import {
  REQUEST_IMAGE_REVIEW_DATA,
  RECEIVE_IMAGE_REVIEW_DATA,
  CLEAR_REVIEW_DATA,
  SELECT_IMAGE_REVIEW_EVENT,
  TOGGLE_ACTION_DRAWER_IMAGE_REVIEW,
  SET_REJECTION_REASON,
  HANDLE_REJECTION_MESSAGE,
  SET_APPROVAL_REASON,
  IMAGE_REVIEW_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_REVIEW,
  ERROR_SEARCHING_IMAGE_REVIEW,
  STORE_IMAGE_REVIEW_STRAPI_DATA,
  RESET_ACTION_TOOLBAR,
  SET_LOOK_CLOSER_ITEM,
  RETOUCH_PROCESS_LOADER,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'
import { requestImageReviewHistoryData } from '../reviewHistory/actionCreator'

export function resetFetch(data) {
  return {
    type: IMAGE_REVIEW_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_IMAGE_REVIEW,
    payload: data,
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING_IMAGE_REVIEW,
    payload: payload,
  }
}

export function getImageReviewStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Image%20Review&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data)
          ? dispatch(showDefaultAlert(true))
          : dispatch(storeImageReviewStrapiData(response.data))
        dispatch(showDefaultAlert(false))
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function storeImageReviewStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const errorData = data.filter(
    (item) => item.interface_document_type === 'error'
  )
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  const error =
    errorData.length > 0
      ? {
          title: errorData[0].user_facing_title,
          body: errorData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_IMAGE_REVIEW_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
      error,
    },
  }
}

function requestImageReviewDataEvent() {
  return {
    type: REQUEST_IMAGE_REVIEW_DATA,
    payload: {
      isFetching: true,
      reviewData: [],
      selectedReviewImages: [],
    },
  }
}

function successRequestReviewDataEvent(json) {
  return {
    type: RECEIVE_IMAGE_REVIEW_DATA,
    payload: {
      isFetching: false,
      reviewData: json,
    },
  }
}

export function requestImageReviewData(
  data,
  pageNumber,
  pageSize,
  sortDirection,
  sortField
) {
  var queryParam = {
    tcins: data,
  }

  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(requestImageReviewDataEvent())
    return axios
      .post(
        `${envConfigs.api.externalApi}review/image/search?key=${envConfigs.api.gatewayKey}`,
        queryParam
      )
      .then((res) => {
        if (res.data) {
          dispatch(successRequestReviewDataEvent(res.data))
        } else {
          dispatch(errorSearching(true))
        }
      })
      .catch((error, _) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

export function clearReviewData() {
  return {
    type: CLEAR_REVIEW_DATA,
  }
}

function resetActionToolbar() {
  return {
    type: RESET_ACTION_TOOLBAR,
  }
}

export function handleSelectImage(newSelected) {
  return {
    type: SELECT_IMAGE_REVIEW_EVENT,
    payload: {
      selectedReviewImages: newSelected,
    },
  }
}

export function reviewImages(
  images,
  status,
  replace,
  rejectionReason,
  auth,
  reviewTcins,
  isReprocess
) {
  var queryParam = images.map((item) => {
    let imageList = item.image_list.map((image) => {
      return {
        ids_review: status,
        image_id: image.image_id,
        rejection_reason: rejectionReason,
        replace: replace,
        user_id: auth.lanId,
        user_name: auth.email,
        source: image.source,
        file_path: image.file_path,
      }
    })
    return {
      tcin: item.tcin,
      image_list: imageList,
    }
  })
  const url = isReprocess
    ? `${envConfigs.api.externalApi}review/image/reprocess`
    : `${envConfigs.api.externalApi}review/image?key=${envConfigs.api.gatewayKey}`

  return (dispatch) => {
    dispatch(retouchProcessLoader(true))
    return axios
      .post(url, queryParam)
      .then((res) => {
        dispatch(resetActionToolbar())
        dispatch(retouchProcessLoader(false))
        if (isReprocess) {
          dispatch(requestImageReviewHistoryData(reviewTcins))
        } else {
          dispatch(requestImageReviewData(reviewTcins))
        }
      })
      .catch((error, data) => {
        dispatch(toast.error(error.message, TOAST_BODY))
        dispatch(retouchProcessLoader(false))
      })
  }
}

export function toggleActionDrawer(isActionDrawerOpen, drawerAction) {
  return {
    type: TOGGLE_ACTION_DRAWER_IMAGE_REVIEW,
    payload: {
      isActionDrawerOpen: isActionDrawerOpen,
      drawerAction: drawerAction,
    },
  }
}

export function setRejectionReason(currentRejectionReason) {
  return {
    type: SET_REJECTION_REASON,
    payload: {
      currentRejectionReason: currentRejectionReason,
    },
  }
}

export function handleChangeRejectionMessage(rejectionMessage) {
  return {
    type: HANDLE_REJECTION_MESSAGE,
    payload: {
      rejectionMessage: rejectionMessage,
    },
  }
}

export function setApprovalOption(currentApprovalOption) {
  return {
    type: SET_APPROVAL_REASON,
    payload: {
      currentApprovalOption: currentApprovalOption,
    },
  }
}

export function setLookCloserItem(tcin) {
  return {
    type: SET_LOOK_CLOSER_ITEM,
    payload: {
      lookCloserItem: tcin,
    },
  }
}

function retouchProcessLoader(payload) {
  return {
    type: RETOUCH_PROCESS_LOADER,
    payload: payload,
  }
}
