import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ItemData from './ItemData'

class ItemDataContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <ItemData
        itemMtaData={this.props.itemMtaData.merchandiseTypeAtttributes}
        productClassification={this.props.itemMtaData.productClassification}
        merchandiseClassification={
          this.props.itemMtaData.merchandiseClassification
        }
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const mapStateToProps = (state) => {
  const { prodSpecs } = state
  const { itemMtaData } = prodSpecs
  return {
    itemMtaData,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemDataContainer)
