import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import styles from '../theme'
import { REVIEW_DRAWER_ACTION } from '../../../Shared/Constants'

const ApproveImageAction = (props) => {
  const {
    classes = {},
    toggleActionDrawer = () => {},
    disabled = false,
  } = props

  return (
    <Button
      disabled={disabled}
      className={classes.drawerButton}
      onClick={() => toggleActionDrawer(true, REVIEW_DRAWER_ACTION.APPROVE)}
    >
      <ThumbUpIcon />
      <span className={classes.marginLeft5}>Approve</span>
    </Button>
  )
}

// @ts-ignore
export default withStyles(styles)(ApproveImageAction)
