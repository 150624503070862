/* eslint-disable array-callback-return */
import {
  SET_IMAGE_URLS,
  SET_IMAGE_FETCHING,
  SET_MODELS,
  SET_TRAINING_IN_PROGRESS,
  IMAGE_TAG_TRAINING_RESET_FETCH,
  SET_TAG_GROUPS,
  SET_TAG_GROUPS_FILTER_TEXT,
  SET_FILTERED_TAG_GROUPS,
  RESET_EDITED_TAG,
  UPDATE_TAG_DESCRIPTION,
  UPDATE_TAG_EDIT_STATE,
  UPDATE_TAG_IS_EDITED,
  SET_TAG_STEPPER_OVERALL_ACTIVE_STEP,
  SET_TAG_SELECTION_ACTIVE_STEP,
  SET_TAGS_BY_TYPE_MAP,
  SET_CREATE_TAG_IMAGE_URL,
  SET_CREATE_NEW_TAG_SYNC_ERROR,
  SET_TAG_FORM_TYPE_DESCRIPTION,
  SET_TAG_FORM_TYPE_DESCRIPTION_DISABLED_STATE,
  INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT,
  ADD_IMAGE_TO_APPROVED_REVIEW_LIST,
  REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST,
  SET_TAG_FORM_TAG_DESCRIPTION,
  SET_TAG_FORM_TAG_LABEL,
  SET_TAG_FORM_TYPE,
  RESET_CREATE_TAG,
  EXAMPLE_IMAGE_SELECTED,
  REQUEST_EXAMPLE_TCIN_IMAGE,
  SET_EXAMPLE_IMAGE_LIST,
  SET_EXAMPLE_IMAGE_TCIN_LIST,
  SET_CLASSIFIER_ACTIVE_STEP,
  START_OVER_SELECT_EXAMPLE_IMAGE,
  REQUEST_CLASSIFIER_TCIN_IMAGE,
  SET_CLASSIFIER_IMAGE_LIST,
  SET_CLASSIFIER_IMAGE_TCIN_LIST,
  CLASSIFIER_IMAGE_SELECTED,
  TAG_DATA_SELECTION,
  REQUEST_SIMILAR_IMAGE_CLASSIFIER,
  RECEIVE_SIMILAR_IMAGE_CLASSIFIER,
  MAX_SIMILAR_IMAGE_COUNT_CLASSIFIER,
  RESET_CLASSIFIER_SELECTION,
  ADD_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER,
  INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT_CLASSIFIER,
  REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER,
  TOGGLE_SELECT_IMAGE_FROM_TRAINING_GRID,
  CLEAR_SELECTED_IMAGES_FROM_TRAINING_GRID,
  REMOVE_IMAGE_FROM_MODEL_GRID,
  SET_DELETE_CONFIRMATION_OPEN,
  POPULATE_CLASSIFIER_DETAILS,
  TOGGLE_CLASSIFIER_APPROVED_SELECT,
  REMOVE_IMAGE_FROM_CLASSIFIER_APPROVED_IMAGES,
  SET_DELETE_CONFIRMATION_OPEN_CLASSIFIER,
  CLEAR_SELECTED_IMAGES_FROM_CLASSIFIER_APPROVED_LIST,
  DELETING_FROM_CLASSIFIER_IMAGEGRID_STATUS,
  DELETING_FROM_TRAINING_IMAGEGRID_STATUS,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { each } from 'lodash'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'

export function resetFetch(data) {
  return {
    type: IMAGE_TAG_TRAINING_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

function setImageUrlsFetching(status) {
  return {
    type: SET_IMAGE_FETCHING,
    payload: {
      imageUrlsFetching: status,
    },
  }
}

export function selectImage(selectedImage) {
  return {
    type: EXAMPLE_IMAGE_SELECTED,
    payload: {
      selectedImage: selectedImage,
    },
  }
}

export function selectImageClassifier(selectedImage) {
  return {
    type: CLASSIFIER_IMAGE_SELECTED,
    payload: {
      selectedImage: selectedImage,
    },
  }
}

export function selectTrainingImage(image) {
  return {
    type: TOGGLE_SELECT_IMAGE_FROM_TRAINING_GRID,
    payload: {
      image: image,
    },
  }
}

export function goToClassifierPage() {
  return (dispatch, getState) => {
    dispatch(populateClassifierDetails())
    dispatch(setClassifierActiveStep(3))
    let emailId = getState().auth.email
      ? getState().auth.email
      : getState().auth.lanId
    dispatch(
      getSimilarItems(
        getState().imageTagTraining.classifierSelection.selectedImage,
        getState().imageTagTraining.classifierSelection.tagSelectedData,
        emailId
      )
    )
    dispatch(resetCreateTag())
  }
}

export function populateClassifierDetails() {
  return {
    type: POPULATE_CLASSIFIER_DETAILS,
  }
}

export function removeSelectedImageFromTrainingDeleteConfirmation(image) {
  return (dispatch, getState) => {
    dispatch(selectTrainingImage(image))
    if (
      getState().imageTagTraining.training.imageInfo.images.filter((image) => {
        return image.isSelected
      }).length === 0
    ) {
      dispatch(setDeleteConfirmationOpen(false))
    }
  }
}

export function removeSelectedImageFromClassifierDeleteConfirmation(image) {
  return (dispatch, getState) => {
    dispatch(toggleClassifierApprovedSelectedImage(image))
    if (
      getState().imageTagTraining.classifierSelection.currentApprovedImages.filter(
        (image) => {
          return image.isSelected
        }
      ).length === 0
    ) {
      dispatch(setDeleteConfirmationOpenClassifier(false))
    }
  }
}

export function setDeleteConfirmationOpen(open) {
  return {
    type: SET_DELETE_CONFIRMATION_OPEN,
    payload: {
      open: open,
    },
  }
}

export function setDeleteConfirmationOpenClassifier(open) {
  return {
    type: SET_DELETE_CONFIRMATION_OPEN_CLASSIFIER,
    payload: {
      open: open,
    },
  }
}

export function clearSelectedTrainingImages() {
  return {
    type: CLEAR_SELECTED_IMAGES_FROM_TRAINING_GRID,
  }
}

export function clearSelectedClassifierApprovedImages() {
  return {
    type: CLEAR_SELECTED_IMAGES_FROM_CLASSIFIER_APPROVED_LIST,
  }
}

export function setCreateNewTagSyncError(identifier, syncError) {
  return {
    type: SET_CREATE_NEW_TAG_SYNC_ERROR,
    payload: {
      identifier: identifier,
      syncError: syncError,
    },
  }
}

export function resetClassifierSelection() {
  return {
    type: RESET_CLASSIFIER_SELECTION,
  }
}

export function resetCreateTag() {
  return {
    type: RESET_CREATE_TAG,
  }
}

export function startOverSelectExampleImage() {
  return (dispatch) => {
    dispatch(resetSelectExampleImage())
    dispatch(setExampleImageSelectionActiveStep(0))
  }
}

export function resetSelectExampleImage() {
  return {
    type: START_OVER_SELECT_EXAMPLE_IMAGE,
  }
}

export function setTagFormType(typeSelect) {
  return {
    type: SET_TAG_FORM_TYPE,
    payload: {
      typeSelect: typeSelect,
    },
  }
}

export function setTagFormTypeDescription(typeDescription) {
  return {
    type: SET_TAG_FORM_TYPE_DESCRIPTION,
    payload: {
      typeDescription: typeDescription,
    },
  }
}

export function setTagFormTagLabel(tagLabel) {
  return {
    type: SET_TAG_FORM_TAG_LABEL,
    payload: {
      tagLabel: tagLabel,
    },
  }
}

export function setTagFormTagDescription(tagDescription) {
  return {
    type: SET_TAG_FORM_TAG_DESCRIPTION,
    payload: {
      tagDescription: tagDescription,
    },
  }
}

export function setTagFormTypeDescriptionDisabledState(disabled) {
  return {
    type: SET_TAG_FORM_TYPE_DESCRIPTION_DISABLED_STATE,
    payload: {
      disabled: disabled,
    },
  }
}

function setImageUrls(imageDetailsList, group, label) {
  return {
    type: SET_IMAGE_URLS,
    payload: {
      images: imageDetailsList,
      group: group,
      label: label,
    },
  }
}

export function setTagGroups(tagGroups) {
  return {
    type: SET_TAG_GROUPS,
    payload: {
      tagGroups: tagGroups,
    },
  }
}

function setTagGroupsFilterText(tagGroupsFilterText) {
  return {
    type: SET_TAG_GROUPS_FILTER_TEXT,
    payload: {
      tagGroupsFilterText: tagGroupsFilterText,
    },
  }
}

function setFilteredTagGroups(filteredTagGroups) {
  return {
    type: SET_FILTERED_TAG_GROUPS,
    payload: {
      filteredTagGroups: filteredTagGroups,
    },
  }
}

function setModels(models) {
  return {
    type: SET_MODELS,
    payload: {
      models: models,
    },
  }
}

function updateTagDesc(type, tag, tagDescription) {
  return {
    type: UPDATE_TAG_DESCRIPTION,
    payload: {
      type: type,
      tag: tag,
      tagDescription: tagDescription,
    },
  }
}

export function resetEditedTag(editedTagType, editedTagName) {
  return {
    type: RESET_EDITED_TAG,
    payload: {
      editedTagType: editedTagType,
      editedTagName: editedTagName,
    },
  }
}

export function changeTagEditState(tagType, tagName, isTagEditing) {
  return {
    type: UPDATE_TAG_EDIT_STATE,
    payload: {
      tagType: tagType,
      tagName: tagName,
      isTagEditing: isTagEditing,
    },
  }
}

export function changeTagIsEditedState(tagType, tagName, isTagEdited) {
  return {
    type: UPDATE_TAG_IS_EDITED,
    payload: {
      tagType: tagType,
      tagName: tagName,
      isTagEdited: isTagEdited,
    },
  }
}

export function updateTagDescription(type, tag, tagDescription) {
  return (dispatch) => {
    dispatch(updateTagDesc(type, tag, tagDescription))
    dispatch(changeTagEditState(type, tag, false))
  }
}

export function setTagStepperActiveStep(activeStep) {
  return {
    type: SET_TAG_STEPPER_OVERALL_ACTIVE_STEP,
    payload: {
      activeStep: activeStep,
    },
  }
}

export function setClassifierActiveStep(activeStep) {
  return {
    type: SET_CLASSIFIER_ACTIVE_STEP,
    payload: {
      activeStep: activeStep,
    },
  }
}

export function setExampleImageSelectionActiveStep(activeStep) {
  return {
    type: SET_TAG_SELECTION_ACTIVE_STEP,
    payload: {
      activeStep: activeStep,
    },
  }
}

export function selectedTagImageContinue(imageUrl) {
  return (dispatch) => {
    dispatch(setCreateNewTagImage(imageUrl))
    dispatch(setTagStepperActiveStep(2))
  }
}

export function tagDataSelection(data) {
  return {
    type: TAG_DATA_SELECTION,
    payload: {
      tagSelectedData: data,
    },
  }
}

function setCreateNewTagImage(imageUrl) {
  return {
    type: SET_CREATE_TAG_IMAGE_URL,
    payload: {
      imageUrl: imageUrl,
    },
  }
}

export function getImageDataForCreateNewTag(tcinData) {
  return (dispatch) => {
    dispatch(setExampleImageSelectionActiveStep(1))
    dispatch(setExampleImageListFetching(true))
    return axios
      .get(
        `${envConfigs.api.pipelineAssetApp}assets/v1/content/findByTcin?tcin=${tcinData}&key=${envConfigs.api.gatewayKey}`
      )
      .then((res) => {
        let imageData = []
        res.data.map((item) => {
          if (item.view_type.toLowerCase() !== 'swatch') {
            imageData.push(item)
          }
        })
        dispatch(setExampleImageListFetching(false))
        dispatch(setExampleImageList(imageData))
        dispatch(setExampleImageTcinList(tcinData))
      })
      .catch(() => {
        dispatch(
          resetFetch(false),
          toast.error('Service not availabe please try again later', TOAST_BODY)
        )
      })
  }
}

export function getImageDataForClassifier(tcinData) {
  return (dispatch) => {
    dispatch(setClassifierActiveStep(1))
    dispatch(setClassifierImageListFetching(true))
    return axios
      .get(
        `${envConfigs.api.pipelineAssetApp}assets/v1/content/findByTcin?tcin=${tcinData}&key=${envConfigs.api.gatewayKey}`
      )
      .then((res) => {
        let imageData = []
        res.data.map((item) => {
          if (item.view_type.toLowerCase() !== 'swatch') {
            imageData.push(item)
          }
        })
        dispatch(setClassifierImageListFetching(false))
        dispatch(setClassifierImageList(imageData))
        dispatch(setClassifierImageTcinList(tcinData))
      })
      .catch(() => {
        dispatch(
          resetFetch(false),
          toast.error('Service not availabe please try again later', TOAST_BODY)
        )
      })
  }
}

function setExampleImageListFetching(isImageListFetching) {
  return {
    type: REQUEST_EXAMPLE_TCIN_IMAGE,
    payload: {
      imageListFetching: isImageListFetching,
    },
  }
}

function setClassifierImageListFetching(isImageListFetching) {
  return {
    type: REQUEST_CLASSIFIER_TCIN_IMAGE,
    payload: {
      imageListFetching: isImageListFetching,
    },
  }
}

function setExampleImageList(imageData) {
  return {
    type: SET_EXAMPLE_IMAGE_LIST,
    payload: {
      imageList: imageData,
    },
  }
}

function setClassifierImageList(imageData) {
  return {
    type: SET_CLASSIFIER_IMAGE_LIST,
    payload: {
      imageList: imageData,
    },
  }
}

export function setExampleImageTcinList(tcinList) {
  return {
    type: SET_EXAMPLE_IMAGE_TCIN_LIST,
    payload: {
      tcinList: [tcinList],
    },
  }
}

export function setClassifierImageTcinList(tcinList) {
  return {
    type: SET_CLASSIFIER_IMAGE_TCIN_LIST,
    payload: {
      tcinList: [tcinList],
    },
  }
}

export function removeImageFromModelTraining(imageInfo, emailId) {
  let selectedImageUrls = imageInfo.images
    .filter((image) => image.isSelected)
    .map((image) => image.url)

  let tagRemoveRequestsList = []

  each(selectedImageUrls, (imageUrl) => {
    tagRemoveRequestsList.push({
      image_url: imageUrl,
      email: emailId,
      applied_tags: [
        {
          type: imageInfo.group,
          tag: imageInfo.label,
        },
      ],
    })
  })

  return (dispatch) => {
    dispatch(setIsDeletingFromTrainingImageGrid(true))
    axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/images/review/remove?key=${envConfigs.api.gatewayKey}`,
        tagRemoveRequestsList
      )
      .then(() => {
        let imageUrlsToRemove = tagRemoveRequestsList.map(
          (tagRemoveRequest) => {
            return tagRemoveRequest.image_url
          }
        )
        dispatch(
          removeImageFromCurrentlyDisplayedModelTrainingList(imageUrlsToRemove)
        )
        dispatch(setDeleteConfirmationOpen(false))
        dispatch(setIsDeletingFromTrainingImageGrid(false))
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

export function removeImageFromClassifierSelection(currentApprovedImages) {
  let selectedImages = currentApprovedImages.filter((image) => image.isSelected)

  let tagRemoveRequestsList = []

  each(selectedImages, (image) => {
    tagRemoveRequestsList.push({
      image_url: image.url,
      email: image.email,
      applied_tags: image.appliedTags,
    })
  })

  return (dispatch) => {
    dispatch(setIsDeletingFromClassifierImageGrid(true))
    axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/images/review/remove?key=${envConfigs.api.gatewayKey}`,
        tagRemoveRequestsList
      )
      .then(() => {
        let imageUrlsToRemove = tagRemoveRequestsList.map(
          (tagRemoveRequest) => {
            return tagRemoveRequest.image_url
          }
        )
        dispatch(removeImageFromClassifierApprovedImagesList(imageUrlsToRemove))
        dispatch(setDeleteConfirmationOpenClassifier(false))
        dispatch(setIsDeletingFromClassifierImageGrid(false))
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

function setIsDeletingFromClassifierImageGrid(status) {
  return {
    type: DELETING_FROM_CLASSIFIER_IMAGEGRID_STATUS,
    payload: {
      status: status,
    },
  }
}

function setIsDeletingFromTrainingImageGrid(status) {
  return {
    type: DELETING_FROM_TRAINING_IMAGEGRID_STATUS,
    payload: {
      status: status,
    },
  }
}

function removeImageFromCurrentlyDisplayedModelTrainingList(imageUrlsToRemove) {
  return {
    type: REMOVE_IMAGE_FROM_MODEL_GRID,
    payload: {
      imageUrlsToRemove: imageUrlsToRemove,
    },
  }
}

function removeImageFromClassifierApprovedImagesList(imageUrlsToRemove) {
  return {
    type: REMOVE_IMAGE_FROM_CLASSIFIER_APPROVED_IMAGES,
    payload: {
      imageUrlsToRemove: imageUrlsToRemove,
    },
  }
}

export function removeImageTag(imageTagInfo) {
  let tagRemoveRequest = {
    ...imageTagInfo,
    image_url: imageTagInfo.imageUrl,
  }
  return (dispatch) => {
    axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/images/review/remove?key=${envConfigs.api.gatewayKey}`,
        tagRemoveRequest
      )
      .then(() => {
        dispatch(removeImageFromApprovedReviewList(tagRemoveRequest))
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

export function removeImageTagClassifier(imageTagInfo) {
  let tagRemoveRequest = {
    ...imageTagInfo,
    image_url: imageTagInfo.url,
  }
  return (dispatch) => {
    axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/images/review/remove?key=${envConfigs.api.gatewayKey}`,
        tagRemoveRequest
      )
      .then(() => {
        dispatch(removeImageFromApprovedReviewListClassifier(tagRemoveRequest))
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

export function saveNewTag(tagSaveRequest) {
  return (dispatch) => {
    axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/tags/save_tag?key=${envConfigs.api.gatewayKey}`,
        tagSaveRequest
      )
      .then(() => {
        dispatch(setTagStepperActiveStep(3))
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

export function getSimilarItems(selectedImage, tagSelectedData, emailId) {
  return (dispatch) => {
    dispatch(requestSimilarItemClassifier())
    dispatch(setClassifierActiveStep(3))
    let tagReviewRequest = {
      publish_url: selectedImage.publish_url,
      user_email: emailId,
      tag_review_votes: [tagSelectedData],
    }
    axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/images/review?key=${envConfigs.api.gatewayKey}`,
        tagReviewRequest
      )
      .then(() => {
        axios
          .get(
            `${envConfigs.api.classificationApi}classify/v1/search/additional_images?emailId=${tagReviewRequest.user_email}&imageUrl=${tagReviewRequest.publish_url}&key=${envConfigs.api.gatewayKey}`
          )
          .then((res) => {
            dispatch(recieveSimilarItemClassifier(res.data))
            dispatch(maxSimilarCountClassifier(res.data.length))
            dispatch(addImageToApprovedReviewListClassifier(tagReviewRequest))
          })
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

export function saveTag(tagSaveRequest) {
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/tags/save_tag?key=${envConfigs.api.gatewayKey}`,
        tagSaveRequest
      )
      .then(() => {
        // do nothing
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

export function reviewImageTag(tagReviewRequest) {
  return (dispatch) => {
    axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/images/review?key=${envConfigs.api.gatewayKey}`,
        tagReviewRequest
      )
      .then(() => {
        dispatch(addImageToApprovedReviewList(tagReviewRequest))
        dispatch(incrementCurrentSimilarImageCount())
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

export function reviewImageTagClassifier(tagReviewRequest) {
  return (dispatch) => {
    axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/images/review?key=${envConfigs.api.gatewayKey}`,
        tagReviewRequest
      )
      .then(() => {
        dispatch(addImageToApprovedReviewListClassifier(tagReviewRequest))
        dispatch(incrementCurrentSimilarImageCountClassifier())
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

function addImageToApprovedReviewList(tagReviewRequest) {
  return {
    type: ADD_IMAGE_TO_APPROVED_REVIEW_LIST,
    payload: {
      imageTagInfo: {
        imageUrl: tagReviewRequest.publish_url,
        email: tagReviewRequest.user_email,
        applied_tags: [tagReviewRequest.tag_review_votes],
      },
    },
  }
}

function addImageToApprovedReviewListClassifier(tagReviewRequest) {
  return {
    type: ADD_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER,
    payload: {
      imageTagInfo: {
        url: tagReviewRequest.publish_url,
        email: tagReviewRequest.user_email,
        isSelected: false,
        appliedTags: tagReviewRequest.tag_review_votes,
      },
    },
  }
}

export function toggleClassifierApprovedSelectedImage(image) {
  return {
    type: TOGGLE_CLASSIFIER_APPROVED_SELECT,
    payload: {
      image: image,
    },
  }
}

function removeImageFromApprovedReviewList(imageTagInfo) {
  return {
    type: REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST,
    payload: {
      imageTagInfo: imageTagInfo,
    },
  }
}

function removeImageFromApprovedReviewListClassifier(imageTagInfo) {
  return {
    type: REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER,
    payload: {
      imageTagInfo: imageTagInfo,
    },
  }
}

export function incrementCurrentSimilarImageCount() {
  return {
    type: INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT,
  }
}

export function incrementCurrentSimilarImageCountClassifier() {
  return {
    type: INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT_CLASSIFIER,
  }
}

function requestSimilarItemClassifier() {
  return {
    type: REQUEST_SIMILAR_IMAGE_CLASSIFIER,
    payload: {
      similarImageFetching: true,
    },
  }
}

function recieveSimilarItemClassifier(data) {
  return {
    type: RECEIVE_SIMILAR_IMAGE_CLASSIFIER,
    payload: {
      similarImageFetching: false,
      similarImageData: data,
    },
  }
}

function maxSimilarCountClassifier(count) {
  return {
    type: MAX_SIMILAR_IMAGE_COUNT_CLASSIFIER,
    payload: {
      maxSimilarImageCount: count,
    },
  }
}

function setTagsByTypeMap(tagsByTypeMap) {
  return {
    type: SET_TAGS_BY_TYPE_MAP,
    payload: {
      tagsByTypeMap: tagsByTypeMap,
    },
  }
}

export function setTagsByType() {
  return (dispatch) => {
    axios
      .get(
        `${envConfigs.api.classificationApi}classify/v1/tags?key=${envConfigs.api.gatewayKey}`
      )
      .then((result) => {
        let tagsByTypeMap = createTagsByTypeMap(result.data)
        dispatch(setTagsByTypeMap(tagsByTypeMap))
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(
            `Error While Fetching Getting Tags Info: ${status}`,
            TOAST_BODY
          )
        )
      })
  }
}

function createTagsByTypeMap(tagGroups) {
  let tagsByTypeMap = new Map()
  tagGroups.map((group) => {
    let tagsList = group.tags.map((tag) => {
      return tag.name
    })
    let groupMetadata = group.group_metadata

    let mapValue = {
      tags: tagsList,
      groupMetadata: groupMetadata,
    }

    tagsByTypeMap.set(group.group, mapValue)
  })
  return tagsByTypeMap
}

export function saveTagUpdate(tagType, tagName, tagDescription) {
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/tags/update_tag_metadata?type=${tagType}&tag=${tagName}&description=${tagDescription}&key=${envConfigs.api.gatewayKey}`
      )
      .then((res) => {
        dispatch(changeTagEditState(tagType, tagName, false))
        dispatch(changeTagIsEditedState(tagType, tagName, false))
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Saving Tag: ${status}`, TOAST_BODY)
        )
      })
  }
}

export function getAllTagsByGroup() {
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.api.classificationApi}classify/v1/tags?key=${envConfigs.api.gatewayKey}`
      )
      .then((res) => {
        dispatch(setTagGroups(res.data))
      })
      .catch((error) => {
        let status = error.response ? error.response.status : 'Unknown Error'
        dispatch(
          resetFetch(false),
          toast.error(`Error While Fetching Tags: ${status}`, TOAST_BODY)
        )
      })
  }
}

export function filterTagGroups(filterText, tagGroups) {
  return (dispatch) => {
    let filteredTagGroups = new Set()

    if (filterText.length === 0) {
      filteredTagGroups = [...tagGroups]
    } else {
      let filters = filterText.split(' ')
      filters.forEach((filter) => {
        // check group names. If group name matches, add entire group and return
        tagGroups.forEach((group) => {
          if (group.group.indexOf(filter) >= 0) {
            filteredTagGroups.add(group)
            return
          }
          // check tags. for each individual tag that matches, store in list
          let matchingTags = []
          group.tags.forEach((tag) => {
            if (tag.name.indexOf(filter) >= 0) {
              matchingTags.push(tag)
            }
          }, this)
          // if there are matching tags for this group, recreate group object with only the needed tags and add
          if (matchingTags.length > 0) {
            let match = {
              group: group.group,
              group_metadata: group.group_metadata,
              tags: matchingTags,
            }
            filteredTagGroups.add(match)
          }
        })
      })
    }
    dispatch(setFilteredTagGroups([...filteredTagGroups]))
    dispatch(setTagGroupsFilterText(filterText))
  }
}

export function getImageUrlsForModelLabel(model, label) {
  return (dispatch) => {
    dispatch(setImageUrlsFetching(true))
    return axios
      .get(
        `${envConfigs.api.classificationApi}classify/v1/search/image_list?model=${model}&label=${label}&key=${envConfigs.api.gatewayKey}`
      )
      .then((res) => {
        let imageDetailsList = res.data.map((url) => {
          return {
            url: url,
            isSelected: false,
          }
        })
        dispatch(setImageUrls(imageDetailsList, model, label))
        dispatch(setImageUrlsFetching(false))
      })
      .catch((error) => {
        dispatch(
          resetFetch(false),
          toast.error(
            `Error While Fetching Images: ${error.response.status}`,
            TOAST_BODY
          )
        )
        dispatch(setImageUrlsFetching(false))
      })
  }
}

function parseModels(modelsFromApi) {
  let modelsList = []
  modelsFromApi.forEach(function (model) {
    let labels = []
    model.labels.forEach(function (l) {
      labels.push({
        label: l.label,
        isEligibleForTraining: l.eligible_for_training,
      })
    })

    modelsList.push({
      type: model.model,
      id: model.id,
      isEligibleForTraining: model.eligible_for_training,
      isTrainingInProgress: model.training_in_progress,
      labels: labels,
    })
  })
  return modelsList
}

export function getModelsListFromApi() {
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.api.classificationApi}classify/v1/search/models?key=${envConfigs.api.gatewayKey}`
      )
      .then((res) => {
        let modelsList = parseModels(res.data)
        dispatch(setModels(modelsList))
      })
      .catch(() => {
        dispatch(
          resetFetch(false),
          toast.error('Error Getting Tags For Review', TOAST_BODY)
        )
      })
  }
}

export function initiateModelTraining(id, userEmail) {
  return (dispatch) => {
    dispatch(setTrainingRequesting(true))
    dispatch(
      resetFetch(false),
      toast.info('Initiating Model Training Request', TOAST_BODY)
    )
    return axios
      .post(
        `${envConfigs.api.classificationApi}classify/v1/training/initiate_training?classifyTrainingDataId=${id}&emailId=${userEmail}&key=${envConfigs.api.gatewayKey}`
      )
      .then(() => {
        dispatch(getModelsListFromApi())
        dispatch(
          resetFetch(false),
          toast.success('Model Training Request Complete', TOAST_BODY)
        )
      })
      .catch(() => {
        dispatch(
          resetFetch(false),
          toast.error('Error While Initiating Model Training', TOAST_BODY)
        )
      })
  }
}

function setTrainingRequesting(status) {
  return {
    type: SET_TRAINING_IN_PROGRESS,
    payload: {
      isTrainingInProgress: status,
    },
  }
}
