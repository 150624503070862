import React from 'react'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import HistoryPageContainer from './Components/HistoryPageContainer'

function VideoHistory(props) {
  return (
    <React.Fragment>
      <HeaderTitle title="Video History" />
      <Helmet title="Video History" />
      <HistoryPageContainer />
    </React.Fragment>
  )
}

export default VideoHistory
