import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MtaDetails from './MtaDetails'
import { clearMtaData } from '../../../../store/prodSpecs/actionCreator'

class MtaDetailsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <MtaDetails
        mtaData={this.props.mtaData}
        clearMtaData={this.props.clearMtaData}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clearMtaData }, dispatch)

const mapStateToProps = (state) => {
  const { prodSpecs } = state
  const { mtaData } = prodSpecs
  return {
    mtaData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MtaDetailsContainer)
