export const NEW_FILTER_EVENT_BULK_UPLOAD_REPORT =
  'NEW_FILTER_EVENT_BULK_UPLOAD_REPORT'
export const BUILD_FILTER_EVENT_BULK_UPLOAD_REPORT =
  'BUILD_FILTER_EVENT_BULK_UPLOAD_REPORT'
export const REQUEST_BULK_UPLOAD_REPORT_DATA = 'REQUEST_BULK_UPLOAD_REPORT_DATA'
export const RECEIVE_BULK_UPLOAD_REPORT_DATA = 'RECEIVE_BULK_UPLOAD_REPORT_DATA'
export const BULK_UPLOAD_REPORT_RESET_FETCH = 'BULK_UPLOAD_REPORT_RESET_FETCH'
export const CHANGE_FILTER_EVENT_BULK_UPLOAD_REPORT =
  'CHANGE_FILTER_EVENT_BULK_UPLOAD_REPORT'
export const SELECT_FILTER_VALUE_EVENT_BULK_UPLOAD_REPORT =
  'SELECT_FILTER_VALUE_EVENT_BULK_UPLOAD_REPORT'
export const CHANGE_DEFAULT_PAGE_SIZE_BULK_UPLOAD_REPORT =
  'CHANGE_DEFAULT_PAGE_SIZE_BULK_UPLOAD_REPORT'
export const CHANGE_CURRENT_PAGE_BULK_UPLOAD_REPORT =
  'CHANGE_CURRENT_PAGE_BULK_UPLOAD_REPORT'
export const SELECT_BULK_UPLOAD_REPORT_EVENT = 'SELECT_BULK_UPLOAD_REPORT_EVENT'
export const REMOVE_FILTER_EVENT_BULK_UPLOAD_REPORT =
  'REMOVE_FILTER_EVENT_BULK_UPLOAD_REPORT'
export const CLEAR_BULK_UPLOAD_REPORT_DATA_EVENT =
  'CLEAR_BULK_UPLOAD_REPORT_DATA_EVENT'
export const DOWNLOAD_START_BULK_UPLOAD_REPORT =
  'DOWNLOAD_START_BULK_UPLOAD_REPORT'
export const DOWNLOAD_FINISH_BULK_UPLOAD_REPORT =
  'DOWNLOAD_FINISH_BULK_UPLOAD_REPORT'
export const BUILD_FILTER_EXPAND_BULK_UPLOAD_REPORT =
  'BUILD_FILTER_EXPAND_BULK_UPLOAD_REPORT'
export const SHOW_DEFAULT_ALERT_BULK_UPLOAD_REPORT =
  'SHOW_DEFAULT_ALERT_BULK_UPLOAD_REPORT'
export const STORE_BULK_UPLOAD_REPORT_STRAPI_DATA =
  'STORE_BULK_UPLOAD_REPORT_STRAPI_DATA'
export const ERROR_SEARCHING_BULK_UPLOAD_REPORT =
  'ERROR_SEARCHING_BULK_UPLOAD_REPORT'
