import { TableHead, TableRow, TableCell, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'

function BadgesTableContainerHead(props) {
  const {
    onSelectAllClick = () => {},
    numSelected = 0,
    totalBadges = 0,
    classes = {},
  } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < totalBadges}
            checked={numSelected === totalBadges && totalBadges > 0}
            onChange={onSelectAllClick}
            color="primary"
          />
        </TableCell>
        <TableCell>Edit</TableCell>
        <TableCell>Rank</TableCell>
        <TableCell>Badge Name</TableCell>
        <TableCell className={classes.noWrap}>Value ID</TableCell>
        <TableCell>MTA Value</TableCell>
        <TableCell>Icon</TableCell>
        <TableCell>MTA Name</TableCell>
        <TableCell>MTA ID</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Brand</TableCell>
        <TableCell>Aprox. Item Count</TableCell>
      </TableRow>
    </TableHead>
  )
}

// @ts-ignore
export default withStyles(styles)(BadgesTableContainerHead)
