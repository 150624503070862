/* eslint-disable no-empty-pattern */
/* eslint-disable no-constant-condition */
import { TextField, Tooltip } from '@material-ui/core'
import styles from '../theme'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'

function escapeHtml(input) {
  return { __html: input }
}

function EditAltText(props) {
  const {
    isAltTextEditable,
    changeToEditState,
    alttext,
    altTextUniqNo,
    convertAltText,
    updateEditLongCopy,
    convertAltTextField,
    classes,
    tcin,
  } = props
  let AltTextDefaultValue = convertAltTextField(alttext)
  return (
    <div>
      {isAltTextEditable ? (
        <TextField
          multiline
          rows="5"
          fullWidth
          margin="normal"
          defaultValue={AltTextDefaultValue}
          autoFocus
          onBlur={(event) => updateEditLongCopy(event, altTextUniqNo, tcin)}
        />
      ) : (
        <div className={classes.editAltTextWrapper}>
          <Tooltip title="Click to Edit">
            <div
              className={classes.clickToEditContainer}
              onClick={() => changeToEditState(altTextUniqNo)}
              role="presentation"
              dangerouslySetInnerHTML={escapeHtml(convertAltText(alttext))}
            />
          </Tooltip>
          <Tooltip title="Click to Edit">
            <EditIcon
              onClick={() => changeToEditState(altTextUniqNo)}
              className={classes.editAlign}
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(EditAltText)
