/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import styles from './theme'
import { withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ImageToolbar from '../Shared/Components/ImageToolbar'
import ApproveImageAction from '../Shared/Components/ApproveImageAction'
import SendToRetouchImageAction from '../Shared/Components/SendToRetouchImageAction'
import RejectImageAction from '../Shared/Components/RejectImageAction'
import ImageToolbarActionDrawerContainer from '../Shared/Components/ImageToolbarActionDrawer/ImageToolbarActionDrawerContainer'
import {
  Button,
  Paper,
  Grid,
  Toolbar,
  CircularProgress,
  Typography,
  Tooltip,
} from '@material-ui/core'
import {
  clearReviewData,
  handleSelectImage,
  toggleActionDrawer,
  getImageReviewStrapiData,
} from '../../../store/images/review/actionCreator'
import { isNil, uniq, indexOf, first, some, sortBy, isEmpty } from 'lodash'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { ROUTES } from '../../Shared/Constants'
import { escapeHtml } from '../../Shared/SharedUtils'
import { withRouter } from '../../Shared/WithRouterHoc'

function LookCloser(props) {
  const {
    isFetching = false,
    classes = {},
    reviewData = [],
    selectedReviewImages = [],
    lookCloserItem,
  } = props

  useEffect(() => {
    if (some(reviewData) && isEmpty(selectedReviewImages)) {
      const initialCurrentIndex = reviewData.find(
        (r) => r.tcin === lookCloserItem
      )
      if (!isNil(initialCurrentIndex)) {
        const initialSortedImageList = sortBy(initialCurrentIndex.image_list, [
          (item) => item.requested_position,
        ])
        props.handleSelectImage([
          {
            tcin: lookCloserItem,
            image: initialSortedImageList[0],
          },
        ])
      }
    }
  }, [selectedReviewImages])

  const toggleActionDrawer = (isActionDrawerOpen, drawerAction) => {
    props.toggleActionDrawer(isActionDrawerOpen, drawerAction)
  }

  const backToReview = () => {
    props.handleSelectImage([])
    // re-run selected filter
    // props.history.push(ROUTES.IMAGES.REVIEW)
    props.router.navigate({ pathname: ROUTES.IMAGES.REVIEW })
  }

  const backToSearch = () => {
    props.clearReviewData()
    // re-run selected filter
    // props.history.push(ROUTES.IMAGES.SEARCH)
    props.router.navigate({ pathname: ROUTES.IMAGES.SEARCH })
  }

  const lookCloserData = reviewData.find((r) => r.tcin === lookCloserItem)
  let numSelected = selectedReviewImages.length
  let selectedTcins = uniq(selectedReviewImages.map((item) => item.tcin))

  let currentIndex
  let sortedImageList
  if (some(selectedReviewImages)) {
    sortedImageList = sortBy(lookCloserData.image_list, [
      (item) => item.requested_position,
    ])
    currentIndex = !isNil(lookCloserData)
      ? indexOf(
          sortedImageList.map((r) => r.image_id),
          first(selectedReviewImages).image.image_id
        )
      : null
  }

  return (
    <React.Fragment>
      <HeaderTitle title="Image Review History" />
      <Helmet title="Image Review History" />
      {!isNil(lookCloserItem) && !isNil(currentIndex) && currentIndex !== -1 ? (
        <React.Fragment>
          <Toolbar>
            <Grid container direction="column">
              <Grid justify="space-between" container alignItems="center">
                <div className={classes.title}>
                  <Typography
                    variant="subtitle1"
                    className={classes.totalElementsTitle}
                    id="tableTitle"
                  >
                    {currentIndex + 1} of {sortedImageList.length} needing
                    review
                  </Typography>
                </div>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => backToReview()}
                  className={classes.marginRight20}
                >
                  Back To Review Results
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          <Paper className={classes.width100}>
            {isFetching && (
              <Grid className={classes.progressBar}>
                <CircularProgress />
              </Grid>
            )}
            {isNil(lookCloserData) && !isFetching && (
              <div className={classes.noResult}>
                No Review Data Found for Item
              </div>
            )}
            {!isNil(lookCloserData) && !isFetching && (
              <Grid className={classes.leftRightPadding10} container item>
                <Grid container className={classes.leftRightPadding10}>
                  <Typography variant="subtitle1">
                    {`${lookCloserData.tcin} - `}
                  </Typography>
                  <Typography
                    className={classes.paddingLeft3}
                    variant="subtitle1"
                    dangerouslySetInnerHTML={escapeHtml(
                      !isNil(lookCloserData.item_info.title)
                        ? lookCloserData.item_info.title
                        : 'No Title Found'
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  className={`${classes.leftRightPadding10} ${classes.newImageParentContainer}`}
                >
                  <Grid container item justify="center">
                    <img
                      data-test-id="new-image"
                      alt={sortedImageList[currentIndex].image_id}
                      src={`${sortedImageList[currentIndex].publish_url}`}
                      className={`${classes.newImage} ${classes.newImageContainer}`}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  sm={6}
                  item
                  className={`${classes.leftRightPadding10} ${classes.spaceBetween}`}
                >
                  <Grid container item direction="row" justify="space-between">
                    <Grid
                      data-test-id="image-position"
                      className={classes.flex}
                      item
                      container
                      direction="column"
                    >
                      <Grid container>
                        <Typography className={classes.leftRightPadding10}>
                          {`<- New`}
                        </Typography>
                        <Typography className={classes.leftRightPadding10}>
                          {`Existing ->`}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="subtitle2">
                          Position:{' '}
                          {sortedImageList[currentIndex].requested_position ===
                          0
                            ? 'Primary'
                            : `Alt ${sortedImageList[currentIndex].requested_position}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      className={classes.existingImageLookCloser}
                    >
                      {!isNil(
                        sortedImageList[currentIndex].existing_publish_url
                      ) ? (
                        <img
                          data-test-id="existing-image"
                          alt={sortedImageList[currentIndex].image_id}
                          src={`${sortedImageList[currentIndex].existing_publish_url}`}
                          className={classes.maxWidth100Per}
                        />
                      ) : (
                        <div
                          data-test-id="existing-image"
                          className={classes.lookCloserNoExistingImage}
                        >
                          No Existing Image
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    className={`${classes.existingImagesContainer}`}
                  >
                    <Typography variant="h6">Existing Images:</Typography>
                    <Grid item sm={12} container>
                      <Grid item sm={3}>
                        <Typography>Primary:</Typography>
                        {!isNil(
                          lookCloserData.live_image_list.find(
                            (r) => r.view_type === 'primary'
                          )
                        ) ? (
                          <img
                            data-test-id="existing-primary"
                            alt={
                              lookCloserData.live_image_list.find(
                                (r) => r.view_type === 'primary'
                              ).image_associations_mongo_id
                            }
                            src={`${
                              lookCloserData.live_image_list.find(
                                (r) => r.view_type === 'primary'
                              ).publish_url
                            }?make_square=true&hei=150&wei=150`}
                          />
                        ) : (
                          <div>No Primary</div>
                        )}
                      </Grid>
                      <Grid item sm={9}>
                        <Typography>Alternates:</Typography>
                        <Grid container className={classes.alternates}>
                          {lookCloserData.live_image_list
                            .filter((r) => r.view_type !== 'primary')
                            .map((liveImage) => {
                              return (
                                <img
                                  data-test-id="existing-alternates"
                                  className={classes.existingImages}
                                  key={liveImage.image_associations_mongo_id}
                                  alt={liveImage.image_associations_mongo_id}
                                  src={`${liveImage.publish_url}?make_square=true&hei=150&wei=150`}
                                />
                              )
                            })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Toolbar>
                    <Button
                      onClick={() =>
                        props.handleSelectImage([
                          {
                            tcin: lookCloserItem,
                            image: sortedImageList[currentIndex - 1],
                          },
                        ])
                      }
                      disabled={currentIndex === 0}
                    >
                      <Tooltip title="Previous Image">
                        <ArrowBackIosIcon
                          className={classes.imageNavigationIcon}
                        />
                      </Tooltip>
                    </Button>
                    <Button
                      onClick={() =>
                        props.handleSelectImage([
                          {
                            tcin: lookCloserItem,
                            image: sortedImageList[currentIndex + 1],
                          },
                        ])
                      }
                      disabled={
                        currentIndex === lookCloserData.image_list.length - 1
                      }
                    >
                      <Tooltip title="Next Image">
                        <ArrowForwardIosIcon
                          className={classes.imageNavigationIcon}
                        />
                      </Tooltip>
                    </Button>
                  </Toolbar>
                </Grid>
                <Grid container item className={classes.height64} />
              </Grid>
            )}
            <ImageToolbar
              numSelected={numSelected}
              selectedTcins={selectedTcins}
              enabledActions={[
                <ApproveImageAction toggleActionDrawer={toggleActionDrawer} />,
                <SendToRetouchImageAction
                  toggleActionDrawer={toggleActionDrawer}
                />,
                <RejectImageAction toggleActionDrawer={toggleActionDrawer} />,
              ]}
            />
            <ImageToolbarActionDrawerContainer
              numSelected={numSelected}
              selectedTcins={selectedTcins}
            />
          </Paper>
        </React.Fragment>
      ) : (
        <Paper className={classes.width100}>
          <Grid
            container
            item
            className={classes.noResult}
            justify="space-around"
            alignItems="center"
          >
            <div>No images Ready for Review were found</div>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => backToSearch()}
              className={classes.actions}
            >
              BACK TO SEARCH
            </Button>
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearReviewData,
      handleSelectImage,
      toggleActionDrawer,
      getImageReviewStrapiData,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageReview } = state
  const { reviewData, selectedReviewImages, isFetching, lookCloserItem } =
    imageReview
  return {
    reviewData,
    selectedReviewImages,
    isFetching,
    lookCloserItem,
  }
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(LookCloser)))
