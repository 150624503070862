export const IMAGE_MANAGE_RESET_FETCH = 'IMAGE_MANAGE_RESET_FETCH'
export const SHOW_DEFAULT_ALERT_IMAGE_MANAGE = 'SHOW_DEFAULT_ALERT_IMAGE_MANAGE'
export const STORE_IMAGE_MANAGE_STRAPI_DATA = 'STORE_IMAGE_MANAGE_STRAPI_DATA'
export const BUILD_FILTER_EVENT_IMAGE_MANAGE = 'BUILD_FILTER_EVENT_IMAGE_MANAGE'
export const CLEAR_IMAGE_MANAGE_DATA_EVENT = 'CLEAR_IMAGE_MANAGE_DATA_EVENT'
export const ERROR_SEARCHING_IMAGE_MANAGE = 'ERROR_SEARCHING_IMAGE_MANAGE'
export const REQUEST_IMAGE_MANAGE_DATA = 'REQUEST_IMAGE_MANAGE_DATA'
export const RECEIVE_IMAGE_MANAGE_DATA = 'RECEIVE_IMAGE_MANAGE_DATA'
export const CHANGE_CURRENT_PAGE_IMAGE_MANAGE =
  'CHANGE_CURRENT_PAGE_IMAGE_MANAGE'
export const CHANGE_DEFAULT_PAGE_SIZE_IMAGE_MANAGE =
  'CHANGE_DEFAULT_PAGE_SIZE_IMAGE_MANAGE'
export const SELECT_IMAGE_MANAGE_EVENT = 'SELECT_IMAGE_MANAGE_EVENT'
export const SET_SORT_FIELD_IMAGE_MANAGE = 'SET_SORT_FIELD_IMAGE_MANAGE'
export const SET_SORT_DIRECTION_IMAGE_MANAGE = 'SET_SORT_DIRECTION_IMAGE_MANAGE'
export const UPDATE_IMAGE_DATA_MANAGE = 'UPDATE_IMAGE_DATA_MANAGE'
export const RESET_IMAGE_DATA_MANAGE = 'RESET_IMAGE_DATA_MANAGE'
export const PUBLISH_IMAGE_DATA_MANAGE = 'PUBLISH_IMAGE_DATA_MANAGE'
export const START_PUBLISH_IMAGE_DATA_MANAGE = 'START_PUBLISH_IMAGE_DATA_MANAGE'
export const SET_COPIED_IMAGE = 'SET_COPIED_IMAGE'
export const TOGGLE_RELATED_ITEMS_MODAL = 'TOGGLE_RELATED_ITEMS_MODAL'
export const STORE_RELATED_ITEMS_DATA = 'STORE_RELATED_ITEMS_DATA'
export const SUCCESS_SEARCH_FOR_ITEM_IMAGES = 'SUCCESS_SEARCH_FOR_ITEM_IMAGES'
export const TOGGLE_SYNC_ITEMS_MODAL = 'TOGGLE_SYNC_ITEMS_MODAL'
export const TOGGLE_EDIT_SWATCH_MODAL = 'TOGGLE_EDIT_SWATCH_MODAL'
export const TOGGLE_EDIT_IMAGE_LABEL_DIALOGUE =
  'TOGGLE_EDIT_IMAGE_LABEL_DIALOGUE'
export const SAVED_LABEL_VALUE_LIST = 'SAVED_LABEL_VALUE_LIST'
