import React from 'react'
import TagsPage from './TagsPage'
import { connect } from 'react-redux'

class TagsPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    const { imageUrlsFetching, imageUrlInfo } = this.props
    return (
      <TagsPage
        imageUrlsFetching={imageUrlsFetching}
        imageUrlInfo={imageUrlInfo}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { imageUrlsFetching, imageUrlInfo } = imageTagTraining
  return {
    auth,
    imageUrlsFetching,
    imageUrlInfo,
  }
}

export default connect(mapStateToProps, null)(TagsPageContainer)
