import { TableHead, TableRow, TableCell } from '@material-ui/core'

function HistoryPageContainerHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Image</TableCell>
        <TableCell>TCIN</TableCell>
        <TableCell>FileName</TableCell>
        <TableCell>Upload Date</TableCell>
        <TableCell>Uploaded By</TableCell>
        <TableCell>Status</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default HistoryPageContainerHead
