const FilterData = [
  // {
  //   display: 'Date Range',
  //   value: 'dateRange',
  // },
  {
    display: 'Email(s)',
    value: 'emails',
  },
  {
    display: 'TCIN(s)',
    value: 'tcin',
  },
]

const Timespans = [
  {
    display: 'Today',
    value: 'today',
  },
  {
    display: 'Past 3 Days',
    value: 'past3Days',
  },
  {
    display: 'Past 7 Days',
    value: 'past7Days',
  },
  {
    display: 'Past 14 Days',
    value: 'past14Days',
  },
  {
    display: 'Past 30 Days',
    value: 'past30Days',
  },
  {
    display: 'Date Picker',
    value: 'datePicker',
  },
]

export { FilterData, Timespans }
