import {
  REQUEST_BADGES,
  RECEIVE_BADGES,
  UPDATE_BADGE_EDIT_STATE,
  CHANGE_BADGE,
  BADGES_VALUE_PERCENT,
  BADGES_CLEAR_UPLOAD,
  BADGES_UPLOAD_IN_PROGRESS_START,
  BADGES_DROP_ZONE_ACTIVE,
  BADGES_ADD_FILES_UPLOAD,
  CHANGE_BADGE_CRITERIA,
  SELECT_BADGE_EVENT,
  DOWNLOAD_START_BADGES,
  DOWNLOAD_FINISH_BADGES,
  OPEN_CREATE_BADGE_MODAL,
  CLOSE_CREATE_BADGE_MODAL,
  BADGES_RESET_FETCH,
  CREATE_BADGE_IN_PROCESS,
} from './actionType'

const initState = {
  badgesList: [],
  isBadgesFetching: false,
  uploadData: null,
  brands: [],
  dropZoneEnter: false,
  uploadInProgress: false,
  selectedBadges: [],
  createBadgeOpen: false,
  badgeFieldToEdit: '',
  createBadgeInProcess: false,
  editableBadge: {
    id: 'new',
    mta_id: null,
    badge_rank: '500',
    mta_name: null,
    mta_value_name: null,
    badge_id: null,
    badge_name: null,
    badge_url: '',
    badge_description: null,
    active: true,
    create_time: '',
    create_by: '',
    last_updated_time: '',
    last_updated_by: '',
    item_count: '',
    image_job_id: '',
    criteria: null,
  },
}

export default function badgesReducer(state = initState, action = {}) {
  switch (action.type) {
    case REQUEST_BADGES: {
      const { payload } = action
      const newState = {
        ...state,
        isBadgesFetching: payload.isBadgesFetching,
      }
      return newState
    }
    case RECEIVE_BADGES: {
      const { payload } = action
      const newState = {
        ...state,
        isBadgesFetching: payload.isBadgesFetching,
        badgesList: [
          ...payload.badgesList.map((badge) => {
            var temp = Object.assign({}, badge)
            if (temp.criteria != null) {
              return {
                ...temp,
                criteria: JSON.parse(temp.criteria),
              }
            }
            return temp
          }),
        ],
      }
      return newState
    }
    case BADGES_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isBadgesFetching: payload.isFetching,
      }
      return newState
    }
    case UPDATE_BADGE_EDIT_STATE: {
      const { payload } = action
      const newState = {
        ...state,
        badgeFieldToEdit: payload.fieldName,
      }
      return newState
    }
    case CHANGE_BADGE: {
      const { payload } = action
      const name = payload.fieldName
      const value = payload.value
      const newState = {
        ...state,
        editableBadge: {
          ...state.editableBadge,
          [name]: value,
        },
      }
      return newState
    }
    case CHANGE_BADGE_CRITERIA: {
      // helper function for handling adjusting badge criteria
      let adjustCriteria = (tempBadge) => {
        // handle existing criteria
        if (tempBadge.criteria == null) {
          tempBadge.criteria = {}
        } else {
          let tempCriteria = Object.assign({}, tempBadge.criteria)
          tempBadge.criteria = tempCriteria
        }
        // handle add/remove
        if (criteriaValue != null) {
          tempBadge.criteria[criteriaName] = criteriaValue
        } else {
          delete tempBadge.criteria[criteriaName]
        }
        return tempBadge
      }
      const { payload } = action
      const criteriaName = payload.criteriaName
      var criteriaValue = payload.criteriaValue
      if (criteriaValue === '') {
        criteriaValue = null
      }
      const badge = payload.badge
      let tempBadge = Object.assign({}, badge)
      const newState = {
        ...state,
        editableBadge: adjustCriteria(tempBadge),
      }
      return newState
    }
    case BADGES_VALUE_PERCENT: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((item) => {
          if (item.fileName === payload.fileName) {
            return { ...item, ...payload }
          }
          return item
        }),
      }
      return newState
    }
    case BADGES_DROP_ZONE_ACTIVE: {
      const { payload } = action
      const newState = {
        ...state,
        dropZoneEnter: payload.dropZoneEnter,
      }
      return newState
    }
    case BADGES_ADD_FILES_UPLOAD: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: payload.uploadData,
      }
      return newState
    }
    case BADGES_CLEAR_UPLOAD: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: payload.uploadData,
        uploadInProgress: payload.uploadInProgress,
      }
      return newState
    }
    case BADGES_UPLOAD_IN_PROGRESS_START: {
      const { payload } = action
      const newState = {
        ...state,
        uploadInProgress: payload.uploadInProgress,
      }
      return newState
    }
    case SELECT_BADGE_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedBadges: payload.selectedBadges,
      }
      return newState
    }
    case DOWNLOAD_START_BADGES: {
      const newState = {
        ...state,
        isBadgesFetching: true,
      }
      return newState
    }
    case DOWNLOAD_FINISH_BADGES: {
      const newState = {
        ...state,
        isBadgesFetching: false,
      }
      return newState
    }
    case OPEN_CREATE_BADGE_MODAL: {
      const { payload } = action
      const newState = {
        ...state,
        createBadgeOpen: true,
        editableBadge: payload.editableBadge,
      }
      return newState
    }
    case CLOSE_CREATE_BADGE_MODAL: {
      const newState = {
        ...state,
        createBadgeOpen: false,
        editableBadge: initState.editableBadge,
        badgeFieldToEdit: initState.badgeFieldToEdit,
      }
      return newState
    }
    case CREATE_BADGE_IN_PROCESS: {
      const { payload } = action
      const newState = {
        ...state,
        createBadgeInProcess: payload.inProcess,
      }
      return newState
    }
    default:
      return state
  }
}
