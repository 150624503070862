import HistoryPage from './HistoryPage'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getHistoryData,
  handleChangePage,
  changeDefaultPageSize,
} from '../../../../store/images/history/actionCreator'

function HistoryPageContainer(props) {
  const {
    currentPage,
    totalElements,
    historyData,
    rowsPerPage,
    selectedFilters,
    sortDirection,
    sortField,
    pageName,
  } = props

  const changePage = (event, page) => {
    props.handleChangePage(
      {
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      selectedFilters
    )
  }

  const handleChangeDefaultPageSize = (event) => {
    props.changeDefaultPageSize(
      event.target.value,
      selectedFilters,
      sortDirection,
      sortField,
      pageName
    )
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (totalElements - currentPage) * rowsPerPage)
  return (
    <HistoryPage
      historyData={historyData}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      emptyRows={emptyRows}
      totalElements={totalElements}
      handleChangePage={changePage}
      handleChangeRowsPerPage={handleChangeDefaultPageSize}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHistoryData,
      handleChangePage,
      changeDefaultPageSize,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageHistory, auth } = state
  const {
    historyData,
    currentPage,
    defaultPageSize,
    totalPages,
    totalElements,
    selectedFilters,
    sortDirection,
    sortField,
    pageName,
  } = imageHistory
  return {
    historyData,
    currentPage,
    defaultPageSize,
    totalPages,
    totalElements,
    selectedFilters,
    auth,
    sortDirection,
    sortField,
    pageName,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryPageContainer)
