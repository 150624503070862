import React from 'react'
import HeaderTitle from '../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import BadgesTableContainer from './Components/BadgesTable/BadgesTableContainer'
import CreateBadgeContainer from './Components/BadgesTable/CreateBadge/CreateBadgeContainer'

class Badges extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeaderTitle title="Badges" />
        <Helmet title="Badges" />
        <BadgesTableContainer />
        <CreateBadgeContainer />
      </React.Fragment>
    )
  }
}

export default Badges
