import React from 'react'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Grid } from '@material-ui/core'
import DialogBoxErrorContainer from '../DialogBoxError/DialogBoxErrorContainer'
import DropZone from '../../../../Shared/DropZone/DropZone'

function UploadCopyBullets(props) {
  const { onDrop, onDragEnter, onDragLeave, dropZoneEnter, classes } = props

  const dropZoneRef = React.createRef()

  return (
    <div>
      <DropZone
        onDrop={onDrop}
        dropZoneEnter={dropZoneEnter}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        disableClick
        dropZoneRef={dropZoneRef}
        requiredType={null}
        dropZoneActiveStyle={classes.dropZoneActive}
        dropZoneNotActiveStyle={classes.dropZoneNotActive}
        DropZoneContent={
          <React.Fragment>
            <Grid item xs={4} container justify="center">
              <p>
                <CloudUpload className={classes.uploadIcon} />
              </p>
            </Grid>
            <Grid container item xs={8} justify="center">
              <Grid item xs={12}>
                <p className={classes.DropZoneLargeText}>DRAG & DROP</p>
                <p className={classes.DropZoneSmallText}>
                  an Excel file containing Copy and Features, or{' '}
                  <span
                    role="presentation"
                    onClick={() => dropZoneRef.current.open()}
                    className={classes.browseButton}
                  >
                    browse
                  </span>
                </p>
              </Grid>
            </Grid>
          </React.Fragment>
        }
      />
      <DialogBoxErrorContainer />
    </div>
  )
}
export default withStyles(styles)(UploadCopyBullets)
