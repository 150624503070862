import React from 'react'
import { some, isNil } from 'lodash'
import styles from '../../../theme'
import { withStyles } from '@material-ui/core/styles'
import { Card, Grid, CardContent } from '@material-ui/core'

function escapeHtml(input) {
  return { __html: input }
}

const ClaimViewer = (props) => {
  const {
    claimsData = [],
    claimsError = null,
    classes = {},
    subjectiveClaimsPopUp,
  } = props

  return (
    <React.Fragment>
      <Grid item>
        <Card className={classes.productDetailWrapper}>
          <CardContent>
            {claimsError && (
              <div data-test-id={'claims-error'}>Error: {claimsError}</div>
            )}
            {!claimsError && some(claimsData) && (
              <div>
                <div className={classes.productDetailHeader}>Claims Data</div>
                <div className={classes.productDetailContainerClaimsSec}>
                  {claimsData &&
                    claimsData.map((claim, parentIndex) => {
                      return (
                        <Card className={classes.cardAlignemnet}>
                          <CardContent>
                            <div>
                              {claim &&
                                claim.claimDetails &&
                                claim.claimDetails
                                  .filter(
                                    (claimDetail) =>
                                      !isNil(claimDetail.status) &&
                                      claimDetail.status.code === 501 &&
                                      !isNil(
                                        claimDetail.communicationChannel
                                      ) &&
                                      claimDetail.communicationChannel.code !==
                                        3 &&
                                      !isNil(
                                        claimDetail.vendorSubstantiation
                                      ) &&
                                      claimDetail.vendorSubstantiation
                                        .substantiated === true
                                  )
                                  .map((claimDetail, childIndex) => {
                                    return (
                                      <React.Fragment>
                                        {claimDetail &&
                                        claimDetail.shareWithGuests ? (
                                          <div
                                            data-test-id={'single-claim'}
                                            key={parentIndex + childIndex}
                                          >
                                            <div>
                                              <span>
                                                <b>Claim ID: </b>
                                              </span>
                                              <span>{claimDetail.claimId}</span>
                                            </div>
                                            <div>
                                              <span>
                                                <b>Guest Facing Claim: </b>
                                              </span>
                                              <span>
                                                {claimDetail.guestFacingClaim}
                                              </span>
                                            </div>
                                            <div>
                                              <span>
                                                <b>Claim Description: </b>
                                              </span>
                                              <span>
                                                {claimDetail.claimDescription}
                                              </span>
                                            </div>
                                            <div>
                                              <span>
                                                <b>Applies to: </b>
                                              </span>
                                              <span>
                                                {claimDetail.appliesTo}
                                              </span>
                                            </div>
                                            <div>
                                              <span>
                                                <b>Claims About:</b>
                                              </span>
                                              <span>
                                                {
                                                  claimDetail?.claimsAbout
                                                    ?.description
                                                }
                                              </span>
                                            </div>
                                            <hr />
                                          </div>
                                        ) : null}
                                      </React.Fragment>
                                    )
                                  })}
                            </div>
                          </CardContent>
                        </Card>
                      )
                    })}
                </div>
              </div>
            )}
          </CardContent>
        </Card>
        <Card className={classes.productDetailWrapper}>
          <CardContent>
            {claimsError && (
              <div data-test-id={'claims-error'}>Error: {claimsError}</div>
            )}
            {!claimsError && some(claimsData) && (
              <div>
                <div className={classes.productDetailHeader}>
                  Additional Copy Features/Subjective Statements
                </div>
                <span
                  className={classes.acnhorAlign}
                  onClick={() => subjectiveClaimsPopUp(true)}
                >
                  {claimsData.filter(
                    (item) => item?.subjectiveStatements?.length
                  ).length > 0
                    ? 'Click here for Additional Copy Features'
                    : null}
                </span>
                <div className={classes.productDetailContainerClaimsSec}>
                  {claimsData &&
                    claimsData.map((claim, parentIndex) => {
                      return (
                        <>
                          {claim && claim.subjectiveStatements ? (
                            <Card className={classes.cardAlignemnet}>
                              <CardContent>
                                <div>
                                  <div>
                                    <b>Additional Copy Features:</b>
                                    <ul
                                      className={classes.subjectiveStamentAlign}
                                    >
                                      {claim &&
                                        claim.subjectiveStatements &&
                                        claim.subjectiveStatements.map(
                                          (item, index) => {
                                            return (
                                              <li
                                                key={index}
                                                dangerouslySetInnerHTML={escapeHtml(
                                                  item
                                                )}
                                              />
                                            )
                                          }
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          ) : null}
                        </>
                      )
                    })}
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  )
}

export default withStyles(styles)(ClaimViewer)
