import { Grid, Paper, Tab, Tabs, Toolbar, Badge } from '@material-ui/core'
import EditCopyContainer from '../EditCopy/EditCopyContainer'
import CopyHistoryContainer from '../CopyHistory/CopyHistoryContainer'
import ImageViewerContainer from '../ImageViewer/ImageViewerContainer'
import NotesEditPageContainer from '../NotesEditPage/NotesEditPageContainer'
import styles from '../../../theme'
import { withStyles } from '@material-ui/core/styles'

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#CC0000',
    color: 'white',
  },
}))(Badge)

function CopyDetailLayout(props) {
  const {
    currentTabIndex = 0,
    handleTabChange = () => {},
    hideDot = false,
    classes = {},
  } = props
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EditCopyContainer />
        </Grid>
        <Grid item xs={12}>
          <Paper color="default">
            <Toolbar>
              <Tabs
                value={currentTabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                classes={{ indicator: classes.noColor }}
              >
                <Tab value={0} label="DETAILS" />
                <Tab value={1} label="HISTORY" />
                <Tab
                  value={2}
                  label={
                    <StyledBadge badgeContent="!" invisible={hideDot}>
                      <div className={classes.marginLeftRight10}>NOTES</div>
                    </StyledBadge>
                  }
                />
              </Tabs>
            </Toolbar>
            {currentTabIndex === 0 && <ImageViewerContainer />}
            {currentTabIndex === 1 && <CopyHistoryContainer />}
            {currentTabIndex === 2 && <NotesEditPageContainer />}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
export default withStyles(styles)(CopyDetailLayout)
