//

export function Footer(props) {
  const { children, ...additionalProps } = props
  return <div {...additionalProps}>{children}</div>
}

Footer.displayName = 'Footer'

export default Footer
