import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getAllTagsByGroup,
  filterTagGroups,
} from '../../../../../../store/images/tagTraining/actionCreator'
import CreateNewTagPage from './CreateNewTagPage'

class CreateNewTagPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <React.Fragment>
        <CreateNewTagPage />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllTagsByGroup,
      filterTagGroups,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { createNewTag } = imageTagTraining
  return {
    auth,
    createNewTag,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewTagPageContainer)
