/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
  Button,
  Select,
  Tooltip,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import Autocomplete from '../../Shared/Autocomplete/Autocomplete'
import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import { isEqual, isNil, debounce } from 'lodash'
import ClearIcon from '@material-ui/icons/Clear'
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { bool, func, object, array, string } from 'prop-types'
import { mapToArray } from '../SharedUtils'

function BuildFilter(props) {
  const {
    dateRangeAlreadySelected = false,
    dateRangeAdded = false,
    selectedFilter = -1,
    onFilterSelect = () => {},
    changeDateRangeFilter = () => {},
    filterValues = {},
    onFilterValueSelect = () => {},
    handleAddTcin = () => {},
    handleAddDpci = () => {},
    handleDeleteTcin = () => {},
    handleDeleteDpci = () => {},
    handleAddEmail = () => {},
    handleDeleteEmail = () => {},
    isFetching = false,
    addNewFilter = () => {},
    selectedDepartmentData = {},
    handleAddDivision = () => {},
    handleDeleteDivision = () => {},
    defaultFilterValues = {},
    FilterData = [],
    Timespans = [],
    LaunchTimespans = [],
    CopyWritingStatus = [],
    RoutedTeams = [],
    NoteOptions = [],
    ApprovalStatus = [],
    IsuItemSetUpStatus = [],
    WeeksInOutData = [],
    classes = {},
    handleAddVendor = () => {},
    emailInputDisabled = false,
    deptClassList = [],
    vendorList = [],
    isFetchingLov = false,
    onSearchChange = () => {},
    brandsList = [],
    handleAddBrand = () => {},
    onSearchChangeBrand = () => {},
  } = props

  const [state, setState] = React.useState({
    date: [new Date(), new Date()],
  })

  const [selectedVendor, setSelectedVendor] = React.useState('')
  const [selectedBrand, setSelectedbrand] = React.useState('')
  const [selectedDepartment, setSelectedDepartment] = React.useState('')
  const [selectedClass, setSelectedClass] = React.useState('')
  const [localVendorList, setLocalVendorList] = React.useState(vendorList)
  const [localBrandsList, setLocalBrandsList] = React.useState(brandsList)

  const [selectedStartWeek, setSelectedStartWeek] = React.useState('')
  const [selectedEndWeek, setSelectedEndWeek] = React.useState('')

  let onDateChange = (date) => {
    // eslint-disable-next-line no-sequences
    return setState({ ...state, date: date }), changeDateRangeFilter(date)
  }

  // Important for for being nice to our BE servers
  // Don't let the users fire off queries with every keystroke
  // https://dev.to/sbrshkappa/what-is-debouncing-a-javascript-implementation-3aoh
  const onSearchChangeInternal = (text) => {
    onSearchChange(text)
  }

  const onSearchBrandChangeInternal = (text) => {
    onSearchChangeBrand(text)
  }

  const debounceOnSearchChange = React.useCallback(
    debounce(onSearchChangeInternal, 500),
    [onSearchChange]
  )

  const debounceOnSearchChangeBrand = React.useCallback(
    debounce(onSearchBrandChangeInternal, 500),
    [onSearchChangeBrand]
  )

  React.useEffect(() => {
    if (!isEqual(vendorList, localVendorList)) {
      setLocalVendorList(vendorList)
    }
  }, [vendorList])

  React.useEffect(() => {
    if (!isEqual(brandsList, localBrandsList)) {
      setLocalBrandsList(brandsList)
    }
  }, [brandsList])

  React.useEffect(() => {
    setLocalVendorList([])
    debounceOnSearchChange(selectedVendor)
  }, [selectedVendor])

  React.useEffect(() => {
    setLocalBrandsList([])
    debounceOnSearchChangeBrand(selectedBrand)
  }, [selectedBrand])

  const resetState = () => {
    setSelectedVendor('')
    setSelectedDepartment('')
    setSelectedClass('')
    setLocalVendorList([])
    setSelectedbrand('')
    setLocalBrandsList([])
    setSelectedStartWeek('')
    setSelectedEndWeek('')
    setState({ date: [new Date(), new Date()] })
  }

  const endAdornmentVendor = (
    <ClearIcon
      className={classes.pointer}
      onClick={(e) => {
        setSelectedVendor('')
        setSelectedbrand('')
      }}
    />
  )

  return (
    <Card classes={{ root: classes.overflowAuto }}>
      <CardHeader
        subheader={'Build Your Filter'}
        className={classes.marginBottom31}
      />
      <CardContent>
        <div className={classes.marginBottom31}>
          <Select
            name="searchFilter"
            value={selectedFilter}
            onChange={onFilterSelect}
            inputProps={{
              id: 'filter-required',
            }}
            fullWidth
            className={classes.formControl}
          >
            {FilterData.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.display}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText classes={{ root: classes.marginLeft7 }}>
            Please select filter
          </FormHelperText>
        </div>
        {selectedFilter === 'eventType' && (
          <div>
            <Select
              name="copyWritingStatus"
              value={filterValues.copyWritingStatus}
              onChange={onFilterValueSelect}
              fullWidth
              className={classes.formControl}
            >
              {CopyWritingStatus.filter((item) => item.isVisible).map(
                (item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.display}
                  </MenuItem>
                )
              )}
            </Select>
            <FormHelperText className={classes.formControl}>
              Please select copy writing status
            </FormHelperText>
          </div>
        )}
        {selectedFilter === 'department' && (
          <div>
            <Select
              name="departmentData"
              value={filterValues.departmentData}
              onChange={onFilterValueSelect}
              fullWidth
              className={classes.formControl}
            >
              {selectedDepartmentData.returnData &&
                selectedDepartmentData.returnData.map((item) => (
                  <MenuItem key={item.display} value={item.value}>
                    {item.display}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText className={classes.formControl}>
              Please select department
            </FormHelperText>
          </div>
        )}
        {selectedFilter === 'dateRange' && (
          <React.Fragment>
            <div className={classes.marginBottom31}>
              <Select
                name="dateRange"
                value={filterValues.dateRange}
                onChange={onFilterValueSelect}
                fullWidth
                className={classes.formControl}
              >
                {Timespans.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.display}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className={classes.formControl}>
                Please select a date range
              </FormHelperText>
            </div>
            {filterValues.dateRange === 'datePicker' && (
              <div className={classes.marginTop31}>
                <DateRangePicker
                  onChange={onDateChange}
                  value={state.date}
                  locale="en-US"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  yearPlaceholder="yyyy"
                  format="MM-dd-y"
                />
              </div>
            )}
          </React.Fragment>
        )}

        {selectedFilter === 'launchDate' && (
          <React.Fragment>
            <div className={classes.marginBottom31}>
              <Select
                name="dateRange"
                value={filterValues.dateRange}
                onChange={onFilterValueSelect}
                fullWidth
                className={classes.formControl}
              >
                {LaunchTimespans.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.display}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className={classes.formControl}>
                Please select a date range
              </FormHelperText>
            </div>
            {filterValues.dateRange === 'datePicker' && (
              <div className={classes.marginTop31}>
                <DateRangePicker
                  onChange={onDateChange}
                  value={state.date}
                  locale="en-US"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  yearPlaceholder="yyyy"
                  format="MM-dd-y"
                />
              </div>
            )}
          </React.Fragment>
        )}

        {selectedFilter === 'approvalStatus' && (
          <div>
            <Select
              name="approvalStatus"
              value={filterValues.approvalStatus}
              onChange={onFilterValueSelect}
              fullWidth
              className={classes.formControl}
            >
              {ApprovalStatus.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.display}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.formControl}>
              Please select approval status
            </FormHelperText>
          </div>
        )}

        {selectedFilter === 'itemSetupStatus' && (
          <div>
            <Select
              name="itemSetupStatus"
              value={filterValues.itemSetupStatus}
              onChange={onFilterValueSelect}
              fullWidth
              className={classes.formControl}
            >
              {IsuItemSetUpStatus.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.display}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.formControl}>
              Please select Item setup status
            </FormHelperText>
          </div>
        )}

        {selectedFilter === 'vendor' && (
          <div className={classes.margin8}>
            <Autocomplete
              placeholder={'Vendor Name or ID'}
              loading={isFetchingLov}
              value={selectedVendor}
              onChange={(e) => setSelectedVendor(e.target.value)}
              onSelect={(selectedValue) => {
                if (!isNil(selectedValue)) {
                  const splitVal = selectedValue.split(' - ')
                  setSelectedVendor(selectedValue)
                  handleAddVendor(!isNil(splitVal[0]) ? splitVal[0] : '')
                }
              }}
              items={localVendorList.map((item) => {
                if (!isNil(item.vendor_name)) {
                  return `${item.gms_vendor_id} - ${item.vendor_name}`
                }
                return item.gms_vendor_id
              })}
              fullWidth
              endAdornment={endAdornmentVendor}
            />
          </div>
        )}

        {selectedFilter === 'brand' && (
          <div className={classes.margin8}>
            <Autocomplete
              placeholder={'Enter or select brand'}
              loading={isFetchingLov}
              value={selectedBrand}
              onChange={(e) => setSelectedbrand(e.target.value)}
              onSelect={(selectedValue) => {
                if (!isNil(selectedValue)) {
                  const splitVal = selectedValue.split(' - ')
                  setSelectedbrand(selectedValue)
                  handleAddBrand(!isNil(splitVal[0]) ? splitVal[0] : '')
                }
              }}
              items={localBrandsList.map((item) => {
                if (!isNil(item.brandName)) {
                  return `${item.brandId} - ${item.brandName}`
                }
                return item.brandId
              })}
              fullWidth
              endAdornment={endAdornmentVendor}
            />
          </div>
        )}

        {selectedFilter === 'tcin' && (
          <div
            className={filterValues.tcins.length > 12 ? classes.tcinList : null}
          >
            <ChipInput
              value={mapToArray(filterValues.tcins)}
              fullWidth
              fullWidthInput
              blurBehavior="add"
              onAdd={(chips) => handleAddTcin(chips)}
              onDelete={(deletedChip) => handleDeleteTcin(deletedChip)}
              // @ts-ignore
              className={classes.formControl}
              helperText="Please provide list of TCINs"
            />
          </div>
        )}

        {selectedFilter === 'dpcis' && (
          <div
            className={filterValues.tcins.length > 12 ? classes.tcinList : null}
          >
            <ChipInput
              value={mapToArray(filterValues.dpcis)}
              fullWidth
              fullWidthInput
              blurBehavior="add"
              onAdd={(chips) => handleAddDpci(chips)}
              onDelete={(deletedChip) => handleDeleteDpci(deletedChip)}
              // @ts-ignore
              className={classes.formControl}
              helperText="Please provide list of DPCIs"
            />
          </div>
        )}

        {selectedFilter === 'emails' && (
          <ChipInput
            value={mapToArray(filterValues.emails)}
            fullWidth
            fullWidthInput
            blurBehavior="add"
            onAdd={(chips) => handleAddEmail(chips)}
            onDelete={(deletedChip) => handleDeleteEmail(deletedChip)}
            // @ts-ignore
            className={classes.formControl}
            helperText="Please provide list of emails"
            disabled={emailInputDisabled}
          />
        )}
        {selectedFilter === 'division' && (
          <ChipInput
            value={mapToArray(filterValues.divisions)}
            fullWidth
            fullWidthInput
            blurBehavior="add"
            onAdd={(chips) => handleAddDivision(chips)}
            onDelete={(deletedChip) => handleDeleteDivision(deletedChip)}
            // @ts-ignore
            className={classes.formControl}
          />
        )}
        {selectedFilter === 'routedTeams' && (
          <div>
            <Select
              name="routedTeams"
              value={filterValues.routedTeams}
              onChange={onFilterValueSelect}
              fullWidth
              className={classes.formControl}
            >
              {RoutedTeams.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.display}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.formControl}>
              Please select Routing Team
            </FormHelperText>
          </div>
        )}
        {selectedFilter === 'notes' && (
          <div>
            <Select
              name="noteOptions"
              value={filterValues.noteOptions}
              onChange={onFilterValueSelect}
              fullWidth
              className={classes.formControl}
            >
              {NoteOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.display}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.formControl}>
              Please select note option
            </FormHelperText>
          </div>
        )}
        {selectedFilter === 'deptClass' && (
          <div className={classes.margin8}>
            <Autocomplete
              placeholder={'Department'}
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
              onSelect={(selectedValue) => {
                if (!isNil(selectedValue)) {
                  setSelectedDepartment(selectedValue)
                  const event = {
                    target: {
                      name: 'dept',
                      value: deptClassList.find(
                        (dept) => dept.type === selectedValue.split(' - ')[0]
                      ),
                    },
                  }
                  onFilterValueSelect(event)
                }
              }}
              items={deptClassList.map((item) => {
                if (!isNil(item.type)) {
                  return `${item.type} - ${item.deptName}`
                }
                return item.type
              })}
              fullWidth
            />
          </div>
        )}
        {selectedFilter === 'deptClass' &&
          filterValues.deptClass.dept !== -1 && (
            <div className={classes.subRow}>
              <Autocomplete
                placeholder={'Class (Optional)'}
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
                onSelect={(selectedValue) => {
                  if (!isNil(selectedValue)) {
                    setSelectedClass(selectedValue)
                    const event = {
                      target: {
                        name: 'class',
                        value: filterValues.deptClass.dept.subClasses.find(
                          (deptClass) =>
                            deptClass.type === selectedValue.split(' - ')[0]
                        ),
                      },
                    }
                    onFilterValueSelect(event)
                  }
                }}
                items={filterValues.deptClass.dept.subClasses.map((item) => {
                  if (!isNil(item.type)) {
                    return `${item.type} - ${item.subClassName}`
                  }
                  return item.type
                })}
                fullWidth
              />
            </div>
          )}

        {selectedFilter === 'weeksOutAgo' && (
          <div className={classes.margin8}>
            <Autocomplete
              placeholder={'From'}
              value={selectedStartWeek}
              onChange={(e) => setSelectedStartWeek(e.target.value)}
              onSelect={(selectedValue) => {
                if (!isNil(selectedValue)) {
                  setSelectedStartWeek(selectedValue)
                  const event = {
                    target: {
                      name: 'startWeek',
                      value: WeeksInOutData.find(
                        (startWeek) => startWeek.label === selectedValue
                      ),
                    },
                  }
                  onFilterValueSelect(event)
                }
              }}
              items={WeeksInOutData.map((weeks) => {
                if (!isNil(weeks.label)) {
                  return `${weeks.label}`
                }
                return weeks.value
              })}
              fullWidth
            />
          </div>
        )}
        {selectedFilter === 'weeksOutAgo' &&
          filterValues.weeks.startDate !== null && (
            <div className={classes.subRow}>
              <Autocomplete
                placeholder={'To (Optional)'}
                value={selectedEndWeek}
                onChange={(e) => setSelectedEndWeek(e.target.value)}
                onSelect={(selectedValue) => {
                  if (!isNil(selectedValue)) {
                    setSelectedEndWeek(selectedValue)
                    const event = {
                      target: {
                        name: 'endWeek',
                        value: WeeksInOutData.find(
                          (startWeek) => startWeek.label === selectedValue
                        ),
                      },
                    }
                    onFilterValueSelect(event)
                  }
                }}
                items={WeeksInOutData.map((weeks) => {
                  if (!isNil(weeks.label)) {
                    return `${weeks.label}`
                  }
                  return weeks.value
                })}
                fullWidth
              />
            </div>
          )}
      </CardContent>
      <CardActions>
        <Tooltip
          id="tooltip-icon"
          title={
            dateRangeAdded && dateRangeAlreadySelected
              ? 'Replace date'
              : 'Add to filter'
          }
        >
          <div>
            <Button
              disabled={
                isEqual(filterValues, defaultFilterValues) ||
                (filterValues.dateRange === 'datePicker' &&
                  (filterValues.fromDate === -1 ||
                    filterValues.toDate === -1)) ||
                isFetching
              }
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                addNewFilter()
                resetState()
              }}
            >
              {dateRangeAdded && dateRangeAlreadySelected ? 'Replace' : 'Add'}
            </Button>
          </div>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

BuildFilter.propTypes = {
  selectedFilter: string.isRequired,
  onFilterSelect: func.isRequired,
  filterValues: object.isRequired,
  onFilterValueSelect: func.isRequired,
  isFetching: bool.isRequired,
  addNewFilter: func.isRequired,
  defaultFilterValues: object.isRequired,
  FilterData: array.isRequired,
}

// @ts-ignore
export default withStyles(styles)(BuildFilter)
