import {
  NEW_FILTER_EVENT_BULK_UPLOAD_REPORT,
  BUILD_FILTER_EVENT_BULK_UPLOAD_REPORT,
  REQUEST_BULK_UPLOAD_REPORT_DATA,
  RECEIVE_BULK_UPLOAD_REPORT_DATA,
  CHANGE_FILTER_EVENT_BULK_UPLOAD_REPORT,
  SELECT_FILTER_VALUE_EVENT_BULK_UPLOAD_REPORT,
  CHANGE_DEFAULT_PAGE_SIZE_BULK_UPLOAD_REPORT,
  CHANGE_CURRENT_PAGE_BULK_UPLOAD_REPORT,
  SELECT_BULK_UPLOAD_REPORT_EVENT,
  REMOVE_FILTER_EVENT_BULK_UPLOAD_REPORT,
  BULK_UPLOAD_REPORT_RESET_FETCH,
  CLEAR_BULK_UPLOAD_REPORT_DATA_EVENT,
  DOWNLOAD_START_BULK_UPLOAD_REPORT,
  DOWNLOAD_FINISH_BULK_UPLOAD_REPORT,
  BUILD_FILTER_EXPAND_BULK_UPLOAD_REPORT,
  STORE_BULK_UPLOAD_REPORT_STRAPI_DATA,
  SHOW_DEFAULT_ALERT_BULK_UPLOAD_REPORT,
  ERROR_SEARCHING_BULK_UPLOAD_REPORT,
} from './actionType'

const initState = {
  buildFilterContainerShown: false,
  newFilterContainerShown: false,
  copyDataContainerShown: false,
  selectedFilters: [],
  copyData: [],
  isFetching: false,
  selectedFilter: -1,
  filterValues: {
    dateRange: -1,
    tcins: [],
    emails: [],
    fromDate: -1,
    toDate: -1,
    companyName: -1,
  },
  defaultFilterValues: {
    dateRange: -1,
    tcins: [],
    emails: [],
    fromDate: -1,
    toDate: -1,
    companyName: -1,
  },
  defaultPageSize: 10,
  sortDirection: 'asc',
  sortField: 'TCIN',
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  selectedCopy: undefined,
  buildFilterExpansionPanel: false,
  alert: null,
  information: null,
  faq: null,
  showDefaultAlert: false,
  errorSearching: false,
  // DO NOT CHANGE THE PAGENAME!
  pageName: 'BulkUploadReport',
}

export default function bulkUploadReportReducer(
  state = initState,
  action = {}
) {
  switch (action.type) {
    case NEW_FILTER_EVENT_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterContainerShown: payload,
      }
      return newState
    }
    case REMOVE_FILTER_EVENT_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        copyDataContainerShown: payload.copyDataContainerShown,
        selectedFilters: payload.selectedFilters,
      }
      return newState
    }
    case BUILD_FILTER_EVENT_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        copyDataContainerShown: payload.copyDataContainerShown,
        selectedFilters: payload.selectedFilters,
        // Resetting the value after adding
        filterValues: initState.filterValues,
      }
      return newState
    }
    case REQUEST_BULK_UPLOAD_REPORT_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        copyData: payload.copyData,
        isFetching: payload.isFetching,
        selectedCopy: payload.selectedCopy,
      }
      return newState
    }
    case CLEAR_BULK_UPLOAD_REPORT_DATA_EVENT: {
      const newState = {
        ...state,
        copyData: [],
      }
      return newState
    }
    case BULK_UPLOAD_REPORT_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
        isLoading: payload.isFetching,
      }
      return newState
    }
    case SELECT_BULK_UPLOAD_REPORT_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedCopy: payload.selectedCopy,
      }
      return newState
    }
    case CHANGE_CURRENT_PAGE_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        currentPage: payload.currentPage,
      }
      return newState
    }
    case RECEIVE_BULK_UPLOAD_REPORT_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        copyData: payload.copyData,
        isFetching: payload.isFetching,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
      }
      return newState
    }
    case CHANGE_FILTER_EVENT_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedFilter: payload.selectedFilter,
        filterValues: initState.filterValues,
      }
      return newState
    }
    case SELECT_FILTER_VALUE_EVENT_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        filterValues: payload.filterValues,
      }
      return newState
    }
    case CHANGE_DEFAULT_PAGE_SIZE_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        defaultPageSize: payload.defaultPageSize,
      }
      return newState
    }
    case DOWNLOAD_START_BULK_UPLOAD_REPORT: {
      const newState = {
        ...state,
        isLoading: true,
      }
      return newState
    }
    case DOWNLOAD_FINISH_BULK_UPLOAD_REPORT: {
      const newState = {
        ...state,
        isLoading: false,
      }
      return newState
    }
    case BUILD_FILTER_EXPAND_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterExpansionPanel: payload.buildFilterExpansionPanel,
      }
      return newState
    }
    case STORE_BULK_UPLOAD_REPORT_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case ERROR_SEARCHING_BULK_UPLOAD_REPORT: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    default:
      return state
  }
}
