import React from 'react'
import { Typography, Toolbar, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'

import GetAppIcon from '@material-ui/icons/GetApp'

let BulkUploadReportDataContainerToolbar = (props) => {
  const {
    selectedCopy = [],
    classes = {},
    downloadSelectedTcinsCopy = () => {},
  } = props
  const numSelected = selectedCopy ? selectedCopy.length : 0

  return (
    <React.Fragment>
      {numSelected > 0 && (
        <Toolbar className={classes.toolBar}>
          <div className={classes.title}>
            {numSelected > 0 && (
              <Typography color="inherit">{numSelected} selected</Typography>
            )}
          </div>
          {numSelected > 0 ? null : <div className={classes.spacer} />}
          <div className={classes.actions}>
            {numSelected > 0 && (
              <Button
                className={classes.drawerButton}
                onClick={() => downloadSelectedTcinsCopy()}
              >
                <GetAppIcon />
                <span className={classes.marginLeft5}>
                  DOWNLOAD SELECTED TO EXCEL
                </span>
              </Button>
            )}
          </div>
        </Toolbar>
      )}
    </React.Fragment>
  )
}

BulkUploadReportDataContainerToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BulkUploadReportDataContainerToolbar)
