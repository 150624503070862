import ImageSearchIcon from '@material-ui/icons/ImageSearch'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import styles from '../theme'
import { includes } from 'lodash'

const ReviewImageAction = (props) => {
  const { reviewImageClickAction = () => {}, classes = {}, auth = {} } = props

  return (
    auth.permission.imgReview &&
    !includes(auth.permission.hostName, 'vendorpipeline') && (
      <Button className={classes.drawerButton} onClick={reviewImageClickAction}>
        <ImageSearchIcon />
        <span className={classes.marginLeft5}>Review New Images</span>
      </Button>
    )
  )
}

// @ts-ignore
export default withStyles(styles)(ReviewImageAction)
