export const RECIEVE_IMAGE_REVIEW_HISTORY_DATA = 'RECIEVE_REVIEW_HISTORY_DATA'
export const REQUEST_IMAGE_REVIEW_HISTORY_DATA = 'REQUEST_REVIEW_HISTORY_DATA'
export const IMAGE_REVIEW_HISTORY_RESET_FETCH =
  'IMAGE_REVIEW_HISTORY_RESET_FETCH'
export const ERROR_SEARCHING_IMAGE_REVIEW_HISTORY =
  'ERROR_SEARCHING_IMAGE_REVIEW_HISTORY'
export const CLEAR_REVIEW_HISTORY_DATA = 'CLEAR_REVIEW_HISTORY_DATA'
export const SELECT_IMAGE_REVIEW_HISTORY_EVENT =
  'SELECT_IMAGE_REVIEW_HISTORY_EVENT'
export const STORE_IMAGE_REVIEW_HISTORY_STRAPI_DATA =
  'STORE_IMAGE_REVIEW_HISTORY_STRAPI_DATA'
export const SHOW_DEFAULT_ALERT_IMAGE_REVIEW_HISTORY =
  'SHOW_DEFAULT_ALERT_IMAGE_REVIEW_HISTORY'
