const styles = (theme) => ({
  marginTopBottom10: {
    margin: '10px 0px',
  },
  alert: {
    backgroundColor: '#d32f2f',
    color: 'white',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  header: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '.005em',
  },
  body: {
    fontSize: '14px',
    letterSpacing: '.010em',
    fontWeight: '400',
    lineHeight: '20px',
  },
  buildFilterExpansion: {
    width: '100%',
    margin: '0 0 5px 0',
  },
})

export default styles
