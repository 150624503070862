import React from 'react'
import { Typography, Toolbar, Grid, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import TablePagination from '@material-ui/core/TablePagination'
import { isNil } from 'lodash'

let ImageDataContainerTitle = (props) => {
  const {
    classes = {},
    totalElements = 0,
    rowsPerPage = 0,
    page = 0,
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    publishImageData = () => {},
    resetImageData = () => {},
    imageDataChanges = [],
    auth = {},
    sortDirection = 'ASC',
    sortField = 'TCIN',
    selectedFilters = {},
    setCopiedImage = () => {},
    copiedImage = null,
    backToSearch = () => {},
  } = props

  let disabled = imageDataChanges.length === 0

  const cancelCopy = () => {
    setCopiedImage(null)
  }

  return (
    <Toolbar>
      <Grid justify="space-between" container spacing={3} alignItems="center">
        <Grid item>
          <div className={classes.title}>
            <Typography
              variant="subtitle1"
              className={classes.totalElementsTitle}
              id="tableTitle"
            >
              {totalElements} items
            </Typography>
          </div>
        </Grid>
        <Grid item>
          {!isNil(auth) &&
          !isNil(auth.permission) &&
          auth.permission.imgCurationEdit ? (
            <React.Fragment>
              <Button
                data-test-id="reset"
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => resetImageData()}
                disabled={disabled}
                className={classes.marginRight20}
              >
                RESET
              </Button>
              <Button
                data-test-id="publish"
                variant="contained"
                color="primary"
                type="button"
                onClick={() =>
                  publishImageData(
                    imageDataChanges,
                    auth,
                    selectedFilters,
                    rowsPerPage,
                    sortDirection,
                    sortField
                  )
                }
                disabled={disabled}
                className={classes.marginRight20}
              >
                PUBLISH
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => cancelCopy()}
                className={
                  copiedImage === null ? classes.hidden : classes.marginRight20
                }
              >
                CANCEL COPY
              </Button>
            </React.Fragment>
          ) : null}
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={() => backToSearch()}
            className={classes.marginRight20}
          >
            BACK TO SEARCH
          </Button>
        </Grid>
        <Grid item>
          <TablePagination
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={[10, 25, 50, 100, 250]}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Toolbar>
  )
}

ImageDataContainerTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
}

// @ts-ignore
export default withStyles(styles)(ImageDataContainerTitle)
