import VideoReviewData from './VideoReviewData'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  handleChangePage,
  changeDefaultPageSize,
  handleSelectVideo,
  setSortField,
  setSortDirection,
  requestVideoReviewData,
} from '../../../../../store/videos/review/actionCreator'
import { gridCheckedLogic } from '../../../../Shared/SharedUtils'
import {
  setShowVideoActiveCheckbox,
  resetRejectionMessages,
  setShowRejectButtons,
  requestVideoDataById,
  editModeEnabled,
} from '../../../../../store/videos/upload/actionCreator'
import { savePaginationDataEvent } from '../../../../../store/auth/actionCreator'
import { withRouter } from '../../../../Shared/WithRouterHoc'
import { VIDEO_EDIT_MODE } from '../../../../Shared/Constants'

function VideoReviewDataContainer(props) {
  const handleSelectAllClick = (_, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = props.videoData
    }
    props.handleSelectVideo({
      selectedVideo: newSelected,
    })
  }

  const handleClick = (_, checkedVideo) => {
    const { selectedVideo } = props
    props.handleSelectVideo({
      selectedVideo: gridCheckedLogic(selectedVideo, checkedVideo),
    })
  }

  const handleChangePage = (_, page) => {
    props.handleChangePage(
      {
        currentPage: page,
        defaultPageSize: props.pageSize,
        selectedFilters: props.selectedFilters,
      },
      props.sortDirection,
      props.sortField
    )
  }

  const handleChangeDefaultPageSize = (event) => {
    props.changeDefaultPageSize(
      {
        defaultPageSize: event.target.value,
        selectedFilters: props.selectedFilters,
      },
      props.sortDirection,
      props.sortField,
      props.pageName
    )
  }

  const isSelected = (checkedVideo) =>
    props.selectedVideo.indexOf(checkedVideo) !== -1

  const requestVideoDataById = (id) => {
    props.requestVideoDataById(id)
    props.editModeEnabled(VIDEO_EDIT_MODE.REVIEW)
    //props.history.push(`/v2/video/videoEdit/${id}`)
    props.router.navigate({ pathname: `/v2/video/videoEdit/${id}` })
  }

  const {
    defaultPageSize,
    videoData,
    currentPage,
    selectedVideo,
    totalElements,
    auth,
    selectedFilters,
    rowsPerPage,
    requestVideoReviewData,
    sortDirection,
    sortField,
    setSortDirection,
    setSortField,
    savePaginationDataEvent,
    pageName,
    setShowRejectButtons,
    setShowVideoActiveCheckbox,
    resetRejectionMessages,
  } = props

  const emptyRows =
    defaultPageSize -
    Math.min(defaultPageSize, (totalElements - currentPage) * defaultPageSize)

  return (
    <VideoReviewData
      data={videoData}
      selectedVideo={selectedVideo}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      emptyRows={emptyRows}
      totalElements={totalElements}
      handleSelectAllClick={handleSelectAllClick}
      isSelected={isSelected}
      auth={auth}
      handleClick={handleClick}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeDefaultPageSize}
      selectedFilters={selectedFilters}
      sortDirection={sortDirection}
      sortField={sortField}
      requestVideoReviewData={requestVideoReviewData}
      setSortDirection={setSortDirection}
      setSortField={setSortField}
      savePaginationDataEvent={savePaginationDataEvent}
      pageName={pageName}
      requestVideoDataById={requestVideoDataById}
      setShowRejectButtons={setShowRejectButtons}
      setShowVideoActiveCheckbox={setShowVideoActiveCheckbox}
      resetRejectionMessages={resetRejectionMessages}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleChangePage,
      changeDefaultPageSize,
      handleSelectVideo,
      savePaginationDataEvent,
      setSortField,
      setSortDirection,
      requestVideoReviewData,
      requestVideoDataById,
      setShowRejectButtons,
      setShowVideoActiveCheckbox,
      resetRejectionMessages,
      editModeEnabled,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { videoReview, auth } = state
  const {
    videoData,
    currentPage,
    selectedVideo,
    defaultPageSize,
    currentRouteTeam,
    plannerNotes,
    totalPages,
    totalElements,
    selectedFilters,
    assignedWritersList,
    pageName,
  } = videoReview
  return {
    videoData,
    currentPage,
    selectedVideo,
    defaultPageSize,
    currentRouteTeam,
    plannerNotes,
    auth,
    totalPages,
    totalElements,
    selectedFilters,
    assignedWritersList,
    pageName,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoReviewDataContainer))
