const styles = (theme) => ({
  table: {
    minWidth: '1020px',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: 'white',
  },
  progressBar: {
    marginLeft: '50%',
  },
  title: {
    flex: '0 0 auto',
  },
  drawerButton: {
    margin: '0px 0px 0px 5px',
    color: 'white',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  padding5: {
    padding: '5px',
  },
  noResult: {
    textAlign: 'center',
    padding: '20px',
  },
  marginRight20: {
    marginRight: '20px',
  },
  totalElementsTitle: {
    color: '#CC0000',
    padding: '30px 5px',
    fontSize: '1.2em',
  },
  height49: {
    height: 49,
  },
  maxHeight40: {
    maxHeight: '40px',
  },
  toolBar: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: '#CC0000',
    color: 'white',
    zIndex: 1000,
  },
  maxWidth350: {
    maxWidth: '350px',
  },
  posterFrame: {
    maxWidth: '200px',
    maxHeight: '200px',
    padding: '10px',
    cursor: 'pointer',
  },
  marginRight10: {
    marginRight: '10px',
  },
  maxWidth200: {
    maxWidth: '200px',
  },
  iconMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  expansionText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#01a3e7',
  },
  sort: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  imageSpacing: {
    margin: '15px 15px 0 0',
    maxHeight: '100px',
    maxWidth: '100px',
  },
  itemData: {
    width: '250px',
  },
  imageTableCellDraggedOver: {
    display: 'flex',
    backgroundColor: 'rgba(156, 6, 19, 0.5)',
    height: '142px',
    minWidth: '115px',
    width: '100%',
  },
  imageTableCell: {
    display: 'flex',
    height: '142px',
    minWidth: '115px',
    width: '100%',
  },
  imageBorder: {
    border: '5px solid red',
  },
  disabled: {
    opacity: 0.25,
  },
  horizontalFlex: {
    display: 'flex',
  },
  pasteTarget: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '142px',
    minWidth: '115px',
  },
  centerFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pasteIcon: {
    color: '#5e9afa',
    fontSize: '65px',
    cursor: 'pointer',
  },
  swatchPasteIcon: {
    color: '#5e9afa',
    fontSize: '35px',
    cursor: 'pointer',
  },
  actionIcons: {
    fontSize: '18px',
    cursor: 'pointer',
    padding: '5px',
  },
  verticalFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  utilityRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  hidden: {
    display: 'none',
  },
  swatchImage: {
    height: '40px',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  itemDetails: {
    minWidth: '100px',
  },
  primaryMissing: {
    textAlign: 'center',
    padding: '40px 30px',
    color: 'red',
    border: '5px solid red',
    width: '100%',
    maxWidth: '135px',
  },
  bookmarkIcon: {
    color: '#FFC107',
  },
  minHeight45Per: {
    minHeight: '45%',
  },
  modalToolbar: {
    color: 'white',
    backgroundColor: '#CC0000',
    display: 'flex',
    justifyContent: 'space-between',
  },
  white: {
    color: 'white',
  },
  noOverflow: {
    overflow: 'visible',
  },
  width50Per: {
    width: '50%',
  },
  maxWidth100Per: {
    maxWidth: '100%',
  },
  fontSize12: {
    fontSize: '12px',
  },
  fontSize18: {
    fontSize: '18px',
  },
  fontSize20: {
    fontSize: '20px',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  paletteParent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  palette: {
    width: '32%',
    paddingBottom: '12%',
    marginBottom: '2%',
  },
  width200: {
    width: '200px',
  },
  swatchPreview: {
    height: `calc(100% - 30px)`,
  },
  zoomin: {
    position: 'absolute',
    top: '15px',
    left: '0px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  posRel: {
    position: 'relative',
  },
  displayNone: {
    display: 'none',
  },
  toolbarSpacer: {
    height: '60px',
  },
  saveFilterTitle: {
    backgroundColor: '#3f51b5',
  },
  saveTitleText: {
    color: 'white',
  },
  labelSeleclistWrapper: {
    width: '600px',
  },
  labelSeleclistInner: {
    width: '250px',
    display: 'inline-block',
  },
  marginTop20: {
    marginTop: '20px',
  },
  multiSelectWidth: {
    width: '250px',
  },
  verticalAlignBotttom: {
    verticalAlign: 'bottom',
  },
  manageSearchWrapper: {
    overflowX: 'auto',
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 250px)',
  },
})

export default styles
