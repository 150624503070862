import styles from '../../theme'
import { Paper, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ChipInput from 'material-ui-chip-input'
import { mapToArray, splitTcins } from '../../../Shared/SharedUtils'

function PasteTcinTextArea(props) {
  const {
    classes,
    tcinChipsArray,
    editModeSizeChartTcinList,
    addTcinToApi,
    actionTypeForTcin,
  } = props

  const handleAddTcin = (selectedTcins) => {
    let chips = []
    splitTcins(selectedTcins).forEach((i) => {
      chips.push({
        value: i,
        display: i,
      })
    })

    if (tcinChipsArray.length > 0) {
      tcinChipsArray.forEach((item) => {
        chips.push(item)
      })
    }
    props.addTcinChip(chips)
  }

  const clearPastedValue = () => {
    props.addTcinChip([])
    props.addTcinChipToApi([])
    if (editModeSizeChartTcinList) {
      props.actionTypeCrudOpp('')
    }
  }

  const handleDeleteTcin = (deletedTcin) => {
    let chips = []
    tcinChipsArray.forEach((item) => {
      if (item.value !== deletedTcin) {
        chips.push(item)
      }
    })
    if (chips.length === 0) {
      props.addTcinChipToApi([])
    }
    props.addTcinChip(chips)
  }

  const addTcinToPublisApi = (actionMode) => {
    props.addTcinChipToApi(tcinChipsArray)
    if (editModeSizeChartTcinList) {
      props.actionTypeCrudOpp(actionMode)
    }
    // props.addTcinChip([])
  }

  return (
    <Paper className={classes.rulesConditionViewerMain}>
      <div
        className={
          tcinChipsArray && tcinChipsArray.length > 12
            ? classes.tcinList
            : classes.initialTcinList
        }
        data-test-id="size-chart-tcin-input"
      >
        <ChipInput
          value={mapToArray(tcinChipsArray)}
          fullWidth
          fullWidthInput
          blurBehavior="add"
          onAdd={(chips) => {
            handleAddTcin(chips)
          }}
          onDelete={(deletedChip) => handleDeleteTcin(deletedChip)}
          // @ts-ignore
          className={classes.formControl}
          helperText="Please provide list of TCINs"
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => clearPastedValue()}
          style={{ marginTop: '30px' }}
          disabled={tcinChipsArray.length === 0}
        >
          CLEAR
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '30px', marginLeft: '30px' }}
          disabled={tcinChipsArray.length === 0}
          onClick={() => addTcinToPublisApi('Add')}
        >
          ADD
        </Button>
        {editModeSizeChartTcinList && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '30px', marginLeft: '30px' }}
            disabled={tcinChipsArray.length === 0}
            onClick={() => addTcinToPublisApi('Delete')}
          >
            Delete Tcin List
          </Button>
        )}
      </div>
      {addTcinToApi && addTcinToApi.length > 0 ? (
        <div className={classes.alertBarTcinList}>
          {actionTypeForTcin === 'Delete' ? (
            <div>
              {addTcinToApi.length} TCINs removed from the Size chart. Please
              proceed to confirm
            </div>
          ) : (
            <div>
              {addTcinToApi.length} TCINs are added to the list. Please proceed
              to attach the size chart
            </div>
          )}
        </div>
      ) : null}
    </Paper>
  )
}
export default withStyles(styles)(PasteTcinTextArea)
