export const NEW_FILTER_EVENT = 'NEW_FILTER_EVENT'
export const BUILD_FILTER_EVENT = 'BUILD_FILTER_EVENT'
export const REQUEST_COPY_DATA = 'REQUEST_COPY_DATA'
export const RECEIVE_COPY_DATA = 'RECEIVE_COPY_DATA'
export const LONG_COPY_RESET_FETCH = 'LONG_COPY_RESET_FETCH'
export const CHANGE_FILTER_EVENT = 'CHANGE_FILTER_EVENT'
export const SELECT_FILTER_VALUE_EVENT = 'SELECT_FILTER_VALUE_EVENT'
export const CHANGE_DEFAULT_PAGE_SIZE_LONG_COPY =
  'CHANGE_DEFAULT_PAGE_SIZE_LONG_COPY'
export const CHANGE_CURRENT_PAGE_LONG_COPY = 'CHANGE_CURRENT_PAGE_LONG_COPY'
export const SELECT_COPY_EVENT = 'SELECT_COPY_EVENT'
export const REMOVE_FILTER_EVENT = 'REMOVE_FILTER_EVENT'
export const CLEAR_COPY_DATA_EVENT = 'CLEAR_COPY_DATA_EVENT'
export const VIEW_COPY_DETAIL_EVENT = 'VIEW_COPY_DETAIL_EVENT'
export const REQUEST_DRAFT_COPY_EVENT = 'REQUEST_DRAFT_COPY_EVENT'
export const REQUEST_FIRST_DRAFT_COPY_EVENT = 'REQUEST_FIRST_DRAFT_COPY_EVENT'
export const REQUEST_SAVE_COPY_EVENT = 'REQUEST_SAVE_COPY_EVENT'
export const REQUEST_PUBLISH_COPY_EVENT = 'REQUEST_PUBLISH_COPY_EVENT'
export const REQUEST_ITEM_DATA = 'REQUEST_ITEM_DATA'
export const RECEIVE_ITEM_DATA = 'RECEIVE_ITEM_DATA'
export const REQUEST_DEPARTMENT = 'REQUEST_DEPARTMENT'
export const RECIEVE_DEPARTMENT = 'RECIEVE_DEPARTMENT'
export const TOGGLE_ACTION_DRAWER = 'TOGGLE_ACTION_DRAWER'
export const SET_ROUTE_TEAM = 'SET_ROUTE_TEAM'
export const SET_IS_FETCHING_WRITERS = 'SET_IS_FETCHING_WRITERS'
export const SET_ASSIGNED_WRITERS_LIST = 'SET_ASSIGNED_WRITERS_LIST'
export const SET_ASSIGNED_WRITER = 'SET_ASSIGNED_WRITER'
export const REQUEST_ASSIGN_COPY = 'REQUEST_ASSIGN_COPY'
export const SUCCESS_ASSIGN_COPY = 'SUCCESS_ASSIGN_COPY'
export const ADD_PLANNER_NOTES = 'ADD_PLANNER_NOTES'
export const REQUEST_SET_ROUTE_TEAM = 'REQUEST_SET_ROUTE_TEAM'
export const SUCCESS_SET_ROUTE_TEAM = 'SUCCESS_SET_ROUTE_TEAM'
export const REQUEST_SAVE_PLANNER_NOTES = 'REQUEST_SAVE_PLANNER_NOTES'
export const SUCCESS_SAVE_PLANNER_NOTES = 'SUCCESS_SAVE_PLANNER_NOTES'
export const SUCCESS_SAVE_PLANNER_NOTES_EDIT_PAGE =
  'SUCCESS_SAVE_PLANNER_NOTES_EDIT_PAGE'
export const UPDATE_COPY_LIST = 'UPDATE_COPY_LIST'
export const REQUEST_COPY_DETAIL_EVENT = 'REQUEST_COPY_DETAIL_EVENT'
export const UPDATE_SELECTED = 'UPDATE_SELECTED'
export const UPDATE_CURRENT_IMAGES = 'UPDATE_CURRENT_IMAGES'
export const UPDATE_CURRENT_IMAGE_SELECTED = 'UPDATE_CURRENT_IMAGE_SELECTED'
export const UPDATE_EDIT_FEATURE_BULLET_STATE =
  'UPDATE_EDIT_FEATURE_BULLET_STATE'
export const UPDATE_EDIT_FEATURE_BULLET = 'UPDATE_EDIT_FEATURE_BULLET'
export const UPDATE_EDIT_LONG_COPY_STATE = 'UPDATE_EDIT_LONG_COPY_STATE'
export const UPDATE_EDIT_LONG_COPY = 'UPDATE_EDIT_LONG_COPY'
export const ENTERED_EVENT = 'ENTERED_EVENT'
export const DOWNLOAD_START_LONG_COPY = 'DOWNLOAD_START_LONG_COPY'
export const DOWNLOAD_FINISH_LONG_COPY = 'DOWNLOAD_FINISH_LONG_COPY'
export const STICKER_DATA_CALLED = 'STICKER_DATA_CALLED'
export const STICKER_DATA_SUCCESS = 'STICKER_DATA_SUCCESS'
export const UPDATE_SELECTED_STICKER = 'UPDATE_SELECTED_STICKER'
export const CLEAR_SELECTED_STICKER = 'CLEAR_SELECTED_STICKER'
export const DELETE_COPY_BULLET_CLICKED = 'DELETE_COPY_BULLET_CLICKED'
export const CANCEL_COPY_BULLET_CLICKED = 'CANCEL_COPY_BULLET_CLICKED'
export const DELETE_OPTION_VALUE = 'DELETE_OPTION_VALUE'
export const CONFIRMATION_DELETE = 'CONFIRMATION_DELETE'
export const DELETE_BACT_TO_LIST = 'DELETE_BACT_TO_LIST'
export const CANCEL_QUICK_PUBLISH = 'CANCEL_QUICK_PUBLISH'
export const HISTORY_INDEX_UPDATE = 'HISTORY_INDEX_UPDATE'
export const TAB_INDEX_UPDATE = 'TAB_INDEX_UPDATE'
export const BUILD_FILTER_EXPAND = 'BUILD_FILTER_EXPAND'
export const REQUEST_MODAL_META_DATA = 'REQUEST_MODAL_META_DATA'
export const RECIEVE_MODAL_META_DATA = 'RECIEVE_MODAL_META_DATA'
export const SET_SORT_FIELD = 'SET_SORT_FIELD'
export const SET_SORT_DIRECTION = 'SET_SORT_DIRECTION'
export const SHOW_DEFAULT_ALERT_LONG_COPY = 'SHOW_DEFAULT_ALERT_LONG_COPY'
export const STORE_LONG_COPY_STRAPI_DATA = 'STORE_LONG_COPY_STRAPI_DATA'
export const STORE_SWATCH_DATA = 'STORE_SWATCH_DATA'
export const ERROR_SEARCHING = 'ERROR_SEARCHING'
export const RECEIVE_CLAIMS_DATA = 'RECEIVE_CLAIMS_DATA'
export const RECEIVE_CLAIMS_ERROR = 'RECEIVE_CLAIMS_ERROR'
export const UPDATE_PROFANITY_FEATURE_BULLETS =
  'UPDATE_PROFANITY_FEATURE_BULLETS'
export const UPDATE_SINGLE_EDIT_LONG_COPY = 'UPDATE_SINGLE_EDIT_LONG_COPY'
export const UPDATE_PROFANITY_LONG_COPY_API = 'UPDATE_PROFANITY_LONG_COPY_API'
export const PROFANITY_API_LOADER = 'PROFANITY_API_LOADER'
export const SUBJECTIVE_CLAIMS_POPUP = 'SUBJECTIVE_CLAIMS_POPUP'
