//

import * as React from 'react'
import ListItem from '@material-ui/core/ListItem'
import { NavLink } from 'react-router-dom'

import Text from './Text'
import Icon from './Icon'

/**
 * Check if a string begins with `//`, `http://`, or `https://`
 * https://www.debuggex.com/r/YOT4v3GB0b677efd
 *
 * @param  {String} pathStr - URL to test
 * @return {Boolean}
 */
export function isRelativePath(pathStr) {
  return pathStr && !/^(\/\/|https?:\/\/)/i.test(pathStr)
}

/**
 * Given a list of any React elements, return the first one that
 * matches the type passed in
 *
 * @param  {NodeList} components - list of components to search through
 * @param  {Object} type - React component
 * @return {Object|null}
 */
export function getFirstOfType(children, type) {
  let res = null

  React.Children.forEach(children, (child) => {
    if (child && child.type === type && res === null) {
      res = child
    }
  })

  return res
}

export function Link(props) {
  const {
    activeClassName,
    classes,
    children,
    exact,
    onClose,
    target,
    to,
    ...remainingProps
  } = props

  const firstIconChild = getFirstOfType(children, Icon)
  const firstTextChild = getFirstOfType(children, Text)
  const iconChild = !!firstIconChild && firstIconChild
  const textChild = !!firstTextChild && firstTextChild

  /**
   * The Praxis `SideNav` component should support multiple
   * types of linking.
   *
   * In order to do so, `Link` extends `react-router-dom`s
   * `to` prop. It accepts either:
   *   - a string: https://reacttraining.com/react-router/web/api/Link/to-string
   *   - or an object: https://reacttraining.com/react-router/web/api/Link/to-object
   *
   * In addition to these two types, we also add the option
   * of adding external "absolute" links.
   *
   * When an external link is used, a traditional anchor
   * tag is rendered, so `to` *must* be a string.
   *
   * Acceptable types:
   *   | example                             |   component   |
   *   |-------------------------------------|---------------|
   *   | `"/1"`                              | `<NavLink />` |
   *   | { pathname: '/1', search: '?test' } | `<NavLink />` |
   *   | `http://target.com`                 | `<a />`       |
   */
  const spreadProps =
    typeof to === 'string' && !isRelativePath(to)
      ? {
          component: (muiProps) => (
            <a {...muiProps}>
              {iconChild}
              {textChild}
            </a>
          ),
          href: to,
          target,
        }
      : {
          component: React.forwardRef((muiProps, ref) => (
            <div ref={ref}>
              <NavLink
                activeClassName={activeClassName}
                exact={exact}
                to={to}
                onClick={() => onClose()}
                {...muiProps}
              >
                {iconChild}
                {textChild}
              </NavLink>
            </div>
          )),
        }

  return iconChild || textChild ? (
    <ListItem
      classes={classes}
      role="menuitem"
      {...spreadProps}
      {...remainingProps}
    />
  ) : null
}

Link.defaultProps = {
  activeClassName: '',
  classes: {},
  exact: false,
  onClose: () => {},
  target: '_blank',
}

// export default React.forwardRef((Link
export default Link
