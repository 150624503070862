import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import {
  REQUEST_IMAGE_REVIEW_HISTORY_DATA,
  RECIEVE_IMAGE_REVIEW_HISTORY_DATA,
  IMAGE_REVIEW_HISTORY_RESET_FETCH,
  ERROR_SEARCHING_IMAGE_REVIEW_HISTORY,
  CLEAR_REVIEW_HISTORY_DATA,
  SELECT_IMAGE_REVIEW_HISTORY_EVENT,
  STORE_IMAGE_REVIEW_HISTORY_STRAPI_DATA,
  SHOW_DEFAULT_ALERT_IMAGE_REVIEW_HISTORY,
} from './actionType'
import { isEmpty } from 'lodash'

export function requestImageReviewHistoryData(tcins) {
  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(getReviewHistoryStart())
    return axios
      .post(envConfigs.api.externalApi + 'review/image/history', {
        tcins: tcins,
      })
      .then((res) => {
        dispatch(getReviewHistorySuccess(res.data))
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

export function resetFetch(data) {
  return {
    type: IMAGE_REVIEW_HISTORY_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING_IMAGE_REVIEW_HISTORY,
    payload: payload,
  }
}

function getReviewHistoryStart() {
  return {
    type: REQUEST_IMAGE_REVIEW_HISTORY_DATA,
    payload: {
      isFetching: true,
    },
  }
}

export function clearReviewHistoryData() {
  return {
    type: CLEAR_REVIEW_HISTORY_DATA,
  }
}

function getReviewHistorySuccess(data) {
  return {
    type: RECIEVE_IMAGE_REVIEW_HISTORY_DATA,
    payload: {
      reviewHistoryData: data,
      isFetching: false,
    },
  }
}

export function handleSelectImage(newSelected) {
  return {
    type: SELECT_IMAGE_REVIEW_HISTORY_EVENT,
    payload: {
      selectedReviewHistoryImages: newSelected,
    },
  }
}

export function getImageReviewHistoryStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Image%20Review%20History&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data)
          ? dispatch(showDefaultAlert(true))
          : dispatch(storeImageReviewHistoryStrapiData(response.data))
        dispatch(showDefaultAlert(false))
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function storeImageReviewHistoryStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const errorData = data.filter(
    (item) => item.interface_document_type === 'error'
  )
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  const error =
    errorData.length > 0
      ? {
          title: errorData[0].user_facing_title,
          body: errorData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_IMAGE_REVIEW_HISTORY_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
      error,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_IMAGE_REVIEW_HISTORY,
    payload: data,
  }
}
