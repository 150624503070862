import {
  IMAGE_SEARCH_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_SEARCH,
  STORE_IMAGE_SEARCH_STRAPI_DATA,
  REMOVE_FILTER_EVENT_IMAGE_SEARCH,
  BUILD_FILTER_EXPAND_IMAGE_SEARCH,
  NEW_FILTER_EVENT_IMAGE_SEARCH,
  BUILD_FILTER_EVENT_IMAGE_SEARCH,
  CLEAR_IMAGE_SEARCH_DATA_EVENT,
  ERROR_SEARCHING_IMAGE_SEARCH,
  REQUEST_IMAGE_SEARCH_DATA,
  RECEIVE_IMAGE_SEARCH_DATA,
  CHANGE_CURRENT_PAGE_IMAGE_SEARCH,
  CHANGE_FILTER_EVENT_IMAGE_SEARCH,
  SELECT_FILTER_VALUE_EVENT_IMAGE_SEARCH,
  CHANGE_DEFAULT_PAGE_SIZE_IMAGE_SEARCH,
  SELECT_ITEM_SEARCH_EVENT,
  SET_SORT_FIELD_IMAGE_SEARCH,
  SET_SORT_DIRECTION_IMAGE_SEARCH,
  RECEIVE_DEPT_CLASS_LIST_IMAGE_SEARCH,
} from './actionType'

export const initState = {
  isFetching: false,
  showDefaultAlert: false,
  filterValues: {
    dateRange: -1,
    fromDate: -1,
    toDate: -1,
    deptClass: {
      dept: -1,
      class: -1,
    },
    weeks: {
      startDate: null,
      endDate: null,
    },
    approvalStatus: -1,
    tcins: [],
    vendor: [],
    dpcis: [],
    itemSetupStatus: -1,
  },
  defaultFilterValues: {
    dateRange: -1,
    fromDate: -1,
    toDate: -1,
    deptClass: {
      dept: -1,
      class: -1,
    },
    approvalStatus: -1,
    itemSetupStatus: -1,
    tcins: [],
    vendor: [],
    dpcis: [],
    weeks: {
      startDate: null,
      endDate: null,
    },
  },
  // DO NOT CHANGE THE PAGENAME!
  pageName: 'ImageSearch',
  selectedFilter: '',
  rhData: [],
  originalRhData: [],
  errorSearching: false,
  selectedItems: [],
  totalPages: 0,
  totalElements: 0,
  defaultPageSize: 10,
  currentPage: 0,
  selectedFilters: [],
  dataContainerShown: false,
  newFilterContainerShown: false,
  buildFilterContainerShown: false,
  buildFilterExpansionPanel: false,
  alert: null,
  information: null,
  faq: null,
  error: null,
  sortDirection: 'asc',
  sortField: 'TCIN',
  vendorList: [],
  deptClassList: [],
}

export default function imageSearchReducer(state = initState, action = {}) {
  switch (action.type) {
    case IMAGE_SEARCH_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case STORE_IMAGE_SEARCH_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
        error: payload.error,
      }
      return newState
    }
    case BUILD_FILTER_EXPAND_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterExpansionPanel: payload.buildFilterExpansionPanel,
      }
      return newState
    }
    case NEW_FILTER_EVENT_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterContainerShown: payload,
      }
      return newState
    }
    case BUILD_FILTER_EVENT_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        dataContainerShown: payload.dataContainerShown,
        selectedFilters: payload.selectedFilters,
        // Resetting the value after adding
        filterValues: initState.filterValues,
      }
      return newState
    }
    case REMOVE_FILTER_EVENT_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        dataContainerShown: payload.dataContainerShown,
        selectedFilters: payload.selectedFilters,
      }
      return newState
    }
    case RECEIVE_IMAGE_SEARCH_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        rhData: payload.rhData,
        originalRhData: payload.rhData,
        isFetching: payload.isFetching,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
      }
      return newState
    }
    case REQUEST_IMAGE_SEARCH_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        rhData: payload.rhData,
        isFetching: payload.isFetching,
        selectedItems: payload.selectedItems,
      }
      return newState
    }
    case ERROR_SEARCHING_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    case CLEAR_IMAGE_SEARCH_DATA_EVENT: {
      const newState = {
        ...state,
        rhData: initState.rhData,
      }
      return newState
    }
    case CHANGE_FILTER_EVENT_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        selectedFilter: payload.selectedFilter,
        filterValues: initState.filterValues,
      }
      return newState
    }
    case SELECT_FILTER_VALUE_EVENT_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        filterValues: payload.filterValues,
      }
      return newState
    }
    case CHANGE_CURRENT_PAGE_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        currentPage: payload.currentPage,
      }
      return newState
    }
    case CHANGE_DEFAULT_PAGE_SIZE_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        defaultPageSize: payload.defaultPageSize,
      }
      return newState
    }
    case SELECT_ITEM_SEARCH_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedItems: payload.selectedItems,
      }
      return newState
    }
    case SET_SORT_FIELD_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        sortField: payload,
      }
      return newState
    }
    case SET_SORT_DIRECTION_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        sortDirection: payload,
      }
      return newState
    }
    case RECEIVE_DEPT_CLASS_LIST_IMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        deptClassList: payload.deptClassList,
      }
      return newState
    }
    default:
      return state
  }
}
