import React from 'react'
import { Stepper, Step, StepLabel, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import CreateNewTagFormContainer from '../CreateNewTagForm/CreateNewTagFormContainer'
import SelectExampleImageStepperContainer from '../SelectExampleImageStepper/SelectExampleImageStepperContainer'

function CreateNewTagStepper(props) {
  const { createNewTagOverall } = props

  return (
    <React.Fragment>
      <Paper className={props.classes.marginTop25}>
        <Stepper
          className={props.classes.paddingTop50}
          activeStep={createNewTagOverall.activeStep}
        >
          {createNewTagOverall.steps.map((step, index) => {
            return (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Paper>
      <Paper className={props.classes.marginTop25}>
        <CreateNewTagFormContainer />
      </Paper>
      {createNewTagOverall.activeStep === 1 && (
        <Paper className={props.classes.marginTop25}>
          <SelectExampleImageStepperContainer />
        </Paper>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(CreateNewTagStepper)
