export const SET_IMAGE_URLS = 'SET_IMAGE_URLS'
export const SET_MODELS = 'SET_MODELS'
export const SET_IMAGE_FETCHING = 'SET_IMAGE_FETCHING'
export const SET_TRAINING_IN_PROGRESS = 'SET_TRAINING_IN_PROGRESS'
export const IMAGE_TAG_TRAINING_RESET_FETCH = 'IMAGE_TAG_TRAINING_RESET_FETCH'
export const SET_TAG_GROUPS = 'SET_TAG_GROUPS'
export const SET_TAG_GROUPS_FILTER_TEXT = 'SET_TAG_GROUPS_FILTER_TEXT'
export const SET_FILTERED_TAG_GROUPS = 'SET_FILTERED_TAG_GROUPS'
export const RESET_EDITED_TAG = 'RESET_EDITED_TAG'
export const UPDATE_TAG_DESCRIPTION = 'UPDATE_TAG_DESCRIPTION'
export const UPDATE_TAG_EDIT_STATE = 'UPDATE_TAG_EDIT_STATE'
export const UPDATE_TAG_IS_EDITED = 'UPDATE_TAG_IS_EDITED'
export const SET_TAG_STEPPER_OVERALL_ACTIVE_STEP =
  'SET_TAG_STEPPER_OVERALL_ACTIVE_STEP'
export const SET_TAG_SELECTION_ACTIVE_STEP = 'SET_TAG_SELECTION_ACTIVE_STEP'
export const SET_TAGS_BY_TYPE_MAP = 'SET_TAGS_BY_TYPE_MAP'
export const SET_CREATE_TAG_IMAGE_URL = 'SET_CREATE_TAG_IMAGE_URL'
export const SET_CREATE_NEW_TAG_SYNC_ERROR = 'SET_CREATE_NEW_TAG_SYNC_ERRORS'
export const INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT =
  'INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT'
export const ADD_IMAGE_TO_APPROVED_REVIEW_LIST =
  'ADD_IMAGE_TO_APPROVED_REVIEW_LIST'
export const REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST =
  'REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST'
export const SET_TAG_FORM_TYPE_DESCRIPTION = 'SET_TAG_FORM_TYPE_DESCRIPTION'
export const SET_TAG_FORM_TYPE_DESCRIPTION_DISABLED_STATE =
  'SET_TAG_FORM_TYPE_DESCRIPTION_DISABLED_STATE'
export const SET_TAG_FORM_TAG_DESCRIPTION = 'SET_TAG_FORM_TAG_DESCRIPTION'
export const SET_TAG_FORM_TAG_LABEL = 'SET_TAG_FORM_TAG_LABEL'
export const SET_TAG_FORM_TYPE = 'SET_TAG_FORM_TYPE'
export const RESET_CREATE_TAG = 'RESET_CREATE_TAG'
export const EXAMPLE_IMAGE_SELECTED = 'EXAMPLE_IMAGE_SELECTED'
export const REQUEST_EXAMPLE_TCIN_IMAGE = 'REQUEST_EXAMPLE_TCIN_IMAGE'
export const SET_EXAMPLE_IMAGE_LIST = 'SET_EXAMPLE_IMAGE_LIST'
export const SET_EXAMPLE_IMAGE_TCIN_LIST = 'SET_EXAMPLE_IMAGE_TCIN_LIST'
export const SET_CLASSIFIER_ACTIVE_STEP = 'SET_CLASSIFIER_ACTIVE_STEP'
export const START_OVER_SELECT_EXAMPLE_IMAGE = 'START_OVER_SELECT_EXAMPLE_IMAGE'
export const REQUEST_CLASSIFIER_TCIN_IMAGE = 'REQUEST_CLASSIFIER_TCIN_IMAGE'
export const SET_CLASSIFIER_IMAGE_LIST = 'SET_CLASSIFIER_IMAGE_LIST'
export const SET_CLASSIFIER_IMAGE_TCIN_LIST = 'SET_CLASSIFIER_IMAGE_TCIN_LIST'
export const CLASSIFIER_IMAGE_SELECTED = 'CLASSIFIER_IMAGE_SELECTED'
export const TAG_DATA_SELECTION = 'TAG_DATA_SELECTION'
export const REQUEST_SIMILAR_IMAGE_CLASSIFIER =
  'REQUEST_SIMILAR_IMAGE_CLASSIFIER'
export const RECEIVE_SIMILAR_IMAGE_CLASSIFIER =
  'RECEIVE_SIMILAR_IMAGE_CLASSIFIER'
export const MAX_SIMILAR_IMAGE_COUNT_CLASSIFIER =
  'MAX_SIMILAR_IMAGE_COUNT_CLASSIFIER'
export const RESET_CLASSIFIER_SELECTION = 'RESET_CLASSIFIER_SELECTION'
export const ADD_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER =
  'ADD_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER'
export const INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT_CLASSIFIER =
  'INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT_CLASSIFIER'
export const REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER =
  'REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER'
export const TOGGLE_SELECT_IMAGE_FROM_TRAINING_GRID =
  'TOGGLE_SELECT_IMAGE_FROM_TRAINING_GRID'
export const CLEAR_SELECTED_IMAGES_FROM_TRAINING_GRID =
  'CLEAR_SELECTED_IMAGES_FROM_TRAINING_GRID'
export const REMOVE_IMAGE_FROM_MODEL_GRID = 'REMOVE_IMAGE_FROM_MODEL_GRID'
export const SET_DELETE_CONFIRMATION_OPEN = 'SET_DELETE_CONFIRMATION_OPEN'
export const POPULATE_CLASSIFIER_DETAILS = 'POPULATE_CLASSIFIER_DETAILS'
export const TOGGLE_CLASSIFIER_APPROVED_SELECT =
  'TOGGLE_CLASSIFIER_APPROVED_SELECT'
export const SET_DELETE_CONFIRMATION_OPEN_CLASSIFIER =
  'SET_DELETE_CONFIRMATION_OPEN_CLASSIFIER'
export const REMOVE_IMAGE_FROM_CLASSIFIER_APPROVED_IMAGES =
  'REMOVE_IMAGE_FROM_CLASSIFIER_APPROVED_IMAGES'
export const CLEAR_SELECTED_IMAGES_FROM_CLASSIFIER_APPROVED_LIST =
  'CLEAR_SELECTED_IMAGES_FROM_CLASSIFIER_APPROVED_LIST'
export const DELETING_FROM_CLASSIFIER_IMAGEGRID_STATUS =
  'DELETING_FROM_CLASSIFIER_IMAGEGRID_STATUS'
export const DELETING_FROM_TRAINING_IMAGEGRID_STATUS =
  'DELETING_FROM_TRAINING_IMAGEGRID_STATUS'
