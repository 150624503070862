import ImageToolbarActionDrawer from './ImageToolbarActionDrawer'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  toggleActionDrawer,
  setRejectionReason,
  handleChangeRejectionMessage,
  setApprovalOption,
  reviewImages,
} from '../../../../../store/images/review/actionCreator'

function ImageToolbarActionDrawerContainer(props) {
  const toggleActionDrawer = (isActionDrawerOpen) => {
    props.toggleActionDrawer(isActionDrawerOpen)
  }

  const closeEscapeKey = (event, _) => {
    if (event.keyCode === 27) {
      toggleActionDrawer(false)
    }
  }

  const setApprovalOption = (approvalOption) => {
    props.setApprovalOption(approvalOption)
  }

  const setRejectionReason = (rejectionReason) => {
    props.setRejectionReason(rejectionReason)
  }

  const handleChangeRejectionMessage = (message) => {
    props.handleChangeRejectionMessage(message)
  }

  let reviewTcins = props.isReprocess
    ? props.reviewHistoryData.map((item) => item.tcin)
    : props.reviewData.map((item) => item.tcin)

  const buildSaveContract = (selectedTcins, selectedImages) => {
    return selectedTcins.map((tcin) => {
      return {
        tcin,
        image_list: selectedImages
          .filter((image) => image.tcin === tcin)
          .map((image) => image.image),
      }
    })
  }

  const handleApproveImageClickAction = () => {
    if (currentApprovalOption && currentApprovalOption === 'Replace') {
      props.reviewImages(
        buildSaveContract(
          props.selectedTcins,
          props.isReprocess
            ? props.selectedReviewHistoryImages
            : props.selectedReviewImages
        ),
        'Approved',
        true,
        null,
        props.auth,
        reviewTcins,
        props.isReprocess
      )
    } else {
      props.reviewImages(
        buildSaveContract(
          props.selectedTcins,
          props.isReprocess
            ? props.selectedReviewHistoryImages
            : props.selectedReviewImages
        ),
        'Approved',
        false,
        null,
        props.auth,
        reviewTcins,
        props.isReprocess
      )
    }
  }

  const handleSendToRetouchImageClickAction = () => {
    props.reviewImages(
      buildSaveContract(
        props.selectedTcins,
        props.isReprocess
          ? props.selectedReviewHistoryImages
          : props.selectedReviewImages
      ),
      'Retouch',
      true,
      null,
      props.auth,
      reviewTcins,
      props.isReprocess
    )
  }

  const rejectionReason = () => {
    if (currentRejectionReason && currentRejectionReason === 'Other') {
      return rejectionMessage
    } else if (rejectionMessage !== '') {
      return currentRejectionReason + ' - ' + rejectionMessage
    } else {
      return currentRejectionReason
    }
  }

  const handleRejectImageClickAction = () => {
    props.reviewImages(
      buildSaveContract(
        props.selectedTcins,
        props.isReprocess
          ? props.selectedReviewHistoryImages
          : props.selectedReviewImages
      ),
      'Rejected',
      true,
      rejectionReason(),
      props.auth,
      reviewTcins,
      props.isReprocess
    )
  }

  const {
    isActionDrawerOpen,
    currentRejectionReason,
    rejectionMessage,
    currentApprovalOption,
    drawerAction,
    retouchLoader,
  } = props
  return (
    <ImageToolbarActionDrawer
      toggleActionDrawer={toggleActionDrawer}
      closeEscapeKey={closeEscapeKey}
      isActionDrawerOpen={isActionDrawerOpen}
      setRejectionReason={setRejectionReason}
      currentRejectionReason={currentRejectionReason}
      handleChangeRejectionMessage={handleChangeRejectionMessage}
      handleRejectImageEvent={handleRejectImageClickAction}
      numSelected={props.numSelected}
      drawerAction={drawerAction}
      rejectionMessage={rejectionMessage}
      currentApprovalOption={currentApprovalOption}
      setApprovalOption={setApprovalOption}
      handleApprovalEvent={handleApproveImageClickAction}
      handleRetouchEvent={handleSendToRetouchImageClickAction}
      retouchLoader={retouchLoader}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleActionDrawer,
      setRejectionReason,
      handleChangeRejectionMessage,
      setApprovalOption,
      reviewImages,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, imageReview, imageReviewHistory } = state
  const {
    isActionDrawerOpen,
    currentRejectionReason,
    rejectionMessage,
    currentApprovalOption,
    drawerAction,
    reviewData,
    selectedReviewImages,
    retouchLoader,
  } = imageReview
  const { reviewHistoryData, selectedReviewHistoryImages } = imageReviewHistory
  return {
    isActionDrawerOpen,
    currentRejectionReason,
    rejectionMessage,
    currentApprovalOption,
    drawerAction,
    reviewData,
    selectedReviewImages,
    auth,
    reviewHistoryData,
    selectedReviewHistoryImages,
    retouchLoader,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageToolbarActionDrawerContainer)
