import React from 'react'
import HeaderTitle from '../../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import ClassificationPageContainer from './Components/ClassificationPage/ClassificationPageContainer'

class Classifier extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }
  render() {
    return (
      <React.Fragment>
        <HeaderTitle title="Classification" />
        <Helmet title="Classification" />
        <ClassificationPageContainer />
      </React.Fragment>
    )
  }
}

export default Classifier
