import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Button, Grid, CircularProgress } from '@material-ui/core'
import ErrorDialog from '../../../Shared/ErrorDialog/ErrorDialog'

function BadgeImageUpload(props) {
  const {
    invalidFileError = false,
    removeInvalidFile = () => {},
    invalidFiles = [],
    clearData = () => {},
    classes = {},
    sendToTarget = () => {},
    uploadInProgress = false,
    uploadData = null,
    roundPercent = () => {},
  } = props

  return (
    <React.Fragment>
      <Grid container justify="center" direction="column" alignItems="center">
        {uploadInProgress && uploadData && (
          <Grid item xs={12}>
            {uploadData.map((data, index) => {
              let percent = roundPercent(data.percent)
              return (
                <div key={index} className={classes.wrapper}>
                  <p>{percent}%</p>
                  <CircularProgress
                    className={classes.fabProgress}
                    variant="static"
                    value={percent}
                    size={68}
                  />
                </div>
              )
            })}
          </Grid>
        )}
        <Grid item xs={12} direction="row" container>
          <Grid
            item
            xs={6}
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.margin20}
              onClick={clearData}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              className={classes.margin20}
              disabled={uploadData === null || uploadData.length < 1}
              onClick={sendToTarget}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {invalidFileError && (
        <ErrorDialog
          invalidFileError={invalidFileError}
          removeInvalidFile={() => removeInvalidFile()}
          invalidFiles={invalidFiles}
        />
      )}
    </React.Fragment>
  )
}
export default withStyles(styles)(BadgeImageUpload)
