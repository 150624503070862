import React from 'react'
import HeaderTitle from '../../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import TagsPageContainer from './Components/TagsPage/TagsPageContainer'

class Tags extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <React.Fragment>
        <HeaderTitle title="Tags" />
        <Helmet title="Tags" />
        <TagsPageContainer />
      </React.Fragment>
    )
  }
}

export default Tags
