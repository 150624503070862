import CreateIcon from '@material-ui/icons/Create'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import styles from '../theme'

const ManageImageAction = (props) => {
  const { manageImageClickAction = () => {}, classes = {}, auth = {} } = props

  return (
    <Button className={classes.drawerButton} onClick={manageImageClickAction}>
      <CreateIcon />
      <span className={classes.marginLeft5}>
        {auth.permission.imgCurationEdit ? 'Manage' : 'View'} Live Images
      </span>
    </Button>
  )
}

// @ts-ignore
export default withStyles(styles)(ManageImageAction)
