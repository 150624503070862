import {
  SELECT_DATA_EVENT,
  REMOVE_DATA_EVENT,
  UPDATE_LONG_COPY,
  UPDATE_LONG_COPY_EDIT_STATE,
  UPDATE_FEATURE_BULLETS,
  UPDATE_FEATURE_BULLETS_EDIT_STATE,
  REVERT_FEATURE_BULLETS,
  REVERT_LONG_COPY,
  HANDLE_DELETE_CONFIRMATION,
  UPDATE_SELECTED_LONG_COPY,
  UPDATE_SELECTED_FEATURE_BULLETS,
} from './actionType'
import {
  UPDATE_PROFANITY_FEATURE_BULLETS,
  UPDATE_PROFANITY_LONG_COPY_API,
  PROFANITY_API_LOADER,
} from '../longCopy/actionType'
import { editFeatureBulletState } from '../longCopy/editLongCopyActionCreator'

import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { resetFetch } from './actionCreator'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../components/Shared/Constants'

export function handleSelectData(data) {
  return {
    type: SELECT_DATA_EVENT,
    payload: {
      selectedData: data.selectedData,
    },
  }
}

export function deleteDataEvent(data) {
  return (dispatch) => {
    dispatch(deleteDataEventSucess(data))
    dispatch(
      resetFetch(false),
      toast.success('Successfully Removed', TOAST_BODY)
    )
  }
}

function deleteDataEventSucess(data) {
  return {
    type: REMOVE_DATA_EVENT,
    payload: {
      uploadData: data.uploadData,
      selectedData: data.selectedData,
      defaultUploadData: data.defaultUploadData,
    },
  }
}

export function checkProfanity(tcin, longCopy, featureBullet, feature) {
  var requestBody = {
    long_copy: longCopy,
    feature_bullets: featureBullet,
    tcin: tcin,
  }
  return (dispatch) => {
    dispatch(profanityLoader(true))
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/profanity_check?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestBody
      )
      .then((res) => {
        if (feature === 'longCopy') {
          dispatch(updateLongCopy(tcin, res.data))
          dispatch(
            updateSelectedLongCopy(
              tcin,
              res.data.long_copy,
              res.data.valid,
              res.data.profane_category
            )
          )
          dispatch(profanityLoader(false))
        }
        if (feature === 'featureBullet') {
          dispatch(updateFeatureBullets(tcin, res.data))
          dispatch(profanityLoader(false))
          dispatch(
            updateSelectedFeatureBullets(
              tcin,
              res.data.feature_bullets,
              res.data.valid,
              res.data.profane_category
            )
          )
        }
        if (feature === 'revertLongCopy') {
          dispatch(
            revertBackLongCopy(
              tcin,
              res.data.long_copy,
              res.data.valid,
              res.data.profane_category
            )
          )
          dispatch(
            updateSelectedLongCopy(
              tcin,
              res.data.long_copy,
              res.data.valid,
              res.data.profane_category
            )
          )
          dispatch(profanityLoader(false))
        }
        if (feature === 'revertFeatureBullet') {
          dispatch(
            revertBackFeatureBullets(
              tcin,
              res.data.feature_bullets,
              res.data.valid,
              res.data.profane_category
            )
          )
          dispatch(
            updateSelectedFeatureBullets(
              tcin,
              res.data.feature_bullets,
              res.data.valid,
              res.data.profane_category
            )
          )
          dispatch(profanityLoader(false))
        }
        if (feature === 'singleEditFeatureBullet') {
          dispatch(
            singleEditFeatureBullet(
              res.data.feature_bullets,
              res.data.valid,
              res.data.profane_category,
              res.data.long_copy
            )
          )
          dispatch(editFeatureBulletState(false))
          dispatch(profanityLoader(false))
        }
        if (feature === 'singleEditLongCopy') {
          dispatch(
            singleEditLongCopy(
              res.data.long_copy,
              res.data.valid,
              res.data.profane_category,
              res.data.feature_bullets
            )
          )
          dispatch(profanityLoader(false))
        }
      })
      .catch((error, data) => {
        dispatch(profanityLoader(false))
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function profanityLoader(value) {
  return {
    type: PROFANITY_API_LOADER,
    payload: value,
  }
}

export function singleEditFeatureBullet(
  data,
  valid,
  profaneCategory,
  longCopy
) {
  return {
    type: UPDATE_PROFANITY_FEATURE_BULLETS,
    payload: {
      bullets: data,
      valid: valid,
      ltwoDisclaimer: profaneCategory,
      longCopy: longCopy,
    },
  }
}

export function singleEditLongCopy(data, valid, profaneCategory, bullets) {
  return {
    type: UPDATE_PROFANITY_LONG_COPY_API,
    payload: {
      longCopy: data,
      valid: valid,
      ltwoDisclaimer: profaneCategory,
      bullets: bullets,
    },
  }
}

export function updateLongCopy(tcin, data) {
  return {
    type: UPDATE_LONG_COPY,
    payload: {
      tcin: tcin,
      longCopy: data.long_copy,
      isLongCopyEditable: false,
      isLongCopyEdited: true,
      valid: data.valid,
      ltwoDisclaimer: data.profane_category,
    },
  }
}

export function updateSelectedLongCopy(tcin, data, valid, profaneCategory) {
  return {
    type: UPDATE_SELECTED_LONG_COPY,
    payload: {
      tcin: tcin,
      longCopy: data,
      valid: valid,
      ltwoDisclaimer: profaneCategory,
    },
  }
}

export function updateSelectedFeatureBullets(
  tcin,
  data,
  valid,
  profaneCategory
) {
  return {
    type: UPDATE_SELECTED_FEATURE_BULLETS,
    payload: {
      tcin: tcin,
      featureBullets: data,
      valid: valid,
      ltwoDisclaimer: profaneCategory,
    },
  }
}

export function changeToEditStateLongCopy(tcin, isLongCopyEditable) {
  return {
    type: UPDATE_LONG_COPY_EDIT_STATE,
    payload: {
      tcin: tcin,
      isLongCopyEditable: isLongCopyEditable,
    },
  }
}

export function updateFeatureBullets(tcin, data) {
  return {
    type: UPDATE_FEATURE_BULLETS,
    payload: {
      tcin: tcin,
      featureBullets: data.feature_bullets,
      isFeatureBulletsEditable: false,
      isFeatureBulletsEdited: true,
      valid: data.valid,
      ltwoDisclaimer: data.profane_category,
    },
  }
}

export function changeToEditStateFeatureBullets(
  tcin,
  isFeatureBulletsEditable
) {
  return {
    type: UPDATE_FEATURE_BULLETS_EDIT_STATE,
    payload: {
      tcin: tcin,
      isFeatureBulletsEditable: isFeatureBulletsEditable,
    },
  }
}

export function revertBackFeatureBullets(tcin, data, valid, profaneCategory) {
  return {
    type: REVERT_FEATURE_BULLETS,
    payload: {
      tcin: tcin,
      featureBullets: data,
      isFeatureBulletsEdited: false,
      valid: valid,
      ltwoDisclaimer: profaneCategory,
    },
  }
}

export function revertBackLongCopy(tcin, data, valid, profaneCategory) {
  return {
    type: REVERT_LONG_COPY,
    payload: {
      tcin: tcin,
      longCopy: data,
      isLongCopyEdited: false,
      valid: valid,
      ltwoDisclaimer: profaneCategory,
    },
  }
}
export function handleDeleteConfirmation(tcin) {
  return {
    type: HANDLE_DELETE_CONFIRMATION,
    payload: {
      tcin: tcin,
      confirmDelete: true,
    },
  }
}

export function cancelDeleteEvent(tcin) {
  return {
    type: HANDLE_DELETE_CONFIRMATION,
    payload: {
      tcin: tcin,
      confirmDelete: false,
    },
  }
}
