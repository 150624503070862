const styles = (theme) => ({
  table: {
    minWidth: '1020px',
  },
  progressBar: {
    marginLeft: '50%',
  },
  buildFilterExpansion: {
    width: '100%',
    margin: '0 0 5px 0',
  },
  marginBottom5: {
    marginBottom: '5px',
  },
  noResult: {
    textAlign: 'center',
    padding: '20px',
  },
  width100: {
    width: '100%',
  },
  imageAlignment: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'column',
  },
  totalElementsTitle: {
    color: '#CC0000',
    padding: '30px 5px',
    fontSize: '1.2em',
  },
  title: {
    flex: '0 0 auto',
  },
  marginRight20: {
    marginRight: '20px',
  },
  swatchImage: {
    height: '40px',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  primaryMissing: {
    display: 'flex',
    textAlign: 'center',
    color: 'red',
    border: '5px solid red',
    width: '70px',
    height: '70px',
    alignItems: 'center',
  },
  readyForReviewNotice: {
    height: '10px',
    width: '10px',
    minWidth: '10px',
    margin: '5px 5px 0 0',
    borderRadius: '100px',
    backgroundColor: '#f44336',
    display: 'inline-Block',
  },
  readyForReuseNotice: {
    height: '10px',
    width: '10px',
    minWidth: '10px',
    margin: '5px 5px 0 0',
    borderRadius: '100px',
    backgroundColor: '#6200ea',
    display: 'inline-Block',
  },
  flex: {
    display: 'flex',
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 250px)',
  },
  itemData: {
    width: '250px',
  },
  noExistingImage: {
    width: '150px',
    height: '150px',
  },
  lookCloserNoExistingImage: {
    height: '400px',
    width: '400px',
    backgroundColor: 'gray',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '24px',
  },
  keyCell: {
    width: '150px',
    textAlign: 'center',
    height: '25px',
  },
  height33: {
    height: '33px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  primaryCell: {
    width: '15%',
  },
  width5Per: {
    width: '5%',
  },
  statusText: {
    textAlign: 'center',
    fontSize: '1em',
    color: '#fff',
    textOrientation: 'upright',
    writingMode: 'vertical-lr',
    padding: '0 0 4px 2px',
  },
  blue: {
    backgroundColor: '#2196f3',
    margin: '4px 0 0 0',
  },
  grey: {
    backgroundColor: 'grey',
  },
  statusContainer: {
    display: 'flex',
    height: '158px',
    width: '30px',
  },
  approvalStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  statusApproved: {
    color: '#50ae55',
  },
  statusInProcess: {
    color: '#E1B401',
  },
  statusPending: {
    color: '#fe9d27',
  },
  statusRejected: {
    color: '#f40d1a',
  },
  maxWidth100Per: {
    maxWidth: '100%',
  },
  existingImageLookCloser: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  height49: {
    height: 49,
  },
  leftRightPadding10: {
    padding: '0 10px',
  },
  alternates: {
    maxHeight: '200px',
    overflowY: 'scroll',
  },
  newImageContainer: {
    border: '1px solid gray',
  },
  existingImagesContainer: {
    border: '1px solid gray',
    margin: '10px 0 0 0',
    padding: '10px',
  },
  marginBottom3: {
    marginBottom: '3px',
  },
  flexSpaceAround: {
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'column',
  },
  existingImages: {
    marginRight: '5px',
    marginBottom: '5px',
  },
  newImage: {
    maxHeight: '95%',
    maxWidth: '95%',
  },
  newImageParentContainer: {
    border: '1px solid gray',
    padding: '4px 0',
  },
  height64: {
    height: '64px',
  },
  toolbarSpacer: {
    height: '60px',
  },
  paddingLeft3: {
    paddingLeft: '3px',
  },
  spaceBetween: {
    alignItems: 'space-between',
  },
  disabled: {
    opacity: 0.25,
  },
  zoomin: {
    position: 'absolute',
    top: '30px',
    left: '0px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  displayNone: {
    display: 'none',
  },
  liveWrapper: {
    position: 'relative',
  },
  customBadgeColour: {
    backgroundColor: 'red',
  },
  imageBoxAlign: {
    width: '150px',
    height: '150px',
  },
  imageAlignTop: {
    marginTop: '25px',
  },
})

export default styles
