const styles = (theme) => ({
  formControl: {
    minWidth: 250,
    paddingBottom: '10px',
  },
  buttons: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '400px',
  },
  linkStyle: {
    margin: '0 5px',
    textDecoration: 'none',
  },
  paddingBottom10: {
    paddingBottom: '10px',
  },
  marginTop25: {
    marginTop: '25px',
  },
  paddingTop50: {
    paddingTop: '50px',
  },
  textFieldWidth: {
    width: '200px',
  },
  searchTcinButton: {
    margin: '10px 0 0 10px',
  },
  stepperIcon: {
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    '&$active': {
      color: 'rgba(56, 94, 166, 1)',
    },
    '&$completed': {
      color: 'rgba(56, 94, 166, 1)',
    },
  },
  disabled: {},
  active: {},
  completed: {},
  imageBorder: {
    border: '2px solid #3f51b5',
    margin: '5px',
  },
  imageWithoutBorder: {
    margin: '7px 19px',
  },
  selectedCheck: {
    color: '#3f51b5',
    position: 'relative',
    left: '-36px',
    top: '-142px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginLeft10: {
    marginLeft: '10px',
  },
  tagCards: {
    minWidth: '180px',
    padding: '10px',
    margin: '0px 15px',
    marginTop: '10px',
    maxHeight: '280px',
    overflowY: 'auto',
  },
})

export default styles
