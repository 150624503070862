const styles = (theme) => ({
  toolBar: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: '#CC0000',
    color: 'white',
    zIndex: 1000,
  },
  toolBarDrawerOpen: {
    backgroundColor: '#CC0000',
    color: 'white',
    zIndex: 1000,
  },
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: 'white',
  },
  drawerButton: {
    margin: '0px 0px 0px 5px',
    color: 'white',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  itemDetails: {
    minWidth: '200px',
  },
  actionIcons: {
    fontSize: '18px',
    cursor: 'pointer',
    padding: '5px',
  },
  disabledIcons: {
    fontSize: '18px',
    cursor: 'not-allowed',
    padding: '5px',
    color: 'gray',
  },
  margin16: {
    margin: '16px',
  },
  marginBottom20: {
    marginBottom: '20px',
  },
  marginTopBottom16: {
    margin: '16px 0',
  },
})

export default styles
