import {
  REQUEST_IMAGE_REVIEW_DATA,
  RECEIVE_IMAGE_REVIEW_DATA,
  CLEAR_REVIEW_DATA,
  SELECT_IMAGE_REVIEW_EVENT,
  TOGGLE_ACTION_DRAWER_IMAGE_REVIEW,
  SET_REJECTION_REASON,
  HANDLE_REJECTION_MESSAGE,
  SET_APPROVAL_REASON,
  IMAGE_REVIEW_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_REVIEW,
  ERROR_SEARCHING_IMAGE_REVIEW,
  STORE_IMAGE_REVIEW_STRAPI_DATA,
  RESET_ACTION_TOOLBAR,
  SET_LOOK_CLOSER_ITEM,
  RETOUCH_PROCESS_LOADER,
} from './actionType'

export const initState = {
  isFetching: false,
  showDefaultAlert: false,
  // DO NOT CHANGE THE PAGENAME!
  pageName: 'ImageReview',
  errorSearching: false,
  alert: null,
  information: null,
  faq: null,
  error: null,
  reviewData: [],
  selectedReviewImages: [],
  isActionDrawerOpen: false,
  currentRejectionReason: undefined,
  rejectionMessage: '',
  currentApprovalOption: undefined,
  drawerAction: null,
  lookCloserItem: null,
  retouchLoader: false,
}

export default function imageReviewReducer(state = initState, action = {}) {
  switch (action.type) {
    case IMAGE_REVIEW_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_IMAGE_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case STORE_IMAGE_REVIEW_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
        error: payload.error,
      }
      return newState
    }
    case ERROR_SEARCHING_IMAGE_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    case RETOUCH_PROCESS_LOADER: {
      const { payload } = action
      const newState = {
        ...state,
        retouchLoader: payload,
      }
      return newState
    }
    case REQUEST_IMAGE_REVIEW_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        reviewData: payload.reviewData,
        isFetching: payload.isFetching,
        selectedReviewImages: payload.selectedReviewImages,
      }
      return newState
    }
    case RECEIVE_IMAGE_REVIEW_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        reviewData: payload.reviewData,
        isFetching: payload.isFetching,
        selectedReviewImages: initState.selectedReviewImages,
      }
      return newState
    }
    case RESET_ACTION_TOOLBAR: {
      const newState = {
        ...state,
        isActionDrawerOpen: initState.isActionDrawerOpen,
        currentRejectionReason: initState.currentRejectionReason,
        rejectionMessage: initState.rejectionMessage,
      }
      return newState
    }
    case CLEAR_REVIEW_DATA: {
      const newState = {
        ...state,
        reviewData: initState.reviewData,
        selectedReviewImages: initState.selectedReviewImages,
      }
      return newState
    }
    case SELECT_IMAGE_REVIEW_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedReviewImages: payload.selectedReviewImages,
      }
      return newState
    }
    case TOGGLE_ACTION_DRAWER_IMAGE_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        isActionDrawerOpen: payload.isActionDrawerOpen,
        drawerAction: payload.drawerAction,
      }
      return newState
    }
    case SET_REJECTION_REASON: {
      const { payload } = action
      const newState = {
        ...state,
        currentRejectionReason: payload.currentRejectionReason,
      }
      return newState
    }
    case HANDLE_REJECTION_MESSAGE: {
      const { payload } = action
      const newState = {
        ...state,
        rejectionMessage: payload.rejectionMessage,
      }
      return newState
    }
    case SET_APPROVAL_REASON: {
      const { payload } = action
      const newState = {
        ...state,
        currentApprovalOption: payload.currentApprovalOption,
      }
      return newState
    }
    case SET_LOOK_CLOSER_ITEM: {
      const { payload } = action
      const newState = {
        ...state,
        lookCloserItem: payload.lookCloserItem,
      }
      return newState
    }
    default:
      return state
  }
}
