import { withStyles } from '@material-ui/core/styles'
import styles from '../../../theme'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'

function DialogBoxDeleteSelection(props) {
  const {
    classes,
    selectTypeCopyBulletDelete,
    deleteData,
    cancelCopyBulletDelete,
    handleValueDeleteOption,
  } = props
  return (
    <Dialog
      open={deleteData.showSelectedDeleteType}
      onClose={() => cancelCopyBulletDelete()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'xs'}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid
              container
              direction="column"
              item
              xs={12}
              className={classes.marginBottom10}
            >
              <span className={classes.deleteCopyFeatureTitle}>
                Delete copy / bullets?
              </span>
              <span className={classes.deleteCopyFeatureText}>
                Do you want to remove copy and/or feature bullets for the
                selected items?
              </span>
            </Grid>
            <RadioGroup
              aria-label="CopyFeatureBullet"
              name="CopyFeatureBullet"
              value={deleteData.selectDeleteType}
              onChange={handleValueDeleteOption}
            >
              <FormControlLabel
                value="COPY_ONLY"
                control={<Radio color="primary" />}
                label="COPY"
              />
              <FormControlLabel
                value="FEATURE_BULLETS_ONLY"
                control={<Radio color="primary" />}
                label="BULLETS"
              />
              <FormControlLabel
                value="COPY_AND_FEATURE_BULLETS"
                control={<Radio color="primary" />}
                label="BOTH"
              />
            </RadioGroup>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => cancelCopyBulletDelete()}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => selectTypeCopyBulletDelete()}
          variant="contained"
          color="primary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withStyles(styles)(DialogBoxDeleteSelection)
