import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import { TableCell, Tooltip } from '@material-ui/core'
import DragAndDropImage from './DragAndDropImage'
import { Droppable } from 'react-beautiful-dnd'
import { IMAGE_ROW_ID, IMAGE_VIEW_TYPE } from '../../../Shared/Constants'
import AddCircleIcon from '@material-ui/icons/AddCircle'

function ImageTableCells(props) {
  const {
    row = {},
    classes = {},
    isValidCopyTarget = false,
    pasteImage = () => {},
    auth = {},
    setZoom = () => {},
    item,
    rowIndex,
    getAssetLabel,
  } = props

  let noPrimaryImage =
    row.id === IMAGE_ROW_ID.PRIMARY && row.images.length === 0

  return (
    <TableCell
      data-test-id={
        row.id === IMAGE_ROW_ID.PRIMARY
          ? 'primary-cell'
          : row.id === IMAGE_ROW_ID.ALTERNATE
          ? 'alternate-cell'
          : 'disabled-cell'
      }
    >
      <div className={classes.horizontalFlex}>
        <Droppable droppableId={row.id} direction="horizontal">
          {(provided, snapshot) => (
            <span
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={
                snapshot.isDraggingOver && noPrimaryImage
                  ? `${classes.imageTableCellDraggedOver} ${classes.primaryMissingBorder}`
                  : snapshot.isDraggingOver
                  ? classes.imageTableCellDraggedOver
                  : classes.imageTableCell
              }
            >
              {noPrimaryImage && (
                <div
                  className={classes.primaryMissing}
                  data-test-id={'no-primary-image'}
                >
                  Primary Image Missing
                </div>
              )}
              {isValidCopyTarget &&
                row.id === IMAGE_ROW_ID.PRIMARY &&
                row.images.length === 0 && (
                  <span className={classes.pasteTarget}>
                    <Tooltip title={'Paste Image to TCIN as Primary'}>
                      <AddCircleIcon
                        data-test-id={'primary-paste-icon'}
                        onClick={() => {
                          pasteImage(IMAGE_VIEW_TYPE.PRIMARY)
                        }}
                        className={classes.pasteIcon}
                      />
                    </Tooltip>
                  </span>
                )}
              {row.images.length > 0 &&
                row.images.map((image, index) => {
                  return (
                    <DragAndDropImage
                      setZoom={setZoom}
                      auth={auth}
                      key={
                        image && image.publishUrl
                          ? image.publishUrl + index
                          : index
                      }
                      image={image}
                      index={index}
                      isDragDisabled={row.id === IMAGE_ROW_ID.PRIMARY}
                      setCopiedImage={props.setCopiedImage}
                      toggleEditImageLabelDialogue={
                        props.toggleEditImageLabelDialogue
                      }
                      isEditImageLabelOpen={props.isEditImageLabelOpen}
                      item={item}
                      rowIndex={rowIndex}
                      colIndex={index}
                      getAssetLabel={getAssetLabel}
                    />
                  )
                })}
              {provided.placeHolder}
              {isValidCopyTarget && row.id === IMAGE_ROW_ID.ALTERNATE && (
                <span className={classes.pasteTarget}>
                  <Tooltip title={'Paste Image to TCIN'}>
                    <AddCircleIcon
                      data-test-id={'paste-icon'}
                      className={classes.pasteIcon}
                      onClick={() => {
                        pasteImage(IMAGE_VIEW_TYPE.ALTERNATE)
                      }}
                    />
                  </Tooltip>
                </span>
              )}
            </span>
          )}
        </Droppable>
      </div>
    </TableCell>
  )
}

// @ts-ignore
export default withStyles(styles)(ImageTableCells)
