import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../../../Shared/WithRouterHoc'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
} from '@material-ui/core'
import styles from '../theme'
import SearchResult from './SearchResult'
import ImageToolbar from '../../Shared/Components/ImageToolbar'
import UploadHistoryAction from '../../Shared/Components/UploadHistoryAction'
import ManageImageAction from '../../Shared/Components/ManageImageAction'
import ReviewImageAction from '../../Shared/Components/ReviewImageAction'
import ReviewHistoryAction from '../../Shared/Components/ReviewHistoryAction'
import { flattenDeep } from 'lodash'
import { ROUTES } from '../../../Shared/Constants'

function SearchResultContainer(props) {
  const {
    searchData = [],
    classes = {},
    handleSelectItem = () => {},
    selectedItems = [],
    rowsPerPage = 0,
    auth = {},
    searchRelatedItems,
  } = props

  const isSelected = (checkedImage) =>
    selectedItems.indexOf(checkedImage) !== -1

  const handleUploadHistoryClickAction = () => {
    props.uploadHistoryClickAction(
      {
        newFilterContainerShown: true,
        copyDataContainerShown: true,
        selectedFilters: [],
        selectedFilter: { display: 'TCIN(s)', value: 'tcin' },
        selectedFilterValue: selectedItems.map((item) => ({
          display: item.tcin,
          value: item.tcin,
        })),
      },
      0,
      props.uploadHistoryPageSize,
      'asc',
      'TCIN',
      {
        dateRange: -1,
        tcins: selectedItems.map((item) => ({
          display: item.tcin,
          value: item.tcin,
        })),
        emails: [],
        fromDate: -1,
        toDate: -1,
      }
    )
    handleSelectItem({ selectedItems: [] })
    // props.history.push(ROUTES.IMAGES.UPLOAD_HISTORY)
    props.router.navigate({ pathname: ROUTES.IMAGES.UPLOAD_HISTORY })
  }

  const handleManageImageClickAction = () => {
    props.manageClickAction(
      {
        newFilterContainerShown: true,
        dataContainerShown: true,
        selectedFilters: [],
        selectedFilter: { display: 'TCIN(s)', value: 'tcin' },
        selectedFilterValue: selectedItems.map((item) => ({
          display: item.tcin,
          value: item.tcin,
        })),
      },
      props.managePageSize,
      'asc',
      'TCIN'
    )
    handleSelectItem({ selectedItems: [] })
    //props.history.push(ROUTES.IMAGES.MANAGE)
    props.router.navigate({ pathname: ROUTES.IMAGES.MANAGE })
  }

  const handleReviewImageClickAction = () => {
    props.requestImageReviewData(selectedItems.map((item) => item.tcin))
    handleSelectItem({ selectedItems: [] })
    //props.history.push(ROUTES.IMAGES.REVIEW)
    props.router.navigate({ pathname: ROUTES.IMAGES.REVIEW })
  }

  const handleReviewHistoryClickAction = () => {
    props.requestImageReviewHistoryData(selectedItems.map((item) => item.tcin))
    handleSelectItem({ selectedItems: [] })
    //props.history.push(ROUTES.IMAGES.REVIEW_HISTORY)
    props.router.navigate({ pathname: ROUTES.IMAGES.REVIEW_HISTORY })
  }

  const handleSelectAllClick = (_, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = searchData
    }
    handleSelectItem({
      selectedItems: newSelected,
    })
  }

  let selectedTcins = flattenDeep(selectedItems.map((item) => item.tcin))

  return (
    <div className={classes.tableWrapper}>
      <Table
        className={classes.table}
        aria-labelledby="searchResults"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" align="left">
              <Checkbox
                indeterminate={
                  selectedItems.length > 0 &&
                  (selectedItems.length < rowsPerPage ||
                    selectedItems.length < searchData.length)
                }
                checked={
                  searchData.length > 0 &&
                  (selectedItems.length === rowsPerPage ||
                    selectedItems.length === searchData.length)
                }
                onChange={handleSelectAllClick}
                color="primary"
              />
            </TableCell>
            <TableCell
              padding="default"
              align="left"
              className={classes.itemData}
            >
              Item Details
            </TableCell>
            <TableCell padding="default" align="left">
              Rel. Type Code
            </TableCell>
            <TableCell padding="default" align="left">
              DPCI
            </TableCell>
            <TableCell padding="default" align="left">
              Swatch
            </TableCell>
            <TableCell padding="default" align="left">
              Primary
            </TableCell>
            <TableCell padding="default" align="left">
              Alternates
            </TableCell>
            <TableCell padding="default" align="left">
              Disabled
            </TableCell>
            <TableCell padding="default" align="left">
              Ready for Review
              <div>
                <div>
                  <span className={classes.readyForReviewNotice} />
                  <Typography variant="caption">
                    = primary image for review
                  </Typography>
                </div>
                <div>
                  <span className={classes.readyForReuseNotice} />
                  <Typography variant="caption">
                    = reuse image for review
                  </Typography>
                </div>
              </div>
            </TableCell>
            <TableCell padding="default" align="left">
              Rejected
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchData.map((itemData, index) => (
            <SearchResult
              key={index}
              itemData={itemData}
              isSelected={isSelected(itemData)}
              selectItem={handleSelectItem}
              selectedItems={selectedItems}
              searchRelatedItems={searchRelatedItems}
            />
          ))}
          <TableRow className={classes.height49}>
            <TableCell colSpan={11} />
          </TableRow>
        </TableBody>
      </Table>
      <ImageToolbar
        numSelected={selectedItems.length}
        selectedTcins={selectedTcins}
        enabledActions={[
          <ManageImageAction
            auth={auth}
            manageImageClickAction={handleManageImageClickAction}
          />,
          <ReviewImageAction
            auth={auth}
            reviewImageClickAction={handleReviewImageClickAction}
          />,
          <ReviewHistoryAction
            auth={auth}
            reviewHistoryClickAction={handleReviewHistoryClickAction}
          />,
          <UploadHistoryAction
            auth={auth}
            uploadHistoryClickAction={handleUploadHistoryClickAction}
          />,
        ]}
      />
    </div>
  )
}

// @ts-ignore
export default withRouter(withStyles(styles)(SearchResultContainer))
