import { useEffect, useState } from 'react'
import styles from '../theme'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Checkbox, Tooltip, Typography } from '@material-ui/core'
import { isNil, isEqual } from 'lodash'
import { requestedPositionToString } from '../../../Shared/SharedUtils'
import Moment from 'react-moment'
import Lightbox from 'react-image-lightbox'
import ZoomInIcon from '@material-ui/icons/ZoomIn'

function ImageToReview(props) {
  const {
    classes = {},
    item = {},
    imageDictionary = {},
    itemReviewImages = [],
    handleClick = () => {},
  } = props

  const isSelected = (clickedImage, tcin) => {
    const reviewItem = {
      tcin: tcin,
      image: clickedImage,
    }
    return !isNil(
      itemReviewImages &&
        itemReviewImages.find((image) => isEqual(image, reviewItem))
    )
  }

  const tooltipFormatter = (uploadDate, vendorEmail) => {
    return (
      <div>
        <span>
          Upload Date:{' '}
          <Moment format={'YYYY-MM-DD h:mm a'}>{uploadDate}</Moment>
        </span>
        <br />
        <span>Uploaded By: {vendorEmail}</span>
      </div>
    )
  }

  const modalStyle = {
    content: {
      top: '88px',
    },
  }

  const [lightboxUrl, setLightboxUrl] = useState(null)
  const [isZoomVisible, setIsZoomVisible] = useState(false)
  const [sliderImages, setSliderImages] = useState([])
  const [photoIndex, setPhotoIndex] = useState(0)

  const [withImageName, setWithImageName] = useState([])

  useEffect(() => {
    let imagesForSlider = []
    let withAltImges = []

    if (!isNil(imageDictionary) && imageDictionary.size > 0) {
      ;[...imageDictionary.entries()].map(([key, value]) => {
        const reviewImages = item.image_list.filter(
          (r) => requestedPositionToString(r.requested_position) === key
        )
        const liveImages = item.live_image_list.filter((r) => r.suffix === key)

        for (let i = 0; i < value; i++) {
          if (!isNil(liveImages[i])) {
            imagesForSlider.push(liveImages[i].image_associations_mongo_id)
            withAltImges.push({
              image: liveImages[i].publish_url,
              name: key,
              caption: 'Live',
            })
          }

          if (!isNil(reviewImages[i])) {
            imagesForSlider.push(reviewImages[i].image_id)
            withAltImges.push({
              image: reviewImages[i].publish_url,
              name: key,
              caption: 'New',
            })
          }
        }
      })

      setSliderImages(imagesForSlider)
      setWithImageName(withAltImges)
    }
  }, [])

  const clickLiveImageIndex = (image) => {
    let imageIndex = sliderImages.indexOf(image)
    setPhotoIndex(imageIndex)
  }

  return (
    <Grid container direction="row" wrap="nowrap" spacing={8}>
      {lightboxUrl && (
        <Lightbox
          reactModalStyle={modalStyle}
          imageTitle={
            withImageName[photoIndex].name === '00'
              ? `${withImageName[photoIndex].caption} - Primary`
              : `${withImageName[photoIndex].caption}  - Alt ${withImageName[photoIndex].name} `
          }
          mainSrc={withImageName[photoIndex].image}
          nextSrc={withImageName[(photoIndex + 1) % withImageName.length]}
          prevSrc={
            withImageName[
              (photoIndex + withImageName.length - 1) % withImageName.length
            ]
          }
          imageLoadErrorMessage={'Loading....'}
          onCloseRequest={() => {
            setLightboxUrl(null)
            setPhotoIndex(0)
          }}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + withImageName.length - 1) % withImageName.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % withImageName.length)
          }
        />
      )}

      <Grid
        container
        direction="column"
        wrap="nowrap"
        item
        className={classes.imageAlignment}
        spacing={1}
      >
        <Grid container direction="column" item className={classes.height33} />
        <Grid item className={classes.statusContainer}>
          <div className={`${classes.statusText} ${classes.grey}`}>Live</div>
          <div className={classes.noExistingImage} />
        </Grid>
        <Grid item className={classes.statusContainer}>
          <div className={`${classes.statusText} ${classes.blue}`}>New</div>
          <div className={classes.noExistingImage} />
        </Grid>
        <Grid />
      </Grid>
      {!isNil(imageDictionary) &&
        imageDictionary.size > 0 &&
        [...imageDictionary.entries()].map(([key, value]) => {
          const reviewImages = item.image_list.filter(
            (r) => requestedPositionToString(r.requested_position) === key
          )
          const liveImages = item.live_image_list.filter(
            (r) => r.suffix === key
          )

          const reviewImagesArray = []
          const liveImagesArray = []
          const keyArray = []

          for (let i = 0; i < value; i++) {
            !isNil(reviewImages[i])
              ? reviewImagesArray.push(reviewImages[i])
              : reviewImagesArray.push(null)
            !isNil(liveImages[i])
              ? liveImagesArray.push(liveImages[i])
              : liveImagesArray.push(null)
            keyArray.push(key)
          }

          return (
            <Grid
              key={key}
              container
              direction="column"
              wrap="nowrap"
              item
              className={classes.imageAlignment}
              spacing={1}
            >
              <Grid container item>
                {keyArray.map((formatKey, index) => {
                  return (
                    <Typography key={index} className={classes.keyCell}>
                      {formatKey === '00' ? 'Primary' : `Alt ${formatKey}`}
                    </Typography>
                  )
                })}
              </Grid>

              <Grid item className={classes.imageBoxAlign}>
                {liveImagesArray.map((liveImage, index) => {
                  return !isNil(liveImage) ? (
                    <div
                      className={`${classes.flexColumn}`}
                      onMouseEnter={() => setIsZoomVisible(true)}
                      onMouseLeave={() => setIsZoomVisible(false)}
                    >
                      <Tooltip
                        title={
                          liveImage.active
                            ? ''
                            : 'Image is not active. Choosing approve in place will replace inactive image with new active image'
                        }
                      >
                        <img
                          key={index + liveImage.image_associations_mongo_id}
                          data-test-id={`live-image-active-${liveImage.active}`}
                          alt={liveImage.image_associations_mongo_id}
                          src={`${liveImage.publish_url}?make_square=true&hei=150&wei=150`}
                          className={
                            liveImage.active
                              ? classes.reviewImage
                              : classes.disabled
                          }
                        />
                      </Tooltip>
                      <ZoomInIcon
                        className={`${
                          isZoomVisible ? classes.zoomin : classes.displayNone
                        }`}
                        color="primary"
                        onClick={() => {
                          setLightboxUrl(
                            liveImage.publish_url +
                              '?make_square=true&hei=800&wei=800'
                          )
                          clickLiveImageIndex(
                            liveImage.image_associations_mongo_id
                          )
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      key={index}
                      className={`${classes.noExistingImage} ${classes.marginBottom3}`}
                    />
                  )
                })}
              </Grid>
              <Grid item className={classes.flex}>
                {reviewImagesArray.map((reviewImage, index) => {
                  return !isNil(reviewImage) ? (
                    <div
                      key={index + reviewImage.image_id}
                      className={`${classes.flexColumn}`}
                      onMouseEnter={() => setIsZoomVisible(true)}
                      onMouseLeave={() => setIsZoomVisible(false)}
                    >
                      {reviewImage.reuse ? (
                        <div>
                          <div class="ribbon-wrapper">
                            <div class="reuseAnimationEffect">&nbsp;</div>
                            <div class="ribbon-front">Reuse</div>
                            <div class="ribbon-edge-topleft"></div>
                            <div class="ribbon-edge-topright"></div>
                            <div class="ribbon-edge-bottomleft"></div>
                            <div class="ribbon-edge-bottomright"></div>
                          </div>
                        </div>
                      ) : (
                        <div className={classes.imageAlignTop}></div>
                      )}

                      <Tooltip
                        title={tooltipFormatter(
                          reviewImage.upload_date,
                          reviewImage.vendor_email
                        )}
                      >
                        <img
                          data-test-id="review-image"
                          alt={reviewImage.image_id}
                          src={reviewImage.publish_url + '&hei=150&wei=150'}
                        />
                      </Tooltip>
                      <Checkbox
                        data-test-id="image-checkbox"
                        checked={isSelected(reviewImage, item.tcin)}
                        color="primary"
                        onClick={(event) =>
                          handleClick(event, reviewImage, item.tcin)
                        }
                      />
                      <ZoomInIcon
                        className={`${
                          isZoomVisible ? classes.zoomin : classes.displayNone
                        }`}
                        color="primary"
                        onClick={() => {
                          setLightboxUrl(
                            reviewImage.publish_url + '&hei=800&wei=800'
                          )
                          clickLiveImageIndex(reviewImage.image_id)
                        }}
                      />
                    </div>
                  ) : null
                })}
              </Grid>
            </Grid>
          )
        })}
    </Grid>
  )
}

// @ts-ignore
export default withStyles(styles)(ImageToReview)
