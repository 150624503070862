//

export function Header(props) {
  const { children, ...additionalProps } = props
  return <div {...additionalProps}>{children}</div>
}

Header.displayName = 'Header'

export default Header
