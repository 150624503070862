import React from 'react'
import ExampleImageFinder from './ExampleImageFinder'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  setClassifierActiveStep,
  selectImageClassifier,
} from '../../../../../../store/images/tagTraining/actionCreator'

class ExampleImageFinderContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  selectImage = (item) => {
    this.props.selectImageClassifier(item)
  }

  imageSelectedContinue = () => {
    this.props.setClassifierActiveStep(2)
  }

  render() {
    const {
      tcinList,
      imageList,
      imageListFetching,
      selectedImage,
      stepIsDisabled,
    } = this.props
    return (
      <ExampleImageFinder
        tcinList={tcinList}
        imageList={imageList}
        imageListFetching={imageListFetching}
        selectImage={this.selectImage}
        selectedImage={selectedImage}
        imageSelectedContinue={this.imageSelectedContinue}
        stepIsDisabled={stepIsDisabled}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectImageClassifier,
      setClassifierActiveStep,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { classifierSelection } = imageTagTraining
  const { tcinList, imageList, selectedImage, imageListFetching } =
    classifierSelection
  return {
    tcinList,
    auth,
    imageList,
    imageListFetching,
    selectedImage,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExampleImageFinderContainer)
