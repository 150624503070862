import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DialogBoxEditVtt from './DialogBoxEditVtt'
import {
  editVttDialogBox,
  resetFetch,
} from '../../../../../store/videos/upload/actionCreator'
import { endsWith } from 'lodash'
import { TOAST_BODY, VIDEO_ASSETS } from '../../../../Shared/Constants'
import { toast } from 'react-toastify'

function DialogBoxEditVttContainer(props) {
  const closeEditVtt = () => {
    var files = []
    if (props.ccFileAdded) {
      /* eslint-disable */
      files[0] = new File([props.editVtt.initial.vttData], props.editVtt.initial.vttTitle.trim())
      /* eslint-enable */
      props.onDrop(VIDEO_ASSETS.CLOSED_CAPTION.type, files)
      props.editVttDialogBox(false)
    } else {
      props.editVttDialogBox(false)
    }
  }

  const onSave = () => {
    if (endsWith(props.editVtt.values.vttTitle.trim().toLowerCase(), '.vtt')) {
      /* eslint-disable */
      var files = []
      files[0] = new File([props.editVtt.values.vttData], props.editVtt.values.vttTitle.trim())
      /* eslint-enable */
      props.onDrop(VIDEO_ASSETS.CLOSED_CAPTION.type, files)
      props.editVttDialogBox(false)
    } else {
      props.resetFetch(false)
      toast.info('Enter a extension (.vtt) after the filename', TOAST_BODY)
    }
  }

  const {
    vttEditBoxOpen = false,
    vttEditData = '',
    vttEditBoxTitle = '',
  } = props
  return (
    <DialogBoxEditVtt
      closeEditVtt={closeEditVtt}
      vttEditBoxOpen={vttEditBoxOpen}
      vttEditData={vttEditData}
      vttEditBoxTitle={vttEditBoxTitle}
      onSave={onSave}
      editVtt={props.editVtt}
      classes={props.classes}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editVttDialogBox,
      resetFetch,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { videoUpload, form } = state
  const { editVtt } = form
  const { vttEditBoxOpen, vttEditData, vttEditBoxTitle, ccFileAdded } =
    videoUpload
  return {
    vttEditBoxOpen,
    vttEditData,
    vttEditBoxTitle,
    editVtt,
    ccFileAdded,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogBoxEditVttContainer)
