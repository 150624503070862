const styles = (theme) => ({
  marginTop10: {
    marginTop: '10px',
  },
  margin10: {
    margin: '10px',
  },
  browseButtonText: {
    margin: '0px 5px',
  },
  dropZoneActive: {
    backgroundColor: 'rgba(173, 216, 230, 0.5)',
    width: '90%',
    border: 'dashed 1px rgba(33,150,243, 1)',
    padding: '20px',
    margin: '15px auto',
    height: '335px',
  },
  DropZoneSmallText: {
    marginTop: '12px !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '16px',
    color: 'rgba(102, 102, 102, .86)',
    textAlign: 'center',
  },
  videoUploadIcon: {
    width: '75px',
    height: '75px',
    color: 'rgba(102, 102, 102, .86)',
  },
  lineSeperator: {
    width: '250px',
    borderColor: 'rgba(0, 0, 0, 0.12);',
    margin: '25px auto',
    textAlign: 'center',
  },
  requiredTypeRequired: {
    fontSize: '.75em',
    color: 'red',
  },
  requiredTypeOptional: {
    fontSize: '.75em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  videoTitle: {
    marginLeft: '13px',
    marginTop: '12px',
  },
  videoFileTypeTitle: {
    fontSize: '1.2em',
    marginTop: '0',
  },
  vuVideoPreview: {
    maxHeight: '257px',
    maxWidth: '460px',
    height: '100%',
    width: '100%',
    margin: '10px',
    padding: '10px',
  },
  uploadCcPreview: {
    overflow: 'auto',
    border: '1px solid grey',
    height: '360px',
    margin: '10px',
    padding: '10px',
    width: '100%',
  },
  historyCcPreview: {
    overflow: 'auto',
    border: '1px solid grey',
    height: '260px',
    margin: '10px',
    padding: '10px',
    width: '100%',
  },
  reviewCcPreview: {
    overflow: 'auto',
    border: '1px solid grey',
    height: '255px',
    margin: '10px',
    padding: '10px',
    width: '100%',
  },
  vuPosterFramePreview: {
    maxHeight: '225px',
    maxWidth: '400px',
    height: '100%',
    width: '100%',
    margin: '10px',
    padding: '10px',
  },
  vuTranscriptPreview: {
    width: '100%',
    margin: '15px',
    height: '255px',
  },
  videoHeaderFileName: {
    color: 'grey',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '14px',
  },
  deleteIcon: {
    color: '#3f51b5',
    fontSize: '32px',
    cursor: 'pointer',
  },
  vuAddTcinTitle: {
    fontSize: '1.2em',
  },
  cloudDone: {
    color: 'green',
    marginLeft: '15px',
  },
  tcinAssociated: {
    margin: '0px 15px',
  },
  marginRight15: {
    marginRight: '15px',
  },
  rejectedMessage: {
    backgroundColor: 'rgba(255,0,0,.15)',
    border: '2px solid #F05545',
    margin: '10px 11px 5px',
    padding: '10px 0px',
    textAlign: 'center',
  },
  waitingForReviewMessage: {
    backgroundColor: 'rgba(225,180,1,.1)',
    border: '2px solid #E18401',
    margin: '10px 11px 5px',
    padding: '10px 0px',
    textAlign: 'center',
  },
  approvedMessage: {
    backgroundColor: 'rgba(0,255,0,.15)',
    border: '2px solid #55F045',
    margin: '10px 11px 5px',
    padding: '10px 0px',
    textAlign: 'center',
  },
  addReplacement: {
    margin: '20px 5px',
  },
  paddingSide15: {
    padding: '0px 15px',
    paddingTop: '10px',
  },
  paddingRight5: {
    paddingRight: '5px',
  },
  helpLink: {
    marginLeft: '30px',
    color: '#3f51b5',
    cursor: 'pointer',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  header: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '.005em',
  },
  data: {
    fontSize: '14px',
    letterSpacing: '.010em',
    fontWeight: '400',
    lineHeight: '20px',
  },
  infoCard: {
    overflowY: 'auto',
    maxHeight: '882px',
  },
  appBar: {
    backgroundColor: '#CC0000',
  },
  tabButtons: {
    minWidth: '10%',
  },
  tabsFlex: {
    justifyContent: 'space-evenly',
  },
  addTcinsCard: {
    margin: '10px',
    padding: '15px',
  },
  formControl: {
    width: '400px',
  },
  padding0: {
    padding: '0px',
  },
  noColor: {
    backgroundColor: 'transparent',
  },
  progressBar: {
    marginLeft: '50%',
    marginTop: '25%',
  },
  tcinTableImage: {
    width: '50px',
    height: '50px',
  },
  fullWidth: {
    width: '100%',
  },
  newTcins: {
    display: 'contents',
  },
  tcinList: {
    overflowY: 'auto',
    height: '100px',
    marginTop: '20px',
  },
  marginTopBottom20: {
    margin: '20px 0',
  },
  videoRejectButtons: {
    margin: '10px 10px 0 10px',
  },
  red: {
    color: 'red',
  },
  marginRight10: {
    marginRight: '10px',
  },
  marginLeft10Top5: {
    margin: '5px 0 0 10px',
  },
  uploadCard: {
    margin: '10px',
    maxHeight: '475px',
    minHeight: '475px',
  },
  selectedTcinsWarning: {
    color: '#f44336',
  },
  title: {
    flex: '0 0 auto',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    minWidth: '1020px',
  },
  maxWidth350: {
    maxWidth: '350px',
  },
  height49: {
    height: '49px',
  },
  marginTop25: {
    marginTop: '25px',
  },
  errorTextSpace: {
    maxHeight: '515px',
    overflow: 'auto',
  },
  blue: {
    color: '#3f51b5',
  },
  normaliseText: {
    textTransform: 'inherit',
  },
})

export default styles
