import React from 'react'
import SelectExampleImageStepper from './SelectExampleImageStepper'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { startOverSelectExampleImage } from '../../../../../../store/images/tagTraining/actionCreator'

class SelectExampleImageStepperContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  startOver = () => {
    this.props.startOverSelectExampleImage()
  }

  backToEnterTcin = () => {
    this.props.backToEnterTcin()
  }

  render() {
    const { activeStep } = this.props
    return (
      <SelectExampleImageStepper
        activeStep={activeStep}
        backToEnterTcin={this.startOver}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startOverSelectExampleImage,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { exampleImageSelection } = imageTagTraining
  const { activeStep } = exampleImageSelection
  return {
    auth,
    activeStep,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectExampleImageStepperContainer)
