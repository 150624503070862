export const BUILD_FILTER_EVENT_VIDEO_REVIEW = 'BUILD_FILTER_EVENT_VIDEO_REVIEW'
export const REMOVE_FILTER_EVENT_VIDEO_REVIEW =
  'REMOVE_FILTER_EVENT_VIDEO_REVIEW'
export const RECEIVE_VIDEO_REVIEW_DATA = 'RECEIVE_VIDEO_REVIEW_DATA'
export const REQUEST_VIDEO_REVIEW_DATA = 'REQUEST_VIDEO_REVIEW_DATA'
export const ERROR_SEARCHING_VIDEO_REVIEW = 'ERROR_SEARCHING_VIDEO_REVIEW'
export const CLEAR_VIDEO_REVIEW_DATA_EVENT = 'CLEAR_VIDEO_REVIEW_DATA_EVENT'
export const CHANGE_FILTER_EVENT_VIDEO_REVIEW =
  'CHANGE_FILTER_EVENT_VIDEO_REVIEW'
export const SELECT_FILTER_VALUE_EVENT_VIDEO_REVIEW =
  'SELECT_FILTER_VALUE_EVENT_VIDEO_REVIEW'
export const CHANGE_CURRENT_PAGE_VIDEO_REVIEW =
  'CHANGE_CURRENT_PAGE_VIDEO_REVIEW'
export const SELECT_VIDEO_REVIEW_EVENT = 'SELECT_VIDEO_REVIEW_EVENT'
export const SET_SORT_FIELD_VIDEO_REVIEW = 'SET_SORT_FIELD_VIDEO_REVIEW'
export const SET_SORT_DIRECTION_VIDEO_REVIEW = 'SET_SORT_DIRECTION_VIDEO_REVIEW'
export const NEW_FILTER_EVENT_VIDEO_REVIEW = 'NEW_FILTER_EVENT_VIDEO_REVIEW'
export const BUILD_FILTER_EXPAND_VIDEO_REVIEW =
  'BUILD_FILTER_EXPAND_VIDEO_REVIEW'
export const STORE_VIDEO_REVIEW_STRAPI_DATA = 'STORE_VIDEO_REVIEW_DATA'
export const CHANGE_DEFAULT_PAGE_SIZE_VIDEO_REVIEW =
  'CHANGE_DEFAULT_PAGE_SIZE_VIDEO_REVIEW'
export const VIDEO_REVIEW_SEARCH_RESET_FETCH = 'VIDEO_REVIEW_SEARCH_RESET_FETCH'
export const SHOW_DEFAULT_ALERT_VIDEO_REVIEW_SEARCH =
  'SHOW_DEFAULT_ALERT_VIDEO_REVIEW_SEARCH'
