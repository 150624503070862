import React, { useEffect, useState } from 'react'
import styles from '../theme'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import GetAppIcon from '@material-ui/icons/GetApp'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import {
  Grid,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TextField,
} from '@material-ui/core'

import { getStyleGuidesData } from '../../../store/help/actionCreator'

function StyleGuides(props) {
  const {
    isStyleGuidesFetching = false,
    styleGuides = [],
    classes = {},
  } = props

  const [filterStrings, filterByCategory] = useState('')

  useEffect(() => {
    props.getStyleGuidesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <HeaderTitle title="Style Guides" />
      <Helmet title="Style Guides" />
      <Grid container>
        <Paper className={classes.width100}>
          {isStyleGuidesFetching && (
            <Grid className={classes.progressBar}>
              <CircularProgress />
            </Grid>
          )}

          <Grid className={classes.progressBar}>
            <TextField
              multiline
              rows="1"
              margin="normal"
              label="Filter By Category"
              onChange={(e) => filterByCategory(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="searchResults">
                <TableHead>
                  <TableRow>
                    <TableCell padding="default" align="left">
                      Actions
                    </TableCell>
                    <TableCell padding="default" align="left">
                      Style Guide Name
                    </TableCell>
                    <TableCell padding="default" align="left">
                      Category
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {styleGuides
                    .sort((a, b) => {
                      return a.category < b.category ? -1 : 1
                    })
                    .filter((name) => {
                      return name.category
                        .toLowerCase()
                        .includes(filterStrings.toLowerCase())
                    })
                    .map((styleGuide, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell padding="default">
                            <Tooltip title="Open in Browser">
                              <a
                                color="primary"
                                target="_blank"
                                href={styleGuide.link}
                                rel="noreferrer"
                              >
                                <OpenInBrowserIcon
                                  className={classes.icon}
                                  color="primary"
                                />
                              </a>
                            </Tooltip>
                            <Tooltip title="Download">
                              <a
                                color="primary"
                                target="_blank"
                                download
                                href={styleGuide.link}
                                rel="noreferrer"
                              >
                                <GetAppIcon
                                  className={classes.icon}
                                  color="primary"
                                />
                              </a>
                            </Tooltip>
                          </TableCell>
                          <TableCell padding="default">
                            {styleGuide.name}
                          </TableCell>
                          <TableCell padding="default">
                            {styleGuide.category}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  <TableRow>
                    <TableCell colSpan={11} />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { help } = state
  const { styleGuides, isStyleGuidesFetching } = help
  return {
    styleGuides,
    isStyleGuidesFetching,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStyleGuidesData,
    },
    dispatch
  )

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StyleGuides))
