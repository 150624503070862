import React from 'react'
import CreateNewTagStepperContainer from '../CreateNewTagStepper/CreateNewTagStepperContainer'

function CreateNewTagPage(props) {
  return (
    <React.Fragment>
      <CreateNewTagStepperContainer />
    </React.Fragment>
  )
}

export default CreateNewTagPage
