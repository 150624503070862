import React from 'react'
import ClassificationPage from './ClassificationPage'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  setClassifierActiveStep,
  resetClassifierSelection,
  tagDataSelection,
} from '../../../../../../store/images/tagTraining/actionCreator'

class ClassificationPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.ref = React.createRef()
    this.dom = document.body
  }

  componentDidMount() {
    window.scrollTo(0, this.dom.offsetHeight)
  }

  componentDidUpdate() {
    window.scrollTo(0, this.dom.offsetHeight)
  }

  startOver = () => {
    this.props.resetClassifierSelection()
  }

  backToSelectTags = () => {
    this.props.setClassifierActiveStep(2)
  }

  backToSelectImage = () => {
    this.props.setClassifierActiveStep(1)
    this.props.tagDataSelection([])
  }

  backToEnterTcin = () => {
    this.props.setClassifierActiveStep(0)
  }

  render() {
    const { activeStep, currentSimilarImageCount } = this.props
    return (
      <ClassificationPage
        currentActiveStep={activeStep}
        startOver={this.startOver}
        backToSelectTags={this.backToSelectTags}
        backToSelectImage={this.backToSelectImage}
        backToEnterTcin={this.backToEnterTcin}
        currentSimilarImageCount={currentSimilarImageCount}
        ref={this.ref}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetClassifierSelection,
      setClassifierActiveStep,
      tagDataSelection,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { classifierSelection } = imageTagTraining
  const { activeStep, currentSimilarImageCount } = classifierSelection
  return {
    auth,
    activeStep,
    currentSimilarImageCount,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassificationPageContainer)
