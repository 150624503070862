/* eslint-disable no-redeclare */
/* global localStorage: false */

import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  PROMPT_SIGN_IN,
  DECLINE_SIGN_IN,
  RECEIVE_USER_PROFILE_DATA,
  REQUEST_SAVE_FILTER_DATA,
  SUCCESS_SAVE_FILTER_DATA,
  REQUEST_SAVED_FILTER_DATA,
  RECEIVE_SAVED_FILTER_DATA,
  TOGGLE_SAVE_FILTER_DIALOGUE,
  TOGGLE_CONFIRMATION,
  REQUEST_SAVED_FILTER_DATA_FAILURE,
  START_VENDOR_TCIN_MAPPING,
  SUCCESS_VENDOR_TCIN_MAPPING,
  AUTH_RESET_FETCH,
} from './actionType'
import { each, includes } from 'lodash'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../components/Shared/Constants'

// const idToken = localStorage.getItem('id_token')

export function resetFetch(data) {
  return {
    type: AUTH_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export const signIn = () => ({ type: SIGN_IN })

export const signInSuccess = (payload) => ({ type: SIGN_IN_SUCCESS, payload })

export const signOutAction = () => ({ type: SIGN_OUT })

export const promptToSignIn = () => ({ type: PROMPT_SIGN_IN })

export const declineSignIn = () => ({ type: DECLINE_SIGN_IN })

const formatAdGroups = (groups = []) => {
  return groups.reduce((result, group) => {
    group.split(',').forEach((attribute) => {
      if (attribute.indexOf('CN=') === 0) result.push(attribute.split('CN=')[1])
    })
    return result
  }, [])
}

const checkPermission = (userInfo) => {
  var permission = {
    isCepSuperUser: false,
    imgCurationEdit: false,
    imgReviewUpload: false,
    imgLabelsEdit: false,
    imgReview: false,
    sizeChartsEdit: false,
    videoUpload: false,
    videoReview: false,
    cgi: false,
    longCopy: false,
    longCopySearchAndManage: false,
    longCopyBulkUpload: false,
    copy: false,
    vendorTcinMap: false,
    hostName: window.location.host,
    deleteCopyFeature: false,
    instantEditLongCopy: false,
    badgesEdit: false,
    prodSpecs: false,
  }
  // harbinger.visitorId = userInfo.lanId
  // determine user access rights based on AD groups
  each(userInfo, function (group) {
    var upperGroup = group.toUpperCase()
    // Interal Users
    // ITEM DATA SPECIALIST
    if (includes(upperGroup, 'APP-CEP-ITEMDATA')) {
      permission.imgCurationEdit = true
      permission.imgReviewUpload = true
      permission.imgReview = true
      permission.prodSpecs = true
      // harbinger.segment = 'IDS'
    }
    // ITEM DATA SPECIALIST ADVANCED
    if (includes(upperGroup, 'APP-CEP-ITEMDATA-ADVANCED')) {
      permission.imgCurationEdit = true
      permission.imgReviewUpload = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.imgReview = true
      permission.videoReview = true
      permission.prodSpecs = true
      permission.longCopy = true // Authorized to show Copy & Bullets link to left navigation menu
      permission.longCopyBulkUpload = true // Authorized to show Bulk Upload and Bulk Upload Report tabs on Long Copy Page
      // harbinger.segment = 'IDS-Advanced'
    }
    // SUPPORT
    if (includes(upperGroup, 'APP-CEP-SUPPORT')) {
      permission.imgCurationEdit = true
      permission.imgReviewUpload = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.imgReview = true
      permission.videoReview = true
      permission.longCopy = true // Authorized to show Copy & Bullets link to left navigation menu
      permission.longCopySearchAndManage = true // Authorized to show Search & Manage tab on Long Copy Page
      permission.longCopyBulkUpload = true // Authorized to show Bulk Upload and Bulk Upload Report tabs on Long Copy Page
      permission.prodSpecs = true // Authorized to show ProdSpec link to left navigation menu
      // harbinger.segment = 'Support'
    }
    // SITE MERCHANDISING
    if (includes(upperGroup, 'APP-CEP-SITEMERCH')) {
      permission.imgLabelsEdit = true
      permission.imgCurationEdit = true
      // harbinger.segment = 'Site Merch'
    }
    // CONTENT PLANNING
    if (includes(upperGroup, 'APP-CEP-CONTENT')) {
      permission.imgCurationEdit = true
      permission.sizeChartsEdit = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.videoReview = true
      permission.imgReviewUpload = true
      permission.longCopy = true // Authorized to show Copy & Bullets link to left navigation menu
      permission.longCopySearchAndManage = true // Authorized to show Search & Manage tab on Long Copy Page
      permission.longCopyBulkUpload = true // Authorized to show Bulk Upload and Bulk Upload Report tabs on Long Copy Page
      permission.deleteCopyFeature = true
      permission.instantEditLongCopy = true
      permission.badgesEdit = true
      permission.prodSpecs = true // Authorized to show ProdSpec link to left navigation menu
      // harbinger.segment = 'Content Planning'
    }
    // COPY WRITERS (INTERNAL/INDIA)
    if (includes(upperGroup, 'APP-CEP-COPYWRITING')) {
      permission.longCopy = true
      permission.longCopySearchAndManage = true
      permission.longCopyBulkUpload = true
      // harbinger.segment = 'Copy Writer'
    }
    // PIPELINE TEAM OR CGI TEAM
    if (
      includes(upperGroup, 'ADMN-CEP-PROD') ||
      includes(upperGroup, 'APP-CEP-PIPELINE-TEAM')
    ) {
      permission.isCepSuperUser = true
      permission.imgCurationEdit = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.videoReview = true
      permission.imgReviewUpload = true
      permission.cgi = true
      permission.longCopy = true
      permission.longCopySearchAndManage = true
      permission.longCopyBulkUpload = true
      permission.sizeChartsEdit = true
      permission.imgReview = true
      permission.deleteCopyFeature = true
      permission.instantEditLongCopy = true
      permission.badgesEdit = true
      permission.prodSpecs = true
      // harbinger.segment = 'Pipeline Team'
    }
    // External Users
    // VENDOR PARTNER TYPE
    if (includes(upperGroup, 'APP-CEP-EXTVENDOR-UPLOAD')) {
      permission.imgCurationEdit = true
      permission.longCopy = true
      permission.longCopySearchAndManage = false
      permission.longCopyBulkUpload = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.imgReviewUpload = true
      permission.cgi = true
      permission.vendorTcinMap = true
      // harbinger.segment = 'Merch Vendor'
    }
    // MARKETING PARTNER TYPE
    if (includes(upperGroup, 'APP-CEP-MARKETING-PARTNER')) {
      permission.longCopy = true
      permission.longCopySearchAndManage = true
      permission.longCopyBulkUpload = true
      permission.vendorTcinMap = false
      // harbinger.segment = 'Copywriter'
    }
    // SALES REP TYPE
    if (includes(upperGroup, 'POL-MULTI-PARTNER')) {
      permission.imgCurationEdit = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.imgReviewUpload = true
      permission.cgi = true
      permission.vendorTcinMap = true
      permission.longCopy = true
      permission.longCopySearchAndManage = false
      permission.longCopyBulkUpload = true
      // harbinger.segment = 'Sales Rep'
    }
  })
  return permission
}

export const formatUserInfo = (info = {}) => {
  return {
    email: info.mail,
    firstName: info.firstname,
    lanId: info.samaccountname,
    lastName: info.lastname,
    memberOf: formatAdGroups(info.memberof),
    permission: checkPermission(formatAdGroups(info.memberof)),
    accessToken: info.accessToken,
  }
}

export const fetchUserProfile = (accessToken) => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    const userProfile = localStorage.getItem('user_profile')
    if (userProfile) {
      dispatch(fetchUserProfileSuccess(JSON.parse(userProfile)))
      resolve(JSON.parse(userProfile))
    } else {
      axios
        .post(
          `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/user_profile?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
          null,
          {
            headers: {
              authToken: accessToken,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          dispatch(fetchUserProfileSuccess(res.data))
          localStorage.setItem('user_profile', JSON.stringify(res.data))
          resolve(res.data)
        })
        .catch((error, data) => {
          dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
          reject(new Error(`${error.message}`))
        })
    }
  })
  return promise
}

function fetchUserProfileSuccess(userProfile) {
  return {
    type: RECEIVE_USER_PROFILE_DATA,
    payload: {
      userProfile: userProfile,
    },
  }
}

export function fetchSavedFiltersEvent() {
  return (dispatch) => {
    dispatch(fetchSavedFilterEventRequest())
    axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/user_profile?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        {
          headers: {
            authToken: localStorage.getItem('id_token'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(fetchSavedFilterEventSuccess(res.data))
      })
      .catch((error, data) => {
        dispatch(
          fetchSavedFilterEventFailure(),
          toast.error(error.message, TOAST_BODY)
        )
      })
  }
}

function fetchSavedFilterEventRequest() {
  return {
    type: REQUEST_SAVED_FILTER_DATA,
    payload: {
      isFetching: true,
    },
  }
}

function fetchSavedFilterEventSuccess(savedFilterData) {
  return {
    type: RECEIVE_SAVED_FILTER_DATA,
    payload: {
      isFetching: false,
      savedFilterData: savedFilterData,
    },
  }
}

function fetchSavedFilterEventFailure() {
  return {
    type: REQUEST_SAVED_FILTER_DATA_FAILURE,
    payload: {
      isFetching: false,
    },
  }
}

export function saveFilterDataEvent(filterData) {
  const requestPayload = {
    filter_info_update_request: {
      filter_name: filterData.filterName,
      filter_criteria: filterData.filterCriteria,
      page_name: filterData.pageName,
    },
    pagination_info_update_request: {
      page_size: filterData.pageSize,
      sort_order: filterData.sortOrder,
      sort_field: filterData.sortField,
      page_name: filterData.pageName,
    },
  }
  return (dispatch) => {
    dispatch(saveFilterData())
    axios
      .put(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/user_profile?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestPayload,
        {
          headers: {
            authToken: localStorage.getItem('id_token'),
            'Content-Type': 'application/json;charset=UTF-8',
          },
        }
      )
      .then((res) => {
        dispatch(saveFilterDataSuccess())
        dispatch(fetchSavedFiltersEvent())
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function savePaginationDataEvent(filterData) {
  const requestPayload = {
    pagination_info_update_request: {
      page_size: filterData.pageSize,
      sort_order: filterData.sortOrder,
      sort_field: filterData.sortField,
      page_name: filterData.pageName,
    },
  }
  return (dispatch) => {
    axios
      .put(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/user_profile?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestPayload,
        {
          headers: {
            authToken: localStorage.getItem('id_token'),
            'Content-Type': 'application/json;charset=UTF-8',
          },
        }
      )
      .then((res) => {
        dispatch(fetchSavedFiltersEvent())
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function saveFilterData() {
  return {
    type: REQUEST_SAVE_FILTER_DATA,
    payload: {
      isFetching: true,
    },
  }
}

function saveFilterDataSuccess() {
  return {
    type: SUCCESS_SAVE_FILTER_DATA,
    payload: {
      isFetching: false,
      isSaveFilterOpen: false,
    },
  }
}

export function toggleSaveFilterDialogue(isSaveFilterOpen) {
  return {
    type: TOGGLE_SAVE_FILTER_DIALOGUE,
    payload: {
      isSaveFilterOpen: isSaveFilterOpen,
    },
  }
}

export function removeSavedFilter(id) {
  return (dispatch) => {
    dispatch(saveFilterData())
    axios
      .delete(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/user_profile/filters/${id}?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        {
          headers: {
            authToken: localStorage.getItem('id_token'),
            'Content-Type': 'application/json;charset=UTF-8',
          },
        }
      )
      .then((res) => {
        dispatch(fetchSavedFiltersEvent())
        dispatch(toggleConfirmation(false, null))
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function toggleConfirmation(isConfirmationOpen, confirmationPayload) {
  return {
    type: TOGGLE_CONFIRMATION,
    payload: {
      isConfirmationOpen: isConfirmationOpen,
      confirmationPayload: confirmationPayload,
    },
  }
}

function startVendorTcinMapping() {
  return {
    type: START_VENDOR_TCIN_MAPPING,
  }
}

export function successVendorTcinMapping(payload) {
  return {
    type: SUCCESS_VENDOR_TCIN_MAPPING,
    payload: {
      validTcins: payload.validTcins,
      invalidTcins: payload.invalidTcins,
      unauthorizedTcins: payload.unauthorizedTcins,
    },
  }
}

export function vendorTcinMapping(tcins, email) {
  return (dispatch) => {
    dispatch(startVendorTcinMapping())
    axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/user_profile/vendor_tcin/${email}?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        tcins
      )
      .then((res) => {
        var tcinLists = {
          validTcins: res.data.valid_tcins,
          invalidTcins: res.data.invalid_tcins,
          unauthorizedTcins: res.data.unauthorized_tcins,
        }
        dispatch(
          successVendorTcinMapping(tcinLists),
          toast.info(
            `You submitted ${tcins.length} TCIN(s), you are authorized to work with ${tcinLists.validTcins.length} TCIN(s)`,
            TOAST_BODY
          )
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}
