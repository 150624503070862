import {
  OPEN_SIDE_NAV,
  CLOSE_SIDE_NAV,
  SHOW_AUTH_POPUP,
  SET_HEADER_TITLE,
  STORE_HOME_PAGE_STRAPI_DATA,
  SHOW_DEFAULT_ALERT_LAYOUT,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { isEmpty } from 'lodash'
import { TOAST_BODY } from '../../components/Shared/Constants'
import { toast } from 'react-toastify'

export function openSideNav() {
  return {
    type: OPEN_SIDE_NAV,
  }
}

export function closeSideNav() {
  return {
    type: CLOSE_SIDE_NAV,
  }
}

export function showAuthPopup(popupType) {
  return {
    type: SHOW_AUTH_POPUP,
    payload: popupType,
  }
}

export function setHeaderTitle(headerTitle) {
  return {
    type: SET_HEADER_TITLE,
    payload: headerTitle,
  }
}

export function storeHomePageStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const headlineData = data.filter(
    (item) => item.interface_document_type === 'headline'
  )
  const announcementData = data.filter(
    (item) => item.interface_document_type === 'announcement'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const headline =
    headlineData.length > 0
      ? {
          title: headlineData[0].user_facing_title,
          body: headlineData[0].user_facing_body,
        }
      : null
  const announcement =
    announcementData.length > 0
      ? {
          title: announcementData[0].user_facing_title,
          body: announcementData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_HOME_PAGE_STRAPI_DATA,
    payload: {
      alert,
      headline,
      announcement,
      faq,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_LAYOUT,
    payload: data,
  }
}

export function getHomePageStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Home%20Page&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data)
          ? dispatch(showDefaultAlert(true))
          : dispatch(storeHomePageStrapiData(response.data))
        dispatch(showDefaultAlert(false))
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}
