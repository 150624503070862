import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  Chip,
  Grid,
  ListItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Close from '@material-ui/icons/Close'
import SaveFilter from './SaveFilter'

const filterChip = (filterValue, val, props) => {
  return (
    <Chip
      key={val.value}
      label={val.display}
      className={props.classes.margin5}
      onDelete={() => props.removeFilterHandler(filterValue, val.value)}
    />
  )
}

const filterChipWrapper = (item, props) => {
  return (
    <List
      key={item.filterValue}
      className={
        item.selectedValues.length > 12 ? props.classes.tcinList : null
      }
    >
      <ListItem>
        <Grid container spacing={3}>
          <Grid container direction="row">
            {item.selectedValues.map((val) =>
              filterChip(item.filterValue, val, props)
            )}
          </Grid>
        </Grid>
      </ListItem>
    </List>
  )
}

function NewFilter(props) {
  const {
    clearFilters = () => {},
    selectedFilters = [],
    saveNewFilter = () => {},
    classes = {},
    isSaveFilterOpen = false,
    saveFilter = {},
    handleClose = () => {},
    handleSave = () => {},
    pristine = false,
    disabled = false,
  } = props
  return (
    <Card className={classes.marginLeft5}>
      <SaveFilter
        isSaveFilterOpen={isSaveFilterOpen}
        saveFilter={saveFilter}
        handleClose={handleClose}
        handleSave={handleSave}
        pristine={pristine}
        disabled={disabled}
      />
      <CardContent>
        <Grid container direction="row" className={classes.marginBottom10}>
          <Grid item xs={6}>
            <span className={classes.filterTitle}>Filters Applied</span>
          </Grid>
          <Grid container item xs={6} justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={() => clearFilters()}
            >
              <Close />
              CLEAR FILTER
            </Button>
          </Grid>
        </Grid>
        <List>
          {selectedFilters.map((item) => filterChipWrapper(item, props))}
        </List>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => saveNewFilter()}
        >
          <AddBoxIcon />
          SAVE AS
        </Button>
      </CardActions>
    </Card>
  )
}

export default withStyles(styles)(NewFilter)
