import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Grid,
  Card,
  TextField,
  Button,
  Checkbox,
  Toolbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import TcinCardTableContainer from '../TcinCardTable/TcinCardTableContainer'
import { difference } from 'lodash'
import {
  VIDEO_APPROVAL_STATUS,
  VIDEO_EDIT_MODE,
} from '../../../../Shared/Constants'

const payloadButtonText = (
  rejectVideoGroup,
  videoGroupStatus,
  showActiveCheckbox
) => {
  if (rejectVideoGroup) {
    return 'Reject Video Group'
  } else if (
    videoGroupStatus === VIDEO_APPROVAL_STATUS.APPROVED &&
    showActiveCheckbox
  ) {
    return 'Save Video Group'
  } else if (videoGroupStatus === VIDEO_APPROVAL_STATUS.IN_PROCESS) {
    return 'Approve Video Group'
  }
}

let TcinCard = (props) => {
  const {
    classes = {},
    editModeTitle = '',
    sendToTarget = () => {},
    video = [],
    closedCaption = [],
    posterFrame = [],
    transcript = [],
    videoGroupStatus = null,
    videoFileAdded = false,
    ccFileAdded = false,
    newVideoUpload = () => {},
    selectedTcins = [],
    approveVideoGroup = () => {},
    editModeSetTitle = () => {},
    rejectVideoGroup = false,
    requestedVideoData = {},
    setVideoActive = () => {},
    videoIsActive = false,
    showVideoActiveCheckbox = false,
    showRejectButtons = false,
    editMode = VIDEO_EDIT_MODE.UPLOAD,
    openStartOverModal = false,
    showStartOverConfirmation = () => {},
  } = props

  return (
    <React.Fragment>
      <Dialog
        open={openStartOverModal}
        onClose={() => showStartOverConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Start Over</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start a new video group? This will discard
            the current video group.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => showStartOverConfirmation(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => newVideoUpload()}
            variant="contained"
            color="primary"
          >
            Start Over
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={12}>
          <Card className={classes.addTcinsCard}>
            <Toolbar>
              <Grid
                justify="space-between"
                container
                item
                spacing={3}
                alignItems="center"
              >
                <Grid item>
                  <Button
                    className={classes.margin10}
                    onClick={() => showStartOverConfirmation(true)}
                    variant="contained"
                    color="primary"
                  >
                    Start Over
                  </Button>
                </Grid>
                <Grid item>
                  <TextField
                    multiline
                    rows="1"
                    margin="normal"
                    onChange={(e) => editModeSetTitle(e.target.value)}
                    value={editModeTitle}
                    className={classes.formControl}
                    placeholder="Enter Video Title"
                    error={editModeTitle < 1}
                    helperText={editModeTitle < 1 ? 'Video title required' : ''}
                    disabled={
                      videoGroupStatus !== null &&
                      (videoGroupStatus === VIDEO_APPROVAL_STATUS.APPROVED ||
                        videoGroupStatus ===
                          VIDEO_APPROVAL_STATUS.IN_PROCESS) &&
                      editMode === VIDEO_EDIT_MODE.HISTORY
                    }
                  />
                </Grid>
                {videoGroupStatus === VIDEO_APPROVAL_STATUS.APPROVED &&
                  showVideoActiveCheckbox && (
                    <Grid item>
                      <Checkbox
                        checked={videoIsActive}
                        onChange={() => setVideoActive(!videoIsActive)}
                        color="primary"
                      />
                      <span>
                        {videoIsActive
                          ? 'Uncheck to disable video'
                          : 'Check to enable video'}
                      </span>
                    </Grid>
                  )}
                {!showVideoActiveCheckbox && !showRejectButtons ? (
                  <Grid item>
                    <Button
                      data-test-id="send-to-target-button"
                      className={classes.margin10}
                      onClick={sendToTarget}
                      disabled={
                        editModeTitle.length <= 0 ||
                        selectedTcins.length <= 0 ||
                        video.length <= 0 ||
                        !videoFileAdded ||
                        closedCaption.length <= 0 ||
                        !ccFileAdded ||
                        (video[0] &&
                          video[0].reject_reason &&
                          video[0].reject_reason.length > 0) ||
                        (closedCaption[0] &&
                          closedCaption[0].reject_reason &&
                          closedCaption[0].reject_reason.length > 0) ||
                        (posterFrame[0] &&
                          posterFrame[0].reject_reason &&
                          posterFrame[0].reject_reason.length > 0) ||
                        (transcript[0] &&
                          transcript[0].reject_reason &&
                          transcript[0].reject_reason.length > 0) ||
                        (videoGroupStatus !== null &&
                          videoGroupStatus === VIDEO_APPROVAL_STATUS.IN_PROCESS)
                      }
                      variant="contained"
                      color="primary"
                    >
                      Send To Target
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      data-test-id="approve-video-group-button"
                      className={classes.margin10}
                      onClick={approveVideoGroup}
                      disabled={
                        selectedTcins.length < 1 ||
                        editModeTitle.length < 1 ||
                        (videoGroupStatus === VIDEO_APPROVAL_STATUS.APPROVED &&
                          requestedVideoData.title === editModeTitle &&
                          requestedVideoData.active === videoIsActive &&
                          difference(
                            requestedVideoData.applied_tcins_set,
                            selectedTcins.map((tcin) => tcin.tcin)
                          ).length === 0 &&
                          difference(
                            selectedTcins.map((tcin) => tcin.tcin),
                            requestedVideoData.applied_tcins_set
                          ).length === 0)
                      }
                      variant="contained"
                      color={rejectVideoGroup ? 'secondary' : 'primary'}
                    >
                      {payloadButtonText(
                        rejectVideoGroup,
                        videoGroupStatus,
                        showVideoActiveCheckbox
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
            <TcinCardTableContainer />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

// @ts-ignore
export default withStyles(styles)(TcinCard)
