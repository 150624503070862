import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { TextField, Button, Grid } from '@material-ui/core'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { regexSelector } from '../../../../../Shared/SharedUtils'

/* eslint-disable */
const renderTextField = ({
  input,
  label,
  value,
  meta: { touched, error },
  ...custom
}) => (
    <TextField
      type='number'
      onInput={(e) => regexSelector('number', e)}
      fullWidth
      multiline
      rowsMax="1"
      autoFocus
      margin="normal"
      {...input}
      {...custom}
    />
  )

let AddTcin = (props) => {
  const { getImageData, disabled, classes } = props
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item>
          <Field
            disabled={disabled}
            id="classifierImageTcinList"
            name="classifierImageTcinList"
            component={renderTextField}
            placeholder="enter a TCIN"
            className={classes.textFieldWidth}
          />
          <Button
            variant='contained'
            color='primary'
            disabled={disabled}
            className={classes.searchTcinButton}
            onClick={getImageData}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}

AddTcin = reduxForm({ form: 'classifierSelectionForm' })(AddTcin)
AddTcin = connect(
  state => ({
    initialValues: {
      classifierImageTcinList: state.imageTagTraining.classifierSelection.tcinList[0] ? state.imageTagTraining.classifierSelection.tcinList[0] : '' ,
    },
  }),
)(AddTcin)
export default withStyles(styles)(AddTcin)
