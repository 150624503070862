import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@material-ui/core'
import MDReactComponent from 'markdown-react-js'
import { some } from 'lodash'

function HeaderSection(props) {
  const {
    classes,
    clearData,
    selectedDataLength,
    publishData,
    uploadDataLength,
    handleDownload,
    isCopyEdited,
    downloadBlankTemplate,
    checkUpload,
    showBulletCountDialog,
    cancelPublish,
    bulletCountMessage,
    uploadData,
  } = props

  return (
    <div>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Button
                className={classes.secondaryButton}
                onClick={() => downloadBlankTemplate()}
                variant="outlined"
                color="primary"
              >
                Get Template
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <Button
                className={classes.secondaryButton}
                onClick={clearData}
                variant="outlined"
                color="primary"
              >
                Discard
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={
                  selectedDataLength <= 0 ||
                  some(
                    uploadData,
                    (data) =>
                      data.isLongCopyEditable || data.isFeatureBulletsEditable
                  )
                }
                className={classes.primaryButton}
                onClick={checkUpload}
                variant="contained"
                color="primary"
              >
                Publish Content
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {uploadDataLength > 0 && (
              <Grid item>
                <div className={classes.itemsCountContainer}>
                  <span>{uploadDataLength} items</span>
                  {!isCopyEdited && (
                    <Button
                      className={`${classes.secondaryButton} ${classes.downloadDataButton}`}
                      onClick={() => handleDownload()}
                      variant="outlined"
                      color="primary"
                    >
                      D/L from database
                    </Button>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={showBulletCountDialog}
        onClose={cancelPublish}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {bulletCountMessage
            ? bulletCountMessage.title
            : 'Highlights / Feature Bullet Count'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {bulletCountMessage ? (
              <MDReactComponent text={bulletCountMessage.body} />
            ) : (
              <React.Fragment>
                <div>
                  You have at least one item that does not have the recommended
                  number of bullets.
                </div>
                <p>Target recommends three bullet points as a minimum.</p>
                <p>Press cancel to go back or publish to continue.</p>
              </React.Fragment>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelPublish} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={publishData} variant="contained" color="primary">
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default withStyles(styles)(HeaderSection)
