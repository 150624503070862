/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import VideoReviewDataContainerHead from './VideoReviewDataContainerHead'
import VideoReviewDataContainerToolbar from './VideoReviewDataContainerToolbar'
import VideoReviewDataContainerTitle from './VideoReviewDataContainerTitle'
import styles from '../../theme'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  Grid,
  Tooltip,
  Dialog,
} from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import { Icon } from '../../../../react-praxis-components/src/SideNav'
import WarningIcon from '@material-ui/icons/Warning'
import DoneIcon from '@material-ui/icons/Done'
import EnabledIcon from '@material-ui/icons/Visibility'
import DisabledIcon from '@material-ui/icons/VisibilityOff'
import CachedIcon from '@material-ui/icons/Cached'
// @ts-ignore
import NoThumbnail from '../../../../../images/NoThumbnail.svg?url'
import { VIDEO_APPROVAL_STATUS } from '../../../../Shared/Constants'
import { isValidVideoStatus } from '../../../../Shared/SharedUtils'
import TableCellCollapsible from '../../../../Shared/TableCellCollapsible/TableCellCollapsible'

function VideoReviewData(props) {
  const {
    selectedVideo = [],
    classes = {},
    data = [],
    page = 0,
    rowsPerPage = 0,
    emptyRows = 0,
    handleSelectAllClick = () => {},
    isSelected = false,
    handleClick = () => {},
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    totalElements = 0,
    auth = {},
    requestVideoReviewData = () => {},
    selectedFilters = [],
    sortDirection = 'ASC',
    sortField = 'STATUS',
    setSortDirection = () => {},
    setSortField = () => {},
    savePaginationDataEvent = () => {},
    pageName = 'VideosReview',
    requestVideoDataById = () => {},
    setShowRejectButtons = () => {},
    setShowVideoActiveCheckbox = () => {},
    resetRejectionMessages = () => {},
  } = props

  const [state, setState] = React.useState({
    collapsedRow: null,
    openVideoPlayer: false,
    videoUrl: null,
    videoCcUrl: null,
  })

  const handleExpansion = (index) => {
    if (state.collapsedRow === index) {
      setState({ ...state, collapsedRow: null })
    } else setState({ ...state, collapsedRow: index })
  }

  const formatDate = (date) => {
    let newDate = new Date(date)
    let month = newDate.getMonth() + 1
    let day = newDate.getDate()
    let year = newDate.getFullYear()
    let hours = newDate.getHours()
    let minutes = newDate.getMinutes()
    let amOrpm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours !== 0 ? hours : 12
    // @ts-ignore
    minutes = minutes < 10 ? '0' + minutes : minutes
    var time = hours + ':' + minutes + ' ' + amOrpm
    let formattedDate = month + '/' + day + '/' + year + ' ' + time
    return formattedDate
  }

  return (
    <div>
      <Dialog
        open={state.openVideoPlayer}
        onClose={() =>
          setState({
            ...state,
            videoUrl: null,
            openVideoPlayer: false,
            videoCcUrl: null,
          })
        }
        aria-labelledby="form-dialog-title"
        maxWidth={false}
      >
        <video controls crossOrigin="anonymous" src={state.videoUrl}>
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={state.videoCcUrl}
            default
          />
        </video>
      </Dialog>
      <Paper className={classes.fullWidth}>
        {data.length > 0 && (
          <React.Fragment>
            <VideoReviewDataContainerTitle
              numSelected={selectedVideo.length}
              totalElements={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <VideoReviewDataContainerHead
                  numSelected={selectedVideo.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rowsPerPage}
                  videoDataLength={data.length}
                  selectedFilters={selectedFilters}
                  sortDirection={sortDirection}
                  sortField={sortField}
                  requestVideoReviewData={requestVideoReviewData}
                  setSortDirection={setSortDirection}
                  setSortField={setSortField}
                  savePaginationDataEvent={savePaginationDataEvent}
                  pageName={pageName}
                />
                <TableBody>
                  {data.map((video, index) => {
                    const uniqueDivisions = [...new Set(video.division)]
                    const findIsSelected = isSelected(video)
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={findIsSelected}
                        tabIndex={-1}
                        key={video.external_group_job_id}
                        selected={findIsSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={findIsSelected}
                            color="primary"
                            onClick={(event) => handleClick(event, video)}
                          />
                        </TableCell>
                        <TableCell padding="none">
                          {video.approvalStatus ===
                            VIDEO_APPROVAL_STATUS.APPROVED ||
                          video.approvalStatus ===
                            VIDEO_APPROVAL_STATUS.IN_PROCESS ? (
                            <Icon>
                              <Tooltip
                                title={
                                  video.approvalStatus ===
                                  VIDEO_APPROVAL_STATUS.APPROVED
                                    ? 'Edit'
                                    : 'Review'
                                }
                              >
                                <span
                                  role="presentation"
                                  onClick={() => {
                                    resetRejectionMessages()
                                    if (
                                      video.approvalStatus ===
                                      VIDEO_APPROVAL_STATUS.APPROVED
                                    ) {
                                      setShowVideoActiveCheckbox(true)
                                      setShowRejectButtons(false)
                                      requestVideoDataById(
                                        video.external_group_job_id
                                      )
                                    } else {
                                      setShowVideoActiveCheckbox(false)
                                      setShowRejectButtons(true)
                                      requestVideoDataById(
                                        video.external_group_job_id
                                      )
                                    }
                                  }}
                                  className={classes.pointer}
                                >
                                  <OpenInBrowserIcon />
                                </span>
                              </Tooltip>
                            </Icon>
                          ) : (
                            <Grid
                              container
                              direction="row"
                              className={classes.blockedIcon}
                              alignItems="center"
                            >
                              <BlockIcon />
                            </Grid>
                          )}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.maxWidth200}
                        >
                          {video.approvalStatus ===
                            VIDEO_APPROVAL_STATUS.APPROVED && (
                            <React.Fragment>
                              <div
                                style={{ color: video.statusColor }}
                                className={classes.iconMessage}
                              >
                                <DoneIcon className={classes.marginRight10} />
                                <span>Approved</span>
                              </div>
                              {video.active ? (
                                <div className={classes.iconMessage}>
                                  <EnabledIcon
                                    className={classes.marginRight10}
                                  />
                                  <span>Enabled</span>
                                </div>
                              ) : (
                                <div className={classes.iconMessage}>
                                  <DisabledIcon
                                    className={classes.marginRight10}
                                  />
                                  <span>Disabled</span>
                                </div>
                              )}
                            </React.Fragment>
                          )}
                          {video.approvalStatus ===
                            VIDEO_APPROVAL_STATUS.IN_PROCESS && (
                            <div
                              style={{ color: video.statusColor }}
                              className={classes.iconMessage}
                            >
                              <SpeakerNotesIcon
                                className={classes.marginRight10}
                              />
                              <span>Ready for Review</span>
                            </div>
                          )}
                          {(video.approvalStatus ===
                            VIDEO_APPROVAL_STATUS.PENDING ||
                            video.approvalStatus ===
                              VIDEO_APPROVAL_STATUS.RETRY ||
                            !video.approvalStatus) && (
                            <div
                              style={{ color: video.statusColor }}
                              className={classes.iconMessage}
                            >
                              <CachedIcon className={classes.marginRight10} />
                              <span>Processing...</span>
                            </div>
                          )}
                          {video.approvalStatus ===
                            VIDEO_APPROVAL_STATUS.REJECTED && (
                            <div
                              style={{ color: video.statusColor }}
                              className={classes.iconMessage}
                            >
                              <WarningIcon className={classes.marginRight10} />
                              <span>{video.statusMessage}</span>
                            </div>
                          )}
                          {!isValidVideoStatus(video.approvalStatus) && (
                            <div
                              style={{ color: video.statusColor }}
                              className={classes.iconMessage}
                            >
                              <div>
                                <div>
                                  <WarningIcon
                                    className={classes.marginRight10}
                                  />
                                  <span>
                                    {video?.errorMessage ||
                                      'Error: Please Notify Support'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          <Tooltip title="Click to preview video">
                            <img
                              tabIndex={index}
                              src={
                                video.posterFrameUrl
                                  ? video.posterFrameUrl
                                  : NoThumbnail
                              }
                              alt={video.title}
                              className={classes.posterFrame}
                              onClick={() =>
                                setState({
                                  ...state,
                                  openVideoPlayer: true,
                                  videoCcUrl: video.ccUrl,
                                  videoUrl: video.videoUrl,
                                })
                              }
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  setState({
                                    ...state,
                                    openVideoPlayer: true,
                                    videoCcUrl: video.ccUrl,
                                    videoUrl: video.videoUrl,
                                  })
                                }
                              }}
                              // @ts-ignore
                              onError={(e) => {
                                e.target.src = NoThumbnail
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.maxWidth350}
                        >
                          {video.title}
                        </TableCell>
                        <TableCell padding="none">
                          <TableCellCollapsible
                            list={uniqueDivisions}
                            index={index}
                            collapsedRow={state.collapsedRow}
                            handleExpansion={handleExpansion}
                          />
                        </TableCell>
                        <TableCell padding="none">
                          <TableCellCollapsible
                            list={video.applied_tcins_set}
                            index={index}
                            collapsedRow={state.collapsedRow}
                            handleExpansion={handleExpansion}
                          />
                        </TableCell>

                        <TableCell padding="none">
                          <TableCellCollapsible
                            list={video?.listOfDuplicateTcin}
                            index={index}
                            collapsedRow={state.collapsedRow}
                            handleExpansion={handleExpansion}
                          />
                        </TableCell>

                        <TableCell padding="none">
                          <TableCellCollapsible
                            list={video?.list_of_disabled_tcins}
                            index={index}
                            collapsedRow={state.collapsedRow}
                            handleExpansion={handleExpansion}
                          />
                        </TableCell>

                        <TableCell padding="none">
                          <div>{video.lastModifiedBy}</div>
                          {video.lastModifiedTime
                            ? formatDate(video.lastModifiedTime)
                            : ' - '}
                        </TableCell>
                        <TableCell padding="none">
                          <div>{video.uploadedBy}</div>
                          {video.uploadedTime
                            ? formatDate(video.uploadedTime)
                            : ' - '}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={11} />
                    </TableRow>
                  )}
                  <TableRow className={classes.height49}>
                    <TableCell colSpan={11} />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <VideoReviewDataContainerToolbar
              selectedVideo={selectedVideo}
              auth={auth}
            />
          </React.Fragment>
        )}
      </Paper>
      {!data.length && (
        <div className={classes.noResult}>
          No results matched your filter criteria
        </div>
      )}
    </div>
  )
}

// @ts-ignore
export default withStyles(styles)(VideoReviewData)
