export const ADD_FILES_VIDEO = 'ADD_FILES_VIDEO'
export const VIDEOS_DROP_ZONE_ACTIVE = 'VIDEOS_DROP_ZONE_ACTIVE'
export const VALID_NUMBER_OF_VIDEO_FILES = 'VALID_NUMBER_OF_VIDEO_FILES'
export const VIDEOS_ON_DROP_LOADING = 'VIDEOS_ON_DROP_LOADING'
export const VIDEOS_RESET_FETCH = 'VIDEOS_RESET_FETCH'
export const VIDEO_FILES_ADDED = 'VIDEO_FILES_ADDED'
export const CC_FILES_ADDED = 'CC_FILES_ADDED'
export const POSTER_FRAME_FILES_ADDED = 'POSTER_FRAME_FILES_ADDED'
export const TRANSCRIPT_FILES_ADDED = 'TRANSCRIPT_FILES_ADDED'
export const REMOVE_ALL_FILES = 'REMOVE_ALL_FILES'
export const UPDATE_INVALID_TCIN_LIST = 'UPDATE_INVALID_TCIN_LIST'
export const UPDATE_NOTOWNED_TCIN_LIST = 'UPDATE_NOTOWNED_TCIN_LIST'
export const EDIT_MODE = 'EDIT_MODE'
export const EDIT_MODE_TITLE = 'EDIT_MODE_TITLE'
export const SET_EXTERNAL_GROUP = 'SET_EXTERNAL_GROUP'
export const ADD_DELETE_ASSET = 'ADD_DELETE_ASSET'
export const VIDEO_GROUP_STATUS = 'VIDEO_GROUP_STATUS'
export const UPDATE_SUCCESSFUL_EVENT = 'UPDATE_SUCCESSFUL_EVENT'
export const EDIT_VTT_FILE = 'EDIT_VTT_FILE'
export const SHOW_DEFAULT_ALERT_VIDEOS = 'SHOW_DEFAULT_ALERT_VIDEOS'
export const STORE_VIDEOS_UPLOAD_STRAPI_DATA = 'STORE_VIDEOS_UPLOAD_STRAPI_DATA'
export const OPEN_VIDEO_HELP_CONTENT = 'OPEN_VIDEO_HELP_CONTENT'
export const CLEAR_VIDEO_HELP_CONTENT = 'CLEAR_VIDEO_HELP_CONTENT'
export const RECEIVE_VIDEO_DATA_BY_ID = 'RECEIVE_VIDEO_DATA_BY_ID'
export const CLEAR_VIDEO_DATA_BY_ID = 'CLEAR_VIDEO_DATA_BY_ID'
export const REQUEST_VIDEO_DATA_BY_ID = 'REQUEST_VIDEO_DATA_BY_ID'
export const RECEIVE_TCIN_DATA = 'RECEIVE_TCIN_DATA'
export const REQUEST_ADD_TCIN_TO_VIDEO_GROUP = 'REQUEST_ADD_TCIN_TO_VIDEO_GROUP'
export const SELECT_VIDEO_TCIN_REVIEW_EVENT = 'SELECT_VIDEO_TCIN_REVIEW_EVENT'
export const SET_SHOW_REVIEW_OPTIONS = 'SET_SHOW_REVIEW_OPTIONS'
export const RESET_REJECTION_MESSAGE = 'RESET_REJECTION_MESSAGE'
export const SET_SHOW_VIDEO_ACTIVE_CHECKBOX = 'SET_SHOW_VIDEO_ACTIVE_CHECKBOX'
export const SET_VIDEO_ACTIVE = 'SET_VIDEO_ACTIVE'
export const SET_REJECTION_MESSAGE = 'SET_REJECTION_MESSAGE'
export const SHOW_START_OVER_CONFIRMATION = 'SHOW_START_OVER_CONFIRMATION'
