import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import {
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import MDReactComponent from 'markdown-react-js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function StrapiExpansionPanel(props) {
  const {
    faq = null,
    information = null,
    expansionPanelOpen = false,
    handleFaqPanel = () => {},
    classes = {},
  } = props

  return (
    <Accordion
      className={classes.buildFilterExpansion}
      expanded={expansionPanelOpen}
      onChange={() => handleFaqPanel()}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {expansionPanelOpen ? 'Hide ' : 'Show '} Help/FAQ
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {information || faq ? (
          <CardContent>
            {faq && faq.title ? (
              <MDReactComponent
                className={`${classes.marginBottom10} ${classes.header}`}
                text={faq.title}
              />
            ) : null}
            {faq && faq.body ? (
              <MDReactComponent className={classes.body} text={faq.body} />
            ) : null}
            {information && information.title ? (
              <MDReactComponent
                className={`${classes.marginBottom10} ${classes.header}`}
                text={information.title}
              />
            ) : null}
            {information && information.body ? (
              <MDReactComponent
                className={classes.body}
                text={information.body}
              />
            ) : null}
          </CardContent>
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}

export default withStyles(styles)(StrapiExpansionPanel)
