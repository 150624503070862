import {
  ENTERED_TCIN,
  ENTERED_MTA_ID,
  PROD_SPECS_RESET_FETCH,
  STORE_TCIN_PROD_SPECS,
  STORE_TCIN_IMAGE_DATA,
  STORE_TCIN_MTA_DATA,
  STORE_MTA_DATA,
  CLEAR_MTA_DATA,
} from './actionType'

const initState = {
  enteredTcin: '',
  enteredMtaId: '',
  itemProdSpecs: null,
  itemImageData: null,
  itemMtaData: null,
  mtaData: null,
  isFetching: false,
}

export default function prodSpecsReducer(state = initState, action = {}) {
  switch (action.type) {
    case ENTERED_TCIN: {
      const { payload } = action
      const newState = {
        ...state,
        enteredTcin: payload,
        itemProdSpecs:
          payload === '' ? initState.itemProdSpecs : state.itemProdSpecs,
        itemImageData:
          payload === '' ? initState.itemImageData : state.itemImageData,
        itemMtaData: payload === '' ? initState.itemMtaData : state.itemMtaData,
        mtaData: payload === '' ? initState.mtaData : state.mtaData,
      }
      return newState
    }
    case ENTERED_MTA_ID: {
      const { payload } = action
      const newState = {
        ...state,
        enteredMtaId: payload,
        mtaData: payload === '' ? initState.mtaData : state.mtaData,
      }
      return newState
    }
    case PROD_SPECS_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case STORE_TCIN_PROD_SPECS: {
      const { payload } = action
      const newState = {
        ...state,
        itemProdSpecs: payload,
      }
      return newState
    }
    case STORE_TCIN_IMAGE_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        itemImageData: payload,
      }
      return newState
    }
    case STORE_TCIN_MTA_DATA: {
      const { payload } = action
      const merchandiseClassification = payload.merchandise_classification
      const productClassification = payload.product_classification
      const merchandiseTypeAtttributes = payload.merchandise_type_attributes
      const newState = {
        ...state,
        itemMtaData:
          merchandiseClassification &&
          productClassification &&
          merchandiseTypeAtttributes
            ? {
                merchandiseClassification,
                productClassification,
                merchandiseTypeAtttributes,
              }
            : initState.itemMtaData,
      }
      return newState
    }
    case STORE_MTA_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        mtaData: payload,
      }
      return newState
    }
    case CLEAR_MTA_DATA: {
      const newState = {
        ...state,
        mtaData: initState.mtaData,
      }
      return newState
    }
    default:
      return state
  }
}
