import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import styles from '../theme'

const DisableAltText = (props) => {
  const { classes = {}, disabled = false } = props

  return (
    <Button disabled={disabled} className={classes.drawerButton}>
      <NotInterestedIcon />
      <span
        className={classes.marginLeft5}
        onClick={() => props.disableAltTextSearchResults()}
      >
        Disable
      </span>
    </Button>
  )
}

// @ts-ignore
export default withStyles(styles)(DisableAltText)
