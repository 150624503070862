export const REQUEST_ALTTEXT_UPLOAD_DATA = 'REQUEST_ALTTEXT_UPLOAD_DATA'
export const RECEIVE_ALTTEXT_UPLOAD_DATA = 'RECEIVE_ALTTEXT_UPLOAD_DATA'
export const BULK_UPLOAD_ALTTEXT_RESET_FETCH = 'BULK_UPLOAD_ALTTEXT_RESET_FETCH'
export const BULKUPLOAD_ALTTEXT_DROP_ZONE_ACTIVE =
  'BULKUPLOAD_ALTTEXT_DROP_ZONE_ACTIVE'
export const WRONG_FILE_ALTTEXT_ADDED = 'WRONG_FILE_ALTTEXT_ADDED'
export const CLEAR_ALTTEXT_UPLOAD_DATA = 'CLEAR_ALTTEXT_UPLOAD_DATA'
export const PUBLISH_EVENT_ALTTEXT_SUCCESS = 'PUBLISH_EVENT_ALTTEXT_SUCCESS'
export const STORE__ALTTEXT_BULK_UPLOAD_STRAPI_DATA =
  'STORE__ALTTEXT_BULK_UPLOAD_STRAPI_DATA'
export const SHOW_DEFAULT_ALTTEXT_ALERT_BULK_UPLOAD =
  'SHOW_DEFAULT_ALTTEXT_ALERT_BULK_UPLOAD'
export const NEW_FILTER_EVENT_ALTTEXT_SEARCH = 'NEW_FILTER_EVENT_ALTTEXT_SEARCH'
export const BUILD_FILTER_EXPAND_ALTTEXT_SEARCH =
  'BUILD_FILTER_EXPAND_ALTTEXT_SEARCH'
export const REMOVE_FILTER_EVENT_ALTTEXT_SEARCH =
  'REMOVE_FILTER_EVENT_ALTTEXT_SEARCH'
export const CHANGE_FILTER_EVENT_ALTIMAGE_SEARCH =
  'CHANGE_FILTER_EVENT_ALTIMAGE_SEARCH'
export const SELECT_FILTER_VALUE_EVENT_ALTIMAGE_SEARCH =
  'SELECT_FILTER_VALUE_EVENT_ALTIMAGE_SEARCH'
export const CHANGE_CURRENT_PAGE_ALTIMAGE_SEARCH =
  'CHANGE_CURRENT_PAGE_ALTIMAGE_SEARCH'
export const BUILD_FILTER_EVENT_ALTIMAGE_SEARCH =
  'BUILD_FILTER_EVENT_ALTIMAGE_SEARCH'
export const CLEAR_ALTIMAGE_SEARCH_DATA_EVENT =
  'CLEAR_ALTIMAGE_SEARCH_DATA_EVENT'

export const ERROR_SEARCHING_ALTIMAGE_SEARCH = 'ERROR_SEARCHING_ALTIMAGE_SEARCH'

export const REQUEST_ALTIMAGE_SEARCH_DATA = 'REQUEST_ALTIMAGE_SEARCH_DATA'

export const RECEIVE_ALTIMAGE_SEARCH_DATA = 'RECEIVE_ALTIMAGE_SEARCH_DATA'

export const DISABLE_ALT_TEXT = 'DISABLE_ALT_TEXT'
export const CHANGE_DEFAULT_PAGE_SIZE_ALTIMAGE_SEARCH =
  'CHANGE_DEFAULT_PAGE_SIZE_ALTIMAGE_SEARCH'
export const SELECT_ALT_TEXT_SEARCH_EVENT = 'SELECT_ALT_TEXT_SEARCH_EVENT'
export const REMOVE_FILTER_EVENT_ALTIMAGE_SEARCH =
  'REMOVE_FILTER_EVENT_ALTIMAGE_SEARCH'

export const STORE_ALTIMAGE_SEARCH_STRAPI_DATA =
  'STORE_ALTIMAGE_SEARCH_STRAPI_DATA'

export const UPDATE_ALT_TEXT_EDIT_STATE = 'UPDATE_ALT_TEXT_EDIT_STATE'
export const UPDATE_ALT_TEXT = 'UPDATE_ALT_TEXT'
export const UPDATE_SELECTED_ALT_TEXT = 'UPDATE_SELECTED_ALT_TEXT'

export const ALTTEXT_DELETE_CONFIRMATION = 'ALTTEXT_DELETE_CONFIRMATION'

export const REMOVE_ALTTEXT_ROW = 'REMOVE_ALTTEXT_ROW'
export const SELECT_ALTTEXT_DATA_EVENT = 'SELECT_ALTTEXT_DATA_EVENT'
export const ALTTEXT_PROFANITY_API_LOADER = 'ALTTEXT_PROFANITY_API_LOADER'
export const ALTTEXT_SEARCH_ITEM = 'ALTTEXT_SEARCH_ITEM'
export const UPDATE_ALTTEXT_ITEM_SEARCH = 'UPDATE_ALTTEXT_ITEM_SEARCH'
export const WRONG_FILE_ADDED = 'WRONG_FILE_ADDED'
export const DOWNLOAD_FINISH_ALT_BULK_UPLOAD = 'DOWNLOAD_FINISH_ALT_BULK_UPLOAD'
export const DOWNLOAD_START_ALT_BULK_UPLOAD = 'DOWNLOAD_START_ALT_BULK_UPLOAD'
export const NON_APPROVED_ALT_TEXT = 'NON_APPROVED_ALT_TEXT'
export const RECEIVE_NON_APPROVED_TCINS = 'RECEIVE_NON_APPROVED_TCINS'
