const styles = (theme) => ({
  suggestionMenu: {
    maxHeight: '300px',
    width: '100%',
    overflowY: 'auto',
    padding: 0,
    backgroundColor: 'white',
  },
  input: {
    width: '100%',
  },
  suggestionDiv: {
    width: '100%',
    height: '100%',
    maxHeight: '30px',
    display: 'inline-flex',
    textAlign: 'left',
  },
  positionMenu: {
    position: 'fixed',
    zIndex: '100000',
  },
})

export default styles
