import React from 'react'
import TableUploadData from './TableUploadData'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  handleSelectData,
  deleteDataEvent,
  revertBackFeatureBullets,
  revertBackLongCopy,
  handleDeleteConfirmation,
  updateSelectedFeatureBullets,
  updateSelectedLongCopy,
  checkProfanity,
} from '../../../../../store/bulkUpload/tableActionCreator'
import { indexOfObject, gridCheckedLogic } from '../../../../Shared/SharedUtils'

class TableUploadDataContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  handleSelectAllClick = (_, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = this.props.uploadData.map((item) => {
        var data = {}
        data.tcin = item.tcin
        data.longCopy = item.longCopy
        data.featureBullets = item.featureBullets
        data.valid = item.valid
        return data
      })
    }
    this.props.handleSelectData({
      selectedData: newSelected,
    })
  }

  handleClick = (_, data) => {
    const { selectedData } = this.props
    var item = {}
    item.tcin = data.tcin
    item.longCopy = data.longCopy
    item.featureBullets = data.featureBullets

    var updatedNewSelected = gridCheckedLogic(selectedData, item).map(
      (item) => {
        var data = {}
        data.tcin = item.tcin
        data.longCopy = item.longCopy
        data.featureBullets = item.featureBullets
        data.valid = item.valid
        return data
      }
    )
    this.props.handleSelectData({
      selectedData: updatedNewSelected,
    })
  }

  isSelected = (item) => {
    var data = {}
    data.tcin = item.tcin
    data.longCopy = item.longCopy
    data.featureBullets = item.featureBullets
    return indexOfObject(this.props.selectedData, data) !== -1
  }

  revertBackFeatureBullets = (tcin) => {
    var oldData = this.props.defaultUploadData.filter(
      (data) => data.tcin === tcin
    )
    var currentData = this.props.uploadData.filter((data) => data.tcin === tcin)
    this.props.checkProfanity(
      tcin,
      currentData[0].longCopy,
      oldData[0].featureBullets,
      'revertFeatureBullet'
    )
  }

  revertBackLongCopy = (tcin) => {
    var oldData = this.props.defaultUploadData.filter(
      (data) => data.tcin === tcin
    )
    var currentData = this.props.uploadData.filter((data) => data.tcin === tcin)
    this.props.checkProfanity(
      tcin,
      oldData[0].longCopy,
      currentData[0].featureBullets,
      'revertLongCopy'
    )
  }

  handleDeleteConfirmation = (tcin) => {
    this.props.handleDeleteConfirmation(tcin)
  }

  render() {
    const { uploadData, selectedData } = this.props
    return (
      <TableUploadData
        uploadData={uploadData}
        selected={selectedData}
        handleSelectAllClick={this.handleSelectAllClick}
        isSelected={this.isSelected}
        handleClick={this.handleClick}
        revertBackFeatureBullets={this.revertBackFeatureBullets}
        revertBackLongCopy={this.revertBackLongCopy}
        handleDeleteConfirmation={this.handleDeleteConfirmation}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleSelectData,
      deleteDataEvent,
      revertBackFeatureBullets,
      revertBackLongCopy,
      handleDeleteConfirmation,
      updateSelectedFeatureBullets,
      updateSelectedLongCopy,
      checkProfanity,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { bulkUpload, auth, form } = state
  const { bulkUploadData } = form
  const { selectedData, uploadData, defaultUploadData } = bulkUpload
  return {
    selectedData,
    uploadData,
    auth,
    bulkUploadData,
    defaultUploadData,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableUploadDataContainer)
