// eslint-disable-next-line no-redeclare
/* global localStorage: false */

// External Imports
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Routes, NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Link as MUILink } from '@material-ui/core'
import { includes } from 'lodash'
import SideNav, {
  Header,
  Icon,
  Text,
} from '../../components/react-praxis-components/src/SideNav'
import UserBar from '../../components/react-praxis-components/src/UserBar'
import * as tokenDecoder from 'jwt-decode'

// Utils/Actions
import firefly from '../../analytics/firefly'
import { isExternalUser } from '../Shared/SharedUtils'
import { closeSideNav, openSideNav } from '../../store/layout/actionCreator'
import { ROUTES } from '../Shared/Constants'

// Components
import AppHeader from '../Shared/Header/Header'
// import Auth from '../Auth/Auth'
import Badges from '../Badges/Badges'
import HomePage from '../HomePage/HomePage'
import ImagesHeader from '../Images/ImagesHeader'
import AlttextHeader from '../AltTextImages/AltTextHeader'
import BulkUploadImages from '../Images/Upload/BulkUploadImages'
import Classifier from '../Images/Tags/Classifier/Classifier'
import CreateTag from '../Images/Tags/CreateTag/CreateTag'
import ImageUploadHistory from '../Images/UploadHistory/ImageUploadHistory'
import Search from '../Images/Search/Search'
import ImageReview from '../Images/Search/ImageReview'
import LookCloser from '../Images/Search/LookCloser'
import ImageReviewHistory from '../Images/Search/ImageReviewHistory'
import Manage from '../Images/Manage/Manage'
import Tags from '../Images/Tags/TagsOverview/Tags'
import TagTraining from '../Images/Tags/Training/TagTraining'
import LongCopyHeader from '../LongCopy/LongCopyHeader'
import BulkUpload from '../LongCopy/BulkUpload/BulkUpload'
import BulkUploadReport from '../LongCopy/BulkUploadReport/BulkUploadReport'
import EditLongCopy from '../LongCopy/SearchAndManage/EditLongCopy'
import LongCopy from '../LongCopy/SearchAndManage/LongCopy'
import ProductSpecs from '../ProductSpecs/ProductSpecs'
import SignInPrompt from '../SignInPrompt/SignInPrompt'
import RulesBuilder from '../RuleBuilder/RulesBuilder'
import RulesSelection from '../RulesSelection/SizeChartRulesSelection'
import SizeChartUI from '../RuleBuilder/Components/SizeChart/SizeChartUI'
import VideoHeader from '../Videos/VideoHeader'
import Videos from '../Videos/Upload/Videos'
import VideoHistory from '../Videos/History/VideosHistory'
import VideosReview from '../Videos/Review/VideosReview'
import StageCoronaPage from '../StageCorona/StageCoronaPage'
import HelpHeader from '../Help/HelpHeader'
import StyleGuides from '../Help/StyleGuides/StyleGuides'
import AltTextBulkUpload from '../AltTextImages/AltTextBulkUpload/AltTextBulkUpload'
import AltTextSearch from '../AltTextImages/AltTextSearch/AltTextSearch'
import styles from './theme'

// Icons
import { CopyBulletsIcon, VideoIcon } from './ApplicationIcons'
import List from '@material-ui/icons/List'
import StarIcon from '@material-ui/icons/Stars'
import HomeIcon from '@material-ui/icons/Home'
import StraightenIcon from '@material-ui/icons/Straighten'
import Polymer from '@material-ui/icons/Polymer'
import Info from '@material-ui/icons/Info'
import PhotoIcon from '@material-ui/icons/Photo'
// @ts-ignore
import SlackIcon from '../../images/SlackIcon.svg?url'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import { withAuth } from '@praxis/component-auth'
import {
  signInSuccess,
  fetchUserProfile,
  signOutAction,
  fetchSavedFiltersEvent,
} from '../../store/auth/actionCreator'

export const isExternalUrl = (hostName) => {
  return (
    includes(hostName, 'vendorpipeline') ||
    window.location.hostname.includes('partnersonline.com')
  )
}

export const isVendorPipeline = (hostName) => {
  return includes(hostName, 'vendorpipeline')
}

function Layout(props) {
  useEffect(() => {
    handleValidateToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const urlPath = window.location.pathname
  useEffect(() => {
    if (urlPath.indexOf('/v2/longcopy/editCopy') >= 0) {
      firefly.trackView('/v2/longcopy/editCopy')
    } else if (
      urlPath === '/' ||
      urlPath === '' ||
      urlPath === '/v2/' ||
      urlPath === '/v2'
    ) {
      firefly.trackView('/v2/homepage')
    } else {
      firefly.trackView(urlPath)
    }
  }, [urlPath])

  const handleMenuButtonClick = () => {
    if (props.sideNavIsOpen) {
      props.closeSideNav()
    } else {
      props.openSideNav()
    }
  }

  const handleCloseSideNav = () => {
    if (props.sideNavIsOpen) {
      props.closeSideNav()
    }
  }

  const {
    classes = {},
    user = {},
    headerTitle = '',
    sideNavIsOpen = false,
    login: signIn,
    logout: signOut,
    session,
  } = props

  const fullName = session
    ? `${session.userInfo.firstName} ${session.userInfo.lastName}`
    : undefined

  const userIsExternal = isExternalUser(user)

  useEffect(() => {
    const { session, signInSuccess, fetchUserProfile, signOutAction } = props
    let IdentifyUserInfo = session ? session.identity : {}
    let accessToken = session && session.userInfo.accessToken
    const userInfo = { ...IdentifyUserInfo, accessToken }
    const BearerToken = localStorage.getItem('id_token')
    if (BearerToken) {
      signInSuccess(userInfo)
      fetchUserProfile(localStorage.getItem('id_token'))
    } else {
      signOutAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  const handleValidateToken = () => {
    const idToken = localStorage.getItem('id_token')
    if (idToken) {
      const currentTime = new Date().getTime() / 1000
      const tokenDecoded = tokenDecoder(idToken)
      if (currentTime > tokenDecoded.exp) {
        signOut()
      }
    }
  }

  useEffect(() => {
    props.fetchSavedFiltersEvent()
  }, [])

  return (
    <div>
      <Helmet defaultTitle="Pipeline" titleTemplate="%s - Pipeline" />
      <SideNav
        className={classes.sideNavList}
        isOpen={sideNavIsOpen}
        onClose={handleCloseSideNav}
      >
        <Header>
          <UserBar
            displayName={fullName}
            handleSignIn={signIn}
            handleSignOut={signOut}
            isAuthorized={session && session.isAuthorized()}
          />
        </Header>

        {user.isAuthorized ? (
          <NavLink
            to="/v2/homepage"
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <HomeIcon />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>Home</Text>
          </NavLink>
        ) : null}

        {user.isAuthorized && !isExternalUrl(user.permission.hostName) ? (
          <NavLink
            to="/v2/badges"
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <StarIcon />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>Badges</Text>
          </NavLink>
        ) : null}

        {user.isAuthorized &&
        user.permission.cgi &&
        isExternalUrl(user.permission.hostName) ? (
          <MUILink
            href={'https://extgargantua.partnersonline.com/'}
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
          >
            <Icon className={classes.whiteIcon}>
              <Polymer />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>CGI</Text>
          </MUILink>
        ) : null}

        {/* If User has Long Copy & Feature Bullet Permission permissions */}
        {user.isAuthorized &&
        user.permission.longCopy &&
        user.permission.longCopySearchAndManage &&
        !isVendorPipeline(user.permission.hostName) ? (
          <NavLink
            to="/v2/longcopy"
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <CopyBulletsIcon />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>
              Copy & Bullets
            </Text>
          </NavLink>
        ) : null}

        {/* If User has only Bulk Upload Permission */}
        {user.isAuthorized &&
        user.permission.longCopy &&
        !user.permission.longCopySearchAndManage &&
        user.permission.longCopyBulkUpload &&
        !isVendorPipeline(user.permission.hostName) ? (
          <NavLink
            to="/v2/bulk-upload"
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <CopyBulletsIcon />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>
              Copy & Bullets
            </Text>
          </NavLink>
        ) : null}

        {/* user.isAuthorized && user.permission.isCepSuperUser ? <NavLink to="/v2/dashboards"  className={classes.link} activeClassName={classes.activeLink}>
            <Icon className={classes.whiteIcon}><DashboardIcon /></Icon>
            <Text classes={{ primary: classes.whiteColor }}>Dashboards</Text>
          </NavLink> : null
          */}

        {user.isAuthorized && !isVendorPipeline(user.permission.hostName) ? (
          <NavLink
            to="/v2/help"
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <Info />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>Style Guides</Text>
          </NavLink>
        ) : null}

        {user.isAuthorized &&
        userIsExternal &&
        user.permission.imgReviewUpload &&
        !isVendorPipeline(user.permission.hostName) ? (
          <NavLink
            to={ROUTES.IMAGES.UPLOAD}
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <PhotoIcon />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>Images</Text>
          </NavLink>
        ) : null}

        {user.isAuthorized &&
        !userIsExternal &&
        !isVendorPipeline(user.permission.hostName) ? (
          <NavLink
            to={ROUTES.IMAGES.SEARCH}
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <PhotoIcon />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>Images</Text>
          </NavLink>
        ) : null}

        {user?.userProfile?.enabled_alt_text && (
          <div>
            {user.isAuthorized &&
            !userIsExternal &&
            !isVendorPipeline(user.permission.hostName) ? (
              <NavLink
                to="/v2/altText"
                target="_self"
                className={classes.link}
                onClick={handleCloseSideNav}
                activeClassName={classes.activeLink}
              >
                <Icon className={classes.whiteIcon}>
                  <AddPhotoAlternateIcon />
                </Icon>
                <Text classes={{ primary: classes.whiteColor }}>
                  Image Alternate Text
                </Text>
              </NavLink>
            ) : null}
            {user.isAuthorized &&
            userIsExternal &&
            user.permission.imgReviewUpload &&
            !isVendorPipeline(user.permission.hostName) ? (
              <NavLink
                to={'/v2/altText/bulkUpload'}
                target="_self"
                className={classes.link}
                onClick={handleCloseSideNav}
                activeClassName={classes.activeLink}
              >
                <Icon className={classes.whiteIcon}>
                  <PhotoIcon />
                </Icon>
                <Text classes={{ primary: classes.whiteColor }}>
                  {' '}
                  Image Alternate Text
                </Text>
              </NavLink>
            ) : null}
          </div>
        )}

        {user.isAuthorized &&
        user.permission.prodSpecs &&
        !isVendorPipeline(user.permission.hostName) ? (
          <NavLink
            to="/v2/prodSpecs"
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <List />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>
              ProductSpecs (bullets)
            </Text>
          </NavLink>
        ) : null}

        {user.isAuthorized &&
        user.permission.sizeChartsEdit &&
        !isExternalUrl(user.permission.hostName) ? (
          <NavLink
            to="/v2/size-and-fit"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <StraightenIcon />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>Size & Fit</Text>
          </NavLink>
        ) : null}

        {user.isAuthorized &&
        user.permission.videoUpload &&
        !isVendorPipeline(user.permission.hostName) ? (
          <NavLink
            to="/v2/video/videoEdit/newUpload"
            className={classes.link}
            onClick={handleCloseSideNav}
            activeClassName={classes.activeLink}
          >
            <Icon className={classes.whiteIcon}>
              <VideoIcon />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>Video</Text>
          </NavLink>
        ) : null}

        {/* user.isAuthorized && user.permission.videoUpload ? <NavLink to={isExternalUrl(user.permission.hostName) ? envConfig.hostName.externalUrl + '/video/upload' : envConfig.hostName.internalUrl + '/video/upload'} target="_self" className={classes.link} onClick={handleCloseSideNav} activeClassName={classes.activeLink}>
            <Icon className={classes.whiteIcon}><VideoIcon /></Icon>
            <Text classes={{ primary: classes.whiteColor }}>Video</Text>
            </NavLink> : null
          */}

        {user.isAuthorized && !userIsExternal ? (
          <MUILink
            href={'https://target.slack.com/messages/C82AZSXN0'}
            target="_self"
            className={classes.link}
            onClick={handleCloseSideNav}
          >
            <Icon className={classes.whiteIcon}>
              <img
                src={SlackIcon}
                alt="Slack Icon"
                className={classes.iconSize}
              />
            </Icon>
            <Text classes={{ primary: classes.whiteColor }}>
              #pipeline-support
            </Text>
          </MUILink>
        ) : null}
      </SideNav>
      {/* Below switch case customize headers for every routes. Override AppHeader to customize Header */}
      {user.isAuthorized ? (
        <Routes>
          <Route
            exact
            path="*"
            element={
              <AppHeader
                title={headerTitle}
                menuAction={() => handleMenuButtonClick()}
              />
            }
          />
          {user.permission.longCopySearchAndManage &&
            !isVendorPipeline(user.permission.hostName) && (
              <Route
                exact
                path="/v2/longcopy"
                element={
                  <LongCopyHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {user.permission.longCopySearchAndManage &&
            !isVendorPipeline(user.permission.hostName) && (
              <Route
                exact
                path="/v2/longcopy/editCopy/:id"
                element={
                  <LongCopyHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {user.permission.longCopyBulkUpload &&
            !isVendorPipeline(user.permission.hostName) && (
              <Route
                exact
                path="/v2/bulk-upload"
                element={
                  <LongCopyHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {user.permission.longCopyBulkUpload && !userIsExternal && (
            <Route
              exact
              path="/v2/bulk-upload-report"
              element={
                <LongCopyHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {user.permission.sizeChartsEdit &&
            !isExternalUrl(user.permission.hostName) && (
              <Route
                exact
                path="/v2/size-and-fit"
                element={
                  <AppHeader
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {user.permission.sizeChartsEdit &&
            !isExternalUrl(user.permission.hostName) && (
              <Route
                exact
                path="/v2/size-and-fit/edit-rule"
                element={
                  <AppHeader
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {/* Images */}
          {!isVendorPipeline(user.permission.hostName) && (
            <Route
              exact
              path={ROUTES.IMAGES.ROOT}
              element={
                <ImagesHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {user.permission.imgReviewUpload &&
            !isVendorPipeline(user.permission.hostName) && (
              <Route
                exact
                path={ROUTES.IMAGES.UPLOAD}
                element={
                  <ImagesHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {!isVendorPipeline(user.permission.hostName) && (
            <Route
              exact
              path={ROUTES.IMAGES.UPLOAD_HISTORY}
              element={
                <ImagesHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {!isExternalUrl(user.permission.hostName) && (
            <Route
              exact
              path={ROUTES.IMAGES.SEARCH}
              element={
                <ImagesHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {!isExternalUrl(user.permission.hostName) && (
            <Route
              exact
              path={'/v2/altText'}
              element={
                <AlttextHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}

          {!isExternalUrl(user.permission.hostName) && (
            <Route
              exact
              path={'/v2/altText/bulkUpload'}
              element={
                <AlttextHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}

          {!isExternalUrl(user.permission.hostName) && (
            <Route
              exact
              path={ROUTES.IMAGES.MANAGE}
              element={
                <ImagesHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {!isExternalUrl(user.permission.hostName) &&
            user.permission.imgReview && (
              <Route
                exact
                path={ROUTES.IMAGES.REVIEW}
                element={
                  <ImagesHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {!isExternalUrl(user.permission.hostName) &&
            user.permission.imgReview && (
              <Route
                exact
                path={ROUTES.IMAGES.LOOK_CLOSER}
                element={
                  <ImagesHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {!isExternalUrl(user.permission.hostName) && (
            <Route
              exact
              path={ROUTES.IMAGES.REVIEW_HISTORY}
              element={
                <ImagesHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {user.permission.isCepSuperUser && (
            <Route
              exact
              path={ROUTES.IMAGES.LABELS}
              element={
                <ImagesHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {user.permission.isCepSuperUser && (
            <Route
              exact
              path={ROUTES.IMAGES.LABELS_CREATE}
              element={
                <ImagesHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {user.permission.isCepSuperUser && (
            <Route
              exact
              path={ROUTES.IMAGES.LABELS_TRAINING}
              element={
                <ImagesHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {user.permission.isCepSuperUser && (
            <Route
              exact
              path={ROUTES.IMAGES.LABELS_CLASSIFIER}
              element={
                <ImagesHeader
                  permission={user.permission}
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          )}
          {/* Video */}
          {user.permission.videoUpload &&
            !isVendorPipeline(user.permission.hostName) && (
              <Route
                exact
                path="/v2/video"
                element={
                  <VideoHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {user.permission.videoUpload &&
            !isVendorPipeline(user.permission.hostName) && (
              <Route
                exact
                path="/v2/video/videoEdit/:id"
                element={
                  <VideoHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {user.permission.videoUpload &&
            !isVendorPipeline(user.permission.hostName) && (
              <Route
                exact
                path="/v2/video/history"
                element={
                  <VideoHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {user.permission.videoReview &&
            !isExternalUrl(user.permission.hostName) && (
              <Route
                exact
                path="/v2/video/review"
                element={
                  <VideoHeader
                    permission={user.permission}
                    title={headerTitle}
                    menuAction={() => handleMenuButtonClick()}
                  />
                }
              />
            )}
          {
            <Route
              exact
              path="/v2/help"
              element={
                <HelpHeader
                  title={headerTitle}
                  menuAction={() => handleMenuButtonClick()}
                />
              }
            />
          }
          <Route
            element={
              <AppHeader
                title={headerTitle}
                menuAction={() => handleMenuButtonClick()}
              />
            }
          />
        </Routes>
      ) : (
        <AppHeader
          title={headerTitle}
          menuAction={() => handleMenuButtonClick()}
        />
      )}

      <div className={classes.contentContainer}>
        <Grid container>
          <Grid item xs={12}>
            {user.isAuthorized ? (
              <Routes>
                <Route exact path="/v2/homepage" element={<HomePage />} />
                {/* Long Copy Management Routes */}
                {user.permission.longCopySearchAndManage &&
                !isVendorPipeline(user.permission.hostName) ? (
                  <Route exact path="/v2/longcopy" element={<LongCopy />} />
                ) : null}
                {user.permission.longCopySearchAndManage &&
                !isVendorPipeline(user.permission.hostName) ? (
                  <Route
                    exact
                    path="/v2/longcopy/editCopy/:id"
                    element={<EditLongCopy />}
                  />
                ) : null}
                {user.permission.longCopyBulkUpload &&
                !isVendorPipeline(user.permission.hostName) ? (
                  <Route
                    exact
                    path="/v2/bulk-upload"
                    element={<BulkUpload />}
                  />
                ) : null}

                <Route
                  exact
                  path="/v2/altText/bulkUpload"
                  element={<AltTextBulkUpload />}
                />

                {!isExternalUrl(user.permission.hostName) ? (
                  <Route exact path="/v2/altText" element={<AltTextSearch />} />
                ) : null}

                {user.permission.longCopyBulkUpload && !userIsExternal ? (
                  <Route
                    exact
                    path="/v2/bulk-upload-report"
                    element={<BulkUploadReport />}
                  />
                ) : null}
                {/* Size Chart Routes */}
                {user.permission.sizeChartsEdit &&
                !isExternalUrl(user.permission.hostName) ? (
                  <Route
                    exact
                    path="/v2/size-and-fit"
                    element={<RulesSelection />}
                  />
                ) : null}
                {user.permission.sizeChartsEdit &&
                !isExternalUrl(user.permission.hostName) ? (
                  <Route
                    exact
                    path="/v2/size-and-fit/edit-rule"
                    element={<RulesBuilder />}
                  />
                ) : null}
                {user.permission.sizeChartsEdit &&
                !isExternalUrl(user.permission.hostName) ? (
                  <Route
                    exact
                    path="/v2/size-and-fit/edit-chart"
                    element={<SizeChartUI />}
                  />
                ) : null}
                {/* Image Upload Routes */}
                {user.permission.imgReviewUpload &&
                !isVendorPipeline(user.permission.hostName) ? (
                  <Route
                    exact
                    path={ROUTES.IMAGES.UPLOAD}
                    element={<BulkUploadImages />}
                  />
                ) : null}
                {!isVendorPipeline(user.permission.hostName) && (
                  <Route
                    exact
                    path={ROUTES.IMAGES.UPLOAD_HISTORY}
                    element={<ImageUploadHistory />}
                  />
                )}
                {!isExternalUrl(user.permission.hostName) ? (
                  <Route
                    exact
                    path={ROUTES.IMAGES.SEARCH}
                    element={<Search />}
                  />
                ) : null}
                {!isExternalUrl(user.permission.hostName) &&
                user.permission.imgReview ? (
                  <Route
                    exact
                    path={ROUTES.IMAGES.REVIEW}
                    element={<ImageReview />}
                  />
                ) : null}
                {!isExternalUrl(user.permission.hostName) &&
                user.permission.imgReview ? (
                  <Route
                    exact
                    path={ROUTES.IMAGES.LOOK_CLOSER}
                    element={<LookCloser />}
                  />
                ) : null}
                {!isExternalUrl(user.permission.hostName) && (
                  <Route
                    exact
                    path={ROUTES.IMAGES.REVIEW_HISTORY}
                    element={<ImageReviewHistory />}
                  />
                )}
                {!isExternalUrl(user.permission.hostName) ? (
                  <Route
                    exact
                    path={ROUTES.IMAGES.MANAGE}
                    element={<Manage />}
                  />
                ) : null}
                {user.permission.isCepSuperUser ? (
                  <Route exact path={ROUTES.IMAGES.LABELS} element={<Tags />} />
                ) : null}
                {user.permission.isCepSuperUser ? (
                  <Route
                    exact
                    path={ROUTES.IMAGES.LABELS_CREATE}
                    element={<CreateTag />}
                  />
                ) : null}
                {user.permission.isCepSuperUser ? (
                  <Route
                    exact
                    path={ROUTES.IMAGES.LABELS_TRAINING}
                    element={<TagTraining />}
                  />
                ) : null}
                {user.permission.isCepSuperUser ? (
                  <Route
                    exact
                    path={ROUTES.IMAGES.LABELS_CLASSIFIER}
                    element={<Classifier />}
                  />
                ) : null}
                {/* Video */}
                {user.permission.videoUpload &&
                !isVendorPipeline(user.permission.hostName) ? (
                  <Route
                    exact
                    path="/v2/video/videoEdit/:id"
                    element={<Videos />}
                  />
                ) : null}
                {user.permission.videoUpload &&
                !isVendorPipeline(user.permission.hostName) ? (
                  <Route
                    exact
                    path="/v2/video/history"
                    element={<VideoHistory />}
                  />
                ) : null}
                {!isExternalUrl(user.permission.hostName) ? (
                  <Route
                    exact
                    path="/v2/video/review"
                    element={<VideosReview />}
                  />
                ) : null}
                {!isExternalUrl(user.permission.hostName) ? (
                  <Route exact path="/v2/badges" element={<Badges />} />
                ) : null}
                {
                  <Route
                    exact
                    path={ROUTES.IMAGES.STAGEDATA}
                    element={<StageCoronaPage />}
                  />
                }
                {/* Product Specs */}
                {user.permission.prodSpecs &&
                !isVendorPipeline(user.permission.hostName) ? (
                  <Route
                    exact
                    path="/v2/prodSpecs"
                    element={<ProductSpecs />}
                  />
                ) : null}
                {<Route exact path="/v2/help" element={<StyleGuides />} />}
                <Route exact path="*" element={<HomePage />} />
              </Routes>
            ) : (
              <Routes>
                {' '}
                {/* <Route element={<SignInPrompt signIn={signIn} />} /> */}
                <Route
                  exact
                  path="*"
                  element={<SignInPrompt signIn={signIn} />}
                />
              </Routes>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  headerTitle: state.layout.headerTitle,
  sideNavIsOpen: state.layout.sideNavIsOpen,
  authModalIsShown: state.auth.authModalIsShown,
  user: state.auth,
})

const mapDispatchToProps = {
  openSideNav,
  closeSideNav,
  // signIn,
  // signOut,
  // promptToSignIn,
  signInSuccess,
  fetchUserProfile,
  signOutAction,
  fetchSavedFiltersEvent,
}

const mapAuthToProps = (auth) => {
  return {
    ...auth,
  }
}

export default withAuth(mapAuthToProps)(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Layout))
)
