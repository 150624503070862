import {
  RECEIVE_BRANDS_LIST,
  RECEIVE_VENDOR_LIST,
  SEARCH_SET_FETCH,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../components/Shared/Constants'

function receiveVendorList(data) {
  return {
    type: RECEIVE_VENDOR_LIST,
    payload: {
      vendorList: data,
    },
  }
}

function receiveBrandsList(data) {
  return {
    type: RECEIVE_BRANDS_LIST,
    payload: {
      brandsList: data,
    },
  }
}

export function getVendorList(text) {
  return (dispatch) => {
    dispatch(setFetch(true))
    return axios
      .post(
        `${envConfigs.api.itemApi}vendors/v1/vendors/filter?key=${envConfigs.api.gatewayKey}`,
        { searchText: text }
      )
      .then((res) => {
        dispatch(setFetch(false))
        dispatch(receiveVendorList(res.data))
      })
      .catch((error, data) => {
        dispatch(
          setFetch(false),
          toast.error('Failed to retrieve vendors.', TOAST_BODY),
          toast.error(error.message, TOAST_BODY)
        )
      })
  }
}

export function getBrandsList(text) {
  return (dispatch) => {
    dispatch(setFetch(true))
    return axios
      .post(
        `${envConfigs.api.itemApi}digital_items/v1/brands/filter?key=${envConfigs.api.gatewayKey}`,
        { searchText: text }
      )
      .then((res) => {
        dispatch(setFetch(false))
        dispatch(receiveBrandsList(res.data))
      })
      .catch((error, data) => {
        dispatch(
          setFetch(false),
          toast.error('Failed to retrieve vendors.', TOAST_BODY),
          toast.error(error.message, TOAST_BODY)
        )
      })
  }
}

export function setFetch(data) {
  return {
    type: SEARCH_SET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}
