import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ItemDetails from './ItemDetails'
import { searchMtaData } from '../../../../store/prodSpecs/actionCreator'

class ItemDetailsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <ItemDetails
        itemProdSpecs={this.props.itemProdSpecs}
        searchMtaData={this.props.searchMtaData}
        itemImageData={this.props.itemImageData}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ searchMtaData }, dispatch)

const mapStateToProps = (state) => {
  const { prodSpecs } = state
  const { itemProdSpecs, mtaData, itemImageData } = prodSpecs
  return {
    itemProdSpecs,
    mtaData,
    itemImageData,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemDetailsContainer)
