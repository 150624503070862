import {
  SET_RULES,
  FILTER_CHANGE,
  EXPANDED_STATE_CHANGE,
  RULES_FILTERED_TCINS_FOUND,
  RULE_FILTER_WARNING,
  RULE_SELECTION_RESET_FETCH,
  RULE_TCIN_LIST_OR_RULE,
  SIZE_CHART_RULE_SELECTION_LOADER,
  RESET_TCIN_RULE_SELECTION,
  EDIT_MODE_SIZE_CHART_TCIN,
  ACTION_TYPE_CRUD_OPP_TCIN,
} from './actionType'

export const initialState = {
  rules: {},
  filteredRules: {},
  expanded: [-1],
  filter: '',
  searchedTCINs: [],
  warning: {
    open: false,
    message: '',
    ok: () => {},
    cancel: () => {},
  },
  isFetching: false,
  selectedTcinRule: false,
  sizeChartRuleSelectionLoader: false,
  editModeSizeChartTcinList: false,
  actionTypeForTcin: '',
}

export default function sizeChartRuleSelectionReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case SET_RULES: {
      const newState = {
        ...state,
        rules: action.rules,
        searchedTCINs: [],
      }
      return newState
    }
    case FILTER_CHANGE: {
      const newState = {
        ...state,
        filteredRules: action.filteredRules,
        expanded: [-1],
        filter: action.filter,
        searchedTCINs: action.filter === '' ? [] : state.searchedTCINs,
      }
      return newState
    }
    case EXPANDED_STATE_CHANGE: {
      const newState = {
        ...state,
        expanded: action.expanded,
      }
      return newState
    }
    case RULES_FILTERED_TCINS_FOUND: {
      const newState = {
        ...state,
        searchedTCINs: action.tcins,
      }
      return newState
    }
    case RULE_FILTER_WARNING: {
      const newState = {
        ...state,
        warning: {
          ...state.warning,
          ...action.warning,
        },
      }
      return newState
    }
    case RULE_SELECTION_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case RULE_TCIN_LIST_OR_RULE: {
      const newState = {
        ...state,
        selectedTcinRule: action.selectedTcinRule,
        actionTypeForTcin: action.actionTypeForTcin,
      }
      return newState
    }
    case SIZE_CHART_RULE_SELECTION_LOADER: {
      const newState = {
        ...state,
        sizeChartRuleSelectionLoader: action.payloadProp,
      }
      return newState
    }
    case RESET_TCIN_RULE_SELECTION: {
      const newState = {
        ...state,
        selectedTcinRule: false,
        sizeChartRuleSelectionLoader: false,
        editModeSizeChartTcinList: false,
        actionTypeForTcin: '',
      }
      return newState
    }
    case EDIT_MODE_SIZE_CHART_TCIN: {
      const newState = {
        ...state,
        editModeSizeChartTcinList: action.payloadProp,
        selectedTcinRule: true,
      }
      return newState
    }
    case ACTION_TYPE_CRUD_OPP_TCIN: {
      const newState = {
        ...state,
        actionTypeForTcin: action.actionTypeForTcin,
      }
      return newState
    }
    default:
      return state
  }
}
