import {
  CHANGE_DEFAULT_PAGE_SIZE_VIDEO_REVIEW,
  STORE_VIDEO_REVIEW_STRAPI_DATA,
  BUILD_FILTER_EXPAND_VIDEO_REVIEW,
  NEW_FILTER_EVENT_VIDEO_REVIEW,
  BUILD_FILTER_EVENT_VIDEO_REVIEW,
  REMOVE_FILTER_EVENT_VIDEO_REVIEW,
  RECEIVE_VIDEO_REVIEW_DATA,
  REQUEST_VIDEO_REVIEW_DATA,
  ERROR_SEARCHING_VIDEO_REVIEW,
  CLEAR_VIDEO_REVIEW_DATA_EVENT,
  CHANGE_FILTER_EVENT_VIDEO_REVIEW,
  SELECT_FILTER_VALUE_EVENT_VIDEO_REVIEW,
  CHANGE_CURRENT_PAGE_VIDEO_REVIEW,
  SELECT_VIDEO_REVIEW_EVENT,
  SET_SORT_FIELD_VIDEO_REVIEW,
  SET_SORT_DIRECTION_VIDEO_REVIEW,
  VIDEO_REVIEW_SEARCH_RESET_FETCH,
  SHOW_DEFAULT_ALERT_VIDEO_REVIEW_SEARCH,
} from './actionType'

const DefaultState = {
  isFetching: false,
  alert: null,
  information: null,
  faq: null,
  error: null,
  showDefaultAlert: false,
  buildFilterExpansionPanel: false,
  newFilterContainerShown: false,
  videoDataContainerShown: false,
  selectedFilters: [],
  filterValues: {
    approvalStatus: -1,
    dateRange: -1,
    tcins: [],
    fromDate: -1,
    toDate: -1,
    vendor: [],
  },
  defaultFilterValues: {
    approvalStatus: -1,
    dateRange: -1,
    tcins: [],
    fromDate: -1,
    toDate: -1,
    vendor: [],
  },
  videoData: [],
  totalPages: 0,
  totalElements: 0,
  defaultPageSize: 500,
  sortDirection: 'ASC',
  sortField: 'STATUS',
  currentPage: 0,
  errorSearching: false,
  buildFilterContainerShown: false,
  selectedVideo: [],
  selectedVideos: null,
  vendorList: [],
  // DO NOT CHANGE THE PAGENAME!
  pageName: 'VideosReview',
}

export default function videoReviewReducer(state = DefaultState, action = {}) {
  switch (action.type) {
    case VIDEO_REVIEW_SEARCH_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case STORE_VIDEO_REVIEW_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
        error: payload.error,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_VIDEO_REVIEW_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case BUILD_FILTER_EXPAND_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterExpansionPanel: payload.buildFilterExpansionPanel,
      }
      return newState
    }
    case NEW_FILTER_EVENT_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterContainerShown: payload,
      }
      return newState
    }
    case BUILD_FILTER_EVENT_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        videoDataContainerShown: payload.videoDataContainerShown,
        selectedFilters: payload.selectedFilters,
        // Resetting the value after adding
        filterValues: DefaultState.filterValues,
      }
      return newState
    }
    case REMOVE_FILTER_EVENT_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        videoDataContainerShown: payload.videoDataContainerShown,
        selectedFilters: payload.selectedFilters,
      }
      return newState
    }
    case RECEIVE_VIDEO_REVIEW_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        videoData: payload.videoData,
        isFetching: payload.isFetching,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
      }
      return newState
    }
    case REQUEST_VIDEO_REVIEW_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        videoData: payload.videoData,
        isFetching: payload.isFetching,
        selectedVideos: payload.selectedVideos,
      }
      return newState
    }
    case ERROR_SEARCHING_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    case CLEAR_VIDEO_REVIEW_DATA_EVENT: {
      const newState = {
        ...state,
        videoData: [],
      }
      return newState
    }
    case CHANGE_FILTER_EVENT_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        selectedFilter: payload.selectedFilter,
        filterValues: DefaultState.filterValues,
      }
      return newState
    }
    case SELECT_FILTER_VALUE_EVENT_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        filterValues: payload.filterValues,
      }
      return newState
    }
    case CHANGE_CURRENT_PAGE_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        currentPage: payload.currentPage,
      }
      return newState
    }
    case CHANGE_DEFAULT_PAGE_SIZE_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        defaultPageSize: payload.defaultPageSize,
      }
      return newState
    }
    case SELECT_VIDEO_REVIEW_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedVideo: payload.selectedVideo,
      }
      return newState
    }
    case SET_SORT_FIELD_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        sortField: payload,
      }
      return newState
    }
    case SET_SORT_DIRECTION_VIDEO_REVIEW: {
      const { payload } = action
      const newState = {
        ...state,
        sortDirection: payload,
      }
      return newState
    }
    default:
      return state
  }
}
