import {
  REQUEST_VIDEO_HISTORY_DATA,
  RECIEVE_VIDEO_HISTORY_DATA,
  CHANGE_CURRENT_PAGE_VIDEO_HISTORY,
  CHANGE_DEFAULT_PAGE_SIZE_VIDEO_HISTORY,
  VIDEO_HISTORY_RESET_FETCH,
  CHANGE_SEARCH_ITEM_VIDEO_HISTORY,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'

export function getVideoHistoryData(
  pageNumber,
  pageSize,
  emailId,
  itemSearch,
  tcin
) {
  var requestBody = {
    duration: -1,
  }

  let urlDecision = itemSearch
    ? envConfigs.targetApiGateway.targetApiGatewayUrl +
      envConfigs.targetApiGateway.videoHistoryFilter +
      emailId +
      '&page_number=' +
      pageNumber +
      '&page_size=' +
      pageSize +
      '&key=' +
      envConfigs.targetApiGateway.targetApiGatewayKey
    : envConfigs.targetApiGateway.targetApiGatewayUrl +
      envConfigs.targetApiGateway.videoHistory +
      emailId +
      '&page_number=' +
      pageNumber +
      '&page_size=' +
      pageSize +
      '&key=' +
      envConfigs.targetApiGateway.targetApiGatewayKey

  return (dispatch) => {
    dispatch(dispatchItemDataEvent())
    return axios
      .post(urlDecision, itemSearch ? tcin : requestBody)
      .then((res) => {
        dispatch(changePage(pageNumber))
        dispatch(changePageSize(pageSize))
        dispatch(
          getHistorySuccess(
            res.data,
            res.data.totalPages,
            res.data.totalElements
          )
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function dispatchItemDataEvent() {
  return {
    type: REQUEST_VIDEO_HISTORY_DATA,
    payload: {
      isFetching: true,
    },
  }
}

function getHistorySuccess(data, totalPages, totalElements) {
  return {
    type: RECIEVE_VIDEO_HISTORY_DATA,
    payload: {
      videoHistoryData: data.content,
      isFetching: false,
      totalPages: totalPages,
      totalElements: totalElements,
    },
  }
}

function changePage(currentPage) {
  return {
    type: CHANGE_CURRENT_PAGE_VIDEO_HISTORY,
    payload: {
      currentPage: currentPage,
    },
  }
}

export function handleChangePage(data, emailId, itemSearch) {
  return (dispatch) => {
    dispatch(changePage(data.currentPage))
    dispatch(
      getVideoHistoryData(
        data.currentPage,
        data.defaultPageSize,
        emailId,
        itemSearch
      )
    )
  }
}

function changePageSize(defaultPageSize) {
  return {
    type: CHANGE_DEFAULT_PAGE_SIZE_VIDEO_HISTORY,
    payload: {
      defaultPageSize: defaultPageSize,
    },
  }
}

export function changeDefaultPageSize(defaultPageSize, emailId, itemSearch) {
  return (dispatch) => {
    dispatch(changePageSize(defaultPageSize))
    // Always setting the currentPage to 0 to prevent bugs
    dispatch(changePage(0))
    dispatch(getVideoHistoryData(0, defaultPageSize, emailId, itemSearch))
  }
}

export function resetFetch(data) {
  return {
    type: VIDEO_HISTORY_RESET_FETCH,
    payload: {
      isFetching: data.isFetching,
    },
  }
}

export function changeSearchItem(data) {
  return {
    type: CHANGE_SEARCH_ITEM_VIDEO_HISTORY,
    payload: {
      searchedItems: data,
    },
  }
}
