import AssignmentIcon from '@material-ui/icons/Assignment'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import styles from '../theme'

const UploadHistoryAction = (props) => {
  const { uploadHistoryClickAction = () => {}, classes = {} } = props

  return (
    <Button className={classes.drawerButton} onClick={uploadHistoryClickAction}>
      <AssignmentIcon />
      <span className={classes.marginLeft5}>Inspect Upload History</span>
    </Button>
  )
}

// @ts-ignore
export default withStyles(styles)(UploadHistoryAction)
