import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getAllTagsByGroup,
  filterTagGroups,
  resetEditedTag,
  changeTagEditState,
  saveTagUpdate,
} from '../../../../../../store/images/tagTraining/actionCreator'
import TagOverviewDataTable from './TagOverviewDataTable'

class TagOverviewDataTableContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {
    if (this.props.tagGroups.length === 0) {
      this.props.getAllTagsByGroup()
    }
  }

  resetEditedTag = (editedTagType, editedTagName) => {
    this.props.resetEditedTag(editedTagType, editedTagName)
  }

  changeTagEditState = (tagType, tagName, isEditing) => {
    this.props.changeTagEditState(tagType, tagName, isEditing)
  }

  render() {
    const {
      auth,
      tagGroups,
      tagGroupsFilterText,
      filterTagGroups,
      filteredTagGroups,
      resetEditedTag,
      editedTagGroups,
    } = this.props
    return (
      <React.Fragment>
        <TagOverviewDataTable
          userEmail={auth.email}
          tagGroups={tagGroupsFilterText ? filteredTagGroups : editedTagGroups}
          filterTagGroups={(filterText) =>
            filterTagGroups(filterText, tagGroups)
          }
          tagGroupsFilterText={tagGroupsFilterText}
          resetBtnAction={(editedTagType, editedTagName) =>
            resetEditedTag(editedTagType, editedTagName)
          }
          saveTagBtnAction={(tagType, tagName, tagDescription) =>
            this.props.saveTagUpdate(tagType, tagName, tagDescription)
          }
          changeTagEditState={(tagType, tagName, isEditing) =>
            this.changeTagEditState(tagType, tagName, isEditing)
          }
        />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllTagsByGroup,
      filterTagGroups,
      resetEditedTag,
      changeTagEditState,
      saveTagUpdate,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth, form } = state
  const { tagEditForm } = form
  const { tagGroups, editedTagGroups, tagGroupsFilterText, filteredTagGroups } =
    imageTagTraining
  return {
    auth,
    tagGroups,
    editedTagGroups,
    tagGroupsFilterText,
    filteredTagGroups,
    tagEditForm,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagOverviewDataTableContainer)
