import React from 'react'
import { Typography, Toolbar, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import GetAppIcon from '@material-ui/icons/GetApp'

let VideoReviewDataContainerToolbar = (props) => {
  const { selectedVideo = [], classes = {} } = props

  const numSelected = selectedVideo ? selectedVideo.length : 0

  const selectedTcins = selectedVideo
    .map((video) => video.applied_tcins_set)
    .flat(Infinity)

  return (
    <React.Fragment>
      {numSelected > 0 && (
        <Toolbar className={classes.toolBar}>
          <div className={classes.title}>
            {numSelected > 0 && (
              <Typography color="inherit">{numSelected} selected</Typography>
            )}
          </div>
          {numSelected > 0 ? null : <div className={classes.spacer} />}
          <div className={classes.actions}>
            {numSelected > 0 && (
              <Button
                className={classes.drawerButton}
                onClick={() => {
                  navigator.clipboard.writeText(selectedTcins.join('\n'))
                }}
              >
                <GetAppIcon />
                <span className={classes.marginLeft5}>
                  Copy TCIN(s) to clipboard
                </span>
              </Button>
            )}
          </div>
        </Toolbar>
      )}
    </React.Fragment>
  )
}

// @ts-ignore
export default withStyles(styles)(VideoReviewDataContainerToolbar)
