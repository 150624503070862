import React from 'react'
import CopyData from './CopyData'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CopyWritingStatus } from '../../../FilterData'
import {
  handleChangePage,
  changeDefaultPageSize,
  handleSelectCopy,
  toggleActionDrawer,
  selectRoutingTeam,
  setPlannerNotesEvent,
  addPlannerNotes,
  downloadSelectedTcinsCopyEvent,
  downloadAllToExcel,
  deleteBulletAndCopy,
  openDrawerAndGetWritersForCopyAssignment,
  requestCopyData,
  setSortDirection,
  setSortField,
} from '../../../../../../store/longCopy/actionCreator'
import { savePaginationDataEvent } from '../../../../../../store/auth/actionCreator'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../../../Shared/Constants'
import { gridCheckedLogic } from '../../../../../Shared/SharedUtils'

class CopyDataContainer extends React.Component {
  handleSelectAllClick = (_, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = this.props.copyData
    }
    this.props.handleSelectCopy({
      selectedCopy: newSelected,
    })
  }

  handleClick = (_, checkedCopy) => {
    const { selectedCopy } = this.props
    this.props.handleSelectCopy({
      selectedCopy: gridCheckedLogic(selectedCopy, checkedCopy),
    })
  }

  handleChangePage = (_, page) => {
    let userId = this.props.auth.email
      ? this.props.auth.email
      : this.props.auth.lanId
    this.props.handleChangePage(
      {
        currentPage: page,
        defaultPageSize: this.props.defaultPageSize,
        selectedFilters: this.props.selectedFilters,
      },
      userId,
      this.props.sortDirection,
      this.props.sortField
    )
  }
  toggleActionDrawer = (isActionDrawerOpen, drawerAction) => {
    this.props.toggleActionDrawer(isActionDrawerOpen, drawerAction)
  }
  toggleActionDrawerForAssigningWriters = (
    isActionDrawerOpen,
    drawerAction
  ) => {
    if (
      this.props.assignedWritersList === undefined ||
      !this.props.assignedWritersList ||
      this.props.assignedWritersList.length < 1
    ) {
      this.props.openDrawerAndGetWritersForCopyAssignment(drawerAction)
    } else {
      this.props.toggleActionDrawer(isActionDrawerOpen, drawerAction)
    }
  }
  downloadAllToExcel = () => {
    if (this.props.totalElements > 10000) {
      this.props.resetFetch(false)
      toast.info('Download up to 10000 items only allowed', TOAST_BODY)
      return false
    }
    this.props.downloadAllToExcel(
      this.props.selectedFilters,
      this.props.totalElements
    )
  }
  downloadSelectedTcinsCopy = () => {
    let query = this.props.selectedCopy.map((item) => item.tcin)
    this.props.downloadSelectedTcinsCopyEvent(query.join())
  }
  deleteBulletAndCopy = () => {
    let query = this.props.selectedCopy.map((item) => item.tcin)
    this.props.deleteBulletAndCopy(query, true)
  }
  handleChangeDefaultPageSize = (event) => {
    let userId = this.props.auth.email
      ? this.props.auth.email
      : this.props.auth.lanId
    this.props.changeDefaultPageSize(
      {
        defaultPageSize: event.target.value,
        selectedFilters: this.props.selectedFilters,
      },
      userId,
      this.props.sortDirection,
      this.props.sortField,
      this.props.pageName
    )
  }

  isSelected = (checkedCopy) =>
    this.props.selectedCopy.indexOf(checkedCopy) !== -1

  setBackgroundStatus = (status) => {
    let tcinColor = CopyWritingStatus.filter((item) => item.value === status)
    if (tcinColor.length === 0) {
      return { backgroundColor: 'white', padding: '0px 15px' }
    } else {
      return { backgroundColor: tcinColor[0].color, padding: '0px 15px' }
    }
  }

  convertStatusDisplay = (status) => {
    let statusName = CopyWritingStatus.filter((item) => item.value === status)
    if (statusName.length === 0) {
      return status
    } else {
      return statusName[0].display
    }
  }

  render() {
    const {
      defaultPageSize,
      copyData,
      currentPage,
      selectedCopy,
      drawerAction,
      totalElements,
      auth,
      sortDirection,
      sortField,
      requestCopyData,
      setSortDirection,
      setSortField,
      isActionDrawerOpen,
      deleteData,
      selectedFilters,
      pageName,
      rowsPerPage,
    } = this.props
    const emptyRows =
      defaultPageSize -
      Math.min(defaultPageSize, (totalElements - currentPage) * defaultPageSize)
    return (
      <CopyData
        data={copyData}
        selected={selectedCopy}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        drawerAction={drawerAction}
        emptyRows={emptyRows}
        totalElements={totalElements}
        handleSelectAllClick={this.handleSelectAllClick}
        isSelected={this.isSelected}
        auth={auth}
        handleClick={this.handleClick}
        handleChangePage={this.handleChangePage}
        toggleActionDrawer={this.toggleActionDrawer}
        toggleActionDrawerForAssigningWriters={
          this.toggleActionDrawerForAssigningWriters
        }
        downloadSelectedTcinsCopy={this.downloadSelectedTcinsCopy}
        handleChangeRowsPerPage={this.handleChangeDefaultPageSize}
        setBackgroundStatus={this.setBackgroundStatus}
        convertStatusDisplay={this.convertStatusDisplay}
        downloadAllToExcel={this.downloadAllToExcel}
        isActionDrawerOpen={isActionDrawerOpen}
        deleteBulletAndCopy={this.deleteBulletAndCopy}
        deleteData={deleteData}
        selectedFilters={selectedFilters}
        sortDirection={sortDirection}
        sortField={sortField}
        requestCopyData={requestCopyData}
        setSortDirection={setSortDirection}
        setSortField={setSortField}
        savePaginationDataEvent={savePaginationDataEvent}
        pageName={pageName}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleChangePage,
      changeDefaultPageSize,
      handleSelectCopy,
      toggleActionDrawer,
      selectRoutingTeam,
      setPlannerNotesEvent,
      addPlannerNotes,
      downloadSelectedTcinsCopyEvent,
      downloadAllToExcel,
      deleteBulletAndCopy,
      openDrawerAndGetWritersForCopyAssignment,
      requestCopyData,
      setSortDirection,
      setSortField,
      savePaginationDataEvent,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { longCopy, auth } = state
  const {
    copyData,
    currentPage,
    selectedCopy,
    defaultPageSize,
    isActionDrawerOpen,
    drawerAction,
    currentRouteTeam,
    plannerNotes,
    totalPages,
    totalElements,
    selectedFilters,
    deleteData,
    assignedWritersList,
    sortDirection,
    sortField,
    pageName,
  } = longCopy
  return {
    copyData,
    currentPage,
    selectedCopy,
    defaultPageSize,
    isActionDrawerOpen,
    drawerAction,
    currentRouteTeam,
    plannerNotes,
    auth,
    totalPages,
    totalElements,
    selectedFilters,
    deleteData,
    assignedWritersList,
    sortDirection,
    sortField,
    pageName,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CopyDataContainer)
