import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import { Card, CardContent } from '@material-ui/core'

function DefaultAlert(props) {
  const { classes } = props

  return (
    <Card
      className={`${classes.marginTop10} ${classes.marginBottom10} ${classes.alert}`}
    >
      <CardContent>
        <div className={`${classes.marginBottom10} ${classes.header}`}>
          {' '}
          Until further notification, please do not approve any images marked as
          “Reuse”
        </div>
        <div className={classes.body}></div>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(DefaultAlert)
