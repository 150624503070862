import {
  IMAGE_MANAGE_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_MANAGE,
  STORE_IMAGE_MANAGE_STRAPI_DATA,
  BUILD_FILTER_EVENT_IMAGE_MANAGE,
  CLEAR_IMAGE_MANAGE_DATA_EVENT,
  ERROR_SEARCHING_IMAGE_MANAGE,
  REQUEST_IMAGE_MANAGE_DATA,
  RECEIVE_IMAGE_MANAGE_DATA,
  CHANGE_CURRENT_PAGE_IMAGE_MANAGE,
  CHANGE_DEFAULT_PAGE_SIZE_IMAGE_MANAGE,
  SELECT_IMAGE_MANAGE_EVENT,
  SET_SORT_FIELD_IMAGE_MANAGE,
  SET_SORT_DIRECTION_IMAGE_MANAGE,
  UPDATE_IMAGE_DATA_MANAGE,
  RESET_IMAGE_DATA_MANAGE,
  START_PUBLISH_IMAGE_DATA_MANAGE,
  SET_COPIED_IMAGE,
  TOGGLE_RELATED_ITEMS_MODAL,
  STORE_RELATED_ITEMS_DATA,
  SUCCESS_SEARCH_FOR_ITEM_IMAGES,
  TOGGLE_SYNC_ITEMS_MODAL,
  TOGGLE_EDIT_SWATCH_MODAL,
  TOGGLE_EDIT_IMAGE_LABEL_DIALOGUE,
  SAVED_LABEL_VALUE_LIST,
} from './actionType'

export const initState = {
  isFetching: false,
  showDefaultAlert: false,
  // DO NOT CHANGE THE PAGENAME!
  pageName: 'ImageManage',
  ismData: [],
  originalIsmData: [],
  errorSearching: false,
  selectedImages: null,
  totalPages: 0,
  totalElements: 0,
  defaultPageSize: 10,
  currentPage: 0,
  selectedFilters: [],
  dataContainerShown: false,
  alert: null,
  information: null,
  faq: null,
  error: null,
  sortDirection: 'asc',
  sortField: 'TCIN',
  showRelatedItemsModal: false,
  relatedItemsData: [],
  relatedTcin: '',
  itemToSyncFrom: {},
  itemToSyncTo: [],
  showSyncItemsModal: false,
  showEditSwatchModal: null,
  isEditImageLabelOpen: false,
  currentAddLabelImage: {},
  labelTcin: '',
  rowIndex: 0,
  colIndex: 0,
  savedLabelList: [],
}

export default function imageManageReducer(state = initState, action = {}) {
  switch (action.type) {
    case IMAGE_MANAGE_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_IMAGE_MANAGE: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case STORE_IMAGE_MANAGE_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
        error: payload.error,
      }
      return newState
    }
    case BUILD_FILTER_EVENT_IMAGE_MANAGE: {
      const { payload } = action
      const newState = {
        ...state,
        dataContainerShown: payload.dataContainerShown,
        selectedFilters: payload.selectedFilters,
      }
      return newState
    }
    case RECEIVE_IMAGE_MANAGE_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        ismData: payload.ismData,
        originalIsmData: payload.ismData,
        isFetching: payload.isFetching,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
      }
      return newState
    }
    case REQUEST_IMAGE_MANAGE_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        ismData: payload.ismData,
        isFetching: payload.isFetching,
        selectedImages: payload.selectedImages,
      }
      return newState
    }
    case ERROR_SEARCHING_IMAGE_MANAGE: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    case CLEAR_IMAGE_MANAGE_DATA_EVENT: {
      const newState = {
        ...state,
        ismData: initState.ismData,
      }
      return newState
    }
    case CHANGE_CURRENT_PAGE_IMAGE_MANAGE: {
      const { payload } = action
      const newState = {
        ...state,
        currentPage: payload.currentPage,
      }
      return newState
    }
    case CHANGE_DEFAULT_PAGE_SIZE_IMAGE_MANAGE: {
      const { payload } = action
      const newState = {
        ...state,
        defaultPageSize: payload.defaultPageSize,
      }
      return newState
    }
    case SELECT_IMAGE_MANAGE_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedImages: payload.selectedImages,
      }
      return newState
    }
    case SET_SORT_FIELD_IMAGE_MANAGE: {
      const { payload } = action
      const newState = {
        ...state,
        sortField: payload,
      }
      return newState
    }
    case SET_SORT_DIRECTION_IMAGE_MANAGE: {
      const { payload } = action
      const newState = {
        ...state,
        sortDirection: payload,
      }
      return newState
    }
    case UPDATE_IMAGE_DATA_MANAGE: {
      const { payload } = action
      const newState = {
        ...state,
        ismData: payload,
      }
      return newState
    }
    case RESET_IMAGE_DATA_MANAGE: {
      const newState = {
        ...state,
        ismData: state.originalIsmData,
      }
      return newState
    }
    case SET_COPIED_IMAGE: {
      const { payload } = action
      const newState = {
        ...state,
        copiedImage: payload.copiedImage,
      }
      return newState
    }
    case START_PUBLISH_IMAGE_DATA_MANAGE: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload,
      }
      return newState
    }
    case TOGGLE_RELATED_ITEMS_MODAL: {
      const { payload } = action
      const newState = {
        ...state,
        showRelatedItemsModal: payload,
        relatedItemsData: payload
          ? state.relatedItemsData
          : initState.relatedItemsData,
        relatedTcin: payload ? state.relatedTcin : initState.relatedTcin,
      }
      return newState
    }
    case STORE_RELATED_ITEMS_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        relatedItemsData: payload.relatedItemsData,
        relatedTcin: payload.relatedTcin,
      }
      return newState
    }
    case SUCCESS_SEARCH_FOR_ITEM_IMAGES: {
      const { payload } = action
      const newState = {
        ...state,
        itemToSyncFrom: payload.itemToSyncFrom,
        itemToSyncTo: payload.itemToSyncTo,
      }
      return newState
    }
    case TOGGLE_SYNC_ITEMS_MODAL: {
      const { payload } = action
      const newState = {
        ...state,
        showSyncItemsModal: payload,
        itemToSyncFrom: payload
          ? state.itemToSyncFrom
          : initState.itemToSyncFrom,
        itemToSyncTo: payload ? state.itemToSyncTo : initState.itemToSyncTo,
      }
      return newState
    }
    case TOGGLE_EDIT_SWATCH_MODAL: {
      const { payload } = action
      const newState = {
        ...state,
        showEditSwatchModal: payload,
      }
      return newState
    }
    case TOGGLE_EDIT_IMAGE_LABEL_DIALOGUE: {
      const { payload } = action
      const newState = {
        ...state,
        isEditImageLabelOpen: payload.isEditImageLabelOpen,
        currentAddLabelImage: payload.currentAddLabelImage,
        labelTcin: payload.labelTcin,
        rowIndex: payload.rowIndex,
        colIndex: payload.colIndex,
      }
      return newState
    }
    case SAVED_LABEL_VALUE_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        savedLabelList: payload,
      }
      return newState
    }
    default:
      return state
  }
}
