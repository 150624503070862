/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import ImageDataContainer from './Components/ImageDataContainer'
import { Grid, CircularProgress, Button } from '@material-ui/core'
import styles from './theme'
import { withStyles } from '@material-ui/core/styles'
import {
  getImageManageStrapiData,
  // getVendorList,
} from '../../../store/images/manage/actionCreator'
import { isExternalUser } from '../../Shared/SharedUtils'
import DefaultAlert from '../../Shared/StrapiComponents/DefaultAlert'
import Alert from '../../Shared/StrapiComponents/Alert'
import StrapiExpansionPanel from '../../Shared/StrapiComponents/StrapiExpansionPanel'
import { withRouter } from '../../Shared/WithRouterHoc'
import { ROUTES } from '../../Shared/Constants'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

function Manage(props) {
  const {
    isFetching = false,
    classes = {},
    showDefaultAlert = false,
    auth = {},
    alert = null,
    information = null,
    faq = null,
    userProfile = {},
    errorSearching = false,
    pageName = 'ImageManage',
  } = props

  const modalStyle = {
    content: {
      top: '88px',
    },
  }

  useEffect(() => {
    props.getImageManageStrapiData(isExternalUser(auth))
  }, [])

  const [expansionPanelOpen, expandFaq] = useState(false)
  const [lightboxUrl, setLightboxUrl] = useState(null)

  const handleFaqPanel = () => {
    expandFaq(!expansionPanelOpen)
  }

  const backToSearch = (_) => {
    // props.history.push(ROUTES.IMAGES.SEARCH)
    props.router.navigate({ pathname: ROUTES.IMAGES.SEARCH })
  }

  const paginationValues =
    userProfile && userProfile.pagination
      ? userProfile.pagination.filter((item) => item.page_name === pageName)
      : undefined

  const pageSize =
    paginationValues && paginationValues[0] && paginationValues[0].page_size
      ? paginationValues[0].page_size
      : props.defaultPageSize

  const sortDirection =
    paginationValues && paginationValues[0] && paginationValues[0].sort_order
      ? paginationValues[0].sort_order.toUpperCase()
      : props.sortDirection

  const sortField =
    paginationValues && paginationValues[0] && paginationValues[0].sort_field
      ? paginationValues[0].sort_field
      : props.sortField

  return (
    <React.Fragment>
      <HeaderTitle title="Image Manage" />
      <Helmet title="Image Manage" />
      {showDefaultAlert ? <DefaultAlert /> : null}
      {alert ? <Alert alert={alert} /> : null}
      <Grid container>
        {(information || faq) && (
          <StrapiExpansionPanel
            faq={faq}
            information={information}
            expansionPanelOpen={expansionPanelOpen}
            handleFaqPanel={handleFaqPanel}
          />
        )}
        {isFetching && (
          <Grid className={classes.progressBar}>
            <CircularProgress className={classes.progressBar} />
          </Grid>
        )}
        {!isFetching && !errorSearching && (
          <Grid item xs={12} sm={12}>
            <ImageDataContainer
              rowsPerPage={pageSize}
              sortDirection={sortDirection}
              sortField={sortField}
              backToSearch={backToSearch}
              setZoom={setLightboxUrl}
            />
          </Grid>
        )}
        {errorSearching && (
          <Grid item xs={12} sm={12}>
            <div className={classes.noResult}>
              <div>Issue with search, please try again.</div>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => backToSearch()}
                className={classes.actions}
              >
                BACK TO SEARCH
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
      {lightboxUrl && (
        <Lightbox
          reactModalStyle={modalStyle}
          mainSrc={lightboxUrl}
          onCloseRequest={() => setLightboxUrl(null)}
        />
      )}
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getImageManageStrapiData,
      // getVendorList,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageManage, auth } = state
  const {
    dataContainerShown,
    isFetching,
    alert,
    information,
    faq,
    defaultPageSize,
    sortDirection,
    sortField,
    currentPage,
    errorSearching,
    pageName,
    vendorList,
  } = imageManage
  const { userProfile } = auth
  return {
    dataContainerShown,
    isFetching,
    auth,
    alert,
    information,
    faq,
    userProfile,
    defaultPageSize,
    sortDirection,
    sortField,
    currentPage,
    errorSearching,
    pageName,
    vendorList,
  }
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Manage)))
