import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  PROMPT_SIGN_IN,
  DECLINE_SIGN_IN,
  RECEIVE_USER_PROFILE_DATA,
  REQUEST_SAVE_FILTER_DATA,
  SUCCESS_SAVE_FILTER_DATA,
  REQUEST_SAVED_FILTER_DATA,
  RECEIVE_SAVED_FILTER_DATA,
  TOGGLE_SAVE_FILTER_DIALOGUE,
  TOGGLE_CONFIRMATION,
  REQUEST_SAVED_FILTER_DATA_FAILURE,
  START_VENDOR_TCIN_MAPPING,
  SUCCESS_VENDOR_TCIN_MAPPING,
  AUTH_RESET_FETCH,
} from './actionType'
import { formatUserInfo } from './actionCreator'

export const initialState = {
  ...formatUserInfo(),
  isAuthorized: false,
  popupCount: 0,
  popupType: null,
  authModalIsShown: false,
  isFetchingAuth: false,
  isSaveFilterOpen: false,
  userProfile: null,
  isFetchingSavedFilters: false,
  validTcins: null,
  invalidTcins: null,
  unauthorizedTcins: null,
}

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGN_IN:
      return {
        ...state,
        popupType: 'login',
        popupCount: state.popupCount + 1,
      }
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        ...formatUserInfo(payload),
        isAuthorized: true,
        authModalIsShown: false,
      }
    case SIGN_OUT:
      return {
        ...state,
        ...formatUserInfo(),
        isAuthorized: false,
        popupType: 'logout',
        popupCount: state.popupCount + 1,
      }
    case PROMPT_SIGN_IN:
      return {
        ...state,
        authModalIsShown: true,
      }
    case DECLINE_SIGN_IN:
      return {
        ...state,
        ...formatUserInfo(),
        isAuthorized: false,
        authModalIsShown: false,
      }
    case RECEIVE_USER_PROFILE_DATA: {
      const newState = {
        ...state,
        isFetchingAuth: false,
        userProfile: payload.userProfile,
      }
      return newState
    }
    case REQUEST_SAVE_FILTER_DATA: {
      const newState = {
        ...state,
        isFetchingAuth: payload.isFetching,
      }
      return newState
    }
    case SUCCESS_SAVE_FILTER_DATA: {
      const newState = {
        ...state,
        isFetchingAuth: payload.isFetching,
        isSaveFilterOpen: payload.isSaveFilterOpen,
      }
      return newState
    }
    case REQUEST_SAVED_FILTER_DATA: {
      const newState = {
        ...state,
        isFetchingSavedFilters: payload.isFetching,
      }
      return newState
    }
    case REQUEST_SAVED_FILTER_DATA_FAILURE: {
      const newState = {
        ...state,
        isFetchingSavedFilters: payload.isFetching,
      }
      return newState
    }
    case RECEIVE_SAVED_FILTER_DATA: {
      const newState = {
        ...state,
        isFetchingSavedFilters: payload.isFetching,
        isFetchingAuth: payload.isFetching,
        userProfile: payload.savedFilterData,
      }
      return newState
    }
    case TOGGLE_SAVE_FILTER_DIALOGUE: {
      const newState = {
        ...state,
        isSaveFilterOpen: payload.isSaveFilterOpen,
      }
      return newState
    }
    case TOGGLE_CONFIRMATION: {
      const newState = {
        ...state,
        isConfirmationOpen: payload.isConfirmationOpen,
        confirmationPayload: payload.confirmationPayload,
      }
      return newState
    }
    case START_VENDOR_TCIN_MAPPING: {
      const newState = {
        ...state,
        isFetchingAuth: true,
      }
      return newState
    }
    case SUCCESS_VENDOR_TCIN_MAPPING: {
      const newState = {
        ...state,
        validTcins: payload.validTcins,
        invalidTcins: payload.invalidTcins,
        unauthorizedTcins: payload.unauthorizedTcins,
        isFetchingAuth: false,
      }
      return newState
    }
    case AUTH_RESET_FETCH: {
      const newState = {
        ...state,
        isFetchingAuth: payload.isFetching,
      }
      return newState
    }
    default:
      return state
  }
}
