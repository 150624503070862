import React, { useEffect, useState } from 'react'
import styles from '../theme'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { set, delete as Delete } from 'dot-prop-immutable'

function EditImageLabel(props) {
  const {
    classes = {},
    isEditImageLabelOpen,
    toggleEditImageLabelDialogue,
    savedLabelList,
    auth,
  } = props

  const [savedValuesList, setSavedValuesList] = useState([])

  useEffect(() => {
    setSavedValuesList(savedLabelList && savedLabelList[0])
  }, [isEditImageLabelOpen, savedLabelList])

  const labelCategory = ['Dim Image']
  const labelValues = ['IsNotDimensionalDrawing', 'DimensionalDrawing']

  const vaidateFilledForm = () => {
    const validateValue =
      savedValuesList &&
      savedValuesList.assetLabels &&
      savedValuesList.assetLabels
    if (validateValue && validateValue.length === 0) {
      return false
    } else if (validateValue && validateValue.length) {
      return (
        validateValue.filter((item) => !item.assetLabel || !item.values.length)
          .length > 0
      )
    } else {
      return false
    }
  }

  const editLabelRow = (input) => {
    const { modifiedValue, lid, list, columnID } = input
    const { values, assetLabel } = list
    const editedValuesLabels = {
      assetLabel: columnID === 1 ? modifiedValue : assetLabel,
      updatedBy: auth.email,
      values: columnID === 1 ? values : modifiedValue,
    }
    const updatedLabelRow = set(
      savedValuesList,
      `assetLabels.${lid}`,
      editedValuesLabels
    )
    setSavedValuesList(updatedLabelRow)
  }

  const addLabelRow = () => {
    const addRowValues = {
      assetLabel: '',
      updatedBy: '',
      values: [],
    }
    const addedLabelRow = set(savedValuesList, 'assetLabels', (list) => [
      ...list,
      addRowValues,
    ])
    setSavedValuesList(addedLabelRow)
  }

  const deleteLabelRow = (input) => {
    const { lid } = input
    const deletedLabelRow = Delete(savedValuesList, `assetLabels.${lid}`)
    setSavedValuesList(deletedLabelRow)
  }

  let mappingLabelValues = savedValuesList && savedValuesList.assetLabels

  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted
        maxWidth="md"
        open={isEditImageLabelOpen}
        aria-labelledby="alert-dialog-image-label"
        aria-describedby="alert-dialog-imageLable-description"
      >
        <DialogTitle
          className={classes.saveFilterTitle}
          id="alert-dialog-image-label"
        >
          <div>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.saveTitleText}
            >
              Edit Image Labels
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.marginTop20}
            id="alert-dialog-imageLable-description"
          >
            {mappingLabelValues && mappingLabelValues.length === 0 && (
              <p>No labels are assigned to this image</p>
            )}
            <div className={classes.labelSeleclistWrapper}>
              {mappingLabelValues &&
                mappingLabelValues.map((labelCategoryList, labelArrayIndex) => {
                  return (
                    <div
                      className={`${classes.labelSeleclistWrapperInner} ${classes.marginTop20}`}
                    >
                      <div
                        className={`${classes.labelSeleclistInner} ${classes.marginRight20}`}
                      >
                        <FormControl>
                          <InputLabel id="single-multiple-name-label">
                            Label Category
                          </InputLabel>
                          <Select
                            name="labelCategory"
                            className={`${classes.multiSelectWidth}`}
                            value={
                              labelCategoryList && labelCategoryList.assetLabel
                            }
                            fullWidth
                            onChange={(e) =>
                              editLabelRow({
                                modifiedValue: e.target.value,
                                lid: labelArrayIndex,
                                list: labelCategoryList,
                                columnID: 1,
                              })
                            }
                          >
                            {labelCategory &&
                              labelCategory.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className={`${classes.labelSeleclistInner}`}>
                        <FormControl>
                          <InputLabel id="multiple-name-label">
                            Label
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={
                              labelCategoryList && labelCategoryList.values
                            }
                            className={`${classes.multiSelectWidth}`}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={{
                              variant: 'menu',
                            }}
                            onChange={(e) =>
                              editLabelRow({
                                modifiedValue: e.target.value,
                                lid: labelArrayIndex,
                                list: labelCategoryList,
                                columnID: 2,
                              })
                            }
                          >
                            {labelValues.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  color="primary"
                                  checked={
                                    labelCategoryList &&
                                    labelCategoryList.values.indexOf(name) > -1
                                  }
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <DeleteIcon
                        className={`${classes.actionIcons} ${classes.marginLeft20} ${classes.verticalAlignBotttom}`}
                        color="primary"
                        onClick={() =>
                          deleteLabelRow({
                            lid: labelArrayIndex,
                          })
                        }
                      />
                    </div>
                  )
                })}
            </div>
            <Button
              variant="outlined"
              color="primary"
              className={classes.marginTop20}
              onClick={() => addLabelRow()}
            >
              Add Label
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              toggleEditImageLabelDialogue(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.postAssetLabel(savedValuesList)
            }}
            disabled={vaidateFilledForm()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default withStyles(styles)(EditImageLabel)
