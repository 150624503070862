const styles = (theme) => ({
  header: {
    height: 80,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 88,
    },
  },
  marginLeftMedium: {
    marginLeft: theme.spacing(2),
  },
  toolbarTab: {
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
  headerTab: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-4),
  },
  appBar: {
    backgroundColor: '#CC0000',
  },
  button: {
    color: theme.palette.primary.contrastText,
  },
  whiteColor: {
    color: 'white',
  },
  noColor: {
    backgroundColor: 'transparent',
  },
})

export default styles
