import React from 'react'
import CopyDataActionDrawer from './CopyDataActionDrawer'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  selectRoutingTeam,
  selectAssignedWriter,
  setPlannerNotesEvent,
  addPlannerNotes,
  toggleActionDrawer,
  setRoutingTeamEvent,
  assignWriterToCopy,
  requestCopyData,
  selectTypeCopyBulletDelete,
  handleValueDeleteOption,
  cancelCopyBulletDelete,
} from '../../../../../../../store/longCopy/actionCreator'

class CopyDataActionDrawerContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }
  handleRoutingTeamEvent = () => {
    let userId = this.props.auth.email
      ? this.props.auth.email
      : this.props.auth.lanId
    const trackingIds = this.props.selectedCopy.map((item) => item.id)
    var requestBody = {
      tracking_ids: trackingIds,
      confirmed_by: userId,
      route_team: this.props.currentRouteTeam,
    }
    this.props.setRoutingTeamEvent(requestBody)
    this.toggleActionDrawer(false)
    const vm = this
    setTimeout(function () {
      vm.props.requestCopyData(
        vm.props.selectedFilters,
        vm.props.currentPage,
        vm.props.defaultPageSize,
        userId,
        vm.props.sortDirection,
        vm.props.sortField
      )
    }, 1500)
  }
  selectRouteTeam = (event) => {
    this.props.selectRoutingTeam(event.target.value)
  }
  selectAssignedWriter = (option) => {
    this.props.selectAssignedWriter(option)
  }
  assignWriterToCopy = () => {
    const trackingIds = this.props.selectedCopy.map((item) => item.id)
    let userId = this.props.auth.email
      ? this.props.auth.email
      : this.props.auth.lanId
    this.props.assignWriterToCopy(
      this.props.currentWriterAssigned,
      trackingIds,
      userId
    )
    this.toggleActionDrawer(false)
    const vm = this
    setTimeout(function () {
      vm.props.requestCopyData(
        vm.props.selectedFilters,
        vm.props.currentPage,
        vm.props.defaultPageSize,
        userId,
        vm.props.sortDirection,
        vm.props.sortField
      )
    }, 1500)
  }
  toggleActionDrawer = (isActionDrawerOpen, drawerAction) => {
    this.props.toggleActionDrawer(isActionDrawerOpen, drawerAction)
  }
  closeEscapeKey = (event, keyPressed) => {
    if (event.keyCode === 27) {
      this.toggleActionDrawer(false)
    }
  }
  handlePlannerNotesEvent = () => {
    let userId = this.props.auth.email
      ? this.props.auth.email
      : this.props.auth.lanId
    const trackingIds = this.props.selectedCopy.map((item) => item.id)
    this.props.setPlannerNotesEvent(
      this.props.plannerNotes,
      trackingIds,
      userId
    )
    this.toggleActionDrawer(false)
    const vm = this
    setTimeout(function () {
      vm.toggleActionDrawer(false)
      vm.props.requestCopyData(
        vm.props.selectedFilters,
        vm.props.currentPage,
        vm.props.defaultPageSize,
        userId,
        vm.props.sortDirection,
        vm.props.sortField
      )
    }, 1500)
  }
  handleChangePlannerNotes = (event) => {
    this.props.addPlannerNotes(event.target.value)
  }
  selectTypeCopyBulletDelete = () => {
    this.props.selectTypeCopyBulletDelete(
      true,
      this.props.deleteData.selectDeleteType,
      false
    )
    this.toggleActionDrawer(false)
  }
  handleValueDeleteOption = (event) => {
    this.props.handleValueDeleteOption(event.target.value)
  }
  cancelCopyBulletDelete = () => {
    this.props.cancelCopyBulletDelete()
  }

  render() {
    const {
      selectedCopy,
      isActionDrawerOpen,
      drawerAction,
      currentRouteTeam,
      isFetchingWriters,
      isLoading,
      currentWriterAssigned,
      toggleActionDrawerForAssigningWriters,
      assignedWritersList,
      auth,
      deleteData,
      deleteBulletAndCopy,
      downloadSelectedTcinsCopy,
    } = this.props
    return (
      <CopyDataActionDrawer
        selectedCopy={selectedCopy}
        toggleActionDrawer={this.toggleActionDrawer}
        toggleActionDrawerForAssigningWriters={
          toggleActionDrawerForAssigningWriters
        }
        assignWriterToCopy={this.assignWriterToCopy}
        closeEscapeKey={this.closeEscapeKey}
        drawerAction={drawerAction}
        selectRouteTeam={this.selectRouteTeam}
        isFetchingWriters={isFetchingWriters}
        assignedWritersList={assignedWritersList}
        currentWriterAssigned={currentWriterAssigned}
        selectAssignedWriter={this.selectAssignedWriter}
        handleChangePlannerNotes={this.handleChangePlannerNotes}
        currentRouteTeam={currentRouteTeam}
        handlePlannerNotesEvent={this.handlePlannerNotesEvent}
        isActionDrawerOpen={isActionDrawerOpen}
        handleRoutingTeamEvent={this.handleRoutingTeamEvent}
        isLoading={isLoading}
        auth={auth}
        deleteData={deleteData}
        handleValueDeleteOption={this.handleValueDeleteOption}
        selectTypeCopyBulletDelete={this.selectTypeCopyBulletDelete}
        cancelCopyBulletDelete={this.cancelCopyBulletDelete}
        deleteBulletAndCopy={deleteBulletAndCopy}
        downloadSelectedTcinsCopy={downloadSelectedTcinsCopy}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectRoutingTeam,
      selectAssignedWriter,
      setPlannerNotesEvent,
      addPlannerNotes,
      toggleActionDrawer,
      setRoutingTeamEvent,
      requestCopyData,
      assignWriterToCopy,
      selectTypeCopyBulletDelete,
      handleValueDeleteOption,
      cancelCopyBulletDelete,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { longCopy, auth } = state
  const {
    selectedCopy,
    isActionDrawerOpen,
    drawerAction,
    currentRouteTeam,
    isFetchingWriters,
    assignedWritersList,
    currentWriterAssigned,
    plannerNotes,
    isLoading,
    selectedFilters,
    currentPage,
    defaultPageSize,
    deleteData,
    sortDirection,
    sortField,
  } = longCopy
  return {
    selectedCopy,
    isActionDrawerOpen,
    drawerAction,
    currentRouteTeam,
    isFetchingWriters,
    assignedWritersList,
    currentWriterAssigned,
    plannerNotes,
    auth,
    isLoading,
    selectedFilters,
    currentPage,
    defaultPageSize,
    deleteData,
    sortDirection,
    sortField,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyDataActionDrawerContainer)
