const styles = (theme) => ({
  button: {
    margin: '0px 0px 0px 5px',
    backgroundColor: theme.palette.primary[500],
    borderRadius: '5px',
    color: 'white',
    '&:hover': { backgroundColor: theme.palette.primary[700] },
    '&:disabled': { backgroundColor: '#ddd' },
  },
  marginTop10: {
    marginTop: '10px',
  },
  sendToTargetButton: {
    margin: '30px 5px',
  },
  cancelButton: {
    margin: '30px 40px',
  },
  browseButtonText: {
    marginLeft: '5px',
  },
  dropZoneNotActive: {
    margin: 'auto',
    width: '80%',
    border: 'dashed 1px black',
    padding: '20px',
    marginTop: '50px',
    marginBottom: '50px',
  },
  dropZoneActive: {
    backgroundColor: 'rgba(173, 216, 230, 0.5)',
    margin: 'auto',
    width: '80%',
    border: 'dashed 1px rgba(33,150,243, 1)',
    padding: '20px',
    marginTop: '50px',
    marginBottom: '50px',
  },
  uploadIcon: {
    width: '75px',
    height: '75px',
  },
  DropZoneLargeText: {
    marginBottom: '0px !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '24px',
    color: '#999999',
    textAlign: 'center',
  },
  DropZoneSmallText: {
    marginTop: '0px !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '200',
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#999999',
    textAlign: 'center',
  },
  progressBar: {
    marginTop: '30px',
    marginLeft: '50%',
  },
  numberOfFilesText: {
    color: '#757575',
  },
  redFont: {
    color: 'red',
  },
  margin10: {
    margin: '10px',
  },
  header: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '.005em',
  },
  data: {
    fontSize: '14px',
    letterSpacing: '.010em',
    fontWeight: '400',
    lineHeight: '20px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
})

export default styles
