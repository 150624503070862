import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import HeaderTitle from '../Shared/Header/HeaderTitle'
import { Card, CardContent, Grid, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import DefaultAlert from '../Shared/StrapiComponents/DefaultAlert'
import { bindActionCreators } from 'redux'
import { getHomePageStrapiData } from '../../store/layout/actionCreator'
import MDReactComponent from 'markdown-react-js'
import { isExternalUser } from '../Shared/SharedUtils'
import Alert from '../Shared/StrapiComponents/Alert'
import envConfig from '../../config/apiConfig'
import { includes } from 'lodash'

function HomePage(props) {
  const {
    headerTitle,
    classes,
    alert,
    headline,
    announcement,
    faq,
    auth,
    showDefaultAlert,
  } = props
  useEffect(() => {
    props.getHomePageStrapiData(isExternalUser(auth))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <div>
        <HeaderTitle title="Home" />
        <Helmet title={headerTitle} />
        {showDefaultAlert ? <DefaultAlert /> : null}
        {alert ? <Alert alert={alert} /> : null}
        {headline ? (
          <Card className={`${classes.margin10} ${classes.header}`}>
            <CardContent>
              {includes(auth.permission.hostName, 'vendorpipeline') ? (
                <React.Fragment>
                  <MDReactComponent
                    className={`${classes.marginBottom10} ${classes.header}`}
                    text={'Vendor Pipeline is Moving!'}
                  />
                  <span className={classes.data}>
                    Coming July 21st, Vendor Pipeline will only be accessible at{' '}
                    <a href={envConfig.hostName.externalUrlV2}>
                      {envConfig.hostName.externalUrlV2}
                    </a>
                    . You can find all your favorite Pipeline tools there. CGI
                    App will remain here until July 21st.
                  </span>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    className={`${classes.margin10}`}
                    onClick={() => {
                      window.location = envConfig.hostName.externalUrlV2
                    }}
                  >
                    Go there now!
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {headline.title ? (
                    <MDReactComponent
                      className={`${classes.marginBottom10} ${classes.header}`}
                      text={headline.title}
                    />
                  ) : null}
                  {headline.body ? (
                    <MDReactComponent
                      className={classes.data}
                      text={headline.body}
                    />
                  ) : null}
                </React.Fragment>
              )}
            </CardContent>
          </Card>
        ) : null}
        <Grid container>
          {faq ? (
            <Grid item xs={9}>
              <Card className={classes.margin10}>
                <CardContent>
                  {faq.title ? (
                    <MDReactComponent
                      className={`${classes.marginBottom10} ${classes.header}`}
                      text={faq.title}
                    />
                  ) : null}
                  {faq.body ? (
                    <MDReactComponent
                      className={classes.data}
                      text={faq.body}
                    />
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          {announcement ? (
            <Grid item xs={3}>
              <Card className={classes.margin10}>
                <CardContent>
                  {announcement.title ? (
                    <MDReactComponent
                      className={`${classes.marginBottom10} ${classes.header}`}
                      text={announcement.title}
                    />
                  ) : null}
                  {announcement.body ? (
                    <MDReactComponent
                      className={classes.data}
                      text={announcement.body}
                    />
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHomePageStrapiData,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { layout, auth } = state
  const { headerTitle, alert, headline, announcement, faq, showDefaultAlert } =
    layout
  return {
    headerTitle,
    alert,
    headline,
    announcement,
    faq,
    auth,
    showDefaultAlert,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HomePage))
