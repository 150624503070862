import React from 'react'
import styles from '../theme'
import { withStyles } from '@material-ui/core/styles'
import { TableRow, TableCell, Checkbox } from '@material-ui/core'
import { some } from 'lodash'
import ItemDetails from '../../Shared/Components/ItemDetails'
import ImageToReview from './ImageToReview'
import { RELATIONSHIP_TYPE_CODE } from '../../../Shared/Constants'
import {
  requestedPositionToString,
  determineRelationshipTypeCode,
} from '../../../Shared/SharedUtils'

function ImageReviewResults(props) {
  const {
    classes = {},
    reviewData = [],
    selectedReviewImages = [],
    handleImageClick = () => {},
    handleItemClick = () => {},
    openZoomedReview = () => {},
  } = props

  const isSelected = (checkedItem) => {
    const filterToItem = some(selectedReviewImages)
      ? selectedReviewImages.filter((image) => image.tcin === checkedItem.tcin)
      : []
    return (
      some(filterToItem) &&
      some(checkedItem.image_list) &&
      filterToItem.length === checkedItem.image_list.length
    )
  }

  const itemClickHandler = (_, clickedItem) => {
    const selected = isSelected({
      tcin: clickedItem.tcin,
      image_list: clickedItem.image_list,
    })
    // if item is already selected, remove images
    // else, if no images are selected or indeterminate, filter and append all
    handleItemClick(_, clickedItem, !selected)
  }

  const singleImageClickHandler = (_, clickedImage, tcin) => {
    const reviewItem = {
      tcin: tcin,
      image: clickedImage,
    }
    handleImageClick(_, reviewItem)
  }

  return (
    <React.Fragment>
      {reviewData.map((item) => {
        let findIsSelected = isSelected({
          tcin: item.tcin,
          image_list: item.image_list,
        })
        let itemReviewImages = selectedReviewImages.filter(
          (selected) => selected.tcin === item.tcin
        )
        const relationshipType =
          some(item.item_info) && some(item.item_info.relationship_type_codes)
            ? determineRelationshipTypeCode(
                item.item_info.relationship_type_codes
              )
            : RELATIONSHIP_TYPE_CODE.NA

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={findIsSelected}
            tabIndex={-1}
            key={item.tcin}
            selected={findIsSelected}
          >
            <TableCell padding="checkbox" className={classes.width5Per}>
              <Checkbox
                data-test-id="row-checkbox"
                indeterminate={
                  some(itemReviewImages) &&
                  some(item.image_list) &&
                  itemReviewImages.length < item.image_list.length
                }
                checked={findIsSelected}
                color="primary"
                onClick={(event) => itemClickHandler(event, item)}
              />
            </TableCell>
            <TableCell padding="default">
              <ItemDetails
                tcin={item.tcin}
                title={item.item_info.title}
                launchDate={item.item_info.launch_date}
                showZoomedReview
                openZoomedReview={() => openZoomedReview(item.tcin)}
              />
            </TableCell>
            <TableCell padding="default" className={classes.width5Per}>
              {relationshipType}
            </TableCell>
            <TableCell padding="default">
              <div className={classes.flex}>
                <ImageToReview
                  item={item}
                  itemReviewImages={itemReviewImages}
                  handleClick={singleImageClickHandler}
                  imageDictionary={createImageDictionary(item)}
                />
              </div>
            </TableCell>
          </TableRow>
        )
      })}
    </React.Fragment>
  )
}

export const createImageDictionary = (item) => {
  const reviewSequences = item.image_list.map((r) =>
    requestedPositionToString(r.requested_position)
  )
  const liveSequences = item.live_image_list.map((r) => r.suffix)

  const imageDictionary = {}

  reviewSequences.forEach((reviewSequence) => {
    if (imageDictionary[reviewSequence] === undefined) {
      imageDictionary[reviewSequence] = 1
    } else {
      imageDictionary[reviewSequence] = imageDictionary[reviewSequence] + 1
    }
  })

  liveSequences.forEach((liveSequence) => {
    if (imageDictionary[liveSequence] === undefined) {
      imageDictionary[liveSequence] = 1
    }
  })

  const orderedImageDictionary = new Map()
  Object.keys(imageDictionary)
    .sort()
    .forEach((key) => {
      orderedImageDictionary.set(key, imageDictionary[key])
    })

  return orderedImageDictionary
}

// @ts-ignore
export default withStyles(styles)(ImageReviewResults)
