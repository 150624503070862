import { IMAGE_APPROVAL_STATUS } from '../../Shared/Constants'

const FilterData = [
  {
    display: 'Review Approval Status',
    value: 'approvalStatus',
  },
  {
    display: 'Review Date Range',
    value: 'dateRange',
  },
  {
    display: 'Dept-Class',
    value: 'deptClass',
  },
  {
    display: 'TCIN(s)',
    value: 'tcin',
  },
  {
    display: 'Vendor',
    value: 'vendor',
  },
  {
    display: 'Brand',
    value: 'brand',
  },
  {
    display: 'Launch Date',
    value: 'launchDate',
  },
  {
    display: 'DPCI(s)',
    value: 'dpcis',
  },
  {
    display: 'Item Setup Status',
    value: 'itemSetupStatus',
  },
  {
    display: 'Weeks',
    value: 'weeksOutAgo',
  },
]

const LaunchTimespans = [
  {
    display: 'Last 52 Weeks',
    value: 'last52Weeks',
  },
  {
    display: 'Date Picker',
    value: 'datePicker',
  },
]

const Timespans = [
  {
    display: 'Last 24 Hours',
    value: 'last24Hours',
  },
  {
    display: 'Last 3 Days',
    value: 'last3Days',
  },
  {
    display: 'last 7 Days',
    value: 'last7Days',
  },
  {
    display: 'last 14 Days',
    value: 'last14Days',
  },
  {
    display: 'last 30 Days',
    value: 'last30Days',
  },
  {
    display: 'Date Picker',
    value: 'datePicker',
  },
]

const ImageApprovalStatus = [
  {
    display: IMAGE_APPROVAL_STATUS.APPROVED,
    value: IMAGE_APPROVAL_STATUS.APPROVED,
  },
  {
    display: 'Ready for Review',
    value: IMAGE_APPROVAL_STATUS.IN_PROCESS,
  },
  {
    display: IMAGE_APPROVAL_STATUS.REJECTED,
    value: IMAGE_APPROVAL_STATUS.REJECTED,
  },
]

const IsuItemSetUpStatus = [
  {
    display: 'Initiated',
    value: 'INITIATED',
  },
  {
    display: 'Ready for Order',
    value: 'READY_FOR_ORDER',
  },
  {
    display: 'Ready for Launch',
    value: 'READY_FOR_LAUNCH',
  },
  {
    display: 'History',
    value: 'HISTORICAL',
  },
]

const WeeksInOutData = [
  {
    label: 'Last Week (1 Week Ago)',
    value: -1,
  },
  {
    label: 'This Week',
    value: 0,
  },
  {
    label: 'Next Week (1 Week Out)',
    value: 1,
  },
  {
    label: '2 Weeks Out',
    value: 2,
  },
  {
    label: '3 Weeks Out',
    value: 3,
  },
  {
    label: '4 Weeks Out',
    value: 4,
  },
  {
    label: '5 Weeks Out',
    value: 5,
  },
  {
    label: '6 Weeks Out',
    value: 6,
  },
  {
    label: '7 Weeks Out',
    value: 7,
  },
  {
    label: '8 Weeks Out',
    value: 8,
  },
  {
    label: '9 Weeks Out',
    value: 9,
  },
  {
    label: '10 Weeks Out',
    value: 10,
  },
  {
    label: '11 Weeks Out',
    value: 11,
  },
  {
    label: '12 Weeks Out',
    value: 12,
  },
  {
    label: '13 Weeks Out',
    value: 13,
  },
  {
    label: '14 Weeks Out',
    value: 14,
  },
  {
    label: '15 Weeks Out',
    value: 15,
  },
  {
    label: '16 Weeks Out',
    value: 16,
  },
  {
    label: '17 Weeks Out',
    value: 17,
  },
  {
    label: '18 Weeks Out',
    value: 18,
  },
  {
    label: '19 Weeks Out',
    value: 19,
  },
  {
    label: '20 Weeks Out',
    value: 20,
  },
  {
    label: '21 Weeks Out',
    value: 21,
  },
  {
    label: '22 Weeks Out',
    value: 22,
  },
  {
    label: '23 Weeks Out',
    value: 23,
  },
  {
    label: '24 Weeks Out',
    value: 24,
  },
  {
    label: '25 Weeks Out',
    value: 25,
  },
  {
    label: '26 Weeks Out',
    value: 26,
  },
  {
    label: '27 Weeks Out',
    value: 27,
  },
  {
    label: '28 Weeks Out',
    value: 28,
  },
  {
    label: '29 Weeks Out',
    value: 29,
  },
  {
    label: '30 Weeks Out',
    value: 30,
  },
  {
    label: '31 Weeks Out',
    value: 31,
  },
  {
    label: '32 Weeks Out',
    value: 32,
  },
  {
    label: '33 Weeks Out',
    value: 33,
  },
  {
    label: '34 Weeks Out',
    value: 34,
  },
  {
    label: '35 Weeks Out',
    value: 35,
  },
  {
    label: '36 Weeks Out',
    value: 36,
  },
  {
    label: '37 Weeks Out',
    value: 37,
  },
  {
    label: '38 Weeks Out',
    value: 38,
  },
  {
    label: '39 Weeks Out',
    value: 39,
  },
  {
    label: '40 Weeks Out',
    value: 40,
  },
  {
    label: '41 Weeks Out',
    value: 41,
  },
  {
    label: '42 Weeks Out',
    value: 42,
  },
  {
    label: '43 Weeks Out',
    value: 43,
  },
  {
    label: '44 Weeks Out',
    value: 44,
  },
  {
    label: '45 Weeks Out',
    value: 45,
  },
  {
    label: '46 Weeks Out',
    value: 46,
  },
  {
    label: '47 Weeks Out',
    value: 47,
  },
  {
    label: '48 Weeks Out',
    value: 48,
  },
  {
    label: '49 Weeks Out',
    value: 49,
  },
  {
    label: '50 Weeks Out',
    value: 50,
  },
  {
    label: '51 Weeks Out',
    value: 51,
  },
  {
    label: '52 Weeks Out',
    value: 52,
  },
  {
    label: '2 Weeks Ago',
    value: -2,
  },
  {
    label: '3 Weeks Ago',
    value: -3,
  },
  {
    label: '4 Weeks Ago',
    value: -4,
  },
  {
    label: '5 Weeks Ago',
    value: -5,
  },
  {
    label: '6 Weeks Ago',
    value: -6,
  },
  {
    label: '7 Weeks Ago',
    value: -7,
  },
  {
    label: '8 Weeks Ago',
    value: -8,
  },
  {
    label: '9 Weeks Ago',
    value: -9,
  },
  {
    label: '10 Weeks Ago',
    value: -10,
  },
  {
    label: '11 Weeks Ago',
    value: -11,
  },
  {
    label: '12 Weeks Ago',
    value: -12,
  },
  {
    label: '13 Weeks Ago',
    value: -13,
  },
  {
    label: '14 Weeks Ago',
    value: -14,
  },
  {
    label: '15 Weeks Ago',
    value: -15,
  },
  {
    label: '16 Weeks Ago',
    value: -16,
  },
  {
    label: '17 Weeks Ago',
    value: -17,
  },
  {
    label: '18 Weeks Ago',
    value: -18,
  },
  {
    label: '19 Weeks Ago',
    value: -19,
  },
  {
    label: '20 Weeks Ago',
    value: -20,
  },
  {
    label: '21 Weeks Ago',
    value: -21,
  },
  {
    label: '22 Weeks Ago',
    value: -22,
  },
  {
    label: '23 Weeks Ago',
    value: -23,
  },
  {
    label: '24 Weeks Ago',
    value: -24,
  },
  {
    label: '25 Weeks Ago',
    value: -25,
  },
  {
    label: '26 Weeks Ago',
    value: -26,
  },
  {
    label: '27 Weeks Ago',
    value: -27,
  },
  {
    label: '28 Weeks Ago',
    value: -28,
  },
  {
    label: '29 Weeks Ago',
    value: -29,
  },
  {
    label: '30 Weeks Ago',
    value: -30,
  },
  {
    label: '31 Weeks Ago',
    value: -31,
  },
  {
    label: '32 Weeks Ago',
    value: -32,
  },
  {
    label: '33 Weeks Ago',
    value: -33,
  },
  {
    label: '34 Weeks Ago',
    value: -34,
  },
  {
    label: '35 Weeks Ago',
    value: -35,
  },
  {
    label: '36 Weeks Ago',
    value: -36,
  },
  {
    label: '37 Weeks Ago',
    value: -37,
  },
  {
    label: '38 Weeks Ago',
    value: -38,
  },
  {
    label: '39 Weeks Ago',
    value: -39,
  },
  {
    label: '40 Weeks Ago',
    value: -40,
  },
  {
    label: '41 Weeks Ago',
    value: -41,
  },
  {
    label: '42 Weeks Ago',
    value: -42,
  },
  {
    label: '43 Weeks Ago',
    value: -43,
  },
  {
    label: '44 Weeks Ago',
    value: -44,
  },
  {
    label: '45 Weeks Ago',
    value: -45,
  },
  {
    label: '46 Weeks Ago',
    value: -46,
  },
  {
    label: '47 Weeks Ago',
    value: -47,
  },
  {
    label: '48 Weeks Ago',
    value: -48,
  },
  {
    label: '49 Weeks Ago',
    value: -49,
  },
  {
    label: '50 Weeks Ago',
    value: -50,
  },
  {
    label: '51 Weeks Ago',
    value: -51,
  },
  {
    label: '52 Weeks Ago',
    value: -52,
  },
]

export {
  FilterData,
  Timespans,
  ImageApprovalStatus,
  IsuItemSetUpStatus,
  LaunchTimespans,
  WeeksInOutData,
}
