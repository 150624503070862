import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BulkUploadReportDataContainerHead from './BulkUploadReportDataContainerHead'
import BulkUploadReportDataContainerToolbar from './BulkUploadReportDataContainerToolbar'
import BulkUploadReportDataContainerTitle from './BulkUploadReportDataContainerTitle'
import styles from '../../theme'
import Moment from 'react-moment'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core'

function escapeHtml(input) {
  return { __html: input }
}

function BulkUploadReportData(props) {
  const {
    selectedCopy = [],
    classes = {},
    data = [],
    page = 0,
    rowsPerPage = 0,
    emptyRows = 0,
    handleSelectAllClick = () => {},
    isSelected = false,
    handleClick = () => {},
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    totalElements = 0,
    auth = {},
    downloadSelectedTcinsCopy = () => {},
    downloadAllToExcel = () => {},
    deleteBulletAndCopy = () => {},
  } = props

  return (
    <div>
      <Paper className={classes.copyDataroot}>
        {data.length > 0 && (
          <React.Fragment>
            <BulkUploadReportDataContainerTitle
              numSelected={selectedCopy.length}
              downloadAllToExcel={downloadAllToExcel}
              totalElements={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <BulkUploadReportDataContainerHead
                  numSelected={selectedCopy.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rowsPerPage}
                  copyDataLength={data.length}
                />
                <TableBody>
                  {data.map((n) => {
                    const findIsSelected = isSelected(n)
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={findIsSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={findIsSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={findIsSelected}
                            color="primary"
                            onClick={(event) => handleClick(event, n)}
                          />
                        </TableCell>
                        <TableCell padding="none">{n.tcin}</TableCell>
                        <TableCell
                          padding="none"
                          className={classes.maxWidth350}
                        >
                          {n.long_copy}
                        </TableCell>
                        <TableCell padding="none">
                          <ul>
                            {n.feature_bullets.map((bullet) => {
                              return (
                                <li
                                  key={bullet}
                                  dangerouslySetInnerHTML={escapeHtml(bullet)}
                                />
                              )
                            })}
                          </ul>
                        </TableCell>
                        <TableCell padding="none">{n.company}</TableCell>
                        <TableCell padding="none">{n.created_by}</TableCell>
                        <TableCell padding="none">
                          {n.created_time ? (
                            <Moment
                              format={'YYYY-MMM-DD'}
                              parse={'DD-MM-YYYY HH:mm:ss'}
                            >
                              {n.created_time}
                            </Moment>
                          ) : (
                            ' - '
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={11} />
                    </TableRow>
                  )}
                  <TableRow className={classes.height49}>
                    <TableCell colSpan={11} />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <BulkUploadReportDataContainerToolbar
              selectedCopy={selectedCopy}
              downloadSelectedTcinsCopy={downloadSelectedTcinsCopy}
              deleteBulletAndCopy={deleteBulletAndCopy}
              auth={auth}
            />
          </React.Fragment>
        )}
      </Paper>
      {!data.length && (
        <div className={classes.noResult}>
          No results matched your filter criteria
        </div>
      )}
    </div>
  )
}
export default withStyles(styles)(BulkUploadReportData)
