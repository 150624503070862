/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable camelcase */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../../theme'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Card,
  Grid,
  Typography,
  Toolbar,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import Autocomplete from '../../../../Shared/Autocomplete/Autocomplete'
import CancelIcon from '@material-ui/icons/Cancel'
import GetAppIcon from '@material-ui/icons/GetApp'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SyncDisabledRoundedIcon from '@material-ui/icons/SyncDisabled'
import DeleteIcon from '@material-ui/icons/Delete'
import InsertLinkIcon from '@material-ui/icons/InsertLink'
import BadgeImageUploadContainer from '../../ImageUpload/BadgeImageUploadContainer'
import { regexSelector } from '../../../../Shared/SharedUtils'
import Moment from 'react-moment'
import envConfigs from '../../../../../config/apiConfig'
import { isNil, debounce, isEqual, get } from 'lodash'

function CreateBadge(props) {
  const {
    classes = {},
    cancelCreateBadge = () => {},
    saveBadge = () => {},
    createBadgeOpen = false,
    badgeFieldToEdit = '',
    brands = [],
    changeToEditState = () => {},
    badge = {},
    changeToViewState = () => {},
    changeBadge = () => {},
    changeBadgeCriteria = () => {},
    onSearchChange = () => {},
    auth = () => {},
    createBadgeInProcess = () => {},
    deleteBadge = () => {},
  } = props

  const [brandSearch, setBrandSearch] = React.useState('')
  const [localBrandsList, setLocalBrandsList] = React.useState(brands)

  React.useEffect(() => {
    if (!isEqual(brands, localBrandsList)) {
      setLocalBrandsList(brands)
    }
  }, [brands])

  React.useEffect(() => {
    if (get(badge.criteria, 'item_data.product_brand.brand', '')) {
      setBrandSearch(get(badge.criteria, 'item_data.product_brand.brand', ''))
    }
  }, [badge.criteria])

  // Important for for being nice to our BE servers
  // Don't let the users fire off queries with every keystroke
  // https://dev.to/sbrshkappa/what-is-debouncing-a-javascript-implementation-3aoh
  const onSearchChangeInternal = (text) => {
    onSearchChange(text)
  }

  const debounceOnSearchChange = React.useCallback(
    debounce(onSearchChangeInternal, 500),
    [onSearchChange]
  )

  const saveButtonText = (badge) => {
    if (badge.id === 'new') {
      return 'CREATE'
    } else {
      if (badge.active) {
        return 'REPUBLISH'
      } else {
        return 'ACTIVATE'
      }
    }
  }

  React.useEffect(() => {
    debounceOnSearchChange(brandSearch)
  }, [brandSearch])

  let disableSave = !(
    badge.badge_rank &&
    badge.badge_name &&
    badge.badge_id &&
    badge.mta_value_name &&
    (badge.badge_url || badge.image_job_id) &&
    badge.mta_name &&
    badge.mta_id &&
    badge.badge_description
  )

  return (
    <Dialog
      open={createBadgeOpen}
      onClose={() => cancelCreateBadge()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
      classes={{ paper: classes.minHeight45Per }}
    >
      {createBadgeInProcess ? (
        <DialogContent>
          <div>
            We are currently processing this... If your badge has a large number
            of items, this may take a while...
          </div>
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress size={200} />
          </div>
        </DialogContent>
      ) : (
        <DialogContent>
          <Grid container className={classes.marginBottom50}>
            <Card className={classes.maxWidth80Per}>
              <Grid container spacing={1}>
                <Grid item container>
                  <Grid item>
                    {badgeFieldToEdit === 'badge_name' || !badge.badge_name ? (
                      <TextField
                        name="badge_name"
                        label="Badge Name"
                        value={!badge.badge_name ? '' : badge.badge_name}
                        autoFocus={badgeFieldToEdit === 'badge_name'}
                        onChange={(e) =>
                          changeBadge(e.target.name, e.target.value)
                        }
                        onBlur={(e) => {
                          changeBadge(e.target.name, e.target.value)
                          changeToViewState()
                        }}
                        onFocus={() => changeToEditState('badge_name')}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            changeToViewState()
                          }
                        }}
                        fullWidth
                        className={classes.marginLeftBottom}
                      />
                    ) : (
                      <div
                        onClick={() => changeToEditState('badge_name')}
                        role="presentation"
                      >
                        <Typography variant="h6" className={classes.badgeValue}>
                          {badge.badge_name !== ''
                            ? badge.badge_name
                            : 'Click to add Badge Name'}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid item className={classes.margin10}>
                  {badgeFieldToEdit !== 'badge_url' &&
                  badgeFieldToEdit !== 'image_job_id' &&
                  badge.image_job_id === '' &&
                  badge.badge_url === '' ? (
                    <div
                      role="presentation"
                      className={classes.imageUploadOrUrl}
                    >
                      <Tooltip title="Upload Image">
                        <IconButton
                          onClick={() => changeToEditState('image_job_id')}
                        >
                          <CloudUploadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Set Image url">
                        <IconButton
                          onClick={() => changeToEditState('badge_url')}
                        >
                          <InsertLinkIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : badgeFieldToEdit === 'badge_url' ? (
                    <div>
                      <TextField
                        name="badge_url"
                        label="Badge URL"
                        value={badge.badge_url}
                        autoFocus
                        onChange={(e) => {
                          changeBadge(e.target.name, e.target.value)
                        }}
                        onBlur={(e) => {
                          changeBadge(e.target.name, e.target.value)
                          changeToViewState()
                        }}
                        onFocus={() => changeToEditState('badge_url')}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            changeToViewState()
                          }
                        }}
                        fullWidth
                        className={classes.marginTop40}
                      />
                      <span>
                        (must be a target.com or target.scene7.com domain)
                      </span>
                    </div>
                  ) : badgeFieldToEdit === 'image_job_id' ? (
                    <BadgeImageUploadContainer
                      changeToViewState={changeToViewState}
                    />
                  ) : badge.badge_url !== '' && badge.image_job_id === '' ? (
                    <div className={classes.showBadgeImage}>
                      <Tooltip title="Change URL">
                        <img
                          role="presentation"
                          onClick={() => changeToEditState('badge_url')}
                          src={badge.badge_url}
                          alt={badge.badge_name}
                          onError={(e) => {
                            e.target.src =
                              envConfigs.api.sceneSevenBaseUrl +
                              'badge-transfer'
                          }}
                          className={classes.badgeImage}
                        />
                      </Tooltip>
                      <div
                        onClick={() => {
                          changeBadge('image_job_id', '')
                          changeBadge('badge_url', '')
                        }}
                        role="presentation"
                      >
                        <Tooltip title="Remove URL">
                          <DeleteIcon className={classes.deleteIcon} />
                        </Tooltip>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.showBadgeImage}>
                      <Tooltip title="Change Image">
                        <img
                          role="presentation"
                          onClick={() => changeToEditState('image_job_id')}
                          src={badge.badge_url}
                          alt={badge.badge_name}
                          onError={(e) => {
                            e.target.src =
                              envConfigs.api.sceneSevenBaseUrl +
                              'badge-transfer'
                          }}
                          className={classes.badgeImage}
                        />
                      </Tooltip>
                      <div
                        onClick={() => {
                          changeBadge('image_job_id', '')
                          changeBadge('badge_url', '')
                        }}
                        role="presentation"
                      >
                        <Tooltip title="Remove Image">
                          <DeleteIcon className={classes.deleteIcon} />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  sm
                  container
                  direction="row"
                  className={classes.margin10}
                >
                  <Grid item container spacing={1}>
                    <Grid item xs={4} className={classes.marginBottom25}>
                      <Typography variant="h6">Merch Type Attribute</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {badgeFieldToEdit === 'mta_name' || !badge.mta_name ? (
                        <TextField
                          name="mta_name"
                          label="MTA Name"
                          value={!badge.mta_name ? '' : badge.mta_name}
                          autoFocus={badgeFieldToEdit === 'mta_name'}
                          onChange={(e) =>
                            changeBadge(e.target.name, e.target.value)
                          }
                          onBlur={(e) => {
                            changeBadge(e.target.name, e.target.value)
                            changeToViewState()
                          }}
                          onFocus={() => changeToEditState('mta_name')}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              changeToViewState()
                            }
                          }}
                          fullWidth
                          className={classes.neg16MarginTop}
                        />
                      ) : (
                        <div
                          onClick={() => changeToEditState('mta_name')}
                          role="presentation"
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.badgeValue}
                          >
                            {badge.mta_name !== ''
                              ? badge.mta_name
                              : 'Click to add MTA Name'}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {badgeFieldToEdit === 'mta_id' || !badge.mta_id ? (
                        <TextField
                          name="mta_id"
                          label="MTA ID"
                          value={!badge.mta_id ? '' : badge.mta_id}
                          autoFocus={badgeFieldToEdit === 'mta_id'}
                          onChange={(e) =>
                            changeBadge(
                              e.target.name,
                              regexSelector('number', e)
                            )
                          }
                          onBlur={(e) => {
                            changeBadge(
                              e.target.name,
                              regexSelector('number', e)
                            )
                            changeToViewState()
                          }}
                          onFocus={() => changeToEditState('mta_id')}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              changeToViewState()
                            }
                          }}
                          fullWidth
                          className={classes.neg16MarginTop}
                        />
                      ) : (
                        <div
                          onClick={() => changeToEditState('mta_id')}
                          role="presentation"
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.badgeValue}
                          >
                            {badge.mta_id !== ''
                              ? badge.mta_id
                              : 'Click to add MTA ID'}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4} className={classes.marginBottom25}>
                      <Typography variant="h6">Attribute Value</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {badgeFieldToEdit === 'mta_value_name' ||
                      !badge.mta_value_name ? (
                        <TextField
                          name="mta_value_name"
                          label="MTA Value"
                          value={
                            !badge.mta_value_name ? '' : badge.mta_value_name
                          }
                          autoFocus={badgeFieldToEdit === 'mta_value_name'}
                          onChange={(e) =>
                            changeBadge(e.target.name, e.target.value)
                          }
                          onBlur={(e) => {
                            changeBadge(e.target.name, e.target.value)
                            changeToViewState()
                          }}
                          onFocus={() => changeToEditState('mta_value_name')}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              changeToViewState()
                            }
                          }}
                          fullWidth
                          className={classes.neg16MarginTop}
                        />
                      ) : (
                        <div
                          onClick={() => changeToEditState('mta_value_name')}
                          role="presentation"
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.badgeValue}
                          >
                            {badge.mta_value_name !== ''
                              ? badge.mta_value_name
                              : 'Click to add MTA Value'}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {badgeFieldToEdit === 'badge_id' || !badge.badge_id ? (
                        <TextField
                          name="badge_id"
                          label="Value ID"
                          value={!badge.badge_id ? '' : badge.badge_id}
                          autoFocus={badgeFieldToEdit === 'badge_id'}
                          onChange={(e) =>
                            changeBadge(
                              e.target.name,
                              regexSelector('number', e)
                            )
                          }
                          onBlur={(e) => {
                            changeBadge(
                              e.target.name,
                              regexSelector('number', e)
                            )
                            changeToViewState()
                          }}
                          onFocus={() => changeToEditState('badge_id')}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              changeToViewState()
                            }
                          }}
                          fullWidth
                          className={classes.neg16MarginTop}
                        />
                      ) : (
                        <div
                          onClick={() => changeToEditState('badge_id')}
                          role="presentation"
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.badgeValue}
                          >
                            {badge.badge_id !== ''
                              ? badge.badge_id
                              : 'Click to add Value ID'}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4} className={classes.marginBottom25}>
                      <Typography variant="h6">Rank (order)</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {badgeFieldToEdit === 'badge_rank' ||
                      !badge.badge_rank ? (
                        <TextField
                          name="badge_rank"
                          label="Badge Rank"
                          value={!badge.badge_rank ? '' : badge.badge_rank}
                          autoFocus={badgeFieldToEdit === 'badge_rank'}
                          onChange={(e) =>
                            changeBadge(e.target.name, e.target.value)
                          }
                          onBlur={(e) => {
                            changeBadge(
                              e.target.name,
                              regexSelector('number', e)
                            )
                            changeToViewState()
                          }}
                          onFocus={() => changeToEditState('badge_rank')}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              changeToViewState()
                            }
                          }}
                          fullWidth
                          className={classes.neg16MarginTop}
                        />
                      ) : (
                        <div
                          onClick={() => changeToEditState('badge_rank')}
                          role="presentation"
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.badgeValue}
                          >
                            {badge.badge_rank !== ''
                              ? badge.badge_rank
                              : 'Click to add Badge Rank'}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4} className={classes.marginBottom25}>
                      <Typography variant="h6">Brand</Typography>
                    </Grid>

                    <Grid item xs={4}>
                      {badgeFieldToEdit === 'criteria' || brandSearch === '' ? (
                        <Autocomplete
                          placeholder={'Enter or select brand'}
                          value={brandSearch}
                          onChange={(e) => {
                            setBrandSearch(e.target.value)
                            changeBadgeCriteria(
                              'item_data.product_brand.brand',
                              e.target.value,
                              badge
                            )
                          }}
                          onSelect={(item) => {
                            changeBadgeCriteria(
                              'item_data.product_brand.brand',
                              item,
                              badge
                            )
                            changeToViewState()
                          }}
                          onBlur={(e) => {
                            changeBadgeCriteria(
                              'item_data.product_brand.brand',
                              brandSearch,
                              badge
                            )
                            changeToViewState()
                          }}
                          onFocus={() => changeToEditState('criteria')}
                          items={localBrandsList.map((item) => {
                            return item.value
                          })}
                          fullWidth
                          helperText={'Optional'}
                        />
                      ) : (
                        <div
                          onClick={() => changeToEditState('criteria')}
                          role="presentation"
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.badgeValue}
                          >
                            {brandSearch}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={12}>
                      {badgeFieldToEdit === 'badge_description' ||
                      !badge.badge_description ? (
                        <TextField
                          name="badge_description"
                          label="Badge Description"
                          value={
                            !badge.badge_description
                              ? ''
                              : badge.badge_description
                          }
                          autoFocus={badgeFieldToEdit === 'badge_description'}
                          onChange={(e) =>
                            changeBadge(e.target.name, e.target.value)
                          }
                          onBlur={(e) => {
                            changeBadge(e.target.name, e.target.value)
                            changeToViewState()
                          }}
                          onFocus={() => changeToEditState('badge_description')}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              changeToViewState()
                            }
                          }}
                          fullWidth
                          multiline
                          rows={3}
                          className={classes.neg16MarginTop}
                        />
                      ) : (
                        <div
                          onClick={() => changeToEditState('badge_description')}
                          role="presentation"
                        >
                          <Typography variant="subtitle1">
                            {badge.badge_description !== null
                              ? badge.badge_description
                              : 'Click to add description'}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Typography variant="caption" color="textSecondary">
                click any property to edit
              </Typography>
            </Card>
            <Card className={classes.infoCard}>
              <Typography variant="overline" color="textSecondary">
                information
              </Typography>
              <Grid container direction="column" className={classes.centerText}>
                <Grid item>
                  <Typography variant="caption" color="textSecondary">
                    approx. item count
                  </Typography>
                  <Typography variant="button">
                    {badge.item_count !== '' ? badge.item_count : '-'}
                  </Typography>
                </Grid>
                {(badge.last_updated_by !== '' ||
                  isNil(badge.last_updated_by)) &&
                badge.last_updated_time ? (
                  <React.Fragment>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        last modified on
                      </Typography>
                      <Typography variant="button">
                        <Moment format={'YYYY-MMM-DD'}>
                          {badge.last_updated_time}
                        </Moment>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        last modified by
                      </Typography>
                      <Typography variant="button">
                        {badge.last_updated_by}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        created on
                      </Typography>
                      <Typography variant="button">
                        {badge.create_time !== '' ? (
                          <Moment format={'YYYY-MMM-DD'}>
                            {badge.create_time}
                          </Moment>
                        ) : (
                          '-'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        created by
                      </Typography>
                      <Typography variant="button">
                        {badge.create_by !== '' ? badge.create_by : '-'}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Card>
          </Grid>
          <DialogActions>
            <Toolbar className={classes.newBadgeToolBar}>
              <div className={classes.actions}>
                <Button
                  className={classes.drawerButton}
                  onClick={() => cancelCreateBadge()}
                >
                  <CancelIcon />
                  <span className={classes.marginLeft5}>Cancel</span>
                </Button>
              </div>
              <div className={classes.actions}>
                <div>
                  <Tooltip
                    title={
                      disableSave
                        ? 'All fields must have values before saving'
                        : 'Save Changes'
                    }
                  >
                    <Button
                      className={classes.drawerButton}
                      onClick={() => saveBadge(badge, true)}
                      disabled={disableSave}
                    >
                      <GetAppIcon className={classes.rotate180} />
                      <span className={classes.marginLeft5}>
                        {saveButtonText(badge)}
                      </span>
                    </Button>
                  </Tooltip>
                  {badge.id !== 'new' && badge.active && (
                    <Button
                      className={classes.drawerButton}
                      onClick={() => saveBadge(badge, false)}
                    >
                      <SyncDisabledRoundedIcon />
                      <span className={classes.marginLeft5}>DEACTIVATE</span>
                    </Button>
                  )}
                  {auth.permission &&
                    auth.permission.isCepSuperUser &&
                    badge.id !== 'new' &&
                    !badge.active && (
                      <Button
                        className={classes.drawerButton}
                        onClick={() => deleteBadge(badge)}
                      >
                        <DeleteIcon />
                        <span className={classes.marginLeft5}>DELETE</span>
                      </Button>
                    )}
                </div>
              </div>
            </Toolbar>
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  )
}
export default withStyles(styles)(CreateBadge)
