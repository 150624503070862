const styles = (theme) => ({
  table: {
    minWidth: '1020px',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: 'white',
  },
  progressBar: {
    marginLeft: '50%',
  },
  title: {
    flex: '0 0 auto',
  },
  drawerButton: {
    margin: '0px 0px 0px 5px',
    color: 'white',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  noResult: {
    textAlign: 'center',
    padding: '20px',
  },
  marginRight20: {
    marginRight: '20px',
  },
  totalElementsTitle: {
    color: '#CC0000',
    padding: '30px 5px',
    fontSize: '1.2em',
  },
  buildFilterExpansion: {
    width: '100%',
    margin: '0 0 5px 0',
  },
  marginBottom5: {
    marginBottom: '5px',
  },
  height49: {
    height: 49,
  },
  toolBar: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: '#CC0000',
    color: 'white',
    zIndex: 1000,
  },
  maxWidth350: {
    maxWidth: '350px',
  },
  posterFrame: {
    maxWidth: '200px',
    maxHeight: '200px',
    padding: '10px',
    cursor: 'pointer',
  },
  marginRight10: {
    marginRight: '10px',
  },
  maxWidth200: {
    maxWidth: '200px',
  },
  iconMessage: {
    alignItems: 'center',
  },
  expansionText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#01a3e7',
  },
  sort: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
})

export default styles
