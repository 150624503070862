const styles = (theme) => ({
  dropZoneNotActiveOptional: {
    width: '90%',
    border: 'solid 2px black',
    padding: '20px',
    margin: '15px auto',
    height: '335px',
  },
  dropZoneNotActiveRequired: {
    width: '90%',
    padding: '20px',
    backgroundColor: 'rgba(240, 85, 69, .05)',
    border: '2px solid #F05545',
    margin: '15px auto',
    height: '335px',
  },
})

export default styles
