import React from 'react'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import RemoveCircle from '@material-ui/icons/RemoveCircle'

function DeleteConfirmationDialog(props) {
  const {
    images,
    handleDialogOpenStatus,
    deleteImages,
    dialogOpen,
    deletingInProgress,
    selectToggleDeleteConfirmation,
    classes,
  } = props
  const imageDimensions = '?hei=250&wid=250'
  const selectedImages =
    images.length &&
    images.filter((image) => {
      return image.isSelected
    })
  return (
    <React.Fragment>
      <Dialog
        open={dialogOpen}
        disableBackdropClick={deletingInProgress}
        onClose={() => handleDialogOpenStatus(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={false}
      >
        <DialogTitle id="form-dialog-title">
          Delete {selectedImages.length > 1 ? 'Images' : 'Image'}
        </DialogTitle>
        <DialogContent className={classes.minWidth550}>
          {deletingInProgress ? (
            <div>
              <DialogContentText>Deleting Images...</DialogContentText>
              <CircularProgress disableShrink />
            </div>
          ) : (
            <div>
              <DialogContentText>
                Click on the remove symbol to remove any images from pending
                deletion.
              </DialogContentText>
              <Grid container>
                {selectedImages &&
                  selectedImages.map((image) => (
                    <Grid item key={image.url}>
                      <span>
                        <img
                          key={image.url}
                          className={classes.imageBorder}
                          src={`${image.url}${imageDimensions}`}
                          alt="tag"
                          role="presentation"
                        />
                        {image.isSelected && (
                          <RemoveCircle
                            className={classes.selectedCheck}
                            onClick={() =>
                              selectToggleDeleteConfirmation(image)
                            }
                          />
                        )}
                      </span>
                    </Grid>
                  ))}
              </Grid>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={deletingInProgress}
            onClick={() => handleDialogOpenStatus(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={deletingInProgress}
            onClick={() => deleteImages()}
            variant="contained"
            color="primary"
          >
            Delete {selectedImages.length > 1 ? 'Images' : 'Image'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withStyles(styles)(DeleteConfirmationDialog)
