import React from 'react'
import styles from '../../theme'
import {
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  withStyles,
  Paper,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import TcinRuleValidator from './TcinRuleValidator'
import { isNil } from 'lodash'

export class ResultsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tcinRuleValidatorOpen: false,
    }
  }

  toggleTcinRuleValidatorOpen = (isOpen) => {
    this.setState({ tcinRuleValidatorOpen: isOpen })
  }

  render() {
    const {
      classes,
      totalResults,
      foundItems,
      refreshing,
      ruleId,
      editModeSizeChartTcinList,
    } = this.props

    return (
      <div className={classes.fullWidthAndHeight}>
        <Paper className={classes.resultsTablePaper}>
          <div id={'table_top'} className={classes.rulesTCINTableTop}>
            <div className={classes.rulesTCINTableSummary}>
              {totalResults + ' Results Found - '}
              {totalResults > 100
                ? '100 Displayed'
                : totalResults + ' Displayed'}
            </div>
            {!isNil(ruleId) && (
              <div className={classes.rulesTCINTableSummary}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.toggleTcinRuleValidatorOpen(true)}
                >
                  Check a TCIN against this rule!
                </Button>
              </div>
            )}
          </div>
          <div className={classes.rulesTCINTable}>
            <Table>
              <TableHead>
                <TableRow>
                  {editModeSizeChartTcinList ? null : (
                    <React.Fragment>
                      <TableCell className={classes.rulesTCINTableImageCell}>
                        Image
                      </TableCell>
                      <TableCell className={classes.rulesTCINTableTitleCell}>
                        Title
                      </TableCell>
                    </React.Fragment>
                  )}
                  <TableCell className={classes.rulesTCINTableTCINCell}>
                    TCIN
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div className={classes.rulesTCINTableBody}>
              <Table>
                <TableBody>
                  {refreshing ? (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <div className={classes.resultsProgressWrapper}>
                          <CircularProgress
                            className={classes.resultsProgress}
                            style={{
                              width: '50px',
                              height: '50px',
                            }}
                          />
                          <Typography className={classes.resultsProgressText}>
                            Results Loading...
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : foundItems.length > 0 ? (
                    foundItems.map((value, index) => {
                      return (
                        <TableRow key={index}>
                          {editModeSizeChartTcinList ? null : (
                            <React.Fragment>
                              <TableCell
                                className={classes.rulesTCINTableImageCell}
                              >
                                {value.image ? (
                                  <img
                                    src={value.image + '?hei=60&wei=60'}
                                    alt={'Product'}
                                  />
                                ) : (
                                  <div
                                    className={classes.resultsTableNoImage}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                className={classes.rulesTCINTableTitleCell}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: value.title,
                                  }}
                                />
                              </TableCell>
                            </React.Fragment>
                          )}
                          <TableCell className={classes.rulesTCINTableTCINCell}>
                            {value.tcin}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  ) : (
                    <TableRow key={'rawr'}>
                      <TableCell
                        colSpan={5}
                        style={{ fontSize: '24px', textAlign: 'center' }}
                      >
                        No Items Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </Paper>
        <TcinRuleValidator
          tcinRuleValidatorOpen={this.state.tcinRuleValidatorOpen}
          cancelTcinRuleValidator={this.toggleTcinRuleValidatorOpen}
          ruleId={ruleId}
          editModeSizeChartTcinList={editModeSizeChartTcinList}
        />
      </div>
    )
  }
}

// @ts-ignore
export default withStyles(styles)(ResultsTable)
