import {
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
// @ts-ignore
import { WebVTTParser } from 'webvtt-parser'

const renderTextAreaField = ({
  input,
  label,
  value,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    multiline
    rows={15}
    rowsMax={25}
    fullWidth
    margin="normal"
    placeholder="Enter VTT Text"
    {...input}
    {...custom}
  />
)

const renderTextField = ({
  input,
  label,
  value,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    fullWidth
    multiline
    rows="1"
    margin="normal"
    placeholder="Enter VTT Title"
    {...input}
    {...custom}
  />
)
/* eslint-enable */
function convertTotText(input) {
  var text = ''
  input.forEach((item) => {
    text = text + item + '\n'
  })
  return text
}

function getDefaultVtt() {
  return 'WEBVTT\n\n00:00:00.000 --> 00:00:01.000\n[no audio]'
}

const displayVttErrors = (vttTree) => {
  if (vttTree.errors.length > 0) {
    return vttTree.errors.map((error, index) => {
      return (
        <div key={index}>
          Line {error.line}: {error.message}
        </div>
      )
    })
  }
}

let DialogBoxEditVtt = (props) => {
  const {
    closeEditVtt = () => {},
    vttEditBoxOpen = false,
    onSave = () => {},
    classes = {},
    editVtt = false,
  } = props

  const parser = new WebVTTParser()
  const vttTree = parser.parse(
    editVtt && editVtt.values && editVtt.values.vttData
      ? editVtt.values.vttData
      : '',
    'metadata'
  )

  return (
    <Dialog
      open={vttEditBoxOpen}
      onClose={() => closeEditVtt()}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth={false}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container direction="row" justify="center">
          <Grid item xs={6}>
            <span>VTT Editor</span>
          </Grid>
          <Grid item container justify="flex-end" alignItems="center" xs={6}>
            <Button onClick={closeEditVtt}>
              <Close />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Field
              id="vttTitle"
              name="vttTitle"
              component={renderTextField}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" className={classes.marginTop25}>
              Errors:{' '}
              <span
                className={
                  vttTree.errors.length > 0 ? classes.red : classes.blue
                }
              >
                {vttTree.errors.length}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              id="vttData"
              name="vttData"
              component={renderTextAreaField}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={`${classes.marginTop25} ${classes.errorTextSpace}`}
          >
            {displayVttErrors(vttTree)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={10}>
            <div>
              For deeper explanations on the WEBVTT format please visit these
              links:{' '}
              <a href="https://www.3playmedia.com/2017/06/30/how-to-create-a-webvtt-file/">
                How to Create a WebVTT File
              </a>{' '}
              and{' '}
              <a href="https://w3c.github.io/webvtt/">W3C WebVTT Reference</a>
            </div>
          </Grid>
          <Grid item container justify="flex-end" xs={2}>
            <Button
              onClick={(event) => closeEditVtt()}
              className={classes.marginRight15}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              className={classes.marginRight15}
              onClick={() => onSave()}
              disabled={
                (editVtt &&
                  editVtt.values &&
                  editVtt.values.vttData.trim() === '') ||
                (editVtt &&
                  editVtt.values &&
                  editVtt.values.vttTitle.trim() === '') ||
                vttTree.errors.length > 0
              }
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

DialogBoxEditVtt = reduxForm({ form: 'editVtt' })(DialogBoxEditVtt)
DialogBoxEditVtt = connect((state) => ({
  initialValues: {
    vttTitle: state.videoUpload
      ? state.videoUpload.vttEditBoxTitle
        ? state.videoUpload.vttEditBoxTitle
        : 'untitled.vtt'
      : 'untitled.vtt',
    vttData: state.videoUpload
      ? state.videoUpload.vttEditData
        ? convertTotText(state.videoUpload.vttEditData)
        : getDefaultVtt()
      : getDefaultVtt(),
  }, // pull initial values from account reducer
}))(DialogBoxEditVtt)

// @ts-ignore
export default withStyles(styles)(DialogBoxEditVtt)
