import { IMAGE_VIEW_TYPE, IMAGE_ROW_ID } from '../../Shared/Constants'
import { isEqual } from 'lodash'

export const createPrimaryRow = (row, primaryImages, newImageId) => {
  // Create new row with dragged image removed
  let newPrimaryRow = {
    ...row,
    images: primaryImages,
  }
  // Update images with new params
  newPrimaryRow = {
    ...newPrimaryRow,
    images: newPrimaryRow.images.map((image) => {
      image = {
        ...image,
        active: true,
        sequence: '00',
        viewType: IMAGE_VIEW_TYPE.PRIMARY,
        changed: true,
        imageId: newImageId,
      }
      return image
    }),
  }
  return newPrimaryRow
}

// This will "swap" positions of the alt/disabled and the primary
export const moveFromPrimaryToAlt = (
  row,
  images,
  destinationImage,
  sourceImage
) => {
  // Create new row with dragged image removed
  let newAlternateRow = {
    ...row,
    images,
  }

  // follow logic flow for copy/paste/drag/drop to primary
  const determineImageId = (image) => {
    if (image.sequence === sourceImage.sequence) {
      if (destinationImage.imageId === null) {
        return null
      } else {
        return destinationImage.sourceId
      }
    }
    return image.sourceId
  }

  newAlternateRow = {
    ...newAlternateRow,
    images: newAlternateRow.images
      .map((image) => {
        let newImage = {
          ...image,
          sequence:
            image.sequence === sourceImage.sequence
              ? destinationImage.sequence
              : image.sequence,
          imageId: determineImageId(image),
          viewType: IMAGE_VIEW_TYPE.ALTERNATE,
          active: !(row.id === IMAGE_ROW_ID.DISABLED),
        }
        newImage = {
          ...newImage,
          changed: !isEqual(newImage, image) || image.changed === true,
        }
        return newImage
      })
      .sort((a, b) => a.sequence - b.sequence),
  }
  return newAlternateRow
}

export const createGenericRow = (row, images) => {
  // Create new row with dragged image removed
  let newRow = {
    ...row,
    images: images,
  }
  // Update images with new params
  newRow = {
    ...newRow,
    images: newRow.images
      .map((image) => {
        let newImage = {
          ...image,
          viewType:
            newRow.id === IMAGE_ROW_ID.DISABLED ||
            newRow.id === IMAGE_ROW_ID.ALTERNATE
              ? IMAGE_VIEW_TYPE.ALTERNATE
              : IMAGE_VIEW_TYPE.PRIMARY,
          active: !(newRow.id === IMAGE_ROW_ID.DISABLED),
        }
        newImage = {
          ...newImage,
          changed: !isEqual(newImage, image) || image.changed === true,
        }
        return newImage
      })
      .sort((a, b) => a.sequence - b.sequence),
  }
  return newRow
}

// This will re-order within a row
export const createReorderedRow = (
  row,
  images,
  previousSequenceOrder,
  previousImageIdOrder
) => {
  // Create a new row with the moved image
  let newRow = {
    ...row,
    images: images,
  }
  // Update images with new params
  newRow = {
    ...newRow,
    images: newRow.images.map((image, index) => {
      let newImage = {
        ...image,
        imageId: previousImageIdOrder[index],
        sequence: previousSequenceOrder[index],
        active: !(newRow.id === IMAGE_ROW_ID.DISABLED),
      }

      newImage = {
        ...newImage,
        changed: !isEqual(newImage, image) || image.changed === true,
      }
      return newImage
    }),
  }

  return newRow
}
