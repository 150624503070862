import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import BadgeImageUpload from './BadgeImageUpload'
import {
  uploadBadgeImageStart,
  badgesAddFilesToQueue,
  badgeDropZoneActive,
} from '../../../../store/badges/actionCreator'
import envConfigs from '../../../../config/apiConfig'
import { each } from 'lodash'
import DropZone from '../../../Shared/DropZone/DropZone'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import styles from '../../theme'
import { withStyles } from '@material-ui/core/styles'
import {
  ERR_MORE_THAN_ONE,
  ERR_DOUBLEPERIOD,
  ERR_FILESIZESMALL,
  ERR_FILESIZEBIG,
} from '../../../Shared/Constants'

class BadgeImageUploadContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      validFiles: [],
      invalidFiles: [],
      invalidFileError: false,
    }
  }

  componentDidMount() {
    var uploadFilesList = []
    this.props.badgesAddFilesToQueue(uploadFilesList)
  }

  onDrop = (files) => {
    if (files.length > 1) {
      this.setState({ invalidFileError: true })
      each(files, (value) => {
        value.errorMessage = ERR_MORE_THAN_ONE
        this.state.invalidFiles.push(value)
      })
    } else if (!this.props.uploadInProgress) {
      var vm = this
      var uploadFilesList = []
      each(files, function (value) {
        let objectURL = URL.createObjectURL(value)
        if (vm.checkImage(value)) {
          vm.state.validFiles = [value]
          uploadFilesList = [
            {
              fileName: value.name,
              percent: 0,
              blobUrl: objectURL,
            },
          ]
        } else {
          vm.state.invalidFiles.push(value)
        }
      })
      if (this.state.invalidFiles.length) {
        vm.setState({ invalidFileError: true })
      }
      this.props.badgesAddFilesToQueue(uploadFilesList)
      this.props.badgeDropZoneActive(false)
    }
  }

  checkImage = (file) => {
    var fileParts = file.name.split('.')
    if (file.name.match(/(\..*){2,}/) || fileParts > 2) {
      file.errorMessage = ERR_DOUBLEPERIOD
      return false
    }
    // ensure file is at least 10b
    if (file.size <= envConfigs.imageStandards.minFileSize) {
      file.errorMessage = ERR_FILESIZESMALL
      return false
    }
    // ensure file is not larger than 100mb
    if (file.size >= envConfigs.imageStandards.maxFileSize) {
      file.errorMessage = ERR_FILESIZEBIG
      return false
    }
    return true
  }

  onDragEnter = (files) => {
    if (!this.props.uploadInProgress) {
      this.props.badgeDropZoneActive(true)
    }
  }

  onDragLeave = (files) => {
    if (!this.props.uploadInProgress) {
      this.props.badgeDropZoneActive(false)
    }
  }

  removeInvalidFile = () => {
    this.setState({ invalidFiles: [], invalidFileError: false })
  }

  clearData = () => {
    if (!this.props.uploadInProgress) {
      this.setState({ validFiles: [] })
      this.props.badgesAddFilesToQueue([])
      this.props.changeToViewState()
    }
  }

  sendToTarget = () => {
    if (!this.props.uploadInProgress) {
      let userId = this.props.auth.email
        ? this.props.auth.email
        : this.props.auth.lanId
      this.props.uploadBadgeImageStart(userId, this.state.validFiles)
    }
  }

  roundPercent(rawPercent) {
    var percent = Math.round(rawPercent)
    if (percent < 0) {
      return 0
    } else if (percent >= 100) {
      return 100
    }
    return percent
  }

  render() {
    const { uploadData } = this.props
    return (
      <React.Fragment>
        {uploadData && uploadData.length > 0 ? (
          <img
            src={uploadData[0].blobUrl}
            alt={uploadData[0].fileName}
            // @ts-ignore
            onError={(e) => {
              e.target.src = envConfigs.api.sceneSevenBaseUrl + 'badge-transfer'
            }}
            className={this.props.classes.uploadImage}
          />
        ) : (
          <DropZone
            onDrop={this.onDrop}
            dropZoneEnter={this.props.dropZoneEnter}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            disableClick={false}
            dropZoneRef={null}
            requiredType={null}
            dropZoneActiveStyle={this.props.classes.dropZoneActive}
            dropZoneNotActiveStyle={this.props.classes.dropZoneNotActive}
            DropZoneContent={
              <React.Fragment>
                <span className={this.props.classes.DropZoneLargeText}>
                  Drag & Drop Image Or Click
                </span>
                <span>
                  <CloudUploadIcon fontSize="large" />
                </span>
              </React.Fragment>
            }
          />
        )}
        <BadgeImageUpload
          invalidFileError={this.state.invalidFileError}
          invalidFiles={this.state.invalidFiles}
          removeInvalidFile={this.removeInvalidFile}
          numberValidFiles={this.props.numberValidFiles}
          clearData={this.clearData}
          sendToTarget={this.sendToTarget}
          uploadInProgress={this.props.uploadInProgress}
          uploadData={uploadData}
          roundPercent={this.roundPercent}
        />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      badgeDropZoneActive,
      uploadBadgeImageStart,
      badgesAddFilesToQueue,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, badges } = state
  const {
    dropZoneEnter,
    files,
    numberValidFiles,
    uploadInProgress,
    uploadData,
  } = badges
  return {
    auth,
    dropZoneEnter,
    files,
    numberValidFiles,
    uploadInProgress,
    uploadData,
  }
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BadgeImageUploadContainer))
