export const REQUEST_IMAGE_REVIEW_DATA = 'REQUEST_IMAGE_REVIEW_DATA'
export const RECEIVE_IMAGE_REVIEW_DATA = 'RECEIVE_IMAGE_REVIEW_DATA'
export const CLEAR_REVIEW_DATA = 'CLEAR_REVIEW_DATA'
export const SELECT_IMAGE_REVIEW_EVENT = 'SELECT_IMAGE_REVIEW_EVENT'
export const TOGGLE_ACTION_DRAWER_IMAGE_REVIEW =
  'TOGGLE_ACTION_DRAWER_IMAGE_REVIEW'
export const SET_REJECTION_REASON = 'SET_REJECTION_REASON'
export const HANDLE_REJECTION_MESSAGE = 'HANDLE_REJECTION_MESSAGE'
export const SET_APPROVAL_REASON = 'SET_APPROVAL_REASON'
export const IMAGE_REVIEW_RESET_FETCH = 'IMAGE_REVIEW_RESET_FETCH'
export const SHOW_DEFAULT_ALERT_IMAGE_REVIEW = 'SHOW_DEFAULT_ALERT_IMAGE_REVIEW'
export const ERROR_SEARCHING_IMAGE_REVIEW = 'ERROR_SEARCHING_IMAGE_REVIEW'
export const STORE_IMAGE_REVIEW_STRAPI_DATA = 'STORE_IMAGE_REVIEW_STRAPI_DATA'
export const RESET_ACTION_TOOLBAR = 'RESET_ACTION_TOOLBAR'
export const SET_LOOK_CLOSER_ITEM = 'SET_LOOK_CLOSER_ITEM'
export const RETOUCH_PROCESS_LOADER = 'RETOUCH_PROCESS_LOADER'
