import React from 'react'
import AddTcin from './AddTcin'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getImageDataForClassifier,
  resetFetch,
} from '../../../../../../store/images/tagTraining/actionCreator'
import { validTcin } from '../../../../../Shared/SharedUtils'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../../../Shared/Constants'

class AddTcinContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  getImageData = () => {
    var tcin = this.props.classifierSelectionForm.values.classifierImageTcinList
    if (validTcin(tcin)) {
      this.props.getImageDataForClassifier(tcin)
    } else {
      this.props.resetFetch(false)
      toast.error('Enter a valid Tcin', TOAST_BODY)
    }
  }

  render() {
    const { exampleImageTcinForm, disabled } = this.props
    return (
      <AddTcin
        getImageData={this.getImageData}
        exampleImageTcinForm={exampleImageTcinForm}
        disabled={disabled}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getImageDataForClassifier,
      resetFetch,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, form, imageTagTraining } = state
  const { classifierSelectionForm } = form
  const { classifierSelection } = imageTagTraining
  const { tcinList, activeStep } = classifierSelection
  return {
    tcinList,
    auth,
    activeStep,
    classifierSelectionForm,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTcinContainer)
