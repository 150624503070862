import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  IconButton,
  DialogActions,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Toolbar,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// @ts-ignore
import NoThumbnail from '../../../../images/NoThumbnail.svg?url'
import { noSwatchUrl, urlProtocol } from '../../../Shared/Constants'
import Moment from 'react-moment'
import { isEmpty, isNil } from 'lodash'
import { Block } from '@material-ui/icons'
import {
  isSwatchEligible,
  urlBuilder,
  escapeHtml,
  urlExtenSionBuilder,
} from '../../../Shared/SharedUtils'
import ArrowDownward from '@material-ui/icons/ArrowDownward'

function SyncImagesModal(props) {
  const {
    classes = {},
    open = false,
    toggleSyncItemsModal = () => {},
    itemToSyncFrom = {},
    itemToSyncTo = [],
    syncImages = () => {},
    auth = {},
  } = props

  let noPrimaryImage = itemToSyncFrom.rows.primary.images.length === 0

  return (
    <React.Fragment>
      {!isEmpty(itemToSyncFrom) && !isEmpty(itemToSyncTo) && (
        <Dialog
          open={open}
          onClose={() => toggleSyncItemsModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={false}
          classes={{ paper: classes.minHeight45Per }}
        >
          <Toolbar className={classes.modalToolbar}>
            <div className={classes.title}>
              <Typography color="inherit" variant="h6">
                Sync Images
              </Typography>
            </div>
            <div className={classes.actions}>
              <IconButton onClick={() => toggleSyncItemsModal(false)}>
                <CloseIcon className={classes.white} />
              </IconButton>
            </div>
          </Toolbar>
          <DialogContent>
            <Card>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    <TableCell key={'tcin'} align="left" padding="default">
                      Item Details
                    </TableCell>
                    <TableCell key={'itemType'} align="left" padding="default">
                      Swatch
                    </TableCell>
                    <TableCell key={'primary'} align="left" padding="default">
                      Primary
                    </TableCell>
                    <TableCell key={'dpci'} align="left" padding="default">
                      Alternates
                    </TableCell>
                    <TableCell key={'color'} align="left" padding="default">
                      Disabled
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={itemToSyncFrom.tcin}
                  >
                    <TableCell
                      padding="default"
                      data-test-id="from-item-details-cell"
                    >
                      <Grid
                        container
                        direction="column"
                        className={classes.itemDetails}
                      >
                        <Grid item>
                          <b>{itemToSyncFrom.tcin}</b>{' '}
                          {itemToSyncFrom.relationshipTypeCode || 'N/A'}
                        </Grid>
                        <Grid
                          item
                          dangerouslySetInnerHTML={
                            !isNil(itemToSyncFrom.title)
                              ? escapeHtml(itemToSyncFrom.title)
                              : 'No Title Found'
                          }
                        />
                        <Grid item>
                          Launch Date:{' '}
                          {!isNil(itemToSyncFrom.launchDate) && (
                            <Moment format={'YYYY-MM-DD'}>
                              {itemToSyncFrom.launchDate}
                            </Moment>
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell
                      padding="default"
                      data-test-id="from-swatch-cell"
                    >
                      {isSwatchEligible(itemToSyncFrom.relationshipTypeCode) ? (
                        <img
                          src={
                            urlProtocol +
                            urlBuilder(itemToSyncFrom.swatchImage) +
                            urlExtenSionBuilder(itemToSyncFrom.swatchImage)
                          }
                          alt={itemToSyncFrom.tcin}
                          className={classes.swatchImage}
                          // @ts-ignore
                          onError={(e) => {
                            e.target.src = noSwatchUrl
                          }}
                        />
                      ) : (
                        <Tooltip title="No Swatch Needed">
                          <Block />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell data-test-id="from-primary-cell">
                      {noPrimaryImage ? (
                        <div
                          className={classes.primaryMissing}
                          data-test-id={'no-primary-image'}
                        >
                          Primary Image Missing
                        </div>
                      ) : (
                        <img
                          alt={itemToSyncFrom.tcin}
                          src={
                            urlProtocol +
                            urlBuilder(itemToSyncFrom.rows.primary.images[0]) +
                            '?hei=100&wei=100'
                          }
                          // @ts-ignore
                          onError={(e) => {
                            e.target.src = NoThumbnail
                          }}
                          className={classes.imageSpacing}
                        />
                      )}
                    </TableCell>
                    <TableCell data-test-id="from-alternate-cell">
                      {itemToSyncFrom.rows.alternate.images.map((image) => {
                        return (
                          <img
                            key={image.imageId}
                            alt={itemToSyncFrom.tcin}
                            src={
                              urlProtocol +
                              urlBuilder(image) +
                              '?hei=100&wei=100'
                            }
                            // @ts-ignore
                            onError={(e) => {
                              e.target.src = NoThumbnail
                            }}
                            className={classes.imageSpacing}
                          />
                        )
                      })}
                    </TableCell>
                    <TableCell data-test-id="from-disabled-cell">
                      {itemToSyncFrom.rows.disabled.images.map((image) => {
                        return (
                          <img
                            key={image.imageId}
                            alt={itemToSyncFrom.tcin}
                            src={
                              urlProtocol +
                              urlBuilder(image) +
                              '?hei=100&wei=100'
                            }
                            // @ts-ignore
                            onError={(e) => {
                              e.target.src = NoThumbnail
                            }}
                            className={`${classes.imageSpacing} ${classes.disabled}`}
                          />
                        )
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <span className={classes.centerFlex}>
                        <ArrowDownward /> Sync the following item images with
                        what is displayed above.
                      </span>
                    </TableCell>
                  </TableRow>
                  {itemToSyncTo.map((item) => {
                    return (
                      <TableRow hover key={item.tcin}>
                        <TableCell
                          padding="default"
                          data-test-id="to-item-details-cell"
                        >
                          <Grid
                            container
                            direction="column"
                            className={classes.itemDetails}
                          >
                            <Grid item>
                              <b>{item.tcin}</b>{' '}
                              {item.relationshipTypeCode || 'N/A'}
                            </Grid>
                            <Grid
                              item
                              dangerouslySetInnerHTML={
                                !isNil(item.title)
                                  ? escapeHtml(item.title)
                                  : 'No Title Found'
                              }
                            />
                            <Grid item>
                              Launch Date:{' '}
                              {!isNil(item.launchDate) && (
                                <Moment format={'YYYY-MM-DD'}>
                                  {item.launchDate}
                                </Moment>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell
                          padding="default"
                          data-test-id="to-swatch-cell"
                        >
                          {isSwatchEligible(item.relationshipTypeCode) ? (
                            <div>
                              <img
                                src={
                                  urlProtocol +
                                  urlBuilder(item.swatchImage) +
                                  urlExtenSionBuilder(item.swatchImage)
                                }
                                alt={item.tcin}
                                className={classes.swatchImage}
                                // @ts-ignore
                                onError={(e) => {
                                  e.target.src = noSwatchUrl
                                }}
                              />
                            </div>
                          ) : (
                            <Tooltip title="No Swatch Needed">
                              <Block />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell data-test-id="to-primary-cell">
                          {noPrimaryImage ? (
                            <div
                              className={classes.primaryMissing}
                              data-test-id={'no-primary-image'}
                            >
                              Primary Image Missing
                            </div>
                          ) : (
                            <img
                              alt={item.tcin}
                              src={
                                urlProtocol +
                                urlBuilder(item.rows.primary.images[0]) +
                                '?hei=100&wei=100'
                              }
                              // @ts-ignore
                              onError={(e) => {
                                e.target.src = NoThumbnail
                              }}
                              className={classes.imageSpacing}
                            />
                          )}
                        </TableCell>
                        <TableCell data-test-id="to-alternate-cell">
                          {item.rows.alternate.images.map((image) => {
                            return (
                              <img
                                key={image.imageId}
                                alt={item.tcin}
                                src={
                                  urlProtocol +
                                  urlBuilder(image) +
                                  '?hei=100&wei=100'
                                }
                                // @ts-ignore
                                onError={(e) => {
                                  e.target.src = NoThumbnail
                                }}
                                className={classes.imageSpacing}
                              />
                            )
                          })}
                        </TableCell>
                        <TableCell data-test-id="to-disabled-cell">
                          {item.rows.disabled.images.map((image) => {
                            return (
                              <img
                                key={image.imageId}
                                alt={item.tcin}
                                src={
                                  urlProtocol +
                                  urlBuilder(image) +
                                  '?hei=100&wei=100'
                                }
                                // @ts-ignore
                                onError={(e) => {
                                  e.target.src = NoThumbnail
                                }}
                                className={`${classes.imageSpacing} ${classes.disabled}`}
                              />
                            )
                          })}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => toggleSyncItemsModal(false)}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => syncImages(itemToSyncFrom.tcin, auth)}
              variant="contained"
              color="primary"
            >
              Sync & Publish
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

// @ts-ignore
export default withStyles(styles)(SyncImagesModal)
