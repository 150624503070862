const styles = (theme) => ({
  startOverButton: {
    margin: '20px',
  },
  stepperIcon: {
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    '&$active': {
      color: 'rgba(56, 94, 166, 1)',
    },
    '&$completed': {
      color: 'rgba(56, 94, 166, 1)',
    },
  },
  disabled: {},
  active: {},
  completed: {},
  marginTop10: {
    marginTop: '10px',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  progressBar: {
    marginTop: '30px',
    marginLeft: '50%',
  },
  marginTop25: {
    marginTop: '25px',
  },
  marginLeft20: {
    marginLeft: '20px',
  },
  approvedImageMargin: {
    marginTop: '5px',
    marginBottom: '53px',
  },
  buttonStyles: {
    width: '400px',
    marginTop: '10px',
  },
  selectedCheck: {
    color: '#3f51b5',
    position: 'relative',
    left: '-31px',
    top: '-137px',
  },
  imageBorder: {
    border: '2px solid #3f51b5',
    margin: '5px',
  },
  imageWithoutBorder: {
    margin: '7px 19px',
  },
  searchTcinButton: {
    margin: '10px 0 0 10px',
  },
  textFieldWidth: {
    width: '200px',
  },
  marginLeft10: {
    marginLeft: '10px',
  },
  tagCards: {
    minWidth: '180px',
    padding: '10px',
    margin: '0px 15px',
    marginTop: '10px',
    maxHeight: '280px',
    overflowY: 'auto',
  },
  selectedTypeTag: {
    fontWeight: 400,
    margin: '20px',
  },
  marginTop190: {
    marginTop: '190px',
  },
})

export default styles
