import React from 'react'
import SimilarItem from './SimilarItem'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  reviewImageTagClassifier,
  incrementCurrentSimilarImageCountClassifier,
  removeImageTagClassifier,
  resetClassifierSelection,
  toggleClassifierApprovedSelectedImage,
  setDeleteConfirmationOpenClassifier,
  removeImageFromClassifierSelection,
  removeSelectedImageFromClassifierDeleteConfirmation,
  clearSelectedClassifierApprovedImages,
} from '../../../../../../store/images/tagTraining/actionCreator'

class SimilarItemContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  skipCurrentImage = () => {
    this.props.incrementCurrentSimilarImageCountClassifier()
  }

  submitApprovedImageReview = () => {
    let tagReviewRequest = {
      publish_url:
        this.props.similarImageData[this.props.currentSimilarImageCount]
          .image_url,
      user_email: this.props.auth.email,
      tag_review_votes: [this.props.tagSelectedData],
    }
    this.props.reviewImageTagClassifier(tagReviewRequest)
  }

  toggleApprovedImageSelect = (image) => {
    this.props.toggleClassifierApprovedSelectedImage(image)
  }

  handleDialogOpenStatus = (open) => {
    this.props.setDeleteConfirmationOpenClassifier(open)
  }

  deleteImagesFromApprovedStatus = () => {
    this.props.removeImageFromClassifierSelection(
      this.props.currentApprovedImages,
      this.props.auth.email
    )
  }

  startOver = () => {
    this.props.resetClassifierSelection()
  }

  render() {
    const {
      similarImageData,
      selectedImage,
      currentSimilarImageCount,
      similarImageFetching,
      maxSimilarImageCount,
      currentApprovedImages,
      removeImageTagClassifier,
    } = this.props
    return (
      <SimilarItem
        startOver={this.startOver}
        similarImageData={similarImageData}
        selectedImage={selectedImage}
        currentSimilarImageCount={currentSimilarImageCount}
        maxSimilarImageCount={maxSimilarImageCount}
        currentApprovedImages={currentApprovedImages}
        toggleCurrentApprovedImageSelect={(image) =>
          this.toggleApprovedImageSelect(image)
        }
        similarImageFetching={similarImageFetching}
        skipCurrentImage={this.skipCurrentImage}
        approveCurrentImage={this.submitApprovedImageReview}
        removeImageFromApprovedReviewList={(imageTagInfo) =>
          removeImageTagClassifier(imageTagInfo)
        }
        handleDialogOpenStatus={(open) => this.handleDialogOpenStatus(open)}
        deleteImages={() => this.deleteImagesFromApprovedStatus()}
        isDialogOpen={this.props.deleteConfirmationIsOpen}
        deletingInProgress={this.props.deletingInProgress}
        selectToggleDeleteConfirmation={(image) =>
          this.props.removeSelectedImageFromClassifierDeleteConfirmation(image)
        }
        clearSelectedTrainingImages={() =>
          this.props.clearSelectedClassifierApprovedImages()
        }
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      reviewImageTagClassifier,
      incrementCurrentSimilarImageCountClassifier,
      removeImageTagClassifier,
      resetClassifierSelection,
      toggleClassifierApprovedSelectedImage,
      setDeleteConfirmationOpenClassifier,
      removeImageFromClassifierSelection,
      removeSelectedImageFromClassifierDeleteConfirmation,
      clearSelectedClassifierApprovedImages,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, form, auth } = state
  const { classifierSelection } = imageTagTraining
  const {
    selectedImage,
    similarImageFetching,
    similarImageData,
    maxSimilarImageCount,
    currentSimilarImageCount,
    currentApprovedImages,
    tagSelectedData,
    deleteConfirmationIsOpen,
    deletingInProgress,
  } = classifierSelection
  const { tagDetailsForm } = form
  return {
    auth,
    similarImageData,
    currentApprovedImages,
    selectedImage,
    currentSimilarImageCount,
    similarImageFetching,
    maxSimilarImageCount,
    tagDetailsForm,
    tagSelectedData,
    deleteConfirmationIsOpen,
    deletingInProgress,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimilarItemContainer)
