import { useState, useRef, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import {
  colorThiefFromCanvas,
  flattenImagesForTcin,
  urlBuilder,
} from '../../../Shared/SharedUtils'
import {
  IMAGE_VIEW_TYPE,
  noSwatchUrl,
  urlProtocol,
} from '../../../Shared/Constants'
// @ts-ignore
import CloseIcon from '@material-ui/icons/Close'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

function EditSwatchModal(props) {
  const {
    open = false,
    toggleEditSwatchModal = () => {},
    item = {},
    saveSwatch = () => {},
    classes = {},
  } = props

  const [imageList] = useState(flattenImagesForTcin(item, '').imageList)
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const initialCrop = { unit: '%', width: 40, aspect: 1 / 1, x: 25, y: 25 }
  const [crop, setCrop] = useState(initialCrop)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [rgb, setRgb] = useState(null)
  const [swatchPreview, setSwatchPreview] = useState(
    imageList.find((r) => r.viewType === 'swatch') || imageList[0]
  )
  const [palette, setPalette] = useState([])

  const formatViewType = (viewType, sequence) => {
    if (viewType === IMAGE_VIEW_TYPE.PRIMARY) {
      return 'Primary'
    } else {
      return 'Alt ' + sequence
    }
  }

  const buildRgb = (currentRgb) => {
    return {
      red: currentRgb[0],
      green: currentRgb[1],
      blue: currentRgb[2],
    }
  }

  const buildSwatchRelativeDimensions = (currentCrop, currentImg) => {
    return {
      top: currentCrop.x / currentImg.width,
      left: currentCrop.y / currentImg.height,
      width: currentCrop.width / currentImg.height,
      height: currentCrop.height / currentImg.width,
    }
  }

  const buildAndSave = (
    tcin,
    publishUrl,
    currentRgb,
    currentCrop,
    currentImg
  ) => {
    saveSwatch({
      tcin: tcin,
      publish_url: publishUrl,
      source: 'manual',
      source_type: currentRgb === null ? null : 'rgb',
      swatch_rgb: currentRgb !== null ? buildRgb(currentRgb) : null,
      swatch_location_relative:
        currentRgb === null
          ? buildSwatchRelativeDimensions(currentCrop, currentImg)
          : null,
    })
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width
    canvas.height = crop.height

    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    colorThiefFromCanvas(canvas.toDataURL('image/jpeg')).then(
      (colorPalette) => {
        setPalette(colorPalette)
      }
    )
  }, [completedCrop])

  return (
    <Dialog
      open={open}
      onClose={() => toggleEditSwatchModal(null)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'md'}
      classes={{ paper: classes.minHeight45Per }}
    >
      <Toolbar className={classes.modalToolbar}>
        <div className={classes.title}>
          <Typography color="inherit" variant="h5">
            Edit Swatch for {item.tcin}
          </Typography>
          <Typography color="inherit" variant="subtitle1">
            {item.title}
          </Typography>
          {item.tcinsForItemsToBeDuplicated &&
            item.tcinsForItemsToBeDuplicated.length > 0 && (
              <Typography color="inherit" variant="body2">
                Be aware that updating this swatch will also update the
                following items: {item.tcinsForItemsToBeDuplicated.join(', ')}
              </Typography>
            )}
        </div>
        <div className={classes.actions}>
          <IconButton onClick={() => toggleEditSwatchModal(null)}>
            <CloseIcon className={classes.white} />
          </IconButton>
        </div>
      </Toolbar>
      <DialogContent
        className={`${classes.noOverflow} ${classes.horizontalFlex}`}
      >
        <div className={`${classes.width50Per} ${classes.padding5}`}>
          <ReactCrop
            crossorigin="anonymous"
            src={urlProtocol + urlBuilder(swatchPreview)}
            crop={crop}
            onImageLoaded={(img) => {
              imgRef.current = img
            }}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={(newCrop) => {
              setRgb(null)
              setCompletedCrop(newCrop)
            }}
            imageAlt={item.tcin}
            keepSelection
            onImageError={(e) => {
              e.target.src = noSwatchUrl
            }}
            className={classes.maxWidth100Per}
          />
          <div className={`${classes.horizontalFlex} ${classes.flexWrap}`}>
            {imageList
              .filter((image) => image.viewType !== IMAGE_VIEW_TYPE.SWATCH)
              .map((image, index) => {
                return (
                  <div className={classes.imageSpacing} key={index}>
                    <img
                      className={`${classes.pointer} ${classes.verticalFlex}`}
                      role="presentation"
                      onClick={() => {
                        setSwatchPreview(image)
                        setCrop(initialCrop)
                      }}
                      src={urlProtocol + urlBuilder(image) + '?hei=50&wei=50'}
                      alt={item.tcin}
                      // @ts-ignore
                      onError={(e) => {
                        e.target.src = noSwatchUrl
                      }}
                    />
                    <Typography
                      className={`${classes.fontSize12} ${classes.verticalFlex} ${classes.textAlignCenter}`}
                    >
                      {formatViewType(image.viewType, image.sequence)}
                    </Typography>
                  </div>
                )
              })}
          </div>
        </div>
        <div className={`${classes.width50Per} ${classes.padding5}`}>
          <Typography>
            Select a new swatch directly within any image or choose a color from
            the palette below.
          </Typography>
          <Typography variant="h6">Selection Color Palette</Typography>
          <div className={classes.paletteParent}>
            {palette &&
              palette.map((colorBlock, index) => {
                return (
                  <div
                    data-test-id={'palette-option'}
                    role="button"
                    tabIndex={index}
                    key={index}
                    className={`${classes.palette} ${classes.flexWrap} ${classes.pointer}`}
                    style={{ backgroundColor: `rgb(${colorBlock.join(', ')})` }}
                    onClick={() => setRgb(colorBlock)}
                    // adding for ADA
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setRgb(colorBlock)
                      }
                    }}
                  />
                )
              })}
          </div>
          <div className={`${classes.noOverflow} ${classes.horizontalFlex}`}>
            <div className={`${classes.width50Per} ${classes.padding5}`}>
              {rgb === null ? (
                <canvas
                  data-test-id={'swatch-canvas'}
                  crossOrigin="anonymous"
                  ref={previewCanvasRef}
                  className={`${classes.verticalFlex} ${classes.maxWidth100Per} ${classes.width200}`}
                  // @ts-ignore
                  onError={(e) => {
                    e.target.src = noSwatchUrl
                  }}
                />
              ) : (
                <div
                  data-test-id={'swatch-palette'}
                  className={`${classes.verticalFlex} ${classes.maxWidth100Per} ${classes.width200} ${classes.swatchPreview}`}
                  style={{ backgroundColor: `rgb(${rgb.join(', ')})` }}
                />
              )}
              <Typography
                className={`${classes.verticalFlex} ${classes.textAlignCenter} ${classes.fontSize20}`}
              >
                Preview
              </Typography>
            </div>
            <div
              className={`${classes.verticalFlex} ${classes.width50Per} ${classes.padding5}`}
            >
              <img
                className={`${classes.verticalFlex} ${classes.maxWidth100Per} ${classes.width200}`}
                src={urlProtocol + urlBuilder(item.swatchImage)}
                alt={item.tcin}
                // @ts-ignore
                onError={(e) => {
                  e.target.src = noSwatchUrl
                }}
              />
              <Typography
                className={`${classes.verticalFlex} ${classes.textAlignCenter} ${classes.fontSize20}`}
              >
                Current
              </Typography>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.title}>
          <Button
            color="primary"
            className={classes.marginLeft5}
            variant="outlined"
            onClick={() => toggleEditSwatchModal(null)}
          >
            Cancel
          </Button>
          <Button
            className={classes.marginLeft5}
            variant="contained"
            color="primary"
            data-test-id="save-swatch"
            onClick={() => {
              buildAndSave(
                item.tcin,
                swatchPreview.publishUrl,
                rgb,
                completedCrop,
                imgRef.current
              )
            }}
          >
            Save Swatch
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

// @ts-ignore
export default withStyles(styles)(EditSwatchModal)
