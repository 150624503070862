import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import { Card, CardContent } from '@material-ui/core'

function DefaultAlert(props) {
  const { classes } = props

  return (
    <Card
      className={`${classes.marginTop10} ${classes.marginBottom10} ${classes.alert}`}
    >
      <CardContent>
        <div className={`${classes.marginBottom10} ${classes.header}`}>
          Help Content Currently Unavailable
        </div>
        <div className={classes.body}>
          The team is working on it. You can continue on and use other functions
          in the application.
        </div>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(DefaultAlert)
