/* eslint-disable array-callback-return */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import HeaderSection from './HeaderSection'
import {
  clearUploadData,
  publishData,
  downloadAllToExcelByTcins,
} from '../../../../../store/bulkUpload/actionCreator'

class HeaderSectionContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      showBulletCountDialog: false,
    }
  }

  clearData = () => {
    this.props.clearUploadData()
  }

  handleDownload = () => {
    let tcins = []
    this.props.selectedData.forEach((item) => {
      tcins.push(item.tcin)
    })
    if (tcins.length > 0) {
      this.props.downloadAllToExcelByTcins(tcins)
    }
  }

  publishData = () => {
    this.setState({ showBulletCountDialog: false })
    var invalidFiles = []
    var validFiles = []
    this.props.selectedData.forEach((item) => {
      if (item.valid) {
        var data = {}
        data.tcin = item.tcin
        data.long_copy = item.longCopy
        data.feature_bullets = item.featureBullets
        data.ltwoDisclaimer = item.ltwoDisclaimer
        validFiles.push(data)
      } else {
        invalidFiles.push(item)
      }
    })
    var requestBody = {
      data: validFiles,
      uploaded_by: this.props.auth.email
        ? this.props.auth.email
        : this.props.auth.lanId,
    }

    this.props.publishData(requestBody, invalidFiles)
  }

  checkUpload = () => {
    const shortBullets = this.props.selectedData.filter((item) => {
      if (item.featureBullets.length < 3 && item.featureBullets.length !== 0) {
        return item
      }
    })
    if (shortBullets.length > 0) {
      this.setState({ showBulletCountDialog: true })
    } else {
      this.publishData()
    }
  }

  cancelPublish = () => {
    this.setState({ showBulletCountDialog: false })
  }

  downloadBlankTemplate = () => {
    this.props.downloadAllToExcelByTcins(['-11111111'])
  }

  render() {
    return (
      <HeaderSection
        clearData={this.clearData}
        uploadDataLength={this.props.uploadData.length}
        uploadData={this.props.uploadData}
        selectedDataLength={this.props.selectedData.length}
        publishData={this.publishData}
        handleDownload={this.handleDownload}
        isCopyEdited={this.props.isCopyEdited}
        downloadBlankTemplate={this.downloadBlankTemplate}
        checkUpload={this.checkUpload}
        showBulletCountDialog={this.state.showBulletCountDialog}
        bulletCountMessage={this.props.bulletCountMessage}
        cancelPublish={this.cancelPublish}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearUploadData,
      publishData,
      downloadAllToExcelByTcins,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { bulkUpload, auth } = state
  const { uploadData, selectedData, isCopyEdited, bulletCountMessage } =
    bulkUpload
  return {
    uploadData,
    selectedData,
    auth,
    isCopyEdited,
    bulletCountMessage,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderSectionContainer)
