import { useState } from 'react'
import styles from './theme'
import { withStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import envConfigs from '../../config/apiConfig'

function HelpHeader(props) {
  const { classes, menuAction = () => {} } = props

  const [currentTab, setCurrentTab] = useState('Style Guides')
  // const [currentHeader, setCurrentHeader] = useState('Help')

  const routeToPage = (value) => {
    setCurrentTab(value)
    switch (value) {
      case 'StyleGuides': {
        props.router.navigate({ pathname: '/v2/help/styleGuides' })
        break
      }
      default: {
        break
      }
    }
  }

  const handleChange = (_, value) => {
    routeToPage(value)
  }

  return (
    <div className={classes.header}>
      <AppBar className={classes.appBar}>
        {menuAction && (
          <div className={classes.headerTab}>
            <IconButton
              onClick={menuAction}
              classes={{ root: classes.button }}
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.marginLeftMedium}>
              <img
                alt="Primary"
                src={
                  envConfigs.api.sceneSevenBaseUrl +
                  'pipeline_logo_sq_wh?fmt=png-alpha&hei=40&wei=40'
                }
              />
            </div>
            <div className={classes.marginLeftMedium}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.whiteColor}
              >
                Help - {currentTab}
              </Typography>
            </div>
          </div>
        )}
        <Toolbar className={classes.toolbarTab}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            classes={{ indicator: classes.noColor }}
          >
            {<Tab value="Style Guides" label="Style Guides" />}
          </Tabs>
        </Toolbar>
      </AppBar>
    </div>
  )
}

// @ts-ignore
export default withStyles(styles)(HelpHeader)
