/* eslint-disable array-callback-return */
import {
  IMAGE_SEARCH_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_SEARCH,
  STORE_IMAGE_SEARCH_STRAPI_DATA,
  REMOVE_FILTER_EVENT_IMAGE_SEARCH,
  BUILD_FILTER_EXPAND_IMAGE_SEARCH,
  NEW_FILTER_EVENT_IMAGE_SEARCH,
  BUILD_FILTER_EVENT_IMAGE_SEARCH,
  CLEAR_IMAGE_SEARCH_DATA_EVENT,
  ERROR_SEARCHING_IMAGE_SEARCH,
  REQUEST_IMAGE_SEARCH_DATA,
  RECEIVE_IMAGE_SEARCH_DATA,
  CHANGE_CURRENT_PAGE_IMAGE_SEARCH,
  CHANGE_FILTER_EVENT_IMAGE_SEARCH,
  SELECT_FILTER_VALUE_EVENT_IMAGE_SEARCH,
  CHANGE_DEFAULT_PAGE_SIZE_IMAGE_SEARCH,
  SELECT_ITEM_SEARCH_EVENT,
  SET_SORT_FIELD_IMAGE_SEARCH,
  SET_SORT_DIRECTION_IMAGE_SEARCH,
  RECEIVE_DEPT_CLASS_LIST_IMAGE_SEARCH,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { isEmpty, cloneDeep, isEqual, isNil, toNumber, flatten } from 'lodash'
import { savePaginationDataEvent } from '../../auth/actionCreator'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'

export function resetFetch(data) {
  return {
    type: IMAGE_SEARCH_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_IMAGE_SEARCH,
    payload: data,
  }
}

export function buildFilterExpand(value) {
  return {
    type: BUILD_FILTER_EXPAND_IMAGE_SEARCH,
    payload: {
      buildFilterExpansionPanel: value,
    },
  }
}

export function newFilterAction(isShown) {
  return {
    type: NEW_FILTER_EVENT_IMAGE_SEARCH,
    payload: isShown,
  }
}

export function loadExistingFilter(selectedFilters) {
  return {
    type: BUILD_FILTER_EVENT_IMAGE_SEARCH,
    payload: {
      newFilterContainerShown: true,
      dataContainerShown: true,
      selectedFilters: selectedFilters,
    },
  }
}

export function requestImageSearchData(
  data,
  pageNumber,
  pageSize,
  sortDirection,
  sortField
) {
  var queryParam = {}
  data.map((item) => {
    if (item.filterValue === 'dateRange') {
      if (item.selectedValues[0].value.fromDate !== undefined) {
        queryParam.from_date = item.selectedValues[0].value.fromDate
        queryParam.to_date = item.selectedValues[0].value.toDate
      }
      queryParam.duration = item.selectedValues[0].value.duration
    }
    if (item.filterValue === 'launchDate') {
      if (item.selectedValues[0].value.fromDate !== undefined) {
        queryParam.launch_date_from = item.selectedValues[0].value.fromDate
        queryParam.launch_date_to = item.selectedValues[0].value.toDate
      }
      queryParam.launch_date_duration = item.selectedValues[0].value.duration
    }
    if (item.filterValue === 'tcin') {
      queryParam.tcins = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'brand') {
      queryParam.brands = item.selectedValues.map((filter) => filter.display)
    }
    if (item.filterValue === 'vendor') {
      queryParam.vendor_ids = item.selectedValues.map((filter) =>
        toNumber(filter.value)
      )
    }
    if (item.filterValue === 'deptClass') {
      queryParam.merchandise_hierarchies = flatten(
        item.selectedValues.map(
          (filter) => filter.value.merchandise_hierarchies
        )
      )
    }
    if (item.filterValue === 'approvalStatus') {
      queryParam.event_types = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'dpcis') {
      queryParam.dpci = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'itemSetupStatus') {
      queryParam.item_states = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'weeksOutAgo') {
      const weeksDateRange = flatten(
        item.selectedValues.map((filter) => filter.value.weeksFilter)
      )
      queryParam.weeks = [
        {
          start_date: weeksDateRange[0]?.startDate,
          end_date: weeksDateRange[0]?.endDate,
        },
      ]
    }
  })

  if (data === undefined || data.length === 0) {
    return clearImageSearchDataEvent()
  }

  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(requestImageSearchDataEvent())
    return axios
      .post(
        `${envConfigs.api.externalApi}review/image/get_all_image_data/es?size=${pageSize}&page=${pageNumber}`,
        queryParam
      )
      .then((res) => {
        if (res.data) {
          dispatch(
            successRequestDataEvent(
              res.data.items,
              res.data.total_pages,
              res.data.total_elements
            )
          )
        } else {
          dispatch(errorSearching(true))
        }
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

function clearImageSearchDataEvent() {
  return {
    type: CLEAR_IMAGE_SEARCH_DATA_EVENT,
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING_IMAGE_SEARCH,
    payload: payload,
  }
}

function requestImageSearchDataEvent() {
  return {
    type: REQUEST_IMAGE_SEARCH_DATA,
    payload: {
      isFetching: true,
      rhData: [],
      selectedImages: [],
    },
  }
}

function successRequestDataEvent(json, totalPages, totalElements) {
  return {
    type: RECEIVE_IMAGE_SEARCH_DATA,
    payload: {
      isFetching: false,
      rhData: json,
      totalPages: totalPages,
      totalElements: totalElements,
    },
  }
}

export function clearFilters() {
  return {
    type: REMOVE_FILTER_EVENT_IMAGE_SEARCH,
    payload: {
      newFilterContainerShown: false,
      dataContainerShown: false,
      selectedFilters: [],
    },
  }
}

export function buildFilterAction(
  data,
  pageNumber,
  pageSize,
  sortDirection,
  sortField
) {
  var alreadySelectedFilters = data.selectedFilters.filter(
    (item) => item.filterValue === data.selectedFilter.value
  )
  if (alreadySelectedFilters.length > 0) {
    // If filter already selected and different value selected.
    let isDuplicateValueFound = false
    if (Array.isArray(data.selectedFilterValue)) {
      data.selectedFilterValue.forEach((item) => {
        isDuplicateValueFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val) => val.value === item.value
          ).length === 0
      })
    } else {
      isDuplicateValueFound =
        alreadySelectedFilters[0].selectedValues.filter(
          (val) => val.value === data.selectedFilterValue.value
        ).length === 0
    }
    if (isDuplicateValueFound) {
      return (dispatch) => {
        dispatch(changePage(0)) // Reset Current Page to first page
        var filter = dispatch(handleAlreadySelectedFilter(data))
        dispatch(
          requestImageSearchData(
            filter.payload.selectedFilters,
            pageNumber,
            pageSize,
            sortDirection,
            sortField
          )
        )
      }
    } else {
      return (dispatch) => {
        dispatch(
          resetFetch(false),
          toast.error(
            'You have attempted to add duplicate filters.',
            TOAST_BODY
          )
        )
      }
    }
  } else {
    return (dispatch) => {
      dispatch(changePage(0)) // Reset Current Page to first page
      var filter = dispatch(handleNewFilter(data))
      dispatch(
        requestImageSearchData(
          filter.payload.selectedFilters,
          0,
          pageSize,
          sortDirection,
          sortField
        )
      )
    }
  }
}

function changePage(currentPage) {
  return {
    type: CHANGE_CURRENT_PAGE_IMAGE_SEARCH,
    payload: {
      currentPage: currentPage,
    },
  }
}

function handleAlreadySelectedFilter(data) {
  var deepCopy = cloneDeep(data.selectedFilters)
  let selectedValues = []
  let dateRangeAlreadySelected =
    deepCopy.filter((item) => item.filterValue === 'dateRange').length > 0
  let dateRangeAdded = data.selectedFilter.value === 'dateRange'
  if (dateRangeAlreadySelected && dateRangeAdded) {
    deepCopy
      .filter((item) => item.filterValue === 'dateRange')[0]
      .selectedValues.shift()
  }

  let launchRangeAlreadySelected =
    deepCopy.filter((item) => item.filterValue === 'launchDate').length > 0
  let launchRangeAdded = data.selectedFilter.value === 'launchDate'
  if (launchRangeAlreadySelected && launchRangeAdded) {
    deepCopy
      .filter((item) => item.filterValue === 'launchDate')[0]
      .selectedValues.shift()
  }

  let weeksFilterAlreadySelected =
    deepCopy.filter((item) => item.filterValue === 'weeksOutAgo').length > 0
  let weeksFilterAdded = data.selectedFilter.value === 'weeksOutAgo'
  if (weeksFilterAlreadySelected && weeksFilterAdded) {
    deepCopy
      .filter((item) => item.filterValue === 'weeksOutAgo')[0]
      .selectedValues.shift()
  }

  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  let oldSelectedValue = deepCopy.filter(
    (v) => v.filterValue === data.selectedFilter.value
  )[0].selectedValues
  selectedValues.forEach((item) => {
    oldSelectedValue.push(item)
  })
  deepCopy
    .filter((v) => v.filterValue === data.selectedFilter.value)[0]
    .selectedValues.concat(selectedValues)
  return {
    type: BUILD_FILTER_EVENT_IMAGE_SEARCH,
    payload: {
      newFilterContainerShown: true,
      dataContainerShown: true,
      selectedFilters: deepCopy,
    },
  }
}

function handleNewFilter(data) {
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  var newSelectedFilters = [
    {
      filterValue: data.selectedFilter.value,
      filterDisplay: data.selectedFilter.display,
      selectedValues: selectedValues,
    },
  ]
  return {
    type: BUILD_FILTER_EVENT_IMAGE_SEARCH,
    payload: {
      newFilterContainerShown: true,
      dataContainerShown: true,
      selectedFilters: data.selectedFilters.concat(newSelectedFilters),
    },
  }
}

export function changeFilterEvent(data) {
  return {
    type: CHANGE_FILTER_EVENT_IMAGE_SEARCH,
    payload: {
      selectedFilter: data.selectedFilter,
    },
  }
}

export function selectFilterValueEvent(data) {
  return {
    type: SELECT_FILTER_VALUE_EVENT_IMAGE_SEARCH,
    payload: {
      filterValues: data.filterValues,
    },
  }
}

export function removeFilterHandler(
  data,
  currentPage,
  defaultPageSize,
  sortDirection,
  sortField
) {
  return (dispatch) => {
    var filter = dispatch(removeFilterEvent(data))
    dispatch(
      requestImageSearchData(
        filter.payload.selectedFilters,
        currentPage,
        defaultPageSize,
        sortDirection,
        sortField
      )
    )
  }
}

export function removeFilterEvent(data) {
  var newFilterContainerShown = true
  var dataContainerShown = true
  var deepCopy = cloneDeep(data.selectedFilters)
  var currentFilter = deepCopy.filter(
    (v) => v.filterValue === data.mainFilterValue
  )[0]
  currentFilter.selectedValues = currentFilter.selectedValues.filter(
    (item) => !isEqual(item.value, data.filterValue)
  )
  deepCopy = deepCopy.filter((v) => v.selectedValues.length >= 1)
  if (currentFilter.selectedValues.length === 0 && deepCopy.length <= 0) {
    deepCopy = deepCopy.filter((v) => v.filterValue !== data.mainFilterValue)
    newFilterContainerShown = false
    dataContainerShown = false
  }
  return {
    type: REMOVE_FILTER_EVENT_IMAGE_SEARCH,
    payload: {
      newFilterContainerShown: newFilterContainerShown,
      dataContainerShown: dataContainerShown,
      selectedFilters: deepCopy,
    },
  }
}

export function getImageSearchStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Image%20Search&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data)
          ? dispatch(showDefaultAlert(true))
          : dispatch(storeImageSearchStrapiData(response.data))
        dispatch(showDefaultAlert(false))
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function storeImageSearchStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const errorData = data.filter(
    (item) => item.interface_document_type === 'error'
  )
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  const error =
    errorData.length > 0
      ? {
          title: errorData[0].user_facing_title,
          body: errorData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_IMAGE_SEARCH_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
      error,
    },
  }
}

export function handleChangePage(data, sortDirection, sortField) {
  return (dispatch) => {
    dispatch(changePage(data.currentPage))
    dispatch(
      requestImageSearchData(
        data.selectedFilters,
        data.currentPage,
        data.defaultPageSize,
        sortDirection,
        sortField
      )
    )
  }
}

export function changeDefaultPageSize(
  data,
  sortDirection,
  sortField,
  pageName
) {
  return (dispatch) => {
    dispatch(changePageSize(data.defaultPageSize))
    // Always setting the currentPage to 0 to prevent bugs
    dispatch(changePage(0))
    dispatch(
      requestImageSearchData(
        data.selectedFilters,
        0,
        data.defaultPageSize,
        sortDirection,
        sortField
      )
    ).then(
      dispatch(
        savePaginationDataEvent({
          pageSize: data.defaultPageSize,
          sortOrder: sortDirection,
          sortField: sortField,
          pageName: pageName,
        })
      )
    )
  }
}

function changePageSize(defaultPageSize) {
  return {
    type: CHANGE_DEFAULT_PAGE_SIZE_IMAGE_SEARCH,
    payload: {
      defaultPageSize: defaultPageSize,
    },
  }
}

export function handleSelectItem(data) {
  return {
    type: SELECT_ITEM_SEARCH_EVENT,
    payload: {
      selectedItems: data.selectedItems,
    },
  }
}

export function setSortField(value) {
  return {
    type: SET_SORT_FIELD_IMAGE_SEARCH,
    payload: value,
  }
}

export function setSortDirection(value) {
  return {
    type: SET_SORT_DIRECTION_IMAGE_SEARCH,
    payload: value,
  }
}

function receiveDeptClassList(data) {
  return {
    type: RECEIVE_DEPT_CLASS_LIST_IMAGE_SEARCH,
    payload: {
      deptClassList: data,
    },
  }
}

export function getDeptClassList() {
  return (dispatch) => {
    return axios
      .get(`${envConfigs.api.itemApi}/digital_items/v1/hierarchy/departments`)
      .then((res) => {
        let list = res.data.map((deptClass) => {
          let type = deptClass.department_id
          let deptName = deptClass.department_name
          let subClasses = deptClass.class_list.map((subClass) => {
            let type = subClass.class_id
            let subClassName = subClass.class_name
            return { type: type, subClassName: subClassName }
          })
          return { type: type, deptName: deptName, subClasses: subClasses }
        })
        dispatch(
          receiveDeptClassList(
            list.filter(
              (department) => !isNil(department.type) && department.type !== '0'
            )
          )
        )
      })
      .catch((error, data) => {
        dispatch(
          resetFetch(false),
          toast.error('Failed to retrieve Dept-Classes.', TOAST_BODY),
          toast.error(error.message, TOAST_BODY)
        )
      })
  }
}
