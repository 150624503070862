/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './theme'
import { withStyles } from '@material-ui/core/styles'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Grid,
  Toolbar,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {
  clearReviewData,
  handleSelectImage,
  toggleActionDrawer,
  getImageReviewStrapiData,
  setLookCloserItem,
} from '../../../store/images/review/actionCreator'
import { gridCheckedLogic, isExternalUser } from '../../Shared/SharedUtils'
import ImageReviewResults from './Components/ImageReviewResults'
import ImageToolbar from '../Shared/Components/ImageToolbar'
import ApproveImageAction from '../Shared/Components/ApproveImageAction'
import SendToRetouchImageAction from '../Shared/Components/SendToRetouchImageAction'
import RejectImageAction from '../Shared/Components/RejectImageAction'
import { flattenDeep, uniq } from 'lodash'
import ImageToolbarActionDrawerContainer from '../Shared/Components/ImageToolbarActionDrawer/ImageToolbarActionDrawerContainer'
import StrapiExpansionPanel from '../../Shared/StrapiComponents/StrapiExpansionPanel'
import DefaultAlert from '../../Shared/StrapiComponents/DefaultAlert'
import CustomError from '../../Shared/StrapiComponents/CustomError'
import Alert from '../../Shared/StrapiComponents/Alert'
import { ROUTES } from '../../Shared/Constants'
import { withRouter } from '../../Shared/WithRouterHoc'

function ImageReview(props) {
  const {
    classes = {},
    reviewData = [],
    selectedReviewImages = [],
    isFetching = false,
    auth = {},
    information = {},
    faq = {},
    alert = {},
    showDefaultAlert = false,
  } = props

  useEffect(() => {
    props.getImageReviewStrapiData(isExternalUser(auth))
  }, [])

  const [expansionPanelOpen, expandFaq] = useState(false)

  const handleFaqPanel = () => {
    expandFaq(!expansionPanelOpen)
  }

  const backToSearch = (_) => {
    props.clearReviewData()
    // re-run selected filter
    //props.history.push(ROUTES.IMAGES.SEARCH)
    props.router.navigate({ pathname: ROUTES.IMAGES.SEARCH })
  }

  const handleSelectAllClick = (_, checked) => {
    let newSelected = []
    if (checked) {
      reviewData.forEach((item) => {
        item.image_list.forEach((image) => {
          newSelected.push({
            tcin: item.tcin,
            image,
          })
        })
      })
    }
    props.handleSelectImage(newSelected)
  }

  const handleImageClick = (_, checkedImage) => {
    props.handleSelectImage(
      gridCheckedLogic(selectedReviewImages, checkedImage)
    )
  }

  const handleItemClick = (_, checkedItem, isAppend) => {
    if (isAppend) {
      props.handleSelectImage(
        selectedReviewImages
          .filter((image) => image.tcin !== checkedItem.tcin)
          .concat(
            checkedItem.image_list.map((image) => {
              return {
                tcin: checkedItem.tcin,
                image,
              }
            })
          )
      )
    } else {
      props.handleSelectImage(
        selectedReviewImages.filter((image) => image.tcin !== checkedItem.tcin)
      )
    }
  }

  let totalItems = reviewData.length
  let totalImages = flattenDeep(
    reviewData.map((data) => data.image_list)
  ).length
  let numSelected = selectedReviewImages.length
  let selectedTcins = uniq(selectedReviewImages.map((item) => item.tcin))

  const openZoomedReview = (tcin) => {
    // clear out selected
    props.handleSelectImage([])
    // set look closer tcin
    props.setLookCloserItem(tcin)
    // navigate to look closer
    //props.history.push(ROUTES.IMAGES.LOOK_CLOSER)
    props.router.navigate({ pathname: ROUTES.IMAGES.LOOK_CLOSER })
  }

  const toggleActionDrawer = (isActionDrawerOpen, drawerAction) => {
    props.toggleActionDrawer(isActionDrawerOpen, drawerAction)
  }

  return (
    <React.Fragment>
      <HeaderTitle title="Image Review" />
      <Helmet title="Image Review" />
      <CustomError />
      {showDefaultAlert ? <DefaultAlert /> : null}
      {alert ? <Alert alert={alert} /> : null}
      {(information || faq) && (
        <StrapiExpansionPanel
          faq={faq}
          information={information}
          expansionPanelOpen={expansionPanelOpen}
          handleFaqPanel={handleFaqPanel}
        />
      )}
      <Paper className={classes.width100}>
        <React.Fragment>
          <Toolbar>
            <Grid
              justify="space-between"
              container
              spacing={3}
              alignItems="center"
            >
              <Grid item>
                <div className={classes.title}>
                  <Typography
                    variant="subtitle1"
                    className={classes.totalElementsTitle}
                    id="tableTitle"
                  >
                    {totalItems} items / {totalImages} images for review
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => backToSearch()}
                  className={classes.marginRight20}
                >
                  Back To Search Results
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          {isFetching && (
            <Grid className={classes.progressBar}>
              <CircularProgress />
            </Grid>
          )}
          {reviewData.length > 0 && !isFetching && (
            <React.Fragment>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" align="left">
                        <Checkbox
                          data-test-id="master-checkbox"
                          indeterminate={
                            numSelected > 0 && numSelected < totalImages
                          }
                          checked={
                            totalImages > 0 && numSelected === totalImages
                          }
                          onChange={handleSelectAllClick}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell
                        padding="default"
                        align="left"
                        className={classes.itemData}
                      >
                        Item Details
                      </TableCell>
                      <TableCell padding="default" align="left">
                        Rel. Type Code
                      </TableCell>
                      <TableCell padding="default" align="left">
                        Images
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <ImageReviewResults
                      reviewData={reviewData}
                      selectedReviewImages={selectedReviewImages}
                      handleImageClick={handleImageClick}
                      handleItemClick={handleItemClick}
                      openZoomedReview={openZoomedReview}
                    />
                    <TableRow className={classes.height49}>
                      <TableCell colSpan={11} />
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <ImageToolbar
                numSelected={numSelected}
                selectedTcins={selectedTcins}
                enabledActions={[
                  <ApproveImageAction
                    toggleActionDrawer={toggleActionDrawer}
                  />,
                  <SendToRetouchImageAction
                    toggleActionDrawer={toggleActionDrawer}
                  />,
                  <RejectImageAction toggleActionDrawer={toggleActionDrawer} />,
                ]}
              />
              <ImageToolbarActionDrawerContainer
                numSelected={numSelected}
                selectedTcins={selectedTcins}
                isReprocess={false}
              />
            </React.Fragment>
          )}
          {reviewData.length === 0 && !isFetching && (
            <div className={classes.noResult}>No Images to Review</div>
          )}
        </React.Fragment>
      </Paper>
      <div className={classes.toolbarSpacer} />
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearReviewData,
      handleSelectImage,
      toggleActionDrawer,
      getImageReviewStrapiData,
      setLookCloserItem,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, imageReview } = state
  const {
    reviewData,
    selectedReviewImages,
    isFetching,
    information,
    faq,
    alert,
    showDefaultAlert,
  } = imageReview
  return {
    auth,
    reviewData,
    selectedReviewImages,
    isFetching,
    information,
    faq,
    alert,
    showDefaultAlert,
  }
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(ImageReview)))
