import { withStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
} from '@material-ui/core'
import styles from '../theme'
import AltTextSearchResult from './AltTextSeachResult'
import ImageToolbar from '../../Images/Shared/Components/ImageToolbar'
import DisableAltText from './DisableAltText'
import Lightbox from 'react-image-lightbox'
import { useState } from 'react'
import { flattenDeep } from 'lodash'

function SearchResultContainer(props) {
  const {
    searchData = [],
    classes = {},
    auth = {},
    selectedItems = [],
    handleSelectItem = () => {},
  } = props
  const [lightboxUrl, setLightboxUrl] = useState(null)

  const modalStyle = {
    content: {
      top: '88px',
    },
  }

  const handleSelectAllClick = (_, checked) => {
    let newSelected = []

    let activeAltText = searchData.filter(
      (item) => item.alt_text.alt_text_active
    )

    let filterFlag = activeAltText.length === selectedItems.length

    if (checked && !filterFlag) {
      newSelected = activeAltText
    }

    if (checked && filterFlag) {
      newSelected = []
    }

    handleSelectItem({
      selectedItems: newSelected,
    })
  }

  let selectedTcins = flattenDeep(selectedItems.map((item) => item.tcin))

  const isSelected = (checkedImage) =>
    selectedItems.indexOf(checkedImage) !== -1

  return (
    <div className={classes.tableWrapper}>
      <Table
        className={classes.table}
        aria-labelledby="searchResults"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" align="left">
              <Checkbox
                onChange={handleSelectAllClick}
                color="primary"
                indeterminate={
                  selectedItems.length > 0 &&
                  selectedItems.length < searchData.length
                }
                checked={
                  searchData.length > 0 &&
                  selectedItems.length === searchData.length
                }
              />
            </TableCell>
            <TableCell
              padding="default"
              align="left"
              className={classes.itemData}
            >
              Tcin
            </TableCell>
            <TableCell padding="default" align="left">
              Image Status
            </TableCell>
            <TableCell padding="default" align="left">
              Image Url
            </TableCell>
            <TableCell padding="default" align="left">
              Image
            </TableCell>
            <TableCell padding="default" align="left">
              Position
            </TableCell>
            <TableCell padding="default" align="left">
              Alt Text
            </TableCell>
            <TableCell padding="default" align="left">
              Alt text status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchData &&
            searchData.map((itemData, index) => (
              <AltTextSearchResult
                itemData={itemData}
                setLightboxUrl={setLightboxUrl}
                selectedItems={selectedItems}
                selectItem={handleSelectItem}
                isSelected={isSelected(itemData)}
              />
            ))}
          <TableRow className={classes.height49}>
            <TableCell colSpan={11} />
          </TableRow>
        </TableBody>
      </Table>
      <ImageToolbar
        numSelected={selectedTcins.length}
        selectedTcins={selectedTcins}
        enabledActions={[
          <DisableAltText
            auth={auth}
            disableAltTextSearchResults={props.disableAltTextSearchResults}
          />,
        ]}
      />

      {lightboxUrl && (
        <Lightbox
          reactModalStyle={modalStyle}
          mainSrc={lightboxUrl}
          onCloseRequest={() => setLightboxUrl(null)}
        />
      )}
    </div>
  )
}

// @ts-ignore
export default withStyles(styles)(SearchResultContainer)
