import { Typography, Toolbar, Grid, IconButton, Link } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../../theme'
import GetAppIcon from '@material-ui/icons/GetApp'
import TablePagination from '@material-ui/core/TablePagination'

let CopyDataContainerTitle = (props) => {
  const {
    classes = {},
    totalElements = 0,
    rowsPerPage = 0,
    page = 0,
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    numberOfNotes = 0,
  } = props
  return (
    <Toolbar>
      <Grid justify="space-between" container spacing={3} alignItems="center">
        <Grid item>
          <div className={classes.title}>
            <Typography
              variant="subtitle1"
              className={classes.totalElementsTitle}
              id="tableTitle"
            >
              {totalElements} items / {numberOfNotes} notes
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            spacing={1}
          >
            <Grid item>
              <IconButton onClick={() => props.downloadAllToExcel()}>
                <GetAppIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="caption" id="tableTitle">
                <Link
                  href="#"
                  className={classes.marginRight20}
                  onClick={() => props.downloadAllToExcel()}
                >
                  Download All to Excel
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <TablePagination
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

CopyDataContainerTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
}

export default withStyles(styles)(CopyDataContainerTitle)
