const styles = (theme) => ({
  dropZoneNotActive: {
    margin: 'auto',
    height: '200px',
    width: '200px',
    border: 'dashed 1px black',
    padding: '10px',
    cursor: 'pointer',
  },
  dropZoneActive: {
    backgroundColor: 'rgba(173, 216, 230, 0.5)',
    margin: 'auto',
    height: '200px',
    width: '200px',
    border: 'dashed 1px rgba(33,150,243, 1)',
    padding: '20px',
  },
  margin20: {
    margin: '20px',
  },
  DropZoneLargeText: {
    marginBottom: '0px !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '300px',
    fontStyle: 'normal',
    fontSize: '24px',
    color: '#999999',
    textAlign: 'center',
  },
  progressBar: {
    marginLeft: '50%',
  },
  fabProgress: {
    position: 'absolute',
    top: '-19px',
    left: '-15px',
    zIndex: 1,
  },
  wrapper: {
    margin: '8px',
    position: 'relative',
  },
  badgesRoot: {
    width: '100%',
  },
  table: {
    minWidth: '1020px',
  },
  tableWrapper: {
    overflowX: 'auto',
    maxHeight: 'calc(100vh - 234px)',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  disabledIcon: {
    color: '#a0a0a0',
  },
  title: {
    flex: '0 0 auto',
  },
  totalElementsTitle: {
    color: '#CC0000',
    padding: '30px 5px',
    fontSize: '1.2em',
  },
  marginRight20: {
    marginRight: '20px',
  },
  toolBar: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: '#CC0000',
    color: 'white',
    zIndex: 1000,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: 'white',
  },
  drawerButton: {
    margin: '0px 0px 0px 5px',
    color: 'white',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  badgeValue: {
    padding: '4px 10px 0px 10px',
    cursor: 'pointer',
  },
  newBadgeToolBar: {
    padding: '0',
    margin: '0',
    left: '0px',
    width: '100%',
    color: 'white',
    zIndex: 1000,
    position: 'absolute',
    backgroundColor: '#CC0000',
    bottom: '0px',
  },
  rotate180: {
    transform: 'rotate(180deg)',
  },
  minHeight45Per: {
    minHeight: '45%',
  },
  centerText: {
    textAlign: 'center',
  },
  maxWidth80Per: {
    maxWidth: '75%',
  },
  infoCard: {
    minWidth: '20%',
    marginLeft: '20px',
  },
  marginBottom50: {
    marginBottom: '50px',
  },
  imageUploadOrUrl: {
    margin: '65px 30px',
    textAlign: 'center',
  },
  badgeImage: {
    width: '175px',
    height: '175px',
  },
  uploadImage: {
    width: '175px',
    height: '175px',
    marginLeft: '41px',
  },
  deleteIcon: {
    color: '#3f51b5',
    fontSize: '32px',
    cursor: 'pointer',
  },
  showBadgeImage: {
    textAlign: 'center',
    margin: '10px 20px',
    cursor: 'pointer',
  },
  neg16MarginTop: {
    marginTop: '-16px',
  },
  marginLeftBottom: {
    margin: '0 0 20px 20px',
  },
  marginTop40: {
    marginTop: '40px',
  },
  margin10: {
    margin: '10px',
  },
  marginBottom25: {
    marginBottom: '25px',
  },
  errorText: {
    color: '#f44336',
    margin: '0px',
    fontSize: '0.75rem',
    textAlign: 'left',
    marginTop: '8px',
    minHeight: '1em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1em',
  },
  padding0: {
    padding: '0px',
  },
  disabledBadgeRow: {
    backgroundColor: '#D6D6D6',
    color: '333333',
    fontStyle: 'italic',
  },
  loadingSpinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0 0 0',
    minHeight: '250px',
  },
})

export default styles
