/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import CopyDataContainer from './Components/CopyData/CopyData/CopyDataContainer'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styles from './theme'
import { withStyles } from '@material-ui/core/styles'
import {
  buildFilterExpand,
  getLongCopyStrapiData,
  newFilterAction,
  loadExistingFilter,
  requestCopyData,
  updateFilterSelectedSticker,
  clearSelectedSticker,
  clearFilters,
  resetFetch,
  buildFilterAction,
  changeFilterEvent,
  selectFilterValueEvent,
  requestDepartmentFilter,
  removeFilterHandler,
} from '../../../store/longCopy/actionCreator'
import {
  saveFilterDataEvent,
  fetchSavedFiltersEvent,
  toggleSaveFilterDialogue,
  toggleConfirmation,
  removeSavedFilter,
} from '../../../store/auth/actionCreator'
import {
  isExternalUser,
  splitTcins,
  chipFilter,
  dropDownListFilter,
} from '../../Shared/SharedUtils'
import DefaultAlert from '../../Shared/StrapiComponents/DefaultAlert'
import MyFilter from '../../Shared/Filter/MyFilter/MyFilter'
import BuildFilter from '../../Shared/Filter/BuildFilter'
import {
  FilterData,
  CopyWritingStatus,
  RoutedTeams,
  NoteOptions,
} from './FilterData'
import { includes, uniqBy } from 'lodash'
import NewFilter from '../../Shared/Filter/NewFilter'
import Alert from '../../Shared/StrapiComponents/Alert'
import StrapiExpansionPanel from '../../Shared/StrapiComponents/StrapiExpansionPanel'

function LongCopy(props) {
  const {
    isFetching = false,
    classes = {},
    buildFilterContainerShown = false,
    newFilterContainerShown = false,
    buildFilterExpansionPanel = false,
    copyDataContainerShown = false,
    showDefaultAlert = false,
    auth = {},
    alert = null,
    information = null,
    faq = null,
    userProfile = {},
    isConfirmationOpen = false,
    selectedFilter = -1,
    filterValues = {},
    selectedDepartmentData = {},
    defaultFilterValues = {},
    selectedFilters = [],
    saveFilter = {},
    isSaveFilterOpen = false,
    pristine,
    isFetchingSavedFilters = false,
    errorSearching = false,
    pageName = '',
    isFetchingAuth = false,
  } = props

  useEffect(() => {
    props.fetchSavedFiltersEvent()
    props.getLongCopyStrapiData(isExternalUser(auth))
  }, [])

  useEffect(() => {
    if (
      buildFilterContainerShown ||
      newFilterContainerShown ||
      copyDataContainerShown
    ) {
      expandFaq(false)
    }
  }, [
    buildFilterContainerShown ||
      newFilterContainerShown ||
      copyDataContainerShown,
  ])

  useEffect(() => {
    // Call department value for the filter.
    if (
      !includes(props.auth.permission.hostName, 'vendorpipeline') &&
      selectedFilter === 'department'
    ) {
      props.requestDepartmentFilter()
    }
  }, [selectedFilter])

  const [expansionPanelOpen, expandFaq] = useState(true)

  const handlePanel = () => {
    props.buildFilterExpand(!buildFilterExpansionPanel)
  }

  const handleFaqPanel = () => {
    expandFaq(!expansionPanelOpen)
  }

  const removeSavedFilterHandler = (item) => {
    props.toggleConfirmation(true, { id: item })
  }

  const handleCancelConfirmation = () => {
    props.toggleConfirmation(false, null)
  }

  const handleConfirmRemoveFilter = () => {
    props.removeSavedFilter(props.confirmationPayload.id)
  }

  const loadFilterHandler = (item) => {
    let userId = props.auth.email ? props.auth.email : props.auth.lanId
    props.buildFilterExpand(false)
    props.newFilterAction(true)
    props.loadExistingFilter(JSON.parse(item.filter_criteria))
    props.requestCopyData(
      JSON.parse(item.filter_criteria),
      0,
      pageSize,
      userId,
      sortDirection,
      sortField
    )
    props.clearSelectedSticker()
    JSON.parse(item.filter_criteria).map((item) => {
      item.selectedValues.map((data) => {
        props.updateFilterSelectedSticker(data.value, true)
      })
    })
  }

  const onClick = () => {
    props.newFilterAction(true)
    props.buildFilterExpand(true)
    props.clearFilters()
    props.clearSelectedSticker()
  }

  const buildFilter = (selectedFilter, selectedFilterValue) => {
    if (!props.isFetching) {
      let userId = props.auth.email ? props.auth.email : props.auth.lanId
      props.buildFilterAction(
        {
          newFilterContainerShown: true,
          copyDataContainerShown: true,
          selectedFilters: props.selectedFilters,
          selectedFilter: selectedFilter,
          selectedFilterValue: selectedFilterValue,
        },
        props.currentPage,
        pageSize,
        userId,
        sortDirection,
        sortField,
        props.filterValues
      )
      props.updateFilterSelectedSticker(selectedFilterValue.value, true)
    }
  }

  /**
   * This method calls when user clicks on ADD button on Build filter
   * section. This method reset value for selected value once it add to filter
   * list
   */
  const addNewFilter = () => {
    var selectedFilter = FilterData.filter(
      (item) => item.value === props.selectedFilter
    )[0]
    var selectedFilterValue
    // Check for Copy Writing status
    if (selectedFilter.value === 'eventType') {
      dropDownListFilter(
        CopyWritingStatus,
        filterValues.copyWritingStatus,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'department') {
      dropDownListFilter(
        selectedDepartmentData.returnData,
        filterValues.departmentData,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'tcin') {
      chipFilter(
        filterValues.tcins,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'division') {
      chipFilter(
        filterValues.divisions,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'routedTeams') {
      dropDownListFilter(
        RoutedTeams,
        filterValues.routedTeams,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'notes') {
      dropDownListFilter(
        NoteOptions,
        filterValues.noteOptions,
        selectedFilter,
        buildFilter
      )
    }
    // Return value to parent components
  }

  const onFilterSelect = (event) => {
    props.changeFilterEvent({
      selectedFilter: event.target.value,
    })
    /**
     * Handling display for Filter value
     */
  }

  const selectFilterValue = (selectedFilterValues) => {
    props.selectFilterValueEvent({
      filterValues: selectedFilterValues,
    })
  }

  const onFilterValueSelect = (event) => {
    if (event.target.name === 'copyWritingStatus') {
      /**
       * Raise event for filter value select
       * filterValues: selectedFilterValues,
       */
      selectFilterValue({
        copyWritingStatus: event.target.value,
        departmentData: -1,
        divisions: [],
        tcins: [],
        routedTeams: -1,
        departmentClass: -1,
        noteOptions: -1,
      })
    }
    if (event.target.name === 'departmentData') {
      /**
       * Raise event for filter value select
       * filterValues: selectedFilterValues,
       */
      selectFilterValue({
        copyWritingStatus: -1,
        departmentData: event.target.value,
        divisions: [],
        tcins: [],
        routedTeams: -1,
        departmentClass: -1,
        noteOptions: -1,
      })
    }
    if (event.target.name === 'routedTeams') {
      /**
       * Raise event for filter value select
       * filterValues: selectedFilterValues,
       */
      selectFilterValue({
        copyWritingStatus: -1,
        departmentData: -1,
        divisions: [],
        tcins: [],
        routedTeams: event.target.value,
        departmentClass: -1,
        noteOptions: -1,
      })
    }
    if (event.target.name === 'departmentClass') {
      /**
       * Raise event for filter value select
       * filterValues: selectedFilterValues,
       */
      selectFilterValue({
        copyWritingStatus: -1,
        departmentData: props.filterValues.department,
        divisions: [],
        tcins: [],
        routedTeams: -1,
        departmentClass: event.target.value,
        noteOptions: -1,
      })
    }
    if (event.target.name === 'noteOptions') {
      /**
       * Raise event for filter value select
       * filterValues: selectedFilterValues,
       */
      selectFilterValue({
        copyWritingStatus: -1,
        tcins: [],
        divisions: [],
        routedTeams: -1,
        departmentData: -1,
        departmentClass: -1,
        noteOptions: event.target.value,
      })
    }
  }

  const handleAddTcin = (selectedTcins) => {
    let chips = []
    splitTcins(selectedTcins).forEach((i) => {
      chips.push({
        value: i,
        display: i,
      })
    })
    if (
      props.filterValues !== undefined &&
      props.filterValues.tcins.length > 0
    ) {
      props.filterValues.tcins.forEach((item) => {
        chips.push(item)
      })
    }
    selectFilterValue({
      copyWritingStatus: -1,
      tcins: uniqBy(chips, 'value'),
      divisions: [],
      routedTeams: -1,
      departmentData: -1,
      departmentClass: -1,
      noteOptions: -1,
    })
  }

  const handleDeleteTcin = (deletedTcin) => {
    let chips = []
    props.filterValues.tcins.forEach((item) => {
      if (item.value !== deletedTcin) {
        chips.push(item)
      }
    })
    selectFilterValue({
      copyWritingStatus: -1,
      tcins: uniqBy(chips, 'value'),
      divisions: [],
      routedTeams: -1,
      departmentData: -1,
      departmentClass: -1,
      noteOptions: -1,
    })
  }

  const handleDeleteDivision = (deletedDivision) => {
    let chips = []
    props.filterValues.divisions.forEach((item) => {
      if (item.value !== deletedDivision) {
        chips.push(item)
      }
    })
    selectFilterValue({
      copyWritingStatus: -1,
      tcins: [],
      divisions: chips,
      routedTeams: -1,
      departmentData: -1,
      departmentClass: -1,
      noteOptions: -1,
    })
  }

  const handleAddDivision = (divisions) => {
    let chips = []
    chips.push({
      value: divisions,
      label: divisions,
    })
    selectFilterValue({
      copyWritingStatus: -1,
      tcins: [],
      divisions: chips,
      routedTeams: -1,
      departmentData: -1,
      departmentClass: -1,
      noteOptions: -1,
    })
  }

  const removeFilterHandler = (mainFilter, filterVal) => {
    let userId = props.auth.email ? props.auth.email : props.auth.lanId
    props.removeFilterHandler(
      {
        selectedFilters: props.selectedFilters,
        mainFilterValue: mainFilter,
        filterValue: filterVal,
      },
      props.currentPage,
      pageSize,
      userId,
      sortDirection,
      sortField
    )
    props.updateFilterSelectedSticker(filterVal, false)
  }

  const saveNewFilter = () => {
    if (props.selectedFilters && props.selectedFilters.length > 0) {
      props.toggleSaveFilterDialogue(true)
    }
  }

  const clearFilters = () => {
    props.clearFilters()
    props.clearSelectedSticker()
  }

  const handleClose = () => {
    props.toggleSaveFilterDialogue(false)
  }

  const handleSave = () => {
    props.saveFilterDataEvent({
      filterName: props.saveFilter.values.saveFilter,
      filterCriteria: JSON.stringify(props.selectedFilters),
      pageSize: pageSize,
      sortOrder: sortDirection,
      sortField: sortField,
      pageName: pageName,
    })
  }

  const savedFilterData =
    userProfile && userProfile.search_filters
      ? userProfile.search_filters.filter(
          (filter) => filter.page_name === pageName
        )
      : []

  const paginationValues =
    userProfile && userProfile.pagination
      ? userProfile.pagination.filter((item) => item.page_name === pageName)
      : undefined

  const pageSize =
    paginationValues && paginationValues[0] && paginationValues[0].page_size
      ? paginationValues[0].page_size
      : props.defaultPageSize

  const sortDirection =
    paginationValues && paginationValues[0] && paginationValues[0].sort_order
      ? paginationValues[0].sort_order
      : props.sortDirection

  const sortField =
    paginationValues && paginationValues[0] && paginationValues[0].sort_field
      ? paginationValues[0].sort_field
      : props.sortField

  return (
    <React.Fragment>
      <HeaderTitle title="Search & Manage" />
      <Helmet title="Search & Manage" />
      {showDefaultAlert ? <DefaultAlert /> : null}
      {alert ? <Alert alert={alert} /> : null}
      <Grid container>
        <Grid item xs={12} sm={12} className={classes.marginBottom5}>
          <MyFilter
            isConfirmationOpen={isConfirmationOpen}
            handleCancelConfirmation={handleCancelConfirmation}
            handleConfirmRemoveFilter={handleConfirmRemoveFilter}
            savedFilterData={savedFilterData}
            onClick={onClick}
            loadFilterHandler={loadFilterHandler}
            removeSavedFilterHandler={removeSavedFilterHandler}
            isFetchingSavedFilters={isFetchingSavedFilters}
            disabled={isFetchingAuth}
          />
        </Grid>
        <React.Fragment>
          {(buildFilterContainerShown || newFilterContainerShown) && (
            <Accordion
              className={classes.buildFilterExpansion}
              expanded={buildFilterExpansionPanel}
              onChange={() => handlePanel()}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {buildFilterExpansionPanel ? 'Hide ' : 'Show '} Filter
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {buildFilterContainerShown && (
                  <Grid item xs={12} sm={6}>
                    <BuildFilter
                      selectedFilter={selectedFilter}
                      onFilterSelect={onFilterSelect}
                      filterValues={filterValues}
                      onFilterValueSelect={onFilterValueSelect}
                      handleAddTcin={handleAddTcin}
                      handleDeleteTcin={handleDeleteTcin}
                      isFetching={isFetching}
                      addNewFilter={addNewFilter}
                      selectedDepartmentData={selectedDepartmentData}
                      handleAddDivision={handleAddDivision}
                      handleDeleteDivision={handleDeleteDivision}
                      defaultFilterValues={defaultFilterValues}
                      FilterData={FilterData}
                      CopyWritingStatus={CopyWritingStatus}
                      RoutedTeams={RoutedTeams}
                      NoteOptions={NoteOptions}
                    />
                  </Grid>
                )}
                {newFilterContainerShown && (
                  <Grid item xs={12} sm={6}>
                    <NewFilter
                      clearFilters={clearFilters}
                      selectedFilters={selectedFilters}
                      saveNewFilter={saveNewFilter}
                      removeFilterHandler={removeFilterHandler}
                      isSaveFilterOpen={isSaveFilterOpen}
                      saveFilter={saveFilter}
                      handleClose={handleClose}
                      handleSave={handleSave}
                      pristine={pristine}
                      disabled={isFetchingAuth}
                    />
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {(information || faq) && (
            <StrapiExpansionPanel
              faq={faq}
              information={information}
              expansionPanelOpen={expansionPanelOpen}
              handleFaqPanel={handleFaqPanel}
            />
          )}
        </React.Fragment>
        {isFetching && (
          <Grid className={classes.progressBar}>
            <CircularProgress className={classes.progressBar} />
          </Grid>
        )}
        {copyDataContainerShown && !isFetching && !errorSearching && (
          <Grid item xs={12} sm={12}>
            <CopyDataContainer rowsPerPage={pageSize} />
          </Grid>
        )}
        {errorSearching && (
          <Grid item xs={12} sm={12}>
            <div className={classes.noResult}>
              Filter criteria too broad, please add more filters to narrow
              results.
            </div>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildFilterExpand,
      getLongCopyStrapiData,
      toggleConfirmation,
      removeSavedFilter,
      newFilterAction,
      fetchSavedFiltersEvent,
      loadExistingFilter,
      requestCopyData,
      updateFilterSelectedSticker,
      clearSelectedSticker,
      clearFilters,
      resetFetch,
      buildFilterAction,
      changeFilterEvent,
      selectFilterValueEvent,
      requestDepartmentFilter,
      toggleSaveFilterDialogue,
      saveFilterDataEvent,
      removeFilterHandler,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { longCopy, auth, form } = state
  const {
    buildFilterContainerShown,
    newFilterContainerShown,
    copyDataContainerShown,
    isFetching,
    buildFilterExpansionPanel,
    alert,
    information,
    faq,
    selectedFilter,
    filterValues,
    selectedDepartmentData,
    defaultFilterValues,
    defaultPageSize,
    sortDirection,
    sortField,
    currentPage,
    selectedFilters,
    errorSearching,
    pageName,
  } = longCopy
  const {
    isFetchingSavedFilters,
    userProfile,
    isSaveFilterOpen,
    isConfirmationOpen,
    confirmationPayload,
    isFetchingAuth,
  } = auth
  const { saveFilter } = form
  return {
    buildFilterContainerShown,
    newFilterContainerShown,
    copyDataContainerShown,
    isFetching,
    buildFilterExpansionPanel,
    auth,
    alert,
    information,
    faq,
    userProfile,
    isConfirmationOpen,
    selectedFilter,
    filterValues,
    selectedDepartmentData,
    defaultFilterValues,
    confirmationPayload,
    defaultPageSize,
    sortDirection,
    sortField,
    currentPage,
    selectedFilters,
    saveFilter,
    isSaveFilterOpen,
    isFetchingSavedFilters,
    errorSearching,
    pageName,
    isFetchingAuth,
  }
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LongCopy))
