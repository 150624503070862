import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Uploader from './Uploader'
import { cancelUpload } from '../../../../store/upload/actionCreator'

class UploaderContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  uploadCancel = () => {
    this.props.cancelUpload()
  }
  render() {
    return (
      <Uploader
        uploadCancel={this.uploadCancel}
        uploadInProgress={this.props.uploadInProgress}
        uploadData={this.props.uploadData}
        totalFiles={this.props.totalFiles}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelUpload,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, upload } = state
  const { uploadInProgress, uploadData, totalFiles } = upload
  return {
    auth,
    uploadData,
    uploadInProgress,
    totalFiles,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploaderContainer)
