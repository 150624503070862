import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ImageDataContainerHead from './ImageDataContainerHead'
import ImageToolbar from '../../Shared/Components/ImageToolbar'
import ImageDataContainerTitle from './ImageDataContainerTitle'
import Swatch from './Swatch'
import styles from '../theme'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core'
import { differenceWith, flattenDeep, isEqual, first, isNil } from 'lodash'
import ImageTableCells from './ImageTableCells'
import { DragDropContext } from 'react-beautiful-dnd'
import { IMAGE_ROW_ID, IMAGE_VIEW_TYPE } from '../../../Shared/Constants'
import {
  flattenImagesForTcin,
  imageMapping,
  isSwatchEligible,
} from '../../../Shared/SharedUtils'
import RelatedItemsModal from './RelatedItemsModal'
import SyncImagesModal from './SyncImagesModal'
import ItemDetails from '../../Shared/Components/ItemDetails'
import {
  createPrimaryRow,
  moveFromPrimaryToAlt,
  createGenericRow,
  createReorderedRow,
} from '../Utils'
import EditImageLabel from './EditImageLabel'

export const dndLogic = (result, item, updateImageData) => {
  const { destination, source, draggableId } = result
  let dragId = draggableId.split(' ')[0]
  // Do nothing if user drags outside droppable area
  if (!destination) {
    return
  }
  // Do nothing if user drags to original location
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return
  }

  // Find row where image is coming from
  const startRow = item.rows[source.droppableId]
  // Find row where image is going
  const finishRow = item.rows[destination.droppableId]
  // Move within same row
  if (startRow === finishRow) {
    // Duplicate image array
    const newImages = Array.from(startRow.images)
    // Find dragged image based on publishUrl (dragId)
    const draggedImage = newImages.find((item) => item.publishUrl === dragId)
    // Get original sequence and image ID: logic is that these will never change
    // while the rest of the image changes around them
    const previousSequenceOrder = newImages.map((r) => r.sequence)
    const previousImageIdOrder = newImages.map((r) => r.imageId)

    // Remove dragged image from array
    newImages.splice(source.index, 1)
    // Place dragged image in new location in array
    newImages.splice(destination.index, 0, draggedImage)

    const newRow = createReorderedRow(
      startRow,
      newImages,
      previousSequenceOrder,
      previousImageIdOrder
    )

    // Send changes to redux
    updateImageData({
      ...item,
      rows: {
        ...item.rows,
        [newRow.id]: newRow,
      },
    })
  }
  // Replace Primary Image
  if (finishRow.id === IMAGE_ROW_ID.PRIMARY) {
    // Duplicate startRow images
    const startRowImages = Array.from(startRow.images)
    // Find dragged image based on publishUrl (dragId)
    const newPrimaryImage = startRowImages.find(
      (item) => item.publishUrl === dragId
    )
    // Duplicate primary images
    const primaryImages = Array.from(finishRow.images)
    // If primary image row is empty to start with
    if (finishRow.images.length === 0) {
      // Remove dragged image from array, add current primary
      startRowImages.splice(source.index, 1)
      // Remove current primary image, add new primary image from array
      primaryImages.splice(0, 0, newPrimaryImage)

      if (startRow.id === IMAGE_ROW_ID.ALTERNATE) {
        // Finish row is primary, start row is alternate
        // Send changes to redux
        updateImageData({
          ...item,
          rows: {
            ...item.rows,
            [IMAGE_ROW_ID.ALTERNATE]: { ...startRow, images: startRowImages },
            [IMAGE_ROW_ID.PRIMARY]: createPrimaryRow(
              finishRow,
              primaryImages,
              null
            ),
          },
        })
      } else {
        // Finish row is primary, start row is disabled
        // Send changes to redux
        updateImageData({
          ...item,
          rows: {
            ...item.rows,
            [IMAGE_ROW_ID.DISABLED]: { ...startRow, images: startRowImages },
            [IMAGE_ROW_ID.PRIMARY]: createPrimaryRow(
              finishRow,
              primaryImages,
              null
            ),
          },
        })
      }
    } else if (startRow.id === IMAGE_ROW_ID.ALTERNATE) {
      // Finish row is primary, start row is alternate
      // Grab primary image
      const sourceImage = primaryImages[0]
      // Remove dragged image from array, add current primary
      startRowImages.splice(source.index, 1)
      startRowImages.splice(0, 0, sourceImage)
      // Remove current primary image, add new primary image from array
      primaryImages.splice(0, 1, newPrimaryImage)

      let destinationImage = first(primaryImages)
      const newPrimaryRow = createPrimaryRow(
        finishRow,
        primaryImages,
        sourceImage.sourceId
      )
      const newAlternateRow = moveFromPrimaryToAlt(
        startRow,
        startRowImages,
        destinationImage,
        sourceImage
      )
      // Send changes to redux
      updateImageData({
        ...item,
        rows: {
          ...item.rows,
          [IMAGE_ROW_ID.ALTERNATE]: newAlternateRow,
          [IMAGE_ROW_ID.PRIMARY]: newPrimaryRow,
        },
      })
    } else {
      // Finish row is primary, start row is disabled
      // Grab primary image
      const sourceImage = primaryImages[0]
      // Remove dragged image from array
      startRowImages.splice(source.index, 1)
      // Remove current primary image, add new primary image from array
      primaryImages.splice(0, 1, newPrimaryImage)
      // Create copy of alernate images
      const alternateRow = item.rows[IMAGE_ROW_ID.ALTERNATE]
      const alternateImages = Array.from(alternateRow.images)
      // Add current primary image to alternates row
      alternateImages.splice(0, 0, sourceImage)

      let destinationImage = first(primaryImages)
      const newPrimaryRow = createPrimaryRow(
        finishRow,
        primaryImages,
        sourceImage.sourceId
      )
      const newDisabledRow = createGenericRow(startRow, startRowImages)
      const newAlternateRow = moveFromPrimaryToAlt(
        alternateRow,
        alternateImages,
        destinationImage,
        sourceImage
      )

      // Send changes to redux
      updateImageData({
        ...item,
        rows: {
          ...item.rows,
          [IMAGE_ROW_ID.DISABLED]: newDisabledRow,
          [newPrimaryRow.id]: newPrimaryRow,
          [newAlternateRow.id]: newAlternateRow,
        },
      })
    }
  } else if (startRow !== finishRow) {
    // Move from Alternate row to disabled row or vice versa
    // Duplicate start images
    const startImages = Array.from(startRow.images)
    // Find dragged image based on publish_url (dragId)
    const draggedImage = startImages.find((item) => item.publishUrl === dragId)
    // Remove dragged image from array
    startImages.splice(source.index, 1)
    // Duplicate finish images
    const finishImages = Array.from(finishRow.images)

    const orderedPreDrag = finishImages
      .concat(draggedImage)
      .sort((a, b) => a.sequence - b.sequence)

    const previousSequenceOrder = orderedPreDrag.map((r) => r.sequence)
    const previousImageIdOrder = orderedPreDrag.map((r) => r.imageId)

    // Add dragged image from array
    finishImages.splice(destination.index, 0, draggedImage)

    const newStartRow = createGenericRow(startRow, startImages)
    const newFinishRow = createReorderedRow(
      finishRow,
      finishImages,
      previousSequenceOrder,
      previousImageIdOrder
    )

    // Send changes to redux
    updateImageData({
      ...item,
      rows: {
        ...item.rows,
        [newStartRow.id]: newStartRow,
        [newFinishRow.id]: newFinishRow,
      },
    })
  }
}

export const isValidCopyTarget = (item, copiedImage) => {
  if (copiedImage === null || copiedImage.viewType === IMAGE_VIEW_TYPE.SWATCH) {
    return false
  }

  const mappedCopy = imageMapping(copiedImage, '')
  return !flattenImagesForTcin(item, '').imageList.find((image) =>
    isEqual(image, mappedCopy)
  )
}

export const isValidSwatchCopyTarget = (item, copiedImage) => {
  if (copiedImage === null || copiedImage.viewType !== IMAGE_VIEW_TYPE.SWATCH) {
    return false
  }

  return (
    isSwatchEligible(item.relationshipTypeCode) &&
    !isEqual(item.swatchImage, copiedImage)
  )
}

function ImageData(props) {
  const {
    selectedImages = [],
    classes = {},
    data = [],
    originalData = [],
    page = 0,
    rowsPerPage = 0,
    emptyRows = 0,
    handleSelectAllClick = () => {},
    isSelected = () => {},
    handleClick = () => {},
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    totalElements = 0,
    auth = {},
    selectedFilters = [],
    sortDirection = 'ASC',
    sortField = 'TCIN',
    setSortDirection = () => {},
    setSortField = () => {},
    savePaginationDataEvent = () => {},
    pageName = 'ImageManage',
    updateImageData = () => {},
    resetImageData = () => {},
    publishImageData = () => {},
    copiedImage = null,
    setCopiedImage = () => {},
    pasteImage = () => {},
    toggleRelatedItemsModal = () => {},
    relatedItemsData = [],
    relatedTcin = '',
    showRelatedItemsModal = false,
    searchRelatedItems = () => {},
    manageTcins = () => {},
    openSyncItemsModal = () => {},
    itemToSyncFrom = {},
    itemToSyncTo = [],
    showSyncItemsModal = false,
    toggleSyncItemsModal = () => {},
    syncImages = () => {},
    saveSwatch = () => {},
    showEditSwatchModal = null,
    toggleEditSwatchModal = () => {},
    backToSearch = () => {},
    setZoom = () => {},
    toggleEditImageLabelDialogue = () => {},
    isEditImageLabelOpen = false,
    labelTcin,
    currentAddLabelImage,
    rowIndex,
    colIndex,
    getAssetLabel,
    savedLabelList,
    postAssetLabel,
  } = props

  let imageDataChanges = differenceWith(data, originalData, isEqual)
  let selectedTcins = !isNil(selectedImages)
    ? flattenDeep(selectedImages.map((item) => item.tcin))
    : []

  return (
    <div>
      <Paper className={classes.fullWidth}>
        <ImageDataContainerTitle
          numSelected={!isNil(selectedImages) ? selectedImages.length : 0}
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          resetImageData={resetImageData}
          publishImageData={publishImageData}
          imageDataChanges={imageDataChanges}
          auth={auth}
          sortDirection={sortDirection}
          sortField={sortField}
          selectedFilters={selectedFilters}
          setCopiedImage={setCopiedImage}
          copiedImage={copiedImage}
          backToSearch={backToSearch}
        />
        {data.length > 0 && (
          <React.Fragment>
            <div className={classes.manageSearchWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <ImageDataContainerHead
                  numSelected={selectedImages.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rowsPerPage}
                  imageDataLength={data.length}
                  selectedFilters={selectedFilters}
                  sortDirection={sortDirection}
                  sortField={sortField}
                  setSortDirection={setSortDirection}
                  setSortField={setSortField}
                  savePaginationDataEvent={savePaginationDataEvent}
                  pageName={pageName}
                />
                <TableBody>
                  {data.map((item, rowIndex) => {
                    // run this here so it doesn't get replicated for each image, just 1 time per
                    const validCopyTarget = isValidCopyTarget(item, copiedImage)
                    const findIsSelected = isSelected(item)

                    const onDragEnd = (result) => {
                      dndLogic(result, item, updateImageData)
                    }

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={findIsSelected}
                        tabIndex={-1}
                        key={item.tcin}
                        selected={findIsSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={findIsSelected}
                            color="primary"
                            onClick={(event) => handleClick(event, item)}
                          />
                        </TableCell>

                        <TableCell padding="default">
                          <ItemDetails
                            item={item}
                            tcin={item.tcin}
                            title={item.title}
                            launchDate={item.launchDate}
                            relationshipTypeCode={item.relationshipTypeCode}
                            searchRelatedItems={searchRelatedItems}
                            showRelatedIcon
                            openSyncItemsModal={openSyncItemsModal}
                            auth={auth}
                            showSyncIcon
                          />
                        </TableCell>
                        <TableCell padding="default">
                          {item.relationshipTypeCode || 'N/A'}
                        </TableCell>
                        <TableCell padding="default" data-test-id="swatch-cell">
                          <Swatch
                            item={item}
                            setCopiedImage={setCopiedImage}
                            isValidSwatchCopyTarget={isValidSwatchCopyTarget(
                              item,
                              copiedImage
                            )}
                            pasteImage={(type) => pasteImage(item, type)}
                            saveSwatch={saveSwatch}
                            toggleEditSwatchModal={toggleEditSwatchModal}
                            showEditSwatchModal={showEditSwatchModal}
                            auth={auth}
                          />
                        </TableCell>
                        <DragDropContext onDragEnd={onDragEnd}>
                          {item.rowOrder.map((rowId) => {
                            const row = item.rows[rowId]
                            return (
                              <ImageTableCells
                                setZoom={setZoom}
                                key={`${item.tcin}${rowId}`}
                                row={row}
                                auth={auth}
                                copiedImage={copiedImage}
                                setCopiedImage={setCopiedImage}
                                isValidCopyTarget={validCopyTarget}
                                pasteImage={(type) => pasteImage(item, type)}
                                toggleEditImageLabelDialogue={
                                  toggleEditImageLabelDialogue
                                }
                                isEditImageLabelOpen={isEditImageLabelOpen}
                                item={item}
                                rowIndex={rowIndex}
                                getAssetLabel={getAssetLabel}
                              />
                            )
                          })}
                        </DragDropContext>
                      </TableRow>
                    )
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={11} />
                    </TableRow>
                  )}
                  <TableRow className={classes.height49}>
                    <TableCell colSpan={11} />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <ImageToolbar
              numSelected={selectedImages.length}
              selectedTcins={selectedTcins}
              auth={auth}
            />
          </React.Fragment>
        )}
        {(isNil(data) || data.length === 0) && (
          <div className={classes.noResult}>
            <div>No Images to Maintain</div>
          </div>
        )}
      </Paper>
      <div className={classes.toolbarSpacer} />
      {showRelatedItemsModal && (
        <RelatedItemsModal
          open={showRelatedItemsModal}
          toggleRelatedItemsModal={toggleRelatedItemsModal}
          relatedItems={relatedItemsData}
          relatedTcin={relatedTcin}
          manageTcins={manageTcins}
        />
      )}
      {showSyncItemsModal && (
        <SyncImagesModal
          open={showSyncItemsModal}
          toggleSyncItemsModal={toggleSyncItemsModal}
          itemToSyncFrom={itemToSyncFrom}
          itemToSyncTo={itemToSyncTo}
          syncImages={syncImages}
          auth={auth}
        />
      )}
      <EditImageLabel
        toggleEditImageLabelDialogue={toggleEditImageLabelDialogue}
        isEditImageLabelOpen={isEditImageLabelOpen}
        labelTcin={labelTcin}
        currentAddLabelImage={currentAddLabelImage}
        rowIndex={rowIndex}
        colIndex={colIndex}
        savedLabelList={savedLabelList}
        postAssetLabel={postAssetLabel}
        auth={auth}
      />
    </div>
  )
}

// @ts-ignore
export default withStyles(styles)(ImageData)
