import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  // Tooltip,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

function ImageDataContainerHead(props) {
  const {
    onSelectAllClick = () => {},
    numSelected = 0,
    rowCount = 0,
    classes = {},
    imageDataLength = 0,
    // setSortDirection = () => {},
    // setSortField = () => {},
    // savePaginationDataEvent = () => {},
    // requestImageManageData = () => {},
    // selectedFilters = [],
    // sortField = 'tcin',
    // sortDirection = 'ASC',
    // pageName = 'ImageManage',
  } = props

  // As of 1/7/2021, commenting out sorting as it doesn't provide value
  // on this page: this data should come from the Search page, where we
  // will handle sorting (DanFed)

  // function handleSetSortDirection (sortDirection) {
  //   if (sortDirection === 'ASC') {
  //     return 'DESC'
  //   }
  //   if (sortDirection === 'DESC') {
  //     return 'ASC'
  //   }
  // }

  // function sortColumn (fieldToSort, selectedFilters, rowCount, sortDirection, pageName) {
  //   setSortDirection(handleSetSortDirection(sortDirection))
  //   setSortField(fieldToSort)
  //   savePaginationDataEvent({ pageSize: rowCount, sortOrder: handleSetSortDirection(sortDirection), sortField: fieldToSort, pageName: pageName })
  //   requestImageManageData(selectedFilters, 0, rowCount, handleSetSortDirection(sortDirection), fieldToSort)
  // }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" align="left">
          <Checkbox
            indeterminate={
              numSelected > 0 &&
              (numSelected < rowCount || numSelected < imageDataLength)
            }
            checked={
              imageDataLength > 0 &&
              (numSelected === rowCount || numSelected === imageDataLength)
            }
            onChange={onSelectAllClick}
            color="primary"
          />
        </TableCell>
        <TableCell padding="default" align="left" className={classes.itemData}>
          {/* <Tooltip title="Click to sort">
          <span
            className={classes.sort}
            role="presentation"
            onClick={() => {
              sortColumn(
                'TCIN',
                selectedFilters,
                rowCount,
                sortDirection,
                pageName,
              )
            }}
          > */}
          Item Details
          {/* {sortField === 'TCIN' && sortDirection === 'DESC' && (<KeyboardArrowDownIcon />)}
              {sortField === 'TCIN' && sortDirection === 'ASC' && (<KeyboardArrowUpIcon />)}
          </span>
          </Tooltip> */}
        </TableCell>
        <TableCell padding="default" align="left">
          Rel. Type Code
        </TableCell>
        <TableCell padding="default" align="left">
          Swatch
        </TableCell>
        <TableCell padding="default" align="left">
          Primary
        </TableCell>
        <TableCell padding="default" align="left">
          Alternates
        </TableCell>
        <TableCell padding="default" align="left">
          Disabled
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

// @ts-ignore
export default withStyles(styles)(ImageDataContainerHead)
