const styles = (theme) => ({
  spacer: {
    marginTop: '35px',
  },
  itemsCountContainer: {
    marginLeft: '10px',
  },
  divider: {
    padding: '55px 20px 30px 20px',
    fontWeight: 800,
    fontSize: '0.975rem',
    '@media (max-width: 870px)': {
      padding: 0,
    },
  },
  bulkUploadTopCard: {
    padding: '30px 0px',
    '@media (max-width: 870px)': {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  browseButton: {
    color: 'rgba(76, 144, 254, 1)',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  dropZoneNotActive: {
    margin: 'auto',
    width: '350px',
    border: '1px black',
    padding: '0px 20px 25px 20px',
    backgroundColor: '#F0F2F8',
    height: '134px',
  },
  itemSearchContainer: {
    margin: 'auto',
    width: '350px',
    border: '1px black',
    padding: '20px',
    backgroundColor: '#F0F2F8',
  },
  dropZoneActive: {
    backgroundColor: 'rgba(173, 216, 230, 0.5)',
    margin: 'auto',
    width: '350px',
    border: 'dashed 1px rgba(33,150,243, 1)',
    padding: '0px 20px 25px 20px',
  },
  uploadIcon: {
    width: '75px',
    height: '75px',
  },
  DropZoneLargeText: {
    marginBottom: '0px !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '20px',
    color: '#999999',
    textAlign: 'center',
  },
  DropZoneSmallText: {
    marginTop: '10px !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '200',
    fontStyle: 'normal',
    fontSize: '14px',
    color: '#999999',
    textAlign: 'center',
  },
  progressBar: {
    marginTop: '30px',
    marginLeft: '50%',
  },
  secondaryButton: {
    margin: '10px 10px 0 10px',
    padding: '4px 30px',
  },
  primaryButton: {
    marginLeft: '10px',
    marginTop: '10px',
    padding: '5px 30px',
  },
  itemImage: {
    maxWidth: '65px',
    maxHeight: '65px',
  },
  revertBack: {
    height: '50px',
    width: '50px',
    cursor: 'pointer',
  },
  margin10: {
    margin: '10px',
  },
  marginAuto: {
    margin: 'auto',
  },
  noCopy: {
    margin: '10px',
    height: '50px',
  },
  noBullets: {
    height: '50px',
  },
  deleteButton: {
    cursor: 'pointer',
  },
  wrongFileTitle: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 1)',
  },
  editAltText: {
    maxWidth: '300px',
    overflowWrap: 'break-word',
    position: 'relative',
  },
  clickToEditContainer: {
    margin: '10px',
    marginBottom: '30px',
    minHeight: '62px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
  editFeatureBullets: {
    maxWidth: '200px',
  },
  tcinSearch: {
    overflowY: 'auto',
    height: '150px',
  },
  tableWrapper: {
    overflowX: 'auto',
    minWidth: '1020px',
    width: '100%',
    marginTop: '5px',
  },
  noResult: {
    textAlign: 'center',
    padding: '20px',
  },
  marginTopBottom5: {
    margin: '5px 0',
  },
  downloadDataButton: {
    marginRight: 0,
    width: '190px',
    padding: '4px',
  },
  revertBackCell: {
    maxWidth: '80px',
    paddingLeft: '30px',
    paddingRight: '20px',
  },
  defaultCursor: {
    cursor: 'default',
  },
  loneColour: {
    display: 'block',
    height: '20px',
    backgroundColor: '#eeee00',
  },
  ltwoColour: {
    display: 'block',
    height: '20px',
    backgroundColor: '#ffa500',
  },
  profaneHeading: {
    fontSize: '12px',
    textAlign: 'left',
    display: 'block',
    fontWeight: 'bold',
  },
  profaneWrap: {
    display: 'inline-Block',
    marginRight: '10px',
  },
  profaneMainWrap: {
    textAlign: 'right',
    marginRight: '35px',
  },
  ltwoDisclaimer: {
    color: 'white',
    position: 'absolute',
    bottom: '5px',
    left: '39%',
    backgroundColor: '#ffa500',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  altTextArea: {
    width: '400px',
    margin: '20px',
    marginBottom: '30px',
  },
  tcinTextArea: {
    width: '100px',
  },
  zoomin: {
    cursor: 'pointer',
  },
  infoAlert: {
    backgroundColor: '#e8f4fd',
    padding: '8px 16px',
    margin: '20px 10px',
    color: '#0d3c61',
    borderRadius: '4px',
    fontSize: '0.875rem',
  },
  infoAlertIcon: {
    verticalAlign: 'middle',
    marginRight: '5px',
    color: '#2196f3',
    fontSize: '22px',
  },
})

export default styles
