import React from 'react'
import HeaderTitle from '../../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import CreateNewTagPageContainer from './Components/CreateNewTagPage/CreateNewTagPageContainer'

class CreateTag extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <React.Fragment>
        <HeaderTitle title="Tags" />
        <Helmet title="Tags" />
        <CreateNewTagPageContainer />
      </React.Fragment>
    )
  }
}

export default CreateTag
