const styles = (theme) => ({
  header: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  whiteColor: {
    color: 'white',
  },
  whiteIcon: {
    color: 'white',
  },
  contentContainer: {
    padding: theme.spacing(), // Default <Grid container> spacing is 16. Use 8px padding for bug in material-ui Grid. See https://github.com/callemall/material-ui/issues/7466#issuecomment-316356427
  },
  sideNavList: {
    backgroundColor: '#424242',
  },
  iconSmall: {
    height: '10px',
  },
  iconSize: {
    width: '24px',
    height: '24px',
  },
  link: {
    display: 'flex',
    padding: '10px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  activeLink: {
    backgroundColor: '#bdbdbd',
  },
})

export default styles
