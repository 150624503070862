import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Card,
  CardContent,
} from '@material-ui/core'
import HelpDrawerContainer from '../HelpDrawer/HelpDrawerContainer'
import { Field, reduxForm } from 'redux-form'
import DeleteIcon from '@material-ui/icons/Delete'
import { connect } from 'react-redux'

/* eslint-disable */
const renderTextField = ({
  input,
  label,
  value,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    fullWidth
    multiline
    rowsMax="3"
    autoFocus
    margin="normal"
    {...input}
    {...custom}
  />
)
/* eslint-enable */
let StageCorona = (props) => {
  const {
    classes,
    helpActionDrawer,
    resetData,
    pristine,
    imageDataCorona,
    publishData,
    previewData,
    isFetchingCorona,
    removeItem,
  } = props
  return (
    <Card>
      <CardContent>
        <Grid container className={classes.marginTop20}>
          <Grid item xs={6} container direction="row">
            <Grid item>
              <Field
                id="stageTcinList"
                name="stageTcinList"
                component={renderTextField}
                placeholder="enter a TCIN or list of TCINs and Preview the results"
                disabled={imageDataCorona.length > 0}
                className={classes.textFieldWidth}
              />
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              className={classes.resetButton}
              disabled={pristine && imageDataCorona.length <= 0}
              onClick={resetData}
            >
              Reset
            </Button>
            {imageDataCorona.length > 0 ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.previewPublishButton}
                onClick={publishData}
              >
                Publish
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.previewPublishButton}
                disabled={pristine}
                onClick={previewData}
              >
                Preview
              </Button>
            )}
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Button
              onClick={helpActionDrawer}
              className={classes.aboutPageButton}
              variant="outlined"
              color="primary"
            >
              WHAT IS THIS PAGE?
            </Button>
          </Grid>
        </Grid>
        <React.Fragment>
          {isFetchingCorona && <CircularProgress size={25} color="secondary" />}
          {imageDataCorona.length > 0 && (
            <div className={classes.table}>
              <Table aria-labelledby="tableTitle">
                <TableBody>
                  {imageDataCorona.map((n, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className={classes.width100}>
                          <img
                            src={n.primary_image_url}
                            alt={n.tcin}
                            className={classes.stageImage}
                          />
                        </TableCell>
                        <TableCell className={classes.width100}>
                          {n.tcin}
                        </TableCell>
                        <TableCell className={classes.width10}>
                          <DeleteIcon
                            onClick={() => removeItem(n.tcin)}
                            className={classes.pointerCursor}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          )}
        </React.Fragment>
        <HelpDrawerContainer />
      </CardContent>
    </Card>
  )
}
StageCorona = reduxForm({ form: 'stageCoronaForm' })(StageCorona)
StageCorona = connect((state) => ({
  initialValues: {
    stageTcinList: '',
  },
}))(StageCorona)
export default withStyles(styles)(StageCorona)
