import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  Grid,
  Toolbar,
  Typography,
  TablePagination,
  Tooltip,
  TableHead,
  TableSortLabel,
  Button,
} from '@material-ui/core'
// @ts-ignore
import NoThumbnail from '../../../../../images/NoThumbnail.svg?url'
import ChipInput from 'material-ui-chip-input'
import { mapToArray, splitTcins } from '../../../../Shared/SharedUtils'
import { uniqBy } from 'lodash'
import {
  VIDEO_APPROVAL_STATUS,
  VIDEO_EDIT_MODE,
} from '../../../../Shared/Constants'
import TableCellCollapsible from '../../../../Shared/TableCellCollapsible/TableCellCollapsible'

function TcinCardTable(props) {
  const {
    selectedTcins = [],
    classes = {},
    handleSelectAllClick = () => {},
    isSelected = () => {},
    handleClick = () => {},
    requestedVideoData = {},
    addTcinToVideoGroup = () => {},
    editMode = VIDEO_EDIT_MODE.UPLOAD,
    // isFetchingAuth = false,
  } = props

  const [state, setState] = React.useState({
    collapsedRow: null,
  })

  const handleExpansion = (index) => {
    if (state.collapsedRow === index) {
      setState({ ...state, collapsedRow: null })
    } else setState({ ...state, collapsedRow: index })
  }

  const formatDate = (date) => {
    let newDate = new Date(date)
    let month = newDate.getMonth() + 1
    let day = newDate.getDate()
    let year = newDate.getFullYear()
    let hours = newDate.getHours()
    let minutes = newDate.getMinutes()
    let amOrpm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours !== 0 ? hours : 12
    // @ts-ignore
    minutes = minutes < 10 ? '0' + minutes : minutes
    var time = hours + ':' + minutes + ' ' + amOrpm
    let formattedDate = month + '/' + day + '/' + year + ' ' + time
    return formattedDate
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const tcinData = requestedVideoData
    ? requestedVideoData.lst_group_item_data
    : []
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('tcin')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [showTcinChipEntry, setShowTcinChipEntry] = useState(false)
  const [newTcins, addNewTcins] = useState([])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleAddTcin = (selectedTcins) => {
    let chips = []
    splitTcins(selectedTcins).forEach((i) => {
      chips.push({
        value: i,
        display: i,
      })
    })
    newTcins.forEach((item) => {
      chips.push(item)
    })

    addNewTcins(uniqBy(chips, 'value'))
  }

  const handleDeleteTcin = (deletedTcin) => {
    let chips = []
    newTcins.forEach((item) => {
      if (item.value !== deletedTcin) {
        chips.push(item)
      }
    })
    addNewTcins(chips)
  }

  let numSelected = selectedTcins.length
  let totalElements = tcinData.length
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalElements - page * rowsPerPage)
  const existingTcins = tcinData.map((tcin) => tcin)
  const videoApprovedOrInprocess =
    requestedVideoData !== null &&
    (requestedVideoData.approval_status === VIDEO_APPROVAL_STATUS.APPROVED ||
      requestedVideoData.approval_status === VIDEO_APPROVAL_STATUS.IN_PROCESS)

  return (
    <Paper className={classes.fullWidth}>
      <Toolbar>
        <Grid justify="space-between" container spacing={3} alignItems="center">
          <Grid item>
            <div className={classes.title}>
              <Typography variant="subtitle1" id="selectedTcins">
                {numSelected} / {totalElements} TCIN(s) selected
              </Typography>
              {numSelected < 1 && (
                <Typography
                  variant="caption"
                  className={classes.selectedTcinsWarning}
                  id="selectedTcinsWarning"
                >
                  At least 1 TCIN required
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item className={classes.newTcins}>
            {!showTcinChipEntry ? (
              <Button
                onClick={() => setShowTcinChipEntry(true)}
                variant="contained"
                color="primary"
                disabled={
                  videoApprovedOrInprocess &&
                  editMode === VIDEO_EDIT_MODE.HISTORY
                }
                className={classes.normaliseText} // || isFetchingAuth
              >
                ADD TCIN(s)
              </Button>
            ) : (
              <React.Fragment>
                <div
                  className={
                    newTcins.length > 4
                      ? classes.tcinList
                      : classes.marginTopBottom20
                  }
                >
                  <ChipInput
                    value={mapToArray(newTcins)}
                    fullWidth
                    fullWidthInput
                    blurBehavior="add"
                    onAdd={(chips) => handleAddTcin(chips)}
                    onDelete={(deletedChip) => handleDeleteTcin(deletedChip)}
                    // @ts-ignore
                    className={classes.formControl}
                    helperText="Please provide list of TCINs"
                  />
                </div>
                <Button
                  disabled={newTcins.length < 1}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => {
                    addTcinToVideoGroup(newTcins, existingTcins)
                    setShowTcinChipEntry(false)
                    addNewTcins([])
                  }}
                >
                  Add
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setShowTcinChipEntry(false)
                    addNewTcins([])
                  }}
                >
                  Cancel
                </Button>
              </React.Fragment>
            )}
          </Grid>
          <Grid item>
            <Grid>
              <TablePagination
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                rowsPerPageOptions={[5, 10, 25, 100]}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < totalElements}
                  checked={
                    numSelected > 0 &&
                    (numSelected === rowsPerPage ||
                      numSelected === totalElements)
                  }
                  onChange={handleSelectAllClick}
                  color="primary"
                  disabled={
                    (videoApprovedOrInprocess &&
                      editMode === VIDEO_EDIT_MODE.HISTORY) ||
                    totalElements <= 0
                  }
                />
              </TableCell>
              <TableCell
                // @ts-ignore
                sortDirection={orderBy === 'tcin' ? order : false}
              >
                <Tooltip title="Click to sort">
                  <TableSortLabel
                    active={orderBy === 'tcin'}
                    // @ts-ignore
                    direction={orderBy === 'tcin' ? order : 'asc'}
                    onClick={(e) => handleRequestSort(e, 'tcin')}
                  >
                    Applied TCIN(s)
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>Child TCIN(s)</TableCell>
              <TableCell>Primary Image</TableCell>
              <TableCell
                // @ts-ignore
                sortDirection={orderBy === 'product_title' ? order : false}
              >
                <Tooltip title="Click to sort">
                  <TableSortLabel
                    active={orderBy === 'product_title'}
                    // @ts-ignore
                    direction={orderBy === 'product_title' ? order : 'asc'}
                    onClick={(e) => handleRequestSort(e, 'product_title')}
                  >
                    Product Title
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                // @ts-ignore
                sortDirection={orderBy === 'launch_date' ? order : false}
              >
                <Tooltip title="Click to sort">
                  <TableSortLabel
                    active={orderBy === 'launch_date'}
                    // @ts-ignore
                    direction={orderBy === 'launch_date' ? order : 'asc'}
                    onClick={(e) => handleRequestSort(e, 'launch_date')}
                  >
                    Launch Date
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(tcinData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tcin, index) => {
                const findIsSelected = isSelected(tcin)
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={findIsSelected}
                    tabIndex={-1}
                    key={tcin.tcin}
                    selected={findIsSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={findIsSelected}
                        color="primary"
                        onClick={(event) => handleClick(event, tcin)}
                        disabled={
                          videoApprovedOrInprocess &&
                          editMode === VIDEO_EDIT_MODE.HISTORY
                        }
                      />
                    </TableCell>
                    <TableCell>{tcin.tcin}</TableCell>
                    <TableCell>
                      <TableCellCollapsible
                        list={tcin.child_tcins}
                        index={index}
                        collapsedRow={state.collapsedRow}
                        handleExpansion={handleExpansion}
                      />
                    </TableCell>
                    <TableCell>
                      <img
                        src={
                          tcin.primary_image_url
                            ? tcin.primary_image_url
                            : NoThumbnail
                        }
                        alt={tcin.product_title}
                        // @ts-ignore
                        onError={(e) => {
                          e.target.src = NoThumbnail
                        }}
                        className={classes.tcinTableImage}
                      />
                    </TableCell>
                    <TableCell className={classes.maxWidth350}>
                      {tcin.product_title}
                    </TableCell>
                    <TableCell>
                      {tcin.launch_date ? formatDate(tcin.launch_date) : ' - '}
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={11} />
              </TableRow>
            )}
            <TableRow className={classes.height49}>
              <TableCell colSpan={11} />
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

// @ts-ignore
export default withStyles(styles)(TcinCardTable)
