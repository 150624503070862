import React from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
  Paper,
  CircularProgress,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import {
  Pageview,
  Build,
  Block,
  Done,
  Cached,
  SpeakerNotes,
  Warning,
} from '@material-ui/icons'
import Moment from 'react-moment'
import envConfigs from '../../../../config/apiConfig'
// @ts-ignore
import NoThumbnail from '../../../../images/NoThumbnail.svg?url'
import { isExternalUser, isValidVideoStatus } from '../../../Shared/SharedUtils'
import { startsWith } from 'lodash'
import { urlProtocol, VIDEO_APPROVAL_STATUS } from '../../../Shared/Constants'
import TableCellCollapsible from '../../../Shared/TableCellCollapsible/TableCellCollapsible'

const columnData = [
  { id: 'Actions', numeric: false, disablePadding: false, label: 'Actions' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
  {
    id: 'Poster Frame',
    numeric: false,
    disablePadding: false,
    label: 'Poster Frame',
  },
  {
    id: 'Video Title',
    numeric: false,
    disablePadding: false,
    label: 'Video Title',
  },
  {
    id: 'Applied TCIN(s)',
    numeric: false,
    disablePadding: false,
    label: 'Applied TCIN(s)',
  },
  {
    id: 'Duplicate TCIN(s)',
    numeric: false,
    disablePadding: false,
    label: 'Duplicate TCIN(s)',
  },
  {
    id: 'Upload Date',
    numeric: false,
    disablePadding: false,
    label: 'Upload Date',
  },
]

function escapeHtml(input) {
  return { __html: input }
}

const calendarStrings = {
  lastDay: '[Yesterday at] LT',
  sameDay: '[Today at] LT',
  nextDay: '[Tomorrow at] LT',
  lastWeek: '[Last] dddd [at] LT',
  nextWeek: 'dddd [at] LT',
  sameElse: 'MM-DD-YYYY [at] LT',
}

const generatingPreview =
  envConfigs.api.sceneSevenBaseUrl + 'generating_preview'
const setWidHeight = '?hei=75&wid=133'

function HistoryPage(props) {
  const {
    videoHistoryData = [],
    classes = {},
    totalElements = 0,
    rowsPerPage = 10,
    page = 0,
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    emptyRows = 0,
    auth = {},
    requestVideoDataById = () => {},
  } = props

  const [state, setState] = React.useState({
    collapsedRow: null,
  })

  const handleExpansion = (index) => {
    if (state.collapsedRow === index) {
      setState({ ...state, collapsedRow: null })
    } else setState({ ...state, collapsedRow: index })
  }

  return (
    <React.Fragment>
      {videoHistoryData ? (
        <React.Fragment>
          {videoHistoryData.length > 0 ? (
            <div>
              <Paper className={classes.fullWidth}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.marginTop20}
                >
                  <Grid item xs={6}>
                    <div className={classes.totalHistoryData}>
                      {rowsPerPage < totalElements
                        ? rowsPerPage
                        : totalElements}{' '}
                      out of {totalElements}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <TablePagination
                      component="div"
                      count={totalElements}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      backIconButtonProps={{
                        'aria-label': 'Previous Page',
                      }}
                      nextIconButtonProps={{
                        'aria-label': 'Next Page',
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Grid>
                </Grid>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      {columnData.map((column) => {
                        return (
                          <TableCell
                            key={column.id}
                            padding={column.disablePadding ? 'none' : 'default'}
                          >
                            {column.label}
                          </TableCell>
                        )
                      }, this)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {videoHistoryData.map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {(data.approval_status ===
                              VIDEO_APPROVAL_STATUS.APPROVED ||
                              data.approval_status ===
                                VIDEO_APPROVAL_STATUS.IN_PROCESS) && (
                              <Grid
                                container
                                direction="row"
                                className={classes.openIcon}
                                alignItems="center"
                              >
                                <Pageview className={classes.marginRight10} />
                                <span
                                  role="presentation"
                                  onClick={() =>
                                    requestVideoDataById(
                                      data.external_group_job_id
                                    )
                                  }
                                  className={classes.pointer}
                                >
                                  View Info
                                </span>
                              </Grid>
                            )}
                            {data.approval_status ===
                              VIDEO_APPROVAL_STATUS.REJECTED && (
                              <Grid
                                container
                                direction="row"
                                className={classes.openIcon}
                                alignItems="center"
                              >
                                <Build className={classes.marginRight10} />
                                <span
                                  role="presentation"
                                  onClick={() =>
                                    requestVideoDataById(
                                      data.external_group_job_id
                                    )
                                  }
                                  className={classes.pointer}
                                >
                                  Fix
                                </span>
                              </Grid>
                            )}
                            {data.approval_status !==
                              VIDEO_APPROVAL_STATUS.REJECTED &&
                              data.approval_status !==
                                VIDEO_APPROVAL_STATUS.APPROVED &&
                              data.approval_status !==
                                VIDEO_APPROVAL_STATUS.IN_PROCESS && (
                                <Grid
                                  container
                                  direction="row"
                                  className={classes.blockedIcon}
                                  alignItems="center"
                                >
                                  <Block />
                                </Grid>
                              )}
                          </TableCell>
                          <TableCell>
                            {data.approval_status ===
                              VIDEO_APPROVAL_STATUS.APPROVED && (
                              <Grid
                                container
                                direction="row"
                                style={{ color: data.user_status_color }}
                                alignItems="center"
                              >
                                <Done className={classes.marginRight10} />
                                <span>Approved</span>
                              </Grid>
                            )}
                            {(data.approval_status ===
                              VIDEO_APPROVAL_STATUS.PENDING ||
                              data.approval_status ===
                                VIDEO_APPROVAL_STATUS.RETRY ||
                              !data.approval_status) && (
                              <Grid
                                container
                                direction="row"
                                style={{ color: data.user_status_color }}
                                alignItems="center"
                              >
                                <Cached className={classes.marginRight10} />
                                <span>Processing...</span>
                              </Grid>
                            )}
                            {data.approval_status ===
                              VIDEO_APPROVAL_STATUS.IN_PROCESS && (
                              <Grid
                                container
                                direction="row"
                                style={{ color: data.user_status_color }}
                                alignItems="center"
                              >
                                <SpeakerNotes
                                  className={classes.marginRight10}
                                />
                                <span>Ready for Review</span>
                              </Grid>
                            )}
                            {data.approval_status ===
                              VIDEO_APPROVAL_STATUS.REJECTED && (
                              <Grid
                                container
                                direction="row"
                                style={{ color: data.user_status_color }}
                                alignItems="center"
                              >
                                <Warning className={classes.marginRight10} />
                                <span>
                                  {isExternalUser(auth)
                                    ? data.external_user_status_message
                                    : data.internal_user_status_message}
                                </span>
                              </Grid>
                            )}

                            {!isValidVideoStatus(data?.approval_status) && (
                              <Grid
                                container
                                direction="row"
                                style={{
                                  color: data.user_status_color,
                                  display: 'inline-block',
                                }}
                                alignItems="center"
                              >
                                <div>
                                  <Cached className={classes.marginRight10} />
                                  <span>
                                    {data?.error_message ||
                                      'Error: Please Notify Support'}
                                  </span>
                                </div>
                                <div></div>
                              </Grid>
                            )}
                          </TableCell>
                          <TableCell>
                            {!data.poster_frame_url ||
                            data.poster_frame_url === null ? (
                              <img
                                src={generatingPreview + setWidHeight}
                                alt={data.tcin}
                                // @ts-ignore
                                onError={(e) => {
                                  e.target.src = NoThumbnail
                                }}
                                className={classes.imageThumbnail}
                              />
                            ) : (
                              <img
                                src={
                                  startsWith(data.poster_frame_url, urlProtocol)
                                    ? data.poster_frame_url + setWidHeight
                                    : urlProtocol +
                                      data.poster_frame_url +
                                      setWidHeight
                                }
                                alt={data.tcin}
                                // @ts-ignore
                                onError={(e) => {
                                  e.target.src = NoThumbnail
                                }}
                                className={classes.imageThumbnail}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <div
                              dangerouslySetInnerHTML={escapeHtml(data.title)}
                            />
                          </TableCell>
                          <TableCell>
                            <TableCellCollapsible
                              list={data.applied_tcins_set}
                              index={index}
                              collapsedRow={state.collapsedRow}
                              handleExpansion={handleExpansion}
                            />
                          </TableCell>
                          <TableCell>
                            <TableCellCollapsible
                              list={data?.list_of_duplicate_tcin}
                              index={index}
                              collapsedRow={state.collapsedRow}
                              handleExpansion={handleExpansion}
                            />
                          </TableCell>
                          <TableCell>
                            {data.create_time && data.create_time ? (
                              <Moment calendar={calendarStrings}>
                                {data.create_time}
                              </Moment>
                            ) : (
                              'null'
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={10} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </div>
          ) : (
            <div className={classes.noResult}>
              You haven't uploaded any video yet!
            </div>
          )}
        </React.Fragment>
      ) : (
        <CircularProgress className={classes.progressBar} />
      )}
    </React.Fragment>
  )
}

// @ts-ignore
export default withStyles(styles)(HistoryPage)
