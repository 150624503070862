const FilterData = [
  /* {
    display: 'Division',
    value: 'division',
  },
  {
    display: 'Dept-Class',
    value: 'dept-class',
  },
  {
    display: 'TCIN',
    value: 'tcin',
  },
  {
    display: 'Brand Type',
    value: 'brand_type',
  },
  {
    display: 'Brand',
    value: 'brand',
  }, */
  {
    display: 'Copy Writing Status',
    value: 'eventType',
  },
  {
    display: 'Department',
    value: 'department',
  },
  {
    display: 'Division',
    value: 'division',
  },
  {
    display: 'Notes',
    value: 'notes',
  },
  {
    display: 'Routed Team',
    value: 'routedTeams',
  },
  {
    display: 'TCINs',
    value: 'tcin',
  },
  /* {
    display: 'Routed Team',
    value: 'routedTeam',
  },
  {
    display: 'Launch Date',
    value: 'launch_date',
  }, */
]

const CopyWritingStatus = [
  {
    display: 'Ready For Copy',
    value: 'ReadyForCopy',
    color: 'rgba(195, 224, 246, 1)',
    isVisible: true,
  },
  {
    // Need to get colour
    display: 'Routed To Team',
    value: 'RoutedToGroup',
    color: 'rgba(195, 224, 246, 1)',
    isVisible: true,
  },
  {
    display: 'New Write Request',
    value: 'NewWriteRequest',
    color: 'rgba(254, 222, 196, 1)',
    isVisible: true,
  },
  {
    display: 'Writing Started',
    value: 'WritingStarted',
    color: 'rgba(255, 239, 197, 1)',
    isVisible: true,
  },
  {
    display: 'Ready For QA',
    value: 'ReadyForQA',
    color: 'rgba(239, 250, 218, 1)',
    isVisible: true,
  },
  {
    // Need to get colour
    display: 'Done',
    value: 'Done',
    color: 'rgba(254, 222, 196, 1)',
    isVisible: true,
  },
  {
    // Need to get colour
    display: 'Write To Association',
    value: 'WriteToAssociation',
    color: 'rgba(254, 222, 196, 1)',
    isVisible: false,
  },
  {
    // Need to get colour
    display: 'Send To ItemService',
    value: 'SendToItemService',
    color: 'rgba(254, 222, 196, 1)',
    isVisible: false,
  },
  {
    // Need to get colour
    display: 'Not Ready for Copy',
    value: 'NotReadyForCopy',
    color: 'rgba(192, 192, 192, 1)',
    isVisible: false,
  },
]

const RoutedTeams = [
  {
    display: 'Periscope',
    value: 'Periscope',
  },
  {
    display: 'Target India',
    value: 'TargetIndia',
  },
  {
    display: 'None(vendor will provide)',
    value: 'None',
  },
]

const NoteOptions = [
  {
    display: 'Show Items With Notes',
    value: 'showItemsWithNotes',
  },
  {
    display: 'Show Items Without Notes',
    value: 'showItemsWithoutNotes',
  },
]

export { FilterData, CopyWritingStatus, RoutedTeams, NoteOptions }
