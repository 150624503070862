import React from 'react'
import { Card, Grid, CardContent, Button, Divider } from '@material-ui/core'
import '../../../../styles/shared.css'
import styles from '../../Styles/theme'
import { withStyles } from '@material-ui/core/styles'

function ItemData(props) {
  const {
    itemMtaData,
    productClassification,
    merchandiseClassification,
    classes,
  } = props
  const [state, setState] = React.useState({
    showMtaData: false,
  })

  const mtaData =
    itemMtaData &&
    itemMtaData.map((data) => {
      return (
        <tr key={data.id} className={classes.verticalAlignTop}>
          <td className={classes.tableMtaIds}>
            <b>{data.id} - </b>
          </td>
          <td className={classes.textAlignLeft}>
            <b>{data.name}:</b>{' '}
            {data.values.map((value) => value.name).join(', ')}
          </td>
        </tr>
      )
    })

  const prodClassData =
    productClassification &&
    Object.entries(productClassification).map(([key, value]) => {
      return (
        <div key={key}>
          <b>{key}: </b>
          {typeof value === 'object'
            ? Object.entries(value).map(([key, value]) => {
                return (
                  <div className={classes.paddingLeft20} key={key}>
                    <b>{key}: </b>
                    {value}
                  </div>
                )
              })
            : value}
        </div>
      )
    })

  const merchClassData =
    merchandiseClassification &&
    Object.entries(merchandiseClassification).map(([key, value]) => {
      return (
        <div key={key}>
          <b>{key}: </b>
          {value}
        </div>
      )
    })

  return (
    <Grid item>
      <Card className={classes.sideBarDetailWrapper}>
        <CardContent>
          <div>
            <Button
              className={classes.prodSpecButton}
              variant={!state.showMtaData ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setState({ showMtaData: false })}
            >
              CLASSIFICATIONS
            </Button>
            <Button
              className={classes.prodSpecButton}
              variant={state.showMtaData ? 'contained' : 'outlined'}
              color="primary"
              onClick={() =>
                setState(
                  state.showMtaData
                    ? { showMtaData: true }
                    : { showMtaData: !state.showMtaData }
                )
              }
            >
              MTA DATA
            </Button>
          </div>
          <Divider />
          <div className={classes.sideBarDetailContainer}>
            {state.showMtaData ? (
              <div>
                <table className={classes.width100Percent}>
                  <tr>
                    <th colSpan={2} className={classes.mtaDetailHeader}>
                      MTA Data
                    </th>
                  </tr>
                  {mtaData}
                </table>
              </div>
            ) : (
              <div>
                <div className={classes.productDetailHeader}>
                  Merchandise Classification
                </div>
                <div>{merchClassData}</div>
                <div className={classes.productDetailHeader}>
                  Product Classification
                </div>
                <div>{prodClassData}</div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(ItemData)
