import { withStyles } from '@material-ui/core/styles'
import styles from '../theme'
import { Grid, Typography, FormControl, Button } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import {
  LIMIT_TCIN_SEARCH_EXPAND,
  DISABLE_ADD_BUTTON,
} from '../../../Shared/Constants'

function AssetSearch(props) {
  const {
    classes,
    handleSearchForLongCopyAndFeatureBullets,
    searchedItems,
    handleAddTcin,
    handleDeleteTcin,
  } = props
  return (
    <div className={classes.itemSearchContainer}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start test"
      >
        <Grid item>
          <Typography
            variant="button"
            gutterBottom
            className={classes.DropZoneLargeText}
          >
            Item Search
          </Typography>
        </Grid>
      </Grid>
      <div
        className={
          searchedItems.length > LIMIT_TCIN_SEARCH_EXPAND
            ? classes.tcinSearch
            : null
        }
      >
        <ChipInput
          value={searchedItems}
          fullWidth
          fullWidthInput
          blurBehavior="add"
          onAdd={(chips) => handleAddTcin(chips)}
          onDelete={(deletedChip) => handleDeleteTcin(deletedChip)}
          placeholder="Enter one or more TCIN(s) to search"
          data-testid="chipInputOps"
        />
      </div>
      <FormControl>
        <Button
          disabled={searchedItems.length < DISABLE_ADD_BUTTON}
          className={classes.primaryButton}
          onClick={() => handleSearchForLongCopyAndFeatureBullets()}
          color="primary"
          variant="contained"
          data-testid="AddButton"
        >
          ADD
        </Button>
      </FormControl>
    </div>
  )
}
export default withStyles(styles)(AssetSearch)
