import { useEffect, useState } from 'react'
import { AppBar, Typography, Tabs, Tab, Toolbar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../Shared/WithRouterHoc'
import styles from './theme'
import envConfigs from '../../config/apiConfig'
import { isExternalUrl } from '../../components/Layout/Layout'

function AlttextHeader(props) {
  useEffect(() => {
    var currentTab = 'Alt Text Search'

    switch (props.router.location.pathname) {
      case '/v2/altText': {
        currentTab = 'Search'
        break
      }
      case '/v2/altText/bulkUpload': {
        currentTab = 'Bulk Upload & Item Search'
        break
      }
      default: {
        currentTab = 'Search'
        break
      }
    }

    setState({ currentTab: currentTab })
  }, [props.router.location.pathname])

  const [state, setState] = useState({
    currentTab: 'Search',
  })

  const routeToPage = (value) => {
    setState({ currentTab: value })
    switch (value) {
      case 'Search': {
        props.router.navigate({ pathname: '/v2/altText' })
        break
      }
      case 'Bulk Upload & Item Search': {
        props.router.navigate({ pathname: '/v2/altText/bulkUpload' })
        break
      }
      default: {
        break
      }
    }
  }

  const handleChange = (event, value) => {
    routeToPage(value)
  }

  const { classes = {}, menuAction = () => {}, permission = {} } = props
  return (
    <div className={classes.header}>
      <AppBar className={classes.appBar}>
        {menuAction && (
          <div className={classes.headerTab}>
            <IconButton
              onClick={menuAction}
              classes={{ root: classes.button }}
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.marginLeftMedium}>
              <img
                alt="Primary"
                src={
                  envConfigs.api.sceneSevenBaseUrl +
                  'pipeline_logo_sq_wh?fmt=png-alpha&hei=40&wei=40'
                }
              />
            </div>
            <div className={classes.marginLeftMedium}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.whiteColor}
              >
                AltText - {state.currentTab}
              </Typography>
            </div>
          </div>
        )}
        <Toolbar className={classes.toolbarTab}>
          <Tabs
            value={state.currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            classes={{ indicator: classes.noColor }}
          >
            {!isExternalUrl(permission.hostName) && (
              <Tab value="Search" label="Search" />
            )}

            {permission.imgReviewUpload && (
              <Tab
                value="Bulk Upload & Item Search"
                label="Bulk Upload & Item Search"
              />
            )}
          </Tabs>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default withRouter(withStyles(styles)(AlttextHeader))
