import {
  Drawer,
  Grid,
  Typography,
  Toolbar,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  CircularProgress,
  Backdrop,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import ImageToolbar from '../ImageToolbar'
import ApproveImageAction from '../ApproveImageAction'
import SendToRetouchImageAction from '../SendToRetouchImageAction'
import RejectImageAction from '../RejectImageAction'
import { REVIEW_DRAWER_ACTION } from '../../../../Shared/Constants'

let ImageToolbarActionDrawer = (props) => {
  const {
    classes = {},
    isActionDrawerOpen = false,
    toggleActionDrawer = () => {},
    setRejectionReason = () => {},
    currentRejectionReason = undefined,
    handleChangeRejectionMessage = () => {},
    closeEscapeKey = () => {},
    handleRejectImageEvent = () => {},
    numSelected = 0,
    drawerAction = '',
    rejectionMessage = '',
    currentApprovalOption = undefined,
    setApprovalOption = () => {},
    handleApprovalEvent = () => {},
    handleRetouchEvent = () => {},
    retouchLoader = false,
  } = props

  return (
    <Drawer
      anchor="bottom"
      open={isActionDrawerOpen}
      onKeyDown={(event) => closeEscapeKey(event, false)}
    >
      {isActionDrawerOpen && (
        <ImageToolbar
          numSelected={numSelected}
          isActionDrawerOpen={isActionDrawerOpen}
          enabledActions={[
            <ApproveImageAction
              disabled={drawerAction !== REVIEW_DRAWER_ACTION.APPROVE}
            />,
            <SendToRetouchImageAction
              disabled={drawerAction !== REVIEW_DRAWER_ACTION.RETOUCH}
            />,
            <RejectImageAction
              disabled={drawerAction !== REVIEW_DRAWER_ACTION.REJECT}
            />,
          ]}
        />
      )}
      <Toolbar>
        {drawerAction === REVIEW_DRAWER_ACTION.APPROVE && (
          <Grid container className={classes.margin16}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Approve Images
              </Typography>
              Do you want to Approve all {numSelected} selected images? You may
              choose to retain the submitted image position, or place ALL new
              images at the end of the alternate images list.
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-label="approvalOptions"
                  name="approvalOptions"
                  value={currentApprovalOption}
                  onChange={(event) => setApprovalOption(event.target.value)}
                >
                  <FormControlLabel
                    value="Replace"
                    control={<Radio color="primary" />}
                    label="APPROVE IN PLACE (some overwriting of existing images may occur)"
                  />
                  <FormControlLabel
                    value="NoReplace"
                    control={<Radio color="primary" />}
                    label="APPROVE and ADD TO THE END (existing images will be preserved)"
                  />
                </RadioGroup>
                <Grid container direction="row" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setApprovalOption(undefined)
                      toggleActionDrawer(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.marginLeft5}
                    disabled={!currentApprovalOption}
                    onClick={() => handleApprovalEvent()}
                  >
                    Approve
                  </Button>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {drawerAction === REVIEW_DRAWER_ACTION.RETOUCH && (
          <Grid container className={classes.margin16}>
            {retouchLoader ? (
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open
                style={{ zIndex: 1 }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : null}

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Send Images for Retouching
              </Typography>
              <div className={classes.marginTopBottom16}>
                Do you want to send all {numSelected} selected images to the
                Imaging team for Retouching work? Images are subject to the
                Imaging team level of service
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Grid container direction="row" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => toggleActionDrawer(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.marginLeft5}
                    onClick={() => handleRetouchEvent()}
                  >
                    Retouch
                  </Button>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {drawerAction === REVIEW_DRAWER_ACTION.REJECT && (
          <Grid container className={classes.margin16}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Reject Images
              </Typography>
              Do you want to reject all {numSelected} selected images? All
              images will have the same reason for rejection
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-label="rejectionReason"
                  name="rejectionReason"
                  value={currentRejectionReason}
                  onChange={(event) => setRejectionReason(event.target.value)}
                >
                  <FormControlLabel
                    value="Not a Photo"
                    control={<Radio color="primary" />}
                    label="Not a Photo (drawing, illustration, logo, etc.)"
                  />
                  <FormControlLabel
                    value="Poor Quality"
                    control={<Radio color="primary" />}
                    label="Poor Quality (blurry, bad lighting, other photographic elements)"
                  />
                  <FormControlLabel
                    value="Text On Image"
                    control={<Radio color="primary" />}
                    label="Text On Image (accessibility violation, ingredients list, drug facts, marketing messaging, etc.)"
                  />
                  <FormControlLabel
                    value="Style Guide Violation"
                    control={<Radio color="primary" />}
                    label="Style Guide Violation (something specific to category guidelines)"
                  />
                  <FormControlLabel
                    value="Does Not Match Item Description"
                    control={<Radio color="primary" />}
                    label="Doest not match Item Description (wrong image for this item)"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio color="primary" />}
                    label="Other (reason given is required)"
                  />
                </RadioGroup>
                <TextField
                  label="Rejection Comment"
                  helperText="Please provide a rejection reason (optional)"
                  fullWidth
                  onChange={(event) =>
                    handleChangeRejectionMessage(event.target.value)
                  }
                  className={classes.marginBottom20}
                />
                <Grid container direction="row" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setRejectionReason(undefined)
                      handleChangeRejectionMessage('')
                      toggleActionDrawer(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.marginLeft5}
                    disabled={
                      !currentRejectionReason ||
                      (currentRejectionReason === 'Other' &&
                        rejectionMessage === '')
                    }
                    onClick={() => handleRejectImageEvent()}
                  >
                    Reject
                  </Button>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </Drawer>
  )
}

// @ts-ignore
export default withStyles(styles)(ImageToolbarActionDrawer)
