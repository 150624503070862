import React from 'react'
import BulkUploadReportData from './BulkUploadReportData'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  handleChangePage,
  changeDefaultPageSize,
  handleSelectCopy,
  downloadSelectedTcinsBulkUploadReportEvent,
  downloadAllToExcel,
  resetFetch,
} from '../../../../../store/bulkUploadReport/actionCreator'
import { gridCheckedLogic } from '../../../../Shared/SharedUtils'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../../Shared/Constants'

class BulkUploadReportDataContainer extends React.Component {
  handleSelectAllClick = (event, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = this.props.copyData
    }
    this.props.handleSelectCopy({
      selectedCopy: newSelected,
    })
  }

  handleClick = (_, checkedCopy) => {
    const { selectedCopy } = this.props
    this.props.handleSelectCopy({
      selectedCopy: gridCheckedLogic(selectedCopy, checkedCopy),
    })
  }

  handleChangePage = (_, page) => {
    this.props.handleChangePage(
      {
        currentPage: page,
        defaultPageSize: this.props.defaultPageSize,
        selectedFilters: this.props.selectedFilters,
      },
      this.props.sortDirection,
      this.props.sortField
    )
  }

  downloadAllToExcel = () => {
    if (this.props.totalElements > 10000) {
      this.props.resetFetch(false)
      toast.info('Download up to 10000 items only allowed', TOAST_BODY)
      return false
    }
    this.props.downloadAllToExcel(
      this.props.selectedFilters,
      this.props.totalElements
    )
  }

  downloadSelectedTcinsCopy = () => {
    let query = this.props.selectedCopy.map((item) => item.id)
    this.props.downloadSelectedTcinsBulkUploadReportEvent(query)
  }

  handleChangeDefaultPageSize = (event) => {
    this.props.changeDefaultPageSize(
      {
        defaultPageSize: event.target.value,
        selectedFilters: this.props.selectedFilters,
      },
      this.props.sortDirection,
      this.props.sortField,
      this.props.pageName
    )
  }

  isSelected = (checkedCopy) =>
    this.props.selectedCopy.indexOf(checkedCopy) !== -1

  render() {
    const {
      defaultPageSize,
      copyData,
      currentPage,
      selectedCopy,
      totalElements,
      auth,
      selectedFilters,
      rowsPerPage,
    } = this.props

    const emptyRows =
      defaultPageSize -
      Math.min(defaultPageSize, (totalElements - currentPage) * defaultPageSize)

    return (
      <BulkUploadReportData
        data={copyData}
        selectedCopy={selectedCopy}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        emptyRows={emptyRows}
        totalElements={totalElements}
        handleSelectAllClick={this.handleSelectAllClick}
        isSelected={this.isSelected}
        auth={auth}
        handleClick={this.handleClick}
        handleChangePage={this.handleChangePage}
        downloadSelectedTcinsCopy={this.downloadSelectedTcinsCopy}
        handleChangeRowsPerPage={this.handleChangeDefaultPageSize}
        downloadAllToExcel={this.downloadAllToExcel}
        selectedFilters={selectedFilters}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleChangePage,
      changeDefaultPageSize,
      handleSelectCopy,
      downloadSelectedTcinsBulkUploadReportEvent,
      downloadAllToExcel,
      resetFetch,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { bulkUploadReport, auth } = state
  const {
    copyData,
    currentPage,
    selectedCopy,
    defaultPageSize,
    currentRouteTeam,
    plannerNotes,
    totalPages,
    totalElements,
    selectedFilters,
    assignedWritersList,
    sortDirection,
    sortField,
    pageName,
  } = bulkUploadReport
  return {
    copyData,
    currentPage,
    selectedCopy,
    defaultPageSize,
    currentRouteTeam,
    plannerNotes,
    auth,
    totalPages,
    totalElements,
    selectedFilters,
    assignedWritersList,
    sortDirection,
    sortField,
    pageName,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkUploadReportDataContainer)
