/* eslint-disable array-callback-return */
import {
  REQUEST_HISTORY_DATA,
  RECIEVE_HISTORY_DATA,
  CHANGE_CURRENT_PAGE_IMAGE_HISTORY,
  CHANGE_DEFAULT_PAGE_SIZE_IMAGE_HISTORY,
  BUILD_FILTER_EXPAND_IMAGES,
  STORE_IMAGE_HISTORY_STRAPI_DATA,
  NEW_FILTER_EVENT_IMAGE_HISTORY,
  REMOVE_FILTER_EVENT_IMAGE_HISTORY,
  BUILD_FILTER_EVENT_IMAGE_HISTORY,
  CHANGE_FILTER_EVENT_IMAGE_HISTORY,
  SELECT_FILTER_VALUE_EVENT_IMAGE_HISTORY,
  IMAGE_HISTORY_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_HISTORY,
  ERROR_SEARCHING_IMAGE_HISTORY,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { isEmpty, isEqual, cloneDeep } from 'lodash'
import { savePaginationDataEvent } from '../../auth/actionCreator'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'

export function resetFetch(data) {
  return {
    type: IMAGE_HISTORY_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING_IMAGE_HISTORY,
    payload: payload,
  }
}

export function getHistoryData(pageNumber, pageSize, uploadHistorySearchQuery) {
  var queryParam = {}
  uploadHistorySearchQuery.map((item) => {
    if (item.filterValue === 'dateRange') {
      queryParam.from_date = item.selectedValues[0].value.fromDate
      queryParam.to_date = item.selectedValues[0].value.toDate
    }
    if (item.filterValue === 'emails') {
      queryParam.email_ids = item.selectedValues.map((filter) => filter.value)
    }
    if (item.filterValue === 'tcin') {
      queryParam.tcins = item.selectedValues.map((filter) => filter.value)
    }
  })
  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(dispatchItemDataEvent())
    return axios
      .post(
        envConfigs.targetApiGateway.targetApiGatewayUrl +
          envConfigs.targetApiGateway.imageHistory +
          pageNumber +
          '&page_size=' +
          pageSize +
          '&key=' +
          envConfigs.targetApiGateway.targetApiGatewayKey,
        queryParam
      )
      .then((res) => {
        dispatch(
          getHistorySuccess(
            res.data,
            res.data.totalPages,
            res.data.totalElements
          )
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

function dispatchItemDataEvent() {
  return {
    type: REQUEST_HISTORY_DATA,
    payload: {
      isFetchingHistory: true,
    },
  }
}

function getHistorySuccess(data, totalPages, totalElements) {
  return {
    type: RECIEVE_HISTORY_DATA,
    payload: {
      historyData: data.content,
      isFetchingHistory: false,
      totalPages: totalPages,
      totalElements: totalElements,
    },
  }
}

function changePage(pageNumber) {
  return {
    type: CHANGE_CURRENT_PAGE_IMAGE_HISTORY,
    payload: {
      currentPage: pageNumber,
    },
  }
}

export function handleChangePage(data, uploadHistorySearchQuery) {
  return (dispatch) => {
    dispatch(changePage(data.pageNumber))
    dispatch(
      getHistoryData(data.pageNumber, data.pageSize, uploadHistorySearchQuery)
    )
  }
}

function changePageSize(pageSize) {
  return {
    type: CHANGE_DEFAULT_PAGE_SIZE_IMAGE_HISTORY,
    payload: {
      defaultPageSize: pageSize,
    },
  }
}

export function changeDefaultPageSize(
  pageSize,
  uploadHistorySearchQuery,
  sortDirection,
  sortField,
  pageName
) {
  return (dispatch) => {
    dispatch(changePageSize(pageSize))
    dispatch(
      savePaginationDataEvent({
        pageSize: pageSize,
        sortOrder: sortDirection,
        sortField: sortField,
        pageName: pageName,
      })
    )
    // Always setting the currentPage to 0 to prevent bugs
    dispatch(changePage(0))
    dispatch(getHistoryData(0, pageSize, uploadHistorySearchQuery))
  }
}

export function buildFilterExpand(value) {
  return {
    type: BUILD_FILTER_EXPAND_IMAGES,
    payload: {
      buildFilterExpansionPanel: value,
    },
  }
}

export function storeImageHistoryStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_IMAGE_HISTORY_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
    },
  }
}

export function getImageHistoryStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Image%20Upload%20History&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        if (isEmpty(response.data)) {
          dispatch(showDefaultAlert(true))
        } else {
          dispatch(storeImageHistoryStrapiData(response.data))
          dispatch(showDefaultAlert(false))
        }
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function newFilterAction(isShown) {
  return {
    type: NEW_FILTER_EVENT_IMAGE_HISTORY,
    payload: isShown,
  }
}

export function removeFilterEvent(data) {
  var newFilterContainerShown = true
  var historyPageContainerShown = true
  var deepCopy = cloneDeep(data.selectedFilters)
  var currentFilter = deepCopy.filter(
    (v) => v.filterValue === data.mainFilterValue
  )[0]
  currentFilter.selectedValues = currentFilter.selectedValues.filter(
    (item) => !isEqual(item.value, data.filterValue)
  )
  deepCopy = deepCopy.filter((v) => v.selectedValues.length >= 1)
  if (currentFilter.selectedValues.length === 0 && deepCopy.length <= 0) {
    deepCopy = deepCopy.filter((v) => v.filterValue !== data.mainFilterValue)
    newFilterContainerShown = false
    historyPageContainerShown = false
  }
  return {
    type: REMOVE_FILTER_EVENT_IMAGE_HISTORY,
    payload: {
      newFilterContainerShown: newFilterContainerShown,
      historyPageContainerShown: historyPageContainerShown,
      selectedFilters: deepCopy,
    },
  }
}

export function clearFilters() {
  return {
    type: REMOVE_FILTER_EVENT_IMAGE_HISTORY,
    payload: {
      newFilterContainerShown: false,
      historyPageContainerShown: false,
      selectedFilters: [],
    },
  }
}

function handleAlreadySelectedFilter(data) {
  var deepCopy = cloneDeep(data.selectedFilters)
  let selectedValues = []
  let dateRangeAlreadySelected =
    deepCopy.filter((item) => item.filterValue === 'dateRange').length > 0
  let dateRangeAdded = data.selectedFilter.value === 'dateRange'
  if (dateRangeAlreadySelected && dateRangeAdded) {
    deepCopy
      .filter((item) => item.filterValue === 'dateRange')[0]
      .selectedValues.shift()
  }
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  let oldSelectedValue = deepCopy.filter(
    (v) => v.filterValue === data.selectedFilter.value
  )[0].selectedValues
  selectedValues.forEach((item) => {
    oldSelectedValue.push(item)
  })
  deepCopy
    .filter((v) => v.filterValue === data.selectedFilter.value)[0]
    .selectedValues.concat(selectedValues)
  return {
    type: BUILD_FILTER_EVENT_IMAGE_HISTORY,
    payload: {
      newFilterContainerShown: true,
      historyPageContainerShown: true,
      selectedFilters: deepCopy,
    },
  }
}

function handleNewFilter(data) {
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  var newSelectedFilters = [
    {
      filterValue: data.selectedFilter.value,
      filterDisplay: data.selectedFilter.display,
      selectedValues: selectedValues,
    },
  ]
  return {
    type: BUILD_FILTER_EVENT_IMAGE_HISTORY,
    payload: {
      newFilterContainerShown: true,
      historyPageContainerShown: true,
      selectedFilters: data.selectedFilters.concat(newSelectedFilters),
    },
  }
}

export function buildFilterAction(data, pageNumber, pageSize) {
  var alreadySelectedFilters = data.selectedFilters.filter(
    (item) => item.filterValue === data.selectedFilter.value
  )
  if (alreadySelectedFilters.length > 0) {
    // If filter already selected and different value selected.
    let isDuplicateValueFound = false
    if (Array.isArray(data.selectedFilterValue)) {
      data.selectedFilterValue.forEach((item) => {
        isDuplicateValueFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val) => val.value === item.value
          ).length === 0
      })
    } else {
      isDuplicateValueFound =
        alreadySelectedFilters[0].selectedValues.filter(
          (val) => val.display === data.selectedFilterValue.display
        ).length === 0
    }
    if (isDuplicateValueFound) {
      return (dispatch) => {
        dispatch(changePage(0)) // Reset Current Page to first page
        var filter = dispatch(handleAlreadySelectedFilter(data))
        dispatch(
          getHistoryData(pageNumber, pageSize, filter.payload.selectedFilters)
        )
      }
    } else {
      return (dispatch) => {
        dispatch(
          resetFetch(false),
          toast.error(
            'You have attempted to add duplicate filters.',
            TOAST_BODY
          )
        )
      }
    }
  } else {
    return (dispatch) => {
      dispatch(changePage(0)) // Reset Current Page to first page
      var filter = dispatch(handleNewFilter(data))
      dispatch(
        getHistoryData(pageNumber, pageSize, filter.payload.selectedFilters)
      )
    }
  }
}

export function changeFilterEvent(data) {
  return {
    type: CHANGE_FILTER_EVENT_IMAGE_HISTORY,
    payload: {
      selectedFilter: data.selectedFilter,
    },
  }
}

export function selectFilterValueEvent(data) {
  return {
    type: SELECT_FILTER_VALUE_EVENT_IMAGE_HISTORY,
    payload: {
      filterValues: data.filterValues,
    },
  }
}

export function loadExistingFilter(selectedFilters) {
  return {
    type: BUILD_FILTER_EVENT_IMAGE_HISTORY,
    payload: {
      newFilterContainerShown: true,
      historyPageContainerShown: true,
      selectedFilters: selectedFilters,
    },
  }
}

export function removeFilterHandler(data, currentPage, defaultPageSize) {
  return (dispatch) => {
    var filter = dispatch(removeFilterEvent(data))
    // only refresh data if filter exists
    if (!isEmpty(filter.payload.selectedFilters)) {
      dispatch(
        getHistoryData(
          currentPage,
          defaultPageSize,
          filter.payload.selectedFilters
        )
      )
    }
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_IMAGE_HISTORY,
    payload: data,
  }
}
