/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { object, string, func } from 'prop-types'
import { AppBar, Typography, Tabs, Tab, Toolbar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../Shared/WithRouterHoc'
import styles from './theme'
import envConfigs from '../../config/apiConfig'
import { ROUTES } from '../Shared/Constants'
import { isExternalUrl } from '../../components/Layout/Layout'
function ImagesHeader(props) {
  const { classes = {}, menuAction = () => {}, permission = {} } = props

  useEffect(() => {
    let currentTab
    let currentHeader
    switch (props.router.location.pathname) {
      case ROUTES.IMAGES.UPLOAD: {
        currentTab = 'Upload'
        currentHeader = currentTab
        break
      }
      case ROUTES.IMAGES.UPLOAD_HISTORY: {
        currentTab = 'History'
        currentHeader = currentTab
        break
      }
      case ROUTES.IMAGES.SEARCH: {
        currentTab = 'Search'
        currentHeader = currentTab
        break
      }
      case ROUTES.IMAGES.REVIEW: {
        currentTab = 'Search'
        currentHeader = 'Review'
        break
      }
      case ROUTES.IMAGES.LOOK_CLOSER: {
        currentTab = 'Search'
        currentHeader = 'Look Closer'
        break
      }
      case ROUTES.IMAGES.MANAGE: {
        currentTab = 'Search'
        currentHeader = 'Manage'
        break
      }
      case ROUTES.IMAGES.REVIEW_HISTORY: {
        currentTab = 'Search'
        currentHeader = 'Review History'
        break
      }
      case ROUTES.IMAGES.LABELS: {
        currentTab = 'Labels'
        currentHeader = currentTab
        break
      }
      case ROUTES.IMAGES.LABELS_CREATE: {
        currentTab = 'Labels'
        currentHeader = 'Create'
        break
      }
      case ROUTES.IMAGES.LABELS_TRAINING: {
        currentTab = 'Labels'
        currentHeader = 'Training'
        break
      }
      case ROUTES.IMAGES.LABELS_CLASSIFIER: {
        currentTab = 'Labels'
        currentHeader = 'Classifier'
        break
      }
      default: {
        currentTab = 'Upload'
        currentHeader = currentTab
        break
      }
    }

    setCurrentTab(currentTab)
    setCurrentHeader(currentHeader)
  }, [props.router.location.pathname])

  const [currentTab, setCurrentTab] = useState('Upload')
  const [currentHeader, setCurrentHeader] = useState('Upload')

  const routeToPage = (value) => {
    setCurrentTab(value)
    switch (value) {
      case 'Upload': {
        props.router.navigate({ pathname: ROUTES.IMAGES.UPLOAD })
        break
      }
      case 'History': {
        props.router.navigate({ pathname: ROUTES.IMAGES.UPLOAD_HISTORY })
        break
      }
      case 'Search': {
        props.router.navigate({ pathname: ROUTES.IMAGES.SEARCH })
        break
      }
      case 'Labels': {
        props.router.navigate({ pathname: ROUTES.IMAGES.LABELS })
        break
      }
      default: {
        break
      }
    }
  }

  const handleChange = (_, value) => {
    routeToPage(value)
  }

  return (
    <div className={classes.header}>
      <AppBar className={classes.appBar}>
        {menuAction && (
          <div className={classes.headerTab}>
            <IconButton
              onClick={menuAction}
              classes={{ root: classes.button }}
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.marginLeftMedium}>
              <img
                alt="Primary"
                src={
                  envConfigs.api.sceneSevenBaseUrl +
                  'pipeline_logo_sq_wh?fmt=png-alpha&hei=40&wei=40'
                }
              />
            </div>
            <div className={classes.marginLeftMedium}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.whiteColor}
              >
                Images - {currentHeader}
              </Typography>
            </div>
          </div>
        )}
        <Toolbar className={classes.toolbarTab}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            classes={{ indicator: classes.noColor }}
          >
            {!isExternalUrl(permission.hostName) && (
              <Tab value="Search" label="Search" />
            )}
            {permission.imgReviewUpload && (
              <Tab value="Upload" label="Upload" />
            )}
            {<Tab value="History" label="Upload History" />}
            {permission.isCepSuperUser && <Tab value="Labels" label="Labels" />}
          </Tabs>
        </Toolbar>
      </AppBar>
    </div>
  )
}

ImagesHeader.displayName = 'ImagesHeader'

ImagesHeader.propTypes = {
  classes: object,
  title: string.isRequired,
  menuAction: func,
}

ImagesHeader.defaultProps = {
  classes: {},
}

export default withRouter(withStyles(styles)(ImagesHeader))
