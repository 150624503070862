/* eslint-disable array-callback-return */
import {
  IMAGE_MANAGE_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_MANAGE,
  STORE_IMAGE_MANAGE_STRAPI_DATA,
  BUILD_FILTER_EVENT_IMAGE_MANAGE,
  CLEAR_IMAGE_MANAGE_DATA_EVENT,
  ERROR_SEARCHING_IMAGE_MANAGE,
  REQUEST_IMAGE_MANAGE_DATA,
  RECEIVE_IMAGE_MANAGE_DATA,
  CHANGE_CURRENT_PAGE_IMAGE_MANAGE,
  CHANGE_DEFAULT_PAGE_SIZE_IMAGE_MANAGE,
  SELECT_IMAGE_MANAGE_EVENT,
  SET_SORT_FIELD_IMAGE_MANAGE,
  SET_SORT_DIRECTION_IMAGE_MANAGE,
  UPDATE_IMAGE_DATA_MANAGE,
  RESET_IMAGE_DATA_MANAGE,
  START_PUBLISH_IMAGE_DATA_MANAGE,
  SET_COPIED_IMAGE,
  TOGGLE_RELATED_ITEMS_MODAL,
  STORE_RELATED_ITEMS_DATA,
  SUCCESS_SEARCH_FOR_ITEM_IMAGES,
  TOGGLE_SYNC_ITEMS_MODAL,
  TOGGLE_EDIT_SWATCH_MODAL,
  TOGGLE_EDIT_IMAGE_LABEL_DIALOGUE,
  SAVED_LABEL_VALUE_LIST,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { isEmpty, flatten } from 'lodash'
import { savePaginationDataEvent } from '../../auth/actionCreator'
import { toast } from 'react-toastify'
import { IMAGE_ROW_ID, TOAST_BODY } from '../../../components/Shared/Constants'
import { flattenImages } from '../../../components/Shared/SharedUtils'

export function resetFetch(data) {
  return {
    type: IMAGE_MANAGE_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_IMAGE_MANAGE,
    payload: data,
  }
}

export function setCopiedImage(copiedImage) {
  return {
    type: SET_COPIED_IMAGE,
    payload: {
      copiedImage,
    },
  }
}

export function requestImageManageData(
  data,
  pageNumber,
  pageSize,
  sortDirection,
  sortField
) {
  let queryParam = {}
  let totalCount = 0

  data.map((item) => {
    if (item.filterValue === 'tcin') {
      totalCount = item.selectedValues.length
      queryParam = item.selectedValues.map((filter) => filter.value)
    }
  })

  if (data === undefined || data.length === 0) {
    return clearImageManageDataEvent()
  }

  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(requestImageManageDataEvent())
    return axios
      .post(
        `${envConfigs.api.pipelineAssetApp}assets/v1/curation/list?key=${envConfigs.api.gatewayKey}&page=${pageNumber}&size=${pageSize}`,
        queryParam
      )
      .then((res) => {
        if (res.data) {
          let imageData = res.data.map((item) => {
            const imageList = item.imageList ? item.imageList : []
            let swatchImage = imageList.find(
              (image) => image.viewType === 'swatch'
            )
            let primaryImage = imageList.find(
              (image) => image.viewType === 'primary'
            )
            let alternateImages = imageList.filter(
              (image) => image.viewType === 'alternate' && image.active
            )
            let disabledImages = imageList.filter(
              (image) => image.viewType === 'alternate' && !image.active
            )
            let rows = {
              primary: {
                id: IMAGE_ROW_ID.PRIMARY,
                images: primaryImage ? [primaryImage] : [],
              },
              alternate: {
                id: IMAGE_ROW_ID.ALTERNATE,
                images: alternateImages,
              },
              disabled: {
                id: IMAGE_ROW_ID.DISABLED,
                images: disabledImages,
              },
            }
            let rowOrder = [
              rows.primary.id,
              rows.alternate.id,
              rows.disabled.id,
            ]
            return {
              swatchImage: swatchImage,
              rows: rows,
              rowOrder: rowOrder,
              tcin: item.tcin,
              relationshipTypeCode: item.itemData.itemType,
              title: item.itemData.title,
              launchDate: item.itemData.launchDateTime,
              isEligibleForDuplication: item.isEligibleForDuplication,
              tcinsForItemsToBeDuplicated: item.isEligibleForDuplication
                ? item.tcinsForItemsToBeDuplicated
                : null,
              dept: item.itemData.departmentId,
            }
          })
          dispatch(successRequestDataEvent(imageData, 0, totalCount))
        } else {
          dispatch(errorSearching(true))
        }
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

export function clearImageManageDataEvent() {
  return {
    type: CLEAR_IMAGE_MANAGE_DATA_EVENT,
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING_IMAGE_MANAGE,
    payload: payload,
  }
}

function requestImageManageDataEvent() {
  return {
    type: REQUEST_IMAGE_MANAGE_DATA,
    payload: {
      isFetching: true,
      ismData: [],
      selectedImages: [],
    },
  }
}

function successRequestDataEvent(json, totalPages, totalElements) {
  return {
    type: RECEIVE_IMAGE_MANAGE_DATA,
    payload: {
      isFetching: false,
      ismData: json,
      totalPages: totalPages,
      totalElements: totalElements,
    },
  }
}

export function buildFilterAction(data, pageSize, sortDirection, sortField) {
  return (dispatch) => {
    dispatch(changePage(0)) // Reset Current Page to first page
    var filter = dispatch(handleNewFilter(data))
    dispatch(
      requestImageManageData(
        filter.payload.selectedFilters,
        0,
        pageSize,
        sortDirection,
        sortField
      )
    )
  }
}

function changePage(currentPage) {
  return {
    type: CHANGE_CURRENT_PAGE_IMAGE_MANAGE,
    payload: {
      currentPage: currentPage,
    },
  }
}

function handleNewFilter(data) {
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  var newSelectedFilters = [
    {
      filterValue: data.selectedFilter.value,
      filterDisplay: data.selectedFilter.display,
      selectedValues: selectedValues,
    },
  ]
  return {
    type: BUILD_FILTER_EVENT_IMAGE_MANAGE,
    payload: {
      dataContainerShown: true,
      selectedFilters: data.selectedFilters.concat(newSelectedFilters),
    },
  }
}

export function getImageManageStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Image%20Manage&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data)
          ? dispatch(showDefaultAlert(true))
          : dispatch(storeImageManageStrapiData(response.data))
        dispatch(showDefaultAlert(false))
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function storeImageManageStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const errorData = data.filter(
    (item) => item.interface_document_type === 'error'
  )
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  const error =
    errorData.length > 0
      ? {
          title: errorData[0].user_facing_title,
          body: errorData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_IMAGE_MANAGE_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
      error,
    },
  }
}

export function handleChangePage(data, sortDirection, sortField) {
  return (dispatch) => {
    dispatch(changePage(data.currentPage))
    dispatch(
      requestImageManageData(
        data.selectedFilters,
        data.currentPage,
        data.defaultPageSize,
        sortDirection,
        sortField
      )
    )
  }
}

export function changeDefaultPageSize(
  data,
  sortDirection,
  sortField,
  pageName
) {
  return (dispatch) => {
    dispatch(changePageSize(data.defaultPageSize))
    // Always setting the currentPage to 0 to prevent bugs
    dispatch(changePage(0))
    dispatch(
      requestImageManageData(
        data.selectedFilters,
        0,
        data.defaultPageSize,
        sortDirection,
        sortField
      )
    ).then(
      dispatch(
        savePaginationDataEvent({
          pageSize: data.defaultPageSize,
          sortOrder: sortDirection,
          sortField: sortField,
          pageName: pageName,
        })
      )
    )
  }
}

function changePageSize(defaultPageSize) {
  return {
    type: CHANGE_DEFAULT_PAGE_SIZE_IMAGE_MANAGE,
    payload: {
      defaultPageSize: defaultPageSize,
    },
  }
}

export function handleSelectImage(data) {
  return {
    type: SELECT_IMAGE_MANAGE_EVENT,
    payload: {
      selectedImages: data.selectedImages,
    },
  }
}

export function setSortField(value) {
  return {
    type: SET_SORT_FIELD_IMAGE_MANAGE,
    payload: value,
  }
}

export function setSortDirection(value) {
  return {
    type: SET_SORT_DIRECTION_IMAGE_MANAGE,
    payload: value,
  }
}

export function updateImageData(data) {
  return {
    type: UPDATE_IMAGE_DATA_MANAGE,
    payload: data,
  }
}

export function resetImageData() {
  return {
    type: RESET_IMAGE_DATA_MANAGE,
  }
}

function startPublishImageData() {
  return {
    type: START_PUBLISH_IMAGE_DATA_MANAGE,
    payload: true,
  }
}

export function publishImageData(
  imageDataChanges,
  auth,
  filterData,
  pageSize,
  sortDirection,
  sortField
) {
  let queryParam = flattenImages(imageDataChanges, auth.email)

  return (dispatch) => {
    dispatch(startPublishImageData())
    return axios
      .post(
        `${envConfigs.api.pipelineAssetApp}assets/v1/curation/save?userId=${auth.lanId}?key=${envConfigs.api.gatewayKey}`,
        queryParam
      )
      .then(() => {
        dispatch(
          requestImageManageData(
            filterData,
            0,
            pageSize,
            sortDirection,
            sortField
          ),
          toast.success('Successfully published changes', TOAST_BODY)
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function toggleRelatedItemsModal(openClose) {
  return {
    type: TOGGLE_RELATED_ITEMS_MODAL,
    payload: openClose,
  }
}

export function storeRelatedItems(data, tcin) {
  return {
    type: STORE_RELATED_ITEMS_DATA,
    payload: {
      relatedItemsData: data,
      relatedTcin: tcin,
    },
  }
}

export function searchForRelatedItems(tcin) {
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.api.pipelineAssetApp}assets/v1/curation/parent/${tcin}?key=${envConfigs.api.gatewayKey}`
      )
      .then((response) => {
        let data = response.data
        let relatedItems = []
        let relatedParent = {
          tcin: data.tcin,
          itemType: data.itemType,
          title: data.title,
          publishUrl: data.primaryImageUrl,
          baseUrl: data.baseUrl,
          color: data.color ? data.color : '',
          size: data.size ? data.size : '',
          dpci: data.dpci ? data.dpci : '',
        }
        let relatedChildren = data.relatedChildren.map((child) => {
          let object = {
            tcin: child.tcin,
            itemType: child.itemType,
            title: child.title,
            publishUrl: child.primaryImageUrl,
            baseUrl: child.baseUrl,
            color: child.color ? child.color : '',
            size: child.size ? child.size : '',
            dpci: child.dpci ? child.dpci : '',
          }
          return object
        })
        relatedItems = flatten([relatedParent, relatedChildren])
        dispatch(storeRelatedItems(relatedItems, tcin))
        dispatch(toggleRelatedItemsModal(true))
      })
      .catch((error) => {
        dispatch(toast.error(error.message, TOAST_BODY))
      })
  }
}

export function searchForItemImages(item) {
  return (dispatch) => {
    dispatch(errorSearching(false))
    return axios
      .post(
        `${envConfigs.api.pipelineAssetApp}assets/v1/curation/list?key=${envConfigs.api.gatewayKey}`,
        item.tcinsForItemsToBeDuplicated
      )
      .then((res) => {
        if (res.data) {
          let imageData = res.data.map((item) => {
            const imageList = item.imageList ? item.imageList : []
            let swatchImage = imageList.find(
              (image) => image.viewType === 'swatch'
            )
            let primaryImage = imageList.find(
              (image) => image.viewType === 'primary'
            )
            let alternateImages = imageList.filter(
              (image) => image.viewType === 'alternate' && image.active
            )
            let disabledImages = imageList.filter((image) => !image.active)
            let rows = {
              primary: {
                id: IMAGE_ROW_ID.PRIMARY,
                images: primaryImage ? [primaryImage] : [],
              },
              alternate: {
                id: IMAGE_ROW_ID.ALTERNATE,
                images: alternateImages,
              },
              disabled: {
                id: IMAGE_ROW_ID.DISABLED,
                images: disabledImages,
              },
            }
            let rowOrder = [
              rows.primary.id,
              rows.alternate.id,
              rows.disabled.id,
            ]
            return {
              swatchImage: swatchImage,
              rows: rows,
              rowOrder: rowOrder,
              tcin: item.tcin,
              relationshipTypeCode: item.itemData.itemType,
              title: item.itemData.title,
              launchDate: item.itemData.launchDateTime,
              tcinsForItemsToBeDuplicated: item.isEligibleForDuplication
                ? item.tcinsForItemsToBeDuplicated
                : null,
            }
          })
          dispatch(successSearchForItemImages(item, imageData))
          dispatch(toggleSyncItemsModal(true))
        } else {
          dispatch(errorSearching(true))
        }
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

function successSearchForItemImages(item, data) {
  return {
    type: SUCCESS_SEARCH_FOR_ITEM_IMAGES,
    payload: {
      itemToSyncFrom: item,
      itemToSyncTo: data,
    },
  }
}

export function toggleSyncItemsModal(openClose) {
  return {
    type: TOGGLE_SYNC_ITEMS_MODAL,
    payload: openClose,
  }
}

export function toggleEditSwatchModal(openClose) {
  return {
    type: TOGGLE_EDIT_SWATCH_MODAL,
    payload: openClose,
  }
}

export function syncImages(
  tcin,
  auth,
  filterData,
  pageSize,
  sortDirection,
  sortField
) {
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.api.pipelineAssetApp}assets/v1/curation/sync/${tcin}?userId=${auth.lanId}&key=${envConfigs.api.gatewayKey}`
      )
      .then(() => {
        dispatch(
          toggleSyncItemsModal(false),
          toast.success('Successfully Synced Images', TOAST_BODY)
        )
        dispatch(
          requestImageManageData(
            filterData,
            0,
            pageSize,
            sortDirection,
            sortField
          )
        )
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function saveSwatch(obj) {
  return (dispatch) => {
    return axios
      .post(`${envConfigs.dashboard.intakeHost}/v1/swatch/create`, obj)
      .then(() => {
        dispatch(
          toggleEditSwatchModal(null),
          toast.success(
            'Changes saved successfully and may take less than 10 minutes to be published.'
          )
        )
      })
      .catch((error, data) => {
        dispatch(toast.error(error.message, TOAST_BODY))
      })
  }
}

export function toggleEditImageLabelDialogue(
  isEditImageLabelOpen = false,
  image = {},
  labelTcin = '',
  rowIndex = 0,
  colIndex = 0
) {
  return {
    type: TOGGLE_EDIT_IMAGE_LABEL_DIALOGUE,
    payload: {
      isEditImageLabelOpen: isEditImageLabelOpen,
      currentAddLabelImage: image,
      labelTcin: labelTcin.tcin,
      rowIndex: rowIndex,
      colIndex: colIndex,
    },
  }
}

export function savedLabelValueList(value) {
  return {
    type: SAVED_LABEL_VALUE_LIST,
    payload: value,
  }
}

export function getAssetLabel(
  tcin,
  sequence,
  diaLogueBoxToggle,
  image,
  item,
  rowIndex,
  colIndex,
  assetUrl
) {
  const placeholderEmptyResponse = [
    {
      tcin: tcin,
      position: sequence,
      signature: image.signature,
      assetUrl: assetUrl,
      source: image.source,
      dept: item.dept,
      viewType: image.viewType,
      assetLabels: [],
    },
  ]
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.api.externalModelAsset}external-model-assets/v1/asset-labels/labels/${tcin}/${sequence}`
      )
      .then((response) => {
        dispatch(
          toggleEditImageLabelDialogue(
            diaLogueBoxToggle,
            image,
            item,
            rowIndex,
            colIndex
          )
        )
        dispatch(savedLabelValueList(response.data))
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(savedLabelValueList(placeholderEmptyResponse))
          dispatch(
            toggleEditImageLabelDialogue(
              diaLogueBoxToggle,
              image,
              item,
              rowIndex,
              colIndex
            )
          )
        } else {
          dispatch(
            showDefaultAlert(true),
            toggleEditImageLabelDialogue(
              false,
              image,
              item,
              rowIndex,
              colIndex
            ),
            toast.error(error.message, TOAST_BODY)
          )
          dispatch(
            toggleEditImageLabelDialogue(false, image, item, rowIndex, colIndex)
          )
        }
      })
  }
}

export function postAssetLabel(obj) {
  const queryParams = {
    tcin: obj.tcin,
    position: obj.position,
    signature: obj.signature,
    assetUrl: obj.assetUrl,
    source: obj.source,
    dept: obj.dept,
    viewType: obj.viewType,
    assetLabels: obj.assetLabels,
  }
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.api.externalModelAsset}external-model-assets/v1/asset-labels/labels/${obj.tcin}/${obj.position}`,
        queryParams
      )
      .then(() => {
        dispatch(
          showDefaultAlert(true),
          toast.success('Changes saved successfully.')
        )
        dispatch(toggleEditImageLabelDialogue(false))
      })
      .catch((error, data) => {
        if (error.response.status === 400) {
          dispatch(
            showDefaultAlert(true),
            toast.error(error.response.data.message, TOAST_BODY)
          )
        } else {
          dispatch(toggleEditImageLabelDialogue(true))
          dispatch(
            showDefaultAlert(true),
            toast.error(error.message, TOAST_BODY)
          )
        }
      })
  }
}
