import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import styles from '../theme'

const ReviewHistoryAction = (props) => {
  const { reviewHistoryClickAction = () => {}, classes = {} } = props

  return (
    <Button className={classes.drawerButton} onClick={reviewHistoryClickAction}>
      <AssignmentTurnedInIcon />
      <span className={classes.marginLeft5}>Inspect Review History</span>
    </Button>
  )
}

// @ts-ignore
export default withStyles(styles)(ReviewHistoryAction)
