import { withStyles } from '@material-ui/core/styles'
import { TableCell, TableRow, Checkbox, Tooltip } from '@material-ui/core'
import Block from '@material-ui/icons/Block'
import { some } from 'lodash'
import styles from '../theme'
import SearchDisplay from './SearchDisplay'
import {
  IMAGE_VIEW_TYPE,
  RELATIONSHIP_TYPE_CODE,
  IMAGE_APPROVAL_STATUS,
} from '../../../Shared/Constants'
import {
  gridCheckedLogic,
  isSwatchEligible,
  determineRelationshipTypeCode,
} from '../../../Shared/SharedUtils'
import ItemDetails from '../../Shared/Components/ItemDetails'

function SearchResult(props) {
  const {
    itemData = {},
    selectItem = () => {},
    selectedItems = [],
    isSelected = () => {},
    searchRelatedItems,
  } = props

  const handleClick = (_, checkedItem) => {
    selectItem({
      selectedItems: gridCheckedLogic(selectedItems, checkedItem),
    })
  }

  const itemDataExists = some(itemData.item_info)
  let relationshipType = RELATIONSHIP_TYPE_CODE.NA
  if (itemDataExists && some(itemData.item_info.relationship_type_codes)) {
    relationshipType = determineRelationshipTypeCode(
      itemData.item_info.relationship_type_codes
    )
  }

  const parsedImages = {
    swatch: itemData.live_image_list
      ? itemData.live_image_list.filter((image) => image.view_type === 'swatch')
      : [],
    primary: itemData.live_image_list
      ? itemData.live_image_list.filter(
          (image) => image.view_type === 'primary'
        )
      : [],
    alternates: itemData.live_image_list
      ? itemData.live_image_list.filter(
          (image) => image.view_type === 'alternate' && image.active
        )
      : [],
    disabled: itemData.live_image_list
      ? itemData.live_image_list.filter(
          (image) => image.view_type === 'alternate' && !image.active
        )
      : [],
  }

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={itemData.tcin}
      selected={isSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          color="primary"
          onClick={(event) => handleClick(event, itemData)}
        />
      </TableCell>
      <TableCell padding="default">
        <ItemDetails
          tcin={itemData.tcin}
          title={itemDataExists ? itemData.item_info.title : 'No Title Found'}
          lifecycle_state={
            itemDataExists ? itemData.item_info.lifecycle_state : 'N/A'
          }
          launchDate={
            itemDataExists
              ? itemData.item_info.launch_date
              : 'No Launch Date Found'
          }
          relationshipTypeCode={relationshipType}
          searchRelatedItems={searchRelatedItems}
          showRelatedIconSearch
          showItemLifeCycle
        />
      </TableCell>
      <TableCell padding="default" data-test-id="relationship-cell">
        {relationshipType}
      </TableCell>
      <TableCell padding="default" data-test-id="dpci-cell">
        {itemData?.item_info?.dpci}
      </TableCell>
      <TableCell padding="default" data-test-id="swatch-cell">
        {isSwatchEligible(relationshipType) ? (
          <SearchDisplay
            displayType={IMAGE_VIEW_TYPE.SWATCH}
            imageList={parsedImages.swatch}
          />
        ) : (
          <Tooltip title="No Swatch Needed">
            <Block />
          </Tooltip>
        )}
      </TableCell>
      <TableCell padding="default" data-test-id="primary-cell">
        <SearchDisplay
          displayType={IMAGE_VIEW_TYPE.PRIMARY}
          imageList={parsedImages.primary}
        />
      </TableCell>
      <TableCell padding="default" data-test-id="alternates-cell">
        <SearchDisplay
          displayType={IMAGE_VIEW_TYPE.ALTERNATE}
          imageList={parsedImages.alternates}
        />
      </TableCell>
      <TableCell padding="default" data-test-id="disabled-cell">
        <SearchDisplay
          displayType={IMAGE_VIEW_TYPE.DISABLED}
          imageList={parsedImages.disabled}
        />
      </TableCell>
      <TableCell padding="default" data-test-id="review-cell">
        <SearchDisplay
          displayType={IMAGE_VIEW_TYPE.READY_FOR_REVIEW}
          imageList={itemData.image_list.filter(
            (image) =>
              image.ids_review === IMAGE_APPROVAL_STATUS.IN_PROCESS &&
              !image.reuse
          )}
        />
        <SearchDisplay
          displayType={IMAGE_VIEW_TYPE.REUSE}
          imageList={itemData.image_list.filter(
            (image) =>
              image.reuse &&
              image.ids_review === IMAGE_APPROVAL_STATUS.IN_PROCESS
          )}
        />
      </TableCell>
      <TableCell padding="default" data-test-id="rejected-cell">
        <SearchDisplay
          displayType={IMAGE_VIEW_TYPE.REJECTED}
          imageList={itemData.image_list.filter(
            (image) => image.ids_review === 'Rejected'
          )}
        />
      </TableCell>
    </TableRow>
  )
}

// @ts-ignore
export default withStyles(styles)(SearchResult)
