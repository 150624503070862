import {
  REQUEST_STYLE_GUIDES,
  RECEIVE_STYLE_GUIDES,
  STYLE_GUIDES_RESET_FETCH,
} from './actionTypes'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'

import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../components/Shared/Constants'

function requestStyleGuides() {
  return {
    type: REQUEST_STYLE_GUIDES,
    payload: {
      isStyleGuidesFetching: true,
    },
  }
}
function receiveStyleGuides(data) {
  return {
    type: RECEIVE_STYLE_GUIDES,
    payload: {
      styleGuides: data,
      isStyleGuidesFetching: false,
    },
  }
}

export function resetFetch(data) {
  return {
    type: STYLE_GUIDES_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function getStyleGuidesData() {
  return (dispatch) => {
    dispatch(requestStyleGuides())
    return axios
      .get(
        envConfigs.targetApiGateway.targetApiGatewayUrl +
          'item_assets/v1/search/config/IMAGE_STYLE_GUIDES?key=' +
          envConfigs.api.gatewayKey
      )
      .then((res) => {
        dispatch(receiveStyleGuides(res.data))
      })
      .catch(() => {
        dispatch(
          resetFetch(false),
          toast.error('Failed to retrieve style guides.', TOAST_BODY)
        )
      })
  }
}
