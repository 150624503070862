import React from 'react'
import { Card, Grid, CardContent, Divider, IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import '../../../../styles/shared.css'
import styles from '../../Styles/theme'
import { withStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'

function MtaDetails(props) {
  const { mtaData, clearMtaData, classes } = props
  const [state, setState] = React.useState({
    showMtaDetails: false,
  })

  return (
    <Grid item>
      <Card className={classes.templateDetailWrapper}>
        <CardContent>
          <table className={classes.width100Percent}>
            <tbody>
              <tr className={classes.templateDetailHeader}>
                <td className={classes.textAlignLeft}>
                  <IconButton
                    onClick={() => {
                      setState({ ...state, showMtaDetails: false })
                      clearMtaData()
                    }}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  Template Details
                </td>
                <td className={classes.textAlignRight}>
                  {!isEmpty(mtaData.mta_ids)
                    ? mtaData.mta_ids[0]
                    : 'MTA Not Found'}
                </td>
              </tr>
            </tbody>
          </table>
          <Divider />
          <div className={classes.templateDetailContainer}>
            {mtaData ? (
              <pre>{JSON.stringify(mtaData, null, 2)}</pre>
            ) : (
              'No MTA Data Available'
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(MtaDetails)
