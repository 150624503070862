import React from 'react'
import { object, string, func } from 'prop-types'
import { AppBar, Typography, Tabs, Tab, Toolbar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../Shared/WithRouterHoc'
import styles from './theme'
import envConfigs from '../../config/apiConfig'

class LongCopyHeader extends React.Component {
  componentWillMount() {
    var currentTab = 'Search & Manage'
    switch (this.props.router.location.pathname) {
      case '/v2/longcopy': {
        currentTab = 'Search & Manage'
        break
      }
      case '/v2/bulk-upload': {
        currentTab = 'Bulk Upload & Item Search'
        break
      }
      case '/v2/bulk-upload-report': {
        currentTab = 'Bulk Upload Report'
        break
      }
      default: {
        currentTab = 'Search & Manage'
        break
      }
    }
    this.setState({ currentTab: currentTab })
  }

  routeToPage = (value) => {
    this.setState({ currentTab: value })
    switch (value) {
      case 'Search & Manage': {
        // this.props.history.push('/v2/longcopy')
        this.props.router.navigate({ pathname: '/v2/longcopy' })
        break
      }
      case 'Bulk Upload & Item Search': {
        //this.props.history.push('/v2/bulk-upload')
        this.props.router.navigate({ pathname: '/v2/bulk-upload' })
        break
      }
      case 'Bulk Upload Report': {
        //this.props.history.push('/v2/bulk-upload-report')
        this.props.router.navigate({ pathname: '/v2/bulk-upload-report' })
        break
      }
      default: {
        break
      }
    }
  }

  handleChange = (event, value) => {
    this.routeToPage(value)
  }

  render() {
    const { classes, menuAction, permission } = this.props
    const { currentTab } = this.state

    console.log(this.props)
    return (
      <div className={classes.header}>
        <AppBar className={classes.appBar}>
          {menuAction && (
            <div className={classes.headerTab}>
              <IconButton
                onClick={menuAction}
                classes={{ root: classes.button }}
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.marginLeftMedium}>
                <img
                  alt="Primary"
                  src={
                    envConfigs.api.sceneSevenBaseUrl +
                    'pipeline_logo_sq_wh?fmt=png-alpha&hei=40&wei=40'
                  }
                />
              </div>
              <div className={classes.marginLeftMedium}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.whiteColor}
                >
                  Copy & Bullets - {currentTab}
                </Typography>
              </div>
            </div>
          )}
          <Toolbar className={classes.toolbarTab}>
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleChange}
              indicatorColor="primary"
              classes={{ indicator: classes.noColor }}
            >
              {permission.longCopySearchAndManage && (
                <Tab value="Search & Manage" label="Search & Manage" />
              )}
              {permission.longCopyBulkUpload && (
                <Tab
                  value="Bulk Upload & Item Search"
                  label="Bulk Upload & Item Search"
                />
              )}
              {permission.longCopyBulkUpload && (
                <Tab value="Bulk Upload Report" label="Bulk Upload Report" />
              )}
            </Tabs>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

LongCopyHeader.displayName = 'LongCopyHeader'

LongCopyHeader.propTypes = {
  classes: object,
  title: string.isRequired,
  menuAction: func,
}

LongCopyHeader.defaultProps = {
  classes: {},
}

export default withRouter(withStyles(styles)(LongCopyHeader))
