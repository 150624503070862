import React from 'react'
import {
  Typography,
  Toolbar,
  Button,
  CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import GetAppIcon from '@material-ui/icons/GetApp'

let BadgesTableContainerToolbar = (props) => {
  const {
    numSelected = 0,
    classes = {},
    downloadSelectedBadges = () => {},
    isBadgesFetching = false,
  } = props

  return (
    <React.Fragment>
      {numSelected > 0 && (
        <Toolbar className={classes.toolBar}>
          <div className={classes.title}>
            {numSelected > 0 && (
              <Typography color="inherit">{numSelected} selected</Typography>
            )}
          </div>
          {numSelected > 0 ? null : <div className={classes.spacer} />}
          <div className={classes.actions}>
            {numSelected > 0 && (
              <div>
                {isBadgesFetching ? (
                  <CircularProgress className={classes.progressBar} />
                ) : (
                  <Button
                    className={classes.drawerButton}
                    onClick={() => downloadSelectedBadges()}
                  >
                    <GetAppIcon />
                    <span className={classes.marginLeft5}>
                      DOWNLOAD SELECTED TO EXCEL
                    </span>
                  </Button>
                )}
              </div>
            )}
          </div>
        </Toolbar>
      )}
    </React.Fragment>
  )
}

BadgesTableContainerToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BadgesTableContainerToolbar)
