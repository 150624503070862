import React from 'react'
import { isNil } from 'lodash'
import { Tooltip } from '@material-ui/core'
import Block from '@material-ui/icons/Block'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { withStyles } from '@material-ui/core/styles'
import {
  isSwatchEligible,
  urlBuilder,
  urlExtenSionBuilder,
} from '../../../Shared/SharedUtils'
import {
  IMAGE_VIEW_TYPE,
  noSwatchUrl,
  urlProtocol,
} from '../../../Shared/Constants'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import BrushIcon from '@material-ui/icons/Brush'
import styles from '../theme'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import EditSwatchModal from './EditSwatchModal'

function Swatch(props) {
  const {
    item = {},
    setCopiedImage = () => {},
    isValidSwatchCopyTarget = false,
    pasteImage = () => {},
    saveSwatch = () => {},
    toggleEditSwatchModal = () => {},
    showEditSwatchModal = null,
    classes,
    auth = {},
  } = props

  const isSwatchCopyEligible = (item) => {
    return isSwatchEligible(item.relationshipTypeCode) &&
      !isNil(item.swatchImage) &&
      !isNil(item.swatchImage.publishUrl) ? (
      <div className={classes.utilityRow}>
        {item.swatchImage.changed && (
          <Tooltip title={'Unpublished Changes'}>
            <BookmarkIcon className={classes.bookmarkIcon} />
          </Tooltip>
        )}
        {auth.permission.imgCurationEdit ? (
          <Tooltip title={'Copy Swatch'}>
            <FileCopyIcon
              className={classes.actionIcons}
              color="primary"
              onClick={() => setCopiedImage(item.swatchImage, '')}
            />
          </Tooltip>
        ) : null}
      </div>
    ) : null
  }

  const isSwatchEditEligible = (item) => {
    return isSwatchEligible(item.relationshipTypeCode) &&
      auth.permission.imgCurationEdit ? (
      <div className={classes.utilityRow}>
        <Tooltip title={'Edit Swatch'}>
          <BrushIcon
            className={classes.actionIcons}
            color="primary"
            onClick={() => toggleEditSwatchModal(item.tcin)}
          />
        </Tooltip>
      </div>
    ) : null
  }

  return (
    <React.Fragment>
      {isValidSwatchCopyTarget ? (
        <span className={classes.centerFlex}>
          <Tooltip title={'Paste Swatch to TCIN'}>
            <AddCircleIcon
              data-test-id={'paste-swatch'}
              className={classes.swatchPasteIcon}
              onClick={() => {
                pasteImage(IMAGE_VIEW_TYPE.SWATCH)
              }}
            />
          </Tooltip>
        </span>
      ) : (
        <React.Fragment>
          {isSwatchEligible(item.relationshipTypeCode) ? (
            <div>
              <div className={classes.utilityRow}>
                <img
                  src={
                    urlProtocol +
                    urlBuilder(item.swatchImage) +
                    urlExtenSionBuilder(item.swatchImage)
                  }
                  alt={item.tcin}
                  className={classes.swatchImage}
                  // @ts-ignore
                  onError={(e) => {
                    e.target.src = noSwatchUrl
                  }}
                />
              </div>
              <div className={classes.utilityRow}>
                {isSwatchCopyEligible(item)}
                {isSwatchEditEligible(item)}
              </div>
            </div>
          ) : (
            <Tooltip title="No Swatch Needed">
              <Block />
            </Tooltip>
          )}
        </React.Fragment>
      )}
      {showEditSwatchModal === item.tcin && (
        <EditSwatchModal
          open={showEditSwatchModal === item.tcin}
          toggleEditSwatchModal={toggleEditSwatchModal}
          item={item}
          saveSwatch={saveSwatch}
        />
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(Swatch)
