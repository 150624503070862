import { each, includes } from 'lodash'

export function isCepAdmin(userInfo) {
  var isCepAdmin = false
  each(userInfo.memberOf, function (group) {
    var upperGroup = group.toUpperCase()
    if (includes(upperGroup, 'ADMN-CEP-PROD')) {
      isCepAdmin = true
    }
  })
  return isCepAdmin
}

export function isContentPlanner(userInfo) {
  var isContentPlanner = false
  each(userInfo.memberOf, function (group) {
    var upperGroup = group.toUpperCase()
    if (includes(upperGroup, 'APP-CEP-CONTENT')) {
      isContentPlanner = true
    }
  })
  return isContentPlanner
}

export function isCopyWriter(userInfo) {
  var isCopyWriter = false
  each(userInfo.memberOf, function (group) {
    var upperGroup = group.toUpperCase()
    if (includes(upperGroup, 'APP-CEP-COPYWRITING')) {
      isCopyWriter = true
    }
  })
  return isCopyWriter
}
