import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Grid, Typography, FormControl, Button } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'

function ItemSearch(props) {
  const {
    classes,
    handleSearchForLongCopyAndFeatureBullets,
    searchedItems,
    handleAddTcin,
    handleDeleteTcin,
  } = props
  return (
    <div className={classes.itemSearchContainer}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <Typography
            variant="button"
            gutterBottom
            className={classes.DropZoneLargeText}
          >
            Item Search
          </Typography>
        </Grid>
      </Grid>
      <div className={searchedItems.length > 12 ? classes.tcinSearch : null}>
        <ChipInput
          value={searchedItems}
          fullWidth
          fullWidthInput
          blurBehavior="add"
          onAdd={(chips) => handleAddTcin(chips)}
          onDelete={(deletedChip) => handleDeleteTcin(deletedChip)}
          placeholder="Enter one or more TCIN(s) to search current Copy"
        />
      </div>
      <FormControl>
        <Button
          disabled={searchedItems.length < 1}
          className={classes.primaryButton}
          onClick={() => handleSearchForLongCopyAndFeatureBullets()}
          color="primary"
          variant="contained"
        >
          ADD
        </Button>
      </FormControl>
    </div>
  )
}
export default withStyles(styles)(ItemSearch)
