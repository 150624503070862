/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UploadPage from './UploadPage'
import { each, isEqual } from 'lodash'
import {
  dropZoneActive,
  onDropLoading,
  addVideoFiles,
  addCCFiles,
  addPosterFrameFiles,
  addTranscriptFiles,
  removeFiles,
  addedFilesVideo,
  setExternalGroupId,
  addDeleteAsset,
  addVideoGroupStatus,
  updateUploadSuccessfull,
  editVttDialogBox,
  clearSelectedVideoHelpContent,
  editModeSetTitle,
  setShowRejectButtons,
  setRejectionMessage,
  handleSelectVideoTcin,
  setVideoActive,
  editModeEnabled,
  setShowVideoActiveCheckbox,
  resetRejectionMessages,
} from '../../../../../store/videos/upload/actionCreator'
import { addFilesToQueue } from '../../../../../store/upload/actionCreator'
import { withRouter } from '../../../../Shared/WithRouterHoc'
import {
  VIDEO_ASSETS,
  VIDEO_APPROVAL_STATUS,
  VIDEO_EDIT_MODE,
} from '../../../../Shared/Constants'
import envConfigs from '../../../../../config/apiConfig'
// @ts-ignore
import { WebVTTParser } from 'webvtt-parser'

function UploadPageContainer(props) {
  const [state, setState] = useState({
    video: [],
    closedCaption: [],
    posterFrame: [],
    transcript: [],
    validFiles: [],
    invalidFiles: [],
    invalidFileError: false,
  })

  useEffect(() => {
    if (
      props.router.params.id !== 'newUpload' &&
      !props.requestedVideoData &&
      !props.isFetching
    ) {
      //props.history.push('/v2/video/videoEdit/newUpload')
      props.router.navigate({ pathname: '/v2/video/videoEdit/newUpload' })
    }
  }, [props.router.params.id])

  useEffect(() => {
    if (props.uploadComplete) {
      //props.history.push('/v2/video/history')
      props.router.navigate({ pathname: '/v2/video/history' })
    }
  }, [props.uploadComplete])

  useEffect(() => {
    if (props.router.params.id === 'newUpload' && props.requestedVideoData) {
      props.handleSelectVideoTcin({
        selectedTcins: props.requestedVideoData.lst_group_item_data,
      })
    }
    if (props.router.params.id === 'newUpload' && !props.requestedVideoData) {
      setState({
        video: [],
        closedCaption: [],
        posterFrame: [],
        transcript: [],
        validFiles: [],
        invalidFiles: [],
        invalidFileError: false,
      })
      props.editModeEnabled(VIDEO_EDIT_MODE.UPLOAD)
      props.editModeSetTitle('')
      props.setShowVideoActiveCheckbox(false)
      props.setShowRejectButtons(false)
      props.setVideoActive(false)
      props.resetRejectionMessages()
      props.addVideoGroupStatus(null)
    }
  }, [props.requestedVideoData])

  useEffect(() => {
    if (
      props.requestedVideoData &&
      props.requestedVideoData.external_group_job_id &&
      props.router.params.id ===
        props.requestedVideoData.external_group_job_id &&
      !props.isFetching
    ) {
      // because setState is not synchronous, adding a flag for isDirty to determine if the state should be
      // updated, and if so, doing one large state update at the end of the useEffect
      let isDirty = false
      let stateClone = { ...state }
      props.handleSelectVideoTcin({
        selectedTcins: props.requestedVideoData.lst_group_item_data,
      })
      props.editModeSetTitle(props.requestedVideoData.title)
      props.setVideoActive(props.requestedVideoData.active)
      var item = props.requestedVideoData.map_child_assets
      // reset external group staus
      props.addVideoGroupStatus(props.requestedVideoData.approval_status)
      // setting the delete array empty
      props.addDeleteAsset([])
      // setting the external group id
      props.setExternalGroupId(props.requestedVideoData.external_group_job_id)
      // If video is present
      if (item.video) {
        isDirty = true
        var videoData = {
          rejected:
            item.video[0].approval_status === VIDEO_APPROVAL_STATUS.REJECTED,
        }
        // eslint-disable-next-line array-callback-return
        Object.keys(item.video[0]).map(function (key, index) {
          videoData[key] = item.video[0][key]
        })
        stateClone = { ...state, video: [videoData] }
        props.addVideoFiles(true)
      }
      // If closed Caption is present
      // to adjust for the possibility of mixed casing, grabbing the correct version
      if (item.closedcaption || item.closedCaption) {
        isDirty = true
        const closedCaptionRoot = item.closedcaption
          ? item.closedcaption
          : item.closedCaption
        var ccData = {
          fileContents: '',
          rejected:
            closedCaptionRoot[0].approval_status ===
            VIDEO_APPROVAL_STATUS.REJECTED,
        }
        var xmlhttp
        /* eslint-disable */
        xmlhttp = new XMLHttpRequest()
          /* eslint-enable */
        xmlhttp.open('GET', closedCaptionRoot[0].asset_url, false)
        xmlhttp.send()
        ccData.fileContents = xmlhttp.responseText
        // eslint-disable-next-line array-callback-return
        Object.keys(closedCaptionRoot[0]).map(function (key, index) {
          ccData[key] = closedCaptionRoot[0][key]
        })
        stateClone = { ...stateClone, closedCaption: [ccData] }
        props.addCCFiles(true)
      }
      // If poster frame is present
      // to adjust for the possibility of mixed casing, grabbing the correct version
      if (item.posterframe || item.posterFrame) {
        isDirty = true
        const posterFrameRoot = item.posterframe
          ? item.posterframe
          : item.posterFrame
        var posterFrameData = {
          rejected:
            posterFrameRoot[0].approval_status ===
            VIDEO_APPROVAL_STATUS.REJECTED,
        }
        // eslint-disable-next-line array-callback-return
        Object.keys(posterFrameRoot[0]).map(function (key, index) {
          posterFrameData[key] = posterFrameRoot[0][key]
        })
        stateClone = { ...stateClone, posterFrame: [posterFrameData] }
        props.addPosterFrameFiles(true)
      }
      // If transcript is present
      if (item.transcript) {
        isDirty = true
        var transcriptData = {
          rejected:
            item.transcript[0].approval_status ===
            VIDEO_APPROVAL_STATUS.REJECTED,
        }
        // eslint-disable-next-line array-callback-return
        Object.keys(item.transcript[0]).map(function (key, index) {
          transcriptData[key] = item.transcript[0][key]
        })
        stateClone = { ...stateClone, transcript: [transcriptData] }
        props.addTranscriptFiles(true)
      }
      props.editModeSetTitle(props.requestedVideoData.title)
      if (isDirty) {
        setState({ ...stateClone })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isFetching])

  useEffect(() => {
    var uploadFilesList = []
    props.addFilesToQueue(uploadFilesList)
    props.addedFilesVideo(uploadFilesList)
    props.updateUploadSuccessfull(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleVttFile = (result, file, uploadFilesList) => {
    const parser = new WebVTTParser()
    const vttTree = parser.parse(result, 'metadata')
    if (vttTree.errors.length) {
      props.editVttDialogBox(true, createVttData(result), file.name)
    } else {
      let objectURL = URL.createObjectURL(file)
      file.blobUrl = objectURL
      file.fileContents = result
      file.assetType = VIDEO_ASSETS.CLOSED_CAPTION.id
      setState({
        ...state,
        closedCaption: [file],
        validFiles: [...state.validFiles, file],
      })

      props.addCCFiles(true)
      uploadFilesList.push({
        fileName: file.name,
        percent: 0,
      })
      props.addFilesToQueue(uploadFilesList)
      props.addedFilesVideo(uploadFilesList)
    }
  }

  const getFileExtension = (filename) => {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
  }

  const checkFile = (fileType, file) => {
    var fileExt = getFileExtension(file.name).toLowerCase()
    // ensure file types
    if (fileType === VIDEO_ASSETS.VIDEO.type && fileExt !== 'mp4') {
      file.errorMessage = 'Video must be in MP4 format.'
      return false
    }
    if (fileType === VIDEO_ASSETS.CLOSED_CAPTION.type && fileExt !== 'vtt') {
      file.errorMessage = 'Closed captions must be in VTT format.'
      return false
    }
    if (
      fileType === VIDEO_ASSETS.POSTER_FRAME.type &&
      fileExt !== 'jpeg' &&
      fileExt !== 'jpg'
    ) {
      file.errorMessage = 'Poster frame image must be in JPEG/JPG format.'
      return false
    }
    if (fileType === VIDEO_ASSETS.MEDIA_ALTERNATIVE.type && fileExt !== 'pdf') {
      file.errorMessage = 'Media Alternative must be in PDF format.'
      return false
    }
    if (
      fileType === VIDEO_ASSETS.VIDEO.type &&
      file?.size > envConfigs.videoUploadLimit
    ) {
      file.errorMessage =
        'Your video file should not be larger than 200MB . Please ensure your file is within this limit and try again'
      return false
    }

    return true
  }

  const onDrop = (fileType, files) => {
    var uploadFilesList = []
    each(props.uploadData, function (value) {
      uploadFilesList.push(value)
    })
    if (fileType === VIDEO_ASSETS.VIDEO.type) {
      if (checkFile(VIDEO_ASSETS.VIDEO.type, files[0])) {
        uploadFilesList.push({
          fileName: files[0].name,
          percent: 0,
        })
        let objectURL = URL.createObjectURL(files[0])
        files[0].blobUrl = objectURL
        files[0].assetType = VIDEO_ASSETS.VIDEO.id
        setState({
          ...state,
          video: files,
          validFiles: [...state.validFiles, ...files],
        })

        props.addVideoFiles(true)
        props.addFilesToQueue(uploadFilesList)
        props.addedFilesVideo(uploadFilesList)
      } else {
        setState({
          ...state,
          invalidFiles: [...state.invalidFiles, ...files],
          invalidFileError: true,
        })
      }
    }
    if (state.invalidFiles.length) {
      setState({ ...state, invalidFileError: true })
    }
    if (fileType === VIDEO_ASSETS.CLOSED_CAPTION.type) {
      if (checkFile(VIDEO_ASSETS.CLOSED_CAPTION.type, files[0])) {
        /* eslint-disable */
        let reader = new FileReader()
        reader.onload = function (event) {
          let result = reader.result
          // check if common Bom exists and remove if so (for the UTF-8 BOM in ISO-8859-1)
          // @ts-ignore
          if (result.charCodeAt(0) === 0x00EF && result.charCodeAt(1) === 0x00BB && result.charCodeAt(2) === 0x00BF) {
            result = result.slice(3)
            files[0] = new File([result],files[0].name)
          }
          handleVttFile(result, files[0], uploadFilesList)
        }
        reader.readAsBinaryString(files[0])
        /* eslint-enable */
      } else {
        setState({ ...state, invalidFiles: [...state.invalidFiles, ...files] })
      }
    }
    if (state.invalidFiles.length) {
      setState({ ...state, invalidFileError: true })
    }
    if (fileType === VIDEO_ASSETS.POSTER_FRAME.type) {
      if (checkFile(VIDEO_ASSETS.POSTER_FRAME.type, files[0])) {
        let objectURL = URL.createObjectURL(files[0])
        files[0].blobUrl = objectURL
        files[0].assetType = VIDEO_ASSETS.POSTER_FRAME.id
        setState({
          ...state,
          posterFrame: files,
          validFiles: [...state.validFiles, ...files],
        })
        props.addPosterFrameFiles(true)
        uploadFilesList.push({
          fileName: files[0].name,
          percent: 0,
        })
        props.addFilesToQueue(uploadFilesList)
        props.addedFilesVideo(uploadFilesList)
      } else {
        setState({ ...state, invalidFiles: [...state.invalidFiles, ...files] })
      }
    }
    if (state.invalidFiles.length) {
      setState({ ...state, invalidFileError: true })
    }
    if (fileType === VIDEO_ASSETS.MEDIA_ALTERNATIVE.type) {
      if (checkFile(VIDEO_ASSETS.MEDIA_ALTERNATIVE.type, files[0])) {
        let objectURL = URL.createObjectURL(files[0])
        files[0].blobUrl = objectURL
        files[0].assetType = VIDEO_ASSETS.MEDIA_ALTERNATIVE.id
        setState({
          ...state,
          transcript: files,
          validFiles: [...state.validFiles, ...files],
        })
        props.addTranscriptFiles(true)
        uploadFilesList.push({
          fileName: files[0].name,
          percent: 0,
        })
        props.addFilesToQueue(uploadFilesList)
        props.addedFilesVideo(uploadFilesList)
      } else {
        setState({ ...state, invalidFiles: [...state.invalidFiles, ...files] })
      }
    }
    if (state.invalidFiles.length) {
      setState({ ...state, invalidFileError: true })
    }
    var data = {
      video: false,
      closedCaption: false,
      posterFrame: false,
      transcript: false,
    }
    props.dropZoneActive(data)
  }

  const removeFilesFromQueue = (file, fileType) => {
    var uploadFilesList = []
    each(props.uploadData, function (value) {
      if (value.fileName !== file.name) {
        uploadFilesList.push(value)
      }
    })
    props.addFilesToQueue(uploadFilesList)
    props.addedFilesVideo(uploadFilesList)

    var updatedValidFileList = []
    each(state.validFiles, function (values) {
      if (!isEqual(values, file)) {
        updatedValidFileList.push(values)
      }
    })
    setState({ ...state, validFiles: updatedValidFileList, [fileType]: [] })
  }

  const removeFile = (type) => {
    switch (type) {
      case VIDEO_ASSETS.VIDEO.type:
        removeFilesFromQueue(state.video[0], VIDEO_ASSETS.VIDEO.id)
        props.addVideoFiles(false)
        break
      case VIDEO_ASSETS.CLOSED_CAPTION.type:
        removeFilesFromQueue(
          state.closedCaption[0],
          VIDEO_ASSETS.CLOSED_CAPTION.id
        )
        props.addCCFiles(false)
        break
      case VIDEO_ASSETS.MEDIA_ALTERNATIVE.type:
        removeFilesFromQueue(
          state.transcript[0],
          VIDEO_ASSETS.MEDIA_ALTERNATIVE.id
        )
        props.addTranscriptFiles(false)
        break
      case VIDEO_ASSETS.POSTER_FRAME.type:
        removeFilesFromQueue(state.posterFrame[0], VIDEO_ASSETS.POSTER_FRAME.id)
        props.addPosterFrameFiles(false)
        break
      default:
    }
  }

  const deleteAssetJob = (type, id) => {
    switch (type) {
      case VIDEO_ASSETS.VIDEO.type:
        setState({ ...state, video: [] })
        props.addVideoFiles(false)
        break
      case VIDEO_ASSETS.CLOSED_CAPTION.type:
        setState({ ...state, closedCaption: [] })
        props.addCCFiles(false)
        break
      case VIDEO_ASSETS.MEDIA_ALTERNATIVE.type:
        setState({ ...state, transcript: [] })
        props.addTranscriptFiles(false)
        break
      case VIDEO_ASSETS.POSTER_FRAME.type:
        setState({ ...state, posterFrame: [] })
        props.addPosterFrameFiles(false)
        break
      default:
    }
    var data = []
    // eslint-disable-next-line array-callback-return
    props.deleteAsset.map(function (value) {
      data.push(value)
    })
    data.push(id)
    props.addDeleteAsset(data)
  }

  const editVttDialogBox = () => {
    props.editVttDialogBox(
      true,
      createVttData(state.closedCaption[0].fileContents),
      state.closedCaption[0].fileName
    )
    removeFilesFromQueue(state.closedCaption[0], VIDEO_ASSETS.CLOSED_CAPTION.id)
  }

  const createVttData = (data) => {
    var NEWLINE = /\r\n|\r|\n/
    var lines = data.split(NEWLINE)
    return lines
  }

  const removeInvalidFile = () => {
    setState({ ...state, invalidFiles: [], invalidFileError: false })
  }

  return (
    <UploadPage
      // @ts-ignore
      dropZoneEnter={props.dropZoneEnter}
      onDrop={onDrop}
      posterFrame={state.posterFrame}
      transcript={state.transcript}
      closedCaption={state.closedCaption}
      video={state.video}
      removeFile={removeFile}
      videoFileAdded={props.videoFileAdded}
      ccFileAdded={props.ccFileAdded}
      posterFrameFileAdded={props.posterFrameFileAdded}
      transcriptFileAdded={props.transcriptFileAdded}
      editMode={props.editMode}
      videoGroupStatus={props.videoGroupStatus}
      deleteAssetJob={deleteAssetJob}
      editModeTitle={props.editModeTitle}
      vttEditBoxOpen={props.vttEditBoxOpen}
      editVttDialogBox={editVttDialogBox}
      invalidFileError={state.invalidFileError}
      invalidFiles={state.invalidFiles}
      removeInvalidFile={removeInvalidFile}
      information={props.information}
      videoInfo={props.videoInfo}
      closedCaptionInfo={props.closedCaptionInfo}
      posterFrameInfo={props.posterFrameInfo}
      transcriptInfo={props.transcriptInfo}
      selectedHelpContent={props.selectedHelpContent}
      clearSelectedVideoHelpContent={props.clearSelectedVideoHelpContent}
      validFiles={state.validFiles}
      isFetching={props.isFetching}
      showRejectButtons={props.showRejectButtons}
      setRejectionMessage={props.setRejectionMessage}
      rejectionMessages={props.rejectionMessages}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onDropLoading,
      dropZoneActive,
      addVideoFiles,
      addCCFiles,
      addPosterFrameFiles,
      addTranscriptFiles,
      removeFiles,
      addFilesToQueue,
      addedFilesVideo,
      setExternalGroupId,
      addDeleteAsset,
      addVideoGroupStatus,
      updateUploadSuccessfull,
      editVttDialogBox,
      clearSelectedVideoHelpContent,
      editModeSetTitle,
      setRejectionMessage,
      setShowRejectButtons,
      handleSelectVideoTcin,
      setVideoActive,
      editModeEnabled,
      setShowVideoActiveCheckbox,
      resetRejectionMessages,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { videoUpload, upload } = state
  const { uploadData } = upload
  const {
    isFetching,
    dropZoneEnter,
    videoFileAdded,
    ccFileAdded,
    posterFrameFileAdded,
    transcriptFileAdded,
    deleteAsset,
    editMode,
    videoGroupStatus,
    uploadComplete,
    editModeTitle,
    vttEditBoxOpen,
    information,
    videoInfo,
    closedCaptionInfo,
    posterFrameInfo,
    transcriptInfo,
    selectedHelpContent,
    requestedVideoData,
    showRejectButtons,
    rejectionMessages,
  } = videoUpload
  return {
    dropZoneEnter,
    videoFileAdded,
    ccFileAdded,
    posterFrameFileAdded,
    transcriptFileAdded,
    uploadData,
    deleteAsset,
    editMode,
    videoGroupStatus,
    uploadComplete,
    editModeTitle,
    vttEditBoxOpen,
    information,
    videoInfo,
    closedCaptionInfo,
    posterFrameInfo,
    transcriptInfo,
    selectedHelpContent,
    requestedVideoData,
    isFetching,
    rejectionMessages,
    showRejectButtons,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UploadPageContainer))
