import {
  REQUEST_CORONA_DATA,
  PUBLISH_CORONA_DATA,
  HELP_ACTION_DRAWER,
  PREVIEW_DATA,
  REMOVE_ITEM_DATA,
  STAGE_CORONA_RESET_FETCH,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { reset } from 'redux-form'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'

export function resetFetch(data) {
  return {
    type: STAGE_CORONA_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function fetchingData(value) {
  return {
    type: REQUEST_CORONA_DATA,
    payload: {
      isFetchingCorona: value,
    },
  }
}

export function helpActionDrawer(value) {
  return {
    type: HELP_ACTION_DRAWER,
    payload: {
      helpTextContainerOpen: value,
    },
  }
}

export function resetForm() {
  return (dispatch) => {
    dispatch(recievePreviewData([]))
    dispatch(reset('stageCoronaForm'))
  }
}

export function publishData(tcinData) {
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.api.pipelineAssetApp}assets/v1/stage/publish?key=${envConfigs.api.gatewayKey}`,
        tcinData
      )
      .then((res) => {
        dispatch(
          publishToCorona([]),
          toast.success(
            'We have successfully sent data to corona stage',
            TOAST_BODY
          )
        )
      })
      .catch((err) => {
        dispatch(resetFetch(false), toast.error(err.message, TOAST_BODY))
      })
  }
}

export function previewData(tcinList) {
  return (dispatch) => {
    dispatch(fetchingData(true))
    return axios
      .post(
        `${envConfigs.api.pipelineAssetApp}assets/v1/stage/tcin_list?key=${envConfigs.api.gatewayKey}`,
        tcinList
      )
      .then((res) => {
        dispatch(recievePreviewData(res.data))
        dispatch(reset('stageCoronaForm'))
      })
      .catch((err) => {
        dispatch(resetFetch(false), toast.error(err.message, TOAST_BODY))
      })
  }
}

function recievePreviewData(value) {
  return {
    type: PREVIEW_DATA,
    payload: {
      imageDataCorona: value,
      isFetchingCorona: false,
    },
  }
}

export function publishToCorona(value) {
  return {
    type: PUBLISH_CORONA_DATA,
    payload: {
      imageDataCorona: value,
    },
  }
}

export function removeItem(data) {
  return {
    type: REMOVE_ITEM_DATA,
    payload: {
      imageDataCorona: data,
    },
  }
}
