import { REQUEST_STYLE_GUIDES, RECEIVE_STYLE_GUIDES } from './actionTypes'

const initState = {
  styleGuides: [],
  isStyleGuidesFetching: false,
}

export default function helpReducer(state = initState, action = {}) {
  switch (action.type) {
    case REQUEST_STYLE_GUIDES: {
      const { payload } = action
      const newState = {
        ...state,
        isStyleGuidesFetching: payload.isStyleGuidesFetching,
      }
      return newState
    }
    case RECEIVE_STYLE_GUIDES: {
      const { payload } = action
      const newState = {
        ...state,
        isStyleGuidesFetching: payload.isStyleGuidesFetching,
        styleGuides: payload.styleGuides,
      }

      return newState
    }
    default:
      return state
  }
}
