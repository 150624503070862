/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { object, string, func } from 'prop-types'
import {
  AppBar,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../Shared/WithRouterHoc'
import { includes } from 'lodash'
import styles from './theme'
import envConfigs from '../../config/apiConfig'

function VideoHeader(props) {
  useEffect(() => {
    var currentTab = 'Video Edit'
    if (!props.permission.videoUpload) {
      currentTab = 'Video Edit'
    } else {
      switch (props.router.location.pathname) {
        case '/v2/video/videoEdit/newUpload': {
          currentTab = 'Video Edit'
          break
        }
        case '/v2/video/history': {
          currentTab = 'History'
          break
        }
        case '/v2/video/review': {
          currentTab = 'Review'
          break
        }
        default: {
          currentTab = 'Video Edit'
          break
        }
      }
    }
    setState({ currentTab: currentTab })
  }, [props.router.location.pathname])

  const [state, setState] = useState({
    currentTab: 'Video Edit',
  })

  const routeToPage = (value) => {
    setState({ currentTab: value })
    switch (value) {
      case 'Video Edit': {
        // props.history.push('/v2/video/videoEdit/newUpload')
        props.router.navigate({ pathname: '/v2/video/videoEdit/newUpload' })
        window.location.reload()
        break
      }
      case 'History': {
        //props.history.push('/v2/video/history')
        props.router.navigate({ pathname: '/v2/video/history' })
        break
      }
      case 'Review': {
        //props.history.push('/v2/video/review')
        props.router.navigate({ pathname: '/v2/video/review' })
        break
      }
      default: {
        break
      }
    }
  }

  const handleChange = (event, value) => {
    routeToPage(value)
  }

  const { classes = {}, menuAction = () => {}, permission = {} } = props
  return (
    <div className={classes.header}>
      <AppBar className={classes.appBar}>
        {menuAction && (
          <div className={classes.headerTab}>
            <IconButton
              onClick={menuAction}
              classes={{ root: classes.button }}
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.marginLeftMedium}>
              <img
                alt="Primary"
                src={
                  envConfigs.api.sceneSevenBaseUrl +
                  'pipeline_logo_sq_wh?fmt=png-alpha&hei=40&wei=40'
                }
              />
            </div>
            <div className={classes.marginLeftMedium}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.whiteColor}
              >
                Videos - {state.currentTab}
              </Typography>
            </div>
          </div>
        )}
        <Toolbar className={classes.toolbarTab}>
          <Tabs
            value={state.currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            classes={{ indicator: classes.noColor }}
          >
            {permission.videoUpload && (
              <Tab value="Video Edit" label="Video Edit" />
            )}
            {permission.videoUpload && (
              <Tab value="History" label="Upload History" />
            )}
            {permission.videoReview &&
              !includes(permission.hostName, 'vendorpipeline') && (
                <Tab value="Review" label="Review" />
              )}
          </Tabs>
        </Toolbar>
      </AppBar>
    </div>
  )
}

VideoHeader.displayName = 'VideoHeader'

VideoHeader.propTypes = {
  classes: object,
  title: string.isRequired,
  menuAction: func,
}

VideoHeader.defaultProps = {
  classes: {},
}

export default withRouter(withStyles(styles)(VideoHeader))
