import { object, string, func } from 'prop-types'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import envConfigs from '../../../config/apiConfig'

export function Header({ classes, title, menuAction }) {
  return (
    <div className={classes.header}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {menuAction && (
            <div className={classes.headerTab}>
              <IconButton
                onClick={menuAction}
                classes={{ root: classes.button }}
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.marginLeftMedium}>
                <img
                  alt="Primary"
                  src={
                    envConfigs.api.sceneSevenBaseUrl +
                    'pipeline_logo_sq_wh?fmt=png-alpha&hei=40&wei=40'
                  }
                />
              </div>
              <div className={classes.marginLeftMedium}>
                <Typography variant="h6" color="inherit">
                  {title}
                </Typography>
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.displayName = 'Header'

Header.propTypes = {
  classes: object,
  title: string.isRequired,
  menuAction: func,
}

Header.defaultProps = {
  classes: {},
}

export default withStyles(styles)(Header)
