import React from 'react'
import {
  Card,
  Grid,
  CardContent,
  Switch,
  FormControlLabel,
  Button,
  Divider,
} from '@material-ui/core'
import '../../../../styles/shared.css'
import styles from '../../Styles/theme'
import { withStyles } from '@material-ui/core/styles'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import '../../Styles/carouselOverrideStyles.css'
import NoImage from '../../../../images/NoImage.png'
import { urlProtocol } from '../../../Shared/Constants'
import { urlBuilder } from '../../../Shared/SharedUtils'

function escapeHtml(input, key) {
  var txt = document.createElement('span')
  txt.innerHTML = input
  if (key === 'Category' && input && input.indexOf(':') > 0) {
    return input.split(':')[1]
  }
  if (key === 'Launch Date' || key === 'Intended Launch Date') {
    var date = new Date(input)
    try {
      var dateFormat = new Intl.DateTimeFormat('en-US').format(date)
      return dateFormat
    } catch (error) {
      return input
    }
  }
  return txt.innerHTML
}

function ItemDetails(props) {
  const { itemProdSpecs, searchMtaData, itemImageData, classes } = props
  const [state, setState] = React.useState({
    showIds: false,
  })

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked })
  }

  return (
    <Grid item>
      {itemImageData ? (
        <div className={classes.itemTitle}>{itemImageData.itemData.title}</div>
      ) : null}
      {itemImageData &&
      itemImageData.imageList &&
      itemImageData.imageList.length > 1 ? (
        <Carousel
          showStatus={false}
          autoPlay
          stopOnHover
          infiniteLoop
          width={'300px'}
        >
          {itemImageData.imageList.map((image) => {
            return (
              <div key={image.imageId}>
                <img
                  src={urlProtocol + urlBuilder(image)}
                  alt={image.imageId}
                />
              </div>
            )
          })}
        </Carousel>
      ) : itemImageData &&
        itemImageData.imageList &&
        itemImageData.imageList.length === 1 ? (
        <div>
          <img
            src={urlProtocol + urlBuilder(itemImageData.imageList[0])}
            alt={itemImageData.imageList[0].imageId}
            className={classes.singleImage}
          />
        </div>
      ) : (
        <div>
          <img
            src={NoImage}
            alt={'No Pictures Found'}
            className={classes.singleImage}
          />
        </div>
      )}
      <Card className={classes.productDetailWrapper}>
        <CardContent>
          <div className={classes.productDetailHeader}>
            Guest Visible Item Details
            {itemProdSpecs && (
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={state.showIds}
                    onChange={handleChange('showIds')}
                    inputProps={{ 'aria-label': 'show IDs' }}
                    value="showIds"
                  />
                }
                label="show IDs"
                labelPlacement="end"
                className={classes.paddingLeft10}
              />
            )}
          </div>
          <Divider />
          <div className={classes.productDetailContainer}>
            {itemProdSpecs ? (
              itemProdSpecs.map((data) => {
                return (
                  <div key={data.displayKey}>
                    <span>
                      <b>{data.displayKey}: </b>
                      {escapeHtml(data.displayValue, data.displayKey)}
                    </span>
                    {state.showIds && (
                      <div>
                        {data.mtaIds ? (
                          data.mtaIds.map((id) => {
                            return (
                              <span key={id} className={classes.paddingRight5}>
                                <Button
                                  className={classes.link}
                                  onClick={() => searchMtaData(id)}
                                >
                                  {id}
                                </Button>
                              </span>
                            )
                          })
                        ) : (
                          <span>No MTAs Available</span>
                        )}
                      </div>
                    )}
                  </div>
                )
              })
            ) : (
              <div>No Item Details Available</div>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(ItemDetails)
