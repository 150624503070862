const styles = (theme) => ({
  progressBar: {
    marginTop: '30px',
    marginLeft: '50%',
  },
  marginTop20: {
    marginTop: '20px',
  },
  totalHistoryData: {
    fontSize: '1.2em',
    color: '#8F1C20',
  },
  table: {
    minWidth: 1020,
  },
  imageThumbnail: {
    height: '75px',
  },
  noResult: {
    textAlign: 'center',
    padding: '20px',
  },
  width100: {
    width: '100px',
  },
  marginBottom5: {
    marginBottom: '5px',
  },
  buildFilterExpansion: {
    width: '100%',
    margin: '0 0 5px 0',
  },
  width100Percent: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  totalElementsTitle: {
    color: '#CC0000',
    padding: '30px 5px',
    fontSize: '1.2em',
  },
  smallMargin: {
    margin: theme.spacing(0.5),
  },
  tcinList: {
    overflowY: 'auto',
    height: '150px',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  filterTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  saveFilterTitle: {
    backgroundColor: '#3f51b5',
  },
  saveTitleText: {
    color: 'white',
  },
  saveFilterWidth: {
    width: '600px',
  },
  helperTextLabel: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    marginTop: 8,
    lineHeight: '1em',
    minHeight: '1em',
    margin: 0,
  },
  maxExceeded: {
    color: 'red',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    marginTop: 8,
    lineHeight: '1em',
    minHeight: '1em',
    margin: 0,
  },
  marginLeft10: {
    marginLeft: '10px',
  },
  marginTop31: {
    marginTop: '31px',
  },
  marginBottom31: {
    marginBottom: '31px',
  },
})

export default styles
