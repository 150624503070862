import React from 'react'
import styles from '../../theme'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

function LongCopySubmitError(props) {
  const {
    classes = {},
    bulkUploadSubmitError,
    bulkUploadSubmitErrorAction,
  } = props

  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted
        maxWidth="md"
        open={bulkUploadSubmitError}
        aria-labelledby="alert-dialog-image-label"
        aria-describedby="alert-dialog-imageLable-description"
      >
        <DialogTitle
          className={classes.saveFilterTitle}
          id="alert-dialog-image-label"
        >
          <div>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.saveTitleText}
            >
              Submit Error Occured
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.marginTop20}
            id="alert-dialog-imageLable-description"
          >
            <ul>
              <li>
                Check if long copy and soft bullets contain any script running
                codes then change it with appropriate HTML codes. <br />
                Ex ( " double quotation mark should be changed to{' '}
                <b>&amp;#34;</b>)
              </li>
              <li>
                Check if a long copy has \t (this happens when the user uses a
                tab). \t should be changed to HTML code <b>&amp;emsp;</b> or
                space instead of Tab.
              </li>
              <li>
                Long copy or soft bullets contains scripting running codes like
                /t or ". Please remove it and try again
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => bulkUploadSubmitErrorAction(!bulkUploadSubmitError)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default withStyles(styles)(LongCopySubmitError)
