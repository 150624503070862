import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Button,
  Grid,
  Card,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core'

const capitalize = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
function TagSelection(props) {
  const [state, setState] = React.useState({
    selectedType: null,
  })
  const {
    selectedImage,
    tagSelectedContinue,
    tagData,
    tagDataSelection,
    tagSelectedData,
    tagDataFetching,
    classes,
    stepIsDisabled,
    activeStep,
  } = props
  const buttonDisabled = stepIsDisabled || tagSelectedData.length <= 0
  const types = tagData.map((item) => item.group)
  const tags = tagData
    .filter((item) => item.group === state.selectedType)
    .flatMap((item) => item.tags)
  return (
    <React.Fragment>
      {tagDataFetching ? (
        <CircularProgress className={classes.progressBar} />
      ) : (
        <Grid container direction="column">
          <Grid item>
            {selectedImage && (
              <Grid container direction="row">
                <img
                  key={selectedImage.id}
                  src={selectedImage.publish_url}
                  alt={selectedImage.tcin}
                  className={`${classes.marginLeft10} ${classes.marginTop10}`}
                />
                {tagSelectedData &&
                tagSelectedData.type &&
                tagSelectedData.tag &&
                activeStep === 3 ? (
                  <div className={classes.marginTop190}>
                    <div className={classes.selectedTypeTag}>
                      Selected type: {tagSelectedData.type}
                    </div>
                    <div className={classes.selectedTypeTag}>
                      Selected tag: {tagSelectedData.tag}
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    {types && (
                      <Card className={classes.tagCards}>
                        Select a type
                        <RadioGroup
                          onChange={(e) =>
                            setState({ selectedType: e.target.value })
                          }
                        >
                          {types.map((type) => (
                            <FormControlLabel
                              disabled={stepIsDisabled}
                              key={type}
                              value={type}
                              control={<Radio color="primary" />}
                              label={capitalize(type)}
                            />
                          ))}
                        </RadioGroup>
                      </Card>
                    )}
                    {tags.length > 0 && (
                      <Card className={classes.tagCards}>
                        Select a tag
                        <RadioGroup
                          onChange={(e) =>
                            tagDataSelection({
                              type: state.selectedType,
                              tag: e.target.value,
                            })
                          }
                        >
                          {tags.map((tag) => (
                            <FormControlLabel
                              disabled={stepIsDisabled}
                              key={tag.name}
                              value={tag.name}
                              control={<Radio color="primary" />}
                              label={capitalize(tag.name)}
                            />
                          ))}
                        </RadioGroup>
                      </Card>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item container direction="row">
            <Button
              disabled={buttonDisabled}
              className={classes.searchTcinButton}
              onClick={tagSelectedContinue}
              variant="contained"
              color="primary"
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(TagSelection)
