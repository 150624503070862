import EditAltText from './EditAltText'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  changeToEditStateAltText,
  checkProfanityAltText,
  updateSelectedAltText,
} from '../../../store/altText/actionCreator'

function EditAltTextContainer(props) {
  const updateEditLongCopy = (event, unique_id, tcin) => {
    if (
      event.target.value ===
      props.data.alt_text
        .replace(/(<br\s*\/?>)|(<BR\s*\/?>)|(<Br\s*\/?>)|(<bR\s*\/?>)/g, '\n')
        .replace(/<\/?span[^>]*>/g, '')
    ) {
      props.changeToEditStateAltText(unique_id, false)
    } else {
      var AltTextString = ''
      AltTextString = event.target.value.replace(/(\n)/g, '<br />')
      props.checkProfanityAltText(AltTextString, unique_id, tcin)
    }
  }

  const convertAltText = (value) => {
    if (value !== null && value !== undefined) {
      let temp = value
      var res = temp.replace(
        /(<br\s*\/?>)|(<BR\s*\/?>)|(<Br\s*\/?>)|(<bR\s*\/?>)/g,
        '\n'
      )
      return res
    }
    return value
  }

  const convertAltTextField = (value) => {
    if (value !== null && value !== undefined) {
      let temp = value
      var res = temp
        .replace(/(<br\s*\/?>)|(<BR\s*\/?>)|(<Br\s*\/?>)|(<bR\s*\/?>)/g, '\n')
        .replace(/<\/?span[^>]*>/g, '')
      return res
    }
    return value
  }

  const changeToEditState = (unique_id) => {
    props.changeToEditStateAltText(unique_id, true)
  }
  return (
    <EditAltText
      alttext={props.data.alt_text}
      altTextUniqNo={props.data.unique_id}
      isAltTextEditable={props.data.isAltTextEditable}
      tcin={props.data.tcin}
      changeToEditState={changeToEditState}
      convertAltText={convertAltText}
      updateEditLongCopy={updateEditLongCopy}
      convertAltTextField={convertAltTextField}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { changeToEditStateAltText, checkProfanityAltText, updateSelectedAltText },
    dispatch
  )

export default connect(null, mapDispatchToProps)(EditAltTextContainer)
