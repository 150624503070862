import {
  SET_IMAGE_URLS,
  SET_MODELS,
  SET_TAG_GROUPS,
  SET_IMAGE_FETCHING,
  SET_TRAINING_IN_PROGRESS,
  IMAGE_TAG_TRAINING_RESET_FETCH,
  SET_FILTERED_TAG_GROUPS,
  SET_TAG_GROUPS_FILTER_TEXT,
  RESET_EDITED_TAG,
  UPDATE_TAG_DESCRIPTION,
  UPDATE_TAG_EDIT_STATE,
  UPDATE_TAG_IS_EDITED,
  SET_TAG_STEPPER_OVERALL_ACTIVE_STEP,
  SET_TAG_SELECTION_ACTIVE_STEP,
  SET_TAGS_BY_TYPE_MAP,
  SET_CREATE_TAG_IMAGE_URL,
  SET_CREATE_NEW_TAG_SYNC_ERROR,
  SET_TAG_FORM_TYPE_DESCRIPTION,
  SET_TAG_FORM_TYPE_DESCRIPTION_DISABLED_STATE,
  INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT,
  ADD_IMAGE_TO_APPROVED_REVIEW_LIST,
  REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST,
  SET_TAG_FORM_TAG_DESCRIPTION,
  SET_TAG_FORM_TAG_LABEL,
  SET_TAG_FORM_TYPE,
  RESET_CREATE_TAG,
  EXAMPLE_IMAGE_SELECTED,
  REQUEST_EXAMPLE_TCIN_IMAGE,
  SET_EXAMPLE_IMAGE_LIST,
  SET_EXAMPLE_IMAGE_TCIN_LIST,
  SET_CLASSIFIER_ACTIVE_STEP,
  START_OVER_SELECT_EXAMPLE_IMAGE,
  REQUEST_CLASSIFIER_TCIN_IMAGE,
  SET_CLASSIFIER_IMAGE_LIST,
  SET_CLASSIFIER_IMAGE_TCIN_LIST,
  CLASSIFIER_IMAGE_SELECTED,
  TAG_DATA_SELECTION,
  REQUEST_SIMILAR_IMAGE_CLASSIFIER,
  RECEIVE_SIMILAR_IMAGE_CLASSIFIER,
  MAX_SIMILAR_IMAGE_COUNT_CLASSIFIER,
  RESET_CLASSIFIER_SELECTION,
  ADD_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER,
  INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT_CLASSIFIER,
  REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER,
  TOGGLE_SELECT_IMAGE_FROM_TRAINING_GRID,
  CLEAR_SELECTED_IMAGES_FROM_TRAINING_GRID,
  REMOVE_IMAGE_FROM_MODEL_GRID,
  SET_DELETE_CONFIRMATION_OPEN,
  POPULATE_CLASSIFIER_DETAILS,
  TOGGLE_CLASSIFIER_APPROVED_SELECT,
  SET_DELETE_CONFIRMATION_OPEN_CLASSIFIER,
  REMOVE_IMAGE_FROM_CLASSIFIER_APPROVED_IMAGES,
  CLEAR_SELECTED_IMAGES_FROM_CLASSIFIER_APPROVED_LIST,
  DELETING_FROM_CLASSIFIER_IMAGEGRID_STATUS,
  DELETING_FROM_TRAINING_IMAGEGRID_STATUS,
} from './actionType'

const initState = {
  isFetching: false,
  tagGroups: [],
  editedTagGroups: [],
  tagGroupsFilterText: '',
  filteredTagGroups: [],
  tagsByTypeMap: new Map(),
  training: {
    models: [],
    imageUrlsFetching: false,
    imageInfo: {
      images: [],
      group: '',
      label: '',
    },
    deleteConfirmationisOpen: false,
    deletingInProgress: false,
  },
  createNewTag: {
    similarImageFetching: false,
    similarImageData: [],
    maxSimilarImageCount: 0,
    currentSimilarImageCount: 0,
    currentApprovedImages: [],
    overall: {
      activeStep: 0,
      steps: [
        {
          step: 0,
          label: 'Define Tag Metadata',
        },
        {
          step: 1,
          label: 'Pick an Image',
        },
        {
          step: 2,
          label: 'Save Tag',
        },
      ],
      typeSelect: '',
      typeDescription: '',
      tagLabel: '',
      tagDescription: '',
      typeDescriptionDisabled: false,
      tagExampleImageUrl: '',
    },
  },
  exampleImageSelection: {
    activeStep: 0,
    imageListFetching: false,
    tcinList: [],
    imageList: [],
    selectedImage: null,
  },
  classifierSelection: {
    activeStep: 0,
    imageListFetching: false,
    tcinList: [],
    imageList: [],
    selectedImage: null,
    tagData: [],
    tagSelectedData: [],
    tagDataFetching: false,
    similarImageFetching: false,
    similarImageData: [],
    maxSimilarImageCount: 0,
    currentSimilarImageCount: 0,
    currentApprovedImages: [],
    deleteConfirmationIsOpen: false,
    deletingInProgress: false,
  },
}

export default function tagTrainingReducer(state = initState, action = {}) {
  switch (action.type) {
    case SET_IMAGE_URLS: {
      const { payload } = action
      const newState = {
        ...state,
        training: {
          ...state.training,
          imageInfo: {
            images: payload.images,
            group: payload.group,
            label: payload.label,
          },
        },
      }
      return newState
    }

    case REMOVE_IMAGE_FROM_MODEL_GRID: {
      const { payload } = action
      const newState = {
        ...state,
        training: {
          ...state.training,
          imageInfo: {
            ...state.training.imageInfo,
            images: state.training.imageInfo.images.filter(
              (image) => !payload.imageUrlsToRemove.includes(image.url)
            ),
          },
        },
      }
      return newState
    }

    case REMOVE_IMAGE_FROM_CLASSIFIER_APPROVED_IMAGES: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          currentApprovedImages:
            state.classifierSelection.currentApprovedImages.filter(
              (image) => !payload.imageUrlsToRemove.includes(image.url)
            ),
        },
      }
      return newState
    }

    case DELETING_FROM_CLASSIFIER_IMAGEGRID_STATUS: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          deletingInProgress: payload.status,
        },
      }
      return newState
    }

    case DELETING_FROM_TRAINING_IMAGEGRID_STATUS: {
      const { payload } = action
      const newState = {
        ...state,
        training: {
          ...state.training,
          deletingInProgress: payload.status,
        },
      }
      return newState
    }

    case SET_DELETE_CONFIRMATION_OPEN_CLASSIFIER: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          deleteConfirmationIsOpen: payload.open,
        },
      }
      return newState
    }

    case SET_DELETE_CONFIRMATION_OPEN: {
      const { payload } = action
      const newState = {
        ...state,
        training: {
          ...state.training,
          deleteConfirmationisOpen: payload.open,
        },
      }
      return newState
    }

    case SET_MODELS: {
      const { payload } = action
      const newState = {
        ...state,
        training: {
          ...state.training,
          models: payload.models,
        },
      }
      return newState
    }

    case POPULATE_CLASSIFIER_DETAILS: {
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          activeStep: 3,
          tcinList: state.exampleImageSelection.tcinList,
          imageList: state.exampleImageSelection.imageList,
          selectedImage: state.exampleImageSelection.selectedImage,
          tagSelectedData: [
            {
              type: state.createNewTag.overall.typeSelect,
              tag: state.createNewTag.overall.tagLabel,
            },
          ],
        },
      }
      return newState
    }

    case SET_TAG_GROUPS: {
      const { payload } = action
      const newState = {
        ...state,
        tagGroups: payload.tagGroups,
        editedTagGroups: payload.tagGroups.map((tagGroup) => {
          tagGroup.tags = tagGroup.tags.map((tag) => {
            return {
              ...tag,
              isTagEditing: false,
              isTagEdited: false,
            }
          })
          return tagGroup
        }),
      }
      return newState
    }

    case CLEAR_SELECTED_IMAGES_FROM_CLASSIFIER_APPROVED_LIST: {
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          currentApprovedImages:
            state.classifierSelection.currentApprovedImages.map((image) => {
              var tempImage = Object.assign({}, image)
              tempImage.isSelected = false
              return tempImage
            }),
        },
      }
      return newState
    }

    case CLEAR_SELECTED_IMAGES_FROM_TRAINING_GRID: {
      const newState = {
        ...state,
        training: {
          ...state.training,
          imageInfo: {
            ...state.training.imageInfo,
            images: state.training.imageInfo.images.map((image) => {
              var tempImage = Object.assign({}, image)
              tempImage.isSelected = false
              return tempImage
            }),
          },
        },
      }
      return newState
    }

    case TOGGLE_SELECT_IMAGE_FROM_TRAINING_GRID: {
      const { payload } = action
      const newState = {
        ...state,
        training: {
          ...state.training,
          imageInfo: {
            ...state.training.imageInfo,
            images: state.training.imageInfo.images.map((image) => {
              var tempImage = Object.assign({}, image)
              if (image.url === payload.image.url) {
                let updatedImage = {
                  ...tempImage,
                  isSelected: !tempImage.isSelected,
                }
                tempImage = updatedImage
              }
              return tempImage
            }),
          },
        },
      }
      return newState
    }

    case UPDATE_TAG_DESCRIPTION: {
      const { payload } = action
      const newState = {
        ...state,
        editedTagGroups: state.editedTagGroups.map((tagGroup) => {
          var tempTagGroup = Object.assign({}, tagGroup)
          if (tempTagGroup.group === payload.type) {
            tempTagGroup.tags = tagGroup.tags.map((tag) => {
              var tempTag = Object.assign({}, tag)
              if (tag.name === payload.tag) {
                let tagIsEdited =
                  tempTag.tag_metadata.description !== payload.tagDescription
                var updatedTag = {
                  ...tempTag,
                  tag_metadata: {
                    description: payload.tagDescription,
                    example_asset_urls: tag.tag_metadata.example_asset_urls,
                  },
                  isTagEdited: tagIsEdited,
                }
                tempTag = updatedTag
              }
              return tempTag
            })
          }
          return tempTagGroup
        }),
      }
      return newState
    }

    case RESET_EDITED_TAG: {
      const { payload } = action
      const newState = {
        ...state,
        editedTagGroups: state.tagGroups.map((tagGroup) => {
          var tempTagGroup = Object.assign({}, tagGroup)
          if (tempTagGroup.group === payload.editedTagType) {
            tempTagGroup.tags = tagGroup.tags.map((tag) => {
              var tempTag = Object.assign({}, tag)
              if (tag.name === payload.editedTagName) {
                tempTag = state.tagGroups
                  .filter((group) => group.group === payload.editedTagType)[0]
                  .tags.filter((tag) => tag.name === payload.editedTagName)[0]
                tempTag.isTagEdited = false
              }
              return tempTag
            })
          }
          return tempTagGroup
        }),
      }
      return newState
    }

    case UPDATE_TAG_EDIT_STATE: {
      const { payload } = action
      const newState = {
        ...state,
        editedTagGroups: state.editedTagGroups.map((tagGroup) => {
          var tempTagGroup = Object.assign({}, tagGroup)
          if (tempTagGroup.group === payload.tagType) {
            tempTagGroup.tags = tagGroup.tags.map((tag) => {
              var tempTag = Object.assign({}, tag)
              if (tag.name === payload.tagName) {
                tempTag.isTagEditing = payload.isTagEditing
              }
              return tempTag
            })
          }
          return tempTagGroup
        }),
      }
      return newState
    }

    case UPDATE_TAG_IS_EDITED: {
      const { payload } = action
      const newState = {
        ...state,
        editedTagGroups: state.editedTagGroups.map((tagGroup) => {
          var tempTagGroup = Object.assign({}, tagGroup)
          if (tempTagGroup.group === payload.tagType) {
            tempTagGroup.tags = tagGroup.tags.map((tag) => {
              var tempTag = Object.assign({}, tag)
              if (tag.name === payload.tagName) {
                tempTag.isTagEdited = payload.isTagEdited
              }
              return tempTag
            })
          }
          return tempTagGroup
        }),
      }
      return newState
    }

    case SET_TAG_GROUPS_FILTER_TEXT: {
      const { payload } = action
      const newState = {
        ...state,
        tagGroupsFilterText: payload.tagGroupsFilterText,
      }
      return newState
    }

    case SET_FILTERED_TAG_GROUPS: {
      const { payload } = action
      const newState = {
        ...state,
        filteredTagGroups: payload.filteredTagGroups,
      }
      return newState
    }

    case TAG_DATA_SELECTION: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          tagSelectedData: payload.tagSelectedData,
        },
      }
      return newState
    }

    case REQUEST_SIMILAR_IMAGE_CLASSIFIER: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          similarImageFetching: payload.similarImageFetching,
        },
      }
      return newState
    }

    case RECEIVE_SIMILAR_IMAGE_CLASSIFIER: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          similarImageFetching: payload.similarImageFetching,
          similarImageData: payload.similarImageData,
        },
      }
      return newState
    }

    case MAX_SIMILAR_IMAGE_COUNT_CLASSIFIER: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          maxSimilarImageCount: payload.maxSimilarImageCount,
        },
      }
      return newState
    }

    case INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT_CLASSIFIER: {
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          currentSimilarImageCount:
            state.classifierSelection.currentSimilarImageCount + 1,
        },
      }
      return newState
    }

    case INCREMENT_CURRENT_SIMILAR_IMAGE_COUNT: {
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          currentSimilarImageCount:
            state.createNewTag.currentSimilarImageCount + 1,
        },
      }
      return newState
    }

    case ADD_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          currentApprovedImages:
            state.classifierSelection.currentApprovedImages.concat(
              payload.imageTagInfo
            ),
        },
      }
      return newState
    }

    case TOGGLE_CLASSIFIER_APPROVED_SELECT: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          currentApprovedImages:
            state.classifierSelection.currentApprovedImages.map((image) => {
              var tempImage = Object.assign({}, image)
              if (image.url === payload.image.url) {
                let updatedImage = {
                  ...tempImage,
                  isSelected: !tempImage.isSelected,
                }
                tempImage = updatedImage
              }
              return tempImage
            }),
        },
      }
      return newState
    }

    case ADD_IMAGE_TO_APPROVED_REVIEW_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          currentApprovedImages:
            state.createNewTag.currentApprovedImages.concat(
              payload.imageTagInfo
            ),
        },
      }
      return newState
    }

    case REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST_CLASSIFIER: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          currentApprovedImages:
            state.classifierSelection.currentApprovedImages.filter(
              (image) => image.url !== payload.imageTagInfo.url
            ),
        },
      }
      return newState
    }

    case REMOVE_IMAGE_TO_APPROVED_REVIEW_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          currentApprovedImages:
            state.createNewTag.currentApprovedImages.filter(
              (image) => image.imageUrl !== payload.imageTagInfo.imageUrl
            ),
        },
      }
      return newState
    }

    case SET_IMAGE_FETCHING: {
      const { payload } = action
      const newState = {
        ...state,
        training: {
          ...state.training,
          imageUrlsFetching: payload.imageUrlsFetching,
        },
      }
      return newState
    }

    case SET_TRAINING_IN_PROGRESS: {
      const { payload } = action
      const newState = {
        ...state,
        imageUrlInfo: {
          urls: [],
        },
        tags: state.tags.map((tag) => {
          var tempTag = Object.assign({}, tag)
          var updatedTag = {
            ...tempTag,
            isTrainingInProgress: payload.isTrainingInProgress,
          }
          tempTag = updatedTag
          return tempTag
        }),
      }
      return newState
    }

    case SET_TAG_STEPPER_OVERALL_ACTIVE_STEP: {
      const { payload } = action
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          overall: {
            ...state.createNewTag.overall,
            activeStep: payload.activeStep,
          },
        },
      }
      return newState
    }

    case CLASSIFIER_IMAGE_SELECTED: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          selectedImage: payload.selectedImage,
        },
      }
      return newState
    }

    case EXAMPLE_IMAGE_SELECTED: {
      const { payload } = action
      const newState = {
        ...state,
        exampleImageSelection: {
          ...state.exampleImageSelection,
          selectedImage: payload.selectedImage,
        },
      }
      return newState
    }

    case REQUEST_CLASSIFIER_TCIN_IMAGE: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          imageListFetching: payload.imageListFetching,
        },
      }
      return newState
    }

    case REQUEST_EXAMPLE_TCIN_IMAGE: {
      const { payload } = action
      const newState = {
        ...state,
        exampleImageSelection: {
          ...state.exampleImageSelection,
          imageListFetching: payload.imageListFetching,
        },
      }
      return newState
    }

    case SET_CLASSIFIER_IMAGE_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          imageList: payload.imageList,
        },
      }
      return newState
    }

    case SET_EXAMPLE_IMAGE_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        exampleImageSelection: {
          ...state.exampleImageSelection,
          imageList: payload.imageList,
        },
      }
      return newState
    }

    case SET_CLASSIFIER_IMAGE_TCIN_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          tcinList: payload.tcinList,
        },
      }
      return newState
    }

    case SET_EXAMPLE_IMAGE_TCIN_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        exampleImageSelection: {
          ...state.exampleImageSelection,
          tcinList: payload.tcinList,
        },
      }
      return newState
    }

    case SET_TAG_SELECTION_ACTIVE_STEP: {
      const { payload } = action
      const newState = {
        ...state,
        exampleImageSelection: {
          ...state.exampleImageSelection,
          activeStep: payload.activeStep,
        },
      }
      return newState
    }

    case SET_CLASSIFIER_ACTIVE_STEP: {
      const { payload } = action
      const newState = {
        ...state,
        classifierSelection: {
          ...state.classifierSelection,
          activeStep: payload.activeStep,
        },
      }
      return newState
    }

    case SET_TAGS_BY_TYPE_MAP: {
      const { payload } = action
      const newState = {
        ...state,
        tagsByTypeMap: payload.tagsByTypeMap,
      }
      return newState
    }

    case START_OVER_SELECT_EXAMPLE_IMAGE: {
      const newState = {
        ...state,
        exampleImageSelection: {
          ...initState.exampleImageSelection,
        },
      }
      return newState
    }

    case RESET_CLASSIFIER_SELECTION: {
      const newState = {
        ...state,
        classifierSelection: {
          ...initState.classifierSelection,
        },
      }
      return newState
    }

    case RESET_CREATE_TAG: {
      const newState = {
        ...state,
        createNewTag: {
          ...initState.createNewTag,
        },
        exampleImageSelection: {
          ...initState.exampleImageSelection,
        },
      }
      return newState
    }

    case SET_TAG_FORM_TYPE_DESCRIPTION_DISABLED_STATE: {
      const { payload } = action
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          overall: {
            ...state.createNewTag.overall,
            typeDescriptionDisabled: payload.disabled,
          },
        },
      }
      return newState
    }

    case SET_CREATE_TAG_IMAGE_URL: {
      const { payload } = action
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          overall: {
            ...state.createNewTag.overall,
            tagExampleImageUrl: payload.imageUrl,
          },
        },
      }
      return newState
    }

    case SET_CREATE_NEW_TAG_SYNC_ERROR: {
      const { payload } = action
      const newState = {
        ...state,
        tagDetailsForm: {
          ...state.tagDetailsForm,
          syncErrors: {
            ...state.tagDetailsForm.syncErrors,
            [payload.identifier]: payload.syncError,
          },
        },
      }
      return newState
    }

    case SET_TAG_FORM_TYPE: {
      const { payload } = action
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          overall: {
            ...state.createNewTag.overall,
            typeSelect: payload.typeSelect,
          },
        },
      }
      return newState
    }

    case SET_TAG_FORM_TYPE_DESCRIPTION: {
      const { payload } = action
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          overall: {
            ...state.createNewTag.overall,
            typeDescription: payload.typeDescription,
          },
        },
      }
      return newState
    }

    case SET_TAG_FORM_TAG_LABEL: {
      const { payload } = action
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          overall: {
            ...state.createNewTag.overall,
            tagLabel: payload.tagLabel,
          },
        },
      }
      return newState
    }

    case SET_TAG_FORM_TAG_DESCRIPTION: {
      const { payload } = action
      const newState = {
        ...state,
        createNewTag: {
          ...state.createNewTag,
          overall: {
            ...state.createNewTag.overall,
            tagDescription: payload.tagDescription,
          },
        },
      }
      return newState
    }

    case IMAGE_TAG_TRAINING_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }

    default:
      return state
  }
}
