import React from 'react'
import { connect } from 'react-redux'
import styles from './theme'
import HeaderTitle from '../Shared/Header/HeaderTitle'
import { Navigate } from 'react-router'
import {
  Stepper,
  Step,
  Paper,
  StepButton,
  withStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Backdrop,
} from '@material-ui/core'
import SelectItems from './Components/SelectItems'
import SizeChartEdit from './Components/SizeChart/SizeChartUI'
import HowToMeasure from './Components/HowToMeasure'
import RuleConfirmation from './Components/RuleConfirmation'
import {
  getRuleAttributes,
  getRule,
  saveRule,
  getRuleItems,
  resetState,
  sendWarning,
  setActiveStep,
  completeStep,
  disableLoadingCondition,
  saveRulePutCall,
} from '../../store/sizeChart/ruleBuilder/actionCreator'
import {
  getAllSizeChartIdentifiers,
  getSizeChart,
  saveSizeChart,
  setSaveSizechartError,
  setSizeChartFastlyRedirect,
  checkForSizeChartExist,
} from '../../store/sizeChart/sizeChartEdit/sizeChartHTTPActions'
import { resetSizeChart } from '../../store/sizeChart/sizeChartEdit/sizeChartDataActions'
import {
  getRules,
  resetTcinRuleSelection,
} from '../../store/sizeChart/ruleSelection/actionCreator'
import { forEach } from 'lodash'
import { bindActionCreators } from 'redux'
// import uuid from 'react-uuid'
import { v4 as uuidv4 } from 'uuid'

export class RulesBuilder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: {
        redirect: false,
        target: '',
      },
    }
  }

  componentWillUnmount() {
    this.props.setSizeChartFastlyRedirect(false)
    this.props.resetState()
    this.props.resetSizeChart()
    // reset your redirect flag and target value
  }

  componentDidMount() {
    const { sizeChart, name, getRule, getSizeChart, selectedTcinRule } =
      this.props
    this.props.getAllSizeChartIdentifiers()
    this.props.getRuleAttributes().then(() => {
      if (
        sizeChart.category &&
        sizeChart.category !== '' &&
        sizeChart.brand &&
        sizeChart.brand !== '' &&
        sizeChart.size &&
        sizeChart.size !== '' &&
        name &&
        name !== '' &&
        !selectedTcinRule
      ) {
        getRule({
          size_chart_info: {
            category: sizeChart.category,
            brand: sizeChart.brand,
            size: sizeChart.size,
          },
          name,
        })
        getSizeChart(
          {
            category: sizeChart.category,
            brand: sizeChart.brand,
            size: sizeChart.size,
          },
          selectedTcinRule
        )
      } else {
        if (!selectedTcinRule) {
          this.props.getRuleItems()
        } else {
          this.props.disableLoadingCondition(false)
        }
      }
    })
  }

  saveRule() {
    const {
      ruleId,
      sizeChart,
      conditions,
      auth,
      name,
      priority,
      selectedTcinRule,
      tcinChipsArray,
      actionTypeForTcin,
      editModeSizeChartTcinList,
    } = this.props

    let finalArray = tcinChipsArray.map(function (obj) {
      return obj.value
    })

    let numberGenerator = uuidv4()
    let tcinRuleApiNumberGenarator = 'size_chart_by_tcin_' + numberGenerator

    let createModeCreteria = [
      {
        key: 'tcin_list_reference_id',
        values: editModeSizeChartTcinList
          ? conditions[0].values
          : [tcinRuleApiNumberGenarator],
      },
    ]

    let payload = {
      size_chart_info: {
        category: sizeChart.category,
        brand: sizeChart.brand,
        size: sizeChart.size,
        sizeChartId: sizeChart.sizeChartId,
      },
      type: selectedTcinRule ? 'size-rule-by-tcins' : null, // New Default Attribute
      list_of_tcins: finalArray.length > 0 ? finalArray : null, // Comma Separated tcins ,Should be sent in batches of 10000
      criteria: selectedTcinRule ? createModeCreteria : conditions,
      updated_by: auth.email,
      name: name || numberGenerator,
      priority,
      id: ruleId,
      action: actionTypeForTcin,
    }

    if (!this.hasValidSizeChart()) {
      this.props.sendWarning({
        ignore: false,
        open: true,
        message: 'Size Charts cannot have empty cells',
        ok: () => {
          this.props.sendWarning({
            error: {
              open: false,
              ignore: false,
              message: '',
              cancel: () => {},
              ok: () => {},
            },
          })
        },
        cancel: undefined,
      })
    } else {
      if (editModeSizeChartTcinList) {
        this.props
          .saveSizeChart({
            ...this.props.sizeChart,
            type: selectedTcinRule ? 'size-rule-by-tcins' : null, // New Default Attribute
            includeMeasurements: this.props.useMeasurementGuide,
            measurementCategory: this.props.useMeasurementGuide
              ? this.props.sizeChart.measurementCategory
              : '',
            user: { ...this.props.auth },
          })
          .then(
            this.props.saveRulePutCall({
              ...payload,
              size_chart_info: {
                ...payload.size_chart_info,
                size_chart_tab_name: this.props.sizeChart.sizeChartTabName,
              },
            })
          )
          .then(() => {
            this.props.resetTcinRuleSelection()
            this.props.getRules()
            this.setState({
              redirect: {
                ...this.state.redirect,
                redirect: true,
                target: '/v2/size-and-fit/',
              },
            })
          })
      } else {
        if (selectedTcinRule) {
          this.props.saveSizeChart(
            {
              ...this.props.sizeChart,
              type: selectedTcinRule ? 'size-rule-by-tcins' : null, // New Default Attribute
              includeMeasurements: this.props.useMeasurementGuide,
              measurementCategory: this.props.useMeasurementGuide
                ? this.props.sizeChart.measurementCategory
                : '',
              user: { ...this.props.auth },
            },
            payload,
            this.props.sizeChart.sizeChartTabName,
            selectedTcinRule
          )
        } else {
          this.props
            .saveRule({
              ...payload,
              size_chart_info: {
                ...payload.size_chart_info,
                size_chart_tab_name: this.props.sizeChart.sizeChartTabName,
              },
            })
            .then(
              this.props.saveSizeChart({
                ...this.props.sizeChart,
                type: selectedTcinRule ? 'size-rule-by-tcins' : null, // New Default Attribute
                includeMeasurements: this.props.useMeasurementGuide,
                measurementCategory: this.props.useMeasurementGuide
                  ? this.props.sizeChart.measurementCategory
                  : '',
                user: { ...this.props.auth },
              })
            )
            .then(() => {
              this.props.resetTcinRuleSelection()
              this.props.getRules()
              this.setState({
                redirect: {
                  ...this.state.redirect,
                  redirect: true,
                  target: '/v2/size-and-fit/',
                },
              })
            })
        }
      }
    }
  }

  redirect() {
    if (this.state.redirect.redirect || this.props.redirect) {
      return (
        <Navigate
          to={{
            pathname: this.props.redirect
              ? this.props.target
              : this.state.redirect.target,
            state: { refferer: '/v2/size-and-fit/edit-chart' },
          }}
        />
      )
    }
  }

  hasValidSizeChart() {
    const { charts } = this.props.sizeChart
    let out = true
    forEach(charts, (chart) => {
      forEach(chart.header, (cell) => {
        if (cell === '') {
          out = false
        }
      })
      forEach(chart.data, (row) => {
        forEach(row, (cell) => {
          if (cell === '') {
            out = false
          }
        })
      })
    })
    return out
  }

  handleStep(index, selectedTcinRule = false) {
    const { activeStep } = this.props
    if (
      index !== 0 &&
      activeStep === 0 &&
      this.props.totalResults >= 5000 &&
      !selectedTcinRule
    ) {
      let message = (
        <div>
          You're about to create a rule that could affect{' '}
          {this.props.totalResults} items.
        </div>
      )
      this.props.sendWarning({
        ignore: false,
        open: true,
        message,
        ok: () => {
          this.props.sendWarning({
            ignore: true,
            open: false,
            message: '',
          })
          this.props.setActiveStep(index)
        },
        cancel: () => {
          this.props.sendWarning({
            ignore: false,
            open: false,
            message: '',
          })
        },
      })
      return
    }
    if ((index === 1 || index === 2) && !this.hasFullSizeChartIdentifiers()) {
      // does not allow navigation to how to measure
      // or to edit size chart before cat / brand / size are selected
      return
    }

    // if currently editing the size chart and the size chart has holes in it
    if (activeStep === 1 && index !== 1 && !this.hasValidSizeChart()) {
      this.props.sendWarning({
        ignore: false,
        open: true,
        message: 'Size Charts cannot have empty cells',
        ok: () => {
          this.props.sendWarning({
            error: {
              open: false,
              ignore: false,
              message: '',
              cancel: () => {},
              ok: () => {},
            },
          })
        },
        cancel: undefined,
      })
    } else {
      // retrives the size chart whenever edit size chart or publish rule are selected
      if (index === 1 || index === 3) {
        // only retrieve sizechart from sizecharts api if we don't have any chart data
        // fixes bug where editting existing sizecharts gets overwritten with existing chart data on confirm page
        if (
          this.props.sizeChart === undefined ||
          !this.props.sizeChart.charts.length
        ) {
          this.props.getSizeChart(
            {
              ...this.props.sizeChart,
            },
            selectedTcinRule
          )
        }
      }
      this.props.completeStep(activeStep)
      this.props.setActiveStep(index)
    }
  }

  validateSpecialCharacters = (value) => {
    return /#|%/.test(value)
  }

  hasFullSizeChartIdentifiers() {
    const { sizeChart } = this.props
    return (
      sizeChart.category &&
      sizeChart.category !== '' &&
      !this.validateSpecialCharacters(sizeChart.category) &&
      sizeChart.brand &&
      sizeChart.brand !== '' &&
      !this.validateSpecialCharacters(sizeChart.brand) &&
      sizeChart.size &&
      sizeChart.size !== '' &&
      !this.validateSpecialCharacters(sizeChart.size)
    )
  }
  promptSave() {
    let message = (
      <div>
        You're about to save a rule that could affect {this.props.totalResults}{' '}
        items.
      </div>
    )
    this.props.sendWarning({
      ignore: false,
      open: true,
      message: message,
      ok: () => {
        this.saveRule()
      },
      cancel: () => {
        this.props.sendWarning({ open: false })
      },
    })
  }

  shouldNextBeDisabled(index, selectedTcinRule) {
    switch (index) {
      case 0: {
        return !this.hasFullSizeChartIdentifiers()
      }
      case 1: {
        return !this.hasValidSizeChart()
      }
      case 3: {
        const conditionsOnlyRelationshipType =
          this.props.conditions.length === 1 &&
          this.props.conditions.filter(
            (item) => item.key === 'relationship_type'
          ).length > 0
        return selectedTcinRule ? false : conditionsOnlyRelationshipType
      }
      default: {
        return false
      }
    }
  }

  render() {
    const {
      classes,
      warning,
      activeStep,
      stages,
      completedSteps,
      selectedTcinRule,
      fastlyLoading,
      sizeChartExistLoader,
      sizeChartExist,
    } = this.props

    return (
      <div id="RulesSelection" className={classes.RulesBuilderMain}>
        {this.redirect()}
        <HeaderTitle title="Size And Fit / Rule Builder" />
        {fastlyLoading || sizeChartExistLoader ? (
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open
            style={{ zIndex: 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}

        <Dialog open={warning.open}>
          <DialogContent>
            <DialogContentText id="Rule Builder Warning Text">
              {warning.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {warning.cancel ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={warning.cancel}
              >
                Cancel
              </Button>
            ) : (
              ''
            )}
            {warning.ok ? (
              <Button variant="contained" color="primary" onClick={warning.ok}>
                Ok
              </Button>
            ) : (
              ''
            )}
          </DialogActions>
        </Dialog>
        <div id="Rules Header" className={classes.RulesBuilderHeader}>
          <Paper>
            <div id="Stepper" className={classes.RulesBuilderStepper}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                nonLinear
                style={{ padding: '10px' }}
              >
                {stages.map((label, index) => {
                  const props = {}
                  const labelProps = {}
                  return (
                    <Step
                      completed={completedSteps[index]}
                      key={label}
                      {...props}
                    >
                      <StepButton
                        {...labelProps}
                        className={classes.RulesBuilderStepButton}
                        onClick={() => this.handleStep(index, selectedTcinRule)}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  )
                })}
              </Stepper>
            </div>
            <div className={classes.rulesBuilderControlWrapper}>
              <div className={classes.RulesBuilderControl}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.RulesBuilderControlButton}
                  onClick={() => {
                    this.props.resetTcinRuleSelection()
                    this.setState({
                      redirect: {
                        redirect: true,
                        target: '/v2/size-and-fit',
                      },
                    })
                  }}
                >
                  cancel
                </Button>
              </div>
              <div className={classes.RulesBuilderControl}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.RulesBuilderControlButton}
                  disabled={activeStep === 0}
                  onClick={() => this.handleStep(activeStep - 1)}
                >
                  back
                </Button>
              </div>
              <div className={classes.RulesBuilderControl}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.RulesBuilderControlButton}
                  disabled={
                    sizeChartExist ||
                    (selectedTcinRule && this.props.addTcinToApi.length === 0)
                      ? true
                      : this.shouldNextBeDisabled(activeStep, selectedTcinRule)
                  }
                  onClick={
                    activeStep === stages.length - 1
                      ? this.props.totalResults >= 5000 && !selectedTcinRule
                        ? () => this.promptSave()
                        : () => this.saveRule()
                      : () => this.handleStep(activeStep + 1, selectedTcinRule)
                  }
                >
                  {activeStep === stages.length - 1 ? 'Publish' : 'Next'}
                </Button>
              </div>
            </div>
          </Paper>
        </div>
        <div
          id="item_selection"
          className={
            activeStep === 0 ? classes.rulesBuilderContent : classes.hidden
          }
        >
          {activeStep === 0 ? (
            <SelectItems
              checkForSizeChartExist={this.props.checkForSizeChartExist}
            />
          ) : (
            ''
          )}
        </div>
        <div
          id="Size_Chart"
          className={
            activeStep === 1 ? classes.rulesBuilderContent : classes.hidden
          }
        >
          {activeStep === 1 ? <SizeChartEdit subComponent /> : ''}
        </div>
        <div
          id="How_To_Measure"
          className={
            activeStep === 2 ? classes.rulesBuilderContent : classes.hidden
          }
        >
          {activeStep === 2 ? <HowToMeasure /> : ''}
        </div>
        <div
          id="Rule_Confirmation"
          className={
            activeStep === 3 ? classes.rulesBuilderContent : classes.hidden
          }
        >
          {activeStep === 3 ? <RuleConfirmation /> : ''}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    layout,
    auth,
    sizeChartEdit,
    sizeChartRuleBuilder,
    sizeChartRuleSelection,
  } = state
  const { headerTitle } = layout
  const {
    sizeChart,
    useMeasurementGuide,
    fastlyLoading,
    redirect,
    target,
    sizeChartExistLoader,
    sizeChartExist,
  } = sizeChartEdit
  const {
    name,
    conditions,
    warning,
    completedSteps,
    stages,
    totalResults,
    priority,
    ruleId,
    activeStep,
    tcinChipsArray,
    addTcinToApi,
  } = sizeChartRuleBuilder
  const { selectedTcinRule, actionTypeForTcin, editModeSizeChartTcinList } =
    sizeChartRuleSelection
  return {
    headerTitle,
    auth,
    sizeChart,
    name,
    conditions,
    warning,
    activeStep,
    completedSteps,
    stages,
    useMeasurementGuide,
    totalResults,
    priority,
    ruleId,
    selectedTcinRule,
    tcinChipsArray,
    actionTypeForTcin,
    editModeSizeChartTcinList,
    addTcinToApi,
    fastlyLoading,
    redirect,
    target,
    sizeChartExistLoader,
    sizeChartExist,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRuleAttributes,
      getRule,
      saveRule,
      getRuleItems,
      getRules,
      getAllSizeChartIdentifiers,
      getSizeChart,
      saveSizeChart,
      resetSizeChart,
      setSaveSizechartError,
      resetState,
      sendWarning,
      setActiveStep,
      completeStep,
      disableLoadingCondition,
      resetTcinRuleSelection,
      saveRulePutCall,
      setSizeChartFastlyRedirect,
      checkForSizeChartExist,
    },
    dispatch
  )

RulesBuilder.defaultProps = {
  rules: {},
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RulesBuilder))
