import {
  ADD_FILES,
  IMAGES_DROP_ZONE_ACTIVE,
  VALID_NUMBER_OF_IMAGE_FILES,
  IMAGES_ON_DROP_LOADING,
  STORE_IMAGES_UPLOAD_STRAPI_DATA,
  IMAGE_UPLOAD_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_UPLOAD,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../../config/apiConfig'
import { isEmpty } from 'lodash'
import { TOAST_BODY } from '../../../components/Shared/Constants'
import { toast } from 'react-toastify'

export function resetFetch(data) {
  return {
    type: IMAGE_UPLOAD_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function addedFiles(files) {
  return {
    type: ADD_FILES,
    payload: {
      files: files,
    },
  }
}

export function validNumberFiles(numberValidFiles) {
  return {
    type: VALID_NUMBER_OF_IMAGE_FILES,
    payload: {
      numberValidFiles: numberValidFiles,
    },
  }
}

export function dropZoneActive(data) {
  return {
    type: IMAGES_DROP_ZONE_ACTIVE,
    payload: {
      dropZoneEnter: data,
    },
  }
}

export function onDropLoading(isFetchingOnDrop) {
  return {
    type: IMAGES_ON_DROP_LOADING,
    payload: {
      isFetchingOnDrop: isFetchingOnDrop,
    },
  }
}

export function storeImageUploadStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_IMAGES_UPLOAD_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALERT_IMAGE_UPLOAD,
    payload: data,
  }
}

export function getImageUploadStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Image%20Upload&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data)
          ? dispatch(showDefaultAlert(true))
          : dispatch(storeImageUploadStrapiData(response.data))
        dispatch(showDefaultAlert(false))
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}
