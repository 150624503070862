import {
  REQUEST_VIDEO_HISTORY_DATA,
  RECIEVE_VIDEO_HISTORY_DATA,
  CHANGE_CURRENT_PAGE_VIDEO_HISTORY,
  CHANGE_DEFAULT_PAGE_SIZE_VIDEO_HISTORY,
  VIDEO_HISTORY_RESET_FETCH,
  CHANGE_SEARCH_ITEM_VIDEO_HISTORY,
} from './actionType'

const DefaultState = {
  isFetching: false,
  videoHistoryData: undefined,
  defaultPageSize: 10,
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  searchedItems: [],
}

export default function videoHistoryReducer(state = DefaultState, action = {}) {
  switch (action.type) {
    case REQUEST_VIDEO_HISTORY_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case RECIEVE_VIDEO_HISTORY_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        videoHistoryData: payload.videoHistoryData,
        isFetching: payload.isFetching,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
      }
      return newState
    }
    case CHANGE_CURRENT_PAGE_VIDEO_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        currentPage: payload.currentPage,
      }
      return newState
    }
    case CHANGE_DEFAULT_PAGE_SIZE_VIDEO_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        defaultPageSize: payload.defaultPageSize,
      }
      return newState
    }
    case VIDEO_HISTORY_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case CHANGE_SEARCH_ITEM_VIDEO_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        searchedItems: payload.searchedItems,
      }
      return newState
    }
    default:
      return state
  }
}
