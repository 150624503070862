import { TextField, Typography } from '@material-ui/core'
import styles from '../../theme'
import { withStyles } from '@material-ui/core/styles'

function EditTag(props) {
  const { tagDescription, updateTagMetadata, isTagEditing, classes } = props

  return (
    <div>
      {isTagEditing ? (
        <TextField
          multiline
          fullWidth
          margin="normal"
          defaultValue={tagDescription}
          onBlur={updateTagMetadata}
        />
      ) : (
        <div className={classes.marginTopBottom10} role="presentation">
          <Typography>{tagDescription}</Typography>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(EditTag)
