export const RULES_RELATION_CHANGE = 'RULES_RELATION_CHANGE' // condition
export const RULES_CONDITION_CHANGE = 'RULES_CONDITION_CHANGE' // condition
export const RULES_ADDITION_CONDITION_SELECTION =
  'RULES_ADDITION_CONDITION_SELECTION' // condition
export const RULES_ADDITION_CONDITION_RESET = 'RULES_ADDITION_CONDITION_RESET' // condition
export const RULES_ADDITION_CONDITION_EXTRA_CHANGE =
  'RULES_ADDITION_CONDITION_EXTRA_CHANGE' // condition
export const RULES_ADDITION_CONDITION_INPUT_CHANGE =
  'RULES_ADDITION_CONDITION_INPUT_CHANGE' // condition
export const RULES_CONDITION_REMOVE = 'RULES_CONDITION_REMOVE' // condition
export const RULES_CONDITION_ATTRIBUTE_REMOVE =
  'RULES_CONDITION_ATTRIBUTE_REMOVE' // condition
export const RULES_SET_ITEMS = 'RULES_SET_ITEMS' // api
export const RULES_NAME_CHANGE = 'RULES_NAME_CHANGE' // builder
export const RULE_SET = 'RULE_SET' // api
export const RULE_GET_ERROR = 'RULE_GET_ERROR' // api
export const RULES_CONDITION_EDIT = 'RULES_CONDITION_EDIT' // condition
export const RULES_PRIORITY_CHANGE = 'RULES_PRIORITY_CHANGE' // builder
export const RULE_STATE_RESET = 'RULE_STATE_RESET' // api, builder
export const RULES_ATTRIBUTES_ADD = 'RULES_ATTRIBUTES_ADD' // api
export const RULES_GET_ITEMS_FAILED = 'RULES_GET_ITEMS_FAILED' // api
export const RULES_CONDITION_MANUALLY_ADD = 'RULES_CONDITION_MANUALLY_ADD' // condition
export const RULES_BUILDER_WARNING = 'RULES_BUILDER_WARNING' // builder
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP' // builder
export const RULES_COMPLETE_STEP = 'RULES_COMPLETE_STEP' // builder
export const RULES_ADDITION_CONDITION_OPERATOR_CHANGE =
  'RULES_ADDITION_CONDITION_OPERATOR_CHANGE' // selection
export const RULE_BUILDER_RESET_FETCH = 'RULE_BUILDER_RESET_FETCH'
export const VALIDATE_TCIN_BY_RULE_ID_LOADING =
  'VALIDATE_TCIN_BY_RULE_ID_LOADING'
export const VALIDATE_TCIN_BY_RULE_ID_RESPONSE =
  'VALIDATE_TCIN_BY_RULE_ID_RESPONSE'
export const VALIDATE_TCIN_BY_RULE_ID_CLEAR = 'VALIDATE_TCIN_BY_RULE_ID_CLEAR'
export const ADD_TCIN_CHIPS = 'ADD_TCIN_CHIPS'
export const DISABLE_LOADING_CONDITION = 'DISABLE_LOADING_CONDITION'
export const ADD_TCIN_CHIPS_TO_API = 'ADD_TCIN_CHIPS_TO_API'
