import React from 'react'
import EditTag from './EditTag'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateTagDescription } from '../../../../../../store/images/tagTraining/actionCreator'

class EditTagContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  updateTagDescription = (event) => {
    this.props.updateTagDescription(
      this.props.type,
      this.props.tag,
      event.target.value
    )
  }

  render() {
    return (
      <EditTag
        isTagEditing={this.props.isTagEditing}
        tagDescription={this.props.tagDescription}
        updateTagMetadata={this.updateTagDescription}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateTagDescription,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(EditTagContainer)
