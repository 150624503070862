/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '../../Shared/Autocomplete/Autocomplete'
import { connect } from 'react-redux'
import {
  Card,
  CardContent,
  Switch,
  Typography,
  IconButton,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  MenuItem,
  Select,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import {
  getVendorList,
  getBrandsList,
  setFetch,
} from '../../../store/listOfValues/actionCreator'
import styles from '../theme'
import { editSearchInfo } from '../../../store/sizeChart/sizeChartEdit/sizeChartDataActions'
import {
  setRelationType,
  setAdditionCondition,
  resetAdditionalCondition,
  updateAdditionalConditionInput,
  updateAdditionalConditionExtra,
  saveAdditionalCondition,
  removeCondition,
  removeConditionAttribute,
  editCondition,
  manuallyAddCondition,
  changeAdditionalConditionOperator,
  getRuleItems,
  addTcinChip,
  addTcinChipToApi,
} from '../../../store/sizeChart/ruleBuilder/actionCreator'
import ResultsTable from './SubComponents/ResultsTable'
import { isEqual, debounce } from 'lodash'
import RuleConditionViewer from './SubComponents/RuleConditionViewer'
import PasteTcinTextArea from './SubComponents/PasteTcinTextArea'
import { FREE_FORM, AUTOCOMPLETE, DOUBLE_COMBO_BOX } from './InputTypes'
import {
  variationRelationshipTypes,
  allSizeChartRelationshipTypes,
} from '../../../store/sizeChart/ruleBuilder/reducer'

import { actionTypeCrudOpp } from '../../../store/sizeChart/ruleSelection/actionCreator'
import SizeChartExisPopUp from './SizeChartExisPopUp'
import { checkForSizeChartPopUp } from '../../../store/sizeChart/sizeChartEdit/sizeChartHTTPActions'

function SelectItems(props) {
  const {
    classes,
    conditions,
    identifiers,
    sizeChart,
    categories,
    sizes,
    foundItems,
    totalResults,
    needsRefresh,
    selectedOperator,
    loadingConditions,
    getBrandsList,
    getVendorList,
    additionalConditionKey,
    ruleId,
    ruleByTcinList,
    tcinChipsArray,
    sizeChartRuleSelectionLoader,
    editModeSizeChartTcinList,
    addTcinToApi,
    actionTypeForTcin,
    sizeChartExistPopUp,
  } = props

  let { relationIndex } = props

  const { category, brand, size } = sizeChart

  const [searchText, setSearchText] = React.useState(
    props.additionalConditionInput
  )

  useEffect(() => {
    sizeChartCheck()
  }, [])

  const sizeChartCheck = () => {
    if (ruleByTcinList && !editModeSizeChartTcinList) {
      props.checkForSizeChartExist({
        category: category,
        brand: brand,
        size: size,
      })
    }
  }

  // Important for for being nice to our BE servers
  // Don't let the users fire off queries with every keystroke
  // https://dev.to/sbrshkappa/what-is-debouncing-a-javascript-implementation-3aoh
  const onSearchChangeInternal = (text) => {
    setSearchText(text)
  }

  const debounceOnSearchChange = React.useCallback(
    debounce(onSearchChangeInternal, 500),
    [getBrandsList, getVendorList]
  )

  React.useEffect(() => {
    getLovsFromServer(props.additionalConditionInput)
  }, [searchText, additionalConditionKey])

  React.useEffect(() => {
    debounceOnSearchChange(props.additionalConditionInput)
  }, [props.additionalConditionInput])

  const getLovsFromServer = (text) => {
    // 4 === manufacturer brands
    if (additionalConditionKey === 4) {
      getBrandsList(text)
    }
    // 2 === vendor
    if (additionalConditionKey === 2) {
      getVendorList(text)
    }
  }

  const fetchLovs = () => {
    // 4 === manufacturer brands
    if (props.additionalConditionKey === 4) {
      return props.brandsList.map((brand) => brand.brandName)
    }
    // 2 === vendor
    if (props.additionalConditionKey === 2) {
      return props.vendorList.map(
        (vendor) => `${vendor.gms_vendor_id} - ${vendor.vendor_name}`
      )
    }
    return []
  }

  const getBuilder = (ruleAttribute) => {
    const { classes, additionalConditionInput, additionalConditionExtra } =
      props

    const addConditionButton = (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        style={{ marginTop: '10px' }}
        onClick={() => {
          props.saveAdditionalCondition()
          props.resetAdditionalCondition()
        }}
      >
        Add Condition
      </Button>
    )

    const endAdornment = (
      <IconButton
        onClick={() => {
          let newAdditionalConditionExtra = additionalConditionExtra.slice(0)
          newAdditionalConditionExtra.push(additionalConditionInput)
          props.updateAdditionalConditionInput('')
          props.updateAdditionalConditionExtra(newAdditionalConditionExtra)
        }}
      >
        <Add />
      </IconButton>
    )

    switch (ruleAttribute.inputType) {
      case FREE_FORM: {
        return (
          <div>
            <div className={classes.AdditionalConditionsAutocomplete}>
              <Autocomplete
                placeholder={'Enter ' + ruleAttribute.display + ' Here'}
                value={additionalConditionInput}
                onChange={(e) =>
                  props.updateAdditionalConditionInput(e.target.value)
                }
                items={[]}
                endAdornment={endAdornment}
                fullWidth
              />
            </div>
            <div className={classes.AdditionalConditionsChipArray}>
              {additionalConditionExtra.map((value, index) => {
                return (
                  <Chip
                    key={index}
                    label={value}
                    onDelete={() => {
                      let newAdditionalConditionExtra =
                        additionalConditionExtra.slice(0)
                      newAdditionalConditionExtra.splice(index, 1)
                      props.updateAdditionalConditionExtra(
                        newAdditionalConditionExtra
                      )
                    }}
                    className={classes.selectionContditionChip}
                  />
                )
              })}
            </div>
            {additionalConditionExtra.length > 0 ? addConditionButton : ''}
          </div>
        )
      }
      case AUTOCOMPLETE: {
        return (
          <div>
            <div className={classes.AdditionalConditionsAutocomplete}>
              <Autocomplete
                placeholder={'Enter ' + ruleAttribute.display + ' Here'}
                value={props.additionalConditionInput}
                loading={props.lovIsFetching}
                onChange={(e) => {
                  props.updateAdditionalConditionInput(e.target.value)
                }}
                onSelect={(e) => {
                  let newAdditionalConditionExtra =
                    additionalConditionExtra.slice(0)
                  newAdditionalConditionExtra.push(e)
                  props.updateAdditionalConditionInput('')
                  props.updateAdditionalConditionExtra(
                    newAdditionalConditionExtra
                  )
                }}
                items={fetchLovs(ruleAttribute)}
                endAdornment={endAdornment}
                fullWidth
              />
            </div>
            <div className={classes.AdditionalConditionsChipArray}>
              {additionalConditionExtra.map((value, index) => {
                return (
                  <Chip
                    key={index}
                    label={value}
                    onDelete={() => {
                      let newAdditionalConditionExtra =
                        additionalConditionExtra.slice(0)
                      newAdditionalConditionExtra.splice(index, 1)
                      props.updateAdditionalConditionExtra(
                        newAdditionalConditionExtra
                      )
                    }}
                    className={classes.selectionContditionChip}
                  />
                )
              })}
            </div>
            {additionalConditionExtra.length > 0 ? addConditionButton : ''}
          </div>
        )
      }
      case DOUBLE_COMBO_BOX: {
        return (
          <div>
            <FormControl style={{ width: '100%' }}>
              <InputLabel shrink>{ruleAttribute.labels[0]}</InputLabel>
              <Select
                value={
                  additionalConditionInput === ''
                    ? -1
                    : additionalConditionInput
                }
                onChange={(e) =>
                  props.updateAdditionalConditionInput(e.target.value)
                }
                name={ruleAttribute.labels[0]}
                className={classes.selectionConditionSelect}
              >
                {ruleAttribute.data[0].map((value, index) => {
                  return (
                    <MenuItem value={index} key={index}>
                      {value.display}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            {additionalConditionInput !== -1 &&
            additionalConditionInput !== '' ? (
              <FormControl style={{ width: '100%' }}>
                <InputLabel shrink>{ruleAttribute.labels[1]}</InputLabel>
                <Select
                  value={additionalConditionExtra}
                  onChange={(e) =>
                    props.updateAdditionalConditionExtra(e.target.value)
                  }
                  name={ruleAttribute.labels[1]}
                  className={classes.selectionConditionSelect}
                  multiple
                  fullWidth
                >
                  {ruleAttribute.data[1][additionalConditionInput].map(
                    (value, index) => {
                      return (
                        <MenuItem value={index} key={index}>
                          {value.display}
                        </MenuItem>
                      )
                    }
                  )}
                </Select>
              </FormControl>
            ) : (
              ''
            )}
            {additionalConditionExtra.length > 0 ? addConditionButton : ''}
          </div>
        )
      }
    }
  }

  const mapRuleAttributes = () => {
    const { ruleAttributes } = props

    return ruleAttributes.map((ruleAttribute, index) => {
      return {
        name: ruleAttribute.display,
        type: ruleAttribute.type,
        builder: () => getBuilder(ruleAttribute),
      }
    })
  }
  if (relationIndex === -1 && conditions.length > 0) {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].key === 'relationship_type') {
        if (isEqual(conditions[i].values, variationRelationshipTypes)) {
          relationIndex = 0
        } else if (
          isEqual(conditions[i].values, allSizeChartRelationshipTypes)
        ) {
          relationIndex = 1
        }
      }
    }
  }

  if (needsRefresh && relationIndex !== -1 && !ruleByTcinList) {
    props.getRuleItems(conditions)
  }

  const validateSpecialCharacters = (value) => {
    return /#|%/.test(value)
  }

  return (
    <React.Fragment>
      {sizeChartRuleSelectionLoader ? (
        <div className={classes.resultsProgressWrapper}>
          <CircularProgress
            className={classes.resultsProgress}
            style={{
              width: '50px',
              height: '50px',
            }}
          />
          <Typography className={classes.resultsProgressText}>
            Results Loading...
          </Typography>
        </div>
      ) : (
        <div className={classes.selectItemsMain}>
          <div className={classes.selectItemsRulesBuilder}>
            <div className={classes.selectItemsPaper}>
              <SizeChartExisPopUp
                sizeChart={sizeChart}
                sizeChartExistPopUp={sizeChartExistPopUp}
                checkForSizeChartPopUp={(payload) =>
                  props.checkForSizeChartPopUp(payload)
                }
              />
              {/* rule search creation side */}
              <div className={classes.selectionItemsConditions}>
                <div style={{ width: '100%' }}>
                  <div id="sizechart_card">
                    <Card className={classes.selectionSizeChartCard}>
                      <CardContent>
                        <Typography
                          variant="h3"
                          className={classes.rulesBuilderCardHeader}
                        >
                          {'Size Chart Identifiers'}
                        </Typography>
                        <div className={classes.selectionSizeChartInput}>
                          <Autocomplete
                            value={category}
                            onChange={(e) => {
                              props.editSearchInfo({
                                key: 'category',
                                value: e.target.value,
                                sizeChart,
                                identifiers,
                              })
                            }}
                            onSelect={(e) => {
                              props.editSearchInfo({
                                key: 'category',
                                value: e,
                                sizeChart,
                                identifiers,
                              })
                            }}
                            items={categories}
                            placeholder={'Size Chart Category'}
                            onBlur={(e) => sizeChartCheck()}
                          />
                        </div>
                        <div className={classes.selectionSizeChartInput}>
                          <Autocomplete
                            value={brand}
                            onChange={(e) => {
                              props.editSearchInfo({
                                key: 'brand',
                                value: e.target.value,
                                sizeChart,
                                identifiers,
                              })
                            }}
                            onSelect={(e) => {
                              props.editSearchInfo({
                                key: 'brand',
                                value: e,
                                sizeChart,
                                identifiers,
                              })
                            }}
                            items={props.brandsList.map((r) => r.brandName)}
                            placeholder={'Size Chart Brand'}
                            onBlur={(e) => sizeChartCheck()}
                          />
                        </div>
                        <div className={classes.selectionSizeChartInput}>
                          <Autocomplete
                            value={size}
                            onChange={(e) => {
                              props.editSearchInfo({
                                key: 'size',
                                value: e.target.value,
                                sizeChart,
                                identifiers,
                              })
                            }}
                            onSelect={(e) => {
                              props.editSearchInfo({
                                key: 'size',
                                value: e,
                                sizeChart,
                                identifiers,
                              })
                            }}
                            items={sizes}
                            placeholder={'Size Chart Sizing'}
                            onBlur={(e) => sizeChartCheck()}
                          />
                        </div>
                      </CardContent>
                      <div className={classes.urlEncoderError}>
                        {validateSpecialCharacters(category) ||
                        validateSpecialCharacters(brand) ||
                        validateSpecialCharacters(size)
                          ? 'Special characters %,# not allowed'
                          : ''}
                      </div>
                    </Card>
                  </div>
                  {!ruleByTcinList && (
                    <div id="other_condition_card">
                      <Card className={classes.selectionRelationshipCard}>
                        <CardContent>
                          <div
                            className={
                              classes.rulesBuilderAdditionalConditionHeader
                            }
                          >
                            <div
                              className={
                                classes.rulesBuilderAdditionalConditionTitle
                              }
                            >
                              <Typography
                                variant="h3"
                                style={{ marginBottom: '15px' }}
                                className={classes.rulesBuilderCardHeader}
                              >
                                Additional Conditions
                              </Typography>
                            </div>
                            <div
                              className={
                                classes.rulesBuilderOperatorSelectionWrapper
                              }
                            >
                              <Switch
                                checked={selectedOperator === 1}
                                onChange={(e) =>
                                  props.changeAdditionalConditionOperator(
                                    e.target.checked ? 1 : 0
                                  )
                                }
                                color="primary"
                              />
                              <Typography
                                variant="button"
                                style={{ margin: 'auto' }}
                              >
                                {selectedOperator === 0 ? 'Include' : 'Exclude'}
                              </Typography>
                            </div>
                          </div>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel shrink>Attribute</InputLabel>
                            <Select
                              value={props.additionalConditionKey}
                              onChange={(e) =>
                                props.setAdditionCondition(e.target.value)
                              }
                              name="Additional Attribute Selector"
                              className={classes.selectionConditionSelect}
                            >
                              {mapRuleAttributes().map((item, index) => {
                                return (
                                  <MenuItem value={index} key={index}>
                                    {item.name}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                          <div
                            className={
                              classes.selectionAdditionalContitionBuilder
                            }
                          >
                            {props.additionalConditionKey >= 0
                              ? mapRuleAttributes()[
                                  props.additionalConditionKey
                                ].builder()
                              : ''}
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  )}
                  {!ruleByTcinList && (
                    <div id="relation_card">
                      <Card className={classes.selectionRelationshipCard}>
                        <CardContent>
                          <Typography
                            variant="h3"
                            style={{ marginBottom: '15px' }}
                            className={classes.rulesBuilderCardHeader}
                          >
                            {'Relationship Type'}
                          </Typography>
                          <FormControl
                            className={classes.selectionRelationshipForm}
                          >
                            <RadioGroup
                              name="Relationship Type"
                              className={classes.selectionRelationshipGroup}
                              value={'' + relationIndex}
                              onChange={(e) => {
                                props.setRelationType(e.target.value)
                              }}
                            >
                              <FormControlLabel
                                value={'' + 0}
                                control={<Radio color="primary" />}
                                label="VAP, VPC & VC"
                              />
                              <FormControlLabel
                                value={'' + 1}
                                control={<Radio color="primary" />}
                                label="VAP, VPC, VC, SA & CC"
                              />
                            </RadioGroup>
                          </FormControl>
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </div>
              </div>

              {/* rule search review */}

              <div className={classes.selectItemConditionsView}>
                {!ruleByTcinList && (
                  <RuleConditionViewer
                    conditions={conditions}
                    editCondition={(payload) => props.editCondition(payload)}
                    removeCondition={(payload) =>
                      props.removeCondition(payload)
                    }
                    removeConditionAttribute={(payload) =>
                      props.removeConditionAttribute(payload)
                    }
                  />
                )}
                {ruleByTcinList && (
                  <PasteTcinTextArea
                    editModeSizeChartTcinList={editModeSizeChartTcinList}
                    actionTypeCrudOpp={(mode) => props.actionTypeCrudOpp(mode)}
                    addTcinChipToApi={(payload) =>
                      props.addTcinChipToApi(payload)
                    }
                    addTcinChip={(payload) => props.addTcinChip(payload)}
                    tcinChipsArray={tcinChipsArray}
                    addTcinToApi={addTcinToApi}
                    actionTypeForTcin={actionTypeForTcin}
                  />
                )}
              </div>
            </div>
          </div>
          {/* Rule Search Results */}
          {(!ruleByTcinList || editModeSizeChartTcinList) && (
            <div className={classes.selectItemsPreview}>
              <ResultsTable
                ruleId={ruleId}
                foundItems={foundItems}
                totalResults={totalResults}
                refreshing={needsRefresh || loadingConditions}
                editModeSizeChartTcinList={editModeSizeChartTcinList}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

SelectItems.defaultProps = {
  category: '',
  brand: '',
  size: '',
}

export const mapStateToProps = (state) => {
  const { sizeChartRuleBuilder, sizeChartRuleSelection } = state
  const {
    relationIndex,
    additionalConditionKey,
    additionalConditionInput,
    additionalConditionExtra,
    ruleAttributes,
    ruleId,
    addTcinToApi,
  } = sizeChartRuleBuilder
  const { actionTypeForTcin } = sizeChartRuleSelection
  return {
    // size chart stuff
    sizeChart: state.sizeChartEdit.sizeChart,
    identifiers: state.sizeChartEdit.identifiers,
    categories: state.sizeChartEdit.categories,
    sizes: state.sizeChartEdit.sizes,
    sizeChartExistPopUp: state.sizeChartEdit.sizeChartExistPopUp,

    // Select Items Specific
    relationIndex,
    additionalConditionKey,
    additionalConditionInput,
    additionalConditionExtra,
    ruleAttributes,
    ruleId,

    // Non Specific stuff
    conditions: state.sizeChartRuleBuilder.conditions,
    foundItems: state.sizeChartRuleBuilder.foundItems,
    needsRefresh: state.sizeChartRuleBuilder.needsRefresh,
    totalResults: state.sizeChartRuleBuilder.totalResults,
    selectedOperator: state.sizeChartRuleBuilder.selectedOperator,
    loadingConditions: state.sizeChartRuleBuilder.loadingConditions,

    vendorList: state.lov.vendorList,
    brandsList: state.lov.brandsList,
    lovIsFetching: state.lov.isFetching,
    ruleByTcinList: state.sizeChartRuleSelection.selectedTcinRule,
    tcinChipsArray: state.sizeChartRuleBuilder.tcinChipsArray,
    sizeChartRuleSelectionLoader:
      state.sizeChartRuleSelection.sizeChartRuleSelectionLoader,
    editModeSizeChartTcinList:
      state.sizeChartRuleSelection.editModeSizeChartTcinList,
    addTcinToApi,
    actionTypeForTcin,
  }
}

export function mapDispatchToProps(dispatch) {
  // these are all the functions which will change what the page looks like
  // in a stateless component, all of these actions SHOULD be handled in props
  return {
    // Misc Size Chart Actions
    editSearchInfo: (payload) => dispatch(editSearchInfo(payload)),

    // Condition Actions
    setRelationType: (index) => dispatch(setRelationType(index)),
    setAdditionCondition: (index) => dispatch(setAdditionCondition(index)),
    resetAdditionalCondition: () => dispatch(resetAdditionalCondition()),
    updateAdditionalConditionInput: (value) =>
      dispatch(updateAdditionalConditionInput(value)),
    updateAdditionalConditionExtra: (value) =>
      dispatch(updateAdditionalConditionExtra(value)),
    saveAdditionalCondition: () => dispatch(saveAdditionalCondition()),
    removeCondition: (payload) => dispatch(removeCondition(payload)),
    removeConditionAttribute: (payload) =>
      dispatch(removeConditionAttribute(payload)),
    editCondition: (conditionIndex) => dispatch(editCondition(conditionIndex)),
    addCondition: (payload) => dispatch(manuallyAddCondition(payload)),
    changeAdditionalConditionOperator: (payload) =>
      dispatch(changeAdditionalConditionOperator(payload)),

    // api Actions
    getRuleItems: () => dispatch(getRuleItems()),

    // lov
    getVendorList: (text) => dispatch(getVendorList(text)),
    getBrandsList: (text) => dispatch(getBrandsList(text)),
    setFetch: () => dispatch(setFetch()),
    addTcinChip: (tcinChipArray) => dispatch(addTcinChip(tcinChipArray)),
    addTcinChipToApi: (tcinChipArray) =>
      dispatch(addTcinChipToApi(tcinChipArray)),
    actionTypeCrudOpp: (mode) => dispatch(actionTypeCrudOpp(mode)),
    checkForSizeChartPopUp: (payload) =>
      dispatch(checkForSizeChartPopUp(payload)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SelectItems))
