import React from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import styles from '../../../theme'
import { withStyles } from '@material-ui/core/styles'
import changeCase from 'change-case'

function convertKeyName(name) {
  return changeCase.titleCase(name.split('_').join(' '))
}

function escapeHtml(input) {
  return { __html: input }
}

function convertHeader(name) {
  return changeCase.ucFirst(name)
}

function ModalData(props) {
  const { modalMetadata, modalMetadataFetching, classes } = props
  return (
    <React.Fragment>
      {modalMetadataFetching ? (
        <CircularProgress />
      ) : (
        <Grid item xs={12}>
          {modalMetadata.length > 0 && (
            <div>
              <div className={classes.productDetailHeader}>Model Details</div>
              <div className={classes.productModalContainer}>
                {modalMetadata.map((item) => {
                  return (
                    <div key={item}>
                      <div>
                        <Typography variant="subtitle2">
                          {convertHeader(item.view_type)}
                        </Typography>
                      </div>
                      <div className={classes.paddingLeft20}>
                        {Object.keys(item.metadata).map(function (key) {
                          return (
                            <div key={key}>
                              <span>
                                <b>{convertKeyName(key)}: </b>
                              </span>
                              <span
                                dangerouslySetInnerHTML={escapeHtml(
                                  item.metadata[key]
                                )}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </Grid>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(ModalData)
