import ImageData from './ImageData'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  handleChangePage,
  changeDefaultPageSize,
  handleSelectImage,
  setSortField,
  setSortDirection,
  updateImageData,
  resetImageData,
  publishImageData,
  setCopiedImage,
  toggleRelatedItemsModal,
  searchForRelatedItems,
  toggleSyncItemsModal,
  searchForItemImages,
  syncImages,
  saveSwatch,
  toggleEditSwatchModal,
  clearImageManageDataEvent,
  buildFilterAction,
  toggleEditImageLabelDialogue,
  getAssetLabel,
  postAssetLabel,
} from '../../../../store/images/manage/actionCreator'
import { savePaginationDataEvent } from '../../../../store/auth/actionCreator'
import { cloneDeep, maxBy, isNil } from 'lodash'
import {
  flattenImagesForTcin,
  sequenceToString,
  gridCheckedLogic,
} from '../../../Shared/SharedUtils'
import { IMAGE_ROW_ID, IMAGE_VIEW_TYPE } from '../../../Shared/Constants'

export function ImageDataContainer(props) {
  const handleSelectAllClick = (_, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = props.ismData
    }
    props.handleSelectImage({
      selectedImages: newSelected,
    })
  }

  const handleClick = (_, checkedImage) => {
    const { selectedImages } = props

    props.handleSelectImage({
      selectedImages: gridCheckedLogic(selectedImages, checkedImage),
    })
  }

  const handleChangePage = (_, page) => {
    props.handleChangePage(
      {
        currentPage: page,
        defaultPageSize: props.defaultPageSize,
        selectedFilters: props.selectedFilters,
      },
      props.sortDirection,
      props.sortField
    )
  }

  const handleChangeDefaultPageSize = (event) => {
    props.changeDefaultPageSize(
      {
        defaultPageSize: event.target.value,
        selectedFilters: props.selectedFilters,
      },
      props.sortDirection,
      props.sortField,
      props.pageName
    )
  }

  const isSelected = (checkedImage) =>
    props.selectedImages.indexOf(checkedImage) !== -1

  const updateImageData = (imageData) => {
    let newIsmData = cloneDeep(props.ismData)
    const foundIndex = newIsmData.findIndex(
      (item) => item.tcin === imageData.tcin
    )
    newIsmData[foundIndex] = imageData
    props.updateImageData(newIsmData)
  }

  const setCopiedImage = (image) => {
    props.setCopiedImage(image)
  }

  const currentMaxSequence = (item) => {
    const maxSequence = maxBy(flattenImagesForTcin(item).imageList, (image) => {
      return parseInt(image.sequence)
    })
    return maxSequence ? parseInt(maxSequence.sequence) : 0
  }

  const pasteImage = (imageData, type) => {
    let newIsmData = cloneDeep(props.ismData)
    let newImageData = cloneDeep(imageData)

    const destinationSourceId = !isNil(props.copiedImage.imageId)
      ? props.copiedImage.imageId
      : props.copiedImage.sourceId

    // eslint-disable-next-line default-case
    switch (type) {
      case IMAGE_VIEW_TYPE.PRIMARY:
        newImageData.rows.primary.images.push({
          ...props.copiedImage,
          imageId: null,
          sourceId: destinationSourceId,
          sequence: '00',
          viewType: IMAGE_VIEW_TYPE.PRIMARY,
          active: true,
          changed: true,
        })
        break
      case IMAGE_VIEW_TYPE.ALTERNATE:
        if (props.copiedImage.active) {
          newImageData.rows.alternate.images.push({
            ...props.copiedImage,
            imageId: null,
            sourceId: destinationSourceId,
            sequence: sequenceToString(currentMaxSequence(newImageData)),
            viewType: IMAGE_ROW_ID.ALTERNATE,
            changed: true,
          })
        } else {
          newImageData.rows.disabled.images.push({
            ...props.copiedImage,
            imageId: null,
            sourceId: destinationSourceId,
            sequence: sequenceToString(currentMaxSequence(newImageData)),
            changed: true,
          })
        }
        break
      case IMAGE_VIEW_TYPE.SWATCH:
        // eslint-disable-next-line no-case-declarations
        const imageId =
          !isNil(imageData.swatchImage) && !isNil(imageData.swatchImage.imageId)
            ? imageData.swatchImage.imageId
            : null
        // swatch pastes in place, so if imageId exists, include it: CEP-4326
        newImageData = {
          ...newImageData,
          swatchImage: {
            ...props.copiedImage,
            imageId: imageId,
            sourceId: destinationSourceId,
            changed: true,
          },
        }
    }

    const foundIndex = newIsmData.findIndex(
      (item) => item.tcin === imageData.tcin
    )
    newIsmData[foundIndex] = newImageData
    props.updateImageData(newIsmData)
    // after paste, exit copy mode
    setCopiedImage(null)
  }

  const manageTcins = (tcins) => {
    props.toggleRelatedItemsModal(false)
    props.buildFilterAction(
      {
        selectedFilters: [],
        selectedFilter: {
          display: 'TCIN(s)',
          value: 'tcin',
        },
        selectedFilterValue: tcins.map((tcin) => ({
          display: tcin,
          value: tcin,
        })),
      },
      rowsPerPage,
      'asc',
      'TCIN'
    )
  }

  const syncImages = (tcin, auth) => {
    props.syncImages(
      tcin,
      auth,
      selectedFilters,
      rowsPerPage,
      sortDirection,
      sortField
    )
  }

  const backToSearch = (_) => {
    props.clearImageManageDataEvent()
    props.backToSearch()
  }

  const {
    defaultPageSize,
    ismData,
    originalIsmData,
    currentPage,
    selectedImages,
    totalElements,
    auth,
    selectedFilters,
    rowsPerPage,
    sortDirection,
    sortField,
    setSortDirection,
    setSortField,
    savePaginationDataEvent,
    pageName,
    resetImageData,
    publishImageData,
    copiedImage,
    relatedItemsData,
    relatedTcin,
    showRelatedItemsModal,
    itemToSyncFrom,
    itemToSyncTo,
    showSyncItemsModal,
    setZoom,
    rowIndex,
    colIndex,
  } = props

  const emptyRows =
    defaultPageSize -
    Math.min(defaultPageSize, (totalElements - currentPage) * defaultPageSize)

  return (
    <ImageData
      data={ismData}
      originalData={originalIsmData}
      selectedImages={selectedImages}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      emptyRows={emptyRows}
      totalElements={totalElements}
      handleSelectAllClick={handleSelectAllClick}
      isSelected={isSelected}
      auth={auth}
      handleClick={handleClick}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeDefaultPageSize}
      selectedFilters={selectedFilters}
      sortDirection={sortDirection}
      sortField={sortField}
      setSortDirection={setSortDirection}
      setSortField={setSortField}
      savePaginationDataEvent={savePaginationDataEvent}
      pageName={pageName}
      updateImageData={updateImageData}
      resetImageData={resetImageData}
      publishImageData={publishImageData}
      copiedImage={copiedImage}
      setCopiedImage={setCopiedImage}
      pasteImage={pasteImage}
      searchRelatedItems={props.searchForRelatedItems}
      toggleRelatedItemsModal={props.toggleRelatedItemsModal}
      relatedItemsData={relatedItemsData}
      relatedTcin={relatedTcin}
      showRelatedItemsModal={showRelatedItemsModal}
      manageTcins={manageTcins}
      itemToSyncFrom={itemToSyncFrom}
      itemToSyncTo={itemToSyncTo}
      showSyncItemsModal={showSyncItemsModal}
      toggleSyncItemsModal={props.toggleSyncItemsModal}
      openSyncItemsModal={props.searchForItemImages}
      syncImages={syncImages}
      saveSwatch={props.saveSwatch}
      toggleEditSwatchModal={props.toggleEditSwatchModal}
      showEditSwatchModal={props.showEditSwatchModal}
      backToSearch={backToSearch}
      setZoom={setZoom}
      isEditImageLabelOpen={props.isEditImageLabelOpen}
      labelTcin={props.labelTcin}
      currentAddLabelImage={props.currentAddLabelImage}
      toggleEditImageLabelDialogue={props.toggleEditImageLabelDialogue}
      rowIndex={rowIndex}
      colIndex={colIndex}
      getAssetLabel={props.getAssetLabel}
      savedLabelList={props.savedLabelList}
      postAssetLabel={props.postAssetLabel}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleChangePage,
      changeDefaultPageSize,
      handleSelectImage,
      savePaginationDataEvent,
      setSortField,
      setSortDirection,
      updateImageData,
      resetImageData,
      publishImageData,
      setCopiedImage,
      toggleRelatedItemsModal,
      searchForRelatedItems,
      toggleSyncItemsModal,
      searchForItemImages,
      syncImages,
      saveSwatch,
      toggleEditSwatchModal,
      clearImageManageDataEvent,
      buildFilterAction,
      toggleEditImageLabelDialogue,
      getAssetLabel,
      postAssetLabel,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageManage, auth } = state
  const {
    ismData,
    originalIsmData,
    currentPage,
    selectedImages,
    defaultPageSize,
    totalElements,
    selectedFilters,
    pageName,
    copiedImage,
    relatedItemsData,
    showRelatedItemsModal,
    relatedTcin,
    itemToSyncFrom,
    itemToSyncTo,
    showSyncItemsModal,
    showEditSwatchModal,
    isEditImageLabelOpen,
    labelTcin,
    currentAddLabelImage,
    rowIndex,
    colIndex,
    savedLabelList,
  } = imageManage
  return {
    ismData,
    originalIsmData,
    currentPage,
    selectedImages,
    defaultPageSize,
    auth,
    totalElements,
    selectedFilters,
    pageName,
    copiedImage,
    relatedItemsData,
    showRelatedItemsModal,
    relatedTcin,
    itemToSyncFrom,
    itemToSyncTo,
    showSyncItemsModal,
    showEditSwatchModal,
    isEditImageLabelOpen,
    labelTcin,
    currentAddLabelImage,
    rowIndex,
    colIndex,
    savedLabelList,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageDataContainer)
