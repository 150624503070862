import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Paper, Typography, TextField, MenuItem } from '@material-ui/core'
import styles from '../theme'
import RuleConditionViewer from './SubComponents/RuleConditionViewer'
import SizeChartUI from './SizeChart/SizeChartUI'
import ResultsTable from './SubComponents/ResultsTable'
import {
  setRuleName,
  setRulePriority,
} from '../../../store/sizeChart/ruleBuilder/actionCreator'

class RuleConfirmation extends React.Component {
  render() {
    const {
      classes,
      conditions,
      priority,
      name,
      foundItems,
      totalResults,
      useMeasurementGuide,
      loadingConditions,
      howToMeasure,
      selectedTcinRule,
    } = this.props

    let priorityValues = ['1', '2', '3', '4', '5']

    return (
      <div className={classes.ruleConfirmationMain}>
        <Paper className={classes.ruleConfirmationFinalSettings}>
          <Typography className={classes.ruleConfirmationTitle}>
            Final Settings
          </Typography>
          <div className={classes.ruleConfirmationFinalSettingsInputWrapper}>
            {!selectedTcinRule && (
              <div className={classes.ruleConfirmationPriorityWrapper}>
                <Typography className={classes.ruleConfirmationLabel}>
                  Rule Priority:
                </Typography>
                <TextField
                  value={priority === undefined ? 1 : '' + priority}
                  onChange={(e) => this.props.changePriority(e.target.value)}
                  className={classes.ruleConfirmationPriorityInput}
                  select
                >
                  {priorityValues.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </div>
            )}
            <div className={classes.ruleConfirmationNameWrapper}>
              <Typography className={classes.ruleConfirmationLabel}>
                Rule Name:
              </Typography>
              <TextField
                value={name}
                className={classes.ruleConfirmationNameInput}
                onChange={(e) => this.props.changeName(e.target.value)}
                placeholder={'Name Defaults to a Random String if not Entered'}
              />
            </div>
            <div className={classes.ruleConfirmationHowToMeasure}>
              <Typography className={classes.ruleConfirmationLabel}>
                How To Measure:{' '}
                {!howToMeasure
                  ? 'Does Not Exist'
                  : useMeasurementGuide
                  ? ' Enabled'
                  : ' Disabled'}
              </Typography>
            </div>
          </div>
        </Paper>
        {!selectedTcinRule && (
          <RuleConditionViewer conditions={conditions} readOnly multiColumn />
        )}
        <div className={classes.rulesConfirmationPreviews}>
          <div className={classes.ruleConfirmationSizeChartPreview}>
            <SizeChartUI subComponent readOnly />
          </div>
          <div className={classes.ruleConfirmationItemPreview}>
            {!selectedTcinRule && (
              <ResultsTable
                foundItems={foundItems}
                totalResults={totalResults}
                refreshing={loadingConditions}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

RuleConfirmation.defaultProps = {
  category: '',
  brand: '',
  size: '',
}

export const mapStateToProps = (state) => {
  const { sizeChartEdit, sizeChartRuleBuilder, sizeChartRuleSelection } = state
  const { useMeasurementGuide, howToMeasure } = sizeChartEdit
  const { conditions, priority, name, foundItems, totalResults } =
    sizeChartRuleBuilder
  const { selectedTcinRule } = sizeChartRuleSelection
  return {
    conditions,
    priority,
    name,
    foundItems,
    useMeasurementGuide,
    totalResults,
    howToMeasure,
    selectedTcinRule,
  }
}

export function mapDispatchToProps(dispatch) {
  // these are all the functions which will change what the page looks like
  // in a stateless component, all of these actions SHOULD be handled in props
  return {
    changeName: (name) => dispatch(setRuleName(name)),
    changePriority: (priority) => dispatch(setRulePriority(priority)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RuleConfirmation))
