import React from 'react'
import { connect } from 'react-redux'
import { withStyles, Input, IconButton, Button, Grid } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import HeaderTitle from '../Shared/Header/HeaderTitle'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import styles from './Styles/theme'
import {
  enterTcin,
  enterMtaId,
  searchTcinProdSpecs,
  searchMtaData,
  resetFetch,
} from '../../store/prodSpecs/actionCreator'
import ItemDetailsContainer from './Components/ItemDetails/ItemDetailsContainer'
import ItemDataContainer from './Components/ItemData/ItemDataContainer'
import MtaDetailsContainer from './Components/MtaDetails/MtaDetailsContainer'
import { regexSelector, validateSingleTcin } from '../Shared/SharedUtils'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../Shared/Constants'

export class ProductSpecs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSideBar: false,
    }
  }

  getTcinData = (tcin) => {
    if (validateSingleTcin(tcin)) {
      this.props.searchTcinProdSpecs(tcin)
    } else {
      this.props.resetFetch(false)
      toast.error('Please Enter A Valid TCIN', TOAST_BODY)
    }
  }

  render() {
    const {
      classes = {},
      enteredTcin = '',
      enteredMtaId = '',
      itemProdSpecs = null,
      itemMtaData = null,
      mtaData = null,
      enterTcin = () => {},
      enterMtaId = () => {},
      searchMtaData = () => {},
    } = this.props

    return (
      <React.Fragment>
        <HeaderTitle title="Product Specs / Auto Bullets" />
        <Helmet title="Product Specs / Auto Bullets" />
        {itemMtaData && (
          <div className={classes.positionButton}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.prodSpecButton}
              onClick={() =>
                this.setState(
                  itemMtaData
                    ? { ...this.state, showSideBar: !this.state.showSideBar }
                    : { showSideBar: false }
                )
              }
            >
              Item Data
            </Button>
          </div>
        )}
        {this.state.showSideBar && itemMtaData && <ItemDataContainer />}
        <div className={classes.displayInlineFlex}>
          <Grid container spacing={0} direction="row">
            <Grid item>
              <Input
                className={classes.input}
                placeholder="enter a tcin"
                onChange={(e) => enterTcin(regexSelector('number', e))}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.getTcinData(enteredTcin)
                  }
                }}
                value={enteredTcin}
                disabled={mtaData && enteredMtaId === 6}
                endAdornment={
                  enteredTcin === '' ? null : (
                    <IconButton
                      onClick={() => {
                        enterTcin('')
                        this.setState({ ...this.state, showSideBar: false })
                      }}
                    >
                      <Clear />
                    </IconButton>
                  )
                }
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.prodSpecButton}
                onClick={() => {
                  this.getTcinData(enteredTcin)
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid item>
              <Input
                className={classes.input}
                placeholder="enter a MTA ID"
                onChange={(e) => enterMtaId(regexSelector('number', e))}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchMtaData(enteredMtaId)
                  }
                }}
                value={enteredMtaId}
                endAdornment={
                  enteredMtaId === '' ? null : (
                    <IconButton onClick={() => enterMtaId('')}>
                      <Clear />
                    </IconButton>
                  )
                }
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.prodSpecButton}
                onClick={() => searchMtaData(enteredMtaId)}
              >
                Search
              </Button>
            </Grid>
            {mtaData && (
              <div className={classes.mtaDetails}>
                <MtaDetailsContainer />
              </div>
            )}
          </Grid>
        </div>
        {/* Uncommment and fill later once proper endpoints are made and implemented into Pipeline */}
        {/* <div className={classes.box}>
            <div className={classes.mtaNumbers}>####</div>
            <div className={classes.mtaText}>MTAs managed</div>
          </div>
          <div className={classes.box}>
            <div className={classes.mtaNumbers}>####</div>
            <div className={classes.mtaText}>
            MTAs not managed
              <div>(displayed as-is)</div>
            </div>
          </div> */}
        {itemProdSpecs && <ItemDetailsContainer />}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enterTcin,
      enterMtaId,
      searchTcinProdSpecs,
      searchMtaData,
      resetFetch,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { prodSpecs } = state
  const { enteredMtaId, enteredTcin, itemProdSpecs, itemMtaData, mtaData } =
    prodSpecs
  return {
    enteredMtaId,
    enteredTcin,
    itemProdSpecs,
    itemMtaData,
    mtaData,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProductSpecs))
