import {
  REQUEST_HISTORY_DATA,
  RECIEVE_HISTORY_DATA,
  CHANGE_CURRENT_PAGE_IMAGE_HISTORY,
  CHANGE_DEFAULT_PAGE_SIZE_IMAGE_HISTORY,
  BUILD_FILTER_EXPAND_IMAGES,
  STORE_IMAGE_HISTORY_STRAPI_DATA,
  NEW_FILTER_EVENT_IMAGE_HISTORY,
  REMOVE_FILTER_EVENT_IMAGE_HISTORY,
  BUILD_FILTER_EVENT_IMAGE_HISTORY,
  CHANGE_FILTER_EVENT_IMAGE_HISTORY,
  SELECT_FILTER_VALUE_EVENT_IMAGE_HISTORY,
  IMAGE_HISTORY_RESET_FETCH,
  SHOW_DEFAULT_ALERT_IMAGE_HISTORY,
  ERROR_SEARCHING_IMAGE_HISTORY,
} from './actionType'

const initState = {
  buildFilterExpansionPanel: false,
  currentPage: 0,
  defaultPageSize: 10,
  historyData: null,
  isFetching: false,
  totalElements: 0,
  totalPages: 0,
  alert: null,
  information: null,
  faq: null,
  buildFilterContainerShown: false,
  newFilterContainerShown: false,
  historyPageContainerShown: false,
  selectedFilters: [],
  filterValues: {
    dateRange: -1,
    tcins: [],
    emails: [],
    fromDate: -1,
    toDate: -1,
  },
  defaultFilterValues: {
    dateRange: -1,
    tcins: [],
    emails: [],
    fromDate: -1,
    toDate: -1,
  },
  selectedFilter: -1,
  showDefaultAlert: false,
  sortDirection: 'asc',
  sortField: 'TCIN',
  // DO NOT CHANGE THE PAGENAME!
  pageName: 'ImageUploadHistory',
  errorSearching: false,
}

export default function imageHistoryReducer(state = initState, action = {}) {
  switch (action.type) {
    case REQUEST_HISTORY_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case RECIEVE_HISTORY_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        historyData: payload.historyData,
        isFetching: payload.isFetching,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
      }
      return newState
    }
    case CHANGE_CURRENT_PAGE_IMAGE_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        currentPage: payload.currentPage,
      }
      return newState
    }
    case CHANGE_DEFAULT_PAGE_SIZE_IMAGE_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        defaultPageSize: payload.defaultPageSize,
        currentPage: initState.currentPage,
      }
      return newState
    }
    case BUILD_FILTER_EXPAND_IMAGES: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterExpansionPanel: payload.buildFilterExpansionPanel,
      }
      return newState
    }
    case STORE_IMAGE_HISTORY_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
      }
      return newState
    }
    case NEW_FILTER_EVENT_IMAGE_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterContainerShown: payload,
      }
      return newState
    }
    case REMOVE_FILTER_EVENT_IMAGE_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        historyPageContainerShown: payload.historyPageContainerShown,
        selectedFilters: payload.selectedFilters,
        historyData: initState.historyData,
      }
      return newState
    }
    case BUILD_FILTER_EVENT_IMAGE_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        historyPageContainerShown: payload.historyPageContainerShown,
        selectedFilters: payload.selectedFilters,
        // Resetting the value after adding
        filterValues: initState.filterValues,
      }
      return newState
    }
    case CHANGE_FILTER_EVENT_IMAGE_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        selectedFilter: payload.selectedFilter,
        filterValues: initState.filterValues,
      }
      return newState
    }
    case SELECT_FILTER_VALUE_EVENT_IMAGE_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        filterValues: payload.filterValues,
      }
      return newState
    }
    case IMAGE_HISTORY_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_IMAGE_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case ERROR_SEARCHING_IMAGE_HISTORY: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    default:
      return state
  }
}
