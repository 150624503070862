import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

function VideoReviewDataContainerHead(props) {
  const {
    onSelectAllClick = () => {},
    numSelected = 0,
    rowCount = 0,
    classes = {},
    videoDataLength = 0,
    setSortDirection = () => {},
    setSortField = () => {},
    savePaginationDataEvent = () => {},
    requestVideoReviewData = () => {},
    selectedFilters = [],
    sortField = 'tcin',
    sortDirection = 'ASC',
    pageName = 'VideosReview',
  } = props

  function handleSetSortDirection(sortDirection) {
    if (sortDirection === 'ASC') {
      return 'DESC'
    }
    if (sortDirection === 'DESC') {
      return 'ASC'
    }
  }

  function sortColumn(
    fieldToSort,
    selectedFilters,
    rowCount,
    sortDirection,
    pageName
  ) {
    setSortDirection(handleSetSortDirection(sortDirection))
    setSortField(fieldToSort)
    savePaginationDataEvent({
      pageSize: rowCount,
      sortOrder: handleSetSortDirection(sortDirection),
      sortField: fieldToSort,
      pageName: pageName,
    })
    requestVideoReviewData(
      selectedFilters,
      0,
      rowCount,
      handleSetSortDirection(sortDirection),
      fieldToSort
    )
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={
              numSelected > 0 &&
              (numSelected < rowCount || numSelected < videoDataLength)
            }
            checked={
              videoDataLength > 0 &&
              (numSelected === rowCount || numSelected === videoDataLength)
            }
            onChange={onSelectAllClick}
            color="primary"
          />
        </TableCell>
        <TableCell padding="none">Open</TableCell>
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'STATUS',
                  selectedFilters,
                  rowCount,
                  sortDirection,
                  pageName
                )
              }}
            >
              Status
              {sortField === 'STATUS' && sortDirection === 'DESC' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'STATUS' && sortDirection === 'ASC' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell padding="none" align="center">
          Video/Poster Frame
        </TableCell>
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'TITLE',
                  selectedFilters,
                  rowCount,
                  sortDirection,
                  pageName
                )
              }}
            >
              Title
              {sortField === 'TITLE' && sortDirection === 'DESC' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'TITLE' && sortDirection === 'ASC' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell padding="none">Division</TableCell>
        <TableCell padding="none">Applied TCIN(s)</TableCell>

        <TableCell padding="none" align="center">
          Duplicate TCIN(s)
        </TableCell>

        <TableCell padding="none" align="center">
          Disabled TCIN(s)
        </TableCell>

        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'LAST_UPDATED_DATE',
                  selectedFilters,
                  rowCount,
                  sortDirection,
                  pageName
                )
              }}
            >
              Last Modified
              {sortField === 'LAST_UPDATED_DATE' &&
                sortDirection === 'DESC' && <KeyboardArrowDownIcon />}
              {sortField === 'LAST_UPDATED_DATE' && sortDirection === 'ASC' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell padding="none">
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  'CREATE_DATE',
                  selectedFilters,
                  rowCount,
                  sortDirection,
                  pageName
                )
              }}
            >
              Upload Info
              {sortField === 'CREATE_DATE' && sortDirection === 'DESC' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'CREATE_DATE' && sortDirection === 'ASC' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

// @ts-ignore
export default withStyles(styles)(VideoReviewDataContainerHead)
