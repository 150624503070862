import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import styles from '../theme'
import { REVIEW_DRAWER_ACTION } from '../../../Shared/Constants'

const RejectImageAction = (props) => {
  const {
    toggleActionDrawer = () => {},
    disabled = false,
    classes = {},
  } = props

  return (
    <Button
      disabled={disabled}
      className={classes.drawerButton}
      onClick={() => toggleActionDrawer(true, REVIEW_DRAWER_ACTION.REJECT)}
    >
      <ThumbDownIcon />
      <span className={classes.marginLeft5}>Reject</span>
    </Button>
  )
}

// @ts-ignore
export default withStyles(styles)(RejectImageAction)
