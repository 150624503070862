import React from 'react'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { Button, Grid } from '@material-ui/core'
import PhotoIcon from '@material-ui/icons/Photo'
import DropZone from '../../../../Shared/DropZone/DropZone'
import ErrorDialog from '../../../../Shared/ErrorDialog/ErrorDialog'

function UploadImage(props) {
  const {
    onDrop = () => {},
    onDragEnter = () => {},
    onDragLeave = () => {},
    dropZoneEnter = false,
    invalidFileError = false,
    removeInvalidFile = () => {},
    invalidFiles = [],
    numberValidFiles = 0,
    clearData = () => {},
    classes = {},
    sendToTarget = () => {},
  } = props

  const dropZoneRef = React.createRef()

  return (
    <div>
      <DropZone
        onDrop={onDrop}
        dropZoneEnter={dropZoneEnter}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        disableClick
        dropZoneRef={dropZoneRef}
        requiredType={null}
        dropZoneActiveStyle={classes.dropZoneActive}
        dropZoneNotActiveStyle={classes.dropZoneNotActive}
        DropZoneContent={
          <React.Fragment>
            <div className={classes.DropZoneLargeText}>Drag & Drop Images</div>
            <Grid justify="center" container alignItems="center" item xs={12}>
              <CloudUpload className={classes.uploadIcon} />
            </Grid>
            <div className={classes.DropZoneSmallText}>
              Images must be .jpg/.png/.tif/.psd, at least 1000x1000 pixels and
              less than 100MB.
            </div>
            <Grid justify="center" container alignItems="center" item xs={12}>
              <div className={classes.DropZoneMediumText}>
                <a href="https://www.chrome.com" className={classes.redFont}>
                  To avoid upload issues please use Chrome
                </a>
              </div>
            </Grid>
            <Grid justify="center" container alignItems="center" item xs={12}>
              <Button
                role="presentation"
                onClick={() => dropZoneRef.current.open()}
                className={classes.marginTop10}
                variant="contained"
                color="primary"
              >
                <PhotoIcon />
                <span className={classes.browseButtonText}>browse images</span>
              </Button>
            </Grid>
          </React.Fragment>
        }
      />
      {numberValidFiles > 0 && (
        <Grid container justify="center" direction="column" alignItems="center">
          {numberValidFiles <= 1 ? (
            <Grid className={classes.numberOfFilesText} item xs={12}>
              {numberValidFiles} file ready for upload
            </Grid>
          ) : (
            <Grid className={classes.numberOfFilesText} item xs={12}>
              {numberValidFiles} files ready for upload
            </Grid>
          )}
          <Grid item xs={12} direction="row" container>
            <Grid item xs={6} container justify="flex-end">
              <Button
                className={classes.cancelButton}
                onClick={clearData}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.sendToTargetButton}
                onClick={sendToTarget}
                variant="contained"
                color="primary"
              >
                send to target
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {invalidFileError && (
        <ErrorDialog
          invalidFileError={invalidFileError}
          removeInvalidFile={() => removeInvalidFile()}
          invalidFiles={invalidFiles}
        />
      )}
    </div>
  )
}

// @ts-ignore
export default withStyles(styles)(UploadImage)
