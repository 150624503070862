const styles = (theme) => ({
  progressCircle: {
    marginTop: '30px',
    marginLeft: '30%',
  },
  primaryListItemHeaderText: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  gridContainer: {
    marginTop: '24px',
  },
  modelsList: {
    maxHeight: 825,
    overflow: 'auto',
  },
  tagPageContainerTitle: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
    fontSize: 'large',
  },
  fontSize10: {
    fontSize: '10px',
  },
  typePadding: {
    paddingLeft: '10px',
    paddingRight: '50px',
  },
  paddingLeft10: {
    paddingLeft: '10px',
  },
})

export default styles
