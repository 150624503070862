import React, { useEffect } from 'react'
import styles from '../theme'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

function SizeChartExisPopUp(props) {
  const { classes = {} } = props

  useEffect(() => {}, [])
  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted
        maxWidth="md"
        open={props.sizeChartExistPopUp}
        aria-labelledby="alert-dialog-image-label"
        aria-describedby="alert-dialog-imageLable-description"
      >
        <DialogTitle
          className={classes.saveFilterTitle}
          id="alert-dialog-image-label"
        >
          <div data-test-id="size-chart-tcin-popup">
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.saveTitleText}
            >
              Size Chart Exist
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          Size chart for
          <ul>
            <li>Category:{props.sizeChart.category}</li>
            <li>Brand:{props.sizeChart.brand}</li>
            <li>size:{props.sizeChart.size}</li>
          </ul>
          is already present. Kindly edit existing size chart instead of
          creating new size Chart from size chart landing page.
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => props.checkForSizeChartPopUp(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default withStyles(styles)(SizeChartExisPopUp)
