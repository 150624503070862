import React from 'react'
import { connect } from 'react-redux'
import ItemBadges from './ItemBadges'

class ItemBadgesContainer extends React.Component {
  render() {
    return <ItemBadges selectedItemData={this.props.selectedItemData} />
  }
}

const mapStateToProps = (state) => {
  const { longCopy } = state
  const { selectedItemData } = longCopy
  return {
    selectedItemData,
  }
}
export default connect(mapStateToProps, null)(ItemBadgesContainer)
