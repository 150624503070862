import AltTextTableUploadData from './AltTextTableUploadData'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  altTextDeleteConfirmation,
  handleSelectAltTextData,
  nonApprovedAltText,
} from '../../../../store/altText/actionCreator'
import { Backdrop, CircularProgress } from '@material-ui/core'

import { indexOfObject, gridCheckedLogic } from '../../../Shared/SharedUtils'

function AltTextTableUploadContainer(props) {
  const handleClick = (_, data) => {
    const { selectedData } = props
    var item = {}
    item.tcin = data.tcin
    item.alt_text = data.alt_text
    item.image_url = data.image_url
    item.unique_id = data.unique_id
    item.valid = data.valid

    var updatedNewSelected = gridCheckedLogic(selectedData, item).map(
      (item) => {
        var data = {}
        data.tcin = item.tcin
        data.alt_text = item.alt_text
        data.image_url = item.image_url
        data.unique_id = item.unique_id
        data.valid = item.valid
        return data
      }
    )
    props.handleSelectAltTextData({
      selectedData: updatedNewSelected,
    })
  }

  const handleSelectAllClick = (_, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = props.uploadData.map((item) => {
        var data = {}
        data.tcin = item.tcin
        data.alt_text = item.alt_text
        data.image_url = item.image_url
        data.unique_id = item.unique_id
        data.valid = item.valid
        return data
      })
    }
    props.handleSelectAltTextData({
      selectedData: newSelected,
    })
  }

  const isSelected = (item) => {
    var data = {}
    data.unique_id = item.unique_id
    return indexOfObject(props.selectedData, data) !== -1
  }
  return (
    <>
      {props.altTextProfanityLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open
          style={{ zIndex: 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}

      <AltTextTableUploadData
        uploadData={props.uploadData}
        selected={props.selectedData}
        altTextDeleteConfirmation={props.altTextDeleteConfirmation}
        isSelected={isSelected}
        handleClick={handleClick}
        handleSelectAllClick={handleSelectAllClick}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { altTextDeleteConfirmation, handleSelectAltTextData, nonApprovedAltText },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, altTextReducer } = state
  const {
    uploadData,
    selectedData,
    altTextProfanityLoading,
    nonApporvedAltTextImages,
    invalid_tcins,
  } = altTextReducer
  return {
    auth,
    uploadData,
    selectedData,
    altTextProfanityLoading,
    nonApporvedAltTextImages,
    invalid_tcins,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AltTextTableUploadContainer)
