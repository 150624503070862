/* eslint-disable no-redeclare */
/* global localStorage: false */

// import * as fireflyInternalAppConfig from './config/fireflyInternalAppConfig'
import Firefly from 'firefly-sdk-ts'
import { initializeFirefly } from './initializeFirefly'
import moment from 'moment'
import * as tokenDecoder from 'jwt-decode'

const Analytics = {
  eventType: {
    CLICK: 'click',
    SUBMIT: 'submit',
    VIEW: 'pageload',
  },
  SCHEMA_VERSION: '1.2.2',
}

const APPLICATION_NAME = 'Pipeline-UI'

const TIMESTAMP_ISO_1806 = 'YYYY-MM-DD HH:mm:ss Z'

const fireflyInternalAppConfig = {
  schemaId: '1961',
  autoWrap: true,
  eventManagerKey: 'internalapp',
  persistMethod: 'localStorage',
  retries: 1,
}

const firefly = {
  trackView(route, title, additionalInfo) {
    const application = this.getApplication()
    const user = this.getUser()
    const view = {
      additionalInfo: additionalInfo || {},
      globalName: route,
      name: title,
    }

    initializeFirefly()

    Firefly.setGlobalDataLayer(
      {
        application,
        employee: user,
        view,
      },
      fireflyInternalAppConfig.persistMethod
    )

    const eventManager = Firefly.getEventManager(fireflyInternalAppConfig)
    eventManager.addEvent({
      event: {
        serverTimestamp: moment.utc().format(TIMESTAMP_ISO_1806),
        type: Analytics.eventType.VIEW,
      },
    })
  },

  trackClick(action, customInteraction) {
    const application = this.getApplication()
    const user = this.getUser()

    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        customInteraction: customInteraction || {},
        employee: user,
      },
      fireflyInternalAppConfig.persistMethod
    )
    const eventManager = Firefly.getEventManager(fireflyInternalAppConfig)
    eventManager.addEvent({
      event: {
        serverTimestamp: moment.utc().format(TIMESTAMP_ISO_1806),
        type: action,
      },
    })
  },

  getUser() {
    const user = localStorage.getItem('id_token')
    if (user === undefined || user === null) {
      return {
        loginId: 'unkown email',
      }
    } else {
      const userDecoded = tokenDecoder(user)
      const email =
        userDecoded.mail !== undefined ? userDecoded.mail : 'unkown email'
      return {
        loginId: email.toLowerCase(),
      }
    }
  },

  getApplication() {
    return {
      name: APPLICATION_NAME,
      version: `${process.env.REACT_APP_VERSION}`,
      schemaVersion: Analytics.SCHEMA_VERSION,
    }
  },
}
export default firefly
