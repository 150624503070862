const styles = (theme) => ({
  minWidth550: {
    minWidth: '550px',
  },
  imageBorder: {
    border: '2px solid #3f51b5',
    margin: '5px',
  },
  imageWithoutBorder: {
    margin: '7px 19px',
  },
  selectedCheck: {
    color: '#3f51b5',
    position: 'relative',
    left: '-31px',
    top: '-233px',
  },
  clearSelectedImagesFab: {
    right: 25,
    top: 575,
    position: 'fixed',
  },
  deleteSelectedImagesFab: {
    right: 25,
    top: 500,
    position: 'fixed',
  },
})

export default styles
