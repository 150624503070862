import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import Dropzone from 'react-dropzone'
import { Grid } from '@material-ui/core'

function DropZone(props) {
  const {
    onDrop = () => {},
    dropZoneEnter = false,
    classes = {},
    onDragEnter = () => {},
    onDragLeave = () => {},
    disableClick = false,
    dropZoneRef,
    requiredType = '' || null,
    dropZoneActiveStyle = {},
    dropZoneNotActiveStyle = {},
    DropZoneContent = {},
    disabled = false,
  } = props

  return (
    <Dropzone
      onDrop={onDrop}
      className={
        dropZoneEnter
          ? dropZoneActiveStyle
          : requiredType
          ? requiredType === 'Required'
            ? classes.dropZoneNotActiveRequired
            : classes.dropZoneNotActiveOptional
          : dropZoneNotActiveStyle
      }
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      disableClick={disableClick}
      ref={dropZoneRef}
      disabled={disabled}
    >
      <Grid container justify="center" alignItems="center">
        {DropZoneContent}
      </Grid>
    </Dropzone>
  )
}

export default withStyles(styles)(DropZone)
