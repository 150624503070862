import React from 'react'
import { connect } from 'react-redux'
import { DefaultState } from './Components/UploadData'
import UploaderContainer from './Components/UploaderContainer'

class Upload extends React.Component {
  constructor(props) {
    super(props)
    this.state = DefaultState
  }
  render() {
    return <UploaderContainer />
  }
}

const mapStateToProps = (state) => {
  const { upload } = state
  const { uploadData } = upload
  return {
    uploadData,
  }
}
export default connect(mapStateToProps, null)(Upload)
