const styles = (theme) => ({
  marginBottom10: {
    marginBottom: '10px',
  },
  margin10: {
    margin: '10px',
  },
  header: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '.005em',
  },
  data: {
    fontSize: '14px',
    letterSpacing: '.010em',
    fontWeight: '400',
    lineHeight: '20px',
  },
})

export default styles
