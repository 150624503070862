import {
  REQUEST_ALTTEXT_UPLOAD_DATA,
  RECEIVE_ALTTEXT_UPLOAD_DATA,
  BULK_UPLOAD_ALTTEXT_RESET_FETCH,
  BULKUPLOAD_ALTTEXT_DROP_ZONE_ACTIVE,
  WRONG_FILE_ALTTEXT_ADDED,
  CLEAR_ALTTEXT_UPLOAD_DATA,
  PUBLISH_EVENT_ALTTEXT_SUCCESS,
  STORE__ALTTEXT_BULK_UPLOAD_STRAPI_DATA,
  SHOW_DEFAULT_ALTTEXT_ALERT_BULK_UPLOAD,
  NEW_FILTER_EVENT_ALTTEXT_SEARCH,
  BUILD_FILTER_EXPAND_ALTTEXT_SEARCH,
  REMOVE_FILTER_EVENT_ALTTEXT_SEARCH,
  CHANGE_FILTER_EVENT_ALTIMAGE_SEARCH,
  SELECT_FILTER_VALUE_EVENT_ALTIMAGE_SEARCH,
  CHANGE_CURRENT_PAGE_ALTIMAGE_SEARCH,
  BUILD_FILTER_EVENT_ALTIMAGE_SEARCH,
  CLEAR_ALTIMAGE_SEARCH_DATA_EVENT,
  ERROR_SEARCHING_ALTIMAGE_SEARCH,
  REQUEST_ALTIMAGE_SEARCH_DATA,
  RECEIVE_ALTIMAGE_SEARCH_DATA,
  CHANGE_DEFAULT_PAGE_SIZE_ALTIMAGE_SEARCH,
  SELECT_ALT_TEXT_SEARCH_EVENT,
  REMOVE_FILTER_EVENT_ALTIMAGE_SEARCH,
  STORE_ALTIMAGE_SEARCH_STRAPI_DATA,
  UPDATE_ALT_TEXT_EDIT_STATE,
  UPDATE_ALT_TEXT,
  UPDATE_SELECTED_ALT_TEXT,
  ALTTEXT_DELETE_CONFIRMATION,
  REMOVE_ALTTEXT_ROW,
  SELECT_ALTTEXT_DATA_EVENT,
  ALTTEXT_PROFANITY_API_LOADER,
  ALTTEXT_SEARCH_ITEM,
  UPDATE_ALTTEXT_ITEM_SEARCH,
  WRONG_FILE_ADDED,
  DOWNLOAD_FINISH_ALT_BULK_UPLOAD,
  DOWNLOAD_START_ALT_BULK_UPLOAD,
  NON_APPROVED_ALT_TEXT,
  RECEIVE_NON_APPROVED_TCINS,
} from './actionTypes'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { toast } from 'react-toastify'
import { TOAST_BODY, constantParams } from '../../components/Shared/Constants'
import { isEmpty, cloneDeep, isEqual } from 'lodash'
import { savePaginationDataEvent } from '../../store/auth/actionCreator'
import { DownloadDate } from '../../components/Shared/SharedUtils'

const fileDownload = require('js-file-download')

export function getAltTextBulkUploadData(data, fileName) {
  /* eslint-disable */
    let formData = new FormData()
    /* eslint-enable */
  formData.append('files', data.files[0])
  formData.append('processed_by', data.processed_by)
  return (dispatch) => {
    dispatch(dispatchItemDataEvent())
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/alt_text/bulk_upload?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        dispatch(itemDataEventSuccess(res.data))
        dispatch(
          nonApprovedTcins({
            data: [],
          })
        )
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          var title = 'is not formtted correctly for bulk copy upload.'
          var message = 'Please download a new template.'
          dispatch(wrongFileType(fileName, title, message))
        } else {
          dispatch(
            resetFetch(false),
            toast.error(
              'The application service is not responding. Please try again later. If this issue persists please request support through POL.',
              TOAST_BODY
            )
          )
        }
      })
  }
}

export function bulkUploadAltTextDropZoneActive(data) {
  return {
    type: BULKUPLOAD_ALTTEXT_DROP_ZONE_ACTIVE,
    payload: {
      dropZoneEnter: data,
    },
  }
}

export function wrongFileType(
  fileName,
  dropZoneErrorTitle,
  dropZoneErrorMessage
) {
  return {
    type: WRONG_FILE_ALTTEXT_ADDED,
    payload: {
      fileName: fileName,
      dropZoneErrorTitle: dropZoneErrorTitle,
      dropZoneErrorMessage: dropZoneErrorMessage,
      validFile: true,
      isFetching: false,
    },
  }
}

function dispatchItemDataEvent() {
  return {
    type: REQUEST_ALTTEXT_UPLOAD_DATA,
    payload: {
      isFetching: true,
    },
  }
}

function itemDataEventSuccess(data) {
  return {
    type: RECEIVE_ALTTEXT_UPLOAD_DATA,
    payload: {
      uploadData: data,
      isFetching: false,
    },
  }
}

export function resetFetch(data) {
  return {
    type: BULK_UPLOAD_ALTTEXT_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function publishData(data, invalidFiles) {
  return (dispatch) => {
    if (data.data.length > 0) {
      dispatch(dispatchItemDataEvent())
      return axios
        .post(
          `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/alt_text/bulk_upload/confirm?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
          data
        )
        .then((res) => {
          if (invalidFiles.length > 0) {
            dispatch(publishEventWithInvalidFiles(invalidFiles))
            dispatch(
              resetFetch(false),
              toast.error(
                `Input validation errors: ${invalidFiles.length} items will not be published. ${data.data.length} items are valid and will be published.`,
                TOAST_BODY
              )
            )
          } else {
            dispatch(publishEventSuccess())
            dispatch(clearUploadData())
            dispatch(
              resetFetch(false),
              toast.success(
                'Alt Text submitted... publishing may take a few minutes.',
                TOAST_BODY
              )
            )
          }
        })
        .catch((error, data) => {
          dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        })
    } else {
      dispatch(publishEventWithInvalidFiles(invalidFiles))
      dispatch(
        resetFetch(false),
        toast.error(
          `Input validation error: ${invalidFiles.length} items will not be published.`,
          TOAST_BODY
        )
      )
    }
  }
}

function publishEventWithInvalidFiles(invalidFiles) {
  var invalidFilesData = {
    data: [],
  }
  // eslint-disable-next-line array-callback-return
  invalidFiles.map((item) => {
    var data = {}
    data.tcin = item.tcin
    data.alt_text = item.alt_text
    data.image_url = item.image_url
    data.unique_id = item.unique_id
    data.profane_category = item.profane_category
    data.valid = item.valid
    invalidFilesData.data.push(data)
  })
  return {
    type: RECEIVE_ALTTEXT_UPLOAD_DATA,
    payload: {
      uploadData: invalidFilesData,
      isFetching: false,
    },
  }
}

export function clearUploadData() {
  return {
    type: CLEAR_ALTTEXT_UPLOAD_DATA,
  }
}

function publishEventSuccess() {
  return {
    type: PUBLISH_EVENT_ALTTEXT_SUCCESS,
    payload: {
      validFile: false,
    },
  }
}

export function storeAltTextBulkUploadStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const errorData = data.filter(
    (item) => item.interface_document_type === 'error'
  )
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  const error =
    errorData.length > 0
      ? {
          title: errorData[0].user_facing_title,
          body: errorData[0].user_facing_body,
        }
      : null
  return {
    type: STORE__ALTTEXT_BULK_UPLOAD_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
      error,
    },
  }
}

export function showDefaultAlert(data) {
  return {
    type: SHOW_DEFAULT_ALTTEXT_ALERT_BULK_UPLOAD,
    payload: data,
  }
}

export function getAltTextBulkUploadStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Alt%20Text%20Bulk%20Upload&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        if (isEmpty(response.data)) {
          dispatch(showDefaultAlert(true))
        } else {
          dispatch(storeAltTextBulkUploadStrapiData(response.data))
          dispatch(showDefaultAlert(false))
        }
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function newFilterAction(isShown) {
  return {
    type: NEW_FILTER_EVENT_ALTTEXT_SEARCH,
    payload: isShown,
  }
}

export function buildFilterExpand(value) {
  return {
    type: BUILD_FILTER_EXPAND_ALTTEXT_SEARCH,
    payload: {
      buildFilterExpansionPanel: value,
    },
  }
}

export function clearFilters() {
  return {
    type: REMOVE_FILTER_EVENT_ALTTEXT_SEARCH,
    payload: {
      newFilterContainerShown: false,
      dataContainerShown: false,
      selectedFilters: [],
    },
  }
}

export function changeFilterEvent(data) {
  return {
    type: CHANGE_FILTER_EVENT_ALTIMAGE_SEARCH,
    payload: {
      selectedFilter: data.selectedFilter,
    },
  }
}

export function selectFilterValueEvent(data) {
  return {
    type: SELECT_FILTER_VALUE_EVENT_ALTIMAGE_SEARCH,
    payload: {
      filterValues: data.filterValues,
    },
  }
}

export function buildFilterAction(
  data,
  pageNumber,
  pageSize,
  sortDirection,
  sortField
) {
  var alreadySelectedFilters = data.selectedFilters.filter(
    (item) => item.filterValue === data.selectedFilter.value
  )
  if (alreadySelectedFilters.length > 0) {
    // If filter already selected and different value selected.
    let isDuplicateValueFound = false
    if (Array.isArray(data.selectedFilterValue)) {
      data.selectedFilterValue.forEach((item) => {
        isDuplicateValueFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val) => val.value === item.value
          ).length === 0
      })
    } else {
      isDuplicateValueFound =
        alreadySelectedFilters[0].selectedValues.filter(
          (val) => val.value === data.selectedFilterValue.value
        ).length === 0
    }
    if (isDuplicateValueFound) {
      return (dispatch) => {
        dispatch(changePage(0)) // Reset Current Page to first page
        var filter = dispatch(handleAlreadySelectedFilter(data))
        dispatch(
          requestAltImageSearchData(
            filter.payload.selectedFilters,
            pageNumber,
            pageSize,
            sortDirection,
            sortField
          )
        )
      }
    } else {
      return (dispatch) => {
        dispatch(
          resetFetch(false),
          toast.error(
            'You have attempted to add duplicate filters.',
            TOAST_BODY
          )
        )
      }
    }
  } else {
    return (dispatch) => {
      dispatch(changePage(0)) // Reset Current Page to first page
      var filter = dispatch(handleNewFilter(data))
      dispatch(
        requestAltImageSearchData(
          filter.payload.selectedFilters,
          0,
          pageSize,
          sortDirection,
          sortField
        )
      )
    }
  }
}

function changePage(currentPage) {
  return {
    type: CHANGE_CURRENT_PAGE_ALTIMAGE_SEARCH,
    payload: {
      currentPage: currentPage,
    },
  }
}

function handleAlreadySelectedFilter(data) {
  var deepCopy = cloneDeep(data.selectedFilters)
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  let oldSelectedValue = deepCopy.filter(
    (v) => v.filterValue === data.selectedFilter.value
  )[0].selectedValues
  selectedValues.forEach((item) => {
    oldSelectedValue.push(item)
  })
  deepCopy
    .filter((v) => v.filterValue === data.selectedFilter.value)[0]
    .selectedValues.concat(selectedValues)
  return {
    type: BUILD_FILTER_EVENT_ALTIMAGE_SEARCH,
    payload: {
      newFilterContainerShown: true,
      dataContainerShown: true,
      selectedFilters: deepCopy,
    },
  }
}

function handleNewFilter(data) {
  let selectedValues = []
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element) => {
      selectedValues.push(element)
    })
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    })
  }
  var newSelectedFilters = [
    {
      filterValue: data.selectedFilter.value,
      filterDisplay: data.selectedFilter.display,
      selectedValues: selectedValues,
    },
  ]
  return {
    type: BUILD_FILTER_EVENT_ALTIMAGE_SEARCH,
    payload: {
      newFilterContainerShown: true,
      dataContainerShown: true,
      selectedFilters: data.selectedFilters.concat(newSelectedFilters),
    },
  }
}

export function requestAltImageSearchData(
  data,
  pageNumber,
  pageSize,
  sortDirection,
  sortField
) {
  var queryParam = {}
  data.map((item) => {
    if (item.filterValue === 'tcin') {
      queryParam.tcins = item.selectedValues.map((filter) => filter.value)
    }
  })

  if (data === undefined || data.length === 0) {
    return clearAltImageSearchDataEvent()
  }

  return (dispatch) => {
    dispatch(errorSearching(false))
    dispatch(requestImageSearchDataEvent())
    return axios
      .post(
        `${envConfigs.api.altTextApi}es/get_all_altText_data?size=${pageSize}&page=${pageNumber}`,
        queryParam
      )
      .then((res) => {
        if (res.data) {
          dispatch(
            successRequestDataEvent(
              res.data.items,
              res.data.total_pages,
              res.data.total_elements
            )
          )
        } else {
          dispatch(errorSearching(true))
        }
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(errorSearching(true))
      })
  }
}

function clearAltImageSearchDataEvent() {
  return {
    type: CLEAR_ALTIMAGE_SEARCH_DATA_EVENT,
  }
}

function errorSearching(payload) {
  return {
    type: ERROR_SEARCHING_ALTIMAGE_SEARCH,
    payload: payload,
  }
}

function requestImageSearchDataEvent() {
  return {
    type: REQUEST_ALTIMAGE_SEARCH_DATA,
    payload: {
      isFetching: true,
      rhData: [],
      selectedImages: [],
    },
  }
}

function successRequestDataEvent(json, totalPages, totalElements) {
  return {
    type: RECEIVE_ALTIMAGE_SEARCH_DATA,
    payload: {
      isFetching: false,
      rhData: json,
      totalPages: totalPages,
      totalElements: totalElements,
    },
  }
}

export function disableAltTextSearchResults(
  requestBody,
  data,
  pageNumber,
  pageSize,
  sortDirection,
  sortField
) {
  return (dispatch) => {
    dispatch(requestImageSearchDataEvent())
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/alt_text/disable?disabled_by=${requestBody.uploaded_by}&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestBody
      )
      .then((response) => {
        dispatch(showDefaultAlert(true))
        dispatch(
          requestAltImageSearchData(
            data,
            pageNumber,
            pageSize,
            sortDirection,
            sortField
          )
        )
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function handleChangePage(data, sortDirection, sortField) {
  return (dispatch) => {
    dispatch(changePage(data.currentPage))
    dispatch(
      requestAltImageSearchData(
        data.selectedFilters,
        data.currentPage,
        data.defaultPageSize,
        sortDirection,
        sortField
      )
    )
  }
}

function changePageSize(defaultPageSize) {
  return {
    type: CHANGE_DEFAULT_PAGE_SIZE_ALTIMAGE_SEARCH,
    payload: {
      defaultPageSize: defaultPageSize,
    },
  }
}

export function changeDefaultPageSize(
  data,
  sortDirection,
  sortField,
  pageName
) {
  return (dispatch) => {
    dispatch(changePageSize(data.defaultPageSize))
    // Always setting the currentPage to 0 to prevent bugs
    dispatch(changePage(0))
    dispatch(
      requestAltImageSearchData(
        data.selectedFilters,
        0,
        data.defaultPageSize,
        sortDirection,
        sortField
      )
    ).then(
      dispatch(
        savePaginationDataEvent({
          pageSize: data.defaultPageSize,
          sortOrder: sortDirection,
          sortField: sortField,
          pageName: pageName,
        })
      )
    )
  }
}

export function handleSelectItem(data) {
  return {
    type: SELECT_ALT_TEXT_SEARCH_EVENT,
    payload: {
      selectedItems: data.selectedItems,
    },
  }
}

export function loadExistingFilter(selectedFilters) {
  return {
    type: BUILD_FILTER_EVENT_ALTIMAGE_SEARCH,
    payload: {
      newFilterContainerShown: true,
      dataContainerShown: true,
      selectedFilters: selectedFilters,
    },
  }
}

export function removeFilterHandler(
  data,
  currentPage,
  defaultPageSize,
  sortDirection,
  sortField
) {
  return (dispatch) => {
    var filter = dispatch(removeFilterEvent(data))
    dispatch(
      requestAltImageSearchData(
        filter.payload.selectedFilters,
        currentPage,
        defaultPageSize,
        sortDirection,
        sortField
      )
    )
  }
}

export function removeFilterEvent(data) {
  var newFilterContainerShown = true
  var dataContainerShown = true
  var deepCopy = cloneDeep(data.selectedFilters)
  var currentFilter = deepCopy.filter(
    (v) => v.filterValue === data.mainFilterValue
  )[0]
  currentFilter.selectedValues = currentFilter.selectedValues.filter(
    (item) => !isEqual(item.value, data.filterValue)
  )
  deepCopy = deepCopy.filter((v) => v.selectedValues.length >= 1)
  if (currentFilter.selectedValues.length === 0 && deepCopy.length <= 0) {
    deepCopy = deepCopy.filter((v) => v.filterValue !== data.mainFilterValue)
    newFilterContainerShown = false
    dataContainerShown = false
  }
  return {
    type: REMOVE_FILTER_EVENT_ALTIMAGE_SEARCH,
    payload: {
      newFilterContainerShown: newFilterContainerShown,
      dataContainerShown: dataContainerShown,
      selectedFilters: deepCopy,
    },
  }
}

export function getAltImageSearchStrapiData(isExternalUser) {
  const visibilityFlag = isExternalUser
    ? 'is_externally_visible=true'
    : 'is_internally_visible=true'
  return (dispatch) => {
    return axios
      .get(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}${envConfigs.targetApiGateway.strapiApi}&${visibilityFlag}&page=Alt%20Text%20Manage&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`
      )
      .then((response) => {
        isEmpty(response.data)
          ? dispatch(showDefaultAlert(true))
          : dispatch(storeAltImageSearchStrapiData(response.data))
        dispatch(showDefaultAlert(false))
      })
      .catch((error) => {
        dispatch(showDefaultAlert(true), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function storeAltImageSearchStrapiData(data) {
  const alertData = data.filter(
    (item) => item.interface_document_type === 'alert'
  )
  const informationData = data.filter(
    (item) => item.interface_document_type === 'information'
  )
  const faqData = data.filter((item) => item.interface_document_type === 'faq')
  const errorData = data.filter(
    (item) => item.interface_document_type === 'error'
  )
  const alert =
    alertData.length > 0
      ? {
          title: alertData[0].user_facing_title,
          body: alertData[0].user_facing_body,
        }
      : null
  const information =
    informationData.length > 0
      ? {
          title: informationData[0].user_facing_title,
          body: informationData[0].user_facing_body,
        }
      : null
  const faq =
    faqData.length > 0
      ? {
          title: faqData[0].user_facing_title,
          body: faqData[0].user_facing_body,
        }
      : null
  const error =
    errorData.length > 0
      ? {
          title: errorData[0].user_facing_title,
          body: errorData[0].user_facing_body,
        }
      : null
  return {
    type: STORE_ALTIMAGE_SEARCH_STRAPI_DATA,
    payload: {
      alert,
      information,
      faq,
      error,
    },
  }
}

export function changeToEditStateAltText(unique_id, isAltTextEditable) {
  return {
    type: UPDATE_ALT_TEXT_EDIT_STATE,
    payload: {
      unique_id: unique_id,
      isAltTextEditable: isAltTextEditable,
    },
  }
}

export function checkProfanityAltText(alt_text, unique_id, tcin) {
  var requestBody = {
    tcin: tcin,
    asset_desc: alt_text,
  }
  return (dispatch) => {
    dispatch(altTextProfanityLoader(true))
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/long_copy/profanity_check?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        requestBody
      )
      .then((res) => {
        dispatch(updateAltText(unique_id, res.data))
        dispatch(altTextProfanityLoader(false))
      })
      .catch((error, data) => {
        dispatch(altTextProfanityLoader(false))
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function altTextProfanityLoader(value) {
  return {
    type: ALTTEXT_PROFANITY_API_LOADER,
    payload: value,
  }
}

export function updateAltText(unique_id, data) {
  return {
    type: UPDATE_ALT_TEXT,
    payload: {
      alt_text: data.alt_text,
      valid: data.valid,
      profane_category: data.profane_category,
      unique_id: unique_id,
      isAltTextEditable: false,
    },
  }
}

export function updateSelectedAltText(unique_id, data, valid, profaneCategory) {
  return {
    type: UPDATE_SELECTED_ALT_TEXT,
    payload: {
      unique_id: unique_id,
      alt_text: data,
      valid: valid,
      profane_category: profaneCategory,
    },
  }
}

export function altTextDeleteConfirmation(unique_id, flagForPopUp) {
  return {
    type: ALTTEXT_DELETE_CONFIRMATION,
    payload: {
      unique_id: unique_id,
      confirmDelete: flagForPopUp,
    },
  }
}

export function deleteAltTextRow(data) {
  return (dispatch) => {
    dispatch(deleteAltTextEventSucess(data))
    dispatch(
      resetFetch(false),
      toast.success('Successfully Removed', TOAST_BODY)
    )
  }
}

function deleteAltTextEventSucess(data) {
  return {
    type: REMOVE_ALTTEXT_ROW,
    payload: {
      uploadData: data.uploadData,
      selectedData: data.selectedData,
      defaultUploadData: data.defaultUploadData,
    },
  }
}

export function handleSelectAltTextData(data) {
  return {
    type: SELECT_ALTTEXT_DATA_EVENT,
    payload: {
      selectedData: data.selectedData,
    },
  }
}

export function requestAltTextDataByTcins(tcins, emailId) {
  var queryParam = {
    tcins: tcins,
  }
  return (dispatch) => {
    return axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/alt_text/search/tcins?page_number=0&page_size=1000&sort_direction=asc&sort_field=tcin&key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        queryParam
      )
      .then((res) => {
        dispatch(
          nonApprovedTcins({
            data: res?.data?.invalid_tcins,
          })
        )
        if (
          res?.data?.paged_result?.content &&
          res?.data?.paged_result?.content?.length > 0
        ) {
          let data = []
          res?.data?.paged_result?.content.forEach((item) => {
            data.push({
              tcin: item.tcin,
              alt_text: item?.alt_text || '',
              image_url: item?.image_url,
              valid: true,
              profane_category: [],
              unique_id: item?.unique_id,
            })
          })
          dispatch(
            itemDataEventSuccess({
              data: data,
            })
          )
        } else {
          dispatch(
            resetFetch(false),
            toast.error('No TCIN Data found', TOAST_BODY)
          )
        }
      })
      .catch((error, data) => {
        dispatch(
          resetFetch(false),
          toast.error('Issue Searching TCINs, try again', TOAST_BODY),
          toast.error(error.message, TOAST_BODY)
        )
      })
  }
}

function nonApprovedTcins(tcin) {
  return {
    type: RECEIVE_NON_APPROVED_TCINS,
    payload: {
      invalid_tcins: tcin?.data,
    },
  }
}

export function altTextSearchItem(data) {
  return {
    type: ALTTEXT_SEARCH_ITEM,
    payload: {
      searchedItems: data,
    },
  }
}

export function updateItemSearch(data) {
  return {
    type: UPDATE_ALTTEXT_ITEM_SEARCH,
    payload: {
      inputTcins: data,
      isFetching: true,
    },
  }
}

export function confirmWrongFile() {
  return {
    type: WRONG_FILE_ADDED,
    payload: {
      fileName: '',
      dropZoneErrorTitle: '',
      dropZoneErrorMessage: '',
      validFile: false,
      isFetching: false,
    },
  }
}

export function altTextDownLoadTemplate(badgesList) {
  return (dispatch) => {
    axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/alt_text/download_template?key=${envConfigs.api.gatewayKey}`,
        constantParams,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        fileDownload(
          res.data,
          `alt_text_upload_template_${DownloadDate}_v.2.0.xlsx`
        )
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function downloadStartAlTtext(downloadedTcins) {
  return {
    type: DOWNLOAD_START_ALT_BULK_UPLOAD,
    payload: {
      downloadedTcins: downloadedTcins,
    },
  }
}

export function downloadFinishAtlText(downloadedTcins) {
  return {
    type: DOWNLOAD_FINISH_ALT_BULK_UPLOAD,
    payload: {
      downloadedTcins: downloadedTcins,
    },
  }
}

export function downloadAllToExcelByTcins(tcins) {
  var queryParam = {
    tcins: tcins,
  }
  return (dispatch) => {
    dispatch(downloadStartAlTtext(queryParam))
    axios
      .post(
        `${envConfigs.targetApiGateway.targetApiGatewayUrl}item_assets/v1/alt_text/bulk_upload/download_alttext/tcins?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        queryParam,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        fileDownload(res.data, `Alttext_${DownloadDate}_v.2.0.xlsx`)
        dispatch(downloadFinishAtlText(queryParam))
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(downloadFinishAtlText(queryParam))
      })
  }
}
export function nonApprovedAltText(data) {
  return {
    type: NON_APPROVED_ALT_TEXT,
    payload: {
      DataExist: data,
    },
  }
}
