import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Card,
  CardContent,
  Input,
} from '@material-ui/core'
import {
  validateTcinByRuleId,
  validateTcinByRuleIdClear,
} from '../../../../store/sizeChart/ruleBuilder/actionCreator'
import { isNil, isEmpty } from 'lodash'

export function TcinRuleValidator(props) {
  const {
    // component props functions
    cancelTcinRuleValidator = () => {},
    // component props
    tcinRuleValidatorOpen = false,
    ruleId = '',
    // state props
    validateTcinByRuleIdLoading = false,
    validateTcinByRuleIdData = {},
    // dispatch props
    validateTcinByRuleId = () => {},
    validateTcinByRuleIdClear = () => {},
    editModeSizeChartTcinList,
    classes,
  } = props

  const [tcin, setTcin] = useState('')

  return (
    <Dialog
      open={tcinRuleValidatorOpen}
      onClose={() => cancelTcinRuleValidator()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'lg'}
      fullWidth
    >
      <DialogContent>
        <Card>
          <CardContent>
            {validateTcinByRuleIdLoading ? (
              <div>Loading</div>
            ) : (
              <div>
                <div>Check a TCIN: </div>
                <Input
                  onChange={(e) => {
                    setTcin(e.target.value)
                    validateTcinByRuleIdClear()
                  }}
                  value={tcin}
                  placeholder={'TCIN'}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    validateTcinByRuleId(
                      ruleId,
                      tcin,
                      editModeSizeChartTcinList
                    )
                  }
                  className={classes.buttonAlign}
                >
                  Search
                </Button>
              </div>
            )}
          </CardContent>
        </Card>

        {editModeSizeChartTcinList ? (
          <Card>
            <CardContent>
              {!isNil(validateTcinByRuleIdData) &&
                !isEmpty(validateTcinByRuleIdData) && (
                  <div>
                    Is this TCIN ({tcin}) an overall match:{' '}
                    {validateTcinByRuleIdData.overall_match ? 'Yes!' : 'No'}
                  </div>
                )}
            </CardContent>
          </Card>
        ) : (
          <React.Fragment>
            {!isNil(validateTcinByRuleIdData) &&
              !isEmpty(validateTcinByRuleIdData) && (
                <Card>
                  <CardContent>
                    <div>
                      Is this TCIN ({tcin}) an overall match:{' '}
                      {validateTcinByRuleIdData.overall_match ? 'Yes!' : 'No'}
                    </div>
                    <br />
                    {!isNil(
                      validateTcinByRuleIdData.individual_criteria_validation
                    ) &&
                      validateTcinByRuleIdData.individual_criteria_validation.map(
                        (validation, index) => {
                          return (
                            <div data-test-id="criteria" key={index}>
                              Invididual match:{' '}
                              {validation.match ? 'Yes!' : 'No'} <br />
                              Criteria:{' '}
                              {
                                validation.criteria
                                  .rule_search_criteria_es_details
                                  .field_display_name
                              }{' '}
                              {
                                validation.criteria
                                  .rule_search_criteria_es_details.es_operator
                              }
                              S values {validation.criteria.values.join(' or ')}
                              <hr />
                            </div>
                          )
                        }
                      )}
                    {!isNil(validateTcinByRuleIdData.item_data_info) && (
                      <div>
                        <br />
                        <div>Item data found:</div>
                        <br />
                        <div>
                          {JSON.stringify(
                            validateTcinByRuleIdData.item_data_info
                          )}
                        </div>
                      </div>
                    )}
                  </CardContent>
                </Card>
              )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            cancelTcinRuleValidator(false)
            validateTcinByRuleIdClear()
            setTcin('')
          }}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      validateTcinByRuleId,
      validateTcinByRuleIdClear,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { sizeChartRuleBuilder } = state
  const { validateTcinByRuleIdLoading, validateTcinByRuleIdData } =
    sizeChartRuleBuilder

  return {
    validateTcinByRuleIdLoading,
    validateTcinByRuleIdData,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TcinRuleValidator))
