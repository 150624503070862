import React from 'react'
import styles from '../theme'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

function Confirmation(props) {
  const {
    isConfirmationOpen = false,
    handleCancelConfirmation = () => {},
    handleConfirmRemoveFilter = () => {},
    classes = {},
    disabled = false,
  } = props

  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted
        maxWidth="md"
        open={isConfirmationOpen}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          className={classes.saveFilterTitle}
          id="alert-dialog-slide-title"
        >
          <div>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.saveTitleText}
            >
              Confirmation
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.marginTop20}
            id="alert-dialog-slide-description"
          >
            Are you sure you want to delete the filter?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCancelConfirmation()
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirmRemoveFilter()
            }}
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default withStyles(styles)(Confirmation)
