/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UploadImage from './UploadImage'
import {
  uploadStart,
  addFilesToQueue,
} from '../../../../../store/upload/actionCreator'
import {
  dropZoneActive,
  addedFiles,
  validNumberFiles,
  onDropLoading,
} from '../../../../../store/images/upload/actionCreator'
import envConfigs from '../../../../../config/apiConfig'
import { each, findIndex } from 'lodash'
import firefly from '../../../../../analytics/firefly'
import {
  ERR_DOUBLEPERIOD,
  ERR_FILENAME,
  ERR_FILEFORMAT,
  ERR_FILESIZESMALL,
  ERR_FILESIZEBIG,
  ERR_FILEDUPLICATE,
} from '../../../../Shared/Constants'

function UploadImageContainer(props) {
  const {
    uploadInProgress = false,
    files = [],
    auth = {},
    numberValidFiles = 0,
    dropZoneEnter = false,
    addFilesToQueue = () => {},
    addedFiles = () => {},
    onDropLoading = () => {},
    validNumberFiles = () => {},
    dropZoneActive = () => {},
    uploadStart = () => {},
  } = props

  const [validFiles, addValidFiles] = useState([])
  const [invalidFiles, addInvalidFiles] = useState([])
  const [invalidFileError, setInvalidFileError] = useState(false)

  useEffect(() => {
    var uploadFilesList = []
    addFilesToQueue(uploadFilesList)
    addedFiles(uploadFilesList)
  }, [])

  const onDrop = (newFiles) => {
    if (!uploadInProgress) {
      onDropLoading(true)
      var uploadFilesList = []
      each(newFiles, function (value) {
        if (checkImage(value)) {
          validFiles.push(value)
          uploadFilesList.push({
            fileName: value.name,
            percent: 0,
          })
        } else {
          invalidFiles.push(value)
        }
      })

      validNumberFiles(validFiles.length)
      if (invalidFiles.length) {
        setInvalidFileError(true)
      }

      // Adding the already existed files in the uploaded Files
      each(files, function (value) {
        uploadFilesList.push(value)
      })
      addFilesToQueue(uploadFilesList)
      addedFiles(uploadFilesList)
      onDropLoading(false)
      dropZoneActive(false)
    }
  }

  const checkImage = (file) => {
    var fileParts = file.name.split('.')

    if (file.name.match(/(\..*){2,}/) || fileParts > 2) {
      file.errorMessage = ERR_DOUBLEPERIOD
      return false
    }

    if (!envConfigs.imageStandards.filenameRegex.test(fileParts[0])) {
      file.errorMessage = ERR_FILENAME
      return false
    }

    // ensure file name follows TCIN_(position) pattern
    if (!envConfigs.imageStandards.allowedExtensionsRegex.test(fileParts[1])) {
      file.errorMessage = ERR_FILEFORMAT
      return false
    }

    // ensure file is at least 5kb
    if (file.size <= envConfigs.imageStandards.minFileSize) {
      file.errorMessage = ERR_FILESIZESMALL
      return false
    }

    // ensure file is not larger than 100mb
    if (file.size >= envConfigs.imageStandards.maxFileSize) {
      file.errorMessage = ERR_FILESIZEBIG
      return false
    }

    if (
      validFiles.length &&
      findIndex(validFiles, ['name', file.name]) !== -1
    ) {
      file.errorMessage = ERR_FILEDUPLICATE
      return false
    }

    return true
  }

  const onDragEnter = (files) => {
    if (!uploadInProgress) {
      onDropLoading(true)
      dropZoneActive(true)
    }
  }

  const onDragLeave = (files) => {
    if (!uploadInProgress) {
      onDropLoading(false)
      dropZoneActive(false)
    }
  }

  const removeInvalidFile = () => {
    addInvalidFiles([])
    setInvalidFileError(false)
  }

  const clearData = () => {
    if (!uploadInProgress) {
      addValidFiles([])
      validNumberFiles(0)
      addedFiles([])
    }
  }

  const sendToTarget = () => {
    if (!uploadInProgress) {
      let userId = auth.email ? auth.email : auth.lanId
      uploadStart(userId, validFiles, 'images')
      firefly.trackClick('Image Upload Start', {
        key: 'Number of Files',
        location: window.location.pathname,
        value: numberValidFiles,
      })
    }
  }

  return (
    <React.Fragment>
      <UploadImage
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        dropZoneEnter={dropZoneEnter}
        invalidFileError={invalidFileError}
        invalidFiles={invalidFiles}
        removeInvalidFile={removeInvalidFile}
        numberValidFiles={numberValidFiles}
        clearData={clearData}
        sendToTarget={sendToTarget}
      />
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dropZoneActive,
      addedFiles,
      validNumberFiles,
      onDropLoading,
      uploadStart,
      addFilesToQueue,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, imageUpload, upload } = state
  const { dropZoneEnter, files, numberValidFiles } = imageUpload
  const { uploadInProgress } = upload
  return {
    auth,
    dropZoneEnter,
    files,
    numberValidFiles,
    uploadInProgress,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadImageContainer)
