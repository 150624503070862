import { Card, Grid, CardContent } from '@material-ui/core'
import styles from '../../../theme'
import { withStyles } from '@material-ui/core/styles'

function ItemBadges(props) {
  const { selectedItemData, classes } = props
  return (
    <Grid item>
      <Card className={`${classes.badgesWrapper} ${classes.marginBottom10}`}>
        <CardContent className={classes.inlineFlex}>
          {selectedItemData &&
            selectedItemData.wellness_merchandise_attributes &&
            selectedItemData.wellness_merchandise_attributes.map((image) => (
              <div key={image.value_id} className={classes.marginLeftRight10}>
                <img
                  src={image.badge_url + '?hei=75&wid=75'}
                  alt={image.value_name}
                  role="presentation"
                />
                <div className={classes.badgeText}>{image.value_name}</div>
              </div>
            ))}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(ItemBadges)
