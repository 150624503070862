const styles = (theme) => ({
  displayInlineFlex: {
    display: 'inline-flex',
  },
  input: {
    margin: '15px',
    width: '120px',
  },
  box: {
    border: '5px solid lightgray',
    width: '250px',
    height: '200px',
    margin: '50px 0 50px 20px',
  },
  mtaNumbers: {
    color: 'darkgrey',
    fontSize: '35px',
    textAlign: 'center',
    marginTop: '50px',
  },
  mtaText: {
    color: 'darkgrey',
    textAlign: 'center',
    marginTop: '20px',
  },
  productDetailWrapper: {
    marginLeft: '10px',
    maxWidth: '400px',
  },
  productDetailHeader: {
    padding: '5px 0 0 20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '18px',
    color: '#999999',
    lineHeight: '28px',
  },
  productDetailContainer: {
    maxHeight: '40vh',
    paddingLeft: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '15px',
    color: '#666666',
    lineHeight: '28px',
    overflowY: 'auto',
  },
  paddingLeft20: {
    paddingLeft: '20px',
  },
  prodSpecButton: {
    margin: '30px 10px',
    padding: '5px 30px',
  },
  itemTitle: {
    marginBottom: '30px',
    fontSize: '18px',
    color: '#999999',
  },
  paddingLeft10: {
    paddingLeft: '10px',
  },
  positionButton: {
    position: 'fixed',
    top: '50%',
    left: '94%',
    transform: 'rotate(90deg)',
    width: '156px',
    zIndex: 1000,
  },
  sideBarDetailWrapper: {
    width: '575px',
    position: 'fixed',
    top: '5%',
    right: '0%',
    height: '100vh',
  },
  sideBarDetailContainer: {
    maxHeight: '80vh',
    paddingLeft: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '15px',
    color: '#666666',
    lineHeight: '28px',
    overflowY: 'auto',
    maxWidth: '475px',
  },
  link: {
    color: 'rgba(34, 150, 243, 1)',
    padding: '0',
    minWidth: '0',
    '&:hover': { backgroundColor: 'white' },
  },
  paddingRight5: {
    paddingRight: '5px',
  },
  templateDetailWrapper: {
    maxWidth: '669px',
  },
  templateDetailHeader: {
    paddingTop: '5px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '18px',
    color: '#999999',
    lineHeight: '28px',
  },
  templateDetailContainer: {
    maxHeight: '85.4vh',
    paddingLeft: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '15px',
    color: '#666666',
    lineHeight: '28px',
    overflowY: 'auto',
  },
  paddingLeft389: {
    paddingLeft: '389px',
  },
  mtaDetails: {
    position: 'absolute',
    top: '217px',
    left: '420px',
  },
  tableMtaIds: {
    textAlign: 'right',
    width: '15%',
  },
  width100Percent: {
    width: '100%',
  },
  verticalAlignTop: {
    verticalAlign: 'top',
  },
  mtaDetailHeader: {
    padding: '5px 0 0 20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '18px',
    color: '#999999',
    lineHeight: '28px',
    textAlign: 'left',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  textAlignRight: {
    textAlign: 'right',
    width: '15%',
  },
  singleImage: {
    margin: '0 0 15px 85px',
    height: '250px',
    width: '250px',
  },
})

export default styles
