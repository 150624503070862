import {
  REQUEST_BADGES,
  RECEIVE_BADGES,
  UPDATE_BADGE_EDIT_STATE,
  CHANGE_BADGE,
  BADGES_VALUE_PERCENT,
  BADGES_CLEAR_UPLOAD,
  BADGES_UPLOAD_IN_PROGRESS_START,
  BADGES_DROP_ZONE_ACTIVE,
  BADGES_ADD_FILES_UPLOAD,
  CHANGE_BADGE_CRITERIA,
  SELECT_BADGE_EVENT,
  DOWNLOAD_START_BADGES,
  DOWNLOAD_FINISH_BADGES,
  OPEN_CREATE_BADGE_MODAL,
  CLOSE_CREATE_BADGE_MODAL,
  BADGES_RESET_FETCH,
  CREATE_BADGE_IN_PROCESS,
} from './actionType'
import axios from 'axios'
import envConfigs from '../../config/apiConfig'
import { each, isEmpty, remove, isNil } from 'lodash'
import { DownloadDate } from '../../components/Shared/SharedUtils'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../components/Shared/Constants'

const fileDownload = require('js-file-download')

// THESE CONSTANTS MUST BE LIKE THIS BASED ON BACK END RESPONSE
const SUCCESSFUL_SAVE = 'successfully saved'
const SUCCESSFUL_DELETE = 'successfully deleted'

function requestBadges() {
  return {
    type: REQUEST_BADGES,
    payload: {
      isBadgesFetching: true,
    },
  }
}
function receiveBadges(data) {
  return {
    type: RECEIVE_BADGES,
    payload: {
      badgesList: data,
      isBadgesFetching: false,
    },
  }
}

export function resetFetch(data) {
  return {
    type: BADGES_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function getBadgesData() {
  return (dispatch) => {
    dispatch(requestBadges())
    return axios
      .get(
        envConfigs.api.pipelineContentApp +
          'badges?key=' +
          envConfigs.api.gatewayKey
      )
      .then((res) => {
        dispatch(receiveBadges(res.data))
      })
      .catch(() => {
        dispatch(
          resetFetch(false),
          toast.error('Failed to retrieve badges.', TOAST_BODY)
        )
      })
  }
}

export function changeBadgeEditState(fieldName) {
  return {
    type: UPDATE_BADGE_EDIT_STATE,
    payload: {
      fieldName: fieldName,
    },
  }
}

export function deleteBadge(badge) {
  return (dispatch) => {
    if (!badge.active) {
      dispatch(cancelCreateBadge())
      const badgeToDelete = Object.assign({}, badge)
      if (badgeToDelete.criteria != null) {
        badgeToDelete.criteria = JSON.stringify(badgeToDelete.criteria)
      }
      return axios
        .post(
          envConfigs.api.pipelineContentApp +
            'badges/remove?key=' +
            envConfigs.api.gatewayKey,
          badgeToDelete
        )
        .then((res) => {
          if (res.data.log === SUCCESSFUL_DELETE) {
            dispatch(getBadgesData())
            toast.success('Badge Deleted Successfully')
          } else {
            toast.error(`Unable to Delete Badge: ${res.data.log}`, TOAST_BODY)
          }
        })
        .catch((error) => {
          toast.error(`Unable to Delete Badge: ${error}`, TOAST_BODY)
        })
    } else {
      toast.error('Badge must be disabled to delete.', TOAST_BODY)
    }
  }
}

export function saveBadge(badge, active, auth) {
  var badgeToSave = Object.assign({}, badge)
  badgeToSave.active = active
  badgeToSave.badge_url = badgeToSave.badge_url.split('?')[0]
  if (badgeToSave.id === 'new') {
    badgeToSave.id = null
    badgeToSave.create_by = auth.email
    badgeToSave.last_updated_by = null
  } else {
    badgeToSave.last_updated_by = auth.email
  }
  // stringify criteria before saving so it can be saved to mongo later
  if (badgeToSave.criteria != null) {
    badgeToSave.criteria = JSON.stringify(badgeToSave.criteria)
  }
  return (dispatch) => {
    dispatch(createBadgeInProcess(true))
    return axios
      .post(
        envConfigs.api.pipelineContentApp +
          'badges?key=' +
          envConfigs.api.gatewayKey,
        badgeToSave
      )
      .then((res) => {
        dispatch(createBadgeInProcess(false))
        dispatch(cancelCreateBadge())
        dispatch(getBadgesData())
        dispatch(
          resetFetch(false),
          !isNil(res.data.log) && res.data.log !== SUCCESSFUL_SAVE
            ? toast.error(res.data.log, TOAST_BODY)
            : toast.success('Badge Saved Successfully', TOAST_BODY)
        )
      })
      .catch((error) => {
        if (error.response.status === 400) {
          dispatch(createBadgeInProcess(false))
          dispatch(cancelCreateBadge())
          dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        }
        dispatch(createBadgeInProcess(false))
        dispatch(cancelCreateBadge())
        dispatch(
          resetFetch(false),
          toast.error('Failed to save badge.', TOAST_BODY)
        )
      })
  }
}

export function changeBadge(fieldName, value) {
  return {
    type: CHANGE_BADGE,
    payload: {
      fieldName: fieldName,
      value: value,
    },
  }
}

export function changeBadgeCriteria(criteriaName, criteriaValue, badge) {
  return {
    type: CHANGE_BADGE_CRITERIA,
    payload: {
      criteriaName: criteriaName,
      criteriaValue: criteriaValue,
      badge: badge,
    },
  }
}

// ------------------- badge image upload stuff
var activeFiles = []

function updatePercent(data, percent) {
  return {
    type: BADGES_VALUE_PERCENT,
    payload: {
      fileName: data.name,
      percent: percent,
    },
  }
}

function clearUploader() {
  return {
    type: BADGES_CLEAR_UPLOAD,
    payload: {
      uploadInProgress: false,
      uploadData: [],
    },
  }
}

function remainingFiles(userEmail, activeFiles, data) {
  if (data.length > envConfigs.uploadStandards.maxNumberofFiles) {
    for (var i = 0; i < envConfigs.uploadStandards.maxNumberofFiles; i++) {
      activeFiles.push(data[i])
    }
    data.splice(0, envConfigs.uploadStandards.maxNumberofFiles)
  } else {
    each(data, function (file) {
      activeFiles.push(file)
    })
    data.splice(0, envConfigs.uploadStandards.maxNumberofFiles)
  }
  return (dispatch) => {
    dispatch(chunkStartRequest(userEmail, activeFiles, data))
  }
}

function chunkComplete(jobid, userEmail, value, activeFiles, data) {
  let params = {
    job_id: jobid,
    user_id: userEmail,
    vault_upload_status: '',
    original_asset_name: value.name,
    content_signature: '',
    asset_content_type: '',
    asset_size: value.size,
    metadata: '',
    user_email_address: userEmail,
    asset_relationship: 'standalone',
  }
  var requestBody = {
    targets: ['GUEST'],
    upload_id: jobid,
    file_name: value.name,
    total_number_of_chunks: value.numberOfChunks,
    chunk_size: value.chunkSize,
    total_file_size: value.sizeOfFile,
    content_type: 'images',
    metadata: JSON.stringify(params),
  }
  return (dispatch) => {
    return axios
      .post(
        `${
          envConfigs.targetApiGateway.targetApiGatewayUrl +
          envConfigs.targetApiGateway.sessionEndPath
        }?is_projected=true&key=${
          envConfigs.targetApiGateway.targetApiGatewayKey
        }`,
        requestBody
      )
      .then((res) => {
        remove(activeFiles, function (n) {
          return n === value
        })
        dispatch(changeBadge('image_job_id', jobid))
        dispatch(
          changeBadge('badge_url', res.data.projected_targets[0].projected_url)
        )
        if (isEmpty(activeFiles)) {
          if (isEmpty(data)) {
            setTimeout(() => {
              dispatch(clearUploader())
              dispatch(changeBadgeEditState(''))
              dispatch(
                resetFetch(false),
                toast.success('Successfully Uploaded Image', TOAST_BODY)
              )
            }, 2000)
          } else {
            dispatch(remainingFiles(userEmail, activeFiles, data))
          }
        }
      })
      .catch((error) => {
        if (value.retry === envConfigs.uploadStandards.maxRetry) {
          dispatch(changeBadge('image_job_id', jobid))
          dispatch(updatePercent(value, -1))
          dispatch(clearUploader())
          dispatch(
            resetFetch(false),
            toast.error(
              'Problems uploading image(s), please try again',
              TOAST_BODY
            ),
            toast.error(error.message, TOAST_BODY)
          )
        } else {
          value.retry++
          setTimeout(() => {
            dispatch(chunkComplete(jobid, userEmail, value, activeFiles, data))
          }, envConfigs.uploadStandards.timeout)
        }
      })
  }
}

function chunkUpload(jobid, userEmail, value, activeFiles, data) {
  var chunkedFormData = new window.FormData()
  chunkedFormData.append(
    'chunkUploadRequest',
    new window.Blob(
      [
        JSON.stringify({
          upload_id: jobid,
          file_name: value.name,
          chunk_number: value.currentChunk,
          total_number_of_chunks: value.numberOfChunks,
          chunk_size: value.chunkSize,
          total_file_size: value.sizeOfFile,
          content_type: 'images',
        }),
      ],
      {
        type: 'application/json',
      }
    )
  )
  chunkedFormData.append(
    'file',
    value.slice(
      value.currentChunk * value.chunkSize,
      (value.currentChunk + 1) * value.chunkSize
    )
  )
  return (dispatch) => {
    return axios
      .post(
        `${
          envConfigs.targetApiGateway.targetApiGatewayUrl +
          envConfigs.targetApiGateway.sessionUploadPath
        }?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
        chunkedFormData
      )
      .then((res) => {
        value.currentChunk++
        if (value.currentChunk <= value.numberOfChunks) {
          var percentageComplete =
            ((value.currentChunk + 1) / value.numberOfChunks) * 100
          dispatch(updatePercent(value, percentageComplete))
          dispatch(chunkUpload(jobid, userEmail, value, activeFiles, data))
        } else {
          dispatch(chunkComplete(jobid, userEmail, value, activeFiles, data))
        }
      })
      .catch((error, data) => {
        if (value.retry === envConfigs.uploadStandards.maxRetry) {
          dispatch(updatePercent(value, -1))
          dispatch(clearUploader())
          dispatch(
            resetFetch(false),
            toast.error(
              'Problems uploading image(s), please try again',
              TOAST_BODY
            ),
            toast.error(error.message, TOAST_BODY)
          )
        } else {
          value.retry++
          setTimeout(() => {
            dispatch(chunkUpload(jobid, userEmail, value, activeFiles, data))
          }, envConfigs.uploadStandards.timeout)
        }
      })
  }
}

function chunkStartRequest(userEmail, activeFiles, data) {
  var uploadAssetTo = 'GUEST'
  return (dispatch) => {
    each(activeFiles, function (value) {
      return axios
        .post(
          `${
            envConfigs.targetApiGateway.targetApiGatewayUrl +
            envConfigs.targetApiGateway.sessionStartPath
          }?key=${envConfigs.targetApiGateway.targetApiGatewayKey}`,
          { targets: [uploadAssetTo] },
          {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
            },
          }
        )
        .then((res) => {
          value.chunkSize = envConfigs.uploadStandards.chunkSize
          value.sizeOfFile = value.size
          value.numberOfChunks = Math.ceil(value.sizeOfFile / value.chunkSize)
          value.currentChunk = 0
          value.retry = 0
          dispatch(
            chunkUpload(res.data.upload_id, userEmail, value, activeFiles, data)
          )
        })
        .catch((error) => {
          dispatch(clearUploader())
          dispatch(
            resetFetch(false),
            toast.error(
              'Problems uploading image(s), please try again',
              TOAST_BODY
            ),
            toast.error(error.message, TOAST_BODY)
          )
          remove(activeFiles, function (n) {
            return n === value
          })
        })
    })
  }
}

function uploadInProgressStart() {
  return {
    type: BADGES_UPLOAD_IN_PROGRESS_START,
    payload: {
      uploadInProgress: true,
    },
  }
}

export function uploadBadgeImageStart(userEmail, data) {
  if (data.length > 0) {
    if (data.length > envConfigs.uploadStandards.maxNumberofFiles) {
      for (var i = 0; i < envConfigs.uploadStandards.maxNumberofFiles; i++) {
        activeFiles.push(data[i])
      }
      data.splice(0, envConfigs.uploadStandards.maxNumberofFiles)
    } else {
      each(data, function (file) {
        activeFiles.push(file)
      })
      data.splice(0, envConfigs.uploadStandards.maxNumberofFiles)
    }
    return (dispatch) => {
      dispatch(chunkStartRequest(userEmail, activeFiles, data))
      dispatch(uploadInProgressStart())
    }
  }
}

export function badgeDropZoneActive(data) {
  return {
    type: BADGES_DROP_ZONE_ACTIVE,
    payload: {
      dropZoneEnter: data,
    },
  }
}

export function badgesAddFilesToQueue(files) {
  return {
    type: BADGES_ADD_FILES_UPLOAD,
    payload: {
      uploadData: files,
    },
  }
}

export function handleSelectBadges(data) {
  return {
    type: SELECT_BADGE_EVENT,
    payload: {
      selectedBadges: data.selectedBadges,
    },
  }
}

export function downloadAllToExcel(badgesList) {
  return (dispatch) => {
    dispatch(downloadStartBadges(badgesList))
    axios
      .post(
        `${envConfigs.api.pipelineContentApp}badges/download?key=${envConfigs.api.gatewayKey}`,
        null,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        fileDownload(res.data, `badges_${DownloadDate}_v.2.0.xlsx`)
        dispatch(downloadFinishBadges(badgesList))
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(downloadFinishBadges(badgesList))
      })
  }
}

export function downloadStartBadges(downloadedBadges) {
  return {
    type: DOWNLOAD_START_BADGES,
    payload: {
      downloadedBadges: downloadedBadges,
    },
  }
}

export function downloadFinishBadges(downloadedBadges) {
  return {
    type: DOWNLOAD_FINISH_BADGES,
    payload: {
      downloadedBadges: downloadedBadges,
    },
  }
}

export function downloadSelectedBadgesEvent(queryParamter) {
  // harbinger.trackEvent('s7g49u', [{ key: 'Item Count', value: queryParamter.split(',').length }, { key: 'Button Pressed', value: 'Download Selected' }])
  return (dispatch) => {
    dispatch(downloadStartBadges(queryParamter))
    axios
      .post(
        `${envConfigs.api.pipelineContentApp}badges/download?key=${envConfigs.api.gatewayKey}`,
        queryParamter,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        fileDownload(res.data, `badges_${DownloadDate}_v.2.0.xlsx`)
        dispatch(downloadFinishBadges(queryParamter))
      })
      .catch((error) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
        dispatch(downloadFinishBadges(queryParamter))
      })
  }
}

export function openCreateBadgeModal(badge) {
  return {
    type: OPEN_CREATE_BADGE_MODAL,
    payload: {
      editableBadge: badge,
    },
  }
}

export function cancelCreateBadge() {
  return {
    type: CLOSE_CREATE_BADGE_MODAL,
  }
}

export function createBadgeInProcess(inProcess) {
  return {
    type: CREATE_BADGE_IN_PROCESS,
    payload: {
      inProcess,
    },
  }
}
