/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import UploadCopyBulletsContainer from './Components/UploadCopyBullets/UploadCopyBulletsContainer'
import HeaderSectionContainer from './Components/HeaderSection/HeaderSectionContainer'
import ItemSearchContainer from './Components/ItemSearch/ItemSearchContainer'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Grid, Card } from '@material-ui/core'
import styles from './theme'
import { getBulkUploadStrapiData } from '../../../store/bulkUpload/actionCreator'
import DefaultAlert from '../../Shared/StrapiComponents/DefaultAlert'
import { isExternalUser } from '../../Shared/SharedUtils'
import TableUploadDataContainer from './Components/TableUploadData/TableUploadDataContainer'
import Alert from '../../Shared/StrapiComponents/Alert'
import StrapiExpansionPanel from '../../Shared/StrapiComponents/StrapiExpansionPanel'
import LongCopySubmitError from '../BulkUpload/Components/DialogBoxError/LongCopySubmitError'
import { bulkUploadSubmitErrorAction } from '../../../store/bulkUpload/actionCreator'

function BulkUpload(props) {
  const {
    uploadData = [],
    isFetching = false,
    classes = {},
    auth = {},
    alert = null,
    information = null,
    faq = null,
    showDefaultAlert = null,
    bulkUploadSubmitError,
    bulkUploadSubmitErrorAction,
  } = props

  useEffect(() => {
    props.getBulkUploadStrapiData(isExternalUser(auth))
  }, [])

  useEffect(() => {
    if (uploadData.length > 0) {
      expandFaq(false)
    }
  }, [uploadData])

  const [expansionPanelOpen, expandFaq] = useState(true)

  const handlePanel = () => {
    expandFaq(!expansionPanelOpen)
  }

  return (
    <React.Fragment>
      <HeaderTitle title="Bulk Upload & Item Search" />
      <Helmet title="Bulk Upload & Item Search" />
      {showDefaultAlert ? <DefaultAlert /> : null}
      {alert ? <Alert alert={alert} /> : null}
      <Card className={classes.marginTopBottom5}>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          className={classes.bulkUploadTopCard}
        >
          <Grid item>
            <UploadCopyBulletsContainer />
          </Grid>
          <Grid item>
            <div className={classes.divider}>or</div>
          </Grid>
          <Grid item>
            <ItemSearchContainer />
          </Grid>
          <Grid item>
            <HeaderSectionContainer />
          </Grid>
        </Grid>
      </Card>
      {(information || faq) && (
        <StrapiExpansionPanel
          faq={faq}
          information={information}
          expansionPanelOpen={expansionPanelOpen}
          handleFaqPanel={handlePanel}
        />
      )}
      {uploadData.length > 0 && <TableUploadDataContainer />}
      {isFetching && (
        <div>
          <CircularProgress className={classes.progressBar} />
        </div>
      )}
      <LongCopySubmitError
        bulkUploadSubmitError={bulkUploadSubmitError}
        bulkUploadSubmitErrorAction={bulkUploadSubmitErrorAction}
      />
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBulkUploadStrapiData,
      bulkUploadSubmitErrorAction,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { bulkUpload, auth } = state
  const {
    uploadData,
    isFetching,
    alert,
    information,
    faq,
    showDefaultAlert,
    bulkUploadSubmitError,
  } = bulkUpload
  return {
    uploadData,
    isFetching,
    auth,
    alert,
    information,
    faq,
    showDefaultAlert,
    bulkUploadSubmitError,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BulkUpload))
