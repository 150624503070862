import React from 'react'
import {
  Card,
  Grid,
  CardContent,
  TextField,
  Button,
  // Tooltip,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import {
  Link,
  Text,
  Icon,
} from '../../../../../react-praxis-components/src/SideNav'
import DialogBoxDeleteSelectionContainer from '../DialogBoxDeleteSelection/DialogBoxDeleteSelectionContainer'
import DialogBoxConfirmDeleteContainer from '../../Shared/DialogBoxConfirmDelete/DialogBoxConfirmDeleteContainer'
import DialogBoxQuickPublishContainer from '../DialogBoxQuickPublish/DialogBoxQuickPublishContainer'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../../theme'
import FlagIcon from '@material-ui/icons/Flag'
import { lwoDiscFunc, loneDiscFunc } from '../../../../../Shared/SharedUtils'
import {
  RESTRICTED_PROFANE_WARNING,
  INAPROPRIATE_PROFANE_WARNING,
} from './../../../../../Shared/Constants'

/* eslint-disable */
const renderTextAreaField = ({
  input,
  label,
  value,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    multiline
    rows="10"
    fullWidth
    autoFocus
    margin="normal"
    {...input}
    {...custom}
  />
)

const renderTextField = ({
  input,
  label,
  value,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    fullWidth
    multiline
    rows="8"
    autoFocus
    margin="normal"
    {...input}
    {...custom}
  />
)

/* eslint-enable */
function isDisable(event, permission = false) {
  if (
    event === 'NewWriteRequest' ||
    event === 'WritingStarted' ||
    event === 'ReadyForQA'
  ) {
    return false
  }
  if (permission && event === 'Done') {
    return false
  }
  return true
}

function escapeHtml(input) {
  return { __html: input }
}

function convertLongCopy(value) {
  let temp = value
  var res = temp
    .replace(/(<br\s*\/?>)|(<BR\s*\/?>)|(<Br\s*\/?>)|(<bR\s*\/?>)/g, '\n')
    .replace(/<\/?span[^>]*>/g, '')
  return res
}

function convertFeatureBullet(value) {
  var convertedString = ''
  value.map((item) => {
    convertedString += item.replace(/<\/?span[^>]*>/g, '') + '\n'
  })
  return convertedString
}

let EditCopy = (props) => {
  const {
    pristine,
    selectedItemData,
    selectedCopyData,
    getFeatureBulletWordCount,
    editCopy,
    firstDraft,
    changeFirstDraft,
    saveDataEventHandler,
    publishEventHandler,
    draftDataEventHandler,
    getLongCopyWordCount,
    getTotalFeatureBullet,
    setBackgroundStatus,
    quickEditPublishConfirm,
    convertStatusDisplay,
    editCopyData,
    editFeatureBulletState,
    updateFeatureBullets,
    backTolist,
    classes,
    deleteBulletAndCopy,
    permission,
    singleEditBulletsProfanityCheck,
    updateSingleEditLongCopy,
    singleEditLongCopyProfanityCheck,
    profanityLoading,
    publishDataError,
  } = props
  if (
    !pristine &&
    firstDraft &&
    selectedCopyData.current_event.event_type !== 'ReadyForQA' &&
    selectedCopyData.current_event.event_type === 'NewWriteRequest'
  ) {
    changeFirstDraft()
  }
  return (
    <Card>
      {profanityLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open
          style={{ zIndex: 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      {/* <div className={classes.profaneMainWrap}>
        <Tooltip title={RESTRICTED_PROFANE_WARNING}>
          <div className={classes.profaneWrap}>
            <span className={classes.profaneHeading}>Restricted word</span>
            <span className={classes.loneColour} />
          </div>
        </Tooltip>
        <Tooltip title={INAPROPRIATE_PROFANE_WARNING}>
          <div className={classes.profaneWrap}>
            <span className={classes.profaneHeading}>Inappropriate Word</span>
            <span className={classes.ltwoColour} />
          </div>
        </Tooltip>
      </div> */}
      <CardContent>
        <Grid container spacing={3}>
          {/* New Eidt Page */}
          <Grid item container direction="row">
            {/* long Copy and Feature Bullets */}
            {/* eslint-disable */}
            <Grid item xs={7} container direction="column">
              <Grid item className={classes.width100Percent}>
                {editCopyData.isfeatureBulletsEdit
                  ? <Field
                    id="featureBullet"
                    name="featureBullet"
                    component={renderTextField}
                    disabled={isDisable(selectedCopyData.current_event.event_type, permission.instantEditLongCopy)}
                    className={classes.editLongCopy}
                    variant="outlined"
                    onBlur={(event) => {updateFeatureBullets(false);singleEditBulletsProfanityCheck(event)}}
                  />
              : <div
                  className={
                    isDisable(selectedCopyData.current_event.event_type, permission.instantEditLongCopy) 
                    ? classes.editFeatureBulletDivDisable : classes.editFeatureBulletDiv}
                  onClick={() => editFeatureBulletState(selectedCopyData.current_event.event_type, true)}
                >
                  <span className={classes.headerHighlightText}>Highlights / Features</span>
                  <ul className={classes.featureBulletDivUl}>
                    {
                       editCopyData.featureBullets && editCopyData.featureBullets.map((item, index) => {
                        return <li key={index}  dangerouslySetInnerHTML={escapeHtml(item)}/>
                      })
                    }
                  </ul>
              </div>}
              </Grid>
              <Grid item className={classes.width100Percent}>
                {editCopyData.isLongCopySingleEdit ? <Field
                  className={classes.editLongCopy}
                  id="longCopy"
                  name="longCopy"
                  variant="outlined"
                  component={renderTextAreaField}
                  disabled={isDisable(selectedCopyData.current_event.event_type, permission.instantEditLongCopy)}
                  onBlur={(event) =>{singleEditLongCopyProfanityCheck(event)} }
                /> :
                  <div
                     className={
                      isDisable(selectedCopyData.current_event.event_type, permission.instantEditLongCopy) 
                      ? classes.editFeatureBulletDivDisable : classes.editFeatureBulletDiv}
                    onClick={() => updateSingleEditLongCopy(true)}
                    role="presentation"
                    dangerouslySetInnerHTML={escapeHtml(editCopyData.longCopy)}
                  />}

              </Grid>
              {lwoDiscFunc(editCopyData && editCopyData.ltwoDisclaimer) && 
              <div className={classes.singleEditDisclaimerWrapper}>
                 {loneDiscFunc(editCopyData && editCopyData.ltwoDisclaimer) ? ' ' :  <span className={classes.singleEditDisclaimer}>
                  <FlagIcon style={{ color: 'white', verticalAlign: 'middle' }} /> {INAPROPRIATE_PROFANE_WARNING}</span> }
              
                  </div>}
                  {loneDiscFunc(editCopyData && editCopyData.ltwoDisclaimer) && 
              <div className={classes.singleEditDisclaimerWrapper}>    
                <span className={classes.singleEditDisclaimer}>
                  <FlagIcon style={{ color: 'white', verticalAlign: 'middle' }} /> {RESTRICTED_PROFANE_WARNING} </span>
                  </div>}   
            </Grid>
            <Grid item xs={2}>
              <div className={classes.counterContainer}>
                <Grid container justify="center" className={classes.marginTop20}>
                  <Grid container justify="center">
                    <Grid container justify="center" item xs={12}>
                      <span className={classes.boldText}>Highlights / Features</span>
                    </Grid>
                    <Grid container justify="center" item xs={12}>
                      <span className={classes.boldText}>(Recommend 4-8)</span>
                    </Grid>
                  </Grid>
                  <Grid className={classes.numberCount}>{getTotalFeatureBullet()}</Grid>

                  <Grid container item justify="center">
                    <Grid container justify="center" item xs={12}>
                      <span className={classes.boldText}>Highlights / Features word count</span>
                    </Grid>
                  </Grid>
                  <Grid className={classes.numberCount}>{getFeatureBulletWordCount()}</Grid>

                  <Grid container justify="center">
                    <Grid container justify="center" item xs={12}>
                      <span className={classes.boldText}>Copy word count</span>
                    </Grid>
                    <Grid container justify="center" item xs={12}>
                      <span className={classes.boldText}>(Recommend 50 minimum)</span>
                    </Grid>
                  </Grid>
                  <Grid className={classes.numberCount}>{getLongCopyWordCount()}</Grid>
                </Grid>
              </div>
            </Grid>
            {/* eslint-enable */}
            {/* Button */}
            <Grid container item xs={3} spacing={3}>
              <Grid item xs={12} container justify="center">
                {selectedItemData.product_description &&
                  selectedItemData.product_description.title && (
                    <span>
                      <div
                        className={classes.headerLabel}
                        dangerouslySetInnerHTML={escapeHtml(
                          selectedItemData.product_description.title
                            ? selectedItemData.product_description.title
                            : 'No Title'
                        )}
                      />
                    </span>
                  )}
              </Grid>
              <Grid container item xs={12}>
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <div
                    className={classes.statusLabel}
                    style={setBackgroundStatus(
                      selectedCopyData.current_event.event_type
                    )}
                  >
                    {convertStatusDisplay(
                      selectedCopyData.current_event.event_type
                    )}
                  </div>
                  <span className={classes.helperTextLabel}>
                    {selectedCopyData.last_updated_by
                      ? 'Last Updated By:' + selectedCopyData.last_updated_by
                      : 'No Writing Started'}
                  </span>
                </Grid>
              </Grid>

              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Button
                  className={classes.margin5}
                  onClick={backTolist}
                  variant="outlined"
                  color="primary"
                >
                  <Link
                    to={{ pathname: `/v2/longcopy` }}
                    className={classes.linkButton}
                  >
                    <Icon className={classes.backButtonLabel}>
                      <KeyboardArrowLeftIcon />
                    </Icon>
                    <Text className={classes.backButtonLabel}>
                      <span className={classes.backButtonLabel}>
                        BACK TO LIST OF ITEMS
                      </span>
                    </Text>
                  </Link>
                </Button>
                {selectedCopyData.current_event.event_type !== 'Done' && (
                  <React.Fragment>
                    {selectedCopyData.current_event.event_type !==
                    'ReadyForQA' ? (
                      <Button
                        className={classes.margin5}
                        disabled={isDisable(
                          selectedCopyData.current_event.event_type
                        )}
                        onClick={
                          editCopyData.isValid
                            ? saveDataEventHandler
                            : publishDataError
                        }
                        variant="contained"
                        color="primary"
                      >
                        SAVE AS "READY FOR QA"
                      </Button>
                    ) : (
                      <Button
                        className={classes.margin5}
                        disabled={
                          !editCopy || editCopy.values.longCopy.length < 3
                        }
                        onClick={
                          editCopyData.isValid
                            ? publishEventHandler
                            : publishDataError
                        }
                        variant="contained"
                        color="primary"
                      >
                        PUBLISH
                        <KeyboardArrowRightIcon />
                      </Button>
                    )}{' '}
                  </React.Fragment>
                )}
                {selectedCopyData.current_event.event_type !== 'ReadyForQA' &&
                  selectedCopyData.current_event.event_type !== 'Done' && (
                    <Button
                      className={classes.margin5}
                      disabled={
                        pristine ||
                        isDisable(selectedCopyData.current_event.event_type) ||
                        selectedCopyData.current_event.event_type ===
                          'ReadyForQA'
                      }
                      onClick={
                        editCopyData.isValid
                          ? draftDataEventHandler
                          : publishDataError
                      }
                      variant="contained"
                      color="primary"
                    >
                      Save and Finish Later
                    </Button>
                  )}
                {selectedCopyData.current_event.event_type === 'Done' &&
                  permission.deleteCopyFeature && (
                    <Button
                      className={classes.margin5}
                      onClick={deleteBulletAndCopy}
                      variant="contained"
                      color="primary"
                    >
                      Delete Copy/Bullets
                    </Button>
                  )}
                {selectedCopyData.current_event.event_type === 'Done' &&
                  permission.instantEditLongCopy && (
                    <Button
                      className={classes.margin5}
                      onClick={
                        editCopyData.isValid
                          ? quickEditPublishConfirm
                          : publishDataError
                      }
                      disabled={pristine}
                      variant="contained"
                      color="primary"
                    >
                      Publish Now
                    </Button>
                  )}
              </Grid>
            </Grid>
          </Grid>
          {/* New Edit Page */}
        </Grid>
      </CardContent>
      <DialogBoxDeleteSelectionContainer />
      <DialogBoxConfirmDeleteContainer />
      <DialogBoxQuickPublishContainer />
    </Card>
  )
}
EditCopy = reduxForm({ form: 'editCopy' })(EditCopy)
EditCopy = connect((state) => ({
  initialValues: {
    longCopy: state.longCopy.editCopyData
      ? state.longCopy.editCopyData.longCopy
        ? convertLongCopy(state.longCopy.editCopyData.longCopy)
        : ''
      : '',
    featureBullet: state.longCopy.editCopyData
      ? state.longCopy.editCopyData.featureBullets
        ? convertFeatureBullet(state.longCopy.editCopyData.featureBullets)
        : ''
      : '',
  }, // pull initial values from account reducer
}))(EditCopy)

export default withStyles(styles)(EditCopy)
