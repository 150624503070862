export const IMAGE_SEARCH_RESET_FETCH = 'IMAGE_SEARCH_RESET_FETCH'
export const SHOW_DEFAULT_ALERT_IMAGE_SEARCH = 'SHOW_DEFAULT_ALERT_IMAGE_SEARCH'
export const STORE_IMAGE_SEARCH_STRAPI_DATA = 'STORE_IMAGE_SEARCH_STRAPI_DATA'
export const REMOVE_FILTER_EVENT_IMAGE_SEARCH =
  'REMOVE_FILTER_EVENT_IMAGE_SEARCH'
export const BUILD_FILTER_EXPAND_IMAGE_SEARCH =
  'BUILD_FILTER_EXPAND_IMAGE_SEARCH'
export const NEW_FILTER_EVENT_IMAGE_SEARCH = 'NEW_FILTER_EVENT_IMAGE_SEARCH'
export const BUILD_FILTER_EVENT_IMAGE_SEARCH = 'BUILD_FILTER_EVENT_IMAGE_SEARCH'
export const CLEAR_IMAGE_SEARCH_DATA_EVENT = 'CLEAR_IMAGE_SEARCH_DATA_EVENT'
export const ERROR_SEARCHING_IMAGE_SEARCH = 'ERROR_SEARCHING_IMAGE_SEARCH'
export const REQUEST_IMAGE_SEARCH_DATA = 'REQUEST_IMAGE_SEARCH_DATA'
export const RECEIVE_IMAGE_SEARCH_DATA = 'RECEIVE_IMAGE_SEARCH_DATA'
export const CHANGE_CURRENT_PAGE_IMAGE_SEARCH =
  'CHANGE_CURRENT_PAGE_IMAGE_SEARCH'
export const CHANGE_FILTER_EVENT_IMAGE_SEARCH =
  'CHANGE_FILTER_EVENT_IMAGE_SEARCH'
export const SELECT_FILTER_VALUE_EVENT_IMAGE_SEARCH =
  'SELECT_FILTER_VALUE_EVENT_IMAGE_SEARCH'
export const CHANGE_DEFAULT_PAGE_SIZE_IMAGE_SEARCH =
  'CHANGE_DEFAULT_PAGE_SIZE_IMAGE_SEARCH'
export const SELECT_ITEM_SEARCH_EVENT = 'SELECT_ITEM_SEARCH_EVENT'
export const SET_SORT_FIELD_IMAGE_SEARCH = 'SET_SORT_FIELD_IMAGE_SEARCH'
export const SET_SORT_DIRECTION_IMAGE_SEARCH = 'SET_SORT_DIRECTION_IMAGE_SEARCH'
export const PUBLISH_IMAGE_DATA_SEARCH = 'PUBLISH_IMAGE_DATA_SEARCH'
export const RECEIVE_DEPT_CLASS_LIST_IMAGE_SEARCH =
  'RECEIVE_DEPT_CLASS_LIST_IMAGE_SEARCH'
