import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import { TextField, Button, Grid } from '@material-ui/core'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

/* eslint-disable */
const renderTextField = ({
  input,
  label,
  value,
  meta: { touched, error },
  ...custom
}) => (
    <TextField
      fullWidth
      multiline
      rowsMax="1"
      autoFocus
      margin="normal"
      {...input}
      {...custom}
    />
  )

let AddTcin = (props) => {
  const { getImageData, pristine, classes } = props
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item>
          <Field
            id="exampleImageTcinList"
            name="exampleImageTcinList"
            component={renderTextField}
            placeholder="enter a TCIN"
            className={classes.textFieldWidth}
          />
          <Button
            className={classes.searchTcinButton}
            disabled={pristine}
            onClick={getImageData}
            variant='contained'
            color='primary'
            >
            Search
          </Button>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}

AddTcin = reduxForm({ form: 'exampleImageTcinForm' })(AddTcin)
AddTcin = connect(
  state => ({
    initialValues: {
      exampleImageTcinList: '',
    },
  }),
)(AddTcin)
export default withStyles(styles)(AddTcin)
