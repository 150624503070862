import { withStyles } from '@material-ui/core/styles'
import styles from './theme'
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

function ErrorDialog(props) {
  const { invalidFileError, removeInvalidFile, classes, invalidFiles } = props

  return (
    <Dialog
      open={invalidFileError}
      onClose={removeInvalidFile}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={1} container justify="flex-start" alignItems="center">
            <ErrorOutline className={classes.errorTitle} />
          </Grid>
          <Grid item>
            <span className={classes.errorTitle}>
              {invalidFiles.length} File Errors{' '}
            </span>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invalidFiles.map((file) => {
              return (
                <TableRow key={file.name}>
                  <TableCell>
                    <span>{file.name}</span>
                  </TableCell>
                  <TableCell>
                    <span>{file.errorMessage}</span>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={removeInvalidFile} variant="outlined" color="primary">
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ErrorDialog)
