import React from 'react'
import ImageGrid from './ImageGrid'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getImageUrlsForModelLabel } from '../../../../../../store/images/tagTraining/actionCreator'

class ImageGridContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    const {
      images,
      selectToggleMainGrid,
      selectToggleDeleteConfirmation,
      handleDialogOpenStatus,
      deleteImages,
      isDialogOpen,
      clearSelectedTrainingImages,
      deletingInProgress,
    } = this.props

    return (
      <React.Fragment>
        <ImageGrid
          images={images}
          selectToggleMainGrid={(image) => selectToggleMainGrid(image)}
          selectToggleDeleteConfirmation={(image) =>
            selectToggleDeleteConfirmation(image)
          }
          clearSelectedTrainingImages={() => clearSelectedTrainingImages()}
          handleDialogOpenStatus={(open) => handleDialogOpenStatus(open)}
          isDialogOpen={isDialogOpen}
          deletingInProgress={deletingInProgress}
          deleteImages={() => deleteImages()}
        />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getImageUrlsForModelLabel,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageTagTraining, auth } = state
  const { training } = imageTagTraining
  const { imageInfo, imageUrlsFetching, deleteConfirmationisOpen } = training
  return {
    auth,
    imageInfo,
    imageUrlsFetching,
    deleteConfirmationisOpen,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageGridContainer)
