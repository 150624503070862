const styles = (theme) => ({
  sizeChartTabNameInput: {
    height: '50px',
    width: '200px',
    margin: 'auto',
  },
  sizeChartTabNameLabel: {
    textAlign: 'left',
    fontSize: '11px',
    color: '#666666',
  },
  tableMain: {
    textAlign: 'left',
    backgroundColor: '#ffffff',
    width: '100%',
  },
  tableWrapper: {
    fontSize: '12px',
    height: '37px',
    width: '100%',
    textAlign: 'center',
    tableLayout: 'fixed',
  },
  tableDiv: {
    minHeight: '100px',
    display: 'inline-block',
  },
  tableCell: {
    color: '#666666',
    backgroundColor: '#f7f7f7',
    minWidth: '100%',
  },
  colHeading: {
    backgroundColor: 'grey',
    color: 'white',
    padding: '5px 0px',
    width: '100%',
    align: 'center',
  },
  captionP: {
    paddingLeft: '15px',
    margin: '0px',
    float: 'left',
    color: '#666',
    fontSize: '13px',
    display: 'block',
    textAlign: 'left',
    width: '80%',
  },
  captionSpan: {
    float: 'left',
    color: '#c00',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'block',
    width: '80%',
    textAlign: 'left',
    paddingLeft: '15px',
  },
  brandInput: {
    margin: '0px',
    color: '#666',
    fontSize: '13px',
    height: '15px',
    textAlign: 'left',
    padding: '0',
  },
  sizeInput: {
    padding: '0',
    color: '#c00',
    fontSize: '14px',
    height: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  fullWidth: {
    width: '80%',
  },
  headerInput: {
    color: 'white',
    textAlign: 'center',
    fontSize: '14px',
    width: '100%',
    padding: '0',
    fontFamily: 'Targetica, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  dataInput: {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontFamily: 'Targetica, "Helvetica Neue", Helvetica, Arial, sans-serif',
    color: '#666',
  },
  selectedHead: {
    backgroundColor: '#555',
    color: 'white',
    padding: '5px 0px',
    width: '100%',
    align: 'center',
  },
  selectedBody: {
    color: 'white',
    backgroundColor: '#00000020',
    minWidth: '100%',
    height: '100%',
    padding: '5px, 0',
    align: 'center',
  },
  addRowButton: {
    width: '100%',
    fontSize: '20px',
  },
  addColumnButton: {
    height: '100%',
    fontSize: '20px',
    width: '100%',
    maxWidth: '30px',
  },
  addColumnDiv: {
    display: 'inline-block',
    height: '100px',
    marginLeft: '5px',
    width: 'calc(10% - 5px)',
    maxWidth: '30px',
  },
  addRowDiv: {
    paddingTop: '10px',
    textAlign: 'left',
    width: '90%',
  },
  addColumnInternalDiv: {
    width: '100%',
    height: '100%',
    display: 'table',
  },
  hidden: {
    display: 'none',
  },
  disclaimerDiv: {
    width: '90%',
    textAlign: 'left',
    height: '15px',
    fontSize: '10px',
    marginBottom: '20px',
  },
  disclaimerInput: {
    fontSize: '10px',
    width: '100%',
    textAlign: 'left',
  },
  uiMain: {
    paddingTop: '10px',
    height: 'calc(100% - 10px)',
    minWidth: '600px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontFamily: 'Targetica, "Helvetica Neue", Helvetica, Arial, sans-serif',
    display: 'flex',
  },
  input: {
    margin: '20px',
    display: 'inline-flex',
  },
  button: {
    width: '200px',
  },
  sizeChartPreview: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'inline-block',
    width: '80%',
    minWidth: '480px',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  sizeChartPreviewItem: {
    minWidth: '480px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: '95%',
  },
  sideToolBar: {
    height: '100%',
    minWidth: '120px',
    width: 'calc(20% - 1px)',
    backgroundColor: '#ffffff',
    display: 'inline-block',
    borderLeft: '1px',
    borderColor: 'black',
    borderLeftStyle: 'solid',
    overflow: 'auto',
  },
  topBar: {
    textAlign: 'center',
  },
  creationMain: {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    width: '100%',
    height: '100%',
  },
  table: {
    align: 'center',
    margin: 'auto',
  },
  creationButton: {
    height: '50px',
    width: '50px',
  },
  creationButtonHovered: {
    height: '50px',
    width: '50px',
    background: '#c00000',
  },
  title: {
    width: '100%',
    height: '50px',
    fontSize: '35px',
    fontWeight: 'bold',
  },
})

export default styles
