import React from 'react'
import BadgesTable from './BadgesTable'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getBadgesData,
  handleSelectBadges,
  resetFetch,
  downloadAllToExcel,
  downloadSelectedBadgesEvent,
  openCreateBadgeModal,
} from '../../../../store/badges/actionCreator'
import { getBrandsList } from '../../../../store/listOfValues/actionCreator'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../Shared/Constants'
import { gridCheckedLogic } from '../../../Shared/SharedUtils'

class BadgesTableContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {
    this.props.getBadgesData()
    this.props.getBrands()
  }

  handleSelectAllClick = (event, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = this.props.badgesList
    }
    this.props.handleSelectBadges({
      selectedBadges: newSelected,
    })
  }

  handleClick = (_, checkedBadge) => {
    const { selectedBadges } = this.props
    this.props.handleSelectBadges({
      selectedBadges: gridCheckedLogic(selectedBadges, checkedBadge),
    })
  }

  downloadAllToExcel = () => {
    if (this.props.badgesList.length > 10000) {
      this.props.resetFetch(false)
      toast.info('Download up to 10000 items only allowed', TOAST_BODY)
      return false
    }
    this.props.downloadAllToExcel(this.props.badgesList)
  }

  downloadSelectedBadges = () => {
    let query = this.props.selectedBadges.map((item) => item.id)
    this.props.downloadSelectedBadgesEvent(query)
  }

  isSelected = (checkedBadge) =>
    this.props.selectedBadges.indexOf(checkedBadge) !== -1

  render() {
    const {
      badgesList,
      auth,
      selectedBadges,
      openCreateBadgeModal,
      editableBadge,
    } = this.props
    return (
      <BadgesTable
        badges={badgesList}
        auth={auth}
        handleSelectAllClick={this.handleSelectAllClick}
        isSelected={this.isSelected}
        handleClick={this.handleClick}
        selectedBadges={selectedBadges}
        downloadSelectedBadges={this.downloadSelectedBadges}
        downloadAllToExcel={this.downloadAllToExcel}
        openCreateBadgeModal={openCreateBadgeModal}
        editableBadge={editableBadge}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBadgesData,
      handleSelectBadges,
      resetFetch,
      downloadAllToExcel,
      downloadSelectedBadgesEvent,
      openCreateBadgeModal,
      getBrands: getBrandsList,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { badges, auth } = state
  const { badgesList, selectedBadges, editableBadge } = badges
  return {
    badgesList,
    auth,
    selectedBadges,
    editableBadge,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BadgesTableContainer)
