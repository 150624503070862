/* eslint-disable no-undef */
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import BrownseAltText from './BrownseAltText'
import {
  getAltTextBulkUploadData,
  bulkUploadAltTextDropZoneActive,
  wrongFileType,
  confirmWrongFile,
} from '../../../../store/altText/actionCreator'
import { endsWith } from 'lodash'
import readXlsxFile from 'read-excel-file'
import envConfigs from '../../../../config/apiConfig'

function BrowseAltTextContainer(props) {
  const onDrop = (files) => {
    if (endsWith(files[0].name.toLowerCase(), '.xlsx')) {
      readXlsxFile(files[0]).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        if (rows.length < envConfigs.altTexbulkUpload.maxNumberofFiles) {
          props.bulkUploadAltTextDropZoneActive(false)
          var requestBody = {
            files: files,
            processed_by: props.auth.email
              ? props.auth.email
              : props.auth.lanId,
          }
          props.getAltTextBulkUploadData(requestBody, files[0].name)
        } else {
          var errorMessage = `More than ${
            envConfigs.altTexbulkUpload.maxNumberofFiles - 1
          } Excel Rows Not Allowed`
          props.wrongFileType(files[0].name, errorMessage, errorMessage)
          props.bulkUploadAltTextDropZoneActive(false)
        }
      })
    } else {
      var errorTitle = 'is not valid for bulk copy upload'
      var errorMessage = 'Please submit an excel document (.xlsx)'
      props.wrongFileType(files[0].name, errorTitle, errorMessage)
      props.bulkUploadAltTextDropZoneActive(false)
    }
  }
  const onDragEnter = (files) => {
    props.bulkUploadAltTextDropZoneActive(true)
  }

  const onDragLeave = (files) => {
    props.bulkUploadAltTextDropZoneActive(false)
  }
  return (
    <BrownseAltText
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      dropZoneEnter={props.dropZoneEnter}
      fileName={props.fileName}
      dropZoneErrorMessage={props.dropZoneErrorMessage}
      dropZoneErrorTitle={props.dropZoneErrorTitle}
      confirmWrongFile={props.confirmWrongFile}
      validFile={props.validFile}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAltTextBulkUploadData,
      bulkUploadAltTextDropZoneActive,
      wrongFileType,
      confirmWrongFile,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, altTextReducer } = state
  const {
    dropZoneEnter,
    fileName,
    dropZoneErrorMessage,
    dropZoneErrorTitle,
    validFile,
  } = altTextReducer
  return {
    auth,
    dropZoneEnter,
    fileName,
    dropZoneErrorMessage,
    dropZoneErrorTitle,
    validFile,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowseAltTextContainer)
