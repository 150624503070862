/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import HeaderTitle from '../../Shared/Header/HeaderTitle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  CircularProgress,
  TablePagination,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styles from './theme'
import { withStyles } from '@material-ui/core/styles'
import {
  buildFilterExpand,
  newFilterAction,
  loadExistingFilter,
  requestImageSearchData,
  clearFilters,
  buildFilterAction,
  changeFilterEvent,
  selectFilterValueEvent,
  removeFilterHandler,
  getImageSearchStrapiData,
  getDeptClassList,
  handleChangePage,
  changeDefaultPageSize,
  handleSelectItem,
} from '../../../store/images/search/actionCreator'

import { requestImageReviewData } from '../../../store/images/review/actionCreator'
import { buildFilterAction as uploadHistoryBuildFilterAction } from '../../../store/images/history/actionCreator'
import {
  buildFilterAction as manageBuildFilterAction,
  searchForRelatedItems,
  toggleRelatedItemsModal,
} from '../../../store/images/manage/actionCreator'
import {
  getVendorList,
  getBrandsList,
} from '../../../store/listOfValues/actionCreator'
import {
  saveFilterDataEvent,
  fetchSavedFiltersEvent,
  toggleSaveFilterDialogue,
  toggleConfirmation,
  removeSavedFilter,
} from '../../../store/auth/actionCreator'
import { requestImageReviewHistoryData } from '../../../store/images/reviewHistory/actionCreator'
import {
  isExternalUser,
  splitTcins,
  chipFilter,
  dropDownListFilter,
  dateRangeFilter,
  deptClassFilter,
  splitDpci,
  weeksAgoOutFilter,
} from '../../Shared/SharedUtils'
import DefaultAlert from '../../Shared/StrapiComponents/DefaultAlert'
import MyFilter from '../../Shared/Filter/MyFilter/MyFilter'
import BuildFilter from '../../Shared/Filter/BuildFilter'
import SearchResultContainer from './Components/SearchResultContainer'
import {
  FilterData,
  Timespans,
  ImageApprovalStatus,
  IsuItemSetUpStatus,
  LaunchTimespans,
  WeeksInOutData,
} from './FilterData'
import NewFilter from '../../Shared/Filter/NewFilter'
import Alert from '../../Shared/StrapiComponents/Alert'
import StrapiExpansionPanel from '../../Shared/StrapiComponents/StrapiExpansionPanel'
import { isEqual, isEmpty, uniqBy, some } from 'lodash'
import { toast } from 'react-toastify'
import { TOAST_BODY, ROUTES } from '../../Shared/Constants'
import RelatedItemsModal from '../Manage/Components/RelatedItemsModal'
import { withRouter } from '../../Shared/WithRouterHoc'

function Search(props) {
  const {
    isFetching = false,
    classes = {},
    buildFilterContainerShown = false,
    newFilterContainerShown = false,
    buildFilterExpansionPanel = false,
    dataContainerShown = false,
    showDefaultAlert = false,
    auth = {},
    alert = null,
    information = null,
    faq = null,
    rhData = [],
    userProfile = {},
    isConfirmationOpen = false,
    selectedFilter = -1,
    filterValues = {},
    defaultFilterValues = {},
    selectedFilters = [],
    saveFilter = {},
    isSaveFilterOpen = false,
    pristine,
    isFetchingSavedFilters = false,
    errorSearching = false,
    pageName = 'ImageSearch',
    vendorList = [],
    isFetchingAuth = false,
    deptClassList = [],
    selectedItems = [],
    isFetchingLov,
    showRelatedItemsModal = false,
    toggleRelatedItemsModal = () => {},
    relatedItemsData = [],
    relatedTcin = '',
    brandsList = [],
  } = props

  const initialFilterValue = {
    dateRange: -1,
    fromDate: -1,
    toDate: -1,
    deptClass: {
      dept: -1,
      class: -1,
    },
    weeks: {
      startDate: null,
      endDate: null,
    },
    approvalStatus: -1,
    tcins: [],
    vendor: [],
    dpcis: [],
    itemSetupStatus: -1,
  }

  useEffect(() => {
    props.fetchSavedFiltersEvent()
    props.getImageSearchStrapiData(isExternalUser(auth))
    if (!isEmpty(selectedFilters)) {
      let filterCriteria = props.selectedFilters
      props.buildFilterExpand(false)
      props.newFilterAction(true)
      props.loadExistingFilter(filterCriteria)
      props.requestImageSearchData(
        filterCriteria,
        0,
        pageSize,
        sortDirection,
        sortField
      )
    }
  }, [])

  // if filterValues exist on init, open the My Filters and Build Filter panels
  useEffect(() => {
    if (!isEqual(filterValues, initialFilterValue)) {
      props.newFilterAction(true)
      props.buildFilterExpand(true)
    }
  }, [filterValues])

  // if user comes in with tcins in the URL, send the parameter through the handleAddTcin function
  // this will be used by LaunchPad and Grouper teams
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (urlSearchParams.has('tcins')) {
      const tcins = urlSearchParams.get('tcins')
      if (some(tcins)) {
        props.changeFilterEvent({
          selectedFilter: 'tcin',
        })
        handleAddTcin(tcins)
      }
    }
  }, [])

  useEffect(() => {
    if (
      buildFilterContainerShown ||
      newFilterContainerShown ||
      dataContainerShown
    ) {
      expandFaq(false)
    }
  }, [
    buildFilterContainerShown || newFilterContainerShown || dataContainerShown,
  ])

  useEffect(() => {
    // Call vendor api for the filter.
    if (selectedFilter === 'vendor') {
      props.getVendorList()
    }
    // Call deptClass api for the filter
    if (selectedFilter === 'deptClass') {
      props.getDeptClassList()
    }
    if (selectedFilter === 'brand') {
      props.getBrandsList()
    }
  }, [selectedFilter])

  const [expansionPanelOpen, expandFaq] = useState(true)

  const handlePanel = () => {
    props.buildFilterExpand(!props.buildFilterExpansionPanel)
  }

  const handleFaqPanel = () => {
    expandFaq(!expansionPanelOpen)
  }

  const buildFilter = (selectedFilter, selectedFilterValue) => {
    if (!props.isFetching) {
      props.buildFilterAction(
        {
          newFilterContainerShown: true,
          dataContainerShown: true,
          selectedFilters: props.selectedFilters,
          selectedFilter: selectedFilter,
          selectedFilterValue: selectedFilterValue,
        },
        props.currentPage,
        pageSize,
        sortDirection,
        sortField
      )
    }
  }

  /**
   * This method calls when user clicks on ADD button on Build filter
   * section. This method reset value for selected value once it add to filter
   * list
   */
  const addNewFilter = () => {
    var selectedFilter = FilterData.filter(
      (item) => item.value === props.selectedFilter
    )[0]
    var selectedFilterValue
    if (selectedFilter.value === 'tcin') {
      chipFilter(
        filterValues.tcins,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'dpcis') {
      chipFilter(
        filterValues.dpcis,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'brand') {
      chipFilter(
        filterValues.brand,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'dateRange') {
      dateRangeFilter(
        filterValues,
        selectedFilterValue,
        selectedFilter,
        buildFilter,
        false
      )
    }
    if (selectedFilter.value === 'vendor') {
      chipFilter(
        filterValues.vendor,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (props.selectedFilter === 'deptClass') {
      deptClassFilter(
        filterValues.deptClass,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (props.selectedFilter === 'weeksOutAgo') {
      weeksAgoOutFilter(
        filterValues.weeks,
        selectedFilterValue,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'approvalStatus') {
      dropDownListFilter(
        ImageApprovalStatus,
        filterValues.approvalStatus,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'itemSetupStatus') {
      dropDownListFilter(
        IsuItemSetUpStatus,
        filterValues.itemSetupStatus,
        selectedFilter,
        buildFilter
      )
    }
    if (selectedFilter.value === 'launchDate') {
      dateRangeFilter(
        filterValues,
        selectedFilterValue,
        selectedFilter,
        buildFilter,
        false
      )
    }
    // Return value to parent components
  }

  const onFilterSelect = (event) => {
    props.changeFilterEvent({
      selectedFilter: event.target.value,
    })
  }

  const selectFilterValue = (selectedFilterValues) => {
    props.selectFilterValueEvent({
      filterValues: selectedFilterValues,
    })
  }

  const onFilterValueSelect = (event) => {
    if (event.target.name === 'dateRange') {
      /**
       * Raise event for filter value select
       * filterValues: selectedFilterValues,
       */
      selectFilterValue({
        ...initialFilterValue,
        dateRange: event.target.value,
      })
    }
    if (event.target.name === 'dept') {
      /**
       * Raise event for filter value select
       * filterValues: selectedFilterValues,
       */
      selectFilterValue({
        ...initialFilterValue,
        deptClass: { dept: event.target.value, class: -1 },
      })
    }
    if (event.target.name === 'class') {
      selectFilterValue({
        ...initialFilterValue,
        deptClass: {
          dept: filterValues.deptClass.dept,
          class: event.target.value,
        },
      })
    }
    if (event.target.name === 'startWeek') {
      /**
       * Raise event for filter value select
       * filterValues: selectedFilterValues,
       */
      selectFilterValue({
        ...initialFilterValue,
        weeks: { startDate: event.target.value, endDate: null },
      })
    }

    if (event.target.name === 'endWeek') {
      selectFilterValue({
        ...initialFilterValue,
        weeks: {
          startDate: filterValues.weeks.startDate,
          endDate: event.target.value,
        },
      })
    }

    if (event.target.name === 'approvalStatus') {
      selectFilterValue({
        ...initialFilterValue,
        approvalStatus: event.target.value,
      })
    }
    if (event.target.name === 'itemSetupStatus') {
      selectFilterValue({
        ...initialFilterValue,
        itemSetupStatus: event.target.value,
      })
    }
  }

  const handleAddTcin = (selectedTcins) => {
    let chips = []
    splitTcins(selectedTcins).forEach((i) => {
      chips.push({
        value: i,
        display: i,
      })
    })
    if (
      props.filterValues !== undefined &&
      props.filterValues.tcins.length > 0
    ) {
      props.filterValues.tcins.forEach((item) => {
        chips.push(item)
      })
    }
    selectFilterValue({ ...initialFilterValue, tcins: uniqBy(chips, 'value') })
  }

  const handleDeleteDpci = (deletedDpci) => {
    let chips = []
    props.filterValues.dpcis.forEach((item) => {
      if (item.value !== deletedDpci) {
        chips.push(item)
      }
    })
    selectFilterValue({ ...initialFilterValue, dpcis: chips })
  }

  const handleAddDpci = (selectedDpcis) => {
    let chips = []
    splitDpci(selectedDpcis).forEach((i) => {
      chips.push({
        value: i,
        display: i,
      })
    })
    if (
      props.filterValues !== undefined &&
      props.filterValues.dpcis.length > 0
    ) {
      props.filterValues.dpcis.forEach((item) => {
        chips.push(item)
      })
    }
    selectFilterValue({ ...initialFilterValue, dpcis: uniqBy(chips, 'value') })
  }

  const handleDeleteTcin = (deletedTcin) => {
    let chips = []
    props.filterValues.tcins.forEach((item) => {
      if (item.value !== deletedTcin) {
        chips.push(item)
      }
    })
    selectFilterValue({ ...initialFilterValue, tcins: chips })
  }

  const handleAddVendor = (vendorNameOrId) => {
    let vendorInfo = null
    let chips = []
    vendorInfo = vendorList.filter((vendor) => {
      return (
        vendor.vendor_name.toLowerCase() === vendorNameOrId.toLowerCase() ||
        vendor.gms_vendor_id.toString() === vendorNameOrId
      )
    })
    if (vendorInfo.length > 0) {
      vendorInfo.forEach((i) => {
        chips.push({
          display: i.gms_vendor_id + ' - ' + i.vendor_name,
          value: i.gms_vendor_id,
        })
      })
      selectFilterValue({
        ...initialFilterValue,
        vendor: uniqBy(chips, 'value'),
      })
    } else {
      toast.info('No vendor found, try again.', TOAST_BODY)
    }
  }

  const handleAddBrand = (brandNameOrId) => {
    let brandInfo = null
    let chips = []
    brandInfo = brandsList.filter((brand) => {
      return (
        brand.brandName.toLowerCase() === brandNameOrId.toLowerCase() ||
        brand.brandId.toString() === brandNameOrId
      )
    })
    if (brandInfo.length > 0) {
      brandInfo.forEach((i) => {
        chips.push({
          display: i.brandName,
          value: i.brandId,
        })
      })
      selectFilterValue({
        ...initialFilterValue,
        brand: uniqBy(chips, 'value'),
      })
    } else {
      toast.info('No Brand found, try again.', TOAST_BODY)
    }
  }

  const changeDateRangeFilter = (dateRange) => {
    let fromDateEpoch = dateRange && dateRange[0] ? dateRange[0].getTime() : -1
    let toDateEpoch = dateRange && dateRange[1] ? dateRange[1].getTime() : -1
    selectFilterValue({
      ...initialFilterValue,
      dateRange: 'datePicker',
      fromDate: fromDateEpoch,
      toDate: toDateEpoch,
    })
  }

  const removeSavedFilterHandler = (item) => {
    props.toggleConfirmation(true, { id: item })
  }

  const handleCancelConfirmation = () => {
    props.toggleConfirmation(false, null)
  }

  const handleConfirmRemoveFilter = () => {
    props.removeSavedFilter(props.confirmationPayload.id)
  }

  const loadFilterHandler = (item) => {
    let filterData = JSON.parse(item.filter_criteria)
    props.buildFilterExpand(false)
    props.newFilterAction(true)
    props.loadExistingFilter(filterData)
    props.requestImageSearchData(
      filterData,
      0,
      pageSize,
      sortDirection,
      sortField
    )
  }

  const onClick = () => {
    props.newFilterAction(true)
    props.buildFilterExpand(true)
    props.clearFilters()
  }

  const removeFilterHandler = (mainFilter, filterVal) => {
    props.removeFilterHandler(
      {
        selectedFilters: props.selectedFilters,
        mainFilterValue: mainFilter,
        filterValue: filterVal,
      },
      props.currentPage,
      props.defaultPageSize,
      sortDirection,
      sortField
    )
  }

  const saveNewFilter = () => {
    if (props.selectedFilters && props.selectedFilters.length > 0) {
      props.toggleSaveFilterDialogue(true)
    }
  }

  const clearFilters = () => {
    props.clearFilters()
  }

  const handleClose = () => {
    props.toggleSaveFilterDialogue(false)
  }

  const handleSave = () => {
    props.saveFilterDataEvent({
      filterName: props.saveFilter.values.saveFilter,
      filterCriteria: JSON.stringify(props.selectedFilters),
      pageSize: pageSize,
      sortOrder: sortDirection,
      sortField: sortField,
      pageName: pageName,
    })
  }

  const handleChangeDefaultPageSize = (event) => {
    props.changeDefaultPageSize(
      {
        defaultPageSize: event.target.value,
        selectedFilters: props.selectedFilters,
      },
      props.sortDirection,
      props.sortField,
      props.pageName
    )
  }

  const savedFilterData =
    userProfile && userProfile.search_filters
      ? userProfile.search_filters.filter(
          (filter) => filter.page_name === pageName
        )
      : []

  const paginationValues =
    userProfile && userProfile.pagination
      ? userProfile.pagination.filter((item) => item.page_name === pageName)
      : undefined

  const pageSize =
    paginationValues && paginationValues[0] && paginationValues[0].page_size
      ? paginationValues[0].page_size
      : props.defaultPageSize

  const sortDirection =
    paginationValues && paginationValues[0] && paginationValues[0].sort_order
      ? paginationValues[0].sort_order.toUpperCase()
      : props.sortDirection

  const sortField =
    paginationValues && paginationValues[0] && paginationValues[0].sort_field
      ? paginationValues[0].sort_field
      : props.sortField

  // data for navigating to upload history
  const uploadHistoryPaginationValues =
    userProfile && userProfile.pagination
      ? userProfile.pagination.filter(
          (item) => item.page_name === props.imageHistoryPageName
        )
      : undefined
  const uploadHistoryPageSize =
    uploadHistoryPaginationValues &&
    uploadHistoryPaginationValues[0] &&
    uploadHistoryPaginationValues[0].page_size
      ? uploadHistoryPaginationValues[0].page_size
      : props.imageHistoryDefaultPageSize
  // data for navigating to search and manage
  const managePaginationValues =
    userProfile && userProfile.pagination
      ? userProfile.pagination.filter(
          (item) => item.page_name === props.imageManagePageName
        )
      : undefined
  const managePageSize =
    managePaginationValues &&
    managePaginationValues[0] &&
    managePaginationValues[0].page_size
      ? managePaginationValues[0].page_size
      : props.imageManageDefaultPageSize

  const handleChangePage = (_, page) => {
    props.handleChangePage(
      {
        currentPage: page,
        defaultPageSize: pageSize,
        selectedFilters: props.selectedFilters,
      },
      props.sortDirection,
      props.sortField
    )
  }

  const manageTcins = (tcins) => {
    props.toggleRelatedItemsModal(false)
    props.manageBuildFilterAction(
      {
        selectedFilters: [],
        selectedFilter: {
          display: 'TCIN(s)',
          value: 'tcin',
        },
        selectedFilterValue: tcins.map((tcin) => ({
          display: tcin,
          value: tcin,
        })),
      },
      pageSize,
      'asc',
      'TCIN'
    )
    //props.history.push(ROUTES.IMAGES.MANAGE)
    props.router.navigate({ pathname: ROUTES.IMAGES.MANAGE })
  }
  return (
    <React.Fragment>
      <HeaderTitle title="Image Search" />
      <Helmet title="Image Search" />
      {showDefaultAlert ? <DefaultAlert /> : null}
      {alert ? <Alert alert={alert} /> : null}
      <Grid container>
        <Grid item xs={12} sm={12} className={classes.marginBottom5}>
          <MyFilter
            isConfirmationOpen={isConfirmationOpen}
            handleCancelConfirmation={handleCancelConfirmation}
            handleConfirmRemoveFilter={handleConfirmRemoveFilter}
            savedFilterData={savedFilterData}
            onClick={onClick}
            loadFilterHandler={loadFilterHandler}
            removeSavedFilterHandler={removeSavedFilterHandler}
            isFetchingSavedFilters={isFetchingSavedFilters}
            disabled={isFetchingAuth}
          />
        </Grid>
        <React.Fragment>
          {(buildFilterContainerShown || newFilterContainerShown) && (
            <Accordion
              className={classes.buildFilterExpansion}
              expanded={buildFilterExpansionPanel}
              onChange={handlePanel}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {buildFilterExpansionPanel ? 'Hide ' : 'Show '} Filter
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {buildFilterContainerShown && (
                  <Grid item xs={12} sm={6}>
                    <BuildFilter
                      selectedFilter={selectedFilter}
                      onFilterSelect={onFilterSelect}
                      filterValues={filterValues}
                      onFilterValueSelect={onFilterValueSelect}
                      handleAddTcin={handleAddTcin}
                      handleAddDpci={handleAddDpci}
                      handleDeleteTcin={handleDeleteTcin}
                      handleDeleteDpci={handleDeleteDpci}
                      isFetching={isFetching}
                      addNewFilter={addNewFilter}
                      defaultFilterValues={defaultFilterValues}
                      FilterData={FilterData}
                      Timespans={Timespans}
                      LaunchTimespans={LaunchTimespans}
                      changeDateRangeFilter={changeDateRangeFilter}
                      handleAddVendor={handleAddVendor}
                      deptClassList={deptClassList}
                      ApprovalStatus={ImageApprovalStatus}
                      IsuItemSetUpStatus={IsuItemSetUpStatus}
                      vendorList={vendorList}
                      isFetchingLov={isFetchingLov}
                      onSearchChange={(newVal) => props.getVendorList(newVal)}
                      onSearchChangeBrand={(newVal) =>
                        props.getBrandsList(newVal)
                      }
                      handleAddBrand={handleAddBrand}
                      brandsList={brandsList}
                      WeeksInOutData={WeeksInOutData}
                    />
                  </Grid>
                )}
                {newFilterContainerShown && (
                  <Grid item xs={12} sm={6}>
                    <NewFilter
                      clearFilters={clearFilters}
                      selectedFilters={selectedFilters}
                      saveNewFilter={saveNewFilter}
                      removeFilterHandler={removeFilterHandler}
                      isSaveFilterOpen={isSaveFilterOpen}
                      saveFilter={saveFilter}
                      handleClose={handleClose}
                      handleSave={handleSave}
                      pristine={pristine}
                      disabled={isFetchingAuth}
                    />
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {(information || faq) && (
            <StrapiExpansionPanel
              faq={faq}
              information={information}
              expansionPanelOpen={expansionPanelOpen}
              handleFaqPanel={handleFaqPanel}
            />
          )}
        </React.Fragment>
        <Paper className={classes.width100}>
          {isFetching && (
            <Grid className={classes.progressBar}>
              <CircularProgress />
            </Grid>
          )}
          {dataContainerShown && !isFetching && !errorSearching && (
            <React.Fragment>
              <Grid item xs={12} sm={12}>
                <TablePagination
                  component="div"
                  count={props.totalElements}
                  rowsPerPage={pageSize}
                  page={props.currentPage}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeDefaultPageSize}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SearchResultContainer
                  searchData={rhData}
                  selectedItems={selectedItems}
                  handleSelectItem={props.handleSelectItem}
                  rowsPerPage={pageSize}
                  auth={auth}
                  uploadHistoryClickAction={
                    props.uploadHistoryBuildFilterAction
                  }
                  uploadHistoryPageSize={uploadHistoryPageSize}
                  manageClickAction={props.manageBuildFilterAction}
                  requestImageReviewHistoryData={
                    props.requestImageReviewHistoryData
                  }
                  managePageSize={managePageSize}
                  requestImageReviewData={props.requestImageReviewData}
                  searchRelatedItems={props.searchForRelatedItems}
                />
              </Grid>
            </React.Fragment>
          )}
        </Paper>
        {errorSearching && (
          <Grid item xs={12} sm={12}>
            <div className={classes.noResult}>
              Filter criteria too broad, please add more filters to narrow
              results.
            </div>
          </Grid>
        )}

        {showRelatedItemsModal && (
          <RelatedItemsModal
            open={showRelatedItemsModal}
            toggleRelatedItemsModal={toggleRelatedItemsModal}
            relatedItems={relatedItemsData}
            relatedTcin={relatedTcin}
            manageTcins={manageTcins}
          />
        )}
      </Grid>
      <div className={classes.toolbarSpacer} />
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getImageSearchStrapiData,
      buildFilterExpand,
      newFilterAction,
      loadExistingFilter,
      requestImageSearchData,
      clearFilters,
      buildFilterAction,
      changeFilterEvent,
      selectFilterValueEvent,
      removeFilterHandler,
      saveFilterDataEvent,
      fetchSavedFiltersEvent,
      toggleSaveFilterDialogue,
      toggleConfirmation,
      removeSavedFilter,
      getVendorList,
      getDeptClassList,
      handleChangePage,
      changeDefaultPageSize,
      handleSelectItem,
      uploadHistoryBuildFilterAction,
      manageBuildFilterAction,
      requestImageReviewData,
      requestImageReviewHistoryData,
      searchForRelatedItems,
      toggleRelatedItemsModal,
      getBrandsList,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { imageSearch, imageHistory, imageManage, auth, form, lov } = state
  const {
    buildFilterContainerShown,
    newFilterContainerShown,
    dataContainerShown,
    isFetching,
    buildFilterExpansionPanel,
    alert,
    information,
    faq,
    rhData,
    selectedFilter,
    filterValues,
    defaultFilterValues,
    defaultPageSize,
    sortDirection,
    sortField,
    currentPage,
    selectedFilters,
    errorSearching,
    pageName,
    deptClassList,
    totalElements,
    selectedItems,
  } = imageSearch
  const { showRelatedItemsModal, relatedItemsData, relatedTcin } = imageManage
  const {
    isFetchingSavedFilters,
    userProfile,
    isSaveFilterOpen,
    isConfirmationOpen,
    confirmationPayload,
    isFetchingAuth,
  } = auth
  const vendorList = lov.vendorList
  const brandsList = lov.brandsList
  const isFetchingLov = lov.isFetching
  const imageHistoryDefaultPageSize = imageHistory.defaultPageSize
  const imageHistoryPageName = imageHistory.pageName
  const imageManageDefaultPageSize = imageManage.defaultPageSize
  const imageManagePageName = imageManage.pageName
  const { saveFilter } = form
  return {
    buildFilterContainerShown,
    newFilterContainerShown,
    dataContainerShown,
    isFetching,
    buildFilterExpansionPanel,
    auth,
    alert,
    information,
    faq,
    rhData,
    userProfile,
    isConfirmationOpen,
    selectedFilter,
    filterValues,
    defaultFilterValues,
    confirmationPayload,
    defaultPageSize,
    sortDirection,
    sortField,
    currentPage,
    selectedFilters,
    saveFilter,
    isSaveFilterOpen,
    isFetchingSavedFilters,
    errorSearching,
    pageName,
    vendorList,
    isFetchingAuth,
    deptClassList,
    totalElements,
    selectedItems,
    imageHistoryDefaultPageSize,
    imageHistoryPageName,
    imageManageDefaultPageSize,
    imageManagePageName,
    isFetchingLov,
    showRelatedItemsModal,
    relatedItemsData,
    relatedTcin,
    brandsList,
  }
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Search)))
