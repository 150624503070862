import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import Paper from '@material-ui/core/Paper'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
} from '@material-ui/core'
import AddTcinContainer from '../AddTcin/AddTcinContainer'
import ExampleImageFinderContainer from '../ExampleImageFinder/ExampleImageFinderContainer'
import TagSelectionContainer from '../TagSelection/TagSelectionContainer'
import SimilarItemContainer from '../SimilarItem/SimilarItemContainer'

function ClassificationPage(props) {
  const {
    currentActiveStep,
    startOver,
    backToSelectTags,
    backToSelectImage,
    backToEnterTcin,
    currentSimilarImageCount,
    ref,
    classes,
  } = props
  return (
    <div ref={ref}>
      <Paper className={classes.marginTop25}>
        <Stepper activeStep={currentActiveStep} orientation="vertical">
          <Step key="label" active={currentActiveStep >= 0}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.stepperIcon,
                  completed: classes.completed,
                  active: classes.active,
                },
              }}
            >
              Specify an item TCIN to begin training the classifier
            </StepLabel>
            <StepContent>
              <AddTcinContainer disabled={currentActiveStep > 0} />
            </StepContent>
          </Step>
          {/* Step 2 */}
          <Step
            className={classes.checkColor}
            key="label2"
            active={currentActiveStep >= 1}
          >
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.stepperIcon,
                  completed: classes.completed,
                  active: classes.active,
                },
              }}
            >
              Select a specific image{' '}
              {currentActiveStep === 1 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={backToEnterTcin}
                >
                  Go Back
                </Button>
              )}
            </StepLabel>
            <StepContent>
              <ExampleImageFinderContainer
                stepIsDisabled={currentActiveStep > 1}
              />
            </StepContent>
          </Step>
          {/* Step 3 */}
          <Step key="label3" active={currentActiveStep >= 2}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.stepperIcon,
                  completed: classes.completed,
                  active: classes.active,
                },
              }}
            >
              Select classification tags{' '}
              {currentActiveStep === 2 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={backToSelectImage}
                >
                  Go Back
                </Button>
              )}
            </StepLabel>
            <StepContent>
              <TagSelectionContainer stepIsDisabled={currentActiveStep > 2} />
            </StepContent>
          </Step>
          {/* Step 4 */}
          <Step key="label4" active={currentActiveStep >= 3}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.stepperIcon,
                  completed: classes.completed,
                  active: classes.active,
                },
              }}
            >
              Validate your selection{' '}
              {currentActiveStep === 3 && currentSimilarImageCount === 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={backToSelectTags}
                >
                  Go Back
                </Button>
              )}
            </StepLabel>
            <StepContent>
              <SimilarItemContainer />
            </StepContent>
          </Step>
        </Stepper>
        {currentActiveStep < 3 && (
          <Button
            className={classes.startOverButton}
            onClick={startOver}
            variant="outlined"
            color="primary"
          >
            Start Over
          </Button>
        )}
      </Paper>
    </div>
  )
}

export default withStyles(styles)(ClassificationPage)
