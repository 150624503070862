import {
  ADD_FILES_VIDEO,
  VIDEOS_DROP_ZONE_ACTIVE,
  VIDEOS_RESET_FETCH,
  VALID_NUMBER_OF_VIDEO_FILES,
  VIDEOS_ON_DROP_LOADING,
  VIDEO_FILES_ADDED,
  CC_FILES_ADDED,
  POSTER_FRAME_FILES_ADDED,
  TRANSCRIPT_FILES_ADDED,
  REMOVE_ALL_FILES,
  UPDATE_INVALID_TCIN_LIST,
  UPDATE_NOTOWNED_TCIN_LIST,
  EDIT_MODE,
  EDIT_MODE_TITLE,
  SET_EXTERNAL_GROUP,
  ADD_DELETE_ASSET,
  VIDEO_GROUP_STATUS,
  UPDATE_SUCCESSFUL_EVENT,
  EDIT_VTT_FILE,
  STORE_VIDEOS_UPLOAD_STRAPI_DATA,
  SHOW_DEFAULT_ALERT_VIDEOS,
  OPEN_VIDEO_HELP_CONTENT,
  CLEAR_VIDEO_HELP_CONTENT,
  RECEIVE_VIDEO_DATA_BY_ID,
  CLEAR_VIDEO_DATA_BY_ID,
  REQUEST_VIDEO_DATA_BY_ID,
  REQUEST_ADD_TCIN_TO_VIDEO_GROUP,
  RECEIVE_TCIN_DATA,
  SELECT_VIDEO_TCIN_REVIEW_EVENT,
  SET_SHOW_REVIEW_OPTIONS,
  RESET_REJECTION_MESSAGE,
  SET_SHOW_VIDEO_ACTIVE_CHECKBOX,
  SET_VIDEO_ACTIVE,
  SET_REJECTION_MESSAGE,
  SHOW_START_OVER_CONFIRMATION,
} from './actionType'
import {
  VIDEO_ASSETS,
  VIDEO_EDIT_MODE,
} from '../../../components/Shared/Constants'

export const DefaultState = {
  files: [],
  numberValidFiles: 0,
  isFetching: false,
  isFetchingOnDrop: false,
  dropZoneEnter: {
    video: false,
    closedCaption: false,
    posterFrame: false,
    transcript: false,
  },
  invalidTcinList: [],
  notOwnedTcinList: [],
  videoFileAdded: false,
  ccFileAdded: false,
  posterFrameFileAdded: false,
  transcriptFileAdded: false,
  editMode: VIDEO_EDIT_MODE.HISTORY,
  editModeTitle: '',
  externalGroupJobId: null,
  deleteAsset: [],
  videoGroupStatus: null,
  uploadComplete: false,
  vttEditBoxOpen: false,
  vttEditData: '',
  vttEditBoxTitle: '',
  alert: null,
  information: null,
  videoInfo: null,
  closedCaptionInfo: null,
  posterFrameInfo: null,
  transcriptInfo: null,
  faq: null,
  showDefaultAlert: false,
  selectedHelpContent: null,
  requestedVideoData: null,
  selectedTcins: [],
  showRejectButtons: false,
  showVideoActiveCheckbox: false,
  rejectionMessages: {
    video_reject: null,
    closed_caption_reject: null,
    poster_frame_reject: null,
    transcript_reject: null,
  },
  videoIsActive: false,
  openStartOverModal: false,
}

export default function videoUploadReducer(state = DefaultState, action = {}) {
  switch (action.type) {
    case ADD_FILES_VIDEO: {
      const { payload } = action
      const newState = {
        ...state,
        files: payload.files,
      }
      return newState
    }
    case VIDEOS_DROP_ZONE_ACTIVE: {
      const { payload } = action
      const newState = {
        ...state,
        dropZoneEnter: payload.dropZoneEnter,
      }
      return newState
    }
    case VALID_NUMBER_OF_VIDEO_FILES: {
      const { payload } = action
      const newState = {
        ...state,
        numberValidFiles: payload.numberValidFiles,
      }
      return newState
    }
    case VIDEOS_ON_DROP_LOADING: {
      const { payload } = action
      const newState = {
        ...state,
        isFetchingOnDrop: payload.isFetchingOnDrop,
      }
      return newState
    }
    case VIDEOS_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case VIDEO_FILES_ADDED: {
      const { payload } = action
      const newState = {
        ...state,
        videoFileAdded: payload.videoFileAdded,
      }
      return newState
    }
    case CC_FILES_ADDED: {
      const { payload } = action
      const newState = {
        ...state,
        ccFileAdded: payload.ccFileAdded,
      }
      return newState
    }
    case POSTER_FRAME_FILES_ADDED: {
      const { payload } = action
      const newState = {
        ...state,
        posterFrameFileAdded: payload.posterFrameFileAdded,
      }
      return newState
    }
    case TRANSCRIPT_FILES_ADDED: {
      const { payload } = action
      const newState = {
        ...state,
        transcriptFileAdded: payload.transcriptFileAdded,
      }
      return newState
    }
    case REMOVE_ALL_FILES: {
      const { payload } = action
      const newState = {
        ...state,
        videoFileAdded: payload.videoFileAdded,
        ccFileAdded: payload.ccFileAdded,
        posterFrameFileAdded: payload.posterFrameFileAdded,
        transcriptFileAdded: payload.transcriptFileAdded,
      }
      return newState
    }
    case UPDATE_INVALID_TCIN_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        invalidTcinList: payload.invalidTcinList,
      }
      return newState
    }
    case UPDATE_NOTOWNED_TCIN_LIST: {
      const { payload } = action
      const newState = {
        ...state,
        notOwnedTcinList: payload.notOwnedTcinList,
      }
      return newState
    }
    case EDIT_MODE: {
      const { payload } = action
      const newState = {
        ...state,
        editMode: payload.editMode,
      }
      return newState
    }
    case EDIT_MODE_TITLE: {
      const { payload } = action
      const newState = {
        ...state,
        editModeTitle: payload.editModeTitle,
      }
      return newState
    }
    case SET_EXTERNAL_GROUP: {
      const { payload } = action
      const newState = {
        ...state,
        externalGroupJobId: payload.externalGroupJobId,
      }
      return newState
    }
    case ADD_DELETE_ASSET: {
      const { payload } = action
      const newState = {
        ...state,
        deleteAsset: payload.deleteAsset,
      }
      return newState
    }
    case VIDEO_GROUP_STATUS: {
      const { payload } = action
      const newState = {
        ...state,
        videoGroupStatus: payload.videoGroupStatus,
      }
      return newState
    }
    case UPDATE_SUCCESSFUL_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        uploadComplete: payload.uploadComplete,
      }
      return newState
    }
    case EDIT_VTT_FILE: {
      const { payload } = action
      const newState = {
        ...state,
        vttEditBoxOpen: payload.vttEditBoxOpen,
        vttEditData: payload.vttEditData,
        vttEditBoxTitle: payload.vttEditBoxTitle,
      }
      return newState
    }
    case STORE_VIDEOS_UPLOAD_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        videoInfo: payload.videoInfo,
        closedCaptionInfo: payload.closedCaptionInfo,
        posterFrameInfo: payload.posterFrameInfo,
        transcriptInfo: payload.transcriptInfo,
        faq: payload.faq,
      }
      return newState
    }
    case SHOW_DEFAULT_ALERT_VIDEOS: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case OPEN_VIDEO_HELP_CONTENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedHelpContent: payload,
      }
      return newState
    }
    case CLEAR_VIDEO_HELP_CONTENT: {
      const newState = {
        ...state,
        selectedHelpContent: null,
      }
      return newState
    }
    case RECEIVE_VIDEO_DATA_BY_ID: {
      const { payload } = action
      const newState = {
        ...state,
        requestedVideoData: payload.requestedVideoData,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case CLEAR_VIDEO_DATA_BY_ID: {
      const { payload } = action
      const newState = {
        ...state,
        requestedVideoData: payload.requestedVideoData,
        isFetching: payload.isFetching,
        selectedTcins: payload.selectedTcins,
      }
      return newState
    }
    case REQUEST_VIDEO_DATA_BY_ID: {
      const { payload } = action
      const newState = {
        ...state,
        requestedVideoData: payload.requestedVideoData,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case RECEIVE_TCIN_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        requestedVideoData: !state.requestedVideoData
          ? { lst_group_item_data: payload.requestedTcinData }
          : {
              ...state.requestedVideoData,
              lst_group_item_data:
                state.requestedVideoData.lst_group_item_data.concat(
                  payload.requestedTcinData
                ),
            },
        isFetching: payload.isFetching,
      }
      return newState
    }
    case REQUEST_ADD_TCIN_TO_VIDEO_GROUP: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case SELECT_VIDEO_TCIN_REVIEW_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedTcins: payload.selectedTcins,
      }
      return newState
    }
    case SET_SHOW_REVIEW_OPTIONS: {
      const { payload } = action
      const newState = {
        ...state,
        showRejectButtons: payload.showRejectButtons,
      }
      return newState
    }
    case RESET_REJECTION_MESSAGE: {
      const newState = {
        ...state,
        rejectionMessages: DefaultState.rejectionMessages,
      }
      return newState
    }
    case SET_SHOW_VIDEO_ACTIVE_CHECKBOX: {
      const { payload } = action
      const newState = {
        ...state,
        showVideoActiveCheckbox: payload.showVideoActiveCheckbox,
      }
      return newState
    }
    case SET_VIDEO_ACTIVE: {
      const { payload } = action
      const newState = {
        ...state,
        videoIsActive: payload.videoIsActive,
      }
      return newState
    }
    case SET_REJECTION_MESSAGE: {
      const { payload } = action
      let updateRejectionMessage = (data) => {
        let rejectMessages = { ...state.rejectionMessages }
        if (data.rejectionMessages.type === VIDEO_ASSETS.VIDEO.id) {
          rejectMessages.video_reject = data.rejectionMessages.message
        }
        if (data.rejectionMessages.type === VIDEO_ASSETS.CLOSED_CAPTION.id) {
          rejectMessages.closed_caption_reject = data.rejectionMessages.message
        }
        if (data.rejectionMessages.type === VIDEO_ASSETS.POSTER_FRAME.id) {
          rejectMessages.poster_frame_reject = data.rejectionMessages.message
        }
        if (data.rejectionMessages.type === VIDEO_ASSETS.MEDIA_ALTERNATIVE.id) {
          rejectMessages.transcript_reject = data.rejectionMessages.message
        }
        return rejectMessages
      }
      const newState = {
        ...state,
        rejectionMessages: updateRejectionMessage(payload),
      }
      return newState
    }
    case SHOW_START_OVER_CONFIRMATION: {
      const { payload } = action
      const newState = {
        ...state,
        openStartOverModal: payload.openStartOverModal,
      }
      return newState
    }
    default:
      return state
  }
}
