import { withStyles } from '@material-ui/core/styles'
import styles from '../../theme'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core'

function escapeHtml(input) {
  return { __html: input }
}

function DialogBoxError(props) {
  const {
    classes,
    fileName,
    dropZoneErrorMessage,
    dropZoneErrorTitle,
    confirmWrongFile,
    validFile,
  } = props
  return (
    <Dialog
      open={validFile}
      onClose={() => confirmWrongFile()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid item xs={12}>
              <p
                dangerouslySetInnerHTML={escapeHtml(
                  fileName + ' ' + dropZoneErrorTitle
                )}
                className={classes.wrongFileTitle}
              />
            </Grid>
            <Grid item xs={12}>
              <p> {dropZoneErrorMessage}</p>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => confirmWrongFile()}
          variant="outlined"
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withStyles(styles)(DialogBoxError)
