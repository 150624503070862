import { withStyles } from '@material-ui/core/styles'
import { Grid, Tooltip } from '@material-ui/core'
import styles from '../theme'
import Moment from 'react-moment'
import { isNil, some } from 'lodash'
import VisibilityIcon from '@material-ui/icons/Visibility'
import SyncIcon from '@material-ui/icons/Sync'
import PeopleIcon from '@material-ui/icons/People'
import { RELATIONSHIP_TYPE_CODE } from '../../../Shared/Constants'
import { escapeHtml } from '../../../Shared/SharedUtils'

const ItemDetails = (props) => {
  const {
    item = {},
    tcin = '',
    title = null,
    launchDate = null,
    lifecycle_state = null,
    relationshipTypeCode = null,
    classes = {},
    openZoomedReview = () => {},
    showZoomedReview = false,
    searchRelatedItems = () => {},
    showRelatedIcon = false,
    openSyncItemsModal = () => {},
    showSyncIcon = false,
    auth = {},
    showRelatedIconSearch = false,
    showItemLifeCycle = false,
  } = props

  const handleSyncOnClick = (item) => {
    if (some(item.tcinsForItemsToBeDuplicated)) {
      openSyncItemsModal(item)
    }
  }

  return (
    <Grid container direction="column" className={classes.itemDetails}>
      <Grid item>
        <b>{tcin}</b>
      </Grid>
      <Grid item>
        <span
          dangerouslySetInnerHTML={escapeHtml(
            !isNil(title) ? title : 'No Title Found'
          )}
        />
      </Grid>
      <Grid item>
        Launch Date:{' '}
        {(!isNil(launchDate) && (
          <Moment format={'YYYY-MM-DD'}>{launchDate}</Moment>
        )) ||
          'N/A'}
      </Grid>
      <Grid item>
        {showItemLifeCycle ? (
          <>
            Item State:{' '}
            <span
              dangerouslySetInnerHTML={escapeHtml(
                !isNil(lifecycle_state) ? lifecycle_state : 'N/A'
              )}
            />
          </>
        ) : null}
      </Grid>
      <Grid item>
        {showZoomedReview && (
          <Tooltip title="Take A Closer Look">
            <VisibilityIcon
              className={classes.actionIcons}
              color="primary"
              onClick={openZoomedReview}
            />
          </Tooltip>
        )}

        {showRelatedIconSearch && relationshipTypeCode === 'N/A' ? null : (
          <span>
            {showRelatedIconSearch &&
              !isNil(relationshipTypeCode) &&
              relationshipTypeCode !== RELATIONSHIP_TYPE_CODE.SA && (
                <Tooltip title="View Related Items">
                  <PeopleIcon
                    className={classes.actionIcons}
                    color="primary"
                    onClick={() => searchRelatedItems(tcin)}
                  />
                </Tooltip>
              )}
          </span>
        )}

        {showRelatedIcon &&
          !isNil(relationshipTypeCode) &&
          relationshipTypeCode !== RELATIONSHIP_TYPE_CODE.SA && (
            <Tooltip title="View Related Items">
              <PeopleIcon
                className={classes.actionIcons}
                color="primary"
                onClick={() => searchRelatedItems(tcin)}
              />
            </Tooltip>
          )}

        {showSyncIcon &&
          !isNil(relationshipTypeCode) &&
          relationshipTypeCode === RELATIONSHIP_TYPE_CODE.VC &&
          auth.permission &&
          auth.permission.imgCurationEdit && (
            <Tooltip
              title={
                some(item.tcinsForItemsToBeDuplicated)
                  ? 'Sync Item Images'
                  : 'No items eligible for Sync logic'
              }
            >
              <SyncIcon
                className={
                  some(item.tcinsForItemsToBeDuplicated)
                    ? classes.actionIcons
                    : classes.disabledIcons
                }
                color="primary"
                onClick={() => handleSyncOnClick(item)}
              />
            </Tooltip>
          )}
      </Grid>
    </Grid>
  )
}

// @ts-ignore
export default withStyles(styles)(ItemDetails)
