const styles = (theme) => ({
  marginTop31: {
    marginTop: '31px',
  },
  marginBottom31: {
    marginBottom: '31px',
  },
  formControl: {
    margin: theme.spacing(),
  },
  marginLeft7: {
    margin: '0 0 0 7px',
  },
  tcinList: {
    overflowY: 'auto',
    height: '150px',
  },
  marginTop20: {
    marginTop: '20px',
  },
  saveFilterTitle: {
    backgroundColor: '#3f51b5',
  },
  saveTitleText: {
    color: 'white',
  },
  saveFilterWidth: {
    width: '600px',
  },
  helperTextLabel: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    marginTop: 8,
    lineHeight: '1em',
    minHeight: '1em',
    margin: 0,
  },
  maxExceeded: {
    color: 'red',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    marginTop: 8,
    lineHeight: '1em',
    minHeight: '1em',
    margin: 0,
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  margin5: {
    margin: '5px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  filterTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  overflowAuto: {
    overflow: 'auto',
  },
  displayBlock: {
    display: 'block',
  },
  pointer: {
    cursor: 'pointer',
  },
  margin8: {
    margin: '8px',
  },
  subRow: {
    margin: '31px 8px 8px 8px',
  },
})

export default styles
