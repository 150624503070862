import { withStyles } from '@material-ui/core/styles'
import SyncDisabledRoundedIcon from '@material-ui/icons/SyncDisabled'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
  Checkbox,
  IconButton,
  Icon,
} from '@material-ui/core'
import styles from '../../theme'
import BadgesTableContainerTitle from './BadgesTableContainerTitle'
import BadgesTableContainerHead from './BadgesTableContainerHead'
import BadgesTableContainerToolbar from './BadgesTableContainerToolbar'
import EditIcon from '@material-ui/icons/Edit'
import envConfigs from '../../../../config/apiConfig'

let BadgesTable = (props) => {
  const {
    auth = {},
    classes = {},
    badges = [],
    isSelected = () => {},
    selectedBadges = {},
    handleSelectAllClick = () => {},
    handleClick = () => {},
    downloadAllToExcel = () => {},
    downloadSelectedBadges = () => {},
    openCreateBadgeModal = () => {},
    editableBadge = {},
  } = props

  const widthHeight = '?wid=75&hei=75&qlt=80&fmt=png-alpha' // const size for badge image

  return (
    <Paper className={classes.badgesRoot}>
      <BadgesTableContainerTitle
        totalElements={badges.length}
        downloadAllToExcel={downloadAllToExcel}
        openCreateBadgeModal={openCreateBadgeModal}
        editableBadge={editableBadge}
      />
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          stickyHeader
        >
          <BadgesTableContainerHead
            onSelectAllClick={handleSelectAllClick}
            numSelected={selectedBadges.length}
            totalBadges={badges.length}
          />
          <TableBody>
            {badges.map((badge) => {
              if (badge.id === 'new' && !auth.permission.badgesEdit) {
                // eslint-disable-next-line array-callback-return
                return
              }
              const findIsSelected = isSelected(badge)
              return (
                <TableRow
                  role="checkbox"
                  aria-checked={findIsSelected}
                  tabIndex={-1}
                  key={badge.id}
                  selected={findIsSelected}
                  hover
                  className={!badge.active ? classes.disabledBadgeRow : ''}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={findIsSelected}
                      onClick={(event) => handleClick(event, badge)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => openCreateBadgeModal(badge)}
                      className={classes.padding0}
                    >
                      <Icon>
                        <EditIcon />
                      </Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell>{badge.badge_rank}</TableCell>
                  <TableCell>
                    {badge.badge_name}
                    {!badge.active && badge.badge_name && (
                      <div>
                        <Tooltip title="Badge is disabled. To enable open badge and select Activate.">
                          <SyncDisabledRoundedIcon
                            className={classes.disabledIcon}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>{badge.badge_id}</TableCell>
                  <TableCell>{badge.mta_value_name}</TableCell>
                  <TableCell padding="none">
                    <img
                      src={badge.badge_url + widthHeight}
                      alt={badge.badge_name}
                      onError={(e) => {
                        e.target.src =
                          envConfigs.api.sceneSevenBaseUrl +
                          '/badge-transfer' +
                          widthHeight
                      }}
                    />
                  </TableCell>
                  <TableCell>{badge.mta_name}</TableCell>
                  <TableCell>{badge.mta_id}</TableCell>
                  <TableCell>{badge.badge_description}</TableCell>
                  <TableCell>
                    {badge.criteria
                      ? badge.criteria['item_data.product_brand.brand']
                      : ''}
                  </TableCell>
                  <TableCell>
                    <div role="presentation">{badge.item_count}</div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      <BadgesTableContainerToolbar
        numSelected={selectedBadges.length}
        downloadSelectedBadges={downloadSelectedBadges}
      />
    </Paper>
  )
}

export default withStyles(styles)(BadgesTable)
