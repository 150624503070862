import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UploadVideo from './UploadVideo'
import {
  dropZoneActive,
  onDropLoading,
  editVttDialogBox,
  openVideoHelpContent,
} from '../../../../../store/videos/upload/actionCreator'
import { VIDEO_ASSETS } from '../../../../Shared/Constants'

function UploadVideoContainer(props) {
  const onDragEnter = (fileType) => {
    var data = {
      video: false,
      closedCaption: false,
      posterFrame: false,
      transcript: false,
    }
    if (fileType === VIDEO_ASSETS.VIDEO.type) {
      data = {
        video: true,
        closedCaption: false,
        posterFrame: false,
        transcript: false,
      }
    }
    if (fileType === VIDEO_ASSETS.CLOSED_CAPTION.type) {
      data = {
        video: false,
        closedCaption: true,
        posterFrame: false,
        transcript: false,
      }
    }
    if (fileType === VIDEO_ASSETS.MEDIA_ALTERNATIVE.type) {
      data = {
        video: false,
        closedCaption: false,
        posterFrame: false,
        transcript: true,
      }
    }
    if (fileType === VIDEO_ASSETS.POSTER_FRAME.type) {
      data = {
        video: false,
        closedCaption: false,
        posterFrame: true,
        transcript: false,
      }
    }
    props.onDropLoading(true)
    props.dropZoneActive(data)
  }

  const onDragLeave = (files) => {
    var data = {
      video: false,
      closedCaption: false,
      posterFrame: false,
      transcript: false,
    }
    props.onDropLoading(false)
    props.dropZoneActive(data)
  }

  const editVttDialogBoxEmpty = () => {
    props.editVttDialogBox(true)
  }

  const openHelpContent = (fileType) => {
    props.openVideoHelpContent(fileType)
  }

  return (
    <UploadVideo
      onDrop={props.onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      dropZoneEnter={props.dropZoneEnter}
      isFetchingOnDrop={props.isFetchingOnDrop}
      fileType={props.fileType}
      requiredType={props.requiredType}
      uploadInProgress={props.uploadInProgress}
      editVttDialogBoxEmpty={editVttDialogBoxEmpty}
      vttHelpUrl={props.vttHelpUrl}
      openHelpContent={openHelpContent}
      disabled={props.disabled}
    />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dropZoneActive,
      onDropLoading,
      editVttDialogBox,
      openVideoHelpContent,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  const { auth, videoUpload, upload } = state
  const { files, vttHelpUrl } = videoUpload
  const { uploadInProgress } = upload
  return {
    auth,
    files,
    uploadInProgress,
    vttHelpUrl,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadVideoContainer)
