import {
  REQUEST_ALTTEXT_UPLOAD_DATA,
  RECEIVE_ALTTEXT_UPLOAD_DATA,
  BULK_UPLOAD_ALTTEXT_RESET_FETCH,
  BULKUPLOAD_ALTTEXT_DROP_ZONE_ACTIVE,
  WRONG_FILE_ALTTEXT_ADDED,
  CLEAR_ALTTEXT_UPLOAD_DATA,
  STORE__ALTTEXT_BULK_UPLOAD_STRAPI_DATA,
  SHOW_DEFAULT_ALTTEXT_ALERT_BULK_UPLOAD,
  BUILD_FILTER_EXPAND_ALTTEXT_SEARCH,
  NEW_FILTER_EVENT_ALTTEXT_SEARCH,
  REMOVE_FILTER_EVENT_ALTTEXT_SEARCH,
  CHANGE_FILTER_EVENT_ALTIMAGE_SEARCH,
  SELECT_FILTER_VALUE_EVENT_ALTIMAGE_SEARCH,
  BUILD_FILTER_EVENT_ALTIMAGE_SEARCH,
  REQUEST_ALTIMAGE_SEARCH_DATA,
  ERROR_SEARCHING_ALTIMAGE_SEARCH,
  RECEIVE_ALTIMAGE_SEARCH_DATA,
  CLEAR_ALTIMAGE_SEARCH_DATA_EVENT,
  SELECT_ALT_TEXT_SEARCH_EVENT,
  CHANGE_CURRENT_PAGE_ALTIMAGE_SEARCH,
  REMOVE_FILTER_EVENT_ALTIMAGE_SEARCH,
  STORE_ALTIMAGE_SEARCH_STRAPI_DATA,
  UPDATE_ALT_TEXT_EDIT_STATE,
  UPDATE_ALT_TEXT,
  UPDATE_SELECTED_ALT_TEXT,
  ALTTEXT_DELETE_CONFIRMATION,
  REMOVE_ALTTEXT_ROW,
  SELECT_ALTTEXT_DATA_EVENT,
  ALTTEXT_PROFANITY_API_LOADER,
  ALTTEXT_SEARCH_ITEM,
  UPDATE_ALTTEXT_ITEM_SEARCH,
  WRONG_FILE_ADDED,
  NON_APPROVED_ALT_TEXT,
  RECEIVE_NON_APPROVED_TCINS,
} from './actionTypes'

export const initState = {
  selectedData: [],
  uploadData: [],
  defaultUploadData: [],
  isFetching: false,
  dropZoneEnter: false,
  validFile: false,
  fileName: '',
  dropZoneErrorMessage: '',
  dropZoneErrorTitle: '',
  alert: null,
  information: null,
  faq: null,
  error: null,
  showDefaultAlert: false,
  bulletCountMessage: null,

  selectedFilters: [],
  dataContainerShown: false,
  newFilterContainerShown: false,
  buildFilterContainerShown: false,
  buildFilterExpansionPanel: false,
  filterValues: {
    tcins: [],
  },
  defaultFilterValues: {
    tcins: [],
  },
  selectedFilter: '',
  selectedItems: [],
  rhData: {
    assets: [],
  },
  errorSearching: false,
  originalRhData: [],
  totalPages: 0,
  totalElements: 0,
  currentPage: 0,
  defaultPageSize: 50,
  // DO NOT CHANGE THE PAGENAME!
  pageName: 'AltTextPage',
  sortDirection: 'asc',
  sortField: 'TCIN',
  altTextProfanityLoading: false,
  searchedItems: [],
  inputTcins: [],
  nonApporvedAltTextImages: false,
  invalid_tcins: [],
}

export default function altTextReducer(state = initState, action = {}) {
  switch (action.type) {
    case REQUEST_ALTTEXT_UPLOAD_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case NON_APPROVED_ALT_TEXT: {
      const { payload } = action
      const newState = {
        ...state,
        nonApporvedAltTextImages: payload.DataExist,
      }
      return newState
    }
    case RECEIVE_NON_APPROVED_TCINS: {
      const { payload } = action
      const newState = {
        ...state,
        invalid_tcins: payload.invalid_tcins,
      }
      return newState
    }
    case WRONG_FILE_ADDED: {
      const { payload } = action
      const newState = {
        ...state,
        fileName: payload.fileName,
        dropZoneErrorTitle: payload.dropZoneErrorTitle,
        dropZoneErrorMessage: payload.dropZoneErrorMessage,
        validFile: payload.validFile,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case ALTTEXT_SEARCH_ITEM: {
      const { payload } = action
      const newState = {
        ...state,
        searchedItems: payload.searchedItems,
      }
      return newState
    }
    case UPDATE_ALTTEXT_ITEM_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        inputTcins: payload.inputTcins,
        searchedItems: [],
        isFetching: payload.isFetching,
      }
      return newState
    }
    case SELECT_ALTTEXT_DATA_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedData: payload.selectedData,
      }
      return newState
    }
    case ALTTEXT_DELETE_CONFIRMATION: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.unique_id === payload.unique_id) {
            var item = {
              tcin: temp.tcin,
              alt_text: temp.alt_text,
              image_url: temp.image_url,
              valid: temp.valid,
              profane_category: temp.profane_category,
              unique_id: temp.unique_id,
              alt_text_active: temp.alt_text_active,
              isAltTextEditable: temp.isAltTextEditable,
              confirmDelete: payload.confirmDelete,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case CLEAR_ALTTEXT_UPLOAD_DATA: {
      const newState = {
        ...state,
        selectedData: [],
        uploadData: [],
        defaultUploadData: [],
        fileName: '',
        dropZoneErrorMessage: '',
        dropZoneErrorTitle: '',
        inputTcins: [],
        invalid_tcins: [],
      }
      return newState
    }
    case RECEIVE_ALTTEXT_UPLOAD_DATA: {
      const { payload } = action
      payload.uploadData.data.map((data) => {
        if (data.alt_text !== null && data.alt_text !== undefined) {
          data.alt_text = data.alt_text.replace(/(\n)/g, '<br />')
        }
        data.itemData = {}
        data.isAltTextEditable = false
        data.confirmDelete = false
      })
      const newState = {
        ...state,
        defaultUploadData: payload.uploadData.data.map((item) => {
          var data = {}
          data.tcin = item.tcin
          data.alt_text = item.alt_text
          data.image_url = item.image_url
          data.valid = item.valid
          data.profane_category = item.profane_category
          data.unique_id = item.unique_id
          data.alt_text_active = item.alt_text_active
          data.isAltTextEditable = item.isAltTextEditable
          return data
        }),
        uploadData: payload.uploadData.data.map((item) => {
          var data = {}
          data.tcin = item.tcin
          data.alt_text = item.alt_text
          data.image_url = item.image_url
          data.valid = item.valid
          data.profane_category = item.profane_category
          data.unique_id = item.unique_id
          data.alt_text_active = item.alt_text_active
          data.isAltTextEditable = item.isAltTextEditable
          data.confirmDelete = false
          return data
        }),
        selectedData: payload.uploadData.data.map((item) => {
          var data = {}
          data.tcin = item.tcin
          data.alt_text = item.alt_text
          data.image_url = item.image_url
          data.valid = item.valid
          data.profane_category = item.profane_category
          data.unique_id = item.unique_id
          data.alt_text_active = item.alt_text_active
          data.isAltTextEditable = item.isAltTextEditable
          return data
        }),
        isFetching: payload.isFetching,
      }
      return newState
    }
    case STORE__ALTTEXT_BULK_UPLOAD_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
        error: payload.error,
        bulletCountMessage: payload.error,
      }
      return newState
    }
    case SHOW_DEFAULT_ALTTEXT_ALERT_BULK_UPLOAD: {
      const { payload } = action
      const newState = {
        ...state,
        showDefaultAlert: payload,
      }
      return newState
    }
    case WRONG_FILE_ALTTEXT_ADDED: {
      const { payload } = action
      const newState = {
        ...state,
        fileName: payload.fileName,
        dropZoneErrorTitle: payload.dropZoneErrorTitle,
        dropZoneErrorMessage: payload.dropZoneErrorMessage,
        validFile: payload.validFile,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case BULKUPLOAD_ALTTEXT_DROP_ZONE_ACTIVE: {
      const { payload } = action
      const newState = {
        ...state,
        dropZoneEnter: payload.dropZoneEnter,
      }
      return newState
    }
    case BULK_UPLOAD_ALTTEXT_RESET_FETCH: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: payload.isFetching,
      }
      return newState
    }
    case BUILD_FILTER_EXPAND_ALTTEXT_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterExpansionPanel: payload.buildFilterExpansionPanel,
      }
      return newState
    }
    case NEW_FILTER_EVENT_ALTTEXT_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        buildFilterContainerShown: payload,
      }
      return newState
    }
    case REMOVE_FILTER_EVENT_ALTTEXT_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        dataContainerShown: payload.dataContainerShown,
        selectedFilters: payload.selectedFilters,
      }
      return newState
    }
    case CHANGE_FILTER_EVENT_ALTIMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        selectedFilter: payload.selectedFilter,
        filterValues: initState.filterValues,
      }
      return newState
    }
    case SELECT_FILTER_VALUE_EVENT_ALTIMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        filterValues: payload.filterValues,
      }
      return newState
    }
    case BUILD_FILTER_EVENT_ALTIMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        dataContainerShown: payload.dataContainerShown,
        selectedFilters: payload.selectedFilters,
        // Resetting the value after adding
        filterValues: initState.filterValues,
      }
      return newState
    }
    case REQUEST_ALTIMAGE_SEARCH_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        rhData: payload.rhData,
        isFetching: payload.isFetching,
        selectedItems: payload.selectedItems,
      }
      return newState
    }
    case CHANGE_CURRENT_PAGE_ALTIMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        currentPage: payload.currentPage,
      }
      return newState
    }
    case ERROR_SEARCHING_ALTIMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        errorSearching: payload,
      }
      return newState
    }
    case RECEIVE_ALTIMAGE_SEARCH_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        rhData: payload.rhData,
        originalRhData: payload.rhData,
        isFetching: payload.isFetching,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
      }
      return newState
    }
    case CLEAR_ALTIMAGE_SEARCH_DATA_EVENT: {
      const newState = {
        ...state,
        rhData: initState.rhData,
      }
      return newState
    }
    case SELECT_ALT_TEXT_SEARCH_EVENT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedItems: payload.selectedItems,
      }
      return newState
    }
    case REMOVE_FILTER_EVENT_ALTIMAGE_SEARCH: {
      const { payload } = action
      const newState = {
        ...state,
        newFilterContainerShown: payload.newFilterContainerShown,
        dataContainerShown: payload.dataContainerShown,
        selectedFilters: payload.selectedFilters,
      }
      return newState
    }
    case STORE_ALTIMAGE_SEARCH_STRAPI_DATA: {
      const { payload } = action
      const newState = {
        ...state,
        alert: payload.alert,
        information: payload.information,
        faq: payload.faq,
        error: payload.error,
      }
      return newState
    }
    case UPDATE_ALT_TEXT_EDIT_STATE: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.unique_id === payload.unique_id) {
            var item = {
              tcin: temp.tcin,
              alt_text: temp.alt_text,
              image_url: temp.image_url,
              valid: temp.valid,
              profane_category: temp.profane_category,
              unique_id: temp.unique_id,
              alt_text_active: temp.alt_text_active,
              isAltTextEditable: payload.isAltTextEditable,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case UPDATE_ALT_TEXT: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: state.uploadData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.unique_id === payload.unique_id) {
            var item = {
              tcin: temp.tcin,
              image_url: temp.image_url,
              alt_text: payload.alt_text,
              valid: payload.valid,
              profane_category: payload.profane_category,
              unique_id: temp.unique_id,
              isAltTextEditable: payload.isAltTextEditable,
            }
            return item
          }
          return temp
        }),
        selectedData: state.selectedData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.unique_id === payload.unique_id) {
            var item = {
              tcin: temp.tcin,
              image_url: temp.image_url,
              alt_text: payload.alt_text,
              valid: payload.valid,
              profane_category: payload.profane_category,
              unique_id: temp.unique_id,
              isAltTextEditable: payload.isAltTextEditable,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case UPDATE_SELECTED_ALT_TEXT: {
      const { payload } = action
      const newState = {
        ...state,
        selectedData: state.selectedData.map((data) => {
          var temp = Object.assign({}, data)
          if (temp.unique_id === payload.unique_id) {
            var item = {
              tcin: temp.tcin,
              alt_text: payload.alt_text,
              valid: payload.valid,
              profane_category: payload.profane_category,
            }
            return item
          }
          return temp
        }),
      }
      return newState
    }
    case REMOVE_ALTTEXT_ROW: {
      const { payload } = action
      const newState = {
        ...state,
        uploadData: payload.uploadData,
        selectedData: payload.selectedData,
        defaultUploadData: payload.defaultUploadData,
      }
      return newState
    }
    case ALTTEXT_PROFANITY_API_LOADER: {
      const { payload } = action
      const newState = {
        ...state,
        altTextProfanityLoading: payload,
      }
      return newState
    }
    default:
      return state
  }
}
