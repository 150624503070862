import {
  RULES_RELATION_CHANGE,
  RULES_CONDITION_CHANGE,
  RULES_ADDITION_CONDITION_SELECTION,
  RULES_ADDITION_CONDITION_RESET,
  RULES_ADDITION_CONDITION_INPUT_CHANGE,
  RULES_ADDITION_CONDITION_EXTRA_CHANGE,
  RULES_CONDITION_REMOVE,
  RULES_CONDITION_ATTRIBUTE_REMOVE,
  RULES_CONDITION_EDIT,
  RULES_CONDITION_MANUALLY_ADD,
  RULES_ADDITION_CONDITION_OPERATOR_CHANGE,
  RULES_NAME_CHANGE,
  RULES_PRIORITY_CHANGE,
  RULE_STATE_RESET,
  RULES_BUILDER_WARNING,
  SET_ACTIVE_STEP,
  RULES_COMPLETE_STEP,
  RULES_SET_ITEMS,
  RULE_SET,
  RULE_GET_ERROR,
  RULES_ATTRIBUTES_ADD,
  RULES_GET_ITEMS_FAILED,
  RULE_BUILDER_RESET_FETCH,
  VALIDATE_TCIN_BY_RULE_ID_LOADING,
  VALIDATE_TCIN_BY_RULE_ID_RESPONSE,
  VALIDATE_TCIN_BY_RULE_ID_CLEAR,
  ADD_TCIN_CHIPS,
  DISABLE_LOADING_CONDITION,
  ADD_TCIN_CHIPS_TO_API,
} from './actionTypes'
import {
  SIZE_CHART_RULE_SELECTION_LOADER,
  EDIT_MODE_SIZE_CHART_TCIN,
} from '../ruleSelection/actionType'
import axios from 'axios'
import apiConfig from '../../../config/apiConfig'
import {
  PRODUCT_TITLE,
  VENDOR_NAME,
  TAXONOMY_GROUP,
  TAXONOMY_SUBGROUP,
  MTA_TYPE,
  MTA_VALUES,
  MANUFACTURER_BRAND,
  TAXONOMY,
  MTA,
} from './SearchCriteriaStandardNames'
import {
  FREE_FORM,
  DOUBLE_COMBO_BOX,
  AUTOCOMPLETE,
} from '../../../components/RuleBuilder/Components/InputTypes'
import { forEach, isNil } from 'lodash'
import { toast } from 'react-toastify'
import { TOAST_BODY, urlProtocol } from '../../../components/Shared/Constants'

export const eSearchTypes = {
  [PRODUCT_TITLE]: 'item_data.product_description.title',
  [VENDOR_NAME]: 'item_data.product_vendors.id',
  [TAXONOMY_GROUP]: 'item_data.product_classification.product_type_name',
  [TAXONOMY_SUBGROUP]: 'item_data.product_classification.product_subtype_name',
  [MTA_TYPE]: 'item_data.merchandise_type_attributes.id',
  [MTA_VALUES]: 'item_data.merchandise_type_attributes.values.id',
  [MANUFACTURER_BRAND]: 'item_data.product_brand.brand',
}

export function resetFetch(data) {
  return {
    type: RULE_BUILDER_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

function getProductTitle() {
  return (dispatch) => {
    return dispatch({
      type: RULES_ATTRIBUTES_ADD,
      attributeInformation: {
        attribute: PRODUCT_TITLE,
        data: [],
        display: 'Product Title',
        inputType: FREE_FORM,
      },
    })
  }
}

function getTaxonomies() {
  return (dispatch) => {
    return axios
      .get(
        `${apiConfig.api.itemApi}digital_items/v1/taxonomy/productTypes?key=${apiConfig.api.gatewayKey}`
      )
      .then((response) => {
        let taxonomies = response.data !== '' ? response.data : []
        taxonomies.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
        let groups = []
        let subGroups = []
        forEach(taxonomies, (taxonomy) => {
          if (taxonomy.status === 'ACTIVE') {
            groups.push({
              display: taxonomy.name,
              value: taxonomy.name,
            })
            let subgroup = []
            forEach(taxonomy.breadcrumbs, (subGroup) => {
              if (subGroup.status === 'ACTIVE') {
                subgroup.push({
                  display: subGroup.name,
                  value: subGroup.name,
                })
              }
            })
            subgroup.sort((a, b) => {
              return a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1
            })
            subGroups.push(subgroup)
          }
        })
        dispatch({
          type: RULES_ATTRIBUTES_ADD,
          attributeInformation: {
            attribute: TAXONOMY,
            data: [groups, subGroups],
            labels: ['Taxonomy Group', 'Taxonomy Subgroup'],
            display: 'Taxonomy',
            inputType: DOUBLE_COMBO_BOX,
          },
        })
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function getVendors() {
  const parseVendors = (vendors) => {
    let out = []
    forEach(vendors, (vendor) => {
      out.push({
        key: vendor.gms_vendor_id.toString(),
        value: vendor.vendor_name,
      })
    })
    return out.sort((a, b) => {
      return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
    })
  }
  return (dispatch) => {
    return axios
      .get(
        apiConfig.api.itemApi + 'vendors/v1/gms?key=' + apiConfig.api.gatewayKey
      )
      .then((response) => {
        dispatch({
          type: RULES_ATTRIBUTES_ADD,
          attributeInformation: {
            attribute: VENDOR_NAME,
            data: parseVendors(response.data),
            display: 'Vendor Name',
            inputType: AUTOCOMPLETE,
          },
        })
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export const merchTypeAttributes = [142750, 133350, 110298, 110447, 110407]

function getMTAs() {
  // this is the 'hard coded' list of merch type attributes used on the rule builder
  // to add more merch type attributes to this list, just add the ID of the merch type
  // to the list
  /* CURRENTLY SUPPORTED MERCH TYPE ATTRIBUTES
   * 142750: Age specific gender
   * 133350: Apperal Item Type
   * 110298: Garment Fit
   * 110447: Occasion Worn For
   * 110407: Size Grouping
   */
  return (dispatch) => {
    return axios
      .post(
        apiConfig.api.itemApi +
          'digital_items/v1/hierarchy/merchAttributes?key=' +
          apiConfig.api.gatewayKey,
        merchTypeAttributes
      )
      .then((response) => {
        let mtas = response.data
        let types = []
        let values = []
        forEach(mtas, (mta) => {
          types.push({
            display: mta.name,
            value: mta.id,
          })
          let attributes = []
          forEach(mta.item_attribute_values, (attribute) => {
            attributes.push({
              display: attribute.display_name,
              value: attribute.value_id,
            })
          })
          attributes.sort((a, b) => {
            return a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1
          })
          values.push(attributes)
        })
        dispatch({
          type: RULES_ATTRIBUTES_ADD,
          attributeInformation: {
            attribute: MTA,
            data: [types, values],
            labels: ['MTA Types', 'MTA Values'],
            display: MTA,
            inputType: DOUBLE_COMBO_BOX,
          },
        })
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

// brandid isn't used in the search since brand names SHOULD be unique, also
// it could cause a null pointer down the road for the backend
function getManufacturerBrands() {
  return (dispatch) => {
    dispatch({
      type: RULES_ATTRIBUTES_ADD,
      attributeInformation: {
        attribute: MANUFACTURER_BRAND,
        data: [],
        display: 'Manufacturer Brand',
        inputType: AUTOCOMPLETE,
      },
    })
  }
}

export function getRuleAttributes() {
  return (dispatch) => {
    // the chained promises make sure that the attributes are
    // called in a set order, rather than at the whim of the
    // scheduler
    dispatch(getProductTitle())
    return dispatch(getTaxonomies()).then(() =>
      dispatch(getVendors()).then(() =>
        dispatch(getMTAs()).then(() => dispatch(getManufacturerBrands()))
      )
    )
  }
}

const resetItems = {
  totalResults: 0,
  items: [],
}

export function getRuleItems() {
  return (dispatch, getState) => {
    const { ruleCriteria } = getState().sizeChartRuleBuilder
    const { selectedTcinRule } = getState().sizeChartRuleSelection
    const payload = { criteria: ruleCriteria }
    return axios
      .post(
        apiConfig.api.sizeChartV2Api +
          'sizechart/search/v1/get_es_documents_from_rule_criteria?size=100&key=' +
          apiConfig.api.gatewayKey,
        payload
      )
      .then((response) => {
        if (selectedTcinRule) {
          dispatch({
            type: RULES_SET_ITEMS,
            foundItems: resetItems,
          })
        } else {
          dispatch({
            type: RULES_SET_ITEMS,
            foundItems: parseSearchResponse(response),
          })
        }
      })
      .catch((error, data) => {
        dispatch({
          type: RULES_GET_ITEMS_FAILED, // turn off loading spinner
        })
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function parseSearchResponse(response) {
  const results = {
    totalResults: response.data.count,
    items:
      !response.data.documents || response.data.count === 0
        ? []
        : response.data.documents.map((item) => {
            return {
              tcin: item.tcin,
              title:
                item.item_data &&
                item.item_data.description &&
                item.item_data.description.title
                  ? item.item_data.description.title
                  : '',
              image:
                item.item_data && item.item_data.digital_assets
                  ? urlProtocol +
                    item.item_data.digital_assets.images.base_url +
                    item.item_data.digital_assets.images.primary_image
                  : null,
            }
          }),
  }
  return results
}

export function validateTcinByRuleId(ruleId, tcin, editModeSizeChartTcinList) {
  const URlGenrater = editModeSizeChartTcinList
    ? `${apiConfig.api.sizeChartV2Api}tcinsizechart/v1/size_chart_info/${ruleId}/${tcin}`
    : `${apiConfig.api.sizeChartV2Api}sizechart/search/v1/validate_tcin_by_rule_id?rule_id=${ruleId}&tcin=${tcin}`
  return (dispatch) => {
    dispatch({
      type: VALIDATE_TCIN_BY_RULE_ID_LOADING,
      payload: {
        isLoading: true,
      },
    })
    return axios
      .get(`${URlGenrater}`)
      .then((response) => {
        if (!isNil(response.data)) {
          dispatch({
            type: VALIDATE_TCIN_BY_RULE_ID_LOADING,
            payload: {
              isLoading: false,
            },
          })
          dispatch({
            type: VALIDATE_TCIN_BY_RULE_ID_RESPONSE,
            payload: {
              data: response.data,
            },
          })
        } else {
          toast.error('Failed to retrieve TCIN and Rule Data', TOAST_BODY)
        }
      })
      .catch((error) => {
        dispatch({
          type: VALIDATE_TCIN_BY_RULE_ID_LOADING,
          payload: {
            isLoading: false,
          },
        })
        toast.error(error && error.response.data.error_message, TOAST_BODY)
      })
  }
}

export function validateTcinByRuleIdClear() {
  return (dispatch) => {
    dispatch({
      type: VALIDATE_TCIN_BY_RULE_ID_CLEAR,
    })
  }
}

export function sizeChartByTcinTableResults(sizeRuleId) {
  return (dispatch) => {
    return axios
      .get(
        apiConfig.api.sizeChartV2Api +
          `tcinsizechart/v1/size_chart_by_tcins/${sizeRuleId}`
      )
      .then((response) => {
        dispatch({
          type: RULES_SET_ITEMS,
          foundItems: parseSearchResponse(response),
        })
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function getRule(payload) {
  return (dispatch) => {
    return axios
      .post(
        apiConfig.api.sizeChartApi +
          'rules/get?key=' +
          apiConfig.api.gatewayKey,
        payload
      )
      .then((response) => {
        const { data } = response
        dispatch({
          type: SIZE_CHART_RULE_SELECTION_LOADER,
          payloadProp: false,
        })
        if (data.type === 'size-rule-by-tcins') {
          dispatch({
            type: EDIT_MODE_SIZE_CHART_TCIN,
            payloadProp: true,
          })
          dispatch(sizeChartByTcinTableResults(data && data.id))
        }
        dispatch({
          type: RULE_SET,
          rule: response.data,
        })
      })
      .catch((error, data) => {
        // Rule not found
        dispatch({
          type: RULE_GET_ERROR,
          message: 'RULE NOT FOUND',
        })
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function saveRule(payload) {
  return (dispatch) => {
    return axios
      .post(
        apiConfig.api.sizeChartApi +
          'rules/save?key=' +
          apiConfig.api.gatewayKey,
        payload
      )
      .then((response) => {
        dispatch(
          resetFetch(false),
          toast.success('Rule Saved Successfully', TOAST_BODY)
        )
        dispatch({
          type: RULE_STATE_RESET,
        })
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function saveRulePutCall(payload) {
  return (dispatch) => {
    return axios
      .put(
        apiConfig.api.sizeChartApi +
          'rules/save?key=' +
          apiConfig.api.gatewayKey,
        payload
      )
      .then((response) => {
        dispatch(
          resetFetch(false),
          toast.success('Rule Saved Successfully', TOAST_BODY)
        )
        dispatch({
          type: RULE_STATE_RESET,
        })
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function setRelationType(codes) {
  return (dispatch) => {
    dispatch({
      type: RULES_RELATION_CHANGE,
      index: codes,
    })
  }
}

// allows the developer to mock up the condition to avoid
// needing the indexes of the data points being added to the condition
// USE WITH CAUTION AND ONLY IF YOU KNOW WHAT YOU ARE DOING!!!!!!!!
export function manuallyAddCondition(payload) {
  return (dispatch) => {
    return dispatch({
      type: RULES_CONDITION_MANUALLY_ADD,
      condition: payload,
    })
  }
}

export function editCondition(conditionIndex) {
  return (dispatch) => {
    return dispatch({
      type: RULES_CONDITION_EDIT,
      condition: conditionIndex,
    })
  }
}

export function removeConditionAttribute(condition) {
  return (dispatch) => {
    return dispatch({
      type: RULES_CONDITION_ATTRIBUTE_REMOVE,
      condition,
    })
  }
}

export function removeCondition(condition) {
  return (dispatch) => {
    return dispatch({
      type: RULES_CONDITION_REMOVE,
      condition,
    })
  }
}

export function setAdditionCondition(index) {
  return (dispatch) => {
    return dispatch({
      type: RULES_ADDITION_CONDITION_SELECTION,
      index,
    })
  }
}

export function updateAdditionalConditionInput(value) {
  return (dispatch) => {
    return dispatch({
      type: RULES_ADDITION_CONDITION_INPUT_CHANGE,
      value,
    })
  }
}

export function updateAdditionalConditionExtra(value) {
  return (dispatch) => {
    return dispatch({
      type: RULES_ADDITION_CONDITION_EXTRA_CHANGE,
      value,
    })
  }
}

export function resetAdditionalCondition() {
  return (dispatch) => {
    return dispatch({
      type: RULES_ADDITION_CONDITION_RESET,
    })
  }
}

export function saveAdditionalCondition() {
  return (dispatch) => {
    return dispatch({
      type: RULES_CONDITION_CHANGE,
    })
  }
}

export function changeAdditionalConditionOperator(selectedOperator) {
  return (dispatch) => {
    return dispatch({
      type: RULES_ADDITION_CONDITION_OPERATOR_CHANGE,
      selectedOperator,
    })
  }
}

export function setRuleName(name) {
  return (dispatch) => {
    return dispatch({
      type: RULES_NAME_CHANGE,
      name,
    })
  }
}

export function setRulePriority(priority) {
  return (dispatch) => {
    return dispatch({
      type: RULES_PRIORITY_CHANGE,
      priority,
    })
  }
}

export function resetState() {
  return (dispatch) => {
    return dispatch({
      type: RULE_STATE_RESET,
    })
  }
}

export function sendWarning(warning) {
  return (dispatch) => {
    dispatch({
      type: RULES_BUILDER_WARNING,
      warning,
    })
  }
}

export function setActiveStep(index) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_STEP,
      index,
    })
  }
}

export function completeStep(index) {
  return (dispatch) => {
    dispatch({
      type: RULES_COMPLETE_STEP,
      index,
    })
  }
}

export function addTcinChip(tcinArray) {
  return (dispatch) => {
    dispatch({
      type: ADD_TCIN_CHIPS,
      tcinArray,
    })
  }
}

export function addTcinChipToApi(tcinArray) {
  return (dispatch) => {
    if (tcinArray.length < 10001) {
      dispatch({
        type: ADD_TCIN_CHIPS_TO_API,
        tcinArray,
      })
    } else {
      toast.error(`Max limit 10K Tcins`, TOAST_BODY)
    }
  }
}

export function disableLoadingCondition(value) {
  return (dispatch) => {
    dispatch({
      type: DISABLE_LOADING_CONDITION,
      value,
    })
  }
}
