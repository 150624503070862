import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  altTextDeleteConfirmation,
  deleteAltTextRow,
} from '../../../../store/altText/actionCreator'

import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core'
import styles from '../../theme'
import { withStyles } from '@material-ui/core/styles'
import { remove, isEqual } from 'lodash'

function escapeHtml(input) {
  return { __html: input }
}

function DeleteAltText(props) {
  const { data, classes, altTextPopUpConfirmation } = props

  const handleDeleteAltText = (event, data) => {
    let updateData = []
    let selectedData = []
    let defaultUploadData = []
    updateData = props.uploadData.map((item) => item)
    remove(updateData, function (item) {
      return isEqual(item, data)
    })
    selectedData = props.selectedData.map((item) => item)
    remove(selectedData, function (item) {
      return isEqual(item.unique_id, data.unique_id)
    })
    defaultUploadData = props.defaultUploadData.map((item) => item)
    remove(defaultUploadData, function (item) {
      return isEqual(item.unique_id, data.unique_id)
    })
    props.deleteAltTextRow({
      uploadData: updateData,
      selectedData: selectedData,
      defaultUploadData: defaultUploadData,
    })
  }

  return (
    <Dialog
      open={data.confirmDelete}
      onClose={() => altTextPopUpConfirmation(data.unique_id, false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="onClose"
    >
      <DialogTitle id="alert-dialog-title">
        {'Skip Upload of Alt Text'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container direction="row">
            <Grid container item xs={6}>
              <Grid item xs={4}>
                <img
                  src={data.image_url}
                  alt={data.image_url}
                  className={classes.altTextImage}
                />
              </Grid>
              <Grid container item xs={8}>
                <Grid item xs={12}>
                  {data.tcin}
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.altTextHeading}>Alt Text</div>
                  <div dangerouslySetInnerHTML={escapeHtml(data.alt_text)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <div>
                {' '}
                Do you want to remove the Alt text for Image from the current
                bulk upload?
              </div>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => altTextPopUpConfirmation(data.unique_id, false)}
          variant="outlined"
          color="primary"
          data-testid="CancelDeleteAltTextButton"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => handleDeleteAltText(event, data)}
          variant="contained"
          color="primary"
          data-testid="DeleteAltTextButton"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ altTextDeleteConfirmation, deleteAltTextRow }, dispatch)

const mapStateToProps = (state) => {
  const { altTextReducer } = state
  const { uploadData, selectedData, defaultUploadData } = altTextReducer
  return {
    uploadData,
    selectedData,
    defaultUploadData,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DeleteAltText))
