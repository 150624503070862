const styles = (theme) => ({
  progressBar: {
    marginTop: '30px',
    marginLeft: '50%',
  },
  totalHistoryData: {
    fontSize: '1.2em',
    color: '#CC0000',
    padding: '30px 20px',
  },
  noResult: {
    textAlign: 'center',
    margin: '20px',
  },
  openIcon: {
    color: '#3f51b5',
  },
  blockedIcon: {
    color: '#AAAAAA',
  },
  imageThumbnail: {
    height: '75px',
  },
  marginTop20: {
    marginTop: '20px',
  },
  pointer: {
    cursor: 'pointer',
  },
  expansionText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#01a3e7',
  },
  marginRight10: {
    marginRight: '10px',
  },
})

export default styles
