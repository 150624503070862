import { TextField, Tooltip } from '@material-ui/core'
import styles from '../../theme'
import { withStyles } from '@material-ui/core/styles'

function escapeHtml(input) {
  return { __html: input }
}

function EditFeatureBullets(props) {
  const {
    tcin,
    featureBullets,
    featureBulletsEdit,
    isFeatureBulletsEditable,
    updateFeatureBullets,
    changeToEditState,
    classes,
  } = props
  return (
    <div>
      {isFeatureBulletsEditable ? (
        <TextField
          multiline
          rows="10"
          fullWidth
          margin="normal"
          defaultValue={featureBulletsEdit}
          autoFocus
          onBlur={(event) => updateFeatureBullets(event, tcin)}
        />
      ) : (
        <Tooltip title="Click to Edit">
          <div
            className={classes.clickToEditContainer}
            onClick={() => changeToEditState(tcin, featureBullets)}
            role="presentation"
          >
            <ul className={classes.marginAuto}>
              {featureBullets.map((bullet) => {
                return (
                  <li
                    key={bullet}
                    dangerouslySetInnerHTML={escapeHtml(bullet)}
                  />
                )
              })}
            </ul>
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default withStyles(styles)(EditFeatureBullets)
