import { withStyles } from '@material-ui/core/styles'
import styles from '../../../theme'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core'

function DialogBoxConfirmDelete(props) {
  const {
    classes,
    confirmDeleteSelection,
    deleteData,
    cancelCopyBulletDelete,
  } = props
  return (
    <Dialog
      open={deleteData.confirmationDelete}
      onClose={() => cancelCopyBulletDelete()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'xs'}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid
              container
              direction="column"
              item
              xs={12}
              className={classes.marginBottom10}
            >
              <span className={classes.deleteCopyFeatureConfirmText}>
                Are you sure you want to delete
                {deleteData.selectDeleteType === 'COPY_ONLY' &&
                  ' only long copy '}
                {deleteData.selectDeleteType === 'FEATURE_BULLETS_ONLY' &&
                  ' only feature bullets '}
                {deleteData.selectDeleteType === 'COPY_AND_FEATURE_BULLETS' &&
                  ' long copy and feature bullets '}
                for the selected tcins ?
              </span>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => cancelCopyBulletDelete()}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => confirmDeleteSelection()}
          variant="contained"
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(DialogBoxConfirmDelete)
